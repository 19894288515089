import React from 'react';
import { Grid } from '@mui/material';

import Layout from 'layouts/projects/Pages/All/Layout';
import SearchBar from 'layouts/projects/Pages/All/SearchBar';
import MDBox from 'components/MDBox';
import ProjectsData from 'layouts/projects/Pages/All/ProjectsData';
import PinnedProjects from 'layouts/projects/Pages/All/PinnedProjects/index.js';
import Summary from 'layouts/projects/Pages/All/Summary';
export default function Projects() {
    return (
        <Layout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDBox
                        color="white"
                        bgColor="dark"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}
                        p={2}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SearchBar />
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
                <Grid item xs={12}>
                    <Summary />
                </Grid>
                <Grid item xs={12}>
                    <PinnedProjects />
                </Grid>
                <Grid item xs={12}>
                    <ProjectsData
                    // data={data?.result || []}
                    // totalCount={data?.totalCount}
                    // totalPages={data?.totalPages}
                    />
                </Grid>
            </Grid>
        </Layout>
    );
}
