import { useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Images
import burceMars from 'assets/images/bruce-mars.jpg';

function Header({ avatar, name, username }) {
    const [visible, setVisible] = useState(true);

    const handleSetVisible = () => setVisible(!visible);

    return (
        <MDBox p={2}>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <MDAvatar src={avatar} alt="profile-image" size="xl" shadow="sm" />
                </Grid>
                <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                            {name}
                        </MDTypography>
                        <MDTypography variant="button" color="text" fontWeight="medium">
                            {username}
                        </MDTypography>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default Header;
