import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import { Dialog, Card, Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import * as yup from 'yup';

import FormField from 'layouts/pages/account/components/FormField';
import { updatePurchase } from 'services/api/purchases';
import { notify } from 'helpers/notificationHelper';

const validationSchema = yup.object({
    wooCommerceID: yup.string().required('WooCommerce ID gereklidir'),
    // Add your validation rules here
});

export default function EditPurchase(props) {
    const { initial, handleClose, open } = props;
    const queryClient = useQueryClient();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    const formik = useFormik({
        initialValues: {
            wooCommerceID: initial?.wooCommerceID || '',
            shipments: initial?.shipments || [],
        },
        validateOnMount: true,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            console.log('Formik onSubmit triggered with values:', values);
            setDisableOnSubmit(true);

            updatePurchase(values.wooCommerceID, values)
                .then((response) => {
                    console.log('API Response:', response);
                    notify(response.data.success, response.data.message);
                    handleClose();
                    queryClient.invalidateQueries(['purchase', initial?.wooCommerceID]);
                    queryClient.invalidateQueries(['wooOrders']);
                })
                .catch((err) => {
                    console.error('Submit error:', err);
                    notify(false, err.message || JSON.stringify(err));
                })
                .finally(() => {
                    setDisableOnSubmit(false);
                    setSubmitting(false);
                });
        },
    });

    useEffect(() => {
        console.log('Formik values:', formik.values);
        console.log('Formik errors:', formik.errors);
        console.log('Formik isValid:', formik.isValid);
    }, [formik.values, formik.errors, formik.isValid]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('Handle form submit triggered');
        console.log('Form is valid:', formik.isValid);
        console.log('Form errors:', formik.errors);
        console.log('Form values:', formik.values);
        formik.handleSubmit(e); // Bu satır çalışıyor mu?
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md" onClick={(e) => e.stopPropagation()}>
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={handleFormSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            {/* Shipping Information */}
                            <Grid item xs={12}>
                                {formik.values.shipments.map((shipment, index) => (
                                    <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel>Shipping Method</InputLabel>
                                                <Select
                                                    label="Shipping Method"
                                                    name={`shipments.${index}.shippingMethod`}
                                                    value={shipment.shippingMethod || ''}
                                                    onChange={formik.handleChange}
                                                >
                                                    <MenuItem value="Aras Kargo">Aras Kargo</MenuItem>
                                                    <MenuItem value="Yurtiçi Kargo">Yurtiçi Kargo</MenuItem>
                                                    <MenuItem value="Fidan Nakliyat">Fidan Nakliyat</MenuItem>
                                                    <MenuItem value="Ege Ulaşım">Ege Ulaşım</MenuItem>
                                                    <MenuItem value="Diğer">Diğer</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                label="Shipping Cost"
                                                name={`shipments.${index}.shippingCost`}
                                                value={shipment.shippingCost || ''}
                                                onChange={formik.handleChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                label="Tracking Number"
                                                name={`shipments.${index}.trackingNumber`}
                                                value={shipment.trackingNumber || ''}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                                <MDButton
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    type="button"
                                    onClick={() => {
                                        const shipments = [...formik.values.shipments];
                                        shipments.push({ shippingMethod: '', shippingCost: 0, trackingNumber: '' });
                                        formik.setFieldValue('shipments', shipments);
                                    }}
                                >
                                    Kargo Bilgisi Ekle
                                </MDButton>
                            </Grid>

                            {/* Gönder Butonu */}
                            <Grid item xs={12}>
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="dark"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    {disableOnSubmit ? 'Güncelleniyor...' : 'KARGO BİLGİLERİNİ GÜNCELLE'}
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </Card>
        </Dialog>
    );
}
