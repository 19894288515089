

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React example components
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import PageLayout from 'examples/LayoutContainers/PageLayout';

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from 'page.routes';

// Authentication pages components
import Footer from 'layouts/authentication/components/Footer';

function BasicLayout({ image, children }) {
	return (
		<PageLayout>
			<DefaultNavbar
				routes={pageRoutes}
				action={{
					type: 'external',
					route: 'mailto:samil@kahramanlar.com.tr',
					label: 'İLETİŞİM',
				}}
				transparent
				light
			/>
			<MDBox
				position="absolute"
				width="100%"
				minHeight="100vh"
				sx={{
					backgroundImage: ({
						functions: { linearGradient, rgba },
						palette: { gradients },
					}) =>
						image &&
						`${linearGradient(
							rgba(gradients.dark.main, 0.6),
							rgba(gradients.dark.state, 0.6)
						)}, url(${image})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}
			/>
			<MDBox px={1} width="100%" height="100vh" mx="auto">
				<Grid
					container
					spacing={1}
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
						{children}
					</Grid>
				</Grid>
			</MDBox>
			<Footer light />
		</PageLayout>
	);
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
	image: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default BasicLayout;
