import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { config } from 'Constants';

import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';

import MDButton from 'components/MDButton';

// import Chip from '@mui/material/Divider';
import { postKanat, updateKanat } from 'services/api/form';
import { getTags } from 'services/api/general/tag';
import Grid from '@mui/material/Grid';
import FormSelect from '../FormComponents/FormSelect';
import EmptySpace from '../FormComponents/EmptySpace';
import FormTextFieldTitle from '../FormComponents/FormTextFieldTitle';
import FormSwitch from '../FormComponents/FormSwitch';
import OutlinedFormTextField from '../FormComponents/OutlinedFormTextField';
import { validationSchemaKanat } from '../FormComponents/YupControlSchema';

import AaroLoadingScreen from 'components/Loading';

const useStyles = makeStyles((theme) => ({
    switch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) => (rp.data?.result?.tags ? rp.data?.result?.tags : []));
    return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.DEMAND_STATUS_TAG_GROUP_ID;

export const Kanat = (props) => {
    const { demandID, initial, id, setOpen, projectID } = props;
    const queryClient = useQueryClient();
    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(['fetchTagGroups', TAG_GROUP_ID_DEMAND], () => fetchTags(TAG_GROUP_ID_DEMAND), { staleTime: 600000 });
    const user = JSON.parse(localStorage.getItem('user'));
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            baslik: initial?.baslik || 'Kanat',
            miktar: initial?.miktar || null,
            kalinlik: initial?.kalinlik || null,
            en: initial?.en || null,
            boy: initial?.boy || null,
            cins: initial?.cins || '',
            cinsAciklama: initial?.cinsAciklama || '',
            dolguTipi: initial?.dolguTipi || 'Standart',
            derzTipi: initial?.derzTipi || 'Yok',
            derzAdedi: initial?.derzAdedi || 0,
            derzSekli: initial?.derzSekli || 'Yok',
            derzBicakAdedi: initial?.derzBicakAdedi || 0,
            maxDerzDerinligi: initial?.maxDerzDerinligi || 0,
            cumbaBandi: initial?.cumbaBandi || 'Yok',
            cumbaKalinligi: initial?.cumbaKalinligi || 0.6,
            cumbaTipi: initial?.cumbaTipi || 'ABS',
            kilitYeri: initial?.kilitYeri || 'Yok',
            menteseYeri: initial?.menteseYeri || 'Yok',
            hidrolik: initial?.hidrolik || false,
            giyotin: initial?.giyotin || false,
            durbun: initial?.durbun || false,
            sertAgacDonme: initial?.sertAgacDonme || false,
            camYeri: initial?.camYeri || 'Yok',
            yuzeyKaplamaSekli: initial?.yuzeyKaplamaSekli || 'Yok',
            mdfYuzeyKalinlik: initial?.mdfYuzeyKalinlik || 0.6,
            ekNot: initial?.ekNot || '',
            indirimOrani: initial?.indirimOrani || 0,
            yoneticiNotu: initial?.yoneticiNotu || '',
            verilenFiyat: initial?.verilenFiyat || null,
            durumu: initial?.durumu || '',
        },
        validationSchema: validationSchemaKanat,

        onSubmit: (values) => {
            setDisableOnSubmit(true);
            if (id) {
                updateKanat(id, values)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demandID]);
                        setOpen(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setDisableOnSubmit(false);
                    });
            } else {
                postKanat(demandID, values)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demandID]);
                        setOpen(false);
                    })

                    .catch((err) => {
                        console.log(err);
                        setDisableOnSubmit(false);
                    });
            }
        },
    });
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    {/* Başlık */}
                    <FormTextFieldTitle
                        variant="filled"
                        fullWidth={true}
                        label="Başlık"
                        placeHolder="Örn: Kanat Başlık"
                        formik={formik}
                        itemID="baslik"
                        disabled={true}
                        selector="kanat"
                    />
                    <EmptySpace height="10px" />
                </Grid>

                {/* DERZ - CUMBA*/}
                <Grid item xs={12} sm={12} md={12}>
                    {/* Miktar - Kalınlık - En - Boy */}
                    <Grid container justifyContent="space-between" spacing={3}>
                        {/* Miktar */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="miktar"
                                label="Miktar (Adet)"
                                placeHolder="Örn: 1000"
                                type="number"
                                formik={formik}
                                step="1"
                                adornment="AD"
                            />
                        </Grid>
                        {/* Kalınlık */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="kalinlik"
                                label="Kalınlık"
                                placeHolder="Örn: 4.1"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* En */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="en"
                                label="En"
                                placeHolder="Örn: 82"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* Boy */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="boy"
                                label="Boy"
                                placeHolder="Örn: 209"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                    </Grid>
                    {/* Derz */}
                    <Grid container justifyContent="space-between" spacing={3}>
                        {/* derzSekli */}
                        <Grid item xs={12} sm={12} md={3}>
                            <FormSelect
                                itemID="derzSekli"
                                formik={formik}
                                inputName="Derz Şekli"
                                items={[
                                    {
                                        name: 'Yok',
                                        value: 'Yok',
                                    },
                                    {
                                        name: 'Standart',
                                        value: 'Standart',
                                    },
                                    {
                                        name: 'V Derz',
                                        value: 'V Derz',
                                    },
                                    {
                                        name: 'Düz Derz',
                                        value: 'Düz Derz',
                                    },
                                ]}
                                helperText={'Kanat Derz Şekli'}
                            />
                        </Grid>
                        {formik.values.derzSekli != 'Yok' ? (
                            <>
                                {/* derzTipi */}
                                <Grid item xs={12} sm={12} md={formik.values.derzSekli == 'Yok' ? 12 : 3}>
                                    {/* derzTipi */}
                                    <FormSelect
                                        itemID="derzTipi"
                                        formik={formik}
                                        inputName="Derz Tipi"
                                        items={[
                                            {
                                                name: 'Yok',
                                                value: 'Yok',
                                            },
                                            {
                                                name: 'Diğer',
                                                value: 'Diğer',
                                            },
                                        ]}
                                        helperText={'Derz Tipi'}
                                    />
                                </Grid>
                                {/* Derz Adedi*/}
                                <Grid item xs={12} sm={12} md={3}>
                                    {/* Derz Adedi*/}
                                    <OutlinedFormTextField
                                        itemID="derzAdedi"
                                        label="Derz Adedi"
                                        type="number"
                                        formik={formik}
                                        step="0.1"
                                        adornment="AD"
                                    />
                                </Grid>
                                {/* Derz Bıçak Adedi */}
                                <Grid item xs={12} sm={12} md={3}>
                                    <OutlinedFormTextField
                                        itemID="derzBicakAdedi"
                                        label="Derz Bıçak Adedi"
                                        type="number"
                                        formik={formik}
                                        step="0.1"
                                        adornment="AD"
                                    />
                                </Grid>
                            </>
                        ) : (
                            ''
                        )}
                    </Grid>

                    {/* Cumba */}
                    <Grid container justifyContent="space-between" spacing={3}>
                        {/* "Max Derz Derinligi*/}
                        <Grid item xs={12} sm={12} md={3}>
                            {/* "Max Derz Derinligi*/}
                            <OutlinedFormTextField
                                itemID="maxDerzDerinligi"
                                label="Max Derz Derinligi (CM)"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* cumbaBandi */}
                        <Grid item xs={12} sm={12} md={3}>
                            <FormSelect
                                itemID="cumbaBandi"
                                formik={formik}
                                inputName="Cumba Bandı"
                                items={[
                                    {
                                        name: 'Yok',
                                        value: 'Yok',
                                    },
                                    {
                                        name: 'Yapılacak',
                                        value: 'Yapılacak',
                                    },
                                    {
                                        name: '2 Kenar',
                                        value: '2 Kenar',
                                    },
                                    {
                                        name: '4 Kenar',
                                        value: '4 Kenar',
                                    },
                                ]}
                                helperText={'Kanat Cumba Bandı'}
                            />
                        </Grid>
                        {formik.values.cumbaBandi != 'Yok' ? (
                            <>
                                {/* cumbaTipi */}
                                <Grid item xs={12} sm={12} md={3}>
                                    <FormSelect
                                        itemID="cumbaTipi"
                                        formik={formik}
                                        inputName="Cumba Tipi"
                                        items={[
                                            {
                                                name: 'ABS',
                                                value: 'ABS',
                                            },
                                            {
                                                name: 'PVC',
                                                value: 'PVC',
                                            },
                                            {
                                                name: 'Kaplama',
                                                value: 'Kaplama',
                                            },
                                        ]}
                                        helperText={'Kanat Cumba Bandı'}
                                    />
                                </Grid>
                                {/* Cumba Kalınlığı */}
                                <Grid item xs={12} sm={12} md={3}>
                                    <OutlinedFormTextField
                                        itemID="cumbaKalinligi"
                                        label="Cumba Kalınlığı (MM)"
                                        type="number"
                                        formik={formik}
                                        step="0.1"
                                        adornment="MM"
                                    />
                                </Grid>
                            </>
                        ) : (
                            ''
                        )}
                    </Grid>
                    {/* Cins - derzSekli -menteseYeri*/}

                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item xs={12} sm={12} md={3}>
                            {/* Cins */}
                            <FormSelect
                                itemID="cins"
                                formik={formik}
                                inputName="Cins"
                                items={[
                                    {
                                        name: 'Lakelik',
                                        value: 'Lakelik',
                                    },
                                    {
                                        name: 'Desenli',
                                        value: 'Desenli',
                                    },
                                    {
                                        name: 'Kaplamalı Alpi',
                                        value: 'Kaplamalı Alpi',
                                    },
                                    {
                                        name: 'Kaplamalı Doğal',
                                        value: 'Kaplamalı Doğal',
                                    },
                                    {
                                        name: 'Kaplama Müşteriden',
                                        value: 'Kaplama Müşteriden',
                                    },
                                    {
                                        name: 'Özel Kaplama Doğal',
                                        value: 'Özel Kaplama Doğal',
                                    },
                                    {
                                        name: 'Özel Kaplama Alpi',
                                        value: 'Özel Kaplama Alpi',
                                    },
                                ]}
                                helperText={'Kasanın cinsi'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {/* Cins Açıklaması */}
                            <OutlinedFormTextField
                                itemID="cinsAciklama"
                                label="Cins Açıklaması"
                                placeHolder="Örn: A17 Teak"
                                formik={formik}
                                adornment=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {' '}
                            {/* dolguTipi */}
                            <FormSelect
                                itemID="dolguTipi"
                                formik={formik}
                                inputName="Dolgu Tipi"
                                items={[
                                    {
                                        name: 'Standart',
                                        value: 'Standart',
                                    },
                                    {
                                        name: 'Okal',
                                        value: 'Okal',
                                    },
                                    {
                                        name: 'Halspan',
                                        value: 'Halspan',
                                    },
                                    {
                                        name: 'Diğer',
                                        value: 'Diğer',
                                    },
                                ]}
                                helperText={'Dolgu Tipi'}
                            />{' '}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {' '}
                            {/*Yüzey Kaplama Şekli*/}
                            <FormSelect
                                itemID="yuzeyKaplamaSekli"
                                formik={formik}
                                inputName="Yüzey Kaplama Şekli"
                                items={[
                                    {
                                        name: 'Yok',
                                        value: 'Yok',
                                    },
                                    {
                                        name: 'Suyuna',
                                        value: 'Suyuna',
                                    },
                                    {
                                        name: 'Sokrasına',
                                        value: 'Sokrasına',
                                    },
                                    {
                                        name: 'H',
                                        value: 'H',
                                    },
                                    {
                                        name: 'E',
                                        value: 'E',
                                    },
                                    {
                                        name: 'Diğer',
                                        value: 'Diğer',
                                    },
                                ]}
                                helperText={'Yüzey Kaplama Şekli'}
                            />{' '}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            {/* menteseYeri */}
                            <FormSelect
                                itemID="menteseYeri"
                                formik={formik}
                                inputName="Menteşe Yeri"
                                items={[
                                    {
                                        name: 'Yok',
                                        value: 'Yok',
                                    },
                                    {
                                        name: 'Yönlü 2',
                                        value: 'Yönlü 2',
                                    },
                                    {
                                        name: 'Yönlü 3',
                                        value: 'Yönlü 3',
                                    },
                                    {
                                        name: 'Gömme 2',
                                        value: 'Gömme 2',
                                    },
                                    {
                                        name: 'Gömme 3',
                                        value: 'Gömme 3',
                                    },
                                ]}
                                helperText={'Menteşe Yeri'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            {/* camYeri */}
                            <FormSelect
                                itemID="camYeri"
                                formik={formik}
                                inputName="Cam Yeri"
                                items={[
                                    {
                                        name: 'Yok',
                                        value: 'Yok',
                                    },
                                    {
                                        name: '1 Göbek Serenli',
                                        value: '1 Göbek Serenli',
                                    },
                                    {
                                        name: '3 Göbek Serenli',
                                        value: '3 Göbek Serenli',
                                    },
                                    {
                                        name: '5 Göbek Serenli',
                                        value: '5 Göbek Serenli',
                                    },
                                    {
                                        name: '1 Göbek MDFli',
                                        value: '1 Göbek MDFli',
                                    },
                                    {
                                        name: '3 Göbek MDFli',
                                        value: '3 Göbek MDFli',
                                    },
                                    {
                                        name: '5 Göbek MDFli',
                                        value: '5 Göbek MDFli',
                                    },
                                    {
                                        name: 'Giyotin',
                                        value: 'Giyotin',
                                    },
                                ]}
                                helperText={'Cam Yeri'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            {' '}
                            {/* kilitYeri */}
                            <FormSelect
                                itemID="kilitYeri"
                                formik={formik}
                                inputName="Kilit Yeri"
                                items={[
                                    {
                                        name: 'Yok',
                                        value: 'Yok',
                                    },
                                    {
                                        name: 'Standart Kale',
                                        value: 'Standart Kale',
                                    },
                                    {
                                        name: 'Topuz',
                                        value: 'Topuz',
                                    },
                                    {
                                        name: 'Otel Tipi',
                                        value: 'Otel Tipi',
                                    },
                                ]}
                                helperText={'Kilit Yeri'}
                            />{' '}
                        </Grid>
                        {/* MDF Kalınlığı*/}
                        <Grid item xs={12} sm={12} md={3}>
                            {/* MDF Kalınlığı*/}
                            <OutlinedFormTextField
                                itemID="mdfYuzeyKalinlik"
                                label="MDF Yüzey Kalınlığı (CM)"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Var Yok Seçenekleri */}
                <Grid container className={classes.switch}>
                    {/* Hidrolik Yeri*/}
                    <FormSwitch formik={formik} name="hidrolik" color="secondary" label="Hidrolik Yeri" />
                    {/*Giyotin Kesim*/}
                    <FormSwitch formik={formik} name="giyotin" color="secondary" label="Giyotin Kesim" />
                    {/*Dürbün Yeri*/}
                    <FormSwitch formik={formik} name="durbun" color="secondary" label="Dürbün Yeri" />
                    {/* Sert Ağaç Dönülmesi*/}
                    <FormSwitch formik={formik} name="sertAgacDonme" color="secondary" label="Sert Ağaç Dönülmesi" />
                </Grid>

                {/* Ek Not */}
                <Grid item xs={12}>
                    {/* Ek Not */}
                    <OutlinedFormTextField
                        itemID="ekNot"
                        label="Ek Notlarınız"
                        placeHolder="Örn: Kendi bıçaklarını verecekler"
                        formik={formik}
                    />
                </Grid>

                {/* Yönetici Alanı*/}
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                        {/* Yönetici Notu*/}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="yoneticiNotu"
                                label="Yöneticinin Notu"
                                placeHolder="Yönetici Notu"
                                formik={formik}
                                disabled={user.role !== 'superadmin'}
                            />
                        </Grid>
                        {/* Verilen Fiyat */}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="verilenFiyat"
                                label="Birim Fiyat"
                                placeHolder="Birim Fiyat"
                                formik={formik}
                                step="0.01"
                                type="number"
                                // disabled={user.role != 'superadmin'}
                            />
                        </Grid>

                        {/* Teklif Durumu */}
                        <Grid item xs={12} sm={12} md={4}>
                            {/* Girinti */}
                            <FormSelect
                                itemID="durumu"
                                formik={formik}
                                inputName="Talep Durumu"
                                items={tags.map((tag) => ({
                                    value: tag._id,
                                    name: tag.name,
                                }))}
                                helperText={'Talebin durumunu seçin'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <MDButton color="dark" variant="gradient" fullWidth type="submit" disabled={disableOnSubmit}>
                        Kaydet
                    </MDButton>
                </Grid>
            </Grid>
        </form>
    );
};
