import { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Timeline context
import { useTimeline } from 'examples/Timeline/context';

// Custom styles for the TimelineItem
import timelineItem from 'examples/Timeline/TimelineItem/styles';
import { formatDateMinutes } from 'helpers/dateHelpers';
import Offer from 'layouts/projects/Components/Offer';
import { formatDate } from 'helpers/dateHelpers';
import MuiLink from '@mui/material/Link';

function TimelineItem({
    color,
    icon,
    title,
    dateTime,
    description,
    lastItem,
    demand,
    project,
}) {
    const [opened, setOpened] = useState(false);
    const isDark = useTimeline();
    return demand?.freezedBy?.role === 'superadmin' ? (
        <MDBox
            position="relative"
            mb={3}
            sx={(theme) => timelineItem(theme, { lastItem, isDark })}
        >
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={
                    isValid(demand.freezedDate, demand.validity)
                        ? 'success'
                        : 'primary'
                }
                color="white"
                width="2rem"
                height="2rem"
                borderRadius="50%"
                position="absolute"
                top="8%"
                left="2px"
                zIndex={2}
                sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                onClick={() => setOpened(!opened)}
            >
                <Icon fontSize="inherit">
                    {isValid(demand.freezedDate, demand.validity)
                        ? 'open_in_full'
                        : 'do_not_disturb'}
                </Icon>
            </MDBox>

            <MDBox ml={5.75} pt={0.7} lineHeight={0}>
                <MDTypography
                    variant="button"
                    fontWeight="medium"
                    color={isDark ? 'white' : 'dark'}
                    onClick={() => setOpened(!opened)}
                >
                    {demand.title}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {isValid(demand.freezedDate, demand.validity)
                        ? 'Geçerli: ' +
                          validityDate(demand.freezedDate, demand.validity)
                        : 'GEÇERSİZ!'}
                </MDTypography>
                <MDBox mt={0.5}>
                    <MDTypography
                        variant="caption"
                        color={isDark ? 'secondary' : 'text'}
                    >
                        {demand?.freezeNotes}&nbsp;&nbsp;|&nbsp;&nbsp;
                        {formatDateMinutes(demand.freezedDate)} -{' '}
                        <MDTypography
                            variant="caption"
                            fontWeight="bold"
                            color={isDark ? 'secondary' : 'text'}
                        >
                            {demand?.freezedBy?.name}
                        </MDTypography>
                    </MDTypography>
                </MDBox>

                {opened && (
                    <MDBox mt={2} mb={1.5}>
                        <MDTypography
                            variant="button"
                            color={isDark ? 'white' : 'dark'}
                        >
                            <Offer
                                demandID={demand._id}
                                projectID={project._id}
                                isHistory={true}
                            />
                        </MDTypography>
                        <MuiLink
                            href={googleFolderLink(
                                demand?.createdGoogleSheetOffers?.[0]?.folderID
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                        >
                            <MDBox
                                display="flex"
                                mt={2}
                                bgColor="white"
                                alignItems="center"
                                color="white"
                                shadow="md"
                                borderRadius="lg"
                                variant="gradient"
                                p={1}
                            >
                                {previouslyCreatedOffers(demand)?.map((offer) =>
                                    googleSheetAndFolderLinkView(
                                        offer?.sheetID,
                                        offer?.user,
                                        offer?.date
                                    )
                                )}
                            </MDBox>
                        </MuiLink>
                    </MDBox>
                )}
            </MDBox>
        </MDBox>
    ) : (
        <MDBox
            position="relative"
            mb={3}
            sx={(theme) => timelineItem(theme, { lastItem, isDark })}
        >
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={
                    'secondary'
                    // isValid(demand.freezedDate, demand.validity)
                    // 	? 'success'
                    // 	: 'primary'
                }
                color="white"
                width="1rem"
                height="1rem"
                borderRadius="50%"
                position="absolute"
                top="32%"
                left="10px"
                zIndex={2}
                sx={{
                    fontSize: ({ typography: { size } }) => size.sm,
                    opacity: 0.7,
                }}
                onClick={() => setOpened(!opened)}
            >
                <Icon fontSize="inherit">
                    {isValid(demand.freezedDate, demand.validity)
                        ? 'open_in_full'
                        : 'do_not_disturb'}
                </Icon>
            </MDBox>

            <MDBox ml={5.75} pt={0.7} lineHeight={0}>
                <MDTypography
                    variant="caption"
                    color={isDark ? 'white' : 'dark'}
                    onClick={() => setOpened(!opened)}
                >
                    {demand.title}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {isValid(demand.freezedDate, demand.validity)
                        ? 'Geçerli: ' +
                          validityDate(demand.freezedDate, demand.validity)
                        : 'GEÇERSİZ!'}
                </MDTypography>
                <MDBox mt={0.5}>
                    <MDTypography
                        variant="caption"
                        color={isDark ? 'secondary' : 'text'}
                        textTransform="lowercase"
                    >
                        {demand?.freezeNotes}&nbsp;&nbsp;|&nbsp;&nbsp;
                        {formatDateMinutes(demand.freezedDate)} -{' '}
                        {demand?.freezedBy?.name}
                    </MDTypography>
                </MDBox>

                {opened && (
                    <MDBox mt={2} mb={1.5}>
                        <MDTypography
                            variant="button"
                            color={isDark ? 'white' : 'dark'}
                        >
                            <Offer
                                demandID={demand._id}
                                projectID={project._id}
                                isHistory={true}
                            />
                        </MDTypography>
                        <MuiLink
                            href={googleFolderLink(
                                demand?.createdGoogleSheetOffers?.[0]?.folderID
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                        >
                            <MDBox
                                display="flex"
                                mt={2}
                                bgColor="white"
                                alignItems="center"
                                color="white"
                                shadow="md"
                                borderRadius="lg"
                                variant="gradient"
                                p={1}
                            >
                                {previouslyCreatedOffers(demand)?.map((offer) =>
                                    googleSheetAndFolderLinkView(
                                        offer?.sheetID,
                                        offer?.user,
                                        offer?.date
                                    )
                                )}
                            </MDBox>
                        </MuiLink>
                    </MDBox>
                )}
            </MDBox>
        </MDBox>
    );
}

const googleSheetLink = (sheetID) => {
    return `https://docs.google.com/spreadsheets/d/${sheetID}`;
};
const googleFolderLink = (folderID) => {
    return `https://drive.google.com/drive/folders/${folderID}`;
};
const googleSheetAndFolderLinkView = (sheetID, user, date) => {
    return (
        <MuiLink
            href={googleSheetLink(sheetID)}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
        >
            <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bgColor="success"
                color="white"
                width="10rem"
                height="4rem"
                top="8%"
                borderRadius="lg"
                m={1}
            >
                <MDTypography variant="h6" fontWeight="bold" color="white">
                    {' '}
                    {user?.username}
                </MDTypography>
                <MDTypography variant="caption" fontWeight="bold" color="white">
                    {' '}
                    {formatDate(date)}
                </MDTypography>
            </MDBox>
        </MuiLink>
    );
};

const previouslyCreatedOffers = (demand) => {
    const offers = demand.createdGoogleSheetOffers;
    const sortedOffers = offers.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return sortedOffers;
};

const validityDate = (freezedDate, validityDay) => {
    const validUntil = moment(freezedDate).add(validityDay, 'days');

    return formatDate(validUntil);
};

const isValid = (freezedDate, validityDay) => {
    const validUntil = moment(freezedDate).add(validityDay, 'days');
    const today = moment(new Date());
    const diff = today.diff(validUntil);
    return diff > 0 ? false : true;
};

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
    color: 'info',
    lastItem: false,
    description: '',
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
    ]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    description: PropTypes.string,
    lastItem: PropTypes.bool,
};

export default TimelineItem;
