/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// core components
import Card from '@mui/material/Card';
import { css } from '@emotion/react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import defaultImage from 'assets/img/image_placeholder.jpg';
import defaultAvatar from 'assets/img/placeholder.jpg';
// core components
import { postFile } from 'services/api/general/file';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 20% 0 0;
    border-color: red;
`;

export default function AddFile(props) {
    const { relatedID, refresh, setRefresh, filePath, additionalName, setUpdateFiles, source } = props;
    const [name, setName] = useState('');
    const [alt, setAlt] = useState('');
    const [attach, setAttach] = useState(null);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(props.avatar ? defaultAvatar : defaultImage);
    let fileInput = React.createRef();
    const formData = new FormData();

    async function handleSubmit(event) {
        setLoading(true);
        event.preventDefault();
        let user = JSON.parse(localStorage.getItem('user'));

        formData.append('relatedID', relatedID);
        formData.append('name', name);
        formData.append('filePath', filePath.trim());
        formData.append('user', user.userID);
        formData.append('alt', alt);
        formData.append('attachment', attach);

        try {
            await postFile(formData, additionalName.trim(), source);
            refreshAttachments();
        } catch (err) {
            console.log(err);
        }
    }
    const refreshAttachments = () => {
        setName('');
        setAlt('');
        setLoading(false);
        const increaseComment = refresh + 1;
        setUpdateFiles(Math.random());
        setRefresh(increaseComment);
    };
    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setFile(file);
            setAttach(file);
            setImagePreviewUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleClick = () => {
        fileInput.current.click();
    };

    return (
        <div className="attachment">
            <Card>
                {loading === false ? (
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
                                <MDBox
                                    component="img"
                                    src={imagePreviewUrl}
                                    alt="asd"
                                    borderRadius="lg"
                                    shadow="md"
                                    width="100%"
                                    height="100%"
                                    position="relative"
                                    zIndex={1}
                                />
                                <MDBox
                                    borderRadius="lg"
                                    shadow="md"
                                    width="100%"
                                    height="100%"
                                    position="absolute"
                                    left={0}
                                    top="3%"
                                    sx={{
                                        backgroundImage: `url(${imagePreviewUrl})`,
                                        transform: 'scale(0.94)',
                                        filter: 'blur(12px)',
                                        backgroundSize: 'cover',
                                    }}
                                />
                            </MDBox>
                            <MDBox p={3}>
                                <MDBox pb={1} display="flex" justifyContent="center" alignItems="center">
                                    <MDInput
                                        type="file"
                                        onChange={handleImageChange}
                                        inputRef={fileInput}
                                        sx={{ display: 'none' }}
                                    />
                                    <MDButton
                                        color={'dark'}
                                        borderRadius="xl"
                                        variant="gradient"
                                        onClick={() => handleClick()}
                                        size="small"
                                    >
                                        Seç
                                    </MDButton>
                                </MDBox>
                                <MDBox pb={1}>
                                    <MDInput
                                        label="Dosya Başlığı"
                                        fullWidth
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </MDBox>
                                <MDBox pb={1}>
                                    <MDInput
                                        label="Dosya Açıklaması"
                                        fullWidth
                                        value={alt}
                                        onChange={(e) => setAlt(e.target.value)}
                                    />
                                </MDBox>
                                <MDBox pb={1}>
                                    <MDButton color={'dark'} fullWidth variant="gradient" type="submit">
                                        Yükle
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </form>
                ) : (
                    <MDBox m={3} sx={{ height: '80px' }} bgColor="white">
                        <PacmanLoader color="#485563" loading={loading} css={override} size={50} />
                    </MDBox>
                )}
            </Card>
        </div>
    );
}
