import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { useMutation } from 'react-query';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { updateProject } from 'services/api/project';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';

export default function DayRating({ project, labelSize = '33px', readOnly = false }) {
    const queryClient = useQueryClient();

    const [rating, setRating] = useState(project?.projectPotential ? project.projectPotential : 0);
    const updateRating = useMutation((rating) => updateProject(project._id, { projectPotential: rating }), {
        onSuccess: (rp) => {
            notify(rp.data.success, rp.data.message);
            queryClient.invalidateQueries(['fetchProject', project._id]);
        },
        onError: (error) => {
            notify(false, error);
        },
    });
    const StyledRating = withStyles({
        iconFilled: {
            color: '#ffb400',
        },
        iconHover: {
            color: '#ffb400',
        },
        label: {
            fontSize: labelSize,
        },
    })(Rating);
    return (
        <StyledRating
            name="day-rating"
            // defaultValue={2}
            value={rating}
            readOnly={readOnly}
            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
            precision={1}
            max={5}
            icon={<StarIcon fontSize="inherit" />}
            onChange={(event, newValue) => {
                event.preventDefault();

                updateRating.mutate(newValue);
                setRating(newValue);
            }}
        />
    );
}
