// prop-types is a library for typechecking of props
import useDialogHook from 'hooks/useDialogHook';
import DiffView from 'components/DiffViwer';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Timeline context

// Custom styles for the TimelineItem
import timelineItem from 'components/General/Activity/styles.js';

function TimelineItem({
    color,
    icon,
    title,
    dateTime,
    description,
    lastItem,
    data,
}) {
    const { open, handleClickOpen, handleClose } = useDialogHook();

    return (
        <MDBox
            position="relative"
            mb={3}
            sx={(theme) => timelineItem(theme, { lastItem })}
        >
            <MDBox
                onClick={handleClickOpen}
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgColor={color}
                color="white"
                width="2rem"
                height="2rem"
                borderRadius="50%"
                position="absolute"
                top="8%"
                left="2px"
                zIndex={2}
                sx={{ fontSize: ({ typography: { size } }) => size.sm }}
            >
                <Icon fontSize="inherit">{icon}</Icon>
            </MDBox>
            <MDBox
                ml={5.75}
                pt={description ? 0.7 : 0.5}
                lineHeight={0}
                maxWidth="30rem"
            >
                <MDTypography
                    variant="button"
                    fontWeight="medium"
                    color={'dark'}
                >
                    {title}
                </MDTypography>
                <MDBox mt={0.5}>
                    <MDTypography variant="caption" color={'text'}>
                        {dateTime}
                    </MDTypography>
                </MDBox>
                <MDBox mt={2} mb={1.5}>
                    {description ? (
                        <MDTypography variant="button" color={'dark'}>
                            {description}
                        </MDTypography>
                    ) : null}
                </MDBox>
            </MDBox>
            {open && (
                <DiffView
                    oldCode={data?.oldData}
                    newCode={data?.newData}
                    open={open}
                    handleClose={handleClose}
                    sourcePage="lead"
                    type={data?.type}
                />
            )}
        </MDBox>
    );
}

export default TimelineItem;
