import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { notify } from 'helpers/notificationHelper';
import { updateProductSuggestion } from 'services/api/productSuggestion';
import Rating from '@mui/material/Rating';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Lottie from 'react-lottie';
import animationData from 'assets/animations/like.json';
import sadAnimationData from 'assets/animations/sad.json';

const validationSchema = yup.object().shape({
    rate: yup.number(),
    productName: yup.string(),
    description: yup.string(),
});

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
    speed: 2.5,
};
const defaultOptionsSad = {
    loop: true,
    autoplay: true,
    animationData: sadAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
    speed: 2.5,
};

export default function UpdateProductSuggestionInput({ product, id }) {
    const queryClient = useQueryClient();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    // const [showAnimation, setShowAnimation] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    let userVote;
    if (user && product) {
        userVote = product.userSuggestions.find((suggestion) => suggestion?.user?._id === user?.userID);
    }

    const initialValues = userVote
        ? { ...userVote }
        : {
              rate: 0,
              productName: '',
              description: '',
              user: user.userID,
          };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setDisableOnSubmit(true);
            try {
                const userSuggestion = product.userSuggestions.find(
                    (suggestion) => suggestion?.user?._id === user.userID
                );
                const updatedProduct = userSuggestion
                    ? {
                          userSuggestions: product.userSuggestions.map((suggestion) =>
                              suggestion?.user?._id === user.userID ? { ...suggestion, ...values } : suggestion
                          ),
                      }
                    : {
                          userSuggestions: [...product.userSuggestions, { user: user.userID, ...values }],
                      };

                console.log(updatedProduct);

                const response = await updateProductSuggestion(id, updatedProduct);
                notify(response.data.success, response.data.message);
                queryClient.invalidateQueries('fetchProductSuggestions');
                if (buttonClicked) {
                    setShowDetails(false);
                    setButtonClicked(false);
                }
            } catch (err) {
                notify(false, JSON.stringify(err));
            } finally {
                setDisableOnSubmit(false);
            }
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Rating
                        name="rate"
                        value={formik.values?.rate}
                        precision={0.5}
                        onChange={(event, newValue) => {
                            setShowDetails(true);
                            // setShowAnimation(true);
                            // setTimeout(() => setShowAnimation(false), 1500);
                            formik.setFieldValue('rate', newValue);
                            formik.handleSubmit();
                        }}
                        size="large"
                    />
                    {/* {showAnimation && (
                        <MDBox display="flex" justifyContent="center" alignItems="center" sx={{ width: '%100' }}>
                            {formik.values?.rate > 2 ? (
                                <Lottie options={defaultOptions} height={150} width={150} />
                            ) : (
                                <Lottie options={defaultOptionsSad} height={150} width={150} />
                            )}
                        </MDBox>
                    )} */}
                </Grid>

                {showDetails && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="productName"
                                name="productName"
                                label="İsim Öneriniz"
                                value={formik.values?.productName}
                                onChange={formik.handleChange}
                                error={formik.touched.productName && Boolean(formik.errors.productName)}
                                helperText={formik.touched.productName && formik.errors.productName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="description"
                                name="description"
                                label="Düşünceleriniz"
                                value={formik.values?.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" onClick={() => setButtonClicked(true)}>
                                <MDButton
                                    color="info"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    Ekle
                                </MDButton>
                            </Box>
                        </Grid>{' '}
                    </>
                )}
            </Grid>
        </form>
    );
}
