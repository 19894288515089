import React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useQuery, useQueryClient } from 'react-query';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import AaroLoadingScreen from 'components/Loading';
import pxToRem from 'assets/theme/functions/pxToRem.js';
import { getTags } from 'services/api/general/tag';

import { notify } from 'helpers/notificationHelper';

import { updateTag, deleteTag } from 'services/api/general/tag.js';

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) =>
        rp.data?.result?.tags ? rp.data?.result?.tags : []
    );
    return response;
};

const checkIfColorIsHex = (color) => {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexRegex.test(color);
};

export default function Parameters({ tagGroupID }) {
    const queryClient = useQueryClient();

    const { data, isLoading, error, refetch } = useQuery(
        ['fetchTagGroups', tagGroupID],
        () => fetchTags(tagGroupID),
        { staleTime: 600000 }
    );

    const handleRowEditCommit = React.useCallback((params) => {
        const id = params.id;
        const key = params.field;
        const value = params.value;
        let obj = {};
        obj[key] = value;
        if (key === 'color' && checkIfColorIsHex(value)) updateCell(id, obj);
        if (key !== 'color') updateCell(id, obj);
    }, []);

    // * Edit cells section
    const updateCell = React.useCallback((id, obj) => {
        updateTag(id, obj)
            .then((rp) => {
                notify(rp.data.success, rp.data.message);
                queryClient.invalidateQueries(['fetchTagGroups']);
            })
            .catch((err) => {
                notify(false, err.message);
            });
    }, []);

    if (isLoading) return <AaroLoadingScreen />;
    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const columns = [
        {
            field: 'name',
            headerName: 'Etiket Adı',
            flex: 1,
            editable: true,
        },
        {
            field: 'description',
            headerName: 'Açıklama',
            flex: 1,
            editable: true,
        },
        {
            field: 'color',
            headerName: 'Renk',
            flex: 1,
            renderCell: (params) => {
                return (
                    <MDBox
                        sx={{
                            backgroundColor: params.formattedValue,
                            width: '100%',
                        }}
                        borderRadius="lg"
                        shadow="lg"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <MDTypography variant="h6" color="white">
                            {params.formattedValue}
                        </MDTypography>
                    </MDBox>
                );
            },
            editable: true,
        },
        {
            field: 'order',
            headerName: 'Sıra',
            flex: 1,
            editable: true,
        },
        {
            field: 'muiColorCode',
            headerName: 'Mui Rengi',
            flex: 1,
            editable: true,
        },

        {
            field: 'editColumn',
            headerName: 'Sil',
            flex: 0.25,
            renderCell: (params) => (
                <MDButton
                    variant="gradient"
                    size="small"
                    color="primary"
                    onClick={() => {
                        const result = window.confirm(
                            'Silmek İstediğinize Emin Misiniz?'
                        );
                        if (result)
                            deleteTag(params.formattedValue)
                                .then((rp) => {
                                    notify(rp.data.success, rp.data.message);
                                    refetch();
                                })
                                .catch((err) => {
                                    notify(false, err.message);
                                });
                    }}
                >
                    SİL
                </MDButton>
            ),
        },
    ];
    const rows = data.map((el) => ({
        id: el._id,
        name: el.name,
        description: el.description,
        color: el?.color,
        order: el?.order,
        muiColorCode: el?.muiColorCode,
        editColumn: el._id,
    }));

    return (
        <MDBox
            color="white"
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
        >
            <DataGridPro
                autoHeight
                autoPageSize
                // localeText={trTR.props.MuiDataGrid.localeText}
                components={{
                    Toolbar: GridToolbar,
                }}
                onCellEditCommit={handleRowEditCommit}
                density="compact"
                // checkboxSelection
                disableSelectionOnClick
                rows={rows}
                columns={columns}
                sx={{
                    fontSize: pxToRem(12),
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1.25,
                }}
            />
        </MDBox>
    );
}
