import { memo, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';

// Material Dashboard 2 PRO React example components
import Layout from './Layout';
import Navbar from './Navbar';
import Header from './Header';

import Footer from 'examples/Footer';

// Project page components

// Libraries
import { useQuery } from 'react-query';

// API
import { getProjects } from 'services/api/project';

// Layout

import ProjectCreation from 'layouts/projects/Components/ProjectCreation';

import ProjectCardSkeleton from 'components/Loading/ProjectCardSkeleton';
import { demandStatusNameFinder } from 'helpers/projectHelpers';
import MDBadge from 'components/MDBadge';

import { matchSorter } from 'match-sorter';

function AaroLayout({ children }) {
	// ProjectCard dropdown menu state
	const [searchTerm, setSearchTerm] = useState('');
	const [projectType, setProjectType] = useState('active');
	const [projectView, setProjectView] = useState('card');
	const [newProject, setNewProject] = useState(false);

	const [filterOption, setFilterOption] = useState('');

	return (
		<Layout>
			<MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
				<Navbar light absolute />
			</MDBox>
			<Header
				projectType={projectType}
				setProjectType={setProjectType}
				setProjectView={setProjectView}
			/>
			<MDBox pb={3}>
				<MDBox mt={5}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							{children}
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
			<Footer />
		</Layout>
	);
}

export default memo(AaroLayout);
