import React, { useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import MDTypography from 'components/MDTypography';
import { formatDateMinutes } from 'helpers/smallCodes';

const TaskHistory = ({ history }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    if (!history || history.length === 0) {
        return <ListItemText primary="Geçmiş bulunamadı" />;
    }

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="task-history-subheader"
            subheader={
                <ListSubheader component="div" id="task-history-subheader">
                    Görev Geçmişi
                </ListSubheader>
            }
        >
            {history.map((record, index) => (
                <React.Fragment key={index}>
                    <ListItemButton onClick={() => handleClick(index)}>
                        <ListItemIcon>
                            <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <MDTypography variant="button" fontWeight="medium">
                                    {record?.changedBy?.username}
                                </MDTypography>
                            }
                            secondary={
                                <MDTypography variant="caption" fontWeight="light">
                                    {formatDateMinutes(record.changedAt)}
                                </MDTypography>
                            }
                        />
                        {openIndex === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {record.changes &&
                                Object.keys(record.changes).map((key) => (
                                    <ListItemButton key={key} sx={{ pl: 4 }}>
                                        <ListItemText
                                            primary={`${key}: ${record.changes[key].oldValue} iken ${record.changes[key].newValue} olarak değiştirildi`}
                                        />
                                    </ListItemButton>
                                ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            ))}
        </List>
    );
};

export default TaskHistory;
