import { useQuery } from 'react-query';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
// helpers
import { formatDate, todayIsBetween } from 'helpers/dateHelpers';
import { getStockPrice } from 'services/outside/aaro/priceList';
import { calculateDiscount, formatPrice } from 'helpers/priceHelpers';

const fetchAaroStockPrice = async (stokID) => {
	const response = await getStockPrice(stokID).then((rp) => rp.data);
	return response;
};

function PriceDetail({ stockID }) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, isLoading, error } = useQuery(
		['fetchAaroStockPrice', stockID],
		() => fetchAaroStockPrice(stockID),
		{ enabled: Boolean(stockID), staleTime: 600000 }
	);

	if (isLoading) return 'geliyor';

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	const lastPriceList = data?.Model[0] ? data.Model[0] : {};
	const isValidDate = todayIsBetween(
		new Date(lastPriceList?.TarihBas),
		new Date(lastPriceList?.TarihBit)
	);
	return (
		<MDBox
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<MDBox>
				<MDBox mb={1}>
					<MDTypography variant="caption" fontWeight="medium">
						{formatDate(lastPriceList?.TarihBas)} -{' '}
						{formatDate(lastPriceList?.TarihBit)}
					</MDTypography>
				</MDBox>
				<MDTypography component="p" variant="button" color="text">
					Liste Adı
				</MDTypography>
				<MDTypography
					component="p"
					variant="button"
					fontWeight="regular"
					color="text"
				>
					{lastPriceList?.FiyatListesiAdi}
				</MDTypography>
			</MDBox>
			<MDButton
				variant="gradient"
				color={isValidDate ? 'success' : 'primary'}
			>
				{isValidDate
					? formatPrice(calculateDiscount(lastPriceList?.Fiyat, 1, 0))
					: 'GEÇERSİZ FİYAT'}
			</MDButton>
		</MDBox>
	);
}

export default PriceDetail;
