import { createContext } from 'react';
import Card from '@mui/material/Card';
import { getTaskSelfAssigned } from 'services/api/general/task';
import { useQuery } from 'react-query';
import MDTypography from 'components/MDTypography';
// @mui material components
import TaskLoadingGIF from 'components/Loading/TaskLoadingGIF.js';
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import SingleTask from 'layouts/todo/components/Activity/components/SingleTask.js';

export const AllTaskListsContext = createContext();

const fetchTasksSelfAssigned = async (userID) => {
    try {
        const createdByResponse = await getTaskSelfAssigned(userID).then((rp) => rp.data.result);
        return createdByResponse;
    } catch (err) {
        return err;
    }
};

export default function SelfAssignedTasks({ title = '' }) {
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;

    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchSelfAssignedTasks', userID],
        () => fetchTasksSelfAssigned(userID),
        {
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading) return <TaskLoadingGIF />;
    if (isFetching) return <TaskLoadingGIF />;
    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <Card sx={{ height: '100%' }}>
            <MDBox pt={2} px={2} display="flex" flexDirection="row" justifyContent="space-between">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {data.map((el) => (
                        <SingleTask key={el?._id} item={el} />
                    ))}
                </MDBox>
            </MDBox>
        </Card>
    );
}
