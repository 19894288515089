import { useEffect, useState } from 'react';

const useGroupItems = (demand, groups) => {
    const [demandItemsReduced, setDemandItemsReduced] = useState([]);
    // const [groupItems, setGroupItems] = useState([]);
    const [groupColumnsAndItems, setGroupColumnsAndItems] = useState([]);

    useEffect(() => {
        const data = demand;

        const dataKeys = ['uKasa', 'uPervaz', 'pervaz', 'kasa', 'kanat', 'diger'];

        let temp = dataKeys.flatMap((key) => data[key]?.map((item) => ({ ...item, type: key }))).filter(Boolean);

        let tempArray = temp.map((el) => ({
            origin: 'defaultColumn',
            quantity: el.miktar,
            ref: { ...el },
            type: el?.type,
        }));

        groups.forEach((group) => {
            ['uKasa', 'uPervaz', 'pervaz', 'kasa', 'kanat', 'diger'].forEach((category) => {
                group[category].forEach((item) => {
                    const matchedItem = tempArray.find((el) => el?.ref._id === item.ref._id);
                    if (matchedItem) matchedItem.quantity -= item.quantity;
                });
            });
        });

        // Yeni arrayi set etme
        setDemandItemsReduced(tempArray);

        const groupsSimplified = groups.map((group) => ({
            groupName: group.groupName,
            groupOrder: group.groupOrder,
            groupID: group._id,
            demand: group.demand,
            items: ['uKasa', 'uPervaz', 'pervaz', 'kasa', 'kanat', 'diger'].flatMap((category) => {
                return group[category].map((item) => {
                    return { ...item, type: category };
                });
            }),
        }));

        setGroupColumnsAndItems(groupsSimplified);
    }, [demand, groups]);

    // console.log('groupColumnsAndItems');
    // console.log(groupColumnsAndItems[1] ? groupColumnsAndItems[1].items : 'sasd');
    // console.log('groupColumnsAndItems');

    return { demandItemsReduced, groupColumnsAndItems };
};

export default useGroupItems;
