import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

import { calculateDiscount } from 'helpers/priceHelpers';

import { postDekontBaslik, postDekontKalem } from 'services/outside/aaro/aaroDekont';
import animationData from 'assets/animations/success2.json';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

function getStepContent(step) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        speed: 2.5,
    };

    switch (step) {
        case 0:
            return (
                <MDTypography variant="h6" color="white">
                    Başarılı{' '}
                </MDTypography>
            );
        case 1:
            return (
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <Lottie options={defaultOptions} height={150} width={150} />
                </MDBox>
            );
        case 2:
            return (
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <Lottie options={defaultOptions} height={150} width={150} />
                </MDBox>
            );
        default:
            return (
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <Lottie options={defaultOptions} height={150} width={150} />
                </MDBox>
            );
    }
}

export default function DekontCreationView(props) {
    const { products, dekontType, detail, customerID = 10468 } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = user.aaroInformation
        ? user.aaroInformation
        : {
              defaultWareHouseID: 11,
              defaultCompanyID: 1,
              defaultBranchID: 7,
              defaultUserID: -1,
          };
    //! Should be filled with user aaro info
    const currentDate = new Date().toISOString().split('T')[0];
    const dekontBaslik = {
        Baslik: {
            TipID: dekontType,
            BelgeNo: 'KHRMDEV' + Math.random().toString().slice(2, 11),
            Tarih: currentDate,
            Vade: currentDate,
            DepoID: aaro.defaultWareHouseID,
            SirketID: aaro.defaultCompanyID,
            SubeID: aaro.defaultBranchID,
        },
        KalemTek: {
            KalemTipi: 1,
            KartID: customerID,
            Tutar: 1,
            DovizID: 1,
            TutarDvz: 0,
            Aciklama: detail,
            Cari: {
                MuhtelifCariAdi: 'otomatik',
                MuhtelifVergiNo: Math.random().toString().slice(2, 12),
                MuhtelifVergiDairesi: 'otomatik',
                MuhtelifAdres: 'otomatik',
                MuhtelifTel: Math.random().toString().slice(2, 11),
            },
        },
    };

    const postAllDekontKalem = async (dekontID) => {
        for (let i = 0; i < products.length; i++) {
            const currentProduct = products[i];

            const currentKalem = {
                KalemTipi: 'SiparisStok',
                DekontID: dekontID,
                KartID: currentProduct.StokID,
                Miktar: currentProduct.Amount,
                Tutar: calculateDiscount(currentProduct.Price, currentProduct.Amount, currentProduct.Discount),
                DovizID: 1,
                TutarDvz: 0,
                Aciklama: '',
                SiparisStok: {
                    DepoID: aaro.defaultWareHouseID,
                    TeslimTarihi: new Date(),
                    BrmFiyatN: calculateDiscount(currentProduct.Price, currentProduct.Amount, currentProduct.Discount),
                    BrmFiyatB: currentProduct.Price,
                    BrutBirimFiyatManuelAtansin: true,
                    VergiDetaylari: [
                        {
                            VergiID: 1,
                            Oran: 20,
                        },
                    ],
                },
            };

            await postDekontKalem(currentKalem)
                .then((rp) => {
                    if (rp.data.Sonuc) {
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            await sleep(400).then(() => setActiveStep((prevActiveStep) => prevActiveStep + 1));
        }

        window.open(`https://erp2.aaro.com.tr/DekontFatura/Kalem?id=${dekontID}`, '_blank');
    };

    useEffect(() => {
        const createDekont = async () => {
            const productNames = products.map((product) => product.StokAdi);
            setSteps(['Dekont Başlık', ...productNames]);

            await postDekontBaslik(dekontBaslik)
                .then((rp) => {
                    if (rp.data.Sonuc) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        postAllDekontKalem(rp.data?.Model?.DekontID);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        createDekont();
    }, []);

    return (
        <>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <MDTypography variant="h6" color="white">
                                {getStepContent(index)}
                            </MDTypography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0}>
                    <MDTypography variant="h6">Başarıyla Kaydedildi </MDTypography>
                </Paper>
            )}
        </>
    );
}
