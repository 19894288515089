import React from 'react';
import { useQueryClient } from 'react-query';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// import Chip from '@mui/material/Divider';
import { updateCariIlgili, addCariIlgili } from 'services/outside/aaro/customer';
import { notify } from 'helpers/notificationHelper';
// const phoneRegExp = /^(\+?[1-9]\d{1,14}|0\d{9,14})$/;
const validationSchema = yup.object({
    IlgiliAdi: yup
        .string('İlgili Adını Giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(30, '30 karakterden az olmalıdır')
        .required('Lütfen geçerli bir ad giriniz'),
    Unvan: yup
        .string('İlgili Görevini Giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(20, '20 karakterden az olmalıdır')
        .required('Lütfen geçerli bir görev giriniz'),
    // Tel: yup.string().matches(phoneRegExp, 'Geçerli bir telefon numarası giriniz'),
    Email: yup.string().email(),
    Not1: yup.string('Kişinin Konumunu Giriniz').max(50, '50 karakterden az olmalıdır'),
    Not2: yup.string('Kişi Hakkında Düşünceleriniz').max(50, '50 karakterden az olmalıdır'),
});

export default function EditCustomerResponsible(props) {
    const { initial, setOpen, setUpdate, action } = props;
    const queryClient = useQueryClient();

    const [disableOnSubmit, setDisableOnSubmit] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            CariKodu: initial?.CariKodu || '',
            CariAdi: initial?.CariAdi || '',
            OnayDurum: initial?.OnayDurum || 1,
            OlsTar: initial?.OlsTar || null,
            DgsTar: initial?.DgsTar || null,
            OlsID: initial?.OlsID || null,
            OlsKodu: initial?.OlsKodu || '',
            OlsAdi: initial?.OlsAdi || '',
            DgsID: initial?.DgsID || null,
            DgsKodu: initial?.DgsKodu || '',
            DgsAdi: initial?.DgsAdi || '',
            EsnekAramaKisiti: initial?.EsnekAramaKisiti || '',
            IlgiliID: initial?.IlgiliID || null,
            IlgiliAdi: initial?.IlgiliAdi || '',
            CariID: initial?.CariID || null,
            Durum: initial?.Durum || true,
            Tel: initial?.Tel || '',
            Fax: initial?.Fax || null,
            Email: initial?.Email || '',
            Not1: initial?.Not1 || '',
            Not2: initial?.Not2 || '',
            Unvan: initial?.Unvan || null,
            Dogum: initial?.Dogum || '2021-01-01',
            Oncelik: initial?.Oncelik || true,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setDisableOnSubmit(true);
            setOpen(true);
            switch (action) {
                case 'add': {
                    addCariIlgili(values)
                        .then(() => {
                            queryClient.invalidateQueries('fetchAaroCustomerResponsibles');
                            notify(true);
                            setOpen(false);
                        })
                        .catch((err) => {
                            notify(false);
                            console.log(err);
                        });
                    break;
                }
                case 'update': {
                    updateCariIlgili(values)
                        .then(() => {
                            queryClient.invalidateQueries('fetchAaroCustomerResponsibles');
                            notify(true);
                            setOpen(false);
                        })
                        .catch((err) => {
                            notify(false);
                            console.log(err);
                        });
                    break;
                }
            }
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="IlgiliAdi"
                    name="IlgiliAdi"
                    label="İlgili Adı"
                    placeholder="Örn: Ahmet Yılmaz"
                    value={formik.values.IlgiliAdi}
                    onChange={formik.handleChange}
                    error={formik.touched.IlgiliAdi && Boolean(formik.errors.IlgiliAdi)}
                    helperText={formik.touched.IlgiliAdi && formik.errors.IlgiliAdi}
                />
                <TextField
                    fullWidth
                    id="Unvan"
                    name="Unvan"
                    label="Görevi"
                    placeholder="Örn: Satın Almacı"
                    value={formik.values.Unvan}
                    onChange={formik.handleChange}
                    error={formik.touched.Unvan && Boolean(formik.errors.Unvan)}
                    helperText={formik.touched.Unvan && formik.errors.Unvan}
                />
                <TextField
                    fullWidth
                    id="Tel"
                    name="Tel"
                    label="Telefon"
                    placeholder="Örn: 5554443322"
                    value={formik.values.Tel}
                    onChange={formik.handleChange}
                    error={formik.touched.Tel && Boolean(formik.errors.Tel)}
                    helperText={formik.touched.Tel && formik.errors.Tel}
                />
                <TextField
                    fullWidth
                    id="Email"
                    name="Email"
                    type="email"
                    label="İlgili Emaili"
                    placeholder="Örn: ahmetyilmaz@gmail.com"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    error={formik.touched.Email && Boolean(formik.errors.Email)}
                    helperText={formik.touched.Email && formik.errors.Email}
                />
                <TextField
                    fullWidth
                    id="Not1"
                    name="Not1"
                    label="Yetkisi"
                    placeholder="Örn: Projeyi tek başına onaylayabilir"
                    value={formik.values.Not1}
                    onChange={formik.handleChange}
                    error={formik.touched.Not1 && Boolean(formik.errors.Not1)}
                    helperText={formik.touched.Not1 && formik.errors.Not1}
                />
                <TextField
                    fullWidth
                    id="Not2"
                    name="Not2"
                    label="Kişiye Yorumunuz"
                    placeholder="Örn: İletişimi zor fakat çok dikkatli"
                    value={formik.values.Not2}
                    onChange={formik.handleChange}
                    error={formik.touched.Not2 && Boolean(formik.errors.Not2)}
                    helperText={formik.touched.Not2 && formik.errors.Not2}
                />
                <br />
                <br />
                <br />
                {/* <Toaster /> */}

                <Button color="primary" variant="contained" fullWidth type="submit" disabled={disableOnSubmit}>
                    Kaydet
                </Button>
            </form>
        </div>
    );
}
