import { useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import TemplateImage from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/TemplateImage';
import {
    KanatForm,
    KanatBadges,
} from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/KanatForm/index.js';
import {
    UKasaForm,
    UKasaBadges,
} from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/UKasaForm/index.js';
import {
    KasaForm,
    KasaBadges,
} from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/KasaForm/index.js';
import {
    PervazForm,
    PervazBadges,
} from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/PervazForm/index.js';
import {
    UPervazForm,
    UPervazBadges,
} from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/UPervazForm/index.js';
import { getSheetTemplate } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';
import { getParameters } from 'services/api/general/parameter';
import { parseStringNumber } from 'helpers/smallCodes';
import RetrievePriceAndNames from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/RetrievePriceAndNames';
import { SHEET_TYPES, SHEET_FIELDS } from './constants';

const fetchSheetTemplate = async (id, filteredDataParameters) => {
    const response = await getSheetTemplate(id, filteredDataParameters)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const fetchParameters = async (id) => {
    const response = await getParameters(``).then((rp) => (rp.data?.result ? rp.data?.result : []));
    return response;
};

const transformDataToInitialAndMatches = (data, dataParameters, sheetName) => {
    const initial = {};
    const matches = {};
    if (data) {
        dataParameters.forEach((param) => {
            const key = param.key.split('_').slice(1).join('_'); // İlk "_" işaretinden sonrasını al
            const sheetKey = param.value;
            const sheetValue = data[`${sheetName}!${sheetKey}`]?.[0]?.[0]; // [["100"]] -> "100"
            if (sheetValue) {
                initial[key] = parseStringNumber(sheetValue);
                matches[key] = sheetName + '!' + sheetKey;
            }
        });
    }
    return { initial, matches };
};

function SheetTemplate({ sheetTemplate, demandID, handleClose }) {
    const [lock, setLock] = useState(false);
    const queryClient = useQueryClient();
    const sheetName = sheetTemplate.name;

    const {
        data: dataParameters,
        isLoading: isLoadingParameters,
        error: errorParameters,
    } = useQuery(['fetchParameters'], fetchParameters, { staleTime: 600000 });

    const filteredDataParameters = useMemo(() => {
        if (!dataParameters) return [];

        const baseParameters = dataParameters
            .filter((el) => el?.tag?.name === sheetTemplate.type)
            .map((el) => `${sheetName}!${el.value}`);

        const additionalParameters = Object.values(SHEET_FIELDS[sheetTemplate.type] || {});
        return [...baseParameters, ...additionalParameters.map((param) => `${sheetName}!${param}`)];
    }, [dataParameters, sheetTemplate.type, sheetName]);

    const { data, isLoading, error, refetch } = useQuery(
        ['fetchSheetTemplate', sheetTemplate._id, filteredDataParameters],
        () => fetchSheetTemplate(sheetTemplate._id, filteredDataParameters),
        {
            staleTime: 600000,
            enabled: !!dataParameters,
        }
    );

    if (isLoadingParameters || isLoading) return <AaroLoadingScreen />;
    if (errorParameters || error) return `Bir Hata Oluştu: ${errorParameters?.message || error?.message}`;

    const { initial, matches } = transformDataToInitialAndMatches(data?.sheetData, dataParameters, sheetName);

    const renderForm = () => {
        const formProps = {
            googleSheetURL: sheetTemplate.url,
            initial,
            matches,
            refetch,
            lock,
            setLock,
        };

        switch (sheetTemplate.type) {
            case SHEET_TYPES.KANAT:
                return <KanatForm {...formProps} />;
            case SHEET_TYPES.U_KASA:
                return <UKasaForm {...formProps} />;
            case SHEET_TYPES.KASA:
                return <KasaForm {...formProps} />;
            case SHEET_TYPES.PERVAZ:
                return <PervazForm {...formProps} />;
            case SHEET_TYPES.U_PERVAZ:
                return <UPervazForm {...formProps} />;
            default:
                return null;
        }
    };

    const renderBadges = () => {
        switch (sheetTemplate.type) {
            case SHEET_TYPES.KANAT:
                return <KanatBadges initial={initial} />;
            case SHEET_TYPES.U_KASA:
                return <UKasaBadges initial={initial} />;
            case SHEET_TYPES.KASA:
                return <KasaBadges initial={initial} />;
            case SHEET_TYPES.PERVAZ:
                return <PervazBadges initial={initial} />;
            case SHEET_TYPES.U_PERVAZ:
                return <UPervazBadges initial={initial} />;
            default:
                return null;
        }
    };

    return (
        <MDBox py={3}>
            <Grid container justifyContent="center" sx={{ height: '100%', mt: 1 }}>
                <Grid item xs={12} md={3} lg={3}>
                    <MDBox p={3}>
                        <TemplateImage coverImage={sheetTemplate?.coverImage} sheetURL={sheetTemplate?.url} />
                        {renderBadges()}
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <MDBox
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        height="100%"
                    >
                        {renderForm()}
                        <RetrievePriceAndNames
                            queryClient={queryClient}
                            handleClose={handleClose}
                            demandID={demandID}
                            templateImage={sheetTemplate.coverImage}
                            type={sheetTemplate.type}
                            sheetName={sheetName}
                            sheetData={data?.sheetData}
                            lock={lock}
                            setLock={setLock}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default SheetTemplate;
