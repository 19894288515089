import * as React from 'react';

import { matchSorter } from 'match-sorter';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {
	DataGridPro,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import pxToRem from 'assets/theme/functions/pxToRem.js';
import MDBox from 'components/MDBox';

import PricePopover from 'layouts/aaro/fastPrice/views/pricePopover';
import StockPopover from 'layouts/aaro/fastPrice/views/stockPopover';

import AddToBasket from '../../components/AddToBasket';
import { getProducts } from 'services/api/product';

import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';

const fetchStocksForWareHouse = async () => {
	const response = await getProducts().then((rp) => rp.data.result);
	return response;
};

const defaultTheme = createTheme();
const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				padding: theme.spacing(0.5, 0.5, 0),
				justifyContent: 'space-between',
				display: 'flex',
				alignItems: 'flex-start',
				flexWrap: 'wrap',
			},
			textField: {
				[theme.breakpoints.down('xs')]: {
					width: '100%',
				},
				margin: theme.spacing(1, 0.5, 1.5),
				'& .MuiSvgIcon-root': {
					marginRight: theme.spacing(0.5),
				},
				'& .MuiInput-underline:before': {
					borderBottom: `1px solid ${theme.palette.divider}`,
				},
			},
		}),
	{ defaultTheme }
);

function QuickSearchToolbar(props) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div>
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
			</div>
			<TextField
				variant="standard"
				value={props.value}
				onChange={props.onChange}
				placeholder="Ara..."
				className={classes.textField}
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{
								visibility: props.value ? 'visible' : 'hidden',
							}}
							onClick={props.clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

export default function QuickFilteringGrid(props) {
	// const { stocks } = props;
	const [searchText, setSearchText] = React.useState('');
	const [rows, setRows] = React.useState([]);

	const {
		data: stocks,
		isLoading,
		error,
		isFetching,
	} = useQuery('fetchAllProducts', () => fetchStocksForWareHouse(), {
		staleTime: 1800000,
		cacheTime: 14400000,
	});

	React.useEffect(() => setRows(stocks), [stocks]);
	if (isLoading) return <AaroLoadingScreen />;
	if (isFetching) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;
	stocks.forEach((el) => (el.id = el.productID));

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);
		const filteredRows = matchSorter(stocks, searchValue, {
			keys: [...Object.keys(stocks[0])],
		});

		setRows(filteredRows);
	};

	const columns = [
		{
			field: 'sku',
			headerName: 'StokKodu',
			width: 150,
		},
		{
			field: 'name',
			headerName: 'StokAdi',
			width: 450,
		},

		{
			field: 'Fiyat',
			headerName: 'Fiyat/Stok',
			width: 120,
			renderCell: (params) => (
				<MDBox
					display="flex"
					justifyContent="space-around"
					alignItems="center"
				>
					<MDBox p={1}>
						<PricePopover
							stockID={params.row.id}
							stockName={params.row.name}
							stockCode={params.row.sku}
						/>
					</MDBox>

					<MDBox p={1}>
						<StockPopover stockID={params.row.id} />
					</MDBox>
				</MDBox>
			),
			editable: true,
		},
		{
			field: 'Sepet',
			headerName: 'Sepete Ekle',
			width: 120,
			renderCell: (params) => (
				<AddToBasket
					stockID={params.row.id}
					sku={params.row.sku}
					stockName={params.row.name}
				/>
			),
			editable: true,
		},
	];

	return (
		<MDBox
			m={3}
			color="white"
			bgColor="white"
			variant="gradient"
			borderRadius="lg"
			shadow="lg"
			opacity={1}
			sx={{ height: '600px', width: '%100' }}
		>
			<DataGridPro
				// autoHeight
				// autoPageSize
				components={{ Toolbar: QuickSearchToolbar }}
				initialState={{
					pinnedColumns: { right: ['Fiyat', 'Sepet'] },
				}}
				rows={rows}
				columns={columns}
				disableColumnFilter
				disableDensitySelector
				componentsProps={{
					toolbar: {
						value: searchText,
						onChange: (event) => requestSearch(event.target.value),
						clearSearch: () => requestSearch(''),
					},
				}}
				sx={{
					fontSize: pxToRem(12),
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					lineHeight: 1.25,
				}}
			/>
		</MDBox>
	);
}
