

import { useState, useEffect } from 'react';

// react-router components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
// import MDInput from 'components/MDInput';
// import MDBadge from 'components/MDBadge';

// Material Dashboard 2 PRO React example components
// import Breadcrumbs from 'examples/Breadcrumbs';
import NotificationItem from 'examples/Items/NotificationItem';

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	// navbarIconButton,
	// navbarDesktopMenu,
	// navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 PRO React context
import {
	useMaterialUIController,
	setTransparentNavbar,
	// setMiniSidenav,
	// setOpenConfigurator,
} from 'context';

function DashboardNavbar({ absolute, light, isMini }) {
	const [navbarType, setNavbarType] = useState();
	const [controller, dispatch] = useMaterialUIController();
	const {
		// miniSidenav,
		transparentNavbar,
		fixedNavbar,
		// openConfigurator,
		darkMode,
	} = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split('/').slice(1);

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType('sticky');
		} else {
			setNavbarType('static');
		}

		// A function that sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(
				dispatch,
				(fixedNavbar && window.scrollY === 0) || !fixedNavbar
			);
		}

		/** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
		// ! commented out because it causes multiple render
		// window.addEventListener('scroll', handleTransparentNavbar);

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar();

		// Remove event listener on cleanup
		return () =>
			window.removeEventListener('scroll', handleTransparentNavbar);
	}, [dispatch, fixedNavbar]);

	// const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	// const handleConfiguratorOpen = () =>
	// 	setOpenConfigurator(dispatch, !openConfigurator);
	// const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);

	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
		>
			<NotificationItem
				icon={<Icon>email</Icon>}
				title="Check new messages"
			/>
			<NotificationItem
				icon={<Icon>podcasts</Icon>}
				title="Manage Podcast sessions"
			/>
			<NotificationItem
				icon={<Icon>shopping_cart</Icon>}
				title="Payment successfully completed"
			/>
		</Menu>
	);

	// Styles for the navbar icons
	// const iconsStyle = ({
	// 	palette: { dark, white, text },
	// 	functions: { rgba },
	// }) => ({
	// 	color: () => {
	// 		let colorValue = light || darkMode ? white.main : dark.main;

	// 		if (transparentNavbar && !light) {
	// 			colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
	// 		}

	// 		return colorValue;
	// 	},
	// });

	return (
		<AppBar
			position={absolute ? 'absolute' : navbarType}
			color="inherit"
			sx={(theme) =>
				navbar(theme, { transparentNavbar, absolute, light, darkMode })
			}
		>
			<Toolbar sx={(theme) => navbarContainer(theme)}>
				<MDBox
					color="inherit"
					mb={{ xs: 1, md: 0 }}
					sx={(theme) => navbarRow(theme, { isMini })}
				></MDBox>
				{isMini ? null : (
					<MDBox sx={(theme) => navbarRow(theme, { isMini })}>
						<MDBox color={light ? 'white' : 'inherit'}>
							{renderMenu()}
						</MDBox>
					</MDBox>
				)}
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
};

export default DashboardNavbar;
