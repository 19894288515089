import { Fragment } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

// @mui material components
import { Box, TextField } from '@mui/material';

export default function index({ dateRange, updateDateRange }) {
    const dateFormatter = (date) => {
        const formattedDate = date.format('DD/MM/YYYY');
        console.log(formattedDate);
        console.log(date);
        return formattedDate;
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} dateFormatter={dateFormatter}>
            <DateRangePicker
                value={dateRange}
                onChange={(newValue) => updateDateRange(newValue)}
                renderInput={(startProps, endProps) => (
                    <Fragment>
                        <TextField {...startProps} label="Başlangıç Tarihi" />
                        <Box sx={{ mx: 2 }}> - </Box>
                        <TextField {...endProps} label="Bitiş Tarihi" />
                    </Fragment>
                )}
            />
        </LocalizationProvider>
    );
}
