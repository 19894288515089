import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function FormSelect(props) {
    const { label, placeHolder, type, formik, itemID, step, adornment, disabled, variant = 'outlined' } = props;

    return (
        <MDBox m={1}>
            <FormControl
                sx={{
                    width: '100%',
                    minWidth: 120,
                }}
                variant={variant}
            >
                <OutlinedInput
                    id={itemID}
                    name={itemID}
                    placeholder={placeHolder}
                    type={type}
                    value={formik.values[itemID]}
                    onChange={formik.handleChange}
                    error={formik.touched[itemID] && Boolean(formik.errors[itemID])}
                    helperText={formik.touched[itemID] && formik.errors[itemID]}
                    endAdornment={
                        <InputAdornment position="end">
                            <MDTypography variant="overline" align="center">
                                {adornment}
                            </MDTypography>
                        </InputAdornment>
                    }
                    disabled={disabled}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': itemID,
                        step: step,
                    }}
                    labelWidth={0}
                    sx={{
                        minHeight: '44px',
                    }}
                />
                <FormHelperText>
                    <MDTypography variant="caption" align="center">
                        {label}
                    </MDTypography>
                </FormHelperText>
            </FormControl>
        </MDBox>
    );
}
