import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const ColorButton = styled(Button)(({ theme, precolor, hovercolor }) => ({
    color: theme.palette.getContrastText(precolor),
    backgroundColor: precolor,
    '&:hover': {
        backgroundColor: hovercolor,
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Inter, sans-serif',
        height: 32,
        color: 'white',
        alignSelf: 'center'
    },
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    height: 32,
    padding: 4,
    alignSelf: 'center',
    textTransform: 'none'
}));
