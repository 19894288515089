import React, { useEffect, memo, useState } from 'react';
import { useQuery } from 'react-query';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import { getChat } from 'services/api/general/chat';
import { getFiles } from 'services/api/general/file';

import MDBadge from 'components/MDBadge';

const fetchTodoMessages = async (relatedID) => {
    const response = await getChat(relatedID).then((rp) => rp.data.result);
    return response;
};
const fetchTodoFiles = async (relatedID) => {
    const response = await getFiles(relatedID).then((rp) => rp.data.result);
    return response;
};

export default function TodoSummary(props) {
    const { taskID, completed, setLoading, taskDueTime } = props;

    return (
        <MDBox display="flex">
            <MDBox mr={1}>
                <GetLatestChatMessage taskID={taskID} completed={completed} setLoading={setLoading} />
            </MDBox>
            <MDBox>
                <GetFiles taskID={taskID} completed={completed} />
            </MDBox>
            <MDBox>
                <GetRemainingTime taskDueTime={taskDueTime?.dateTime} completed={completed} />
            </MDBox>
        </MDBox>
    );
}
const GetLatestChatMessage = memo((props) => {
    const { taskID, completed, setLoading } = props;

    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchChatMessages', taskID],
        () => fetchTodoMessages(taskID),
        { staleTime: 60 * 1000 }
    );
    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        // Gecikme süresini 2000 milisaniye (2 saniye) olarak ayarlıyoruz
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        // Eğer bu useEffect'in bağımlılıkları güncellenirse veya component unmount edilirse, timer'ı iptal ediyoruz.
        return () => clearTimeout(timer);
    }, [data]);
    if (isLoading) return '';

    if (isFetching) return '';

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        data &&
        data.length > 0 && (
            <MDBadge
                badgeContent={data.length + ' mesaj'}
                size="xs"
                container
                color={completed ? 'secondary' : 'info'}
            />
        )
    );
});

const GetFiles = memo((props) => {
    const { taskID, completed } = props;

    const { data, isLoading, error, isFetching } = useQuery(['fetchTodoFiles', taskID], () => fetchTodoFiles(taskID), {
        staleTime: 60 * 1000,
    });

    if (isLoading) return '';

    if (isFetching) return '';

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return data && data.length > 0 ? (
        <MDBadge
            badgeContent={data.length + ' dosya'}
            size="xs"
            container
            color={completed ? 'secondary' : 'warning'}
        />
    ) : (
        ''
    );
});

const GetRemainingTime = (props) => {
    const { taskDueTime, completed } = props;

    let remainingTime = '';
    let color = 'primary';
    const currentTime = new Date();
    const dueTime = new Date(taskDueTime);

    const diffInMilliseconds = dueTime - currentTime;

    if (diffInMilliseconds > 0) {
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInHours > 48) color = 'secondary';

        remainingTime = `${diffInDays} GÜN ${diffInHours % 24} saat ${diffInMinutes % 60} DAKİKA`;
    } else {
        remainingTime = 'Görevin Zamanı Geçmiş';
    }

    if (!remainingTime) return '';

    if (taskDueTime == null) return '';

    return !completed && <MDBadge badgeContent={remainingTime} size="xs" container color={color} />;
};
