// Libraries
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';

// Layout & Components
import Layout from 'layouts/purchases/pages/singleLead/Layout.js';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import MainPage from 'layouts/purchases/pages/singleLead/Sections/Main/index.js';
import AaroLoadingScreen from 'components/Loading';
import Chat from 'components/General/Chat';
import FileView from 'components/General/File';
import TaskListModalView from 'components/General/Task/Views/TaskList/ModalView';
import MDButton from 'components/MDButton';

// Hooks & Services
import useDialogHook from 'hooks/useDialogHook';
import { getPurchase } from 'services/api/purchases';
import { config } from 'Constants';
// import FileView from 'components/General/File';
import AddMultiFile from 'components/General/File/MultiFile.js';
// Components
import EditPurchase from 'layouts/purchases/forms/EditPurchase.js';
import EditShipments from 'layouts/purchases/forms/EditShipments.js';
import EditFeedback from 'layouts/purchases/forms/EditFeedback.js';

import { getWooOrders } from 'services/outside/woo/orders.js';
import { getParameters } from 'services/api/general/parameter';
import MDTypography from 'components/MDTypography';

const fetchPurchase = async (purchaseID) => {
    try {
        const response = await getPurchase(purchaseID);
        return response.data.result;
    } catch (error) {
        throw error;
    }
};

const fetchParameters = async (keys) => {
    const queryString = keys
        .split(',')
        .map((key) => `key=${key}`)
        .join('&');
    const response = await getParameters(queryString).then((rp) => (rp.data?.result ? rp.data?.result : []));
    return response;
};

function SinglePurchasePage({ incomingID, isModal = false, wooCommerceOrderDetails }) {
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const {
        open: openShipments,
        handleClickOpen: handleClickOpenShipments,
        handleClose: handleCloseShipments,
    } = useDialogHook();
    const {
        open: openFeedback,
        handleClickOpen: handleClickOpenFeedback,
        handleClose: handleCloseFeedback,
    } = useDialogHook();
    const { id } = useParams();
    const [value, setValue] = useState(0);
    const [updateFiles, setUpdateFiles] = useState(0);
    const [wooOrders, setWooOrders] = useState(wooCommerceOrderDetails || null);
    const [retryCount, setRetryCount] = useState(0);

    const purchaseID = incomingID || id;

    const paramQuery = 'api_woo_url,api_woo_cs,api_woo_ck';
    const {
        data: parametersData,
        isLoading: parametersIsLoading,
        error: parametersError,
    } = useQuery(['fetchParameters', paramQuery], () => fetchParameters(paramQuery), {
        staleTime: 600000,
        retry: 2,
        onError: (error) => {
            console.error('Parameters fetch error:', error);
        },
    });

    const {
        data: purchase,
        isLoading: purchaseIsLoading,
        error: purchaseError,
        refetch: refetchPurchase,
    } = useQuery(['purchase', purchaseID], () => fetchPurchase(purchaseID), {
        refetchOnWindowFocus: false,
        enabled: !!purchaseID,
        staleTime: 30000,
        cacheTime: 60000,
        retry: 2,
    });

    useEffect(() => {
        const fetchWooOrders = async () => {
            if (!wooCommerceOrderDetails && parametersData && purchase) {
                try {
                    const wooParams = parametersData.reduce((acc, param) => {
                        acc[param.key] = param.value;
                        return acc;
                    }, {});

                    const response = await getWooOrders(
                        wooParams.api_woo_ck,
                        wooParams.api_woo_cs,
                        wooParams.api_woo_url,
                        { search: purchaseID }
                    );
                    setWooOrders(response.data[0]);
                } catch (error) {
                    console.error('Error fetching WooCommerce orders:', error);
                }
            }
        };

        fetchWooOrders();
    }, [wooCommerceOrderDetails, parametersData, purchase, purchaseID]);

    useEffect(() => {
        if (purchase && !purchase.taskList && retryCount < 3) {
            const timer = setTimeout(() => {
                setRetryCount((prev) => prev + 1);
                refetchPurchase();
            }, 1000); // 1 saniye bekleyip tekrar dene

            return () => clearTimeout(timer);
        }
    }, [purchase, retryCount, refetchPurchase]);

    if (purchaseIsLoading || parametersIsLoading) return <AaroLoadingScreen />;
    if (parametersError) return <h1>Parametre yüklenirken hata oluştu: {parametersError.message}</h1>;
    if (purchaseError) return <h1>Satın alma bilgisi yüklenirken hata oluştu: {purchaseError.message}</h1>;
    if (!purchaseID) return <h1>Geçersiz Satın Alma ID'si</h1>;
    if (!purchase) return <h1>Satın Alma Bulunamadı</h1>;

    const user = JSON.parse(localStorage.getItem('user'));
    const { role } = user;
    return (
        <Layout setValue={setValue} isModal={isModal}>
            <MDBox mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={value === 2 ? 12 : 9}>
                        <MDBox mb={3}>
                            <MainPage
                                purchase={purchase}
                                relatedID={purchaseID}
                                updateFiles={updateFiles}
                                setUpdateFiles={setUpdateFiles}
                                wooCommerceOrderDetails={wooOrders || wooCommerceOrderDetails}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <MDBox mb={3} mt={4}>
                            <Chat relatedID={purchaseID} source="purchases" />
                        </MDBox>

                        {purchase?.taskList && (
                            <MDBox mb={3} bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1}>
                                <TaskListModalView
                                    taskListID={purchase?.taskList}
                                    taskID={null}
                                    url={`${config.url.ENV_URL}/purchases/${purchaseID}`}
                                />
                            </MDBox>
                        )}
                        <MDBox mb={3} display="flex" gap={2}>
                            <MDButton
                                color="info"
                                variant="gradient"
                                sx={{ width: '100%' }}
                                onClick={handleClickOpenShipments}
                            >
                                KARGO GİRİŞİ
                            </MDButton>
                            <MDButton
                                color="success"
                                variant="gradient"
                                sx={{ width: '100%' }}
                                onClick={handleClickOpenFeedback}
                            >
                                GERİ BİLDİRİM
                            </MDButton>
                        </MDBox>
                        <MDBox
                            bgColor="secondary"
                            variant="gradient"
                            borderRadius="lg"
                            shadow="lg"
                            opacity={1}
                            p={2}
                            mb={3}
                        >
                            {purchase?.shipments && purchase.shipments.length > 0 && (
                                <MDBox>
                                    <MDTypography variant="caption" mb={1} fontWeight="bold" color="white">
                                        Kargo Bilgileri
                                    </MDTypography>
                                    {purchase.shipments.map((shipment, index) => (
                                        <MDBox key={index} mb={1}>
                                            {shipment.shippingMethod && (
                                                <MDTypography variant="overline" color="white">
                                                    Kargo Firması: {shipment.shippingMethod}
                                                </MDTypography>
                                            )}
                                            <br />
                                            {shipment.trackingNumber && (
                                                <MDTypography variant="overline" color="white">
                                                    Takip No: {shipment.trackingNumber}
                                                </MDTypography>
                                            )}
                                            <br />
                                            {shipment.shippingCost > 0 && (
                                                <MDTypography variant="overline" color="white">
                                                    Kargo Ücreti: {shipment.shippingCost} TL
                                                </MDTypography>
                                            )}
                                            <Divider light sx={{ my: 2 }} />
                                        </MDBox>
                                    ))}
                                </MDBox>
                            )}

                            {(purchase?.customerRating || purchase?.customerFeedback) && (
                                <MDBox>
                                    <MDTypography variant="caption" mb={1} fontWeight="bold" color="white">
                                        Müşteri Geri Bildirimi
                                    </MDTypography>
                                    <MDBox mb={1}>
                                        {purchase.customerRating && (
                                            <MDTypography variant="overline" color="white">
                                                Değerlendirme: {purchase.customerRating}/5
                                            </MDTypography>
                                        )}
                                        <br />
                                        {purchase.customerFeedback && (
                                            <MDTypography variant="overline" color="white">
                                                Yorum: {purchase.customerFeedback}
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </MDBox>
                            )}
                        </MDBox>
                        <MDBox
                            mb={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                        >
                            <AddMultiFile
                                relatedID={purchaseID}
                                additionalName={`woosiparis-${purchaseID}`}
                                filePath={`purchases/${purchaseID}`}
                                setUpdateFiles={setUpdateFiles}
                                source="purchases"
                            />
                        </MDBox>
                        <MDBox mb={3}>
                            <FileView
                                relatedID={purchaseID}
                                updateFiles={updateFiles}
                                setUpdateFiles={setUpdateFiles}
                            />
                        </MDBox>

                        <MDBox mb={3} display="flex" gap={2}>
                            {role === 'superadmin' && (
                                <MDButton
                                    color="dark"
                                    variant="gradient"
                                    sx={{ width: '100%' }}
                                    onClick={handleClickOpen}
                                >
                                    Düzenle
                                </MDButton>
                            )}
                        </MDBox>

                        {open && <EditPurchase open={open} handleClose={handleClose} initial={purchase} />}
                        {openShipments && (
                            <EditShipments open={openShipments} handleClose={handleCloseShipments} initial={purchase} />
                        )}
                        {openFeedback && (
                            <EditFeedback open={openFeedback} handleClose={handleCloseFeedback} initial={purchase} />
                        )}
                    </Grid>
                </Grid>
            </MDBox>
        </Layout>
    );
}

export default SinglePurchasePage;
