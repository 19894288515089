import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { updateProject } from 'services/api/project';

export default function ProjectActivation(props) {
	const { project } = props;

	const [checked, setChecked] = useState(true);

	const updateActivation = async () => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user.role == 'superadmin') {
			updateProject(project._id, { isActive: !checked });
			setChecked(!checked);
		}
	};

	useEffect(() => {
		setChecked(project.isActive);
	}, [props]);

	return (
		<div>
			{/* Ham Zımpara*/}
			<FormControlLabel
				control={
					<Switch
						checked={checked}
						onChange={() => updateActivation()}
						name="settings"
						color="secondary"
					/>
				}
				label="Proje Aktivasyonu"
			/>
		</div>
	);
}
