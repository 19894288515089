/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
// services components
import { postChat, getChat } from 'services/api/general/chat';

import DisplayMessages from './Components/DisplayMessages';
import MDBox from 'components/MDBox';
import Box from '@mui/material/Box';
import { useMaterialUIController } from 'context';

import { config } from 'Constants';
// import Typing from './Typing';

import { channel } from 'pusher.js';

const Chat = (props) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { relatedID, source = '' } = props;

    const [chatMessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [refreshChat, setRefreshChat] = useState(false);
    const scrollRef = useRef(null);
    useEffect(() => {
        const fetchChats = async () => {
            getChat(relatedID).then((rp) => setChatMessages(rp.data.result));

            channel.bind('my-event', (data) => {
                getChat(relatedID).then((rp) => {
                    setChatMessages(rp.data.result);
                    if (scrollRef.current) {
                        scrollRef.current.scrollIntoView({
                            behaviour: 'smooth',
                            block: 'nearest',
                            inline: 'start',
                        });
                    }
                });
            });
        };
        fetchChats();
    }, [refreshChat]);

    async function handleSubmit(event) {
        event.preventDefault();
        setMessage('');
        try {
            postChat({ message, relatedID }, source);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <MDBox mt={1} bgColor={darkMode ? 'dark' : 'white'} borderRadius="xl" shadow="lg">
            <DisplayMessages
                messages={chatMessages}
                scrollRef={scrollRef}
                setRefreshChat={setRefreshChat}
                source={source}
            />

            <MDBox m={1} pb={1}>
                <Grid container>
                    <Grid item xs={10}>
                        <MDBox ml={0.5} mr={0.5}>
                            <TextField
                                id="outlined-basic-email"
                                label="Bir mesaj yazın"
                                fullWidth
                                value={message}
                                multiline
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={2} align="right">
                        <MDBox mr={1} mt={0.5}>
                            <Fab
                                onClick={handleSubmit}
                                aria-label="add"
                                size="small"
                                color="primary"
                                sx={{ backgroundColor: '#42424a' }}
                            >
                                <SendIcon />
                            </Fab>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
};

export default Chat;
