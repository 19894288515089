import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { config } from 'Constants';
import LineSkeleton from 'components/Loading/LineSkeleton';
import Autocomplete from '@mui/material/Autocomplete';
import FormField from 'layouts/pages/account/components/FormField';
import MDTypography from 'components/MDTypography';

import Divider from '@mui/material/Divider';

import axios from 'axios';
import PriceDetail from 'components/Aaro/PriceCheck/components/PriceDetail';
import ItemDetail from 'components/Aaro/PriceCheck/components/ItemDetail';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 500,
	maxHeight: 800,
	width: '100%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	overflowY: 'scroll',
};

function AaroStockPrice({ open, setOpen, item }) {
	const navigate = useNavigate();

	const [value, setValue] = React.useState('');
	const [finalValue, setFinalValue] = React.useState('');
	const [stockID, setStockID] = React.useState(null);
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: `Bearer ${user.aaroToken}`,
		Accept: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded',
	};

	const {
		data: aaro,
		isLoading,
		error,
	} = useQuery(
		['aaroStok', value],
		({ signal }) =>
			axios.post(
				`${config.url.AARO_BASE}/api/Rehber/Secenekler?RehberID=RehberStok&Veri=${value}`,
				{},
				{
					headers,
					signal,
				}
			),
		{ enabled: Boolean(value) }
	);
	const handleSelection = (event, newValue) => {
		event.preventDefault();
		const productID = newValue.split('-')[1];
		setStockID(productID);
		setFinalValue(newValue);
	};

	// if (isLoading) return <LoadingAnimation />;
	// if (isFetching) return <LoadingAnimation />;

	// if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<MDBox sx={style}>
					<ItemDetail item={item} />
					<Autocomplete
						defaultValue=""
						value={finalValue}
						onChange={handleSelection}
						filterOptions={(x) => x}
						options={
							aaro?.data?.Model
								? aaro.data.Model.map((el) =>
										(
											el.Text.replaceAll(
												'-',
												' '
											).substring(
												0,
												el.Text.lastIndexOf('(')
											) +
											'-' +
											el.ID
										).trim()
								  )
								: []
						}
						renderInput={(params) => (
							<FormField
								{...params}
								required
								label="Ürün Adı"
								InputLabelProps={{ shrink: true }}
								value={value}
								onChange={(e) => setValue(e.target.value)}
							/>
						)}
					/>

					{isLoading ? (
						<LineSkeleton width={420} line={10} />
					) : (
						stockID && (
							<MDBox mt={3}>
								<PriceDetail stockID={stockID} />
							</MDBox>
						)
					)}
				</MDBox>
			</Fade>
		</Modal>
	);
}

export default AaroStockPrice = React.memo(AaroStockPrice);
