import { useState, createContext } from 'react';
import Layout from 'layouts/lead/pages/allLeads/layout/index.js';
import { getLeads } from 'services/api/lead';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';
import MDBox from 'components/MDBox';
import { getTags } from 'services/api/general/tag';
import DraggableList from 'layouts/lead/pages/allLeads/DraggableList';
import { config } from 'Constants';
import Paper from '@mui/material/Paper';
import LeadsMap from 'layouts/lead/pages/allLeads/LeadsMap';
export const AllLeadsContext = createContext();

const { LEAD_CATEGORY_GROUP_ID } = config.fixedID;

const fetchLeads = async (showActiveLeads, search) => {
    const searchQuery = {
        $or: [{ name: { $regex: search, $options: 'i' } }, { description: { $regex: search, $options: 'i' } }],
        isActive: showActiveLeads,
    };

    const response = await getLeads(searchQuery)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) => (rp.data?.result?.tags ? rp.data?.result?.tags : []));
    return response;
};

// Verilen lead ve tag verilerini kanban veri yapısına dönüştürür
function convertLeadAndTagDataToKanbanData(leads, tags) {
    const result = {
        empty: { name: 'Grupsuz', items: [] },
    };

    tags.sort((a, b) => a.order - b.order).forEach((element) => {
        result[element._id] = {
            name: element.name,
            items: [],
            columnDetail: element,
        };
    });

    leads.forEach((lead) => {
        const matchingTag = tags.find((tag) => tag._id === lead?.category?._id);
        if (matchingTag) {
            result[matchingTag._id].items.push({
                id: lead._id,
                name: lead.name,
                content: lead,
            });
        } else {
            result.empty.items.push({
                id: lead._id,
                name: lead.name,
                content: lead,
            });
        }
    });

    return result;
}
// Tüm lead'leri gösteren ana bileşen
export default function AllLeads() {
    // Aktif lead'leri gösterip göstermeme durumunu kontrol eden state
    const [showActiveLeads, setShowActiveLeads] = useState(true);
    // Arama metnini tutan state
    const [search, setSearch] = useState('');

    // Lead'leri çeken query
    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchLeads', showActiveLeads, search],
        () => fetchLeads(showActiveLeads, search),
        { refetchOnWindowFocus: false }
    );

    // Tag'leri çeken query
    const {
        data: tagData,
        isLoading: tagIsLoading,
        error: tagError,
        isFetching: tagIsFetching,
    } = useQuery(['fetchTagGroups', LEAD_CATEGORY_GROUP_ID], () => fetchTags(LEAD_CATEGORY_GROUP_ID), {
        staleTime: 600000,
    });

    // Eğer veri yükleniyorsa veya yeniden çekiliyorsa, yüklenme ekranını göster
    if (isLoading || tagIsLoading || isFetching || tagIsFetching) return <AaroLoadingScreen />;
    // Eğer bir hata varsa, hatayı göster
    if (error || tagError) return 'Bir Hata Oluştu: ' + (error?.message || tagError?.message);

    // Lead ve tag verilerini kanban veri yapısına dönüştür
    const kanbanData = convertLeadAndTagDataToKanbanData(data, tagData);

    // Bileşeni render et
    return (
        <AllLeadsContext.Provider value={{ setShowActiveLeads, showActiveLeads, search, setSearch }}>
            <Layout>
                <MDBox opacity={1} px={2}>
                    <Paper elevation={3} sx={{ height: 450, overflow: 'hidden' }}>
                        <LeadsMap data={data} />
                    </Paper>
                    <DraggableList data={kanbanData} />
                </MDBox>
            </Layout>
        </AllLeadsContext.Provider>
    );
}
