import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import MDButton from 'components/MDButton';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import { notify } from 'helpers/notificationHelper';

// Ürün önerisini göndermek için API servisini ekleyin.
import { postProductSuggestion } from 'services/api/productSuggestion';

const validationSchema = yup.object({
    name: yup.string('Enter the product name').required('Ürün adı girilmelidir'),
    note: yup.string('Enter the suggestion note'),
    imageLinks: yup.array().of(yup.string().url('Enter a valid URL')),
});
export default function ProductSuggestionInput(props) {
    const { open, handleClose } = props;
    const queryClient = useQueryClient();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const [imageLinks, setImageLinks] = useState([]);

    const formik = useFormik({
        initialValues: {
            name: '',
            note: '',
            imageLinks: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            try {
                setDisableOnSubmit(true);
                postProductSuggestion({ ...values, imageLinks: imageLinks }).then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries('fetchProductSuggestions');
                    handleClose();
                });
            } catch (err) {
                notify(false, JSON.stringify(err));
            }
        },
    });

    const handleAddImageLink = () => {
        const newImageLinks = [...imageLinks, ''];
        setImageLinks(newImageLinks);
    };

    const handleImageLinkChange = (event, index) => {
        const newImageLinks = [...imageLinks];
        newImageLinks[index] = event.target.value;
        setImageLinks(newImageLinks);
    };
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
            <Card>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3} p={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Başlık"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="note"
                                label="Not"
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        {imageLinks.map((link, index) => (
                            <Grid item xs={12} key={index}>
                                <TextField
                                    fullWidth
                                    id={`imageLink-${index}`}
                                    name={`imageLink-${index}`}
                                    label={`Image Link ${index + 1}`}
                                    value={link}
                                    onChange={(event) => handleImageLinkChange(event, index)}
                                    error={formik.touched.imageLinks && Boolean(formik.errors.imageLinks)}
                                    helperText={formik.touched.imageLinks && formik.errors.imageLinks}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <MDButton variant="gradient" color={'secondary'} onClick={handleAddImageLink}>
                                {' '}
                                Görsel Ekle
                            </MDButton>
                        </Grid>
                        <Grid item xs={12}>
                            <MDButton
                                color="success"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={disableOnSubmit}
                            >
                                Submit
                            </MDButton>
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </Dialog>
    );
}
