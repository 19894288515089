// @mui material components
import { memo, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import MDButton from 'components/MDButton';
import Icon from '@mui/material/Icon';
import DefaultStatisticsCard from 'examples/Cards/StatisticsCards/DefaultStatisticsCard';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// Images
import logo from 'assets/images/projects/kahramanlar.png';
// import aa from 'search-insights';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { getStockSummary } from 'services/outside/aaro/stocks.js';
import { getStocks } from 'services/outside/aaro/stocks';
import ProductSummary from 'layouts/orders/pages/SingleOrder/MainPage/components/ProductSummary.js';
const fetchAllStockAmountsForSingleStock = async (StokID) => {
	const response = await getStocks({
		StokID,
	});
	return response;
};

const fetchStockSummary = async (query) => {
	const response = await getStockSummary(query).then((rp) => rp?.data?.Model);
	return response;
};

function SingleItem(props) {
	const { hit, insights, key, product } = props;

	const { StokID, StokAdi, Miktar, BirimAdi, Tutar, DovizSembol } = product;
	const [activeBranch, setActiveBranch] = useState(0);
	const [show, setShow] = useState(false);
	const [summary, setSummary] = useState({});
	const [branchName, setBranchName] = useState('');
	const {
		data: StockSummary,
		isLoading,
		error,
		isFetching,
	} = useQuery(
		['fetchStockSummary', StokID],
		() =>
			fetchStockSummary({
				StokID: StokID,
				// SiralamaKisiti: 'OlsTar:Desc',
				// Durum: true,
			}),
		{ refetchOnWindowFocus: false, staleTime: 60000 }
	);

	const {
		data: stockAmount,
		isLoading: stockAmountIsLoading,
		error: stockAmountError,
		isFetching: stockAmountIsFetching,
	} = useQuery(
		['fetchStockAmountsForStock', StokID],
		() => fetchAllStockAmountsForSingleStock(StokID),
		{ staleTime: 1200000, cacheTime: 14400000 }
	);

	if (isLoading) return <div>Loading...</div>;
	if (error) return <div>Error...</div>;
	if (isFetching) return <div>Fetching...</div>;
	if (!StockSummary) return <div>No Data...</div>;
	if (stockAmountIsLoading) return <div>Loading...</div>;
	if (stockAmountError) return <div>Error...</div>;
	if (stockAmountIsFetching) return <div>Fetching...</div>;
	if (!stockAmount) return <div>No Data...</div>;
	return (
		<MDBox display="flex" flexDirection="column">
			<MDBox
				display="flex"
				alignItems="center"
				px={3}
				color="white"
				bgColor="white"
				variant="gradient"
				borderRadius="lg"
				shadow="lg"
				opacity={1}
				key={key}
			>
				<MDBox
					mr={2}
					// display={{
					// 	xs: 'none',
					// 	sm: 'none',
					// 	md: 'flex',
					// }}
				>
					<MDAvatar size="lg" src={logo} alt="Gold Glasses" />
				</MDBox>
				<MDBox lineHeight={1} pt={2}>
					<MDTypography
						fontWeight="medium"
						sx={{
							typography: {
								xs: 'h6',
								sm: 'h6',
								md: 'h6',
							},
							textAlign: {
								xs: 'center',
								sm: 'center',
								md: 'left',
							},
						}}
					>
						{StockSummary[0]?.StokAdi} ({Miktar} {BirimAdi}) {Tutar}
						{DovizSembol}
					</MDTypography>
					<MDBox
						mb={2}
						display={{
							xs: 'none',
							sm: 'none',
							md: 'flex',
							lg: 'flex',
						}}
						flexWrap="wrap"
					>
						{stockAmount
							?.sort((a, b) => b.Miktar - a.Miktar)
							.filter((el) => el.Miktar !== 0)
							.map((warehouse) => (
								<MDBox px={0.25}>
									<MDBadge
										variant="gradient"
										color={
											warehouse.Miktar > 0
												? 'success'
												: 'primary'
										}
										size="xs"
										badgeContent={`${warehouse.DepoAdi}: ${warehouse.Miktar} ${warehouse.Brm1Adi}`}
										container
									/>
								</MDBox>
							))}
					</MDBox>
					<MDBox
						mb={2}
						display={{
							xs: 'none',
							sm: 'none',
							md: 'flex',
							lg: 'flex',
						}}
						justifyContent="space-around"
						flexWrap="wrap"
					>
						{StockSummary?.filter(
							(branch) =>
								!['MERKEZ', 'DEMIRHENDEKLTD'].includes(
									branch.SubeAdi.toUpperCase()
								)
						).map((branch) => (
							<MDButton
								variant="gradient"
								color="light"
								size="small"
								onClick={(e) => {
									setActiveBranch(e.target.value);
									setSummary(branch);
									setBranchName(branch.SubeAdi);

									setShow(!show);
								}}
								value={branch?.SubeID}
							>
								{branch?.SubeAdi?.toLocaleUpperCase()}
								&nbsp;
								<Icon color="dark">arrow_drop_down</Icon>
							</MDButton>
						))}
					</MDBox>
				</MDBox>
			</MDBox>
			{show && (
				<ProductSummary
					activeBranch={activeBranch}
					productID={StokID}
					summary={summary}
					branchName={branchName}
				/>
			)}
		</MDBox>
	);
}

export default memo(SingleItem);
