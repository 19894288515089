// React Libraries
import { useCallback } from 'react';

// React Query Library
import { useQuery } from 'react-query';
import { getProjects } from 'services/api/project';

// MUI V5 Components
import { DataGridPro } from '@mui/x-data-grid-pro';

// Helper Functions
import { demandStatusNameFinderByID } from 'helpers/projectHelpers';
import { getCountryFromLabel } from 'helpers/countryHelpers';

// API Service Functions
import { getTags } from 'services/api/general/tag';

// Constants
import { config } from 'Constants';

// Custom Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// Project Components
import { ProjectStatus } from 'layouts/projects/Pages/All/ProjectsData/components/status/index.js';
import ProjectRating from 'layouts/projects/Pages/All/ProjectsData/components/rating/index.js';
import { ProjectResponsibles } from 'layouts/projects/Pages/All/ProjectsData/components/responsibles/index.js';
import ProjectName from 'layouts/projects/Pages/All/ProjectsData/components/projectName';
import AaroCustomerName from 'layouts/projects/Pages/All/ProjectsData/components/aaroCustomerName';
import DemandStatus from 'layouts/projects/Pages/All/ProjectsData/components/demandStatus';
import { ProjectCreationDate } from 'layouts/projects/Pages/All/ProjectsData/components/projectCreationDate';
// import { DemandOfProject } from 'layouts/projects/Pages/All/ProjectsData/components/showDemand';
import FastEdit from 'layouts/projects/Pages/All/ProjectsData/components/fastEdit';
import PinProject from 'layouts/projects/Pages/All/ProjectsData/components/pinProject';

// React Libraries
import React, { useState, useEffect, useMemo } from 'react';

// React Query Library

// MUI Components
import { Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';

// Helper Functions

// API Service Functions

// Project Components
import ProjectTags from 'layouts/projects/Components/ProjectTags/index.js';

import DataTableHeadCell from 'examples/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';

// Search Context
import { useSearch } from 'layouts/projects/Pages/SearchProvider';

const { PROJECT_LOSE_GROUP_ID, PROJECT_WIN_GROUP_ID, PROJECT_SOURCE_GROUP_ID, PROJECT_WINNER_GROUP_ID } =
    config.fixedID;

const fetchPinnedProjects = async (userId) => {
    // Fetch projects where the user has pinned
    const response = await getProjects(`pinnedBy=${userId}`).then((rp) => rp.data);
    return response;
};

// Main Component
export default function ProjectsData() {
    // Get window dimensions for responsive design
    const { height, width } = useWindowDimensions();

    const user = JSON.parse(localStorage.getItem('user'));
    // Get tags for project status
    const selectableTags = config.fixedID.PROJECT_STATUS_TAG_GROUP_ID;

    const {
        data: all,
        isLoading: isLoadingPinnedProjects,
        error: isErrorPinnedProjects,
    } = useQuery(['fetchPinnedProjects', user?.userID], () => fetchPinnedProjects(user?.userID), { staleTime: 600000 });

    const { data: tags, isLoading } = useQuery(['tags', selectableTags], () =>
        getTags(selectableTags).then((rp) => rp?.data?.result?.tags)
    );

    if (isLoadingPinnedProjects) return <div>Loading...</div>;
    if (isErrorPinnedProjects) return <div>Error: {isErrorPinnedProjects.message}</div>;

    // Display loading indicator
    if (isLoading) {
        return <div>Loading...</div>;
    }

    const data = all?.result || [];
    // const totalPages = all?.totalPages;

    return data.length > 0 ? (
        <Card>
            <TableContainer>
                <Table stickyHeader>
                    <MDBox component="thead">
                        <TableRow>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Gün
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Proje Genel
                            </DataTableHeadCell>
                            {/* <DataTableHeadCell width={'auto'} align={'left'}>
                            Ülke
                        </DataTableHeadCell> */}

                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Potansiyeli
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Proje Adı
                            </DataTableHeadCell>

                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Müşteri
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Sorumlu
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Kalemler
                            </DataTableHeadCell>
                            <DataTableHeadCell width={10}>Pin</DataTableHeadCell>
                            <DataTableHeadCell width={10}></DataTableHeadCell>

                            {/* <TableCell>Nasıl Geldi</TableCell>
                        <TableCell>Kim Kazandı</TableCell>
                        <TableCell>Nasıl Kazanıldı</TableCell>
                        <TableCell>Neden Kaybedildi</TableCell> */}
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={row._id} className={row.isActive ? '' : 'inactiveRow'}>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectCreationDate projectID={row._id} date={row.createdAt} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectStatus
                                        name={row.status?.name}
                                        projectId={row._id}
                                        userId={user?.userID}
                                        tags={tags}
                                    />
                                </DataTableBodyCell>
                                {/* <DataTableBodyCell align={'left'}>
                                <img
                                    src={'https://cdn-icons-png.flaticon.com/512/555/555560.png'}
                                    alt="country-flag"
                                    width="32"
                                    height="32"
                                />
                            </DataTableBodyCell> */}

                                <DataTableBodyCell align={'left'}>
                                    <ProjectRating project={{ _id: row._id, projectPotential: row.projectPotential }} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectName id={row._id} formattedValue={row.projectName} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <AaroCustomerName id={row._id} formattedValue={row.aaroCustomerName} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectResponsibles responsibles={row.responsibles} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <DemandStatus
                                        id={row._id}
                                        formattedValue={demandStatusNameFinderByID(row?.pricingStatus)}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'} width={10}>
                                    <PinProject
                                        isPinned={row?.pinnedBy?.includes(user?.userID)}
                                        projectID={row._id}
                                        projects={data}
                                        userID={user?.userID}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'} width={10}>
                                    <FastEdit projectID={row._id} />
                                </DataTableBodyCell>
                                {/* < TableCell style={{flex:1}}>
                                    <ProjectTags
                                        project={row}
                                        tagLabel={''}
                                        tagGroupID={PROJECT_SOURCE_GROUP_ID}
                                        multiple={false}
                                        dbKey={'source'}
                                    />
                                </TableCell>
                                < TableCell style={{flex:1}}>
                                    <ProjectTags
                                        project={row}
                                        tagLabel={''}
                                        tagGroupID={PROJECT_WINNER_GROUP_ID}
                                        multiple={false}
                                        dbKey={'winner'}
                                    />
                                </TableCell>
                                < TableCell style={{flex:1}}>
                                    <ProjectTags
                                        project={row}
                                        tagLabel={''}
                                        tagGroupID={PROJECT_WIN_GROUP_ID}
                                        multiple={true}
                                        dbKey={'win'}
                                    />
                                </TableCell>
                                < TableCell style={{flex:1}}>
                                    <ProjectTags
                                        project={row}
                                        tagLabel={''}
                                        tagGroupID={PROJECT_LOSE_GROUP_ID}
                                        multiple={true}
                                        dbKey={'lose'}
                                    />
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    ) : (
        ''
    );
}
