import React, { useEffect, useState } from 'react';
import ReactFlow, { MiniMap, Controls, Background, useNodesState, useEdgesState } from 'reactflow';
import 'reactflow/dist/style.css';

export default function StockFlowChart({ data }) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        const groupedData = data.reduce((acc, item) => {
            if (item.Kod1Adi && item.Kod1Adi !== 'null') {
                if (!acc[item.Kod1Adi]) {
                    acc[item.Kod1Adi] = { label: item.Kod1Adi, birimKodu: item.BirimKodu, children: {}, total: 0 };
                }
                acc[item.Kod1Adi].total += item.Miktar;

                if (item.Kod2Adi && item.Kod2Adi !== 'null') {
                    if (!acc[item.Kod1Adi].children[item.Kod2Adi]) {
                        acc[item.Kod1Adi].children[item.Kod2Adi] = {
                            label: item.Kod2Adi,
                            birimKodu: item.BirimKodu,
                            children: {},
                            total: 0,
                        };
                    }
                    acc[item.Kod1Adi].children[item.Kod2Adi].total += item.Miktar;

                    if (item.Kod3Adi && item.Kod3Adi !== 'null') {
                        if (!acc[item.Kod1Adi].children[item.Kod2Adi].children[item.Kod3Adi]) {
                            acc[item.Kod1Adi].children[item.Kod2Adi].children[item.Kod3Adi] = {
                                label: item.Kod3Adi,
                                birimKodu: item.BirimKodu,
                                children: {},
                                total: 0,
                            };
                        }
                        acc[item.Kod1Adi].children[item.Kod2Adi].children[item.Kod3Adi].total += item.Miktar;

                        if (item.SubeAdi && item.SubeAdi !== 'null') {
                            if (
                                !acc[item.Kod1Adi].children[item.Kod2Adi].children[item.Kod3Adi].children[item.SubeAdi]
                            ) {
                                acc[item.Kod1Adi].children[item.Kod2Adi].children[item.Kod3Adi].children[item.SubeAdi] =
                                    { label: item.SubeAdi, birimKodu: item.BirimKodu, total: 0 };
                            }
                            acc[item.Kod1Adi].children[item.Kod2Adi].children[item.Kod3Adi].children[
                                item.SubeAdi
                            ].total += item.Miktar;
                        }
                    }
                }
            }
            return acc;
        }, {});

        let xPos = 0;
        let yPos = 25;
        const newNodes = [];
        const newEdges = [];

        Object.entries(groupedData).forEach(([kod1, kod1Value], i) => {
            const kod1Id = `kod1-${i}`;
            newNodes.push({
                id: kod1Id,
                position: { x: xPos, y: yPos },
                data: { label: `${kod1Value.label} (${Math.round(kod1Value.total)} ${kod1Value.birimKodu})` },
                type: 'default',
            });
            yPos += 100;

            Object.entries(kod1Value.children).forEach(([kod2, kod2Value], j) => {
                const kod2Id = `kod2-${i}-${j}`;
                newNodes.push({
                    id: kod2Id,
                    position: { x: xPos + 200, y: yPos },
                    data: { label: `${kod2Value.label} (${Math.round(kod2Value.total)} ${kod2Value.birimKodu})` },
                    type: 'default',
                });
                newEdges.push({ id: `e${kod1Id}-${kod2Id}`, source: kod1Id, target: kod2Id });
                yPos += 100;

                Object.entries(kod2Value.children).forEach(([kod3, kod3Value], k) => {
                    const kod3Id = `kod3-${i}-${j}-${k}`;
                    newNodes.push({
                        id: kod3Id,
                        position: { x: xPos + 400, y: yPos },
                        data: { label: `${kod3Value.label} (${Math.round(kod3Value.total)} ${kod3Value.birimKodu})` },
                        type: 'default',
                    });
                    newEdges.push({ id: `e${kod2Id}-${kod3Id}`, source: kod2Id, target: kod3Id });
                    yPos += 100;

                    Object.entries(kod3Value.children).forEach(([sube, subeValue], l) => {
                        const subeId = `sube-${i}-${j}-${k}-${l}`;
                        newNodes.push({
                            id: subeId,
                            position: { x: xPos + 600, y: yPos },
                            data: {
                                label: `${subeValue.label} (${Math.round(subeValue.total)} ${subeValue.birimKodu})`,
                            },
                            type: 'default',
                        });
                        newEdges.push({ id: `e${kod3Id}-${subeId}`, source: kod3Id, target: subeId });
                        yPos += 100;
                    });
                });
            });

            xPos += 800; // Yeni Kod1 başlangıcında x pozisyonunu ayarla
            yPos = 25; // Y pozisyonunu sıfırla
        });

        setNodes(newNodes);
        setEdges(newEdges);
    }, [data]); // Bağımlılıklar listesi

    return (
        <div style={{ width: '93vw', height: '70vh' }}>
            <ReactFlow nodes={nodes} edges={edges} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange}>
                <MiniMap />
                <Controls />
                <Background variant="dots" gap={12} size={1} />
            </ReactFlow>
        </div>
    );
}
