import { useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from 'context';
import useWindowDimensions from 'hooks/useWindowDimensions';

function APLayout({ children }) {
    const [controller, dispatch] = useMaterialUIController();
    const { width } = useWindowDimensions();

    const { miniSidenav } = controller;
    const { pathname } = useLocation();

    useEffect(() => {
        setLayout(dispatch, 'dashboard');
    }, [dispatch, pathname]);

    return (
        <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                p: width <= 600 ? 0 : 3,

                position: 'relative',

                [breakpoints.up('xl')]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(
                        ['margin-left', 'margin-right'],
                        {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }
                    ),
                },
            })}
        >
            {children}
        </MDBox>
    );
}

// Typechecking props for the DashboardLayout
// APLayout.propTypes = {
//     children: PropTypes.node.isRequired,
// };

export default APLayout;
