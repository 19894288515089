import React from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Rating from '@mui/material/Rating';

// CardBody Component
const CardBody = ({ name, line_items, purchase, status }) => (
    <MDBox mt={0.5} mb={1} display="flex" flexDirection="column">
        <MDTypography
            variant="caption"
            fontWeight="bold"
            sx={{ lineHeight: '1.3', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
            {name}
        </MDTypography>

        {status === 'completed' ? (
            <MDBox>
                {purchase?.customerRating && (
                    <MDBox display="flex" alignItems="center" gap={1} mt={0.5}>
                        <Rating value={purchase.customerRating} readOnly size="small" />
                        <MDTypography variant="caption" fontWeight="light">
                            ({purchase.customerRating}/5)
                        </MDTypography>
                    </MDBox>
                )}
                {purchase?.customerFeedback && (
                    <MDTypography
                        variant="caption"
                        fontWeight="light"
                        sx={{
                            fontStyle: 'italic',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        "{purchase.customerFeedback}"
                    </MDTypography>
                )}
                {/*
                <MDBox
                    sx={{
                        display: 'none',
                        '.MuiCard-root:hover &': {
                            display: 'block',
                        },
                    }}
                >
                    {line_items.map((item, index) => (
                        <MDTypography
                            key={item.id}
                            variant="overline"
                            fontWeight="light"
                            textTransform="lowercase"
                            sx={{ lineHeight: '1', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                            {item.name}
                        </MDTypography>
                    ))}
                </MDBox>
                */}
            </MDBox>
        ) : (
            line_items.map((item, index) => (
                <MDTypography
                    key={item.id}
                    variant="overline"
                    fontWeight="light"
                    textTransform="lowercase"
                    sx={{ lineHeight: '1', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                    {item.name}
                </MDTypography>
            ))
        )}
        {/*
        {purchase &&
            purchase.shipments &&
            purchase.shipments.map((shipment, index) => (
                <MDBox key={index} ml={1}>
                    <MDTypography variant="caption" fontWeight="light">
                        {shipment.shippingMethod && `${shipment.shippingMethod} • `}
                        {shipment.shippingCost > 0 && `$${shipment.shippingCost} • `}
                        {shipment.trackingNumber}
                    </MDTypography>
                </MDBox>
            ))}
        */}
    </MDBox>
);

export default CardBody;
