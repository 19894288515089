import { useQuery } from 'react-query';
// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// API
import { getProjects } from 'services/api/project';

import CustomerSummary from 'layouts/projects/Pages/All/Summary/Charts/CustomerDistribution.js';
import MonthlyProjectDistribution from 'layouts/projects/Pages/All/Summary/Charts/MonthlyProjectDistribution.js';
import ActiveProjectsSummary from 'layouts/projects/Pages/All/Summary/Charts/ActiveProjectsSummary.js';

const fetchProjects = async (projectType) => {
    const isActive = projectType === 'active' ? true : false;
    const response = await getProjects(`isActive=${isActive}`).then((rp) => rp.data.result);
    return response;
};

function Sales() {
    const {
        data: activeProjects,
        isLoading,
        error,
    } = useQuery(['fetchProjects', 'active'], () => fetchProjects('active'), {
        staleTime: 600000,
    });
    const passiveProjects = useQuery(['fetchProjects', 'passive'], () => fetchProjects('passive'), {
        staleTime: 600000,
    });
    if (isLoading) return '';
    if (passiveProjects.isLoading) return '';

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    if (passiveProjects.error) return 'Bir Hata Oluştu: ' + passiveProjects.error.message;

    const projects = [...activeProjects, ...passiveProjects.data];

    return (
        <MDBox>
            <MDBox mb={3}>
                <ActiveProjectsSummary activeProjects={activeProjects} />
            </MDBox>
            <MDBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <CustomerSummary projects={projects} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8}>
                        <MonthlyProjectDistribution projects={projects} />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default Sales;
