import React from 'react';

import MovementsTable from 'components/Aaro/StockMovements';

export default function SingleStockWareHouses(props) {
	const { stockID } = props;
	return (
		<MovementsTable
			params={{
				SiralamaKisiti: 'DgsTar:Desc',
				StokID: stockID,
				TipID: '10005,10006,10007,10008,10009,10010,10011,10012,10013,10014,10015,10016,10019',
			}}
			size="small"
		/>
	);
}
