import React from 'react';
import { getStocks } from 'services/outside/aaro/stocks';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Grid from '@mui/material/Grid';

const fetchAllStockAmountsForSingleStock = async (StokID) => {
	const response = await getStocks({
		StokID,
	});
	return response;
};

export default function SingleStockWareHouses(props) {
	const { stockID: stokID } = props;
	const { data, isLoading, error, isFetching } = useQuery(
		['fetchStockAmountsForStock', stokID],
		() => fetchAllStockAmountsForSingleStock(stokID),
		{ staleTime: 1200000, cacheTime: 14400000 }
	);

	// const priceList1ItemsLength = PriceList1Items?.length;

	// const priceList2ItemsLength = PriceList2Items?.length;
	// if (priceList2ItemsLength > 0) setLoading(false);

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;
	return <>{isFetching ? 'Güncelleniyor' : <ShowResult data={data} />}</>;
}

const ShowResult = (props) => {
	const { data } = props;
	return (
		<Grid container spacing={3}>
			{data.map((warehouse) => (
				<Grid item xs={6} md={4}>
					<Alert
						severity={
							warehouse.Miktar > 5
								? 'success'
								: warehouse.Miktar > 0
								? 'warning'
								: 'error'
						}
					>
						<AlertTitle>{warehouse.DepoAdi}</AlertTitle>
						{warehouse.Miktar} <strong> {warehouse.Brm1Adi}</strong>
					</Alert>
				</Grid>
			))}
		</Grid>
	);
};
