// import { useState } from 'react';
import { useQuery } from 'react-query';

// @mui material components

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

// ProductPage page components
import Product from './SingleProduct';

// Data

import useWindowDimensions from 'hooks/useWindowDimensions';
import useDialogHook from 'hooks/useDialogHook';
import AddNewProduct from './forms/NewProduct';

// API
import { getProductSuggestions } from 'services/api/productSuggestion';

// Kullanıcının oylama yapmadığı ürünleri filtrele
const filterUnratedProducts = (products, userID) => {
    return products.filter((product) => {
        // Find if there's a vote by the user
        const userVote = product.userSuggestions.find((voting) => voting.user._id === userID);
        // If there's no vote or the rate is 0, include the product
        return !userVote || userVote.rate === 0;
    });
};

function ProductSuggestionPage({ isComponent = false }) {
    const { width } = useWindowDimensions();
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const lgColumn = width > 1920 ? 2 : 3;

    const { data, isLoading, isError } = useQuery('fetchProductSuggestions', getProductSuggestions);
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (isError) {
        return <span>Error: Something went wrong...</span>;
    }
    const products = data?.data?.result || []; // data.data.result'ı bir değişkene atıyoruz

    // Filter products to include only those not rated by the user
    const productsComponent = filterUnratedProducts(products, userID);

    return isComponent ? (
        <MDBox>
            <Card sx={{ overflow: 'visible' }}>
                <Grid container spacing={3}>
                    {productsComponent.slice(0, 8).map((product, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={lgColumn} key={index}>
                            <MDBox p={2}>
                                <Product product={product} /> {/* Ürün bilgisini Product componentine geçirin */}
                            </MDBox>
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </MDBox>
    ) : (
        <DashboardLayout>
            <MDBox display="flex" flexDirection="row-reverse">
                <MDButton size="small" color="dark" variant="gradient" onClick={handleClickOpen} sx={{ zIndex: 10 }}>
                    +
                </MDButton>
            </MDBox>

            {open && <AddNewProduct open={open} handleClose={handleClose} sourcePage="productSuggestion" />}
            <MDBox pb={3}>
                <Card sx={{ overflow: 'visible' }}>
                    <Grid container spacing={3}>
                        {products.map((product, index) => (
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={lgColumn} key={index}>
                                <MDBox p={2}>
                                    <Product product={product} /> {/* Ürün bilgisini Product componentine geçirin */}
                                </MDBox>
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default ProductSuggestionPage;
