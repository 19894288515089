import { useState } from 'react';

import Card from '@mui/material/Card';
import { getSpreadsheets } from 'services/api/google';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDBadgeDot from 'components/MDBadgeDot';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components

import DataTable from 'examples/Tables/DataTable';

import { formatSheetPrices } from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/components/sheetFormatHelpers';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';

import AddSpreadsheet from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/forms/AddSpreadsheet/index.js';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

const fetchSpreadsheet = async (id) => {
    const response = await getSpreadsheets(`demandID=${id}`).then((rp) => (rp.data?.result ? rp.data?.result : []));
    return response;
};

export default function GooglePriceSheets(props) {
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const { demand, project } = props;
    const { _id: demandID } = demand;
    const { data, isLoading, error } = useQuery(['fetchSpreadsheets', demandID], () => fetchSpreadsheet(demandID), {
        staleTime: 600000,
    });

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const formattedSheetPrices = formatSheetPrices(data, demand);

    return (
        <MDBox pb={3}>
            <Card>
                {!loading && (
                    <MDBox pt={3} px={3} display="flex" justifyContent="space-between">
                        <MDTypography variant="h6" fontWeight="medium">
                            Fiyat Çalışmaları
                        </MDTypography>
                        <MDButton
                            variant="gradient"
                            color="dark"
                            iconOnly
                            size="small"
                            onClick={() => setOpenDialog(true)}
                            // circular
                        >
                            <Icon>add</Icon>
                            <Dialog
                                onClose={() => setOpenDialog(false)}
                                open={openDialog}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/*Burada  */}
                                <AddSpreadsheet
                                    setOpenDialog={setOpenDialog}
                                    demandID={demand._id}
                                    project={project}
                                    setLoading={setLoading}
                                />
                            </Dialog>
                        </MDButton>
                    </MDBox>
                )}
                {loading ? (
                    <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <MDTypography variant="text" fontWeight="medium" color="success">
                            Server entegrasyona başladı
                        </MDTypography>
                        <CircularProgress color="success" />
                    </MDBox>
                ) : (
                    <MDBox py={1}>
                        <DataTable
                            table={formattedSheetPrices}
                            entriesPerPage={true}
                            showTotalEntries={false}
                            isSorted={false}
                            itemAmount={3}
                            noEndBorder
                        />
                    </MDBox>
                )}
            </Card>
        </MDBox>
    );
}
