// Libraries

import { useParams } from 'react-router-dom';

import Layout from '../Layout';
import ModalView from './ModalView';

function TaskListPage() {
	const { id } = useParams();
	return (
		<Layout>
			<ModalView taskListID={id} taskID={null} />{' '}
		</Layout>
	);
}

export default TaskListPage;
