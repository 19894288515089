import {
    getTamamlandiColor,
    getFiyatBekleniyorColor,
    getDuzeltilmeliColor,
    getRevizeBekleniyorColor,
    getYeniColor,
} from 'helpers/colorHelpers';
import { calculateDiscount } from 'helpers/priceHelpers';
import { config } from 'Constants';
export const availableDemandNames = ['uKasa', 'kasa', 'kanat', 'uPervaz', 'pervaz', 'diger'];

/* eslint-disable eqeqeq */
export const demandStatusNameFinder = (demand) => {
    if (demand == null) return 'bilinmiyor';
    let duzeltilmeliNumber = 0;
    let fiyatBekleniyorNumber = 0;
    let revizeBekleniyorNumber = 0;
    let tamamlandiNumber = 0;

    Object.keys(demand).forEach((key) => {
        if (availableDemandNames.includes(key)) {
            demand[key].forEach((el) => {
                if (el.durumu?.name == 'Düzeltilmeli') {
                    duzeltilmeliNumber++;
                } else if (el.durumu?.name == 'Fiyat Bekleniyor') {
                    fiyatBekleniyorNumber++;
                } else if (el.durumu?.name == 'Revize Bekleniyor') {
                    revizeBekleniyorNumber++;
                } else if (el.durumu?.name == 'Fiyat Verildi') {
                    tamamlandiNumber++;
                }
            });
        }
    });
    if (duzeltilmeliNumber > 0) {
        return 'Düzeltilmeli';
    }
    if (fiyatBekleniyorNumber > 0) {
        return 'Fiyat Bekleniyor';
    }
    if (revizeBekleniyorNumber > 0) {
        return 'Revize Bekleniyor';
    }
    if (tamamlandiNumber > 0) {
        return 'Fiyat Verildi';
    }

    return 'KALEM GİRİLMEMİŞ';
};

export const demandStatusNameFinderByID = (pricingStatus) => {
    if (pricingStatus == null) return 'bilinmiyor';

    const { fiyatVerildi, duzeltilmeli, revizeBekleniyor, fiyatBekleniyor } = config.demandStatusID;
    if (pricingStatus.includes(duzeltilmeli)) {
        return 'Düzeltilmeli';
    }
    if (pricingStatus.includes(fiyatBekleniyor)) {
        return 'Fiyat Bekleniyor';
    }
    if (pricingStatus.includes(revizeBekleniyor)) {
        return 'Revize Bekleniyor';
    }
    if (pricingStatus.includes(fiyatVerildi)) {
        return 'Fiyat Verildi';
    }

    return 'Kalem Girilmemiş';
};

export const tamamlandiButtonStyle = {
    background: getTamamlandiColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};
export const fiyatBekleniyorButtonStyle = {
    background: getFiyatBekleniyorColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};
export const duzeltilmeliButtonStyle = {
    background: getDuzeltilmeliColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};
export const revizeBekleniyorButtonStyle = {
    background: getRevizeBekleniyorColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};

export const yeniButtonStyle = {
    background: getYeniColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};

export const buttonStyleDecider = (name) => {
    switch (name) {
        case 'Fiyat Verildi':
            return tamamlandiButtonStyle;
        case 'Fiyat Bekleniyor':
            return fiyatBekleniyorButtonStyle;
        case 'Revize Bekleniyor':
            return revizeBekleniyorButtonStyle;
        case 'Düzeltilmeli':
            return duzeltilmeliButtonStyle;

        default:
            return yeniButtonStyle;
    }
};

export const demandCalculator = (demand) => {
    let pureTotal = 0;
    let pureDiscount = 0;
    let finalTotal = 0;
    let totalDiscountPercentage = 0;
    let totalProductAmount = 0;
    let numberOfItem = 0;
    Object.keys(demand).forEach((key) => {
        if (availableDemandNames.includes(key)) {
            demand[key].forEach((el) => {
                pureTotal += el.verilenFiyat * el.miktar;
                totalProductAmount += el.miktar;
                numberOfItem += 1;
                pureDiscount +=
                    el.verilenFiyat * el.miktar - calculateDiscount(el.verilenFiyat, el.miktar, el.indirimOrani);
            });
        }
    });
    finalTotal = pureTotal - pureDiscount;

    totalDiscountPercentage = ((pureTotal / finalTotal - 1) * 100).toFixed(2);
    return {
        pureTotal,
        pureDiscount,
        finalTotal,
        totalDiscountPercentage,
        totalProductAmount,
        numberOfItem,
    };
};
