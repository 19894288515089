import React, { useCallback, memo } from 'react';

import { updateProject } from 'services/api/project';

import ColoredTagSelection from 'components/Tags/ColoredTagSelection';

const ProjectTags = ({ project, tagLabel, tagGroupID, multiple, dbKey }) => {
    const selectedTags = project[dbKey] || [];

    const handleChanges = useCallback(
        (key, value) => {
            const tag = {};
            const projectID = project?._id;
            tag[key] = Array.isArray(value) ? value.map((el) => el._id) : value?._id;

            updateProject(projectID, tag).catch((err) => {
                console.error('Error:', err);
            });
        },
        [project]
    );

    return (
        <ColoredTagSelection
            tagGroupID={tagGroupID}
            selectedTags={selectedTags}
            tagLabel={tagLabel}
            multiple={multiple}
            handleChanges={handleChanges}
            dbKey={dbKey}
        />
    );
};

export default memo(ProjectTags);
