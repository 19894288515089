import React from 'react';

import Dialog from '@mui/material/Dialog';

import InputArea from './InputArea';

export default function ChangeProjectName(props) {
	const { open, setOpen, project, setUpdateProject } = props;

	return (
		<div>
			<Dialog
				onClose={() => setOpen(false)}
				open={open}
				fullWidth={true}
				maxWidth="sm"
			>
				<InputArea
					project={project}
					setOpen={setOpen}
					setUpdateProject={setUpdateProject}
				/>
			</Dialog>
		</div>
	);
}
