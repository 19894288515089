import React from 'react';

// Layout
import Layout from 'layouts/settings/Layout';
import ColoredTagSelection from 'components/Tags/ColoredTagSelectionGeneral';
import MDBox from 'components/MDBox';
import { config } from 'Constants';

export default function Settings() {
    const { DEMAND_TEMPLATE_CATEGORY_ID, DEMAND_TEMPLATE_PRODUCT_ID } = config.fixedID;

    return (
        <Layout>
            <MDBox>
                <ColoredTagSelection
                    tagGroupID={DEMAND_TEMPLATE_CATEGORY_ID}
                    // selectedTags={selectedTags}
                    tagLabel={'Şablon Kategorileri'}
                    // multiple={multiple}
                    // handleChanges={handleChanges}
                    // dbKey={dbKey}
                />
            </MDBox>
            <MDBox>
                <ColoredTagSelection
                    tagGroupID={DEMAND_TEMPLATE_PRODUCT_ID}
                    // selectedTags={selectedTags}
                    tagLabel={'Şablon Kapı Tagleri'}
                    // multiple={multiple}
                    // handleChanges={handleChanges}
                    // dbKey={dbKey}
                />
            </MDBox>
        </Layout>
    );
}
