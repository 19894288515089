import { Aaro } from 'aaro';
import { getAllData } from 'aaro-scripts';
import { config } from 'Constants';
import { useQuery } from 'react-query';
import { getTodayDate } from 'helpers/dateHelpers.js';

export const getAllUsers = (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    const today = getTodayDate();
    return getAllData(
        aaro,
        `Personel/Get?CikisTarihiSonBas=${today}&GirisTarihiSonBit=${today}`
    );
};

const fetchUsers = async () => {
    const response = await getAllUsers();
    let jsonArray = [];
    for (let i = 0; i < response.length; i++) {
        let uniqueId = i + 1; // Örnek olarak, bir artan sayısal kimlik atayalım.
        jsonArray[i] = { id: uniqueId, ...response[i] }; // JSON öğesine kimlik ekleyelim.
    }
    return jsonArray;
};

export const useFetchUsers = () => {
    return useQuery('aaroKullanicilari', fetchUsers);
};
