import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function FormSwitch(props) {
    const { formik, name, color, label, disabled = false } = props;

    return (
        <MDBox m={1}>
            <FormControlLabel
                control={
                    <Switch
                        checked={formik.values[name]}
                        onChange={formik.handleChange}
                        name={name}
                        color={color}
                        disabled={disabled}
                    />
                }
                label={
                    <MDTypography variant="overline" align="center">
                        {label}
                    </MDTypography>
                }
            />
        </MDBox>
    );
}
