const formatSeconds = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

function normalizeData(data) {
    if (data.changedBy) {
        data.changedBy = data.changedBy.username;
    }
    if (data.recordedBy) {
        data.recordedBy = data.recordedBy.username;
    }
    if (data.durumu) {
        data.durumu = data.durumu.name;
    }
    if (data.createdAt) {
        data.createdAt = formatSeconds(data.createdAt);
    }
    if (data.updatedAt) {
        data.updatedAt = formatSeconds(data.updatedAt);
    }
    return data;
}

function normalizeArrayOfData(arr) {
    const normalizedArray = [];
    arr.forEach((el) => normalizedArray.push(normalizeData(el)));
    return normalizedArray;
}

const prepareColumns = (formData) => {
    const ignoredNames = [
        '__v',
        'history',
        '_id',
        'createdAt',
        'updatedAt',
        'recordedBy',
    ];
    let columns = [];
    Object.keys(formData).forEach((key) =>
        ignoredNames.includes(key) ? '' : columns.push(key)
    );
    return columns;
};

export const formatDataWithHistory = (formData) => {
    const tempHistory = [];
    const columnNames = prepareColumns(formData);
    let tempObj = {};
    columnNames.forEach((columnName) => {
        tempObj[columnName] = formData[columnName];
    });
    tempHistory.push(tempObj);
    tempObj = {};
    formData.history.reverse().forEach((history) => {
        columnNames.forEach((columnName) => {
            tempObj[columnName] = history[columnName];
        });
        tempHistory.push(tempObj);
        tempObj = {};
    });
    const sortedHistory = normalizeArrayOfData(tempHistory);
    return { columnNames, sortedHistory };
};
