import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import pxToRem from 'assets/theme/functions/pxToRem.js';

import {
	deleteUKasa,
	deleteKasa,
	deleteKanat,
	deleteUPervaz,
	deletePervaz,
	deleteDiger,
} from 'services/api/form';
// imgs
import ukasaJPG from 'assets/images/forms/u-kasa.jpg';
import lpervazJPG from 'assets/images/forms/pervaz-l.jpg';
import upervazJPG from 'assets/images/forms/pervaz-u.jpg';
import kasaJPG from 'assets/images/forms/kasa.jpg';
import kanatJPG from 'assets/images/forms/kanat.jpg';
import digerJPG from 'assets/images/forms/diger.jpg';

// ! DEMAND NAMES
export const demandNamesWeWant = [
	'uKasa',
	'kasa',
	'kanat',
	'uPervaz',
	'pervaz',
	'diger',
];

// ! ITEN DELETION
export const deleteItem = (activeItemType, id) => {
	if (activeItemType === 'kanat') {
		const r = window.confirm('Emin Misiniz?');
		if (r == true) {
			return deleteKanat(id);
		}
	} else if (activeItemType === 'diger') {
		const r = window.confirm('Emin Misiniz?');
		if (r == true) {
			return deleteDiger(id);
		}
	} else if (activeItemType === 'kasa') {
		const r = window.confirm('Emin Misiniz?');
		if (r == true) {
			return deleteKasa(id);
		}
	} else if (activeItemType === 'uKasa') {
		const r = window.confirm('Emin Misiniz?');
		if (r == true) {
			return deleteUKasa(id);
		}
	} else if (activeItemType === 'pervaz') {
		const r = window.confirm('Emin Misiniz?');
		if (r == true) {
			return deletePervaz(id);
		}
	} else if (activeItemType === 'uPervaz') {
		const r = window.confirm('Emin Misiniz?');
		if (r == true) {
			return deleteUPervaz(id);
		}
	}
};

// ! LINE IMAGE

export const lineImage = (key) => {
	switch (key) {
		case 'uKasa':
			return ukasaJPG;
		case 'uPervaz':
			return upervazJPG;
		case 'kasa':
			return kasaJPG;
		case 'pervaz':
			return lpervazJPG;
		case 'kanat':
			return kanatJPG;
		case 'diger':
			return digerJPG;
	}
};

// ! POPOVER AND PAPER STYLE

export const useStyles = makeStyles((theme) => ({
	root: {
		alignItems: 'center',
		lineHeight: '24px',
		width: '100%',
		height: '100%',
		position: 'relative',
		display: 'flex',
		'& .cellValue': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		color: 'black',
		fontWeight: 900,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	paper: {
		position: 'absolute',
		maxWidth: 1000,
		maxHeight: 800,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		overflowY: 'scroll',
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
		bordor: '3px dashed #617d66',
	},
}));
function isOverflown(element) {
	return (
		element.scrollHeight > element.clientHeight ||
		element.scrollWidth > element.clientWidth
	);
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
	const { width, value } = props;
	const wrapper = React.useRef(null);
	const cellDiv = React.useRef(null);
	const cellValue = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const classes = useStyles();
	const [showFullCell, setShowFullCell] = React.useState(false);
	const [showPopper, setShowPopper] = React.useState(false);

	const handleMouseEnter = () => {
		const isCurrentlyOverflown = isOverflown(cellValue.current);
		setShowPopper(isCurrentlyOverflown);
		setAnchorEl(cellDiv.current);
		setShowFullCell(true);
	};

	const handleMouseLeave = () => {
		setShowFullCell(false);
	};

	React.useEffect(() => {
		if (!showFullCell) {
			return undefined;
		}

		function handleKeyDown(nativeEvent) {
			// IE11, Edge (prior to using Bink?) use 'Esc'
			if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
				setShowFullCell(false);
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [setShowFullCell, showFullCell]);

	return (
		<div
			ref={wrapper}
			className={classes.root}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				ref={cellDiv}
				style={{
					height: 1,
					width,
					display: 'block',
					position: 'absolute',
					top: 0,
				}}
			/>
			<div ref={cellValue} className="cellValue">
				{value}
			</div>
			{showPopper && (
				<Popper
					open={showFullCell && anchorEl !== null}
					anchorEl={anchorEl}
					style={{ width, marginLeft: -17 }}
				>
					<Paper
						elevation={1}
						style={{ minHeight: wrapper.current.offsetHeight - 3 }}
					>
						<MDTypography
							variant="caption"
							color="primary"
							fontWeight="bold"
							sx={{ lineHeight: 1 }}
						>
							{value}
						</MDTypography>
						{/* <Typography variant="body2" style={{ padding: 8 }}>
							{value}
						</Typography> */}
					</Paper>
				</Popper>
			)}
		</div>
	);
});

export function renderCellExpand(params) {
	return (
		<GridCellExpand
			value={params.value || ''}
			width={params.colDef.computedWidth}
		/>
	);
}

// ! RICH TEXT EDITOR STYLE
export const richTextTheme = createTheme({
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	overrides: {
		MUIRichTextEditor: {
			editor: {
				fontWeight: 400,
				lineHeight: 1.5,
				fontSize: pxToRem(14),
			},
		},
	},
});

// ! MODAL STYLE

export function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}
