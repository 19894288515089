import React from 'react';
import { useQuery } from 'react-query';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';

import { getProjects } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';
import {
	getDemandStatusImage,
	getProjectStatusColorNew,
} from 'helpers/colorHelpers';
const fetchOtherRelatedProjects = async (query) => {
	const response = await getProjects(query)
		.then((rp) => rp.data.result)
		.catch((err) => err);
	return response;
};

export default function DayRating(props) {
	const { customerID, projectID } = props;
	const query = `aaroCustomerID=${customerID}`;
	const { data, isLoading, error } = useQuery(
		['fetchProjectsByQuery', query],
		() => fetchOtherRelatedProjects(query),
		{ staleTime: 600000 }
	);
	if (isLoading) return <AaroLoadingScreen />;
	if (error) return 'Bir Hata Oluştu: ' + error.message;

	const renderProfiles = data
		?.filter((el) => el._id !== projectID)
		?.map(({ _id, projectName, status }) => (
			<a href={`${window.location.origin}/projects/${_id}`}>
				<MDBox
					key={projectName}
					component="li"
					display="flex"
					alignItems="center"
					py={1}
					mb={1}
				>
					<MDBox mx={1}>
						<MDAvatar
							alt="something here"
							shadow="md"
							src={getDemandStatusImage(status?.tag?.name)}
							bgColor={getProjectStatusColorNew(
								status?.tag?.name
							)}
						/>
					</MDBox>
					<MDBox
						display="flex"
						flexDirection="column"
						alignItems="flex-start"
						justifyContent="center"
					>
						<MDTypography variant="button" fontWeight="medium">
							{projectName}
						</MDTypography>
						<MDTypography variant="caption" color="text">
							Durumu:{' '}
							{status?.tag?.name
								? status?.tag?.name
								: 'Bilinmiyor'}
						</MDTypography>
					</MDBox>
					{/* <MDBox ml="auto">
						<MDButton
							component="a"
							href={`${window.location.origin}/projects/${_id}`}
							target="_blank"
							rel="noreferrer"
							variant="text"
							color="info"
						>
							Görüntüle
						</MDButton>
					</MDBox> */}
				</MDBox>
			</a>
		));

	return (
		<MDBox component="ul" display="flex" flexDirection="column" p={0}>
			{renderProfiles}
		</MDBox>
	);
}
