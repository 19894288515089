import React from 'react';
import Switch from '@mui/material/Switch';
import { AllLeadsContext } from 'layouts/lead/pages/allLeads/index.js';

export default function ViewSwitch() {
    const { setShowActiveLeads, showActiveLeads } =
        React.useContext(AllLeadsContext);
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };
    return (
        <Switch
            {...label}
            checked={showActiveLeads}
            onChange={() => setShowActiveLeads(!showActiveLeads)}
        />
    );
}
