import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getNotes = (relatedID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/note`;
	const data = { relatedID: relatedID };
	return axios.get(url, data, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getNote = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/note/all/${id}`;
	return axios.get(url, { headers });
};
/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const editNote = (data, id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/note/${id}`;
	return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postNote = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/note`;
	return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it deletes note
 * @return promise - axios returns a promise
 */
export const deleteNote = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/note/${id}`;
	return axios.delete(url, { headers });
};
