import ReportsBarChartMulti from 'examples/Charts/BarCharts/ReportsBarChartMulti';
import { config } from 'Constants';
function UsersSummary({ projects }) {
	const labels = [];
	let counterWin = {};
	const valuesWin = [];
	let counterLost = {};
	const valuesLost = [];
	let counterUnknown = {};
	const valuesUnknown = [];
	projects.forEach((project) => {
		// for user's project
		project.responsibles.forEach((responsible) => {
			if (responsible.role !== 'superadmin') {
				if (isNaN(counterWin[responsible.username]))
					counterWin[responsible.username] = 0;
				if (isNaN(counterLost[responsible.username]))
					counterLost[responsible.username] = 0;
				if (isNaN(counterUnknown[responsible.username]))
					counterUnknown[responsible.username] = 0;

				if (project.status) {
					if (
						project.status.tag._id === config.statusID.uretiliyor ||
						project.status.tag._id === config.statusID.uretildi ||
						project.status.tag._id === config.statusID.kazanildi
					)
						counterWin[responsible.username] += 1;

					if (project.status.tag._id === config.statusID.kaybedildi)
						counterLost[responsible.username] += 1;
					if (
						project.status.tag._id ===
						config.statusID.teklifGonderildi
					)
						counterUnknown[responsible.username] += 1;
				} else {
					counterUnknown[responsible.username] += 1;
				}
			}
		});
	});
	Object.keys(counterWin).forEach((key) => {
		labels.push(key);
		valuesWin.push(counterWin[key]);
		valuesLost.push(counterLost[key]);
		valuesUnknown.push(counterUnknown[key]);
	});

	const viewData = {
		labels,
		datasets: [
			{
				label: 'Kazanılan',
				data: valuesWin,
				backgroundColor: 'rgba(76,175,80,1)',
			},
			{
				label: 'Kaybedilen',
				data: valuesLost,
				backgroundColor: 'rgba(233,30,99,1)',
			},
			{
				label: 'Bilinmeyen',
				data: valuesUnknown,
				backgroundColor: 'rgba(255,255,255,1)',
			},
		],
	};
	console.log(viewData);

	return (
		<ReportsBarChartMulti
			color="secondary"
			title="Proje Dağılımları"
			description="Bütün Projeler Arasında"
			date="1dk önce güncellendi"
			chart={viewData}
		/>
	);
}

export default UsersSummary;
