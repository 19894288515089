import { useState } from 'react';

// react-images-viewer components
import ImgsViewer from 'react-images-viewer';

// @mui material components
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Custom Component
import UserRatings from '../components/UserRatings';
import EditProduct from '../forms/EditProduct';
import VoteProduct from '../forms/VoteProduct';
import CustomBadge from '../components/CustomBadge';
import CustomAvatarGroup from '../components/CustomAvatarGroup';
//hooks
import useDialogHook from 'hooks/useDialogHook';
import useImageViewer from 'hooks/useImageViewer';
import { formatDate } from 'helpers/smallCodes';

// Helper function to calculate the average rating
const averageRating = (userSuggestions) => {
    const sum = userSuggestions.reduce((acc, curr) => acc + curr.rate, 0);
    return sum / userSuggestions.length;
};

// Placeholder görsel URL'si
const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/400x400?text=Görsel+Bulunamadı';

function ProductHeader({ name, avgRating, handleClickOpen }) {
    return (
        <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            sx={{ borderBottom: '1px solid #e0e0e0', borderRadius: '5px', padding: '10px' }}
        >
            <Typography variant="h6" component="div" onDoubleClick={handleClickOpen}>
                {name}
            </Typography>

            <CustomBadge avgRating={avgRating} />
        </MDBox>
    );
}

function SingleProduct({ product }) {
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const { _id, name, userSuggestions, createdAt, createdBy } = product;

    const [showComments, setShowComments] = useState(false);
    const [imgSrc, setImgSrc] = useState(product?.imageLinks[0] || PLACEHOLDER_IMAGE);

    // ImageViewer related states and functions are now handled by useImageViewer
    const {
        isOpen: imgsViewer,
        currentImg: imgsViewerCurrent,
        openImageViewer: openImgsViewer,
        closeImageViewer: closeImgsViewer,
        nextImage: imgsViewerNext,
        prevImage: imgsViewerPrev,
    } = useImageViewer();

    const avgRating = averageRating(product?.userSuggestions);

    const handleImageError = () => {
        setImgSrc(PLACEHOLDER_IMAGE);
    };

    return (
        <MDBox sx={{ border: '1px solid #e0e0e0', borderRadius: '5px', padding: '10px' }}>
            {open && <EditProduct open={open} handleClose={handleClose} sourcePage="productSuggestion" id={_id} />}

            <ImgsViewer
                imgs={product?.imageLinks.map((img) => ({ src: img }))}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
                sx={{
                    maxWidth: '100%',
                    margin: 0,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            />
            {/* {JSON.stringify(product)} */}
            <MDBox
                component="div"
                position="relative"
                width="100%"
                sx={{ height: 0, paddingBottom: '100%' }} // Aspect ratio 1:1
            >
                <MDBox
                    component="img"
                    src={imgSrc}
                    alt="Ürün Görseli"
                    onError={handleImageError}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    onClick={openImgsViewer}
                    sx={{ objectFit: 'contain', objectPosition: 'center' }}
                />
            </MDBox>

            <ProductHeader name={name} avgRating={avgRating} handleClickOpen={handleClickOpen} />

            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} pb={1}>
                <MDBox display="flex" alignItems="center" color="text">
                    <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                        <VoteProduct sourcePage="productSuggestion" id={_id} product={product} />
                    </MDTypography>
                </MDBox>
                <MDBox display="flex">
                    <CustomAvatarGroup userSuggestions={userSuggestions} />
                </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pt={1}>
                <MDBox display="flex" alignItems="center" onClick={() => setShowComments(!showComments)}>
                    <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                        <Icon sx={{ fontWeight: 'bold' }}>comment</Icon>
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp; {userSuggestions?.length} oy
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <Typography variant="caption" color="text.secondary">
                        {createdBy?.username} - {formatDate(createdAt)}
                    </Typography>
                </MDBox>
            </MDBox>
            <MDBox>{showComments && <UserRatings userSuggestions={userSuggestions} />}</MDBox>
        </MDBox>
    );
}

export default SingleProduct;
