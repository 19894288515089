import { useState } from 'react';
import { useQuery } from 'react-query';

// MD2 PRO
import MDBox from 'components/MDBox';

// @mui
import Grid from '@mui/material/Grid';

// import FileView from 'components/General/File';
import AddFile from 'components/General/File/AddFile';
import AddMultiFile from 'components/General/File/MultiFile.js';

// API

// Aaro Components

import Chat from 'components/General/Chat';
import FileView from 'components/General/File';
import TaskListModalView from 'components/General/Task/Views/TaskList/ModalView';

import SingleTaskView from 'components/General/Task/Views/Task/sections/SingleTask';
import TaskDetails from 'components/General/Task/Views/Task/sections/TaskDetails';
import AddReminder from 'components/General/Reminder/AddReminder';
import DisplayReminders from 'components/General/Reminder/DisplayReminders';
import DeleteTask from 'components/General/Task/Views/Task/sections/DeleteTask';
import History from 'components/General/Task/Views/Task/sections/History/index.js';

import { getTask } from 'services/api/general/task';
import AaroLoadingScreen from 'components/Loading';

const FetchTask = async (taskID) => {
    const response = await getTask(taskID)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

function TodoDetailPage({ taskID }) {
    const [updateFiles, setUpdateFiles] = useState(0);

    const user = JSON.parse(localStorage.getItem('user'));

    const { data, isLoading, error, isFetching } = useQuery(['fetchSingleTask', taskID], () => FetchTask(taskID), {
        refetchOnWindowFocus: false,
    });

    if (isLoading) return <AaroLoadingScreen />;
    if (isFetching) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <MDBox mt={4}>
            <Grid container>
                <Grid item xs={12}>
                    <MDBox m={3}>
                        <SingleTaskView taskItem={data} />
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MDBox m={3}>
                        <TaskDetails task={data} />
                    </MDBox>
                    <MDBox
                        m={3}
                        mt={6}
                        bgColor="white"
                        display="grid"
                        alignItems="center"
                        color="white"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                    >
                        <AddMultiFile
                            relatedID={taskID}
                            additionalName={'additionalName'}
                            filePath={`tasks/${taskID}`}
                            setUpdateFiles={setUpdateFiles}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MDBox m={3}>
                        <MDBox bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1}>
                            <TaskListModalView
                                title="Alt Görevler"
                                taskID={data?._id}
                                taskListID={data?.taskList}
                                styleOverride={{ wrapItems: true }}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox m={3}>
                        <DeleteTask task={data} />
                    </MDBox>
                    <MDBox m={3}>
                        <History task={data} />
                    </MDBox>
                    <MDBox m={3}>
                        <DisplayReminders
                            relatedID={taskID}
                            // title={{ text: 'SMS, MAIL, SLACK' }}
                            // count="Samil K."
                            // percentage={{
                            // 	color: 'success',
                            // 	text: '03-05-22 12:25',
                            // }}
                            // icon={{
                            // 	color: 'primary',
                            // 	component: 'delete_forever',
                            // }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MDBox m={3}>
                        <Chat relatedID={taskID} />
                    </MDBox>

                    <MDBox m={3}>
                        <AddReminder
                            relatedID={taskID}
                            userIDs={[user.userID]}
                            title={data?.name}
                            message={data?.description}
                        />
                    </MDBox>
                    <MDBox m={3}>
                        <FileView relatedID={taskID} updateFiles={updateFiles} setUpdateFiles={setUpdateFiles} />
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default TodoDetailPage;
