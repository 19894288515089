import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function LineSkeleton({
	line = 3,
	animation = 'wave',
	width = 300,
}) {
	return (
		<Box sx={{ width: width }}>
			{[...Array(line).keys()].map(() => (
				<Skeleton animation={animation} />
			))}
		</Box>
	);
}
