import teklifGonderildiImage from 'assets/images/projects/teklif-gonderildi.png';
import kazanildiImage from 'assets/images/projects/kazanildi.png';
import kaybedildiImage from 'assets/images/projects/kaybedildi.png';
import uretiliyorImage from 'assets/images/projects/uretiliyor.png';
import uretildiImage from 'assets/images/projects/uretildi.png';
import bilinmiyorImage from 'assets/images/projects/bilinmiyor.png';

/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getProjectStatusColor = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'KAZANILDI':
            return 'linear-gradient(to right, #52c234, #061700)';
        case 'KAYBEDILDI':
            return 'linear-gradient(to right, #870000, #190a05)';
        case 'TEKLIF GÖNDERILDI':
            return 'linear-gradient(to right, #0575e6, #021b79)';
        case 'ÜRETILIYOR':
            return 'linear-gradient(to right, #f7971e, #ffd200)';
        case 'ÜRETILDI':
            return 'linear-gradient(to right, #136a8a, #267871)';

        default:
            return 'linear-gradient(to right, #485563, #29323c)';
    }
};

export const getDefaultAaroColor = () => '#00B8BA';

export const getDefaultColor = () => 'linear-gradient(to right, #485563, #29323c)';
export const getDuzeltilmeliColor = () => 'linear-gradient(to right,  #a73737, #7a2828)';
export const getFiyatBekleniyorColor = () => 'linear-gradient(to right, #44a08d, #093637)';
export const getTamamlandiColor = () => 'linear-gradient(to right, #485563, #29323c)';
export const getYeniColor = () => 'linear-gradient(to left, #4e54c8, #8f94fb)';
export const getTeklifGonderildiColor = () => 'linear-gradient(to right, #0575e6, #021b79)';
export const getTeklifKaybedildiColor = () => 'linear-gradient(to right, #870000, #190a05)';
export const getTeklifKazanildiColor = () => 'linear-gradient(to right, #52c234, #061700)';
export const getTeklifUretildiColor = () => 'linear-gradient(to right, #603813, #b29f94)';
export const getTeklifUretiliyorColor = () => 'linear-gradient(to right, #f7971e, #ffd200)';
export const getRevizeBekleniyorColor = () =>
    'linear-gradient(90deg, hsla(42, 94%, 57%, 0.7) 0%, hsla(211, 16%, 34%, 1) 83%)';

/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getProjectStatusColorNew = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'KAZANILDI':
            return 'success';
        case 'KAYBEDILDI':
            return 'primary';
        case 'TEKLIF GÖNDERILDI':
            return 'info';
        case 'ÜRETILIYOR':
            return 'warning';
        case 'ÜRETILDI':
            return 'secondary';

        default:
            return 'dark';
    }
};

/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getDemandStatusColor = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'FIYAT VERILDI':
            return 'success';
        case 'REVIZE BEKLENIYOR':
            return 'warning';
        case 'DÜZELTILMELI':
            return 'primary';
        case 'FIYAT BEKLENIYOR':
            return 'info';
        default:
            return 'secondary';
    }
};

/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getDemandStatusIcon = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'KAZANILDI':
            return 'emoji_events';
        case 'KAYBEDILDI':
            return 'highlight_off';
        case 'TEKLIF GÖNDERILDI':
            return 'mark_email_read';
        case 'ÜRETILIYOR':
            return 'factory';
        case 'ÜRETILDI':
            return 'check_circle';

        default:
            return 'question_mark';
    }
};
/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getDemandStatusImage = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'KAZANILDI':
            return kazanildiImage;
        case 'KAYBEDILDI':
            return kaybedildiImage;
        case 'TEKLIF GÖNDERILDI':
            return teklifGonderildiImage;
        case 'ÜRETILIYOR':
            return uretiliyorImage;
        case 'ÜRETILDI':
            return uretildiImage;

        default:
            return bilinmiyorImage;
    }
};
