export const prepareNameForKasa = (values) => {
    const body =
        'Kasa ' +
        values.kalinlik +
        'x' +
        values.en +
        'x' +
        values.boy +
        ' ' +
        values.yapi +
        ' ' +
        values.cins +
        ' ' +
        values.cinsAciklama +
        ' ' +
        values.koseliBombeli +
        ' ' +
        (values.soveMi ? 'Söve ' : '') +
        (values.soveMi ? '' : 'Lamba(' + values.lambaGenislik + 'x' + values.lambaDerinlik + ') ') +
        (values.fitil == 'L' ? '' : 'Fitil(' + values.fitil + ') ') +
        (values.pervazKanalGenisligi !== 0.5 || values.pervazKanalDerinligi !== 3
            ? 'Pervaz Kanalı(' + values.pervazKanalGenisligi + 'x' + values.pervazKanalDerinligi + ') '
            : '') +
        //+ (values.girinti)
        (values.ozelProfilTipi == 'Standart' ? '' : values.ozelProfilTipi + ' ') +
        (values.ozelProfilAciklama + ' ') +
        (values.dumanFitili ? '+Duman Fitili ' : '') +
        (values.kesim45 ? '+45 Kesim ' : '') +
        (values.kilitKarsiligi ? '+Kilit Karşılığı ' : '') +
        (values.menteseKarsiligi ? '+Menteşe Karşılığı ' : '') +
        (values.hidrolikKarsiligi ? '+Hidrolik Karşılığı ' : '') +
        (values.ekNot ? '*' + values.ekNot : '');

    return body;
};
export const prepareNameForUKasa = (values) => {
    const body = `${values.soveMi ? 'Söve' : ''} ${values.yapi} ${values.cins} ${values.cinsAciklama} (${
        values.kalinlik
    }x${values.en}x${values.boy}cm)
    lamba(${values.lambaDerinlik}x${values.lambaGenislik}) pervaz kanalı(${values.pervazKanalGenisligi}x${
        values.pervazKanalDerinligi
    })
     Fitil:${values.fitil} Profil(${values.ozelProfilTipi} ${values.ozelProfilAciklama}) 
    ${values.dumanFitili ? '+Duman Fitili' : ''} ${values.kesim45 ? '+45 Kesim' : ''} ${
        values.kilitKarsiligi ? '+Kilit Karşılığı' : ''
    } ${values.menteseKarsiligi ? '+Menteşe Karşılığı' : ''} ${values.hidrolikKarsiligi ? '+Hidrolik Karşılığı' : ''} ${
        values.ekNot ? '*' + values.ekNot : ''
    }`;
    return `U KASA ${body.toLocaleLowerCase()}`;
};
export const prepareNameForPervaz = (values) => {
    const gecmeIsimlendirme =
        values.yapi == 'Düz Pervaz' ? '' : `Geçme(${values.gecmeKalinlik}x${values.gecmeIctenUzunluk})`;

    const body = `${values.cins} ${values.cinsAciklama} ${values.koseliBombeli} ${values.yapi} (${values.kalinlik}x${
        values.en
    }x${values.boy}cm) ${gecmeIsimlendirme} Profil(${values.ozelProfilTipi} ${values.ozelProfilAciklama}) 
   ${values.hamZimpara ? '+Ham Zımpara' : ''} ${values.boyKesmeKertme ? '+Boy Kesme ve Kertme' : ''} ${
        values.ekNot ? '*' + values.ekNot : ''
    }
    `;
    return `PERVAZ ${body.toLocaleLowerCase()}`;
};
export const prepareNameForUPervaz = (values) => {
    const body = `${values.mdfKalinlik}cm ${values.yapi} ${values.cins} ${values.cinsAciklama} (${values.kalinlik}x${
        values.en
    }x${values.boy}cm)
    Geçme Kalınlığı:${values.gecmeKalinlik}cm Geçme İçten Uzunluğu:${values.gecmeIctenUzunluk}cm 
    Profil:${values.ozelProfilTipi} ${values.ozelProfilAciklama} 
   ${values.hamZimpara ? '+Ham Zımpara' : ''} ${values.boyKesmeKertme ? '+Boy Kesme ve Kertme' : ''} ${
        values.ekNot ? '*' + values.ekNot : ''
    }
    `;
    return `U PERVAZ ${body.toLocaleLowerCase()}`;
};
export const prepareNameForKanat = (values) => {
    const derzIsimlendirme =
        values.derzSekli == 'Yok'
            ? 'Derz Yok'
            : `DERZ(${values.derzAdedi} adet ${values.derzSekli} ${
                  parseInt(values.derzBicakAdedi, 10) > 1 ? values.derzBicakAdedi + ' farklı bıçak' : ''
              })`;
    const cumbaIsimlendirme =
        values.cumbaBandi == 'Yok'
            ? 'Cumba Yok'
            : `Cumba(Bantlama:${values.cumbaBandi}, Tipi:${values.cumbaTipi}, Bant Kalınlığı:${values.cumbaKalinligi}`;

    const dolguIsimlendirme = `${values.dolguTipi == 'Standart' ? '' : values.dolguTipi + ' dolgulu'}`;

    const body = `${dolguIsimlendirme} ${values.cins} ${values.cinsAciklama}(${values.kalinlik}x${values.en}x${
        values.boy
    }cm)
 ${derzIsimlendirme} 
 ${cumbaIsimlendirme}
 Cam Yeri ${values.camYeri} Kilit Yeri:${values.kilitYeri} Menteşe Yeri:${values.menteseYeri}
   ${values.hidrolik ? '+Hidrolik Yeri' : ''} ${values.giyotin ? '+Giyotin Kesim' : ''}${
        values.durbun ? '+Dürbün Yeri' : ''
    } ${values.sertAgacDonme ? '+Sert Ağaç Dönme' : ''} ${values.ekNot ? '*' + values.ekNot : ''}
    `;
    return `KANAT ${body.toLocaleLowerCase()}`;
};
