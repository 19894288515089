import React, { useState, useEffect } from 'react';
import Map from 'components/General/GoogleMap/Map';
import { updateProject } from 'services/api/project';

import AddProjectPosition from './AddProjectPosition';

import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function ProjectPosition(props) {
	const { project, showEdit = true } = props;

	const changeLatLng = (val) => {
		// console.log({ lat: val.lat, lng: val.lng, val });

		updateProject(project._id, {
			projectLocation: {
				lat: val.lat,
				lng: val.lng,
				label: val.value.label,
			},
		}).then(() => window.location.reload());
	};
	return (
		<Card id="delete-account">
			<MDBox pt={3} px={2}>
				<MDTypography variant="h6" fontWeight="medium">
					Proje Konumu
				</MDTypography>
			</MDBox>
			<MDBox pt={1} pb={2} px={2}>
				<MDBox
					component="ul"
					display="flex"
					flexDirection="column"
					p={0}
					m={0}
				>
					<Map
						enableCard={false}
						height={'23vh'}
						zoom={11}
						latLng={{
							lat: project?.projectLocation?.lat
								? project.projectLocation.lat
								: 39.9577965,
							lng: project?.projectLocation?.lng
								? project.projectLocation.lng
								: 32.8952745,
						}}
					/>
					{showEdit && (
						<AddProjectPosition changeLatLng={changeLatLng} />
					)}
				</MDBox>
			</MDBox>
		</Card>
	);
}
