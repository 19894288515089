import React from 'react';
import Grid from '@mui/material/Grid';
import FormSwitch from 'layouts/projects/Forms/FormComponents/FormSwitch';
import OutlinedFormTextField from 'layouts/projects/Forms/FormComponents/OutlinedFormTextField';
import { validationSchemaUPervazSheetTemplate } from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/YupValidationSchemaSheets.js';
import { uPervazToSheet, sheetToUPervaz } from '@samilkahraman/demand-namer';
import { BaseForm } from '../BaseForm';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

export const UPervazForm = (props) => {
    const allowedChanges = [
        'miktar',
        'kalinlik',
        'en',
        'boy',
        'mdfKalinlik',
        'gecmeKalinlik',
        'gecmeIctenUzunluk',
        'hamZimpara',
        'boyKesmeKertme',
    ];

    const renderFields = (formik, classes) => (
        <>
            <Grid container justifyContent="space-between" spacing={3}>
                {allowedChanges.includes('miktar') && (
                    <Grid item xs={12} sm={6} md={3}>
                        <OutlinedFormTextField
                            itemID="miktar"
                            label="Miktar (Adet)"
                            placeHolder="Örn: 1000"
                            type="number"
                            formik={formik}
                            step="1"
                            adornment="AD"
                        />
                    </Grid>
                )}
                {allowedChanges.includes('kalinlik') && (
                    <Grid item xs={12} sm={6} md={3}>
                        <OutlinedFormTextField
                            itemID="kalinlik"
                            label="Kalınlık"
                            placeHolder="Örn: 1.8"
                            type="number"
                            formik={formik}
                            step="0.1"
                            adornment="CM"
                        />
                    </Grid>
                )}
                {allowedChanges.includes('en') && (
                    <Grid item xs={12} sm={6} md={3}>
                        <OutlinedFormTextField
                            itemID="en"
                            label="En"
                            placeHolder="Örn: 10"
                            type="number"
                            formik={formik}
                            step="0.1"
                            adornment="CM"
                        />
                    </Grid>
                )}
                {allowedChanges.includes('boy') && (
                    <Grid item xs={12} sm={6} md={3}>
                        <OutlinedFormTextField
                            itemID="boy"
                            label="Boy"
                            placeHolder="Örn: 220"
                            type="number"
                            formik={formik}
                            step="0.1"
                            adornment="CM"
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container justifyContent="space-between" spacing={3}>
                {allowedChanges.includes('mdfKalinlik') && (
                    <Grid item xs={12} sm={6} md={4}>
                        <OutlinedFormTextField
                            itemID="mdfKalinlik"
                            label="MDF Kalınlık"
                            placeHolder="Örn: 0.6"
                            type="number"
                            formik={formik}
                            step="0.1"
                            adornment="CM"
                        />
                    </Grid>
                )}
                {allowedChanges.includes('gecmeKalinlik') && (
                    <Grid item xs={12} sm={6} md={4}>
                        <OutlinedFormTextField
                            itemID="gecmeKalinlik"
                            label="Geçme Kalınlık"
                            placeHolder="Örn: 0.4"
                            type="number"
                            formik={formik}
                            step="0.1"
                            adornment="CM"
                        />
                    </Grid>
                )}
                {allowedChanges.includes('gecmeIctenUzunluk') && (
                    <Grid item xs={12} sm={6} md={4}>
                        <OutlinedFormTextField
                            itemID="gecmeIctenUzunluk"
                            label="Geçme İçten Uzunluk"
                            placeHolder="Örn: 1.5"
                            type="number"
                            formik={formik}
                            step="0.1"
                            adornment="CM"
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container className={classes.switch}>
                {allowedChanges.includes('hamZimpara') && (
                    <FormSwitch formik={formik} name="hamZimpara" color="secondary" label="Ham Zımpara" />
                )}
                {allowedChanges.includes('boyKesmeKertme') && (
                    <FormSwitch formik={formik} name="boyKesmeKertme" color="secondary" label="Boy Kesme Kertme" />
                )}
            </Grid>
        </>
    );

    return (
        <BaseForm
            {...props}
            validationSchema={validationSchemaUPervazSheetTemplate}
            allowedChanges={allowedChanges}
            toSheet={uPervazToSheet}
            fromSheet={sheetToUPervaz}
            renderFields={renderFields}
        />
    );
};

export const UPervazBadges = (props) => {
    const { initial } = props;
    const values = sheetToUPervaz(initial);
    const toBadge = (degisken, aciklama, renk = 'dark') => {
        return (
            <MDBox>
                <MDBadge
                    m={1}
                    badgeContent={`${aciklama}: ${values[degisken] !== undefined ? values[degisken] : '-'}`}
                    size="xs"
                    container
                    variant="gradient"
                    color={renk}
                />
            </MDBox>
        );
    };
    return (
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12}>
                <MDBox display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                    {toBadge('kalinlik', 'Kalınlık', 'secondary')}
                    {toBadge('en', 'En', 'secondary')}
                    {toBadge('boy', 'Boy', 'secondary')}
                    {toBadge('mdfKalinlik', 'MDF Kalınlık', 'secondary')}
                    {toBadge('cins', 'Cins', 'secondary')}
                    {toBadge('cinsAciklama', 'Cins Açıklama', 'secondary')}
                    {toBadge('yapi', 'Yapı', 'secondary')}
                    {toBadge('gecmeKalinlik', 'Geçme Kalınlık', 'secondary')}
                    {toBadge('gecmeIctenUzunluk', 'Geçme İçten Uzunluk', 'secondary')}
                    {toBadge('hamZimpara', 'Ham Zımpara', 'secondary')}
                    {toBadge('boyKesmeKertme', 'Boy Kesme Kertme', 'secondary')}
                    {toBadge('ozelProfilTipi', 'Özel Profil Tipi', 'secondary')}
                    {toBadge('ozelProfilAciklama', 'Özel Profil Açıklama', 'secondary')}
                    {toBadge('ekNot', 'Ek Not', 'dark')}
                </MDBox>
            </Grid>
        </Grid>
    );
};
