// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { formatDate } from 'helpers/smallCodes';
import { colorAndLabelDecider } from 'layouts/purchases/helpers/general';

function Header({ orderNumber, orderDate, customerName }) {
    return (
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
                <MDBox mb={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                        Sipariş Detayları #{orderNumber}
                    </MDTypography>
                </MDBox>
                <MDTypography component="p" variant="button" color="text">
                    Tarih: <b>{formatDate(orderDate)}</b>
                </MDTypography>
                <MDTypography component="p" variant="button" fontWeight="regular" color="text">
                    Müşteri: <b>{customerName}</b>
                </MDTypography>
            </MDBox>
            {(() => {
                const { color, label } = colorAndLabelDecider(orderDate);
                return (
                    <MDButton variant="gradient" color={color}>
                        {label}
                    </MDButton>
                );
            })()}
        </MDBox>
    );
}

export default Header;
