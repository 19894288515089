import React from 'react';
// import ReactJson from 'react-json-view';
import { useQueryClient } from 'react-query';

import MDAlert from 'components/MDAlert';
// @mui material components
import Icon from '@mui/material/Icon';

export default function DataWarning({ project }) {
    const queryClient = useQueryClient();

    const responsibles = queryClient.getQueryData(['fetchAaroCustomerResponsibles', project?.aaroCustomerID]);
    // const tasks = queryClient.getQueryData(['fetchAaroCustomerResponsibles', project?.taskList, null]);

    const hasValidResponsibles = () => {
        if (responsibles && responsibles.length > 0) {
            return responsibles.every((responsible) => {
                return (
                    responsible.IlgiliAdi &&
                    responsible.IlgiliAdi.length > 2 &&
                    responsible.Tel &&
                    responsible.Tel.length > 8
                );
            });
        }
        return false;
    };
    const user = JSON.parse(localStorage.getItem('user'));

    // const tasksCompleted = () => {
    //     if (tasks && tasks.length > 0) {
    //         return tasks.every((task) => task.completed);
    //     }
    //     return false;
    // };
    return (
        <>
            {project?.projectPotential === 0 ||
            project?.projectPotential === null ||
            project?.projectLocation == null ||
            project?.source?.length === 0 ||
            project.source == null ? (
                <MDAlert color="error" dismissible>
                    Projeniz fiyat verilmeye uygun değil!
                </MDAlert>
            ) : null}

            {user.role !== 'superadmin' ? (
                !hasValidResponsibles() ? (
                    <MDAlert color="dark" dismissible>
                        <Icon fontSize="small">warning_amber</Icon>&nbsp; Sorumlular hatalı veya eksik!
                    </MDAlert>
                ) : null
            ) : null}

            {/* {!tasksCompleted() ? (
                <MDAlert color="dark" dismissible>
                    <Icon fontSize="small">warning_amber</Icon>&nbsp; Görevler listesi tamamlandı işaretlenmemiş
                </MDAlert>
            ) : null} */}
            {project?.projectPotential === 0 || project?.projectPotential === null ? (
                <MDAlert color="dark" dismissible>
                    <Icon fontSize="small">warning_amber</Icon>&nbsp; Proje Potansiyeli Girilmemiş!
                </MDAlert>
            ) : null}
            {project?.projectLocation == null ? (
                <MDAlert color="dark" dismissible>
                    <Icon fontSize="small">warning_amber</Icon>&nbsp; Proje Konumu Girilmemiş!
                </MDAlert>
            ) : null}

            {(project.source && project.source.length === 0) || project.source == null ? (
                <MDAlert color="dark" dismissible>
                    <Icon fontSize="small">warning_amber</Icon>&nbsp; Nasıl geldi bilgisi girilmemiş!
                </MDAlert>
            ) : null}
        </>
    );
}
