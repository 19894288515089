import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { deleteProject } from 'services/api/project';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ProjectActivation(props) {
	const { project } = props;
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const delProject = async () => {
		const result = window.prompt('Lütfen silmek için onaylıyorum yazın');
		if (result === 'onaylıyorum') {
			deleteProject(project._id).then(() => {
				queryClient.invalidateQueries(['fetchProjects', 'active']);
				queryClient.invalidateQueries(['fetchProjects', 'passive']);
				navigate('../projects');
			});
		}
	};

	return (
		<IconButton
			aria-label="delete"
			onClick={() => delProject()}
			size="large"
		>
			<DeleteIcon fontSize="large" />
		</IconButton>
	);
}
