import { calculateTimePassed } from 'helpers/dateHelpers';

export const colorAndLabelDecider = (date) => {
    const days = calculateTimePassed(date);
    let color = '';
    let label = days + ' GÜN';
    if (days <= 2) {
        color = 'success';
    } else if (days > 2 && days <= 3) {
        color = 'info';
    } else if (days > 3 && days <= 5) {
        color = 'warning';
    } else {
        color = 'error';
    }
    return { color, label };
};
