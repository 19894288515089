import React, { useState, useContext } from 'react';
import { BasketContext } from 'layouts/aaro/fastPrice';

import TextField from '@mui/material/TextField';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import SelectCustomer from './SelectCustomer';

// Burası doğrudan default bir cariye kalemleri atmaktadır.
export default function ExportToAaro(props) {
	const [dekontType, setDekontType] = useState(10016);
	const [detail, setDetail] = useState('');
	const { basket, setBasket } = useContext(BasketContext);
	const [open, setOpen] = useState(false);

	const saveLocally = () =>
		localStorage.setItem('basket', JSON.stringify(basket));

	return (
		<MDBox>
			<MDBox>
				<TextField
					id="outlined-basic"
					label="Açıklama"
					placeholder="Açıklama Giriniz"
					type="text"
					value={detail}
					onChange={(e) => setDetail(e.target.value)}
				/>
				&nbsp;&nbsp;&nbsp;
				<MDButton
					size="small"
					variant="contained"
					color="secondary"
					onClick={(event) => {
						event.preventDefault();
						setDekontType(10016);
						setOpen(true);
						saveLocally();
					}}
				>
					TEKLİF
				</MDButton>{' '}
				&nbsp;&nbsp;&nbsp;
				<MDButton
					color="dark"
					size="small"
					variant="contained"
					onClick={(event) => {
						event.preventDefault();
						setDekontType(10005);
						setOpen(true);
						saveLocally();
					}}
				>
					FATURA
				</MDButton>{' '}
			</MDBox>
			{open && (
				<SelectCustomer
					products={basket}
					dekontType={dekontType}
					detail={detail}
				/>
			)}
		</MDBox>
	);
}
