import * as React from 'react';

import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    connectHits,
    Configure,
    connectSearchBox,
    connectHitInsights,
    connectPagination,
    connectRefinementList,
    Panel,
    connectRange,
    connectCurrentRefinements
    // Hits,
} from 'react-instantsearch-dom';

import MDBox from 'components/MDBox';

import SingleView from './SingleItem';
import RangeSlider from 'layouts/aaro/fastPrice/views/algoliaView/CustomComponents/RangeSlider.js';
import Pagination from 'layouts/aaro/fastPrice/views/algoliaView/CustomComponents/Pagination.js';
import SearchBox from 'layouts/aaro/fastPrice/views/algoliaView/CustomComponents/SearchBox.js';
import RefinementList from 'layouts/aaro/fastPrice/views/algoliaView/CustomComponents/RefinementList.js';
import ClearRefinements from 'layouts/aaro/fastPrice/views/algoliaView/CustomComponents/ClearRefinements.js';

import Grid from '@mui/material/Grid';
import MDTypography from 'components/MDTypography';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MDButton from 'components/MDButton';
import { config } from 'Constants';

const searchClient = algoliasearch(
    config?.algolia?.aaroProdAppID,
    config?.algolia?.aaroProdSearchKey
);

const Hit = React.memo(({ hit, insights }) => <SingleView hit={hit} insights={insights} />);
const HitWithInsights = connectHitInsights(window.aa)(Hit);

const Hits = ({ hits, insights }) =>
    hits.map((hit) => <HitWithInsights key={hit.objectID} hit={hit} insights={insights} />);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
const CustomHits = connectHits(Hits);

const CustomSearchBox = connectSearchBox(SearchBox);

const CustomRangeSlider = connectRange(RangeSlider);

const CustomPagination = connectPagination(Pagination);

const CustomRefinementList = connectRefinementList(RefinementList);

export const Algolia = React.memo((props) => {
    const [defaultFilter, setDefaultFilter] = React.useState('rankingPrice>0');
    window.aa('setUserToken', JSON.parse(localStorage.getItem('user'))?.userID);

    React.useEffect(() => {
        console.log('algolia view render');
    });
    const handleFilter = () => {
        if (defaultFilter.length > 0) {
            setDefaultFilter('');
        } else {
            setDefaultFilter('rankingPrice>0');
        }
    };

    return (
        <Grid container>
            <InstantSearch indexName="improvedProd" searchClient={searchClient}>
                <Grid item xs={12}>
                    <MDBox display="flex" flexDirection="row-reverse" mr={1}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={defaultFilter.length > 0 ? true : false}
                                        onChange={handleFilter}
                                    />
                                }
                                label="Sadece güncel fiyatı olanları göster"
                            />
                        </FormGroup>
                    </MDBox>
                    <MDBox
                        mx={3}
                        color="white"
                        bgColor="white"
                        variant="gradient"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={1}>
                        <Configure
                            hitsPerPage={5}
                            clickAnalytics
                            filters={defaultFilter}
                            enablePersonalization={true}
                        />
                        {/* <Configure hitsPerPage={8} clickAnalytics /> */}
                        <CustomSearchBox />
                    </MDBox>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <MDBox component={Grid} item xs={3} display={{ xs: 'none', lg: 'block' }}>
                            <MDBox
                                color="white"
                                bgColor="white"
                                variant="gradient"
                                borderRadius="lg"
                                shadow="lg"
                                opacity={1}
                                my={3}
                                ml={3}>
                                <MDBox pl={3}>
                                    <MDBox mb={1} display="flex" justifyContent="center" pr={3}>
                                        {/* <MDButton onClick={clearFilters}>
											Filtreleri Sıfırla
										</MDButton> */}
                                        <CustomClearRefinements />
                                    </MDBox>
                                    <MDBox mb={1}>
                                        <MDTypography variant="caption">Kalınlık</MDTypography>
                                    </MDBox>
                                    <CustomRangeSlider attribute="dimension.height" />

                                    <MDBox mb={1}>
                                        <MDTypography variant="caption">En</MDTypography>
                                    </MDBox>

                                    <CustomRangeSlider attribute="dimension.width" />
                                    <MDBox mb={1}>
                                        <MDTypography variant="caption">Boy</MDTypography>
                                    </MDBox>

                                    <CustomRangeSlider attribute="dimension.length" />
                                    {/* <Divider /> */}
                                </MDBox>

                                <MDBox pl={3}>
                                    <MDTypography>Kategoriler</MDTypography>
                                    <CustomRefinementList
                                        attribute="aaroCodes"
                                        operator="and"
                                        searchable
                                        searchablePlaceholder="Search our products"
                                        // showMore={true}
                                        // showMoreLimit={93}
                                    />
                                    <Divider />
                                </MDBox>
                                <MDBox pl={3}>
                                    <MDTypography>Etiketler</MDTypography>
                                    <CustomRefinementList attribute="aaroTags" operator="and" />
                                    <Divider />
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox component={Grid} item xs={12} sm={12} md={9}>
                            <MDBox my={3}>
                                <CustomHits />
                            </MDBox>
                            <MDBox m={3} display="flex" justifyContent="center">
                                <CustomPagination />
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
            </InstantSearch>
        </Grid>
    );
});
