import { useQuery } from 'react-query';
import { getGroups } from 'services/api/project';

import MDBox from 'components/MDBox';
import Groups from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GroupElements/Groups';
import GroupItems from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GroupElements/GroupItems';
import AaroLoadingScreen from 'components/Loading';
// import ReactJson from 'react-json-view';

const fetchGroups = async (demandID) => {
    const response = await getGroups(demandID)
        .then((rp) => {
            const result = rp.data.result;

            // null referansları filtrele
            result.forEach((group) => {
                group.uKasa = group.uKasa.filter((item) => item.ref !== null);
                group.uPervaz = group.uPervaz.filter((item) => item.ref !== null);
                group.pervaz = group.pervaz.filter((item) => item.ref !== null);
                group.kasa = group.kasa.filter((item) => item.ref !== null);
                group.kanat = group.kanat.filter((item) => item.ref !== null);
                group.diger = group.diger.filter((item) => item.ref !== null);
            });

            return result;
        })
        .catch((err) => err);
    return response;
};

export default function GroupElements({ demand }) {
    const { _id: demandID } = demand;
    const { data, isLoading, error } = useQuery(['fetchDemandGroups', demandID], () => fetchGroups(demandID), {
        staleTime: 600000,
    });

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <MDBox>
            <Groups groups={data} demandID={demandID} />
            {/* <MDBox>
                <ReactJson src={data} />
            </MDBox> */}
            <GroupItems groups={data} demand={demand} />
            {/* <ReactJson src={demand} /> */}
        </MDBox>
    );
}
