import React, { useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// MD2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { copyProject, deleteProject } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';

function CopyMenuItem({ projectID, onClose }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleCopy = useCallback(
        (e) => {
            e.preventDefault();
            copyProject(projectID)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchProjects', 'active']);
                    queryClient.invalidateQueries(['fetchProjects', 'passive']);
                    const newProjectID = rp?.data?.result?._id;
                    navigate(`../projects/${newProjectID}`);
                })
                .catch((err) => notify(false, JSON.stringify(err)));

            onClose();
        },
        [projectID, navigate, queryClient, onClose]
    );

    return <MenuItem onClick={handleCopy}>Kopyala</MenuItem>;
}

function DeleteMenuItem({ projectID, onClose }) {
    const queryClient = useQueryClient();

    const handleDelete = useCallback(
        (e) => {
            e.preventDefault();
            const result = window.prompt('Lütfen silmek için onaylıyorum yazın');
            if (result === 'onaylıyorum') {
                deleteProject(projectID)
                    .then((rp) => {
                        queryClient.invalidateQueries(['fetchProjects', 'active']);
                        queryClient.invalidateQueries(['fetchProjects', 'passive']);
                        notify(rp.data.success, rp.data.message);
                    })
                    .catch((err) => notify(false, JSON.stringify(err)));
            }

            onClose();
        },
        [projectID, queryClient, onClose]
    );

    return <MenuItem onClick={handleDelete}>Sil</MenuItem>;
}

export default function FastEdit({ projectID }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <MDBox>
            <MDTypography
                color="secondary"
                sx={{
                    ml: 'auto',
                    mt: -1,
                    alignSelf: 'flex-start',
                    py: 1.25,
                    display: 'flex', // Added this
                    justifyContent: 'center', // Added this
                    alignItems: 'center', // Added this
                }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Icon
                    fontSize="default"
                    sx={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}
                >
                    more_vert
                </Icon>
            </MDTypography>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                keepMounted
            >
                <CopyMenuItem projectID={projectID} onClose={handleClose} />
                <DeleteMenuItem projectID={projectID} onClose={handleClose} />
                {/* <MenuItem onClick={close}>Another action</MenuItem>
                <MenuItem onClick={close}>Something else here</MenuItem> */}
            </Menu>
        </MDBox>
    );
}
