import React from 'react';
import MDBadge from 'components/MDBadge';

function CustomBadge({ avgRating }) {
    let badgeColor = '';

    if (avgRating < 1.5) {
        badgeColor = 'primary';
    } else if (avgRating < 2.5) {
        badgeColor = 'warning';
    } else if (avgRating < 3.5) {
        badgeColor = 'info';
    } else {
        badgeColor = 'success';
    }

    return (
        <MDBadge
            size="xs"
            color={badgeColor}
            badgeContent={`${avgRating.toFixed(2)} ort.`}
            container
            variant="gradient"
        />
    );
}

export default CustomBadge;
