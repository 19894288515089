

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBadge from 'components/MDBadge';

// helpers
import FreezeDemand from 'layouts/projects/Components/Offer/components/DemandButtons/FreezeDemand';
import ViewDemand from 'layouts/projects/Components/Offer/components/DemandButtons/ViewDemand';
import BackToHistoryButton from 'layouts/projects/Components/Offer/components/DemandButtons/BackToHistoryButton';
import { formatDateMinutes } from 'helpers/dateHelpers';

function Header({ demand, isHistory, projectID, setValue }) {
	const user = JSON.parse(localStorage.getItem('user'));
	return (
		<MDBox
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<MDBox>
				<MDBox mb={1}>
					<MDTypography variant="h6" fontWeight="medium">
						Teklif Detayları
					</MDTypography>
				</MDBox>
				<MDTypography component="p" variant="button" color="text">
					Tarih:&nbsp;
					<b>
						{demand.freezedDate
							? formatDateMinutes(demand.freezedDate)
							: formatDateMinutes(Date.now())}
					</b>
				</MDTypography>
				<MDTypography
					component="p"
					variant="button"
					fontWeight="regular"
					color="text"
				>
					Kullanıcı:{' '}
					<b>
						{demand.freezedBy ? demand.freezedBy?.name : user?.name}
					</b>
				</MDTypography>
				<MDBox
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
				>
					<MDBox mr={1}>
						<MDBadge
							variant="gradient"
							color="info"
							size="xs"
							badgeContent={
								demand?.demandCurrency
									? demand.demandCurrency
									: 'TRY'
							}
							container
						/>{' '}
					</MDBox>
					<CurrencyAmounts demand={demand} />
				</MDBox>
			</MDBox>
			{isHistory ? (
				<MDBox display="flex">
					<MDBox p={1}>
						<ViewDemand
							demandID={demand._id}
							projectID={projectID}
						/>
					</MDBox>
					<MDBox p={1}>
						<BackToHistoryButton
							demandID={demand._id}
							projectID={projectID}
						/>
					</MDBox>
				</MDBox>
			) : (
				<FreezeDemand
					demandID={demand._id}
					projectID={projectID}
					setValue={setValue}
				/>
			)}
		</MDBox>
	);
}

const CurrencyAmounts = ({ demand }) => {
	if (demand.demandCurrencyValues) {
		return (
			<>
				{demand.demandCurrencyValues.USD > 1 && (
					<MDBox mr={1}>
						<MDBadge
							variant="gradient"
							color="success"
							size="xs"
							badgeContent={
								'USD: ' + demand.demandCurrencyValues.USD
							}
							container
						/>
					</MDBox>
				)}
				{demand.demandCurrencyValues.EUR > 1 && (
					<MDBox mr={1}>
						<MDBadge
							variant="gradient"
							color="success"
							size="xs"
							badgeContent={
								'EUR: ' + demand.demandCurrencyValues.EUR
							}
							container
						/>
					</MDBox>
				)}
			</>
		);
	}
	return null;
};

export default Header;
