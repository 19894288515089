import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { useMutation } from 'react-query';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { updateCustomer } from 'services/api/customer';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';

export default function DayRating({
	customer,
	labelSize = '33px',
	readOnly = false,
}) {
	const queryClient = useQueryClient();

	const [rating, setRating] = useState(
		customer?.potential ? customer.potential : 0
	);
	const updateRating = useMutation(
		(rating) => updateCustomer(customer._id, { potential: rating }),
		{
			onSuccess: (rp) => {
				notify(rp.data.success, rp.data.message);
				queryClient.invalidateQueries(['fetchCustomers', customer._id]);
			},
			onError: (error) => {
				notify(false, error);
			},
		}
	);
	const StyledRating = withStyles({
		iconFilled: {
			color: '#ffb400',
		},
		iconHover: {
			color: '#ffb400',
		},
		label: {
			fontSize: labelSize,
		},
	})(Rating);
	return (
		<StyledRating
			name="day-rating"
			// defaultValue={2}
			value={rating}
			readOnly={readOnly}
			getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
			precision={1}
			max={10}
			icon={<StarIcon fontSize="inherit" />}
			onChange={(event, newValue) => {
				event.preventDefault();
				updateRating.mutate(newValue);
				setRating(newValue);
			}}
		/>
	);
}
