import axios from 'axios';

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getFormSubmissions = (apiKey, formID, offset, limit) => {
	const headers = {
		// 'Content-Type': 'application/json',
		// 'Access-Control-Allow-Origin': '*',
	};
	const url = `https://api.jotform.com/form/${formID}/submissions?apiKey=${apiKey}&limit=1000`;
	return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getFormSubmissionsFilter = (apiKey, formID, filter) => {
	const headers = {
		// 'Content-Type': 'application/json',
		// 'Access-Control-Allow-Origin': '*',
	};
	const url = `https://api.jotform.com/form/${formID}/submissions?apiKey=${apiKey}&limit=1000&filter=${filter}`;
	return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getFormQuestions = (apiKey, formID, offset, limit) => {
	const headers = {};
	const url = `https://api.jotform.com/form/${formID}/questions?apiKey=${apiKey}&offset=${offset}&limit=${limit}`;
	return axios.get(url, { headers });
};
