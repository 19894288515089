import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// Soft UI Context Provider
import { MaterialUIControllerProvider } from 'context';

// Constants
import { config } from 'Constants';
import { SearchProvider } from 'layouts/projects/Pages/SearchProvider';

// import LogRocket from 'logrocket';
// LogRocket.init('aspebz/ahsaplar');

const queryClient = new QueryClient();

ReactDOM.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <MaterialUIControllerProvider>
                <SearchProvider>
                    <App />
                    <Toaster />
                </SearchProvider>
            </MaterialUIControllerProvider>
            <ReactQueryDevtools initialIsOpen={config.url.API_URL.includes('localhost')} />
        </QueryClientProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
