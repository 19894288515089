import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function FormSelect(props) {
    const { inputName, items, helperText, formik, itemID, required = true, disabled = false } = props;

    return (
        <MDBox m={1}>
            <FormControl
                sx={{
                    width: '100%',
                    minWidth: 120,
                }}
            >
                <InputLabel id={inputName}>
                    <MDTypography variant="overline" align="center">
                        {inputName}
                    </MDTypography>
                </InputLabel>
                <Select
                    aria-label={itemID}
                    name={itemID}
                    labelId={inputName}
                    id={inputName}
                    value={formik.values[itemID]}
                    onChange={formik.handleChange}
                    error={formik.touched[itemID] && Boolean(formik.errors[itemID])}
                    helperText={formik.touched[itemID] && formik.errors[itemID]}
                    required={required}
                    sx={{
                        minHeight: '44px',
                    }}
                    disabled={disabled}
                >
                    {items.map((item) => (
                        <MenuItem value={item.value}>{item.name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {' '}
                    <MDTypography variant="caption" align="center">
                        {helperText}
                    </MDTypography>
                </FormHelperText>
            </FormControl>
        </MDBox>
    );
}
