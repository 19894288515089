import {
    prepareNameForKanat,
    prepareNameForUPervaz,
    prepareNameForPervaz,
    prepareNameForUKasa,
    prepareNameForKasa,
} from 'layouts/projects/Forms/Templates/TemplateForm/calculator/namer.js';

import {
    kasaSoveMi,
    kanatDetaylariniHesapla,
    kanatBoyHesapla,
    kanatEnHesapla,
    kanatKalinlikHesapla,
    kasaDetaylariniHesapla,
    kasaAdetHesapla,
    kasaEnHesapla,
    getKanatProperty,
    getPervazProperty,
    getKasaProperty,
    kasaBaslikBoyHesapla,
    kasaYanBoyHesapla,
    prepareNameForKasas,
    pervazBoyHesapla,
    pervazAdetHesapla,
    prepareNameForPervazs,
} from 'layouts/projects/Forms/Templates/TemplateForm/calculator/functions.js';

export const doorTemplateCalculator = (selectedDoorTemplate, selectedParameters) => {
    let updatedDoor = { ...selectedDoorTemplate };
    let doorItems = JSON.parse(JSON.stringify(updatedDoor));
    const {
        olcuBilgisi,
        kalinlik: duvarKalinlik,
        en: duvarEn,
        boy: duvarBoy,
        adet: kapiAdedi,
        yanginDayanimSuresi,
        montajBoslugu,
        kanatKasaYanBoslugu,
        kanatKasaUstBoslugu,
        kanatAltBoslugu,
    } = selectedParameters;

    // default kanat
    const defaultKanatKalinlik = getKanatProperty(doorItems, 'kalinlik');
    const defaultKanatMDFYuzeyKalinlik = getKanatProperty(doorItems, 'mdfYuzeyKalinlik');
    const defaultKanatMaxDerzDerinligi = getKanatProperty(doorItems, 'maxDerzDerinligi');
    const defaultKanatEkNot = getKanatProperty(doorItems, 'ekNot');
    const kanatKalinlik = kanatKalinlikHesapla(
        defaultKanatKalinlik,
        defaultKanatMDFYuzeyKalinlik,
        defaultKanatMaxDerzDerinligi,
        yanginDayanimSuresi
    );

    // default kasa
    const defaultKasaKalinlik = getKasaProperty(doorItems, 'kalinlik');
    const defaultKasaLambaDerinlik = getKasaProperty(doorItems, 'lambaDerinlik');
    const defaultKasaLambaGenislik = getKasaProperty(doorItems, 'lambaGenislik');
    const defaultKasaEkNot = getKasaProperty(doorItems, 'ekNot');

    // default pervaz
    const defaultPervazGenislik = getPervazProperty(doorItems, 'en');

    const kasaYanBoyu = kasaYanBoyHesapla(olcuBilgisi, duvarBoy, montajBoslugu);
    const kasaBaslikBoyu = kasaBaslikBoyHesapla(olcuBilgisi, duvarEn, montajBoslugu);
    const kasaAdet = kasaAdetHesapla(kapiAdedi, kasaBaslikBoyu);
    const kasaEni = kasaEnHesapla(olcuBilgisi, duvarKalinlik);
    const { kalinlik: kasaKalinlik, lambaDerinlik: kasaLambaDerinlik } = kasaDetaylariniHesapla(
        defaultKasaKalinlik,
        defaultKasaLambaDerinlik,
        defaultKasaLambaGenislik,
        yanginDayanimSuresi,
        defaultKasaEkNot,
        kanatKalinlik
    );

    const kanatEn = kanatEnHesapla(
        olcuBilgisi,
        duvarEn,
        montajBoslugu,
        kanatKasaYanBoslugu,
        kasaKalinlik,
        kasaLambaDerinlik
    );
    const kanatBoy = kanatBoyHesapla(
        olcuBilgisi,
        duvarBoy,
        montajBoslugu,
        kanatAltBoslugu,
        kanatKasaUstBoslugu,
        kasaKalinlik,
        kasaLambaDerinlik
    );

    const pervazBoy = pervazBoyHesapla(kasaYanBoyu, defaultPervazGenislik);
    const pervazMiktar = pervazAdetHesapla(kasaBaslikBoyu, kapiAdedi);

    // kanat

    for (let key in doorItems) {
        if (key === 'kanat') {
            doorItems[key] = doorItems[key].map((item) => {
                const newItem = {
                    ...item,
                    miktar: kapiAdedi,
                    en: kanatEn,
                    boy: kanatBoy,
                    kalinlik: kanatKalinlik,
                    ...kanatDetaylariniHesapla(yanginDayanimSuresi, defaultKanatEkNot),
                    baslik: prepareNameForKanat({
                        ...item,
                        miktar: kapiAdedi,
                        en: kanatEn,
                        boy: kanatBoy,
                        kalinlik: kanatKalinlik,
                        ...kanatDetaylariniHesapla(yanginDayanimSuresi, defaultKanatEkNot),
                    }),
                };

                delete newItem._id;

                return newItem;
            });
        } else if (key === 'kasa' || key === 'uKasa') {
            doorItems[key] = doorItems[key].map((item) => {
                const newItem = {
                    ...item,
                    miktar: kasaAdet,
                    en: kasaEni,
                    boy: kasaYanBoyu,
                    ...kasaDetaylariniHesapla(
                        defaultKasaKalinlik,
                        defaultKasaLambaDerinlik,
                        defaultKasaLambaGenislik,
                        yanginDayanimSuresi,
                        defaultKasaEkNot,
                        kanatKalinlik
                    ),
                    ...kasaSoveMi(kasaEni),
                    baslik: prepareNameForKasas(
                        key,
                        item,
                        kasaAdet,
                        kasaEni,
                        kasaYanBoyu,
                        defaultKasaKalinlik,
                        defaultKasaLambaDerinlik,
                        defaultKasaLambaGenislik,
                        yanginDayanimSuresi,
                        defaultKasaEkNot,
                        kanatKalinlik
                    ),
                };

                delete newItem._id;

                return newItem;
            });
        } else if (key === 'pervaz' || key === 'uPervaz') {
            doorItems[key] = doorItems[key].map((item) => {
                // Yeni bir obje oluşturuyoruz ve tüm item değerlerini bu objeye atıyoruz.
                const newItem = {
                    ...item,
                    miktar: pervazMiktar,
                    boy: pervazBoy,
                    baslik: prepareNameForPervazs(key, item, pervazMiktar, pervazBoy),
                };

                // Eğer newItem bir _id alanına sahipse, bu alanı siliyoruz.
                delete newItem._id;

                // Sonuçta, _id'si silinmiş yeni objeyi dönüyoruz.
                return newItem;
            });
        } else if (key === 'diger') {
            doorItems[key] = doorItems[key].map((item) => {
                const newItem = {
                    ...item,
                    miktar: item.miktar * kapiAdedi,
                };

                delete newItem._id;

                return newItem;
            });
        }
    }
    return doorItems;
};
