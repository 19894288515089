import React from 'react';
import ReactJson from 'react-json-view';
import EditGroupItems from './EditGroupItems';
const GroupItems = ({ demand, groups }) => {
    // const { demandItems, demandItemsReduced, groupItems } = useGroupItems(demand, groups);
    // const { groups } = demand;

    // Component render
    return (
        <>
            <EditGroupItems groups={groups} demand={demand} />{' '}
        </>
    );
};

export default GroupItems;
