import React from 'react';
import { useQueryClient } from 'react-query';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
// @mui material components
import Icon from '@mui/material/Icon';
import { updateLead } from 'services/api/lead';

// Material Dashboard 2 PRO React components
import MDButton from 'components/MDButton';
const useStyles = makeStyles({
    avatar: {},
    profilePicture: {
        color: 'transparent',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        textAlign: 'center',
        textIndent: '10000px',
    },
});

function SimpleDialog(props) {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const { onClose, selectedValue, open, allResponsibles, leadID, users } =
        props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const updateResponsibles = async (user) => {
        const sorumlular = allResponsibles.map(
            (responsible) => responsible._id
        );

        if (sorumlular.includes(user._id)) {
            const index = sorumlular.indexOf(user._id);
            sorumlular.splice(index, 1);
            updateLead(leadID, {
                responsibles: sorumlular,
            }).then((rp) => {
                queryClient.invalidateQueries(['fetchLeads', leadID]);
                // setOpen(false);
            });
        } else {
            updateLead(leadID, {
                responsibles: [...allResponsibles, user._id],
            }).then((rp) => {
                queryClient.invalidateQueries(['fetchLeads', leadID]);
                // setOpen(false);
            });
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Sorumluları Düzenle
                {/* {JSON.stringify(users)} */}
            </DialogTitle>
            <List>
                {allResponsibles
                    ? users?.map((user) => (
                          <ListItem
                              button
                              onClick={() => updateResponsibles(user)}
                              key={user}
                              selected={allResponsibles.some(
                                  (el) => el._id === user._id
                              )}
                          >
                              <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                      {user.profilePicture ? (
                                          <img
                                              src={user.profilePicture}
                                              alt="..."
                                              className={classes.profilePicture}
                                          />
                                      ) : (
                                          <PersonIcon />
                                      )}
                                  </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={user.name} />
                          </ListItem>
                      ))
                    : ''}
            </List>
        </Dialog>
    );
}

export default function AddResponsible(props) {
    const { allResponsibles, leadID, setUpdateResponsibles, users } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <MDButton
                variant="outlined"
                color="dark"
                iconOnly
                onClick={() => setOpen(true)}
                sx={{
                    borderRadius: 8,
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    minHeight: 32,
                }}
            >
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            </MDButton>
            <SimpleDialog
                setOpen={setOpen}
                open={open}
                setUpdateResponsibles={setUpdateResponsibles}
                onClose={() => setOpen(false)}
                allResponsibles={allResponsibles}
                leadID={leadID}
                users={users}
            />
        </>
    );
}
