import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { updateProject } from 'services/api/project';
import Button from '@mui/material/Button';
import { getDefaultColor } from '../../helpers/colorHelper';
import { notify } from 'helpers/notificationHelper';

export default function InputArea(props) {
	const { project, setOpen } = props;
	const queryClient = useQueryClient();
	const [sending, setSending] = useState(false);
	const [projectName, setProjectName] = useState(project.projectName);

	const updateRating = useMutation(
		(newName) => updateProject(project._id, { projectName: newName }),
		{
			onSuccess: (rp) => {
				notify(rp.data.success, rp.data.message);
				queryClient.invalidateQueries(['fetchProject', project._id]);
				setOpen(false);
			},
			onError: (error) => {
				notify(false, error);
			},
		}
	);
	// useEffect(() => {
	// 	setProjectName(project.projectName);
	// }, [props]);
	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<TextField
				placeholder="Proje adını giriniz"
				value={projectName}
				onChange={(e) => setProjectName(e.target.value)}
				type="text"
				fullWidth
			/>
			<Button
				disabled={sending}
				style={{ background: getDefaultColor(), color: 'white' }}
				variant="contained"
				onClick={(event) => {
					event.preventDefault();
					setSending(true);
					updateRating.mutate(projectName);
				}}
			>
				{sending ? <CircularProgress /> : 'Güncelle'}
			</Button>
		</div>
	);
}
