import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from 'react-query';
import { postGroup, updateGroup, deleteGroup } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const calculateTotals = (group) => {
    const categories = ['uKasa', 'uPervaz', 'pervaz', 'kasa', 'kanat', 'diger'];
    let totalItems = 0;
    let totalQuantity = 0;

    categories.forEach((category) => {
        totalItems += group[category].length;
        totalQuantity += group[category].reduce((sum, item) => sum + item.quantity, 0);
    });

    return {
        totalItems,
        totalQuantity,
    };
};
const DataTable = ({ groups, demandID }) => {
    const [open, setOpen] = React.useState(false);
    const [newGroupName, setNewGroupName] = React.useState('');

    const queryClient = useQueryClient();

    const updateMutation = useMutation(
        (params) => {
            return updateGroup(params.id, { [params.field]: params.value })
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
            },
        }
    );

    const createMutation = useMutation(
        (newGroupName) => {
            return postGroup({ demand: demandID, groupName: newGroupName })
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
                setOpen(false);
            },
        }
    );

    const deleteMutation = useMutation(
        (id) => {
            return deleteGroup(id)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
            },
        }
    );

    const rows = groups.map((group) => ({
        id: group._id,
        groupName: group.groupName,
        grupdakiKalemSayisi: calculateTotals(group).totalItems,
        kalemlerinParcaToplami: calculateTotals(group).totalQuantity,
        groupOrder: group.groupOrder,
    }));

    return (
        <MDBox color="white" bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <MDButton variant="gradient" color="success" size="small" onClick={() => setOpen(true)}>
                Yeni Grup Ekle
            </MDButton>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Yeni Grup Ekle</DialogTitle>
                <DialogContent>
                    <DialogContentText>Lütfen yeni grup için bir isim girin.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Grup İsmi"
                        type="text"
                        fullWidth
                        value={newGroupName}
                        onChange={(event) => setNewGroupName(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>İptal</Button>
                    <Button onClick={() => createMutation.mutate(newGroupName)}>Ekle</Button>
                </DialogActions>
            </Dialog>
            <DataGridPro
                rows={rows}
                autoHeight
                autoPageSize
                columns={[
                    { field: 'groupName', editable: true, flex: 4 },
                    { field: 'groupOrder', editable: true, flex: 1 },
                    { field: 'grupdakiKalemSayisi', editable: false, flex: 1, hide: true },
                    { field: 'kalemlerinParcaToplami', editable: false, flex: 1, hide: true },
                    {
                        flex: 0.1,
                        field: 'delete',
                        headerName: 'Sil',
                        sortable: false,
                        width: 100,
                        renderCell: (params) => (
                            <IconButton aria-label="delete" onClick={() => deleteMutation.mutate(params.id)}>
                                <DeleteIcon />
                            </IconButton>
                        ),
                    },
                ]}
                onCellEditCommit={(params) => {
                    updateMutation.mutate(params);
                }}
            />
        </MDBox>
    );
};

export default DataTable;
