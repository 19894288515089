// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components

import useDialogHook from 'hooks/useDialogHook';

import SmsTemplates from 'components/General/SMS/SmsTemplates.js';
import CallTemplates from 'components/General/Call/CallTemplates.js';
// import WhatsappTemplates from 'components/General/Whatsapp/WhatsappTemplates.js';
import AddReminderTemplate from 'components/General/Reminder/Template.js';
import AddVisit from 'components/General/Visit/AddVisit';

function Actions(props) {
    const { customer, lead } = props;
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const { open: openCall, handleClickOpen: handleClickOpenCall, handleClose: handleCloseCall } = useDialogHook();
    // const {
    //     open: openWhatsapp,
    //     handleClickOpen: handleClickOpenWhatsapp,
    //     handleClose: handleCloseWhatsapp,
    // } = useDialogHook();
    const {
        open: openReminder,
        handleClickOpen: handleClickOpenReminder,
        handleClose: handleCloseReminder,
    } = useDialogHook();
    const { open: openVisit, handleClickOpen: handleClickOpenVisit, handleClose: handleCloseVisit } = useDialogHook();

    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    const { _id: leadID, name } = lead;
    return (
        <Card>
            {/* <MDBox p={2} lineHeight={0}>
				<MDTypography variant="h5">Notifications</MDTypography>
				<MDTypography
					variant="button"
					color="text"
					fontWeight="regular"
				>
					Notifications on this page use Toasts from Bootstrap. Read
					more details here.
				</MDTypography>
			</MDBox> */}
            <MDBox p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <MDButton variant="gradient" color="info" onClick={handleClickOpen} fullWidth>
                            SMS
                        </MDButton>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                        <MDButton variant="gradient" color="success" onClick={handleClickOpenVisit} fullWidth>
                            Ziyaret
                        </MDButton>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <MDButton variant="gradient" color="warning" onClick={handleClickOpenCall} fullWidth>
                            ARAMA
                        </MDButton>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <MDButton variant="gradient" color="error" onClick={handleClickOpenReminder} fullWidth>
                            Hatırlatıcı
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
            {open && (
                <SmsTemplates
                    customer={customer}
                    open={open}
                    handleClose={handleClose}
                    initial={{ sourcePage: 'lead', relatedID: leadID }}
                />
            )}
            {openCall && (
                <CallTemplates
                    customer={customer}
                    open={openCall}
                    handleClose={handleCloseCall}
                    initial={{ sourcePage: 'lead', relatedID: leadID }}
                />
            )}
            {openVisit && (
                <AddVisit
                    customer={customer}
                    open={openVisit}
                    handleClose={handleCloseVisit}
                    initial={{ sourcePage: 'lead', relatedID: leadID }}
                />
            )}
            {openReminder && (
                <AddReminderTemplate
                    relatedID={leadID}
                    userIDs={[userID]}
                    open={openReminder}
                    handleClose={handleCloseReminder}
                    title={name}
                    message={`Hatırlatıcı: ${name}`}
                />
            )}
        </Card>
    );
}

export default Actions;
