import { useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import FormField from 'layouts/projects/Forms/Templates/TemplateForm/components/FormField';

function Address() {
    const [showDetails, setShowDetails] = useState(false);
    const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

    const updateMontajBoslugu = (yanginDayanimSuresi) => {
        let montajBoslugu = 1.5;
        let kanatKasaUstBoslugu = 0.4;
        let kanatKasaYanBoslugu = 0.35;
        let kanatAltBoslugu = 1.5;

        if (yanginDayanimSuresi > 0) {
            montajBoslugu = 1;
            kanatKasaUstBoslugu = 0.4;
            kanatKasaYanBoslugu = 0.4;
            kanatAltBoslugu = 1;
        }

        setFieldValue('montajBoslugu', montajBoslugu);
        setFieldValue('kanatKasaUstBoslugu', kanatKasaUstBoslugu);
        setFieldValue('kanatKasaYanBoslugu', kanatKasaYanBoslugu);
        setFieldValue('kanatAltBoslugu', kanatAltBoslugu);
    };
    const formFields = [
        {
            type: 'number',
            label: 'Kalınlık (cm)',
            name: 'kalinlik',
            value: values.kalinlik,
            placeholder: 'Kalınlık',
            disabled: false,
        },
        {
            type: 'number',
            label: 'En (cm)',
            name: 'en',
            value: values.en,
            placeholder: 'En',
            disabled: false,
        },
        {
            type: 'number',
            label: 'Boy (cm)',
            name: 'boy',
            value: values.boy,
            placeholder: 'Boy',
            disabled: false,
        },
        {
            type: 'number',
            label: 'Adet',
            name: 'adet',
            value: values.adet,
            placeholder: 'Adet',
            disabled: false,
        },
        {
            type: 'number',
            label: 'Montaj Boşluğu (cm)',
            name: 'montajBoslugu',
            value: values.montajBoslugu,
            placeholder: 'Montaj Boşluğu',
            disabled: false,
            color: 'primary',
            variant: 'filled',
        },

        {
            type: 'number',
            label: 'kanat Kasa Ust Boşluğu (cm)',
            name: 'kanatKasaUstBoslugu',
            value: values.kanatKasaUstBoslugu,
            placeholder: 'kanat Kasa Ust Boşluğu (cm)',
            disabled: false,
            color: 'primary',
            variant: 'filled',
        },
        {
            type: 'number',
            label: 'Kanat Alt Boşluğu (cm)',
            name: 'kanatAltBoslugu',
            value: values.kanatAltBoslugu,
            placeholder: 'Kanat Alt Boşluğu (cm)',
            disabled: false,
            color: 'primary',
            variant: 'filled',
        },
        {
            type: 'number',
            label: 'Kanat Kasa Yan  Boşluğu (cm)',
            name: 'kanatKasaYanBoslugu',
            value: values.kanatKasaYanBoslugu,
            placeholder: 'Kanat Kasa Yan  Boşluğu (cm)',
            disabled: false,
            color: 'primary',
            variant: 'filled',
        },
    ];

    return (
        <MDBox>
            <MDTypography variant="h5" fontWeight="bold">
                Temel Değerleri Girin
                {/* {JSON.stringify(values)} */}
            </MDTypography>
            <MDBox mt={1.625}>
                <Grid container spacing={3}>
                    {formFields
                        .filter((field) => field.variant !== 'filled')
                        .map((field, index) => (
                            <Grid item xs={12} sm={4} key={field.name}>
                                <MDBox>
                                    <FormField
                                        type={field.type}
                                        disabled={field.disabled}
                                        label={field.label}
                                        name={field.name}
                                        color={field.color}
                                        variant={field.variant}
                                        value={field.value}
                                        placeholder={field.placeholder}
                                        error={errors[field.name] && touched[field.name]}
                                        success={field.value.length > 0 && !errors[field.name]}
                                    />
                                </MDBox>
                            </Grid>
                        ))}

                    <Grid item xs={6} sm={3}>
                        <Autocomplete
                            options={[0, 30]}
                            defaultValue={0}
                            getOptionLabel={(option) => option.toString()}
                            onChange={(event, value) => {
                                setFieldValue('yanginDayanimSuresi', value);
                                updateMontajBoslugu(value);
                            }}
                            onBlur={() => setFieldTouched('yanginDayanimSuresi', true)}
                            renderInput={(params) => (
                                <MDInput {...params} variant="standard" label="Yangın Dayanım Süresi" />
                            )}
                        />
                    </Grid>
                    {showDetails &&
                        formFields
                            .filter((field) => field.variant === 'filled')
                            .map((field, index) => (
                                <Grid item xs={12} sm={4} key={field.name}>
                                    <MDBox>
                                        <FormField
                                            type={field.type}
                                            disabled={field.disabled}
                                            label={field.label}
                                            name={field.name}
                                            color={field.color}
                                            variant={field.variant}
                                            value={field.value}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] && touched[field.name]}
                                            success={field.value.length > 0 && !errors[field.name]}
                                        />
                                    </MDBox>
                                </Grid>
                            ))}
                </Grid>
                <MDButton onClick={() => setShowDetails(!showDetails)}>detayları göster</MDButton>
            </MDBox>
        </MDBox>
    );
}

export default Address;
