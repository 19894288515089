// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography';

function Footer({ light }) {
	const { size } = typography;

	return (
		<MDBox position="absolute" width="100%" bottom={0} py={4}>
			<Container>
				<MDBox
					width="100%"
					display="flex"
					flexDirection={{ xs: 'column', lg: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					px={1.5}
				>
					<MDBox
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexWrap="wrap"
						color={light ? 'white' : 'text'}
						fontSize={size.sm}
					>
						&copy; {new Date().getFullYear()}, Kahramanlar Grubu
						adına
						<Link href="https://www.aaro.com.tr/" target="_blank">
							<MDTypography
								variant="button"
								fontWeight="medium"
								color={light ? 'white' : 'dark'}
							>
								&nbsp;Aaro Yazılım&nbsp;
							</MDTypography>
						</Link>
						tarafından geliştirilmiştir.
					</MDBox>
					<MDBox
						component="ul"
						sx={({ breakpoints }) => ({
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'center',
							listStyle: 'none',
							mt: 3,
							mb: 0,
							p: 0,

							[breakpoints.up('lg')]: {
								mt: 0,
							},
						})}
					>
						<MDBox component="li" pr={2} lineHeight={1}>
							<Link
								href="https://kahramanlar.com.tr/"
								target="_blank"
							>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color={light ? 'white' : 'dark'}
								>
									Kahramanlar A.Ş.
								</MDTypography>
							</Link>
						</MDBox>
						<MDBox component="li" px={2} lineHeight={1}>
							<Link
								href="https://www.aaro.com.tr/"
								target="_blank"
							>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color={light ? 'white' : 'dark'}
								>
									Aaro
								</MDTypography>
							</Link>
						</MDBox>
						<MDBox component="li" px={2} lineHeight={1}>
							<Link
								href="https://www.kapim.com.tr/"
								target="_blank"
							>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color={light ? 'white' : 'dark'}
								>
									Kapım
								</MDTypography>
							</Link>
						</MDBox>
						<MDBox component="li" pl={2} lineHeight={1}>
							<Link
								href="https://www.ahsaplar.com/"
								target="_blank"
							>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color={light ? 'white' : 'dark'}
								>
									Ahşaplar
								</MDTypography>
							</Link>
						</MDBox>
					</MDBox>
				</MDBox>
			</Container>
		</MDBox>
	);
}

// Setting default props for the Footer
Footer.defaultProps = {
	light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
	light: PropTypes.bool,
};

export default Footer;
