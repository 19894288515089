

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function RefundsCell({ value, icon, currentProducts, sheetProducts }) {
	const equality = currentProducts - sheetProducts;

	const iconDecider = () => {
		if (equality > 0)
			return (
				<MDBox color={icon.color} lineHeight={0}>
					<Icon sx={{ fontWeight: 'bold' }} fontSize="small">
						priority_high
					</Icon>
				</MDBox>
			);
		if (equality < 0)
			return (
				<MDBox color={icon.color} lineHeight={0}>
					<Icon sx={{ fontWeight: 'bold' }} fontSize="small">
						priority_high
					</Icon>
				</MDBox>
			);
		return '';
	};
	return (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			px={2}
		>
			<MDTypography
				variant="button"
				fontWeight="regular"
				color={equality === 0 ? 'text' : icon.color}
			>
				{`${sheetProducts} adet kalem`}
			</MDTypography>
			{equality !== 0 ? (
				<MDBox color={icon.color} lineHeight={0}>
					<Icon sx={{ fontWeight: 'bold' }} fontSize="small">
						priority_high
					</Icon>
				</MDBox>
			) : (
				''
			)}
		</MDBox>
	);
}

// Typechecking props for the RefundsCell
RefundsCell.propTypes = {
	icon: PropTypes.shape({
		color: PropTypes.oneOf(['info', 'success', 'warning', 'error'])
			.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
};

export default RefundsCell;
