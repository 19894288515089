import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import OutlinedFormTextField from 'layouts/projects/Forms/FormComponents/OutlinedFormTextField.js';
import AaroLoadingScreen from 'components/Loading';
import { getStockMovements } from 'services/outside/aaro/stocks';

const StockMovementsReportForm = ({ onDataFetched }) => {
    const queryClient = useQueryClient();
    const [submitTime, setSubmitTime] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [erpLink, setErpLink] = useState('');

    const formik = useFormik({
        initialValues: {
            TarihBas: '',
            TarihBit: '',
            TipID: '',
        },
        onSubmit: (values, { setSubmitting }) => {
            mutation.mutate(values);
            setSubmitting(false);
            // Boş değerler içeren anahtarları filtreleyerek ERP Linkini oluştur
            const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
                if (value) acc.append(key, value); // Eğer değer boş değilse, URLSearchParams objesine ekleyin
                return acc;
            }, new URLSearchParams());

            const queryParams = filteredValues.toString();
            const link = `https://erp2.aaro.com.tr/StokHareketleri/Liste?DevirGetir=false&${queryParams}`;
            setErpLink(link);
        },
    });

    const mutation = useMutation(
        (values) => {
            // Boş değerler içeren anahtarları filtreleyerek yeni bir obje oluştur
            const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
                if (value) acc[key] = value; // Eğer değer boş değilse, yeni objeye ekleyin
                return acc;
            }, {});

            return getStockMovements(filteredValues);
        },
        {
            onMutate: () => {
                setIsSubmitting(true);
                setSubmitTime(0);
                const startTime = new Date().getTime();
                return startTime;
            },
            onSuccess: (data, variables, startTime) => {
                onDataFetched(data);
                const endTime = new Date().getTime();
                setSubmitTime(endTime - startTime);
            },
            onError: (error, variables, startTime) => {
                console.error('Veri alınırken bir hata oluştu:', error);
            },
            onSettled: () => {
                setIsSubmitting(false);
            },
        }
    );

    if (mutation.isLoading) return <AaroLoadingScreen />;
    if (mutation.error) return <div>Bir Hata Oluştu: {mutation.error.message}</div>;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <OutlinedFormTextField
                        itemID="TarihBas"
                        label="Başlangıç Tarihi"
                        type="date"
                        formik={formik}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedFormTextField
                        itemID="TarihBit"
                        label="Bitiş Tarihi"
                        type="date"
                        formik={formik}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedFormTextField
                        itemID="TipID"
                        label="Stok Hareket Tipi ID"
                        placeHolder="Örn: 10005"
                        type="text"
                        formik={formik}
                    />
                </Grid>
                <Grid item xs={12}>
                    {submitTime > 0 && (
                        <a href={erpLink} target="_blank" rel="noopener noreferrer">
                            Sorgu {submitTime / 1000} saniye sürdü.
                        </a>
                    )}

                    <MDBox>
                        <MDButton type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                            Filtrele
                        </MDButton>
                    </MDBox>
                </Grid>
            </Grid>
        </form>
    );
};

export default StockMovementsReportForm;
