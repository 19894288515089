export const offerSettings = {
    availableLanguages: ['Türkçe', 'İngilizce'],
    availableCategories: ['Fiyat Teklifi', 'Proforma Fatura'],
};

export const offerTemplates = [
    {
        offerSpreadsheetTemplateID: '1jrwWfpwe1LSmID8EjMEX7P5ZGAgiV6m8JVHCPk_pXSQ',
        offerSheetID: 790763898,
        templateName: 'Şablon 1',
        language: 'Türkçe',
        offerCategory: 'Fiyat Teklifi',
        offerImages: [
            'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/Yunanistan%205000%20Kap%C4%B1/l1o590hq-Baslksz1jpg',
            'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19jk7z5-EkranResmi20220327201401png',
            'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/Yunanistan%205000%20Kap%C4%B1/l1o599z4-Baslksz2jpg',
        ],
        isGrouped: false,
    },
    // {
    //     offerSpreadsheetTemplateID: '1A7uBajyAnOowo9leDbw7TIszvhyY7EjM2p_W693X1ow',
    //     offerSheetID: 790763898,
    //     templateName: 'Şablon 2',
    //     language: 'Türkçe',
    //     offerCategory: 'Fiyat Teklifi',
    //     offerImages: [
    //         'https://kahramanlar.s3.amazonaws.com/projects/Binali%20Akkurt/%C3%96zel%20Masif%20Panel%20%C3%9Cretimi%20-%20A4%20Tasar%C4%B1m/l1p4lomj-wwwKAPIMCOMTRAnkaraTURKIYE21297cm1png',
    //         'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19jk7z5-EkranResmi20220327201401png',
    //         'https://kahramanlar.s3.amazonaws.com/projects/Binali%20Akkurt/%C3%96zel%20Masif%20Panel%20%C3%9Cretimi%20-%20A4%20Tasar%C4%B1m/l1p4mb74-wwwKAPIMCOMTRAnkaraTURKIYE21297cm2png',
    //     ],
    //     isGrouped: false,
    // },
    {
        offerSpreadsheetTemplateID: '1yoaD3S3B9fzl0bzIX7UHsbLz1l1743kPvGpd3apqh8Y',
        offerSheetID: 790763898,
        templateName: 'Şablon 3',
        language: 'Türkçe',
        offerCategory: 'Fiyat Teklifi',
        offerImages: [
            'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19jk7z5-EkranResmi20220327201401png',
            'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19jlez0-EkranResmi20220327201457png',
        ],
        isGrouped: false,
    },
    {
        offerSpreadsheetTemplateID: '1c84hVkYWBlJltKWRVFX5Mbn4epeT-UPgGMfBqGUX7SM',
        offerSheetID: 790763898,
        templateName: 'Gruplu Teklif',
        language: 'Türkçe',
        offerCategory: 'Fiyat Teklifi',
        offerImages: [
            'https://kahramanlar.s3.amazonaws.com/projects/UZUNPINAR%20KERESTE%20BOYA%20SAN.VE%20T%C4%B0C.LTD.%C5%9ET%C4%B0/Kereste/liu2gt98-EkranResmi2023061312.13.30.png',
        ],
        isGrouped: true,
    },
    {
        offerSpreadsheetTemplateID: '16fWIcBbRivxkwVKthvb0u6wsA5zcK2bHeRWrZacnHgI',
        offerSheetID: 790763898,
        templateName: 'Gruplu ve Görselli Teklif 1',
        language: 'Türkçe',
        offerCategory: 'Fiyat Teklifi',
        offerImages: [
            'https://kahramanlar.s3.amazonaws.com/projects/UZUNPINAR%20KERESTE%20BOYA%20SAN.VE%20T%C4%B0C.LTD.%C5%9ET%C4%B0/Kereste/liu2gt98-EkranResmi2023061312.13.30.png',
        ],
        isGrouped: true,
    },
    {
        offerSpreadsheetTemplateID: '144bJQxiUgE50yVikckWVKmlblwt3tCGSxlMuPmZW8RA',
        offerSheetID: 790763898,
        templateName: 'Tolga Gruplu Teklif 1',
        language: 'Türkçe',
        offerCategory: 'Fiyat Teklifi',
        offerImages: [
            'https://kahramanlar.s3.amazonaws.com/tasks/653a358f8ff0250014681354/additionalName/lz2tmtyq-FiyatTeklifi1.png',
        ],
        isGrouped: true,
    },

    // {
    //     offerSpreadsheetTemplateID: '1flK9yc3Q5Wd81-HIS0ncYPmdEDPaQzc-fOlf8UHJE9E',
    //     offerSheetID: 1760630658,
    //     templateName: 'Gruplu Teklif 2',
    //     language: 'Türkçe',
    //     offerCategory: 'Fiyat Teklifi',
    //     offerImages: [
    //         '          https://kahramanlar.s3.amazonaws.com/projects/BinaliAkkurt/zelMasifPanelretimiA4Tasarm/lmbv8r6c-Kapmmin.png',
    //     ],
    //     isGrouped: true,
    // },

    // {
    //     offerSpreadsheetTemplateID: '1fqSBwntAl_nGNNMrC23sYdi3_YYQLtvILrc95SwUCT4',
    //     offerSheetID: 1572948077,
    //     templateName: 'Tolga Özelmacıklı',
    //     language: 'Türkçe',
    //     offerCategory: 'Fiyat Teklifi',
    //     offerImages: [
    //         'https://kahramanlar.s3.amazonaws.com/projects/R%C4%B0NK%20KAPI%20D%C3%9CNYASI/Almanya%20200%20Lake%20Kap%C4%B1/lb4uq85f-EkranResmi2022120112.03.25.png',
    //         'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19jk7z5-EkranResmi20220327201401png',
    //         'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19jlez0-EkranResmi20220327201457png',
    //     ],
    //     isGrouped: false,
    // },

    // {
    // 	offerSpreadsheetTemplateID:
    // 		'1CIwqnGCsNNBzW6nBSFq1jN060fLNUQmsPEVhIDD9CKU',
    // 	offerSheetID: 1729935241,
    // 	templateName: 'Şablon 3',
    // 	language: 'Türkçe',
    // 	offerCategory: 'Fiyat Teklifi',
    // 	offerImages: [
    // 		'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19ju0fh-EkranResmi20220327202031png',
    // 		'https://kahramanlar.s3.amazonaws.com/projects/A%C3%87ILSUSAM%20TAH%C4%B0N%20HELVA%20MET%C4%B0N%20KARAKU%C5%9E/asd(Kopya)(Kopya)/l19juu9l-EkranResmi20220327202105png',
    // 	],
    // },
];
