import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import Card from '@mui/material/Card';

export class MapContainer extends Component {
    state = {
        showingInfoWindow: false, // Hides or shows the InfoWindow
        activeMarker: {}, // Shows the active marker upon click
        selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });

    onClose = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };
    render() {
        return this.props.enableCard ? (
            <Card>
                <Map
                    google={this.props.google}
                    zoom={15}
                    style={{
                        position: this.props?.position
                            ? this.props.position
                            : 'relative',
                        height: this.props?.height ? this.props.height : '40vh',
                        margin: this.props?.margin ? this.props.margin : '20px',
                    }}
                    initialCenter={this.props?.latLng}
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        name={'Kahramanlar Orman Ürünleri'}
                    />
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                        </div>
                    </InfoWindow>
                </Map>
            </Card>
        ) : (
            <div
                style={{
                    position: this.props?.position
                        ? this.props.position
                        : 'relative',
                    height: this.props?.height ? this.props.height : '40vh',
                    margin: this.props?.margin ? this.props.margin : '20px',
                }}
            >
                <Map
                    google={this.props.google}
                    zoom={this.props?.zoom ? this.props.zoom : 10}
                    style={{
                        position: this.props?.position
                            ? this.props.position
                            : 'relative',
                        height: this.props?.height ? this.props.height : '40vh',
                    }}
                    initialCenter={this.props?.latLng}
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        name={'Kahramanlar Orman Ürünleri'}
                    />
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBbWNUj6lb2RpS4j5Dk45l_v_FOgLHfAF4',
})(MapContainer);

Map.propTypes = {
    enableCard: PropTypes.bool,
    position: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
    zoom: PropTypes.number,
};
