import { memo } from 'react'; // React'ten memo fonksiyonunu import ediyoruz
import Grid from '@mui/material/Grid'; // MUI'den Grid componentini import ediyoruz
import MDBox from 'components/MDBox'; // Kendi componentimiz olan MDBox'ı import ediyoruz
import Layout from './Layout'; // Layout componentini import ediyoruz
import Header from './Header'; // Header componentini import ediyoruz

// LeadsLayout adında bir fonksiyon component oluşturuyoruz
// Bu component, çocuk componentleri (children) alır ve onları belirli bir layout içinde render eder
function LeadsLayout({ children }) {
    return (
        <Layout>
            <Header />
            <MDBox pb={3}>
                <MDBox mt={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </Layout>
    );
}

export default memo(LeadsLayout); // LeadsLayout componentini memo ile export ediyoruz
