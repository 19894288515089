

import { useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';

// @mui material components
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDBadgeDot from 'components/MDBadgeDot';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DefaultStatisticsCard from 'examples/Cards/StatisticsCards/DefaultStatisticsCard';
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';
import SalesTable from 'examples/Tables/SalesTable';
import DataTable from 'examples/Tables/DataTable';

// Sales dashboard components
import ChannelsChart from 'layouts/dashboards/sales/components/ChannelsChart';

// Data
import defaultLineChartData from 'layouts/dashboards/sales/data/defaultLineChartData';
import horizontalBarChartData from 'layouts/dashboards/sales/data/horizontalBarChartData';
import salesTableData from 'layouts/dashboards/sales/data/salesTableData';
import dataTableData from 'layouts/dashboards/sales/data/dataTableData';
import StockMovements from 'layouts/orders/pages/SingleOrder/MainPage/components/StockMovements.js';
import WhoBuysThatProducts from 'layouts/orders/pages/SingleOrder/MainPage/components/WhoBuysThatProduct.js';
function Sales(props) {
	const { activeBranch, productID, summary, branchName } = props;
	// DefaultStatisticsCard state for the dropdown value
	const { height, width } = useWindowDimensions();

	const [whoBuys, setWhoBuys] = useState(0);
	const {
		SatisMiktar4Onceki,
		SatisMiktar3Onceki,
		SatisMiktar2Onceki,
		SatisMiktar1Onceki,
		SatisMiktarSon,
		Birim1Kodu,
		DonemBaslangic,
		UretimTarihiSonTumZamanlar,
		DepoMiktar4OncekiDonemSonu,
		DepoMiktar3OncekiDonemSonu,
		DepoMiktar2OncekiDonemSonu,
		DepoMiktar1OncekiDonemSonu,
		DepoMiktarSon,
		TumStoklarSatisSon4DonemToplam,
		UretimMiktarSon,
		UretimMiktar1Onceki,
		UretimMiktar2Onceki,
		UretimMiktar3Onceki,
		UretimMiktar4Onceki,
		AlinanSiparislerAcikMiktar,
	} = summary;

	return (
		<MDBox py={3}>
			<MDBox mb={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={4}>
						<DefaultStatisticsCard
							title={`Geçmiş 4 Dönem Ortalama Depo (${branchName})`}
							count={
								(DepoMiktar4OncekiDonemSonu +
									DepoMiktar3OncekiDonemSonu +
									DepoMiktar2OncekiDonemSonu +
									DepoMiktar1OncekiDonemSonu) /
									5 +
								' ' +
								Birim1Kodu
							}
							percentage={{
								color: calculateProfit(
									DepoMiktar1OncekiDonemSonu,
									DepoMiktar2OncekiDonemSonu
								).isNegative
									? 'primary'
									: 'success',
								value: calculateProfit(
									DepoMiktar1OncekiDonemSonu,
									DepoMiktar2OncekiDonemSonu
								).value,
								label: '1. ve 2. Dönem Kıyası',
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<DefaultStatisticsCard
							title={`Geçmiş 4 Dönem Satış Miktarı (${branchName})`}
							count={
								SatisMiktar4Onceki +
								SatisMiktar3Onceki +
								SatisMiktar2Onceki +
								SatisMiktar1Onceki +
								' ' +
								Birim1Kodu
							}
							percentage={{
								color: calculateProfit(
									SatisMiktar1Onceki,
									SatisMiktar2Onceki
								).isNegative
									? 'primary'
									: 'success',
								value: calculateProfit(
									SatisMiktar1Onceki,
									SatisMiktar2Onceki
								).value,
								label: '1. ve 2. Dönem Kıyası',
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<DefaultStatisticsCard
							title={`Açık Sipariş Adedi (${branchName})`}
							count={
								AlinanSiparislerAcikMiktar
									? AlinanSiparislerAcikMiktar
									: 0 + ' ' + Birim1Kodu
							}
							percentage={{
								color: 'dark',
								value: '',
								label: 'Bekleyen sipariş',
							}}
						/>
					</Grid>
				</Grid>
			</MDBox>
			<MDBox mb={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12}>
						{' '}
						<StockMovements
							productID={productID}
							branchID={activeBranch}
							branchName={branchName}
							setWhoBuys={setWhoBuys}
						/>{' '}
					</Grid>
				</Grid>
			</MDBox>
			<MDBox mb={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} lg={6}>
						<Card sx={{ height: height / 2.25 }}>
							<DefaultLineChart
								title={`Satış ve Depo Miktarı Kıyası (${branchName})`}
								description={
									<MDBox
										display="flex"
										justifyContent="space-between"
									>
										<MDBox display="flex" ml={-1}>
											<MDBadgeDot
												color="info"
												size="sm"
												badgeContent="Satış Miktarı"
											/>
											<MDBadgeDot
												color="dark"
												size="sm"
												badgeContent="Depo Miktarı"
											/>
										</MDBox>
										<MDBox
											mt={-4}
											mr={-1}
											position="absolute"
											right="1.5rem"
										>
											<Tooltip
												title="Tutulan Depo ve Satış Miktarı Kıyası"
												placement="left"
												arrow
											>
												<MDButton
													variant="outlined"
													color="secondary"
													size="small"
													circular
													iconOnly
												>
													<Icon>priority_high</Icon>
												</MDButton>
											</Tooltip>
										</MDBox>
									</MDBox>
								}
								chart={prepareStockWarehouseChart(summary)}
							/>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} lg={6}>
						{whoBuys > 0 && (
							<WhoBuysThatProducts
								productID={productID}
								branchID={activeBranch}
								branchName={branchName}
							/>
						)}
					</Grid>
				</Grid>
			</MDBox>
		</MDBox>
	);
}

const prepareStockWarehouseChart = (summary) => {
	const {
		SatisMiktar4Onceki,
		SatisMiktar3Onceki,
		SatisMiktar2Onceki,
		SatisMiktar1Onceki,
		SatisMiktarSon,
		DepoMiktar4OncekiDonemSonu,
		DepoMiktar3OncekiDonemSonu,
		DepoMiktar2OncekiDonemSonu,
		DepoMiktar1OncekiDonemSonu,
		DepoMiktarSon,
	} = summary;

	return {
		labels: ['4.Dönem', '3.Dönem', '2.Dönem', '1.Dönem', 'Mevcut Dönem'],
		datasets: [
			{
				label: 'Satış Miktarı',
				color: 'info',
				data: [
					SatisMiktar4Onceki,
					SatisMiktar3Onceki,
					SatisMiktar2Onceki,
					SatisMiktar1Onceki,
					SatisMiktarSon,
				],
			},
			{
				label: 'Depo Miktarı',
				color: 'dark',
				data: [
					DepoMiktar4OncekiDonemSonu,
					DepoMiktar3OncekiDonemSonu,
					DepoMiktar2OncekiDonemSonu,
					DepoMiktar1OncekiDonemSonu,
					DepoMiktarSon,
				],
			},
		],
	};
};

const calculateProfit = (first, second) => {
	if (
		first === undefined ||
		first === null ||
		second === undefined ||
		second === null
	) {
		if (
			(first === undefined || first === null) &&
			(second === undefined || second === null)
		) {
			return { value: `0%`, isNegative: true };
		}
		if (first === undefined || first === null) {
			return {
				value: `${second > 0 ? '-' : '+'}${second}%`,
				isNegative: second > 0,
			};
		}
		return { value: `${first}%`, isNegative: first < 0 };
	}

	if (first === 0 && second === 0) return { value: `0%`, isNegative: true };

	if (first !== 0 && second === 0)
		return { value: `${first}%`, isNegative: first < 0 };

	const percentage = (((first - second) / second) * 100).toFixed(2);
	const isNegative = first / second - 1 <= 0;

	const value = `${percentage}%`;
	return { value, isNegative };
};

export default Sales;
