import React from 'react';
import { useFormik } from 'formik';

import { deleteCariIlgili } from 'services/outside/aaro/customer';
import { useQueryClient } from 'react-query';
import MDButton from 'components/MDButton';

import { notify } from 'helpers/notificationHelper';

export default function EditCustomerResponsible(props) {
	const { initial, setOpenDeletion } = props;
	const queryClient = useQueryClient();
	const [disableOnSubmit, setDisableOnSubmit] = React.useState(false);

	const formik = useFormik({
		initialValues: initial,
		onSubmit: (values) => {
			setDisableOnSubmit(true);
			deleteCariIlgili(values)
				.then(() => {
					queryClient.invalidateQueries(
						'fetchAaroCustomerResponsibles'
					);
					notify(true);
					setOpenDeletion(false);
				})
				.catch((err) => {
					notify(false);
					console.log(err);
				});
		},
	});

	return (
		<div>
			<form onSubmit={formik.handleSubmit}>
				<MDButton
					variant="gradient"
					color="primary"
					fullWidth
					type="submit"
					disabled={disableOnSubmit}
				>
					SİLMEYİ ONAYLA
				</MDButton>
			</form>
		</div>
	);
}
