import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { getDefaultColor } from 'helpers/colorHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import { useFormik } from 'formik';
import * as yup from 'yup';
import { notify } from 'helpers/notificationHelper';
import { postCari } from 'services/outside/aaro/customer.js';
import { postCustomer } from 'services/api/customer.js';
import Dialog from '@mui/material/Dialog';

const validationSchema = yup.object({
    CariAdi: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    VergiNo: yup
        .string('Bağlı olduğu liste IDsi gereklidir.')
        .min(11, '11 karakter olmalıdır')
        .max(11, '11 karakter olmalıdır')
        .required('Bağlı olduğu liste IDsi gereklidir'),
});

export default function InputArea(props) {
    const { initial, handleClose, open } = props;

    const navigate = useNavigate();

    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    const formik = useFormik({
        initialValues: {
            CariAdi: initial?.CariAdi || '',
            VergiNo:
                initial?.VergiNo ||
                '00000' + Math.random().toString().slice(2, 8),
            VergiDairesiID: initial?.VergiNo || 1,
            CariKodu: initial?.CariKodu || Math.random().toString().slice(2, 9),
            TipID: initial?.TipID || '102004',
            Kod1ID: initial?.Kod1ID || '699',
            SablonID: initial?.SablonID || '173',
            Durum: true,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setDisableOnSubmit(true);

            postCari(values).then((rp) => {
                // console.log(rp);
                const { data } = rp;
                const { Sonuc, Model } = data;
                const { CariID } = Model;
                notify(Sonuc, 'Aaro Kayıt Sonucu Geldi');
                if (Sonuc) {
                    const newCustomer = {
                        customerID: CariID,
                        name: values.CariAdi,
                        // responsible: userID,
                        responsibles: [userID],
                    };
                    postCustomer(newCustomer)
                        .then((rp) => {
                            const { data } = rp;
                            const { success, message, result } = data;
                            notify(success, message);
                            navigate(`../customers/${CariID}`);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            });
        },
    });

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth="sm"
        >
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={formik.handleSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="CariAdi"
                                    placeholder="Cari Adi"
                                    id="CariAdi"
                                    name="CariAdi"
                                    value={formik.values.CariAdi}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.CariAdi &&
                                        Boolean(formik.errors.CariAdi)
                                    }
                                    helperText={
                                        formik.touched.CariAdi &&
                                        formik.errors.CariAdi
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="Vergi No"
                                    placeholder="VergiNo"
                                    id="VergiNo"
                                    name="VergiNo"
                                    value={formik.values.VergiNo}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.VergiNo &&
                                        Boolean(formik.errors.VergiNo)
                                    }
                                    helperText={
                                        formik.touched.VergiNo &&
                                        formik.errors.VergiNo
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <MDButton
                                        fullWidth
                                        size="small"
                                        variant="gradient"
                                        color="dark"
                                        type="submit"
                                        disabled={disableOnSubmit}
                                    >
                                        Ekle
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </Card>
        </Dialog>
    );
}
