// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components

import useDialogHook from 'hooks/useDialogHook';

import SmsTemplatesWoo from 'components/General/SMS/SmsTemplatesWoo.js';
import CallTemplates from 'components/General/Call/CallTemplates.js';
// import WhatsappTemplates from 'components/General/Whatsapp/WhatsappTemplates.js';
import AddReminderTemplate from 'components/General/Reminder/Template.js';
import Responsibles from 'layouts/purchases/components/Responsibles';

function Actions({ purchase, wooCommerceOrderDetails }) {
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const { open: openCall, handleClickOpen: handleClickOpenCall, handleClose: handleCloseCall } = useDialogHook();
    const {
        open: openReminder,
        handleClickOpen: handleClickOpenReminder,
        handleClose: handleCloseReminder,
    } = useDialogHook();

    if (!purchase) return null;
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    return (
        <Card>
            <MDBox p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <MDButton variant="gradient" color="info" onClick={handleClickOpen} fullWidth>
                            SMS
                        </MDButton>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <MDButton variant="gradient" color="warning" onClick={handleClickOpenCall} fullWidth>
                            ARAMA
                        </MDButton>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <MDButton variant="gradient" color="error" onClick={handleClickOpenReminder} fullWidth>
                            Hatırlatıcı
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
            {open && (
                <SmsTemplatesWoo
                    open={open}
                    handleClose={handleClose}
                    initial={{
                        sourcePage: 'purchase',
                        relatedID: purchase.wooCommerceID,
                        customerID: purchase.wooCommerceID,
                    }}
                    wooCommerceOrderDetails={wooCommerceOrderDetails}
                    purchase={purchase}
                    sourcePage="purchase"
                    orderNumber={purchase.wooCommerceID}
                />
            )}
            {openCall && (
                <CallTemplates
                    open={openCall}
                    handleClose={handleCloseCall}
                    initial={{
                        sourcePage: 'purchase',
                        relatedID: purchase.wooCommerceID,
                        customerID: purchase.wooCommerceID,
                    }}
                />
            )}

            {openReminder && (
                <AddReminderTemplate
                    relatedID={purchase.wooCommerceID}
                    userIDs={[userID]}
                    open={openReminder}
                    handleClose={handleCloseReminder}
                    title={`Sipariş #${purchase.wooCommerceID}`}
                    message={`Sipariş #${purchase.wooCommerceID} için hatırlatıcı`}
                />
            )}
        </Card>
    );
}

export default Actions;
