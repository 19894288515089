import React, { useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { notify } from 'helpers/notificationHelper';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import FormField from 'layouts/pages/account/components/FormField';
import { getUsers } from 'services/api/user';
import { postTaskList, updateTaskList, deleteTaskList } from 'services/api/general/taskList'; // Yeni API fonksiyonlarınız
import AaroLoadingScreen from 'components/Loading';
import MDTypography from 'components/MDTypography';

const fetchUsers = async (id) => {
    const response = await getUsers(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

// Validasyon şeması
const validationSchemaForTaskList = yup.object({
    name: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    relatedID: yup.string('İlgili ID gereklidir.').required('İlgili ID gereklidir.'),
});

export default function InputAreaForTaskList(props) {
    const { initial, setOpenDialog, update = false } = props;

    const queryClient = useQueryClient();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);

    const allUsers = useQuery('fetchUsers', () => fetchUsers(), {
        staleTime: 600000,
    });

    const formik = useFormik({
        initialValues: {
            relatedID: initial?.relatedID || '',
            name: initial?.name || '',
            description: initial?.description || '',
            order: initial?.order || -1,
            isActive: initial?.isActive === false ? false : true,
            favorite: initial?.favorite || false,
            url: initial?.url || '',
            tags: initial?.tags || [],
            sharedWith: initial?.sharedWith || [],
        },
        validationSchema: validationSchemaForTaskList,
        onSubmit: (values) => {
            console.log(values);
            setDisableOnSubmit(true);
            if (update) {
                updateTaskList(initial._id, values)
                    .then((rp) => {
                        queryClient.invalidateQueries(['fetchTaskLists']);
                        setOpenDialog(false);
                    })
                    .catch((err) => {
                        console.error(err);
                        setOpenDialog(false);
                    });
            } else {
                postTaskList(values).then((rp) => {
                    queryClient.invalidateQueries(['fetchTaskLists']);
                    setOpenDialog(false);
                });
            }
        },
    });

    if (allUsers.isLoading) return <AaroLoadingScreen />;
    if (allUsers.error) return 'Bir Hata Oluştu: ' + allUsers.error;

    const FormSwitch = ({ label, value, onChange }) => {
        return (
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                    <Switch checked={value} onChange={onChange} />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        {label}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    };

    return (
        <Card id="basic-info-for-tasklist" sx={{ overflow: 'visible' }}>
            <form onSubmit={formik.handleSubmit}>
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormField
                                label="Liste Adı"
                                placeholder="Liste Adı"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <FormField
                                label="İlgili ID"
                                placeholder="İlgili ID"
                                id="relatedID"
                                name="relatedID"
                                value={formik.values.relatedID}
                                onChange={formik.handleChange}
                                error={formik.touched.relatedID && Boolean(formik.errors.relatedID)}
                                helperText={formik.touched.relatedID && formik.errors.relatedID}
                            />
                        </Grid> */}
                        <Grid item xs={6}>
                            <FormField
                                label="Açıklama"
                                placeholder="Açıklama"
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormField
                                label="Sıra"
                                type="number"
                                id="order"
                                name="order"
                                value={formik.values.order}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                // defaultValue={[user.username]}
                                options={allUsers.data}
                                getOptionLabel={(option) => option?.name}
                                value={formik.values.sharedWith}
                                onChange={(e, value) => {
                                    formik.setFieldValue('sharedWith', value !== null ? value : initial.sharedWith);
                                }}
                                renderInput={(params) => (
                                    <FormField
                                        {...params}
                                        label="Paylaşılanlar"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormSwitch
                                label="Aktif?"
                                value={formik.values.isActive}
                                onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormSwitch
                                label="Favori?"
                                value={formik.values.favorite}
                                onChange={(e) => formik.setFieldValue('favorite', e.target.checked)}
                            />
                        </Grid>

                        {/* Eğer etiket ve paylaşılan kullanıcılar için bir dropdown eklemek isterseniz buraya ekleyebilirsiniz. */}
                        <Grid item xs={12}>
                            <MDBox display="flex">
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="dark"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    Ekle
                                </MDButton>
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="primary"
                                    onClick={() => {
                                        const result = window.confirm('Silmek İstediğinize Emin Misiniz?');
                                        if (result)
                                            deleteTaskList(initial._id)
                                                .then((rp) => {
                                                    notify(rp.data.success, rp.data.message);
                                                    queryClient.invalidateQueries(['fetchTaskLists']);
                                                    setOpenDialog(false);
                                                })
                                                .catch((err) => {
                                                    notify(false, err.message);
                                                });
                                    }}
                                    disabled={disableOnSubmit}
                                >
                                    SİL
                                </MDButton>
                            </MDBox>
                        </Grid>

                        {/* {JSON.stringify(formik.values)} */}
                    </Grid>
                </MDBox>
            </form>
        </Card>
    );
}
