import { useContext } from 'react';
import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './App';
import jwt_decode from 'jwt-decode';
import { config } from 'Constants';
import { Token, Aaro } from 'aaro';
import { getStock } from 'services/outside/aaro/stocks';

const ProtectedRoutes = () => {
	const location = useLocation();
	const user = JSON.parse(localStorage.getItem('user'));

	let validation = false;
	// getStock({ StokID: 9516 })
	// 	.then((rp) => {
	// 		if (!rp.data.Sonuc) ReLogin();
	// 	})
	// 	.catch((err) =>
	// 		console.log('Protected route aaro token control error - ' + err)
	// 	);

	if (user !== null && user !== undefined) {
		const bearerToken = user.token;
		const jwtToken = jwt_decode(bearerToken?.replace('Bearer', ''));
		const currentDate = new Date().getTime() / 1000;
		if (currentDate < jwtToken.exp) validation = true;
	}

	return validation ? (
		<Outlet />
	) : (
		<Navigate
			to="/login"
			replace
			state={{ from: location }}
		/>
	);
};

function ReLogin() {
	const { user } = useContext(UserContext);
	const url = config.url.AARO_BASE;
	Token(url, addKahramanlar(user.username), user.password)
		.then((rp) => {
			const aaroBearerToken = rp.data.access_token;
			const aaro = new Aaro({
				baseUrl: config.url.AARO_BASE,
				accessToken: aaroBearerToken,
			});

			aaro.get('Kullanicilar', { EsnekAramaKisiti: user.username })
				.then((rp) =>
					localStorage.setItem(
						'AaroKullanici',
						rp?.data?.Model
							? JSON.stringify(rp?.data?.Model[0])
							: {}
					)
				)
				.catch((err) => console.log('ReLogin ' + err));
		})
		.catch((err) => err);

	// dummy request for starting aaro
}

const addKahramanlar = (username) => {
	if (!username.includes('@')) {
		return username + '@kahramanlar.com.tr';
	}
	return username;
};

export default ProtectedRoutes;
