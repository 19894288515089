// Libraries
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Layout
import ProjectLayout from 'layouts/customers/pages/SingleCustomer/CustomerLayout.js';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';

// Project Pages And Components
import MainPage from 'layouts/orders/pages/SingleOrder/MainPage/index.js';
import AaroLoadingScreen from 'components/Loading';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';
import OrderDetailCard from 'layouts/orders/pages/SingleOrder/MainPage/components/OrderDetailCard';

// API
import { getOrderStockMovements, getDekontBaslik } from 'services/outside/aaro/dekont.js';

// Helpers
import { calculateTimePassed } from 'helpers/dateHelpers';

const fetchOrderStockMovements = async (query) => {
    return await getOrderStockMovements(query);
};

const fetchDocuments = async (query) => {
    const response = await getDekontBaslik(query);
    return response?.data?.Model;
};

function GeneralPage() {
    const { id } = useParams();
    const [value, setValue] = useState(0);

    const {
        data: stockMovements,
        isLoading,
        error,
    } = useQuery(['fetchOrderStockMovements', id], () => fetchOrderStockMovements({ DekontID: id }), {
        refetchOnWindowFocus: false,
        staleTime: 60000,
    });

    const orderTypeID = 10013;
    const { data: document } = useQuery(
        ['fetchDocuments', orderTypeID, id],
        () =>
            fetchDocuments({
                TipID: orderTypeID,
                SiralamaKisiti: 'OlsTar:Desc',
                Durum: true,
                DekontID: id,
            }),
        { refetchOnWindowFocus: false, staleTime: 60000 }
    );

    if (isLoading) return <AaroLoadingScreen />;
    if (error) return <div>Bir Hata Oluştu: {error.message}</div>;
    if (!stockMovements) return <div>Yükleniyor...</div>;

    const renderPageContent = () => {
        switch (value) {
            case 0:
                return <MainPage products={stockMovements} document={document} />;
            default:
                return <p>...page</p>;
        }
    };

    return (
        <ProjectLayout setValue={setValue}>
            <MDBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={value === 2 ? 12 : 9}>
                        <MDBox mb={3}>{renderPageContent()}</MDBox>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <MDBox mb={3}>
                            <MiniStatisticsCard
                                title={{ text: 'Geçen Süre' }}
                                count={`${calculateTimePassed(document?.[0]?.OlsTar || 0)} Gün`}
                                icon={{
                                    color: 'info',
                                    component: 'gpp_maybe',
                                }}
                                direction="left"
                            />
                        </MDBox>
                        <MDBox mb={3}>
                            <OrderDetailCard order={document?.[0] || {}} />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </ProjectLayout>
    );
}

export default GeneralPage;
