// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function CustomerCell({ discount = 0 }) {
	const discountFormatted = parseFloat(discount);
	if (discountFormatted == 0) {
		return (
			<MDBox display="flex" alignItems="center" flexDirection="column">
				<MDTypography
					variant="caption"
					fontWeight="bold"
					color="text"
					textGradient
					sx={{ lineHeight: 1 }}
				>
					-
				</MDTypography>
			</MDBox>
		);
	}
	if (discountFormatted < 10) {
		return (
			<MDBox display="flex" alignItems="center" flexDirection="column">
				<MDTypography
					variant="caption"
					fontWeight="bold"
					color="success"
					textGradient
					sx={{ lineHeight: 1 }}
				>
					{discountFormatted}%
				</MDTypography>
			</MDBox>
		);
	}
	if (discountFormatted < 20) {
		return (
			<MDBox display="flex" alignItems="center" flexDirection="column">
				<MDTypography
					variant="caption"
					fontWeight="bold"
					color="warning"
					textGradient
					sx={{ lineHeight: 1 }}
				>
					{discountFormatted}%
				</MDTypography>
			</MDBox>
		);
	}
	return (
		<MDBox display="flex" alignItems="center" flexDirection="column">
			<MDTypography
				variant="caption"
				fontWeight="bold"
				color="primary"
				textGradient
				sx={{ lineHeight: 1 }}
			>
				{discountFormatted}%
			</MDTypography>
		</MDBox>
	);
}

// Setting default value for the props of CustomerCell
CustomerCell.defaultProps = {
	image: '',
	color: 'dark',
};

// Typechecking props for the CustomerCell
CustomerCell.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	color: PropTypes.oneOf([
		'transparent',
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'light',
		'dark',
	]),
};

export default CustomerCell;
