/* eslint-disable import/no-anonymous-default-export */


/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from 'layouts/projects/Components/Offer/components/DemandsTable/data/components/DefaultCell';
import ShortTitleCell from 'layouts/projects/Components/Offer/components/DemandsTable/data/components/ShortTitleCell';
import PriceCell from 'layouts/projects/Components/Offer/components/DemandsTable/data/components/PriceCell';
import TitleCell from 'layouts/projects/Components/Offer/components/DemandsTable/data/components/TitleCell';
import DiscountCell from 'layouts/projects/Components/Offer/components/DemandsTable/data/components/DiscountCell';

import { availableDemandNames } from 'helpers/projectHelpers';

export const demandToOffer = (demand) => {
	if (demand == null) return [];

	const columns = [
		{
			Header: 'başlık',
			accessor: 'kisaBaslik',
			Cell: ({ value: [name, data] }) => (
				<ShortTitleCell
					image={data.image}
					color={data.color || 'dark'}
					name={name}
				/>
			),
		},
		{
			Header: 'açıklama',
			accessor: 'baslik',
			Cell: ({ value }) => <TitleCell name={value.baslik} data={value} />,
		},

		{
			Header: 'fiyat',
			accessor: 'verilenFiyat',
			Cell: ({ value: data }) => (
				<PriceCell
					price={data.verilenFiyat}
					amount={1}
					discount={data.indirimOrani}
					data={data}
					demand={demand}
				/>
			),
		},
		{
			Header: 'miktar',
			accessor: 'miktar',
			Cell: ({ value }) => <DefaultCell value={value} />,
		},
		{
			Header: 'iskonto',
			accessor: 'indirimOrani',
			Cell: ({ value }) => (
				<DiscountCell discount={value} demand={demand} />
			),
		},
		{
			Header: 'toplam',
			accessor: 'toplam',
			Cell: ({ value: data }) => (
				<PriceCell
					price={data.verilenFiyat}
					amount={data.miktar}
					discount={data.indirimOrani}
					data={data}
					demand={demand}
				/>
			),
		},
	];
	const rows = [];
	Object.keys(demand).forEach((key) => {
		if (availableDemandNames.includes(key)) {
			demand[key].forEach((el) => {
				let temporaryView = {
					kisaBaslik: [key, { image: el.gorsel ? el.gorsel : key }],
					baslik: el,
					verilenFiyat: el,
					indirimOrani: el.indirimOrani,
					miktar: el.miktar,
					toplam: el,
				};
				rows.push(temporaryView);
			});
		}
	});

	return { columns, rows };
};
