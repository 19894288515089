import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
// helpers

import { calculateDiscount, formatPrice } from 'helpers/priceHelpers';

function ItemDetail({ item }) {
	// indirimsiz orani yazdim.
	return (
		<MDBox
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			mb={5}
		>
			<MDBox>
				<MDBox mb={1}>
					<MDTypography variant="caption" fontWeight="medium">
						{item?.baslik}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDButton variant="gradient" color={'secondary'}>
				{formatPrice(calculateDiscount(item?.verilenFiyat, 1, 0))}
			</MDButton>
		</MDBox>
	);
}

export default ItemDetail;
