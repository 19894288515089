// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components

import useDialogHook from 'hooks/useDialogHook';

import SmsTemplates from 'components/General/SMS/SmsTemplates.js';
import CallTemplates from 'components/General/Call/CallTemplates.js';
import WhatsappTemplates from 'components/General/Whatsapp/WhatsappTemplates.js';

function Actions(props) {
	const { customer } = props;
	const { open, handleClickOpen, handleClose } = useDialogHook();
	const {
		open: openCall,
		handleClickOpen: handleClickOpenCall,
		handleClose: handleCloseCall,
	} = useDialogHook();
	const {
		open: openWhatsapp,
		handleClickOpen: handleClickOpenWhatsapp,
		handleClose: handleCloseWhatsapp,
	} = useDialogHook();

	return (
		<Card>
			{/* <MDBox p={2} lineHeight={0}>
				<MDTypography variant="h5">Notifications</MDTypography>
				<MDTypography
					variant="button"
					color="text"
					fontWeight="regular"
				>
					Notifications on this page use Toasts from Bootstrap. Read
					more details here.
				</MDTypography>
			</MDBox> */}
			<MDBox p={2}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} lg={3}>
						<MDButton
							variant="gradient"
							color="info"
							onClick={handleClickOpen}
							fullWidth
						>
							SMS GÖNDER
						</MDButton>
					</Grid>

					<Grid item xs={12} sm={6} lg={3}>
						<MDButton
							variant="gradient"
							color="success"
							onClick={handleClickOpenWhatsapp}
							fullWidth
						>
							Whatsapp Mesajı
						</MDButton>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<MDButton
							variant="gradient"
							color="warning"
							onClick={handleClickOpenCall}
							fullWidth
						>
							ARAMA KAYDI
						</MDButton>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<MDButton
							component="a"
							href={`https://erp2.aaro.com.tr/FaturaSatisFaturasi`}
							target="_blank"
							variant="gradient"
							color="error"
							// onClick={openErrorSB}
							fullWidth
						>
							Fatura Kes
						</MDButton>
					</Grid>
				</Grid>
			</MDBox>
			{open && (
				<SmsTemplates
					customer={customer}
					open={open}
					handleClose={handleClose}
				/>
			)}
			{openCall && (
				<CallTemplates
					customer={customer}
					open={openCall}
					handleClose={handleCloseCall}
				/>
			)}
			{openWhatsapp && (
				<WhatsappTemplates
					customer={customer}
					open={openWhatsapp}
					handleClose={handleCloseWhatsapp}
				/>
			)}
		</Card>
	);
}

export default Actions;
