import * as React from 'react';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ReorderIcon from '@mui/icons-material/Reorder';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ToggleButtonNotEmpty() {
    const [devices, setDevices] = React.useState(() => ['phone']);

    const handleDevices = (event, newDevices) => {
        if (newDevices.length) {
            setDevices(newDevices);
        }
    };

    return (
        <Stack direction="row" spacing={4}>
            <ToggleButtonGroup
                color="info"
                value={devices}
                onChange={handleDevices}
                aria-label="device"
                sx={{ height: 32 }}
                exclusive
            >
                <ToggleButton value="active" aria-label="active">
                    <LeaderboardIcon />
                </ToggleButton>
                <ToggleButton value="passive" aria-label="passive">
                    <ReorderIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}
