import { useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import { useMutation, useQueryClient, useQuery } from 'react-query';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { syncSpreadsheet } from 'services/api/google';
import { notify } from 'helpers/notificationHelper';

function SyncCell({ demandID }) {
	const [loading, setLoading] = useState(false);
	const queryClient = useQueryClient();

	const startSync = () => {
		setLoading(true);
		syncSpreadsheet(demandID)
			.then((rp) => {
				notify(rp.data.success, rp.data.message);
				queryClient.invalidateQueries(['fetchDemand', demandID]);
				setLoading(false);

				// window.location.reload();
			})
			.catch((err) => {
				console.log(err.toJSON());
				notify(false, JSON.stringify(err));
				setLoading(false);
			});
	};
	return (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			px={2}
		>
			<MDButton
				variant="gradient"
				color="success"
				size="small"
				disabled={loading}
				onClick={startSync}
			>
				Fiyat Eşitle
			</MDButton>
		</MDBox>
	);
}

export default SyncCell;
