import MUIRichTextEditor from 'mui-rte';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import pxToRem from 'assets/theme/functions/pxToRem';
const myTheme = createTheme({
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	overrides: {
		MUIRichTextEditor: {
			editor: {
				fontWeight: 400,
				lineHeight: 1.25,
				fontSize: pxToRem(12),
			},
		},
	},
});

function BillingInformation({ demand }) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	return (
		<>
			<MDTypography variant="h6" fontWeight="medium">
				Teklif Bilgileri
			</MDTypography>
			<MDBox
				component="li"
				display="flex"
				justifyContent="space-between"
				alignItems="flex-start"
				bgColor={darkMode ? 'transparent' : 'grey-100'}
				borderRadius="lg"
				pl={3}
				// mt={2}
			>
				<ThemeProvider theme={myTheme}>
					<MUIRichTextEditor
						defaultValue={JSON.stringify(
							JSON.parse(
								demand?.demandNotes ? demand.demandNotes : ''
							)
						)}
						readOnly={true}
						toolbar={false}
					/>
				</ThemeProvider>
			</MDBox>
		</>
	);
}

export default BillingInformation;
