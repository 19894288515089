import React, { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { updateProject } from 'services/api/project';
import Button from '@mui/material/Button';
// import { getDefaultColor } from 'helpers/colorHelper';
import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { getTags } from 'services/api/general/tag';
import { config } from 'Constants';
import AaroLoadingScreen from 'components/Loading';
import { integrateSpreadsheet } from 'services/api/google';

import { getUsers } from 'services/api/user';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { FormatItalic } from '@mui/icons-material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Stack from '@mui/material/Stack';
import trLocale from 'date-fns/locale/tr';
import Divider from '@mui/material/Divider';
import MuiLink from '@mui/material/Link';

const validationSchema = yup.object({
	sheetName: yup
		.string('Başlık giriniz')
		.min(5, '5 karakterden fazla olmalıdır')
		.max(200, '200 karakterden az olmalıdır')
		.required('Lütfen geçerli bir başlık giriniz'),
});

export default function InputArea(props) {
	const { setOpenDialog, demandID, setLoading, project } = props;

	const queryClient = useQueryClient();

	const [disableOnSubmit, setDisableOnSubmit] = useState(false);
	const customerNameSplitted = project.aaroCustomerName.split(' ');
	const aaroCustomerNameShort =
		customerNameSplitted.length > 2
			? customerNameSplitted[0] + ' ' + customerNameSplitted[1]
			: customerNameSplitted.join(' ');

	const projectNameSplitted = project.projectName.split(' ');
	const projectNameShort =
		projectNameSplitted.length > 2
			? projectNameSplitted[0] + ' ' + projectNameSplitted[1]
			: projectNameSplitted.join(' ');
	const user = JSON.parse(localStorage.getItem('user'));
	const sheetName = `${aaroCustomerNameShort} _ ${projectNameShort} | ${project?.createdBy?.username} _ ${config.google.priceListFolderName}`;
	// ! This initial values should be changed when the price list changed
	const formik = useFormik({
		initialValues: {
			sheetName,
			customerName: project.aaroCustomerName || '',
			customerID: project.aaroCustomerID || 0,
			priceListFolderName: config.google.priceListFolderName,
			rootFolderID: config.google.rootFolderID,
			googleSheetID: config.google.googleSheetID,
			sheetPageIDs: config.google.sheetPageIDs,
			// additionalDue: initial?.additionalDue || [null, null],
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setDisableOnSubmit(true);
			console.log(values);
			setLoading(true);
			integrateSpreadsheet(values, demandID)
				.then((rp) => {
					notify(rp.data.success, rp.data.message);
					queryClient.invalidateQueries([
						'fetchSpreadsheets',
						demandID,
					]);
					queryClient.invalidateQueries(['fetchDemand', demandID]);
					setLoading(false);

					// window.location.reload();
				})
				.catch((err) => {
					console.log(err.toJSON());
					notify(false, JSON.stringify(err));
					setLoading(false);
				});
			setOpenDialog(false);
		},
	});
	const spreadSheetLink = `https://docs.google.com/spreadsheets/d/${formik.values.googleSheetID}`;

	// useEffect(() => {
	// 	setProjectName(project.projectName);
	// }, [props]);
	return (
		<Card id="basic-info" sx={{ overflow: 'visible' }}>
			<form onSubmit={formik.handleSubmit}>
				<MDBox p={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<FormField
								label="Spreadsheet Adı"
								placeholder="Spreadsheet Adı"
								id="sheetName"
								name="sheetName"
								value={formik.values.sheetName}
								onChange={formik.handleChange}
								error={
									formik.touched.sheetName &&
									Boolean(formik.errors.sheetName)
								}
								helperText={
									formik.touched.sheetName &&
									formik.errors.sheetName
								}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<MDBox display="flex" py={1} pr={2}>
								<MDTypography
									variant="button"
									fontWeight="bold"
									textTransform="capitalize"
								>
									Kopyalanacak Fiyat Listesi: &nbsp;
								</MDTypography>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color="text"
								>
									<MuiLink
										href={spreadSheetLink}
										target="_blank"
										rel="noreferrer"
									>
										&nbsp;
										{formik.values.priceListFolderName}
									</MuiLink>
								</MDTypography>
							</MDBox>
							<MDBox display="flex" py={1} pr={2}>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color="primary"
								>
									Bu işlem kalem sayısına göre 3dk'ya kadar
									uzamaktadır. Entegrasyon hazır olduğunda
									sayfa yenilenecektir.
								</MDTypography>
							</MDBox>
						</Grid>

						<Grid item xs={12} sm={12}>
							<MDButton
								fullWidth
								size="small"
								variant="gradient"
								color="success"
								type="submit"
								disabled={disableOnSubmit}
							>
								Entegre Et
							</MDButton>
						</Grid>
					</Grid>
				</MDBox>
			</form>
		</Card>
	);
}
