import { Aaro } from 'aaro';
import { config } from 'Constants';

export const getDekontKalem = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.get('Dekont/Kalemler', data);
};

export const getDekontBaslik = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.get('Dekont/Basliklar', data);
};

export const postDekontBaslik = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.post('Dekont/Baslik', data);
};

export const postDekontKalem = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.post('Dekont/Kalem', data);
};
