import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { config } from 'Constants';
import LineSkeleton from 'components/Loading/LineSkeleton';
import Autocomplete from '@mui/material/Autocomplete';
import FormField from 'layouts/pages/account/components/FormField';

import { postCustomerByIDv2, getCustomerByID } from 'services/api/customer';
import { updateProject, postDemand } from 'services/api/project';

import axios from 'axios';
import { notify } from 'helpers/notificationHelper';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 500,
	maxHeight: 800,
	width: '100%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	overflowY: 'scroll',
};

function ChangeAaroName({ project }) {
	const [value, setValue] = React.useState('');
	const [finalValue, setFinalValue] = React.useState('');
	const [projectName, setProjectName] = React.useState(
		project.projectName ? project.projectName : ''
	);
	const [disabled, setDisabled] = React.useState(false);
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: `Bearer ${user.aaroToken}`,
		Accept: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded',
	};

	const {
		data: aaro,
		isLoading,
		error,
	} = useQuery(
		['aaroCari', value],
		({ signal }) =>
			axios.post(
				`${config.url.AARO_BASE}/api/Rehber/Secenekler?RehberID=RehberCari&Veri=${value}`,
				{},
				{
					headers,
					signal,
				}
			),
		{ enabled: Boolean(value) }
	);
	const [open, setOpen] = React.useState(true);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisabled(true);
		// console.log(value);
		// console.log(finalValue);
		try {
			const customerID = finalValue.split('-')[1];
			const customerName = finalValue.split('-')[0];
			// ! what if server doesnt save the cari?
			await postCustomerByIDv2(customerID, customerName)
				.then((rp) => console.log(rp))
				.catch((err) => notify(false, JSON.stringify(err)));

			// ! This step written for control purpose.
			const isSuccessfullyAdded = await getCustomerByID(customerID)
				.then((rp) => rp.data.result)
				.catch((err) => notify(false, JSON.stringify(err)));
			const projectData = {
				aaroCustomerID: customerID,
				aaroCustomerName: customerName,
				projectName,
			};
			// ! If we have this customer we can create project on it
			if (isSuccessfullyAdded?.customerID == customerID) {
				updateProject(project._id, projectData)
					.then((response) => {
						notify(response.data.success, response.data.message);
						window.location.reload();
					})
					.catch((err) => notify(false, JSON.stringify(err)));
			}
		} catch (err) {
			alert(err);
			setOpen(false);
		}
	};

	// if (isLoading) return <LoadingAnimation />;
	// if (isFetching) return <LoadingAnimation />;

	// if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<form onSubmit={handleSubmit}>
				<Fade in={open}>
					<MDBox sx={style}>
						<Autocomplete
							defaultValue=""
							value={finalValue}
							onChange={(event, newValue) => {
								setFinalValue(newValue);
							}}
							filterOptions={(x) => x}
							options={
								aaro?.data?.Model
									? aaro.data.Model.map((el) =>
											(
												el.Text.replaceAll(
													'-',
													' '
												).substring(
													0,
													el.Text.indexOf('(')
												) +
												'-' +
												el.ID
											).trim()
									  )
									: []
							}
							renderInput={(params) => (
								<FormField
									{...params}
									required
									label="Müşteri"
									InputLabelProps={{ shrink: true }}
									value={value}
									onChange={(e) => setValue(e.target.value)}
								/>
							)}
						/>

						{isLoading ? (
							<LineSkeleton width={420} line={10} />
						) : (
							<>
								<MDBox mt={3}>
									<FormField
										required
										label="Proje Adı"
										value={projectName}
										onChange={(e) =>
											setProjectName(e.target.value)
										}
									/>
								</MDBox>
								<MDBox mt={3}>
									<MDButton
										variant="gradient"
										color="info"
										type="submit"
										disabled={disabled}
									>
										Değiştir
									</MDButton>
								</MDBox>{' '}
							</>
						)}
					</MDBox>
				</Fade>
			</form>
		</Modal>
	);
}

export default ChangeAaroName = React.memo(ChangeAaroName);
