import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';
import { getStockPrice } from 'services/outside/aaro/priceList';
import { formatDate } from 'helpers/smallCodes';
import Link from '@mui/material/Link';

import AaroLoadingScreen from 'components/Loading';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ErrorIcon from '@mui/icons-material/Error';

import TimelineIcon from '@mui/icons-material/Timeline';
import { calculateDiscount, calculateTaxPrice, formatPrice } from 'helpers/priceHelpers';
import { FaAngular } from 'react-icons/fa';

import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data

import { todayIsBetween } from 'helpers/dateHelpers';
import MDButton from 'components/MDButton';
import Divider from '@mui/material/Divider';
import MDBadge from 'components/MDBadge';

import StockAmounts from './components/StockAmounts';
import StockMovements from './components/StockMovements';
import AddToBasket from '../../components/AddToBasket';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const fetchAaroStockPrice = async (stokID) => {
    const response = await getStockPrice(stokID).then((rp) => rp.data);
    return response;
};
function StockPrice(props) {
    const {
        stockID,
        activeStockCode,
        setOpen,

        stockName,
        stockCode,
        queryID,
        objectID,
    } = props;

    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchAaroStockPrice', stockID],
        () => fetchAaroStockPrice(stockID),
        { staleTime: 600000 }
    );

    if (isLoading) return <AaroLoadingScreen />;
    if (isFetching) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <ProductPriceHistory
            data={data.Model[0]}
            price={data.Model[0]?.Fiyat}
            stockID={stockID}
            stokKodu={activeStockCode}
            setOpen={setOpen}
            stockName={stockName}
            stockCode={stockCode}
            queryID={queryID}
            objectID={objectID}
        />
    );
}

const ProductPriceHistory = (props) => {
    const { data, price, stockID, setOpen, stockName, stockCode, queryID, objectID } = props;
    const [changablePrice, setChangablePrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [grupValue, setGrupValue] = useState(0);
    const [paymentValue, setPaymentValue] = useState(0);
    const [profitColor, setProfitColor] = useState('#4caf50');
    const [profitValue, setProfitValue] = useState(100);
    const [amount, setAmount] = useState(1);
    const [activeView, setActiveView] = useState('');

    const [tax, setTax] = useState(20);
    useEffect(() => {
        setChangablePrice(price);
    }, [price]);
    useEffect(() => {
        setDiscount(grupValue + paymentValue);
        calculateProfit();
    }, [grupValue, paymentValue]);
    useEffect(() => {
        calculateProfit();
    }, [tax]);
    useEffect(() => {
        calculateProfit();
    }, [discount]);

    const handleGrupChange = (event) => {
        setGrupValue(parseFloat(event.target.value));
    };
    const handlePaymentChange = (event) => {
        setPaymentValue(parseFloat(event.target.value));
    };

    const alertStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: profitColor,
    };

    const calculateProfit = () => {
        const profit = 100 - 2 * (discount + 20 - tax);
        const success = '#4caf50';
        const info = '#2196f3';
        const warn = '#ff9800';
        const danger = '#f44336';

        if (profit >= 81) setProfitColor(success);
        if (profit < 81 && profit >= 60) setProfitColor(info);
        if (profit < 60 && profit >= 40) setProfitColor(warn);
        if (profit < 40) setProfitColor(danger);

        setProfitValue(profit);
    };

    const isValidDay = todayIsBetween(new Date(data?.TarihBas), new Date(data?.TarihBit));

    return (
        <>
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={3}
                    py={4}
                    color="white"
                    bgColor="secondary"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                >
                    <MDBox>
                        <MDTypography
                            color="white"
                            sx={{
                                typography: {
                                    xs: 'caption',
                                    sm: 'caption',
                                    md: 'h6',
                                },
                                textAlign: {
                                    xs: 'center',
                                    sm: 'center',
                                },
                                color: {
                                    xs: 'white',
                                    sm: 'white',
                                    md: 'white',
                                },
                            }}
                        >
                            {' '}
                            {isValidDay ? 'Geçerli Liste' : 'Geçersiz Liste - Dikkatli Olun!'}
                        </MDTypography>
                    </MDBox>
                    <MDBox>
                        <MDTypography
                            sx={{
                                typography: {
                                    xs: 'caption',
                                    sm: 'caption',
                                    md: 'h6',
                                },
                                textAlign: {
                                    xs: 'center',
                                    sm: 'center',
                                },
                                color: {
                                    xs: 'white',
                                    sm: 'white',
                                    md: 'white',
                                },
                            }}
                            color="white"
                        >
                            {formatDate(data?.TarihBas)}&nbsp;-&nbsp;
                            {formatDate(data?.TarihBit)} Fiyat Listesi
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <Divider />

                <MDBox pb={3} px={3}>
                    <Grid container spacing={3}>
                        <MDBox component={Grid} item xs={12} sm={6} display={{ xs: 'none', sm: 'none', md: 'block' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <MDBadge variant="gradient" color="light" badgeContent={`${stockName}`} container />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RadioGroup
                                        aria-label="grup"
                                        name="grup1"
                                        value={grupValue}
                                        onChange={handleGrupChange}
                                    >
                                        <FormControlLabel value={15} control={<Radio />} label="Perakende" />
                                        <FormControlLabel value={26} control={<Radio />} label="Bayi A" />
                                        <FormControlLabel value={22} control={<Radio />} label="Bayi B" />
                                        <FormControlLabel value={18} control={<Radio />} label="Bayi C" />
                                        <FormControlLabel value={0} control={<Radio />} label="Sıfır" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={paymentValue}
                                        onChange={handlePaymentChange}
                                    >
                                        <FormControlLabel value={12} control={<Radio />} label="Nakit" />
                                        <FormControlLabel value={9} control={<Radio />} label="Visa 1" />
                                        <FormControlLabel value={7} control={<Radio />} label="Visa 5" />
                                        <FormControlLabel value={3} control={<Radio />} label="Çek 30" />
                                        {/* <FormControlLabel
											value={0}
											control={<Radio />}
											label="Çek 60"
										/> */}
                                        <FormControlLabel value={0} control={<Radio />} label="Sıfır" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <Grid item xs={12} sm={6}>
                            <Alert
                                // severity="success"
                                icon={false}
                                style={alertStyle}
                            >
                                <strong style={{ fontSize: '1.5em' }}>
                                    {' '}
                                    {formatPrice(
                                        calculateTaxPrice(calculateDiscount(changablePrice, amount, discount), tax),
                                        data?.DovizKodu
                                    )}{' '}
                                </strong>
                                {' / '}
                                {data?.BirimAdi}
                            </Alert>
                            <MDBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="Gelen Fiyat"
                                            type="number"
                                            // variant="outlined"
                                            value={changablePrice}
                                            onChange={(e) => setChangablePrice(parseFloat(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="Miktar"
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="Toplam İskonto %"
                                            type="number"
                                            value={discount}
                                            onChange={(e) => setDiscount(parseFloat(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="KDV"
                                            type="number"
                                            value={tax}
                                            onChange={(e) => setTax(parseFloat(e.target.value))}
                                        />
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <MDBox display="flex" justifyContent="space-around">
                                <Link>
                                    <AddToBasket
                                        stockID={stockID}
                                        Price={changablePrice}
                                        Amount={amount}
                                        Discount={discount}
                                        Tax={tax}
                                        setOpen={setOpen}
                                        stockName={stockName}
                                        sku={stockCode}
                                        queryID={queryID}
                                        objectID={objectID}
                                    />
                                </Link>
                                <Link>
                                    <MDButton
                                        variant="gradient"
                                        color="warning"
                                        size="small"
                                        onClick={() => setActiveView('depo')}
                                        startIcon={<ArrowDownwardIcon />}
                                    >
                                        Depo
                                    </MDButton>
                                </Link>
                                <Link>
                                    <MDBox
                                        display={{
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'block',
                                        }}
                                    >
                                        <MDButton
                                            variant="gradient"
                                            color="secondary"
                                            size="small"
                                            onClick={() => setActiveView('hareketleri')}
                                            startIcon={<TimelineIcon />}
                                            display={{
                                                xs: 'none',
                                                sm: 'none',
                                                md: 'block',
                                            }}
                                        >
                                            HAREKETLERİ
                                        </MDButton>
                                    </MDBox>
                                </Link>

                                <Link href={`https://erp2.aaro.com.tr/Stok/Kalem?id=${stockID}`} target="_blank">
                                    <MDButton variant="gradient" color="info" size="small" startIcon={<FaAngular />}>
                                        Aaro
                                    </MDButton>
                                </Link>

                                <Link
                                    href={`https://form.jotform.com/213384568913060?stokKodu=${stockCode}`}
                                    target="_blank"
                                >
                                    <MDBox
                                        display={{
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'block',
                                        }}
                                    >
                                        <MDButton
                                            variant="gradient"
                                            color="primary"
                                            size="small"
                                            startIcon={<ErrorIcon />}
                                        >
                                            Hata
                                        </MDButton>
                                    </MDBox>
                                </Link>
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Divider />
                    {<RenderButtonViews viewName={activeView} stockID={stockID} />}
                </MDBox>
            </Card>
        </>
    );
};

const RenderButtonViews = ({ viewName, stockID }) => {
    if (viewName === 'depo')
        return (
            <MDBox p={3}>
                <StockAmounts stockID={stockID} />
            </MDBox>
        );
    if (viewName === 'hareketleri')
        return (
            <MDBox pt={3}>
                <StockMovements stockID={stockID} />
            </MDBox>
        );

    return <MDBox></MDBox>;
};

export default StockPrice = React.memo(StockPrice);
