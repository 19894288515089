// @mui material components
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default styled(Box)(({ theme, ownerState }) => {
    const { palette, typography, borders, functions } = theme;
    const { darkMode } = ownerState;

    const { text, white, dark, inputBorderColor, transparent, error, success, info } = palette;
    const { size } = typography;
    const { borderRadius, borderWidth } = borders;
    const { rgba } = functions;

    return {
        display: 'flex',
        alignItems: 'center',
        border: `${borderWidth[1]} solid ${inputBorderColor} !important`,
        borderRadius: borderRadius.md,

        '&.dropzone': {
            background: `${transparent.main} !important`,
        },

        '& .dz-default': {
            margin: '0 auto !important',
        },

        '& .dz-default .dz-button': {
            color: `${text.main} !important`,
            fontSize: `${size.sm} !important`,
        },

        '& .dz-preview.dz-image-preview': {
            background: `${transparent.main} !important`,
        },

        '& .dz-preview .dz-details': {
            color: `${dark.main} !important`,
            opacity: '1 !important',

            '& .dz-size span, & .dz-filename span': {
                backgroundColor: `${rgba(white.main, 0.7)} !important`,
            },
        },

        '& .dz-error-message': {
            display: 'none !important',
        },

        '& .dz-remove': {
            color: `${darkMode ? white.main : dark.main} !important`,
            textDecoration: 'none',

            '&:hover, &:focus': {
                textDecoration: 'none !important',
            },
        },

        '& .dz-preview.dz-uploading': {
            boxShadow: `0 0 10px 5px ${info.main} !important`,
        },

        '& .dz-preview.dz-success': {
            boxShadow: `0 0 10px 5px ${success.main} !important`,
        },

        '& .dz-preview.dz-error-custom': {
            boxShadow: `0 0 10px 5px ${error.main} !important`,
        },
    };
});
