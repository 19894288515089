// @mui material components
import Grid from '@mui/material/Grid';

import Offer from 'layouts/projects/Components/Offer';

function OfferPage({ project, setValue }) {
    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
                <Offer demandID={project?.demand} projectID={project._id} setValue={setValue} />
            </Grid>
        </Grid>
    );
}

export default OfferPage;
