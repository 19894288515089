export const SHEET_TYPES = {
    KANAT: 'kanat',
    U_KASA: 'uKasa',
    KASA: 'kasa',
    PERVAZ: 'pervaz',
    U_PERVAZ: 'uPervaz',
};

export const SHEET_FIELDS = {
    [SHEET_TYPES.KANAT]: {
        MIKTAR: 'KANAT_MIKTAR',
        YUZEY_AD: 'KANAT_MAMULLER_YUZEY_AD',
        YUZEY_FIYAT: 'KANAT_MAMULLER_YUZEY_LISTE_FIYATI',
        HAM_AD: 'KANAT_MAMULLER_KANAT_AD',
        HAM_FIYAT: 'KANAT_MAMULLER_KANAT_LISTE_FIYATI',
        BOYALI_AD: 'KANAT_MAMULLER_BOYALI_KANAT_AD',
        BOYALI_FIYAT: 'KANAT_MAMULLER_BOYALI_KANAT_LISTE_FIYATI',
    },
    [SHEET_TYPES.U_KASA]: {
        MIKTAR: 'UKASA_MIKTAR',
        HAM_AD: 'UKASA_MAMULLER_HAM_UKASA_AD',
        HAM_FIYAT: 'UKASA_MAMULLER_HAM_UKASA_LISTE_FIYATI',
        SIVAMALI_AD: 'UKASA_MAMULLER_SIVAMALI_HAM_UKASA_AD',
        SIVAMALI_FIYAT: 'UKASA_MAMULLER_SIVAMALI_HAM_UKASA_LISTE_FIYATI',
        BOYALI_AD: 'UKASA_MAMULLER_BOYALI_UKASA_AD',
        BOYALI_FIYAT: 'UKASA_MAMULLER_BOYALI_UKASA_LISTE_FIYATI',
    },
    [SHEET_TYPES.KASA]: {
        MIKTAR: 'KASA_MIKTAR',
        HAM_AD: 'KASA_MAMULLER_HAM_KASA_AD',
        HAM_FIYAT: 'KASA_MAMULLER_HAM_KASA_LISTE_FIYATI',
        SIVAMALI_AD: 'KASA_MAMULLER_SIVAMALI_HAM_KASA_AD',
        SIVAMALI_FIYAT: 'KASA_MAMULLER_SIVAMALI_HAM_KASA_LISTE_FIYATI',
        BOYALI_AD: 'KASA_MAMULLER_BOYALI_KASA_AD',
        BOYALI_FIYAT: 'KASA_MAMULLER_BOYALI_KASA_LISTE_FIYATI',
    },
    [SHEET_TYPES.PERVAZ]: {
        MIKTAR: 'PERVAZ_MIKTAR',
        HAM_AD: 'PERVAZ_MAMULLER_HAM_PERVAZ_AD',
        HAM_FIYAT: 'PERVAZ_MAMULLER_HAM_PERVAZ_LISTE_FIYATI',
        SIVAMALI_AD: 'PERVAZ_MAMULLER_SIVAMALI_HAM_PERVAZ_AD',
        SIVAMALI_FIYAT: 'PERVAZ_MAMULLER_SIVAMALI_HAM_PERVAZ_LISTE_FIYATI',
        BOYALI_AD: 'PERVAZ_MAMULLER_BOYALI_PERVAZ_AD',
        BOYALI_FIYAT: 'PERVAZ_MAMULLER_BOYALI_PERVAZ_LISTE_FIYATI',
    },
    [SHEET_TYPES.U_PERVAZ]: {
        MIKTAR: 'UPERVAZ_MIKTAR',
        HAM_AD: 'UPERVAZ_MAMULLER_HAM_UPERVAZ_AD',
        HAM_FIYAT: 'UPERVAZ_MAMULLER_HAM_UPERVAZ_LISTE_FIYATI',
        SIVAMALI_AD: 'UPERVAZ_MAMULLER_SIVAMALI_HAM_UPERVAZ_AD',
        SIVAMALI_FIYAT: 'UPERVAZ_MAMULLER_SIVAMALI_HAM_UPERVAZ_LISTE_FIYATI',
        BOYALI_AD: 'UPERVAZ_MAMULLER_BOYALI_UPERVAZ_AD',
        BOYALI_FIYAT: 'UPERVAZ_MAMULLER_BOYALI_UPERVAZ_LISTE_FIYATI',
    },
};

export const PRICE_CARD_CONFIGS = {
    [SHEET_TYPES.KANAT]: [
        {
            label: '(2) HAM YUZEY',
            nameField: 'YUZEY_AD',
            priceField: 'YUZEY_FIYAT',
            amountMultiplier: 2,
            color: 'success',
        },
        { label: '(1) HAM KANAT', nameField: 'HAM_AD', priceField: 'HAM_FIYAT', amountMultiplier: 1, color: 'info' },
        {
            label: '(1) BOYALI KANAT',
            nameField: 'BOYALI_AD',
            priceField: 'BOYALI_FIYAT',
            amountMultiplier: 1,
            color: 'dark',
        },
    ],
    [SHEET_TYPES.U_KASA]: [
        { label: '(1) Kasa Ham', nameField: 'HAM_AD', priceField: 'HAM_FIYAT', amountMultiplier: 1, color: 'success' },
        {
            label: '(1) Kasa Sıvama',
            nameField: 'SIVAMALI_AD',
            priceField: 'SIVAMALI_FIYAT',
            amountMultiplier: 1,
            color: 'info',
        },
        {
            label: '(1) Kasa Boyalı',
            nameField: 'BOYALI_AD',
            priceField: 'BOYALI_FIYAT',
            amountMultiplier: 1,
            color: 'dark',
        },
    ],
    [SHEET_TYPES.KASA]: [
        { label: '(1) Kasa Ham', nameField: 'HAM_AD', priceField: 'HAM_FIYAT', amountMultiplier: 1, color: 'success' },
        {
            label: '(1) Kasa Sıvama',
            nameField: 'SIVAMALI_AD',
            priceField: 'SIVAMALI_FIYAT',
            amountMultiplier: 1,
            color: 'info',
        },
        {
            label: '(1) Kasa Boyalı',
            nameField: 'BOYALI_AD',
            priceField: 'BOYALI_FIYAT',
            amountMultiplier: 1,
            color: 'dark',
        },
    ],
    [SHEET_TYPES.PERVAZ]: [
        {
            label: '(1) Pervaz Ham',
            nameField: 'HAM_AD',
            priceField: 'HAM_FIYAT',
            amountMultiplier: 1,
            color: 'success',
        },
        {
            label: '(1) Pervaz Sıvama',
            nameField: 'SIVAMALI_AD',
            priceField: 'SIVAMALI_FIYAT',
            amountMultiplier: 1,
            color: 'info',
        },
        {
            label: '(1) Pervaz Boyalı',
            nameField: 'BOYALI_AD',
            priceField: 'BOYALI_FIYAT',
            amountMultiplier: 1,
            color: 'dark',
        },
    ],
    [SHEET_TYPES.U_PERVAZ]: [
        {
            label: '(1) U-Pervaz Ham',
            nameField: 'HAM_AD',
            priceField: 'HAM_FIYAT',
            amountMultiplier: 1,
            color: 'success',
        },
        {
            label: '(1) U-Pervaz Sıvama',
            nameField: 'SIVAMALI_AD',
            priceField: 'SIVAMALI_FIYAT',
            amountMultiplier: 1,
            color: 'info',
        },
        {
            label: '(1) U-Pervaz Boyalı',
            nameField: 'BOYALI_AD',
            priceField: 'BOYALI_FIYAT',
            amountMultiplier: 1,
            color: 'dark',
        },
    ],
};
