import { useState } from 'react';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { deleteTask } from 'services/api/general/task';
import { notify } from 'helpers/notificationHelper';
import { useQueryClient } from 'react-query';
import AddTodo from 'components/General/Task/utils/AddTodo';
import Dialog from '@mui/material/Dialog';
function DeleteOrEditTask({ task }) {
    const queryClient = useQueryClient();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <Card id="delete-account">
            <MDBox p={2} lineHeight={1}>
                <MDBox mb={1}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Ana Görev
                    </MDTypography>
                    <MDTypography variant="button" color="text">
                        Ana görevi silmek ve düzenlemek için aşağıdaki butonları kullanınız.
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox m={1}>
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <MDButton variant="outlined" color="secondary" fullWidth onClick={() => setOpenDialog(true)}>
                        Düzenle
                    </MDButton>
                    &nbsp;
                    <MDButton
                        variant="gradient"
                        color="error"
                        fullWidth
                        onClick={() => {
                            const confirmation = window.confirm('Emin Misiniz?');
                            if (confirmation)
                                deleteTask(task._id)
                                    .then((rp) => {
                                        notify(rp.data.success, rp.data.message);
                                        queryClient.invalidateQueries(['fetchTasks', task.taskList, null]);
                                    })
                                    .catch((err) => notify(false, JSON.stringify(err)));
                        }}
                    >
                        SİL
                    </MDButton>
                </MDBox>
            </MDBox>
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog} fullWidth={true} maxWidth="sm">
                {/*Burada  */}
                <AddTodo initial={task} setOpenDialog={setOpenDialog} update={true} />
            </Dialog>
        </Card>
    );
}

export default DeleteOrEditTask;
