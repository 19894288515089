import React, { useEffect, useState } from 'react';
import { config } from 'Constants';

import { useMutation, useQueryClient, useQuery } from 'react-query';

import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
// import Chip from '@mui/material/Divider';
import { postUKasa, updateUKasa } from 'services/api/form';
import { getTags } from 'services/api/general/tag';
import FormSelect from '../FormComponents/FormSelect';
import EmptySpace from '../FormComponents/EmptySpace';
import FormTextFieldTitle from '../FormComponents/FormTextFieldTitle';
import FormSwitch from '../FormComponents/FormSwitch';
import OutlinedFormTextField from '../FormComponents/OutlinedFormTextField';

import { validationSchemaUKasa } from '../FormComponents/YupControlSchema';
import MDButton from 'components/MDButton';
import AaroLoadingScreen from 'components/Loading';

const useStyles = makeStyles((theme) => ({
	switch: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	inputs: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));

const fetchTags = async (id) => {
	const response = await getTags(id).then((rp) =>
		rp.data?.result?.tags ? rp.data?.result?.tags : []
	);
	return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.DEMAND_STATUS_TAG_GROUP_ID;
export const UKasa = (props) => {
	const queryClient = useQueryClient();
	const {
		data: tags,
		isLoading,
		error,
	} = useQuery(
		['fetchTagGroups', TAG_GROUP_ID_DEMAND],
		() => fetchTags(TAG_GROUP_ID_DEMAND),
		{ staleTime: 600000 }
	);
	const user = JSON.parse(localStorage.getItem('user'));
	const [disableOnSubmit, setDisableOnSubmit] = React.useState(false);
	const classes = useStyles();
	const { demandID, initial, id, setOpen, projectID } = props;

	const formik = useFormik({
		initialValues: {
			baslik: initial?.baslik || 'U Kasa',
			miktar: initial?.miktar || null,
			kalinlik: initial?.kalinlik || null,
			en: initial?.en || null,
			boy: initial?.boy || null,
			cins: initial?.cins || '',
			cinsAciklama: initial?.cinsAciklama || '',
			yapi: initial?.yapi || 'MDF',
			lambaDerinlik: initial?.lambaDerinlik || 1.2,
			lambaGenislik: initial?.lambaGenislik || 4.5,
			fitil: initial?.fitil || 'L',
			pervazKanalGenisligi: initial?.pervazKanalGenisligi || 0.5,
			pervazKanalDerinligi: initial?.pervazKanalDerinligi || 3,
			pervazKanaliAltBosluk: initial?.pervazKanaliAltBosluk || 0.9,
			ozelProfilTipi: initial?.ozelProfilTipi || 'Standart',
			ozelProfilAciklama: initial?.ozelProfilAciklama || '',
			dumanFitili: initial?.dumanFitili || false,
			kesim45: initial?.kesim45 || false,
			kilitKarsiligi: initial?.kilitKarsiligi || false,
			menteseKarsiligi: initial?.menteseKarsiligi || false,
			hidrolikKarsiligi: initial?.hidrolikKarsiligi || false,
			soveMi: initial?.soveMi || false,
			ekNot: initial?.ekNot || '',
			indirimOrani: initial?.indirimOrani || 0,
			yoneticiNotu: initial?.yoneticiNotu || '',
			verilenFiyat: initial?.verilenFiyat || null,
			durumu: initial?.durumu || '',
		},
		validationSchema: validationSchemaUKasa,
		onSubmit: (values) => {
			setDisableOnSubmit(true);
			if (id) {
				updateUKasa(id, values)
					.then(() => {
						queryClient.invalidateQueries([
							'fetchDemand',
							demandID,
						]);
						setOpen(false);
					})
					.catch((err) => {
						console.log(err);
						setDisableOnSubmit(false);
					});
			} else {
				postUKasa(demandID, values)
					.then(() => {
						queryClient.invalidateQueries([
							'fetchDemand',
							demandID,
						]);
						setOpen(false);
					})

					.catch((err) => {
						console.log(err);
						setDisableOnSubmit(false);
					});
			}
		},
	});
	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;
	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12}>
					{/* Başlık */}
					<FormTextFieldTitle
						variant="filled"
						fullWidth={true}
						label="Başlık"
						placeHolder="Örn: Kasa Başlık"
						formik={formik}
						itemID="baslik"
						disabled={true}
						selector="uKasa"
					/>
					<EmptySpace height="30px" />
					{/* Miktar - Kalınlık - En - Boy */}
					<Grid container justifyContent="space-between" spacing={3}>
						{/* Miktar */}
						<Grid item xs={12} sm={12} md={3}>
							<OutlinedFormTextField
								itemID="miktar"
								label="Miktar (Adet)"
								placeHolder="Örn: 1000"
								type="number"
								formik={formik}
								step="1"
								adornment="AD"
							/>
						</Grid>
						{/* Kalınlık */}
						<Grid item xs={12} sm={12} md={3}>
							<OutlinedFormTextField
								itemID="kalinlik"
								label="Kalınlık"
								placeHolder="Örn: 4.1"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
						{/* En */}
						<Grid item xs={12} sm={12} md={3}>
							<OutlinedFormTextField
								itemID="en"
								label="En"
								placeHolder="Örn: 12"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
						{/* Boy */}
						<Grid item xs={12} sm={12} md={3}>
							<OutlinedFormTextField
								itemID="boy"
								label="Boy"
								placeHolder="Örn: 209"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
					</Grid>
					{/* Lamba Derinlik - Lamba Genişlik - Pervaz Kanal Genişlik - Pervaz Kanal Derinliği */}
					<Grid container className={classes.inputs}>
						{/* lambaDerinlik */}
						<Grid item xs={12} sm={12} md={2}>
							<OutlinedFormTextField
								itemID="lambaDerinlik"
								label="Lamba Derinlik (cm)"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
						{/* Lamba Genişlik */}
						<Grid item xs={12} sm={12} md={2}>
							<OutlinedFormTextField
								itemID="lambaGenislik"
								label="Lamba Genişlik (cm)"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
						{/* Pervaz Kanal Genişlik */}
						<Grid item xs={12} sm={12} md={2}>
							<OutlinedFormTextField
								itemID="pervazKanalGenisligi"
								label="Pervaz Kanal Genişliği (cm)"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
						{/* Pervaz Kanal Derinliği */}
						<Grid item xs={12} sm={12} md={2}>
							<OutlinedFormTextField
								itemID="pervazKanalDerinligi"
								label="Pervaz Kanal Derinliği (cm)"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
						{/* Pervaz Kanal Alt Boşluğu */}
						<Grid item xs={12} sm={12} md={2}>
							<OutlinedFormTextField
								itemID="pervazKanaliAltBosluk"
								label="Pervaz Kanal Alt Boşluğu (cm)"
								type="number"
								formik={formik}
								step="0.1"
								adornment="CM"
							/>
						</Grid>
					</Grid>
				</Grid>
				<EmptySpace height="15px" />
				{/* Cins - Yapı - Özel Profil Tipi - Özel Profil Tipi Açıklaması*/}
				<Grid item xs={6}>
					{/* Cins */}
					<FormSelect
						itemID="cins"
						formik={formik}
						inputName="Cins"
						items={[
							{
								name: 'Lakelik',
								value: 'Lakelik',
							},
							{
								name: 'Desenli',
								value: 'Desenli',
							},
						]}
						helperText={'Kasanın cinsi'}
					/>
					{/* Yapı */}
					<FormSelect
						itemID="yapi"
						formik={formik}
						inputName="Yapı"
						items={[
							{
								name: 'MDF',
								value: 'MDF',
							},
							{
								name: 'Yumuşak Kontrplak',
								value: 'Yumuşak Kontrplak',
							},
							{
								name: 'Sert Kontrplak',
								value: 'Sert Kontrplak',
							},
						]}
						helperText={'Kasanın Yapısı'}
					/>
					{/*Özel Profil Tipi */}
					<FormSelect
						itemID="ozelProfilTipi"
						formik={formik}
						inputName="Profil Tipi"
						items={[
							{
								name: 'Standart',
								value: 'Standart',
							},
							{
								name: 'Farklı Radüsler',
								value: 'Farklı Radüsler',
							},
							{
								name: 'Özel Profil',
								value: 'Özel Profil',
							},
							{
								name: 'Özel Profil + Bıçak',
								value: 'Özel Profil + Bıçak',
							},
						]}
						helperText={'Kasa Profil Tipi'}
					/>
					{/* Köşeli / Bombeli */}
					{/* <FormSelect
						itemID="koseliBombeli"
						formik={formik}
						inputName="Köşeli / Bombeli"
						items={[
							{
								name: 'Köşeli',
								value: 'Köşeli',
							},
							{
								name: 'Bombeli',
								value: 'Bombeli',
							},
						]}
						helperText={'Kasa Bombesi'}
					/> */}
				</Grid>
				{/* Cins Açıklaması - Fitil - Girinti - Köşeli Bombeli*/}
				<Grid item xs={6}>
					{/* Cins Açıklaması */}
					<OutlinedFormTextField
						itemID="cinsAciklama"
						label="Cins Açıklaması"
						placeHolder="Cins Açıklama Alanı(Opsiyonel)"
						formik={formik}
						adornment=""
					/>
					{/* Fitil */}
					<FormSelect
						itemID="fitil"
						formik={formik}
						inputName="Fitil"
						items={[
							{
								name: 'L',
								value: 'L',
							},
							{
								name: 'T',
								value: 'T',
							},
						]}
						helperText={'Kasanın Fitili'}
					/>
					{/* Özel Profil Tipi Açıklaması*/}
					<OutlinedFormTextField
						itemID="ozelProfilAciklama"
						label="Kasa Profil Tipi Açıklaması"
						placeHolder="Örn: Cnc deseni isteniyor"
						formik={formik}
						adornment=""
					/>
				</Grid>
				{/* Ek Not */}
				<Grid item xs={12}>
					{/* Ek Not */}
					<OutlinedFormTextField
						itemID="ekNot"
						label="Ek Notlarınız"
						placeHolder="Örn: Kendi bıçaklarını verecekler"
						formik={formik}
					/>
				</Grid>
				{/* Var Yok Seçenekleri */}
				<Grid container className={classes.switch}>
					{/* Duman Fitili*/}
					<FormSwitch
						formik={formik}
						name="dumanFitili"
						color="secondary"
						label="Duman Fitili"
					/>
					{/* 45 Kesim*/}
					<FormSwitch
						formik={formik}
						name="kesim45"
						color="secondary"
						label="45 Kesim"
					/>
					{/* Kilit Karşılığı*/}
					<FormSwitch
						formik={formik}
						name="kilitKarsiligi"
						color="secondary"
						label="Kilit Karşılığı"
					/>
					{/* Menteşe Karşılığı*/}
					<FormSwitch
						formik={formik}
						name="menteseKarsiligi"
						color="secondary"
						label="Menteşe Karşılığı"
					/>
					{/* Hidrolik Karşılığı*/}
					<FormSwitch
						formik={formik}
						name="hidrolikKarsiligi"
						color="secondary"
						label="Hidrolik Karşılığı"
					/>

					{/* Söve Mi?*/}
					<FormSwitch
						formik={formik}
						name="soveMi"
						color="secondary"
						label="Söve Mi"
					/>
				</Grid>
				{/* Yönetici Alanı*/}
				<Grid item xs={12} sm={12} md={12}>
					<Grid container spacing={3}>
						{/* Yönetici Notu*/}
						<Grid item xs={12} sm={12} md={4}>
							<OutlinedFormTextField
								itemID="yoneticiNotu"
								label="Yöneticinin Notu"
								placeHolder="Yönetici Notu"
								formik={formik}
								disabled={user.role != 'superadmin'}
							/>
						</Grid>
						{/* Verilen Fiyat */}
						<Grid item xs={12} sm={12} md={4}>
							<OutlinedFormTextField
								itemID="verilenFiyat"
								label="Birim Fiyat"
								placeHolder="Birim Fiyat"
								formik={formik}
								step="0.01"
								type="number"
								// disabled={user.role != 'superadmin'}
							/>
						</Grid>

						{/* Teklif Durumu */}
						<Grid item xs={12} sm={12} md={4}>
							{/* Girinti */}
							<FormSelect
								itemID="durumu"
								formik={formik}
								inputName="Talep Durumu"
								items={tags.map((tag) => ({
									value: tag._id,
									name: tag.name,
								}))}
								helperText={'Talebin durumunu seçin'}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sm={12} md={12}>
					<MDButton
						color="dark"
						variant="gradient"
						fullWidth
						type="submit"
						disabled={disableOnSubmit}
					>
						Kaydet
					</MDButton>
				</Grid>
			</Grid>
		</form>
	);
};
