// Libraries

// Layout

// MD2 PRO
import MDBox from 'components/MDBox';
// @mui
import Grid from '@mui/material/Grid';

// Project Pages And Components
import Location from 'layouts/customers/components/Location';
import Potential from 'layouts/customers/components/Potential';
// import FileView from 'components/General/File';
import AddFile from 'components/General/File/AddFile';

// API

// Aaro Components
import CustomerDetails from 'components/Aaro/customer/CustomerDetails';
import CustomerResponsibles from 'components/Aaro/customer/CustomerResponsibles';
import AaroCustomerSales from 'components/Aaro/customer/CustomerSales';
import Actions from 'layouts/customers/components/Actions';
import DisplaySMS from 'components/General/SMS/DisplaySMS.js';
import DisplayCalls from 'components/General/Call/DisplayCalls.js';
import CustomerFeedback from 'layouts/customers/components/CustomerFeedback/index.js';
import DataTable from 'examples/Tables/DataTable';
import dataTableData from 'layouts/dashboards/sales/data/dataTableData';
import SingleProduct from 'layouts/orders/pages/SingleOrder/MainPage/components/SingleProduct.js';
// Helpers

function MainPage({ products, document }) {
	const customerID = products[0] ? products[0].CariID : 0;

	return (
		<MDBox>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{/* {JSON.stringify(products)} */}

					<MDBox display="flex" flexDirection="column">
						{products?.map((product) => (
							<MDBox mb={3}>
								<SingleProduct
									key={product?.StokID}
									product={product}
								/>
							</MDBox>
						))}
					</MDBox>

					{/* <DataTable
						table={dataTableData}
						entriesPerPage={false}
						showTotalEntries={false}
						isSorted={false}
						noEndBorder
					/> */}
				</Grid>
			</Grid>
		</MDBox>
	);
}

/*
	<Grid item xs={12} lg={4}>
						<MDBox mb={3}>
							<CustomerDetails
								customerID={customerID}
								icon="person"
								color="dark"
							/>
							</MDBox>

							<MDBox mb={3}>
								<AaroCustomerSales customerID={customerID} />
							</MDBox>
						</Grid>

*/

export default MainPage;
