import { useQuery } from 'react-query';
// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// OfferPage page components
import Header from 'layouts/projects/Components/Offer/components/Header';
import OfferNotes from 'layouts/projects/Components/Offer/components/OfferNotes';
import OfferSummary from 'layouts/projects/Components/Offer/components/OfferSummary';
import DemandsTable from 'layouts/projects/Components/Offer/components/DemandsTable';
import OfferGroups from 'layouts/projects/Components/Offer/components/OfferGroups';
import { getDemand, getGroups } from 'services/api/project';

import AaroLoadingScreen from 'components/Loading';
const fetchDemand = async (id) => {
    const response = await getDemand(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};
const fetchGroups = async (demandID) => {
    const response = await getGroups(demandID)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

function Offer({ demandID, projectID, isHistory = false, setValue }) {
    const { data, isLoading, error } = useQuery(['fetchDemand', demandID], () => fetchDemand(demandID), {
        staleTime: 600000,
    });

    const { data: demandGroups } = useQuery(['fetchDemandGroups', demandID], () => fetchGroups(demandID), {
        staleTime: 600000,
    });
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    return (
        <Card>
            <MDBox pt={2} px={2}>
                <Header demand={data} isHistory={isHistory} projectID={projectID} setValue={setValue} />
            </MDBox>
            <Divider />
            <MDBox pt={1} pb={3} px={2}>
                <MDBox mb={3}>
                    <DemandsTable demand={data} />
                </MDBox>
                <Divider />
                <MDBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={8}>
                            <MDBox>
                                <OfferNotes demand={data} />
                            </MDBox>
                            <MDBox>{demandGroups?.length > 0 && <OfferGroups demandGroups={demandGroups} />}</MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} sx={{ ml: 'auto' }}>
                            <OfferSummary demand={data} />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default Offer;
