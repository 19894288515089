import React from 'react';

import { useQuery } from 'react-query';
import { getDemandTemplates } from 'services/api/project';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React example components

import TemplateCard from 'layouts/projects/Forms/Templates/TemplateCard';
import TemplateForm from 'layouts/projects/Forms/Templates/TemplateForm';

import Dialog from '@mui/material/Dialog';
import AaroLoadingScreen from 'components/Loading';

import TemplateCrud from 'layouts/projects/Forms/Templates/TemplateCRUD/index.js';

const fetchDemandTemplates = async () => {
    const response = await getDemandTemplates()
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

function isEmptyJson(json) {
    return Object.keys(json).length === 0 && json.constructor === Object;
}

export default function Templates({ open, handleClose, demandID }) {
    const [selected, setSelected] = React.useState({});
    const [edit, setEdit] = React.useState(false);
    const { data, isLoading, error } = useQuery(['fetchDemandTemplates'], () => fetchDemandTemplates(), {
        staleTime: 600000,
    });
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const user = JSON.parse(localStorage.getItem('user'));
    const groupByCategory = data.reduce((acc, cur) => {
        if (!acc[cur.category.name]) {
            acc[cur.category.name] = [];
        }
        acc[cur.category.name].push(cur);
        return acc;
    }, {});
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <MDBox>
                {isEmptyJson(selected) ? (
                    Object.keys(groupByCategory).map((category) => (
                        <MDBox>
                            <MDBox pt={2} px={2} lineHeight={1.25}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    {category}
                                </MDTypography>
                                <MDBox mb={1}>
                                    <MDTypography variant="button" color="text">
                                        {groupByCategory[category][0].categoryDescription}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDBox p={2}>
                                <Grid container spacing={6}>
                                    {groupByCategory[category].map((template) => (
                                        <Grid item xs={12} md={6} xl={3}>
                                            <TemplateCard
                                                setSelected={setSelected}
                                                door={template}
                                                image={template.doorImage}
                                                label="project #1"
                                                title={template.doorName}
                                                doorDescription={template.doorDescription}
                                                tags={template.tags}
                                                action={{
                                                    type: 'internal',
                                                    route: '/pages/profile/profile-overview',
                                                    color: 'info',
                                                    label: 'view project',
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </MDBox>
                        </MDBox>
                    ))
                ) : (
                    <TemplateForm door={selected} demandID={demandID} handleClose={handleClose} />
                )}
                {isEmptyJson(selected) ? (
                    user.role === 'superadmin' && edit ? (
                        <TemplateCrud demandTemplates={data} />
                    ) : (
                        <MDButton onClick={() => setEdit(!edit)}> Düzenle</MDButton>
                    )
                ) : (
                    ''
                )}
            </MDBox>
        </Dialog>
    );
}
