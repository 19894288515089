import { calculateTimePassed } from 'helpers/dateHelpers';

export const colorAndLabelDecider = (date) => {
    const days = calculateTimePassed(date);
    let color = '';
    let label = '';
    if (days < 7) {
        color = 'success';
        label = 'YENİ';
    } else if (days > 7 && days < 14) {
        color = 'info';
        label = '7 GÜN +';
    } else if (days > 14 && days < 28) {
        color = 'warning';
        label = '14 GÜN +';
    } else {
        color = 'primary';
        label = '30 GÜN +';
    }
    return { color, label };
};
