// @mui material components
import Grid from '@mui/material/Grid';

import DefaultStatisticsCard from 'examples/Cards/StatisticsCards/DefaultStatisticsCard';
import { formatPrice } from 'helpers/priceHelpers';

function ActiveProjectsSummary({ activeProjects }) {
    // Her bir projenin toplam değerini diziye ekleyelim
    const projectValues = activeProjects.map((project) => {
        // Eğer demand null veya undefined ise, hemen 0 döndürelim
        if (!project.demand) {
            return 0;
        }

        const { demand } = project;

        // Her bir talep kategorisinin toplam değerini hesaplayalım
        let categoryTotals = Object.keys(demand).map((categoryKey) => {
            const items = demand[categoryKey];
            let categoryTotal = 0;

            // Yalnızca değer bir dizi ise reduce() fonksiyonunu çağırırız
            if (Array.isArray(items)) {
                // Her bir ürünün değerini hesaplayalım ve kategoriye ekleyelim
                categoryTotal = items.reduce((total, item) => {
                    if (!item.verilenFiyat || isNaN(item.verilenFiyat)) {
                        console.error('Invalid or missing price:', item.verilenFiyat);
                        return total; // Do not add anything to the total for this item
                    }

                    let discountRate = item.indirimOrani || 0; // Default to 0 if undefined
                    if (isNaN(discountRate)) {
                        console.error('Invalid discount rate:', item.indirimOrani);
                        return total; // Do not add anything to the total for this item
                    }

                    let quantity = item.miktar || 1; // Default to 1 if undefined
                    if (isNaN(quantity)) {
                        console.error('Invalid quantity:', item.miktar);
                        return total; // Do not add anything to the total for this item
                    }

                    let priceAfterDiscount = item.verilenFiyat * (1 - discountRate / 100); // Apply discount rate
                    let itemTotalValue = priceAfterDiscount * quantity;

                    return total + itemTotalValue;
                }, 0);
            }

            return categoryTotal;
        });
        // Tüm kategorilerin toplamını ekleyerek projenin toplam değerini bulalım
        let projectTotal = categoryTotals.reduce((total, categoryTotal) => total + categoryTotal, 0);

        return projectTotal;
    });

    // Tüm projelerin değerini ekleyerek toplam değeri bulalım
    let totalValue = projectValues.reduce((total, projectValue) => total + projectValue, 0);

    let noStatusCount = activeProjects.reduce((count, project) => {
        // Eğer proje durumu girilmemişse (null veya undefined), sayacı bir artır
        if (!project.status) {
            return count + 1;
        }

        return count;
    }, 0);

    function getProjectHealth(project) {
        let { projectPotential, lose, win, winner, status, source } = project;

        let fieldCount = 5; // Toplam kontrol edilecek alan sayısı

        let points = 0; // Başlangıçta puanımız 0

        // projectPotential, status ve source için doluluk kontrolü yapıyoruz
        // projectPotential bir sayı olmalı
        // status bir obje olmalı ve en az bir özelliği olmalı
        // source bir array olmalı ve en az bir elemanı olmalı
        if (typeof projectPotential === 'number' && projectPotential > 0) points += 1;
        if (status && typeof status === 'object' && Object.keys(status).length > 0) points += 1;
        if (Array.isArray(source) && source.length > 0) points += 1;

        // win veya (lose ve winner) kontrolü için ekstra bir puan ekleyeceğiz
        // Eğer win, lose veya winner bir dizi ise ve boşsa, dolu olarak saymayacağız
        if (Array.isArray(win) && win.length > 0) {
            points += 1;
        } else if (Array.isArray(lose) && lose.length > 0 && Array.isArray(winner) && winner.length > 0) {
            points += 1;
        }

        // Toplam sağlık puanını alan sayısına böleriz
        let healthScore = points / fieldCount;

        return healthScore;
    }

    let totalHealth = activeProjects.reduce((total, project) => total + getProjectHealth(project), 0);

    let overallHealth = (totalHealth / activeProjects.length) * 100; // yüzde olarak

    function getHealthLabel(health) {
        if (health < 40) {
            return {
                color: 'error',
                value: 'Dikkat!',
                label: 'özensiz veri girişi',
            };
        } else if (health < 80) {
            return {
                color: 'warning',
                value: 'Ortalama',
                label: 'veri girişi',
            };
        } else {
            return {
                color: 'success',
                value: 'Teşekkürler',
                label: ', veri girişi sağlıklı',
            };
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                    title="Proje Kalemlerinin Toplamı"
                    count={formatPrice(totalValue)}
                    percentage={{
                        color: 'success',
                        value: 'Aktif',
                        label: 'projelerinizin değeri',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                    title="Bilinmeyen Proje"
                    count={`${noStatusCount} adet`}
                    percentage={{
                        color: 'primary',
                        value: 'Durumsuz',
                        label: 'bıraklan proje sayınız',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                    title="Veri Sağlığı"
                    count={`${overallHealth.toFixed(2)}%`}
                    percentage={getHealthLabel(overallHealth)}
                    dropdown={{
                        action: '',
                        menu: '',
                        value: 'Durum, Potansiyel, Kazanan, Nasıl Geldi',
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default ActiveProjectsSummary;
