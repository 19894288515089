import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getCustomers = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers`;
    return axios.get(url, { headers });
};

/**
 * @desc  it updates given customer
 * @return promise - axios returns a promise
 */
export const updateCustomer = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/${id}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it retrieves customer calls
 * @return promise - axios returns a promise
 */
export const getCustomerCalls = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/${id}?calls=true`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer call
 * @return promise - axios returns a promise
 */
export const postCustomerCall = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/call/${id}`;
    return axios.post(url, data, { headers });
};

/**
 * @desc Retrieves all the calls
 * @return promise - axios returns a promise
 */
export const getCalls = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/call`;
    return axios.get(url, { headers });
};

/**
 * @desc Retrieves all the calls
 * @return promise - axios returns a promise
 */
export const getCallsByQuery = (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/call/query?inquiry=${query}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given group id, it retrieves customer tags
 * @return promise - axios returns a promise
 */
export const getTags = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/tag/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given user id, it retrieves the related customers
 * @return promise - axios returns a promise
 */
export const getCustomersByResponsible = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/responsible/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given user id, it retrieves the related customers
 * @id user id
 * @return promise - axios returns a promise
 */
export const getUserSummaryByDay = (id, day) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/customers/call/summary/${id}`;
    return axios.post(url, { day }, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getChat = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/chat/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postChat = (data, source) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
        'X-Source': source || 'web',
    };
    const url = `${config.url.API_URL}/api/general/chat`;
    return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it deletes chat
 * @return promise - axios returns a promise
 */
export const deleteChat = (id, source) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
        'X-Source': source || 'web',
    };
    const url = `${config.url.API_URL}/api/general/chat/${id}`;
    return axios.delete(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postTyping = (relatedID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/typing/typingEvent`;
    return axios.post(url, { relatedID }, { headers });
};
