import { useState } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import { useQuery } from 'react-query';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDPagination from 'components/MDPagination';
import MDInput from 'components/MDInput';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React example components
import TimelineItem from 'components/General/Activity/Item.js';

import { getActivities } from 'services/api/activity';
import AaroLoadingScreen from 'components/Loading';
import { channel } from 'pusher.js';
import MuiLink from '@mui/material/Link';
import { formatDateMinutes } from 'helpers/smallCodes';
import Link from '@mui/material/Link';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const fetchActivities = async (page, limit, relatedID) => {
    if (!relatedID) {
        const response = await getActivities(`page=${page}&limit=${limit}`).then((rp) => rp.data);
        return response;
    }
    const response = await getActivities(`page=${page}&limit=${limit}&relatedID=${relatedID}`).then((rp) => rp.data);
    return response;
};

function Activities({ relatedID = null, startingEntryNumber = 10 }) {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(startingEntryNumber);

    const { data, refetch, isLoading, error } = useQuery(
        ['fetchActivities', page, perPage, relatedID],
        () => fetchActivities(page, perPage, relatedID),
        {
            staleTime: 600000,
        }
    );

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    channel.bind('my-event', (data) => {
        refetch();
    });

    const { result: activities, totalPages } = data;

    // create an array with the length of the total pages divided by perpage ceiling to the top
    const pages = Array.from(Array(Math.ceil(totalPages / perPage)), (_, i) => i + 1);

    // console.log(totalPages);
    // console.log(perPage);
    // console.log(pages);
    const renderPagination = pages.map((option) => (
        <MDPagination item key={option} onClick={() => setPage(Number(option))} active={page === option}>
            {option}
        </MDPagination>
    ));

    return (
        <Card sx={{ height: '100%' }}>
            <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                    Son Aktiviteler
                </MDTypography>
            </MDBox>

            <MDBox p={2}>
                {activities?.map((el) => (
                    <TimelineItem
                        key={el?._id}
                        data={el}
                        color={el.type === 'add' ? 'success' : el.type === 'update' ? 'warning' : 'error'}
                        icon={el.type === 'add' ? 'add' : el.type === 'update' ? 'edit' : 'delete'}
                        title={
                            <>
                                {el.description}
                                {`${window.location.origin}/${el.table}/${el.relatedID}` !== window.location.href && (
                                    <Link
                                        href={`${window.location.origin}/${el.table}/${el.relatedID}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <ArrowForwardIosIcon />
                                    </Link>
                                )}
                            </>
                        }
                        dateTime={
                            <MuiLink href={el?.url} target="_blank" rel="noreferrer">
                                {formatDateMinutes(el.createdAt)}
                            </MuiLink>
                        }
                    />
                ))}
            </MDBox>
            <MDBox
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                p={3}
            >
                <MDBox mb={{ xs: 3, sm: 0 }}>
                    <MDTypography variant="button" color="secondary" fontWeight="regular">
                        Toplam {totalPages} sonuç bulundu
                    </MDTypography>
                </MDBox>

                <MDPagination variant={'gradient'} color={'dark'}>
                    {page > 1 && (
                        <MDPagination item onClick={() => setPage(page - 1)}>
                            <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
                        </MDPagination>
                    )}
                    {renderPagination.length > 10 ? (
                        <MDBox width="5rem" mx={1}>
                            <MDInput
                                inputProps={{
                                    type: 'number',
                                    min: 1,
                                    max: Math.ceil(totalPages / perPage),
                                }}
                                value={page}
                                onChange={(e) => setPage(Number(e.target.value))}
                            />
                        </MDBox>
                    ) : (
                        renderPagination
                    )}
                    {page < Math.ceil(totalPages / perPage) && (
                        <MDPagination item onClick={() => setPage(page + 1)}>
                            <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
                        </MDPagination>
                    )}
                </MDPagination>
            </MDBox>
        </Card>
    );
}

export default Activities;
