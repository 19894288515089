// separation for dateTime
export const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

export const formatDateHours = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

export const formatDateMinutes = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

export const calculateTimePassed = (date) => {
    const currentDate = new Date();
    let timePassed = currentDate - new Date(date);
    const twentyFourHoursToMS = 86400000;
    timePassed = Math.ceil(timePassed / twentyFourHoursToMS);
    return timePassed;
};

export const todayIsBetween = (startingDate, finishingDate) => {
    const today = new Date();
    if (
        startingDate.getTime() <= today.getTime() &&
        today.getTime() <= finishingDate.getTime()
    )
        return true;
    return false;
};

export const daysUntilBirthday = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    birthDate.setFullYear(today.getFullYear());

    if (today > birthDate) {
        birthDate.setFullYear(birthDate.getFullYear() + 1);
    }

    const oneDay = 1000 * 60 * 60 * 24;
    const diff = Math.ceil((birthDate.getTime() - today.getTime()) / oneDay);
    return diff;
};

export const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};
