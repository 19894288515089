// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { cityCodes } from 'helpers/countryHelpers';
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';

function ShippingInformation({ shipping }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <>
            <MDTypography
                variant="h6"
                fontWeight="medium"
                component="a"
                href="https://docs.google.com/spreadsheets/d/1qmWGlJsfh5X3DiiC594SfiOLJxM35yj-u2Q2DPjSiyo/edit?gid=0#gid=0"
                target="_blank"
                rel="noopener noreferrer"
            >
                Teslimat Bilgileri
            </MDTypography>
            <MDBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor={darkMode ? 'transparent' : 'grey-100'}
                borderRadius="lg"
                p={3}
                mt={2}
            >
                <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
                    <MDBox mb={2}>
                        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                            {`${shipping.first_name} ${shipping.last_name}`}
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            Firma:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                                {shipping?.company || '-'}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            Adres:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                                {`${shipping.address_1}${shipping.address_2 ? `, ${shipping.address_2}` : ''}`}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            İl/İlçe:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                                {`${shipping.city}, ${cityCodes[shipping.state]} ${shipping.postcode}`}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>

                    {shipping.phone && (
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            Telefon:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                                {shipping.phone}
                            </MDTypography>
                        </MDTypography>
                    )}
                </MDBox>
            </MDBox>
        </>
    );
}

export default ShippingInformation;
