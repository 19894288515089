import { memo } from 'react';
import OrderDetailCard from './OrderDetailCard';

import { formatDateMinutes } from 'helpers/dateHelpers';
import { KeyboardReturn } from '@mui/icons-material';

const SituationButtons = (OnayDurum) => {
	let result = 'Bilinmiyor';
	switch (OnayDurum) {
		case 1:
			result = 'Onaylandı';
			break;
		case 0:
			result = 'BEKLİYOR';
			break;
		case -1:
			result = 'RET!';
			break;

		default:
			result = 'Bilinmiyor';
	}
	return result;
};

function ItemDetails({ order }) {
	if (order == null) return <p>!</p>;
	const url = `https://erp2.aaro.com.tr/DekontFatura/Kalem?id=${order?.DekontID}`;

	return (
		<OrderDetailCard
			title={order.Aciklama}
			description={order.description}
			info={{
				belgeNo: order?.BelgeNo,
				// sorumlular: order.assignee.map((el) => el?.name).join(', '),
				durumu: SituationButtons(order?.OnayDurum),

				olusturulmaTarihi: formatDateMinutes(order?.OlsTar),
				degistirilmeTarihi: formatDateMinutes(order?.DgsTar),
				aaro: (
					<a href={url} target="_blank" rel="noreferrer">
						{order?.DekontID}
					</a>
				),
			}}
			social={
				[
					// 	{
					// 		link: 'https://www.facebook.com/CreativeTim/',
					// 		icon: <FacebookIcon />,
					// 		color: 'facebook',
					// 	},
					// 	{
					// 		link: 'https://twitter.com/creativetim',
					// 		icon: <TwitterIcon />,
					// 		color: 'twitter',
					// 	},
					// 	{
					// 		link: 'https://www.instagram.com/creativetimofficial/',
					// 		icon: <InstagramIcon />,
					// 		color: 'instagram',
					// 	},
				]
			}
			action={{ route: '', tooltip: '' }}
			shadow={true}
		/>
	);
}

export default ItemDetails;
