// MD2 PRO
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// @mui
import Grid from '@mui/material/Grid';

// Project Components
import OrderDetails from './order-details';
import Actions from 'layouts/purchases/components/Actions';
import DisplaySMS from 'components/General/SMS/DisplaySMS.js';
import DisplayCalls from 'components/General/Call/DisplayCalls.js';
import { isMobile } from 'helpers/smallCodes';
import { useState } from 'react';

function MainPage({ purchase, wooCommerceOrderDetails }) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <MDBox mt={4}>
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <MDBox
                            mb={3}
                            bgColor={wooCommerceOrderDetails?.customer_note ? 'info' : 'white'}
                            variant="gradient"
                            borderRadius="lg"
                            shadow="lg"
                            opacity={1}
                            p={2}
                        >
                            <MDTypography
                                variant="h6"
                                fontWeight="bold"
                                color={wooCommerceOrderDetails?.customer_note ? 'white' : 'dark'}
                            >
                                <strong>Müşteri Notu:</strong>{' '}
                                {wooCommerceOrderDetails?.customer_note || 'Müşteri notu yok'}
                            </MDTypography>
                        </MDBox>

                        <MDBox>
                            {wooCommerceOrderDetails && (
                                <>
                                    {isMobile() ? (
                                        <MDButton
                                            color="dark"
                                            variant="gradient"
                                            onClick={() => setShowDetails((prev) => !prev)}
                                            fullWidth
                                        >
                                            {showDetails ? 'Sipariş Detaylarını Gizle' : 'Sipariş Detaylarını Göster'}
                                        </MDButton>
                                    ) : (
                                        <OrderDetails wooCommerceOrderDetails={wooCommerceOrderDetails} />
                                    )}
                                    {showDetails && isMobile() && (
                                        <OrderDetails wooCommerceOrderDetails={wooCommerceOrderDetails} />
                                    )}
                                </>
                            )}
                        </MDBox>

                        {(showDetails || !isMobile()) && (
                            <>
                                <MDBox my={3}>
                                    <Actions purchase={purchase} wooCommerceOrderDetails={wooCommerceOrderDetails} />
                                </MDBox>

                                <MDBox>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <DisplaySMS relatedID={purchase?.wooCommerceID} sourcePage="purchase" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DisplayCalls relatedID={purchase?.wooCommerceID} sourcePage="purchase" />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </>
                        )}
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default MainPage;
