import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { postCall } from 'services/api/general/call';

const validationSchema = yup.object({
    relatedID: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    sentToPhone: yup
        .string('10 rakam olmalıdır')
        .min(10, '10 karakterden fazla olmalıdır')
        .max(10, '10 karakterden az olmalıdır')
        .required('10 rakam olmalıdır'),
    sentBy: yup
        .string('Bağlı olduğu liste IDsi gereklidir.')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Bağlı olduğu liste IDsi gereklidir'),
    talkedWith: yup
        .string('Bağlı olduğu liste IDsi gereklidir.')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Bağlı olduğu liste IDsi gereklidir'),
    callContent: yup
        .string('Bağlı olduğu liste IDsi gereklidir.')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(600, '600 karakterden az olmalıdır')
        .required('Bağlı olduğu liste IDsi gereklidir'),
});

export default function InputArea(props) {
    const { initial, customerName, handleClose, number } = props;

    const queryClient = useQueryClient();

    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    const formik = useFormik({
        initialValues: {
            relatedID: initial?.relatedID || '',
            sentBy: initial?.sentBy || userID,
            sentToPhone: initial?.sentToPhone || '',
            callContent: initial?.callContent || '',
            talkedWith: initial?.talkedWith || '',
            sourcePage: initial?.sourcePage || 'customer',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setDisableOnSubmit(true);

            postCall(values)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    handleClose();
                    queryClient.invalidateQueries(['fetchCalls']);
                })
                .catch((err) => notify(false, JSON.stringify(err)));
        },
    });
    useEffect(() => {
        if (customerName) formik.setFieldValue('talkedWith', customerName);
    }, [customerName]);

    useEffect(() => {
        if (number) formik.setFieldValue('sentToPhone', number);
    }, [number]);

    return (
        <Card id="basic-info" sx={{ overflow: 'visible' }}>
            <form onSubmit={formik.handleSubmit}>
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Konuşulan Kişi"
                                placeholder="Konuşulan Kişi"
                                id="talkedWith"
                                name="talkedWith"
                                value={formik.values.talkedWith}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.talkedWith &&
                                    Boolean(formik.errors.talkedWith)
                                }
                                helperText={
                                    formik.touched.talkedWith &&
                                    formik.errors.talkedWith
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Alıcı Telefon"
                                placeholder="Alıcının Telefonu"
                                id="sentToPhone"
                                name="sentToPhone"
                                value={formik.values.sentToPhone}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.sentToPhone &&
                                    Boolean(formik.errors.sentToPhone)
                                }
                                helperText={
                                    formik.touched.sentToPhone &&
                                    formik.errors.sentToPhone
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormField
                                label="Konuşma Özeti"
                                placeholder="Konuşma Özeti"
                                id="callContent"
                                name="callContent"
                                multiline
                                row={5}
                                value={formik.values.callContent}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.callContent &&
                                    Boolean(formik.errors.callContent)
                                }
                                helperText={
                                    formik.touched.callContent &&
                                    formik.errors.callContent
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={12} sm={12}>
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="dark"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    Ekle
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
            </form>
        </Card>
    );
}
