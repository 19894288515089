import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MDBox from 'components/MDBox';
import Card from 'layouts/purchases/pages/allLeads/DraggableList/Card/index';
import { notify } from 'helpers/notificationHelper';
import MDTypography from 'components/MDTypography';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { updateWooOrderStatus } from 'services/outside/woo/orders.js';

const onDragEnd = (result, columns, setColumns, wooConfig) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = source.droppableId === destination.droppableId ? sourceItems : [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);

    if (source.droppableId !== destination.droppableId) {
        const changedItem = destItems[destination.index];

        if (changedItem?.content?.id) {
            // WooCommerce order ID'si content içinde
            updateWooOrderStatus(
                wooConfig.consumerKey,
                wooConfig.consumerSecret,
                wooConfig.websiteUrl,
                changedItem.content.id,
                destination.droppableId
            )
                .then((response) => {
                    notify(true, 'WooCommerce durumu güncellendi');
                })
                .catch((error) => {
                    notify(false, 'WooCommerce durumu güncellenirken hata oluştu');
                    console.error('WooCommerce güncelleme hatası:', error);
                });
        }
    }

    setColumns({
        ...columns,
        [source.droppableId]: { ...sourceColumn, items: sourceItems },
        [destination.droppableId]: { ...destColumn, items: destItems },
    });
};

export default function Kanban({ data, wooConfig }) {
    const [columns, setColumns] = useState(data);
    const { height, width } = useWindowDimensions();
    const columnNumber = Object.entries(columns).length;

    return (
        <MDBox display="flex" justify="center" p={0} m={0} sx={{ width: '100%', overflowX: 'auto' }}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns, wooConfig)}>
                {Object.entries(columns).map(([id, column], index) => (
                    <Column
                        key={id}
                        id={id}
                        column={column}
                        height={height}
                        width={width}
                        columnNumber={columnNumber}
                    />
                ))}
            </DragDropContext>
        </MDBox>
    );
}

const Column = ({ id, column, height }) => (
    <MDBox
        color="dark"
        sx={{
            background: '#f5f5f6',
            borderRadius: 1,
            flexGrow: 1, // This will allow the column to grow and fill the space
            flexBasis: 0, // Resetting the default size to 0
            minWidth: 0, // This will allow the column to shrink below its content size if necessary
            height: 'fit-content', // Adjust height to fit content or a fixed height if needed
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Optional: adds shadow for depth
        }}
        variant="gradient"
        shadow="lg"
        opacity={1}
        p={1}
        m={1}
    >
        <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" textAlign="center">
            {column.name}
        </MDTypography>

        <Droppable droppableId={id}>
            {(provided) => (
                <MDBox {...provided.droppableProps} ref={provided.innerRef} sx={{ minHeight: height / 1.1 }}>
                    {column.items.map((item, index) => (
                        <Item key={item.id} item={item} index={index} column={column} provided={provided} />
                    ))}
                    {provided.placeholder}
                </MDBox>
            )}
        </Droppable>
    </MDBox>
);

const Item = ({ item, index, column, provided }) => (
    <Draggable draggableId={String(item.id)} index={index}>
        {(provided, snapshot) => (
            <MDBox
                mb={1}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{ padding: 1, borderRadius: 1, ...provided.draggableProps.style }}
                bgColor={snapshot.isDragging ? 'light' : 'white'}
            >
                <Card data={item} column={column} />
            </MDBox>
        )}
    </Draggable>
);
