// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import DisplayNote from 'components/General/Note/DisplayNote';
// Images
import orderImage from 'assets/images/product-12.jpg';

// React hooks

function OrderInfo({ line_items }) {
    // Fiyat formatlama fonksiyonu
    const formatPrice = (price) => {
        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
        }).format(price);
    };

    // Gösterilmeyecek meta data keylerinin listesi
    const excludedMetaKeys = [
        '_pao_total',
        '_reduced_stock',
        '_price_including_tax',
        '_price_excluding_tax',
        // Başka gizlenmesi gereken meta datalar buraya eklenebilir
    ];

    // Meta data'yı filtreleme ve düzenleme
    const getDisplayableMetaData = (metaData) => {
        return metaData.filter(
            (meta) =>
                !excludedMetaKeys.includes(meta.key) &&
                meta.display_key &&
                meta.display_value &&
                typeof meta.display_value === 'string'
        );
    };

    return (
        <>
            <Grid container spacing={3}>
                {line_items &&
                    line_items.map((item) => (
                        <Grid item xs={12} key={item.id}>
                            <MDBox
                                sx={{
                                    border: '1px dotted rgba(0,0,0,0.2)',
                                    borderRadius: '8px',
                                    padding: '16px',
                                }}
                            >
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={9}>
                                        <MDBox display="flex" alignItems="center">
                                            <MDBox mr={2} sx={{ cursor: 'pointer' }}>
                                                <MDAvatar
                                                    size="xxl"
                                                    src={item.image?.src || orderImage}
                                                    alt={item.name}
                                                />
                                            </MDBox>
                                            <MDBox lineHeight={1}>
                                                <MDTypography variant="h6" fontWeight="medium">
                                                    {item.name}
                                                </MDTypography>

                                                {/* Meta data gösterimi */}
                                                {item.meta_data && item.meta_data.length > 0 && (
                                                    <MDBox mb={1}>
                                                        {getDisplayableMetaData(item.meta_data).map((meta, index) => (
                                                            <MDTypography
                                                                key={index}
                                                                variant="caption"
                                                                color="primary"
                                                                display="block"
                                                                fontWeight="bold"
                                                            >
                                                                {meta.display_key}: {meta.display_value}
                                                            </MDTypography>
                                                        ))}
                                                    </MDBox>
                                                )}

                                                {/* SKU Bilgisi */}
                                                {item.sku && (
                                                    <MDBadge
                                                        variant="gradient"
                                                        color="info"
                                                        size="xs"
                                                        badgeContent={`SKU: ${item.sku}`}
                                                        container
                                                    />
                                                )}
                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
                                        <MDBox display="flex" justifyContent="flex-end" alignItems="baseline">
                                            <MDTypography
                                                variant="overline"
                                                color={item.quantity > 1 ? 'primary' : 'text'}
                                                mr={0.5}
                                            >
                                                {item.quantity} adet x
                                            </MDTypography>
                                            <MDTypography variant="h6" color="dark">
                                                {formatPrice(item.price)}
                                            </MDTypography>
                                        </MDBox>
                                        {item.quantity > 1 && (
                                            <MDTypography variant="caption" color="primary">
                                                Miktara dikkat
                                            </MDTypography>
                                        )}

                                        {item.subtotal_tax > 0 && (
                                            <MDTypography variant="caption" color="text">
                                                Vergi: {formatPrice(item.subtotal_tax)}
                                            </MDTypography>
                                        )}
                                    </Grid>
                                </Grid>
                                <DisplayNote relatedID={item.id} />
                            </MDBox>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default OrderInfo;
