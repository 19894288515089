

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MuiLink from '@mui/material/Link';

function ProductCell({ image, name, createdBy, folderID, spreadsheetId }) {
	const folderLink = `https://drive.google.com/drive/folders/${folderID}`;
	return (
		<MuiLink href={folderLink} target="_blank" rel="noreferrer">
			<MDBox display="flex" alignItems="center" pr={2}>
				<MDBox mr={2}>
					<MDAvatar
						src={image}
						alt={name}
						variant="rounded"
						size="sm"
					/>
				</MDBox>
				<MDBox display="flex" flexDirection="column">
					<MDTypography variant="button" fontWeight="medium">
						{name}
					</MDTypography>
					<MDTypography
						variant="button"
						fontWeight="regular"
						color="secondary"
					>
						<MDTypography
							component="span"
							variant="button"
							fontWeight="regular"
							color="success"
						>
							{createdBy}
						</MDTypography>{' '}
					</MDTypography>
				</MDBox>
			</MDBox>
		</MuiLink>
	);
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default ProductCell;
