

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme-dark/base/colors";

const { transparent } = colors;

export default {
  styleOverrides: {
    root: {
      "&:hover": {
        backgroundColor: transparent.main,
      },
    },
  },
};
