import React, { useState, useMemo } from 'react';
import { config } from 'Constants';
import { Formik, Form, useFormikContext } from 'formik';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { postSheetTemplate, updateSheetTemplate, deleteSheetTemplate } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getTags } from 'services/api/general/tag';
import { FormField, SelectMultiple } from './FormComponents';
import * as Yup from 'yup';

const { DEMAND_TEMPLATE_CATEGORY_ID, DEMAND_TEMPLATE_PRODUCT_ID } = config.fixedID;

const groupOptions = ['kanat', 'kasa', 'uKasa', 'pervaz', 'uPervaz'];

const validationSchema = Yup.object().shape({
    category: Yup.object().required('Kategori seçimi zorunludur'),
    name: Yup.string().required('Kapı ismi zorunludur'),
    type: Yup.string().oneOf(groupOptions, 'Geçerli bir grup seçiniz').required('Grup seçimi zorunludur'),
    description: Yup.string(),
    coverImage: Yup.string(),
    url: Yup.string().url('Geçerli bir URL giriniz'),
    tags: Yup.array().of(Yup.object()).min(1, 'En az bir etiket seçmelisiniz'),
});

const fetchTags = async (id) => {
    const response = await getTags(id);
    return response.data?.result?.tags || [];
};

const FormikValues = () => {
    const { values } = useFormikContext();
    return (
        <pre style={{ marginTop: '20px', padding: '10px', background: '#f0f0f0', borderRadius: '5px' }}>
            {JSON.stringify(values, null, 2)}
        </pre>
    );
};

const DataTable = ({ demandTemplates }) => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const queryClient = useQueryClient();

    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(['fetchTagGroups', DEMAND_TEMPLATE_CATEGORY_ID], () => fetchTags(DEMAND_TEMPLATE_CATEGORY_ID), {
        staleTime: 600000,
    });

    const {
        data: doorTags,
        isLoading: isLoadingDoorTags,
        error: errorDoorTags,
    } = useQuery(['fetchTagGroups', DEMAND_TEMPLATE_PRODUCT_ID], () => fetchTags(DEMAND_TEMPLATE_PRODUCT_ID), {
        staleTime: 600000,
    });

    const updateMutation = useMutation(
        (data) =>
            updateSheetTemplate(data._id, data.values)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message)),
        {
            onSuccess: () => queryClient.invalidateQueries(['fetchSheetTemplates']),
        }
    );

    const createMutation = useMutation((newTemplate) => postSheetTemplate(newTemplate), {
        onSuccess: (response) => {
            console.log('Create mutation response:', response);
            queryClient.invalidateQueries(['fetchSheetTemplates']);
            notify(true, 'Şablon başarıyla eklendi');
            setOpen(false);
        },
        onError: (error) => {
            console.error('Create mutation error:', error);
            notify(false, error.message || 'Şablon eklenirken bir hata oluştu');
        },
    });

    const deleteMutation = useMutation(
        (id) => {
            if (window.confirm('Silmek İstediğinize Emin Misiniz?')) {
                return deleteSheetTemplate(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);
                        return rp;
                    })
                    .catch((e) => notify(false, e.message));
            }
        },
        {
            onSuccess: () => queryClient.invalidateQueries(['fetchSheetTemplates']),
        }
    );

    const formikInitialValues = {
        category: null, // Tek seçim için null
        name: '',
        type: '',
        description: '',
        coverImage: '',
        url: '',
        tags: [], // Çoklu seçim için boş dizi
    };

    const formikEditInitialValues = selectedRow
        ? {
              ...selectedRow,
              category: selectedRow.category || null,
              tags: Array.isArray(selectedRow.tags) ? selectedRow.tags : [],
          }
        : formikInitialValues;

    const handleFormSubmit = (values, actions) => {
        const formattedValues = {
            ...values,
            category: values.category._id,
            tags: values.tags.map((tag) => tag._id),
        };
        createMutation.mutate(formattedValues, {
            onSuccess: () => {
                actions.resetForm();
                setOpen(false);
            },
            onError: (error) => {
                notify(false, error.message || 'Bir hata oluştu');
            },
        });
    };
    const handleEditFormSubmit = (values) => {
        const formattedValues = {
            ...values,
            category: values.category._id,
            tags: values.tags.map((tag) => tag._id),
        };
        updateMutation.mutate({ _id: selectedRow._id, values: formattedValues });
        setEditOpen(false);
    };

    const handleEditClick = (rowId) => {
        const row = demandTemplates.find((template) => template._id === rowId);
        setSelectedRow(row);
        setEditOpen(true);
    };

    const getCategoryNameById = (categoryId) => {
        if (!categoryId) return '';
        const tag = tags?.find((tag) => tag._id === categoryId._id);
        return tag ? tag.name : '';
    };

    const rows = demandTemplates.map((template) => ({
        id: template._id,
        category: template.category,
        name: template.name,
        type: template.type,
        description: template.description,
        coverImage: template.coverImage,
        url: template.url,
        tags: template.tags,
    }));

    const renderForm = (initialValues, onSubmit, dialogTitle, openState, setOpenState) => (
        <Dialog open={openState} onClose={() => setOpenState(false)}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>Lütfen şablon bilgilerini girin.</DialogContentText>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    <Form>
                        <FormField name="name" type="text" label="Kapı İsmi" />
                        <FormField as="select" name="type" label="Grubu" options={groupOptions} />
                        <FormField name="description" type="text" label="Kapı Açıklaması" />
                        <FormField name="coverImage" type="text" label="Kapı Resmi" />
                        <FormField name="url" type="text" label="Google Sheet URL" />
                        <FormField
                            as="coloredTagSelection"
                            name="category"
                            label="Kategori Seçin"
                            tagGroupID={DEMAND_TEMPLATE_CATEGORY_ID}
                            multiple={false} // Tek bir kategori seçimi için
                        />
                        <SelectMultiple name="tags" label="Etiket Seçin" tagGroupID={DEMAND_TEMPLATE_PRODUCT_ID} />

                        <DialogActions>
                            <Button onClick={() => (open ? setOpen(false) : setEditOpen(false))}>İptal</Button>
                            <Button type="submit">{open ? 'Ekle' : 'Güncelle'}</Button>
                        </DialogActions>
                        <FormikValues />
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );

    const columns = [
        {
            field: 'category',
            flex: 1,
            renderCell: (params) => <div>{getCategoryNameById(params.value)}</div>,
            editable: false,
        },
        { field: 'name', editable: false, flex: 1 },
        { field: 'type', editable: false, flex: 1 },
        { field: 'description', editable: false, flex: 1 },
        { field: 'coverImage', editable: false, flex: 1 },
        { field: 'url', editable: false, flex: 1 },
        {
            flex: 0.1,
            field: 'duzenle',
            headerName: 'Düzenle',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <IconButton aria-label="edit" onClick={() => handleEditClick(params.id)}>
                    <EditIcon />
                </IconButton>
            ),
        },
        {
            flex: 0.1,
            field: 'delete',
            headerName: 'Sil',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <IconButton aria-label="delete" onClick={() => deleteMutation.mutate(params.id)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <MDBox color="white" bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <MDButton variant="gradient" color="success" size="small" onClick={() => setOpen(true)}>
                Yeni Sheet Şablonu Ekle
            </MDButton>

            {open && renderForm(formikInitialValues, handleFormSubmit, 'Yeni Sheet Şablonu Ekle', open, setOpen)}
            {editOpen &&
                selectedRow &&
                renderForm(
                    formikEditInitialValues,
                    handleEditFormSubmit,
                    'Sheet Şablonunu Düzenle',
                    editOpen,
                    setEditOpen
                )}

            <DataGridPro rows={rows} columns={columns} autoHeight autoPageSize />
        </MDBox>
    );
};

export default DataTable;
