import React from 'react';
import Rating from '@mui/material/Rating';
import { getParameters } from 'services/api/general/parameter';
import { getFormSubmissionsFilter } from 'services/outside/jotform/form.js';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';
import { DataGridPro } from '@mui/x-data-grid-pro';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import pxToRem from 'assets/theme/functions/pxToRem.js';

import { config } from 'Constants';

const fetchParameters = async (query) => {
	const response = await getParameters(query).then((rp) =>
		rp.data?.result ? rp.data?.result : []
	);
	return response;
};

const fetchJotformSubmissions = async (apiKey, formID, customerID) => {
	const filter = `{"q6:matches:customerID":"${customerID}"}`;
	const response = await getFormSubmissionsFilter(
		apiKey,
		formID,
		filter
	).then((rp) => (rp.data?.content ? rp.data?.content : []));
	return response;
};

export default function CustomerFeedback({ customerID }) {
	const paramQuery = 'key=api_jotform';
	const musteriGeribildirimID = config.jotform.musteriGeribildirim;

	const { data, isLoading, error, refetch } = useQuery(
		['fetchParamaters', paramQuery],
		() => fetchParameters(paramQuery),
		{ staleTime: 600000 }
	);

	const { isIdle, data: submissions } = useQuery(
		['jotformSubmissions', musteriGeribildirimID, customerID],
		() =>
			fetchJotformSubmissions(
				data[0]?.value,
				musteriGeribildirimID,
				customerID
			),
		{
			enabled: data != null,
		}
	);

	if (isLoading) return <AaroLoadingScreen />;
	if (isIdle) return <AaroLoadingScreen />;
	if (error) return 'Bir Hata Oluştu: ' + error.message;

	const findResponse = (answers, key) => {
		let result = null;
		var answerArr = Object.entries(answers).map((el) => el[1]);
		answerArr.forEach((el) => {
			if (el.name === key) {
				result = el.prettyFormat ? el.prettyFormat : el.answer;
				if (el.type === 'control_fileupload') {
					result = el.answer;
				}
			}
		});
		return result;
	};

	const columns = [
		{
			field: 'gorusmeniziPuanlayin',
			headerName: 'Görüşme Puanı',
			flex: 1,
			renderCell: (params) => {
				return (
					<Rating
						name="read-only"
						value={params.value}
						readOnly
						size="small"
						max={10}
					/>
				);
			},
		},
		{
			field: 'yorumunuzopsiyonel',
			headerName: 'Yorumu',
			flex: 1,
			editable: false,
		},

		{
			field: 'viewColumn',
			headerName: 'Görüntüle',
			flex: 0.5,
			renderCell: (params) => (
				<MDButton
					variant="gradient"
					size="small"
					color="success"
					onClick={() => {
						const url = `https://www.jotform.com/submission/${params.formattedValue}`;
						window.open(url, '_blank').focus();
					}}
				>
					Görüntüle
				</MDButton>
			),
		},
	];

	const rows = submissions?.map((el) => ({
		id: Math.random(),
		gorusmeniziPuanlayin: findResponse(el?.answers, 'gorusmeniziPuanlayin'),
		yorumunuzopsiyonel: findResponse(el?.answers, 'yorumunuzopsiyonel'),
		viewColumn: el?.id,
	}));

	return submissions?.length > 0 ? (
		<MDBox
			color="white"
			bgColor="white"
			variant="gradient"
			borderRadius="lg"
			shadow="lg"
			opacity={1}
			p={2}
		>
			<MDBox height="120px">
				<DataGridPro
					autoPageSize
					// localeText={trTR.props.MuiDataGrid.localeText}

					density="compact"
					// checkboxSelection
					disableSelectionOnClick
					hideFooter={true}
					rows={rows}
					columns={columns}
					sx={{
						fontSize: pxToRem(12),
						fontFamily:
							'"Roboto", "Helvetica", "Arial", sans-serif',
						fontWeight: 400,
						lineHeight: 1.25,
					}}
				/>
			</MDBox>
		</MDBox>
	) : null;
}
