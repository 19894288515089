import axios from '../../general';
import { Aaro } from 'aaro';
import { getAllData } from 'aaro-scripts';
import { config } from 'Constants';

/**
 * Returns all the products that were sold.
 */
export const getPriceListItems = async (FiyatListesiID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const priceListItems = await getAllData(aaro, 'FiyatListesiSatirlar', {
		FiyatListesiID,
	});
	return priceListItems;
};

/**
 * For given id, it return customer basic info.(without email)
 * Returning array size should be 1
 */
export const getStockPrice = async (StokID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: `Bearer ${user.aaroToken}`,
	};
	const stocks = await axios.get(
		`${config.url.AARO_BASE}/api/FiyatListesiSatirlar?StokID=${StokID}&SiralamaKisiti=TarihBas:DESC`,
		{
			headers,
		}
	);

	return stocks;
};

export const getStocks = async (EsnekAramaKisiti) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: `Bearer ${user.aaroToken}`,
	};
	const stocks = await axios.get(
		`${config.url.AARO_BASE}/api/Stok?EsnekAramaKisiti=${EsnekAramaKisiti}`,
		{
			headers,
		}
	);

	return stocks;
};
