import { useEffect, memo } from 'react';
import { Card, Grid, Stack, Box, Typography, Icon } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import ViewToggle from 'layouts/lead/pages/allLeads/layout/Header/Components/ViewToggle.js';
import Search from 'layouts/lead/pages/allLeads/layout/Header/Components/Search.js';
import ViewSwitch from 'layouts/lead/pages/allLeads/layout/Header/Components/ViewSwitch.js';
import { ColorButton } from 'layouts/lead/pages/allLeads/layout/Header/Components/ColorButton.js';
import useDialogHook from 'hooks/useDialogHook';
import AddLead from 'layouts/lead/forms/AddLead.js';
import AddAaroPotential from 'components/Aaro/customer/Forms/AddCustomer.js';

const Header = memo(function HeaderPre({ children }) {
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const {
        open: openAaroPotentialAdd,
        handleClickOpen: handleClickOpenAaroPotentialAdd,
        handleClose: handleCloseAaroPotentialAdd,
    } = useDialogHook();

    useEffect(() => console.log('Header Rendered'));

    return (
        <MDBox position="relative" mb={5}>
            <HeaderGradiant />
            <Card
                sx={{
                    position: 'relative',
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} lg={9} style={{ flexGrow: 1 }}>
                        <Box height="100%" mt={0.5} lineHeight={1}>
                            <Typography variant="h5" fontWeight="medium" mb={1}>
                                Tarafımıza Ulaşan Fırsatlar
                            </Typography>
                            <Stack spacing={1} direction="row">
                                <ViewToggle />
                                <ColorButton
                                    size="small"
                                    precolor="#017737"
                                    hovercolor="#119143"
                                    onClick={handleClickOpen}
                                >
                                    +&nbsp;Ekle
                                </ColorButton>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} alignItems="center">
                        <MDBox display="flex" justifyContent="flex-end" width="100%" alignItems="center">
                            <Search />
                            <MDBox mt={1.5}>
                                <MDButton
                                    variant="gradient"
                                    color="dark"
                                    iconOnly
                                    onClick={handleClickOpenAaroPotentialAdd}
                                >
                                    <Icon>add</Icon>
                                </MDButton>
                            </MDBox>
                            <MDBox mt={1.5}>
                                <ViewSwitch />
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
                {children}
            </Card>
            {open && <AddLead open={open} handleClose={handleClose} />}
            {openAaroPotentialAdd && (
                <AddAaroPotential open={openAaroPotentialAdd} handleClose={handleCloseAaroPotentialAdd} />
            )}
        </MDBox>
    );

    function HeaderGradiant() {
        return (
            <MDBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="8rem"
                borderRadius="xl"
                sx={{
                    background: 'linear-gradient(to right, #42424a, #191919)',
                    backgroundSize: 'cover',
                    backgroundPosition: '50%',
                    overflow: 'hidden',
                }}
            />
        );
    }
});

export default Header;
