import React from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Card from '@mui/material/Card';

import ProjectActivation from 'layouts/projects/Components/ProjectActivation';
import ProjectDeletion from 'layouts/projects/Components/ProjectDeletion';
import ProjectSettings from 'layouts/projects/Components/ProjectSettings';
import DemandCurrency from 'layouts/projects/Components/DemandCurrency';
// import Currency from 'components/General/Currency';
export default function SettingsPage(props) {
	const { project } = props;
	return (
		<MDBox m={4}>
			<Card>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={6}>
						<MDBox m={4}>
							<MDTypography
								variant="h6"
								fontWeight="medium"
								mb={3}
							>
								Proje Ayarları
							</MDTypography>

							<ProjectSettings project={project} />
							<ProjectActivation project={project} />
							<ProjectDeletion project={project} />
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={6}>
						<MDBox m={4}>
							<MDTypography
								variant="h6"
								fontWeight="medium"
								mb={3}
							>
								Kalem Ayarları
							</MDTypography>

							<MDBox mt={3}>
								<DemandCurrency project={project} />
							</MDBox>
						</MDBox>
					</Grid>
				</Grid>
			</Card>
		</MDBox>
	);
}
