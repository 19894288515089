import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from 'examples/Footer';

function BaseLayout({ stickyNavbar, children }) {
    const [tabsOrientation, setTabsOrientation] = useState('horizontal');

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation('vertical')
                : setTabsOrientation('horizontal');
        }

        /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
        window.addEventListener('resize', handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () =>
            window.removeEventListener('resize', handleTabsOrientation);
    }, [tabsOrientation]);

    return (
        <DashboardLayout>
            <MDBox mt={2}>{children}</MDBox>
            <Footer />
        </DashboardLayout>
    );
}

// Setting default values for the props of BaseLayout
BaseLayout.defaultProps = {
    stickyNavbar: false,
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
    stickyNavbar: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default BaseLayout;
