import * as React from 'react';
import { useQueryClient } from 'react-query';

import Popover from '@mui/material/Popover';
import MDBox from 'components/MDBox';
import Icon from '@mui/material/Icon';
import MDButton from 'components/MDButton';
import { getStocks } from 'services/outside/aaro/stocks';
import TimelineList from 'examples/Timeline/TimelineList';
import TimelineItem from './TimelineItem';
const fetchAllStockAmountsForSingleStock = async (StokID) => {
    const response = await getStocks({
        StokID,
    });
    return response;
};

export default function MouseOverPopover({ stockID }) {
    const queryClient = useQueryClient();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const rerender = React.useState(0)[1];

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <MDBox
            onMouseEnter={async () => {
                await queryClient.prefetchQuery(
                    ['fetchStockAmountsForStock', stockID],
                    () => fetchAllStockAmountsForSingleStock(stockID),
                    {
                        staleTime: 60 * 1000, // only prefetch if older than 10 seconds
                    }
                );

                setTimeout(() => {
                    rerender({});
                }, 1);
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <MDButton
                size="medium"
                variant="gradient"
                color="warning"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                iconOnly
            >
                <Icon>home</Icon>
            </MDButton>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <StockView
                    data={queryClient.getQueryData([
                        'fetchStockAmountsForStock',
                        stockID,
                    ])}
                />
            </Popover>
        </MDBox>
    );
}

const StockView = (props) => {
    const { data } = props;
    const stockDetails = data || [];
    const stockName = stockDetails[0] ? stockDetails[0].StokAdi : 'Aranıyor...';
    const renderTimelineItems = stockDetails.map((el) => (
        <TimelineItem
            key={el.StokID + '-' + el.DepoID}
            color={
                el.Miktar > 5
                    ? 'success'
                    : el.Miktar > 0
                    ? 'warning'
                    : 'primary'
            }
            icon={
                el.Miktar > 5
                    ? 'check_circle'
                    : el.Miktar > 0
                    ? 'question_mark'
                    : 'remove_circle'
            }
            title={el.DepoAdi}
            dateTime={el.Miktar + ' - ' + el.Brm1Adi}
        />
    ));

    return (
        <TimelineList title={stockName} dark>
            {renderTimelineItems}
        </TimelineList>
    );
};
