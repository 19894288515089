import { useState } from 'react';

const useImageViewer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);

    const openImageViewer = () => setIsOpen(true);
    const closeImageViewer = () => setIsOpen(false);
    const nextImage = () => setCurrentImg((prevImg) => prevImg + 1);
    const prevImage = () => setCurrentImg((prevImg) => prevImg - 1);

    return {
        isOpen,
        currentImg,
        openImageViewer,
        closeImageViewer,
        nextImage,
        prevImage,
    };
};

export default useImageViewer;
