const currencyToSymbol = {
	TRY: '₺',
	EUR: '€',
	USD: '$',
};
export const formatPrice = (price, symbol = 'TRY') => {
	if (price == null) return;
	return (
		parseFloat(price).toLocaleString(
			// undefined, // leave undefined to use the visitor's browser
			// locale or a string like 'en-US' to override it.
			'en-US',
			{
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}
		) + currencyToSymbol[symbol]
	);
};

export const calculateDiscount = (price, amount, discount = 0) => {
	if (price == null || amount == null || discount == null) return null;

	const discountFixed = (100 + discount) / 100;
	const total = (price * amount) / discountFixed;
	return total;
};

export const calculateRisk = (price, risk) => {
	if (risk == 0 || risk > 99) return formatPrice(price);
	return formatPrice(price - price * (risk / 100));
};

export const calculateTaxPrice = (price, tax) => {
	const convertedTax = tax / 100 + 1;
	return price * convertedTax;
};

export const calculateProfit = (discount, tax, defaultTax = 18) => {
	const profit = 100 - 2 * (discount + defaultTax - tax);
	return profit;
};
