import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Rating from '@mui/material/Rating';
import { withStyles } from '@mui/styles';

import { updateProject } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';

const StyledRating = withStyles({
    iconFilled: {
        color: 'rgba(255, 180, 0, 0.8)', // bu değerleri istediğiniz parlaklık değerine göre ayarlayın
    },
    iconHover: {
        color: 'rgba(255, 180, 0, 1)', // bu değerleri istediğiniz parlaklık değerine göre ayarlayın
    },
})(Rating);

const DayRating = ({ project, readOnly = false }) => {
    const queryClient = useQueryClient();
    const [rating, setRating] = useState(project?.projectPotential || 0);

    const updateRating = useMutation((rating) => updateProject(project._id, { projectPotential: rating }), {
        onSuccess: (rp) => {
            notify(rp.data.success, rp.data.message);
            queryClient.invalidateQueries(['fetchProject', project._id]);
        },
        onError: (error) => {
            notify(false, error);
        },
    });

    const handleChange = (event, newValue) => {
        event.preventDefault();
        updateRating.mutate(newValue);
        setRating(newValue);
    };

    return <StyledRating value={rating} readOnly={readOnly} precision={1} max={5} onChange={handleChange} />;
};

export default DayRating;
