// Libraries

// Layout

// MD2 PRO
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// @mui
import Grid from '@mui/material/Grid';

// Project Pages And Components
// import Position from 'layouts/lead/components/Position';
import Responsibles from 'layouts/lead/components/Responsibles';
import Potential from 'layouts/lead/components/Potential';
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';
import { formatDate } from 'helpers/smallCodes';
import Actions from 'layouts/lead/components/Actions';
// import FileView from 'components/General/File';
import AddMultiFile from 'components/General/File/MultiFile.js';

import DisplaySMS from 'components/General/SMS/DisplaySMS.js';
import DisplayCalls from 'components/General/Call/DisplayCalls.js';
import DisplayVisits from 'components/General/Visit/DisplayVisits.js';
// API

// Aaro Components
import CustomerDetails from 'components/Aaro/customer/CustomerDetails';
import CustomerResponsibles from 'components/Aaro/customer/CustomerResponsibles';
import AaroCustomerSales from 'components/Aaro/customer/CustomerSales';

// Helpers

function MainPage({ lead, relatedID, setUpdateFiles }) {
    const decideColorByPotential = (potential) => {
        if (potential < 2) {
            return 'error';
        } else if (potential >= 2 && potential <= 3) {
            return 'warning';
        } else {
            return 'success';
        }
    };
    return (
        <MDBox mt={4}>
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <MDBox mb={3}>
                            <DefaultInfoCard
                                icon="business"
                                title={lead?.name}
                                description={lead?.description}
                                value={
                                    <MDBox
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <MDBox>
                                            <Responsibles lead={lead} />
                                        </MDBox>
                                        <MDBox mt={1}>
                                            <Potential lead={lead} />
                                        </MDBox>
                                        <MDBox>
                                            <MDTypography variant="caption" fontWeight="light">
                                                {formatDate(lead?.createdAt)}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                }
                                color={decideColorByPotential(lead?.potential)}
                            />
                        </MDBox>
                        <MDBox mb={3}>
                            <CustomerDetails customerID={lead?.customer?.customerID} icon="person" color="info" />
                        </MDBox>
                        <MDBox mb={3}>
                            <CustomerResponsibles customerID={lead?.customer?.customerID} />
                        </MDBox>
                        {/* <MDBox
                            mb={3}
                            bgColor="white"
                            variant="gradient"
                            borderRadius="lg"
                            shadow="lg"
                            opacity={1}
                        >
                            <Position lead={lead} />
                        </MDBox> */}

                        <MDBox
                            mb={3}
                            mt={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                        >
                            <AddMultiFile
                                relatedID={relatedID}
                                additionalName={lead?.name}
                                filePath={`leads/${relatedID}`}
                                setUpdateFiles={setUpdateFiles}
                                source="leads"
                            />
                        </MDBox>
                        <MDBox mb={3}>
                            <AaroCustomerSales customerID={lead?.customer?.customerID} />
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <MDBox mb={3}>
                            <Actions customer={lead?.customer} lead={lead} />
                        </MDBox>
                        <MDBox>
                            <Grid container spacing={3}>
                                {/* relatedID müşterinin ID'si olmalı */}
                                <Grid item xs={12} md={6}>
                                    <DisplayVisits relatedID={lead?._id} sourcePage="lead" />
                                </Grid>
                                {/* relatedID müşterinin ID'si olmalı */}

                                <Grid item xs={12} md={6}>
                                    <DisplaySMS relatedID={lead?._id} sourcePage="lead" />
                                </Grid>
                                {/* relatedID müşterinin ID'si olmalı */}
                                <Grid item xs={12} md={6}>
                                    <DisplayCalls relatedID={lead?._id} sourcePage="lead" />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default MainPage;
