// react-routers components

// prop-types is library for typechecking of props

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import Rating from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import { formatDateMinutes } from 'helpers/dateHelpers';
function ProfilesList({ title, shadow = true, userSuggestions }) {
    const renderProfiles = userSuggestions?.map(({ user, rate, productName, description, updatedAt }) => (
        <MDBox key={user?.username} component="li" display="flex" alignItems="center" py={1} mb={1}>
            <MDBox mr={2}>
                {' '}
                <Tooltip title={`${user?.username}-${formatDateMinutes(updatedAt)}`} placement="top">
                    <MDAvatar src={user?.profilePicture} alt="something here" shadow="md" />
                </Tooltip>
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
                <MDBox display="flex" alignItems="center" width="100%">
                    <MDTypography variant="button" fontWeight="medium">
                        {productName?.length > 0 ? productName : '------'}
                    </MDTypography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Rating name="customized-empty" value={rate} precision={0.5} size="small" />
                </MDBox>
                <MDTypography variant="caption" color="text">
                    {description?.length > 0 ? description : '------'}
                </MDTypography>
            </MDBox>
        </MDBox>
    ));

    return (
        <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
            <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {renderProfiles}
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default ProfilesList;
