import { useState, useEffect, createContext } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React example components
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';

// Material Dashboard 2 PRO React themes
import theme from 'assets/theme';

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from 'assets/theme-dark';

// Material Dashboard 2 PRO React routes
import routes from 'routes';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from 'context';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';

// Auth
import ProtectedRoutes from 'ProtectedRouters';
import SignInBasic from 'layouts/authentication/sign-in/basic';
// import OfferPrint from 'layouts/projects/Components/OfferPrint'; deprecated
import OfferToSheet from 'layouts/projects/Components/OfferToSheet';

// Create context with a default value
export const UserContext = createContext({
    user: {
        loggedIn: false,
        username: '',
        password: '',
    },
    setUser: () => {},
});

export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    useEffect(() => {
        console.log('app render');
    }, []); // Add empty dependency array to run effect only once
    const [user, setUser] = useState({
        loggedIn: false,
        username: '',
        password: '',
    });

    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction);
        if (pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }, [direction, pathname]);

    // // Setting page scroll to 0 when changing the route
    // useEffect(() => {
    //     document.documentElement.scrollTop = 0;
    //     document.scrollingElement.scrollTop = 0;
    // }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            // Check if route has the required properties
            if (!route) {
                return null;
            }

            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });

    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: 'pointer' }}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    // console.log('app rerender');

    return (
        // <ThemeProvider theme={darkMode ? themeDark : theme}>

        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === 'dashboard' && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                        brandName=" Kahramanlar A.Ş."
                        routes={routes}
                        // onMouseEnter={handleOnMouseEnter}
                        // onMouseLeave={handleOnMouseLeave}
                    />
                    <Configurator />
                    {configsButton}
                </>
            )}
            {/* {layout === 'vr' && <Configurator />} */}
            <UserContext.Provider value={{ user, setUser }}>
                <Routes>
                    <Route path="/login" element={<SignInBasic />} />

                    <Route element={<ProtectedRoutes />}>{getRoutes(routes)}</Route>
                    <Route
                        path="/offer/view"
                        element={
                            <OfferToSheet projectID="6151facb70001dded315e674" demandID="6151facc70001dded315e675" />
                        }
                    />
                    <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
                </Routes>
            </UserContext.Provider>
        </ThemeProvider>
    );
}
