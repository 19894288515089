import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import Autocomplete from '@mui/material/Autocomplete';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import { updateDemand } from 'services/api/project';

import { getDemand } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';

import AaroLoadingScreen from 'components/Loading';

const fetchDemand = async (id) => {
	const response = await getDemand(id)
		.then((rp) => rp.data.result)
		.catch((err) => err);
	return response;
};

function ChangeDemandCurrency({ project }) {
	const { data, isLoading, error } = useQuery(
		['fetchDemand', project.demand],
		() => fetchDemand(project.demand),
		{ staleTime: 600000 }
	);

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return <UpdateDemand demand={data} />;
}

const UpdateDemand = (props) => {
	const { demand } = props;
	const queryClient = useQueryClient();
	const [currency, setCurrency] = useState(
		demand.demandCurrency ? demand.demandCurrency : 'TRY'
	);
	const [usd, setUsd] = useState(
		demand.demandCurrencyValues ? demand.demandCurrencyValues.USD : 1
	);
	const [eur, setEur] = useState(
		demand.demandCurrencyValues ? demand.demandCurrencyValues.EUR : 1
	);
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisabled(true);

		try {
			const data = {
				demandCurrency: currency,
				demandCurrencyValues: {
					EUR: eur,
					USD: usd,
				},
			};

			await updateDemand(demand._id, data)
				.then((rp) => {
					notify(rp.data.success, rp.data.message);
					queryClient.invalidateQueries(['fetchDemand', demand._id]);
					setDisabled(false);
				})
				.catch((err) => {
					notify(false, err.message);
					setDisabled(false);
				});
		} catch (err) {
			alert(err);
		}
	};
	return (
		<Card id="change-password">
			<form onSubmit={handleSubmit}>
				<MDBox pb={3} px={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Autocomplete
								defaultValue="TRY"
								value={currency}
								onChange={(event, newValue) => {
									setCurrency(newValue);
								}}
								options={['TRY', 'USD', 'EUR']}
								renderInput={(params) => (
									<FormField
										{...params}
										label="Teklif Kuru"
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<MDInput
								fullWidth
								label="USD"
								value={usd}
								type="number"
								onChange={(e) =>
									setUsd(parseFloat(e.target.value))
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<MDInput
								fullWidth
								label="EUR"
								value={eur}
								type="number"
								onChange={(e) =>
									setEur(parseFloat(e.target.value))
								}
							/>
						</Grid>
					</Grid>

					<MDBox mb={1} mt={1}>
						<MDTypography variant="body2" color="text">
							Buradaki güncellemelerinizle birlikte teklifinizin
							kuru değiştirilir
						</MDTypography>
					</MDBox>
					<MDBox
						display="flex"
						justifyContent="space-between"
						alignItems="flex-end"
						flexWrap="wrap"
					>
						<MDBox ml="auto">
							<MDButton
								variant="gradient"
								color="dark"
								size="small"
								disabled={disabled}
								type="submit"
							>
								Güncelle
							</MDButton>
						</MDBox>
					</MDBox>
				</MDBox>
			</form>
		</Card>
	);
};

export default ChangeDemandCurrency;
