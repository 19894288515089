import { useFetchUsers } from 'services/outside/aaro/users.js';
import { daysUntilBirthday } from 'helpers/dateHelpers.js';

import MDBox from 'components/MDBox';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CakeIcon from '@mui/icons-material/Cake';
import Card from '@mui/material/Card';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
function BirthDays() {
    const { data, isLoading } = useFetchUsers();
    // "/Get?CikisTarihiSonBas=2023-03-24&GirisTarihiSonBit=2023-03-24"
    if (isLoading) {
        return <div>Loading...</div>;
    }

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const rows = data
        ? data
              .map((user) => {
                  const dob = new Date(user.DogumTarihi).toLocaleDateString('tr-TR', options);
                  const daysLeft = daysUntilBirthday(user.DogumTarihi);

                  return {
                      id: user.id,
                      name: user.PersonelAdiSoyadi,
                      dob: dob,
                      daysLeft: daysLeft,
                      birthdaySoon: daysLeft <= 15,
                      personelId: user.PersonelID,
                  };
              })
              .sort((a, b) => a.daysLeft - b.daysLeft)
        : [];
    const columns = [
        { field: 'name', headerName: 'İsim', flex: 1 },
        { field: 'dob', headerName: 'Doğum Günü', flex: 1 },
        {
            field: 'daysLeft',
            headerName: 'Kalan Gün',
            flex: 1,
            renderCell: (params) => {
                const daysLeft = params.value;
                const color = daysLeft <= 15 ? 'green' : daysLeft > 15 && daysLeft <= 30 ? 'orange' : 'inherit';

                return <div style={{ color: color }}>{daysLeft}</div>;
            },
        },
        {
            field: 'birthdaySoon',
            headerName: ' ',
            width: 100,
            renderCell: (params) => {
                return params.value === true ? <CakeIcon color="primary" /> : '';
            },
        },
        {
            field: 'personelId',
            headerName: 'Detay',
            width: 120,
            renderCell: (params) => {
                const handleClick = () => {
                    const personelId = params.value;
                    window.open(`https://erp2.aaro.com.tr/Personel/Ozet?id=${personelId}`, '_blank');
                };

                return (
                    <MDButton variant="contained" color="info" onClick={handleClick}>
                        Detay
                    </MDButton>
                );
            },
        },
    ];

    return (
        <Card sx={{ height: '100%' }}>
            <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                    Yaklaşan Doğum Günleri
                </MDTypography>
            </MDBox>
            <MDBox my={3} bgColor="white">
                <div
                    style={{ height: '80vh', width: '100%' }}
                    sx={{
                        '.MuiDataGrid-root': {
                            backgroundColor: '#424242',
                        },
                        '.MuiDataGrid-cell': {
                            borderRight: '1px solid rgba(224, 224, 224, 0.5)',
                            borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
                        },
                    }}
                >
                    <DataGridPro rows={rows} columns={columns} pageSize={10} columnReorder />
                </div>
            </MDBox>
        </Card>
    );
}

export default BirthDays;
