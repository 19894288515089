import * as React from 'react';
import { getTasksComplex } from 'services/api/general/task';
import { useQuery } from 'react-query';
import List from './List';
import AaroLoadingScreen from 'components/Loading';

const fetchTasks = async (taskListID, taskID) => {
	const searchQuery = {
		$and: [
			{
				taskList: {
					$eq: taskListID,
				},
			},
			{
				parent: {
					$eq: taskID,
				},
			},
		],
	};

	const response = await getTasksComplex(searchQuery)
		.then((rp) => rp.data.result)
		.catch((err) => err);
	return response;
};

export default function Todo({
	title = 'Yapılacaklar',
	taskListID,
	taskID,
	styleOverride,
}) {
	// const [openDialog, setOpenDialog] = React.useState(false);
	// ! TaskListID as a second parameter necessary because taskID can be null
	const { data, isLoading, error, isFetching } = useQuery(
		['fetchTasks', taskListID, taskID],
		() => fetchTasks(taskListID, taskID),
		{ refetchOnWindowFocus: false }
	);

	if (isLoading) return <AaroLoadingScreen />;
	if (isFetching) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;
	return <List title={title} tasks={data} styleOverride={styleOverride} />;
}
