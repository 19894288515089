// @mui material components
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

function DefaultSheetTemplateCard({ image, title, description, color, setSelected, sheetTemplate, tags }) {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'visible',
                alignItems: 'center', // Kartı ortalamak için eklendi
                width: '100%',
            }}
        >
            <MDBox position="relative" width="100%" shadow="xl" borderRadius="xl">
                <CardMedia
                    src={image}
                    component="img"
                    title={title}
                    sx={{
                        width: '100%', // Tam genişlik
                        height: '300px', // Sabit yükseklik
                        margin: 0,
                        boxShadow: ({ boxShadows: { md } }) => md,
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
            </MDBox>
            <MDBox mt={1} mx={0.5} textAlign="center" width="100%">
                {' '}
                {/* Metinleri ortalamak için textAlign eklendi */}
                <MDBox mb={1}>
                    <MDTypography variant="h5" textTransform="capitalize">
                        {title}
                    </MDTypography>
                </MDBox>
                <MDBox mb={3} lineHeight={0}>
                    <MDTypography variant="button" fontWeight="light" color="text">
                        {description}
                    </MDTypography>
                    {tags?.map((el) => (
                        <MDBadge
                            key={el.name}
                            badgeContent={el.name}
                            color={el.muiColorCode ? el.muiColorCode : 'dark'}
                            size="xs"
                            circular
                        />
                    ))}
                </MDBox>
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    {' '}
                    {/* Düğmeyi ortalamak için justifyContent eklendi */}
                    <MDButton variant="outlined" size="small" color={color} onClick={() => setSelected(sheetTemplate)}>
                        Şablonu Seç
                    </MDButton>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default DefaultSheetTemplateCard;
