import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBadge from 'components/MDBadge';

import { config } from 'Constants';
function ViewDemand({ demandID, projectID }) {
	const navigate = useNavigate();
	return (
		<Link
			href={`${config.url.ENV_URL}/offer/view?projectID=${projectID}&demandID=${demandID}`}
			target="_blank"
		>
			<MDButton variant="gradient" color="info" size="small">
				Görüntüle
			</MDButton>
		</Link>
	);
}

export default ViewDemand;
