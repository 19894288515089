import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { BasketContext } from 'layouts/aaro/fastPrice';
import AddIcon from '@mui/icons-material/Add';
import MDBox from 'components/MDBox';

import { getStockPrice } from 'services/outside/aaro/priceList';
import { notify } from 'helpers/notificationHelper';
import MDButton from 'components/MDButton';
import { todayIsBetween } from 'helpers/dateHelpers';
// import aa from 'search-insights';

const fetchAaroStockPrice = async (stokID) => {
	const response = await getStockPrice(stokID).then((rp) => rp.data);
	return response;
};

function AddToBasket({
	stockID,
	Price = 0,
	Amount = 1,
	Discount = 0,
	Tax = 18,
	stockName = '',
	sku = '',
	setOpen,
	queryID,
	objectID,
}) {
	const { basket, setBasket } = useContext(BasketContext);
	const queryClient = useQueryClient();

	const indexName = 'improvedProd';

	const handleBasket = (data) => {
		const currentBasket = {
			id: stockID,
			Price: data.Price ? data.Price : Price,
			Amount,
			Discount,
			Tax,
			StokID: stockID,
			StokKodu: data.StokKodu,
			StokAdi: data.StokAdi,
		};
		const previousData = JSON.parse(JSON.stringify(basket));
		// duplicate prevention by removing older file
		for (let i = 0; i < previousData.length; i++) {
			if (previousData[i].StokID === currentBasket.StokID) {
				previousData.splice(i, 1);
			}
		}

		const newBasket = [...previousData, currentBasket];
		setBasket(newBasket);
		notify(true, 'Sepete Eklendi');
		setOpen && setOpen(false);
	};
	const user = JSON.parse(localStorage.getItem('user'));

	return (
		<MDBox
			onMouseEnter={async () => {
				await queryClient.prefetchQuery(
					['fetchAaroStockPrice', stockID],
					() => fetchAaroStockPrice(stockID),
					{
						staleTime: 60 * 1000, // only prefetch if older than 10 seconds
					}
				);
			}}
		>
			<MDButton
				variant="gradient"
				color="success"
				size="small"
				startIcon={<AddIcon />}
				onClick={() => {
					queryID &&
						window.aa('convertedObjectIDsAfterSearch', {
							eventName: 'Product Added to Cart',
							index: indexName,
							userToken: user.userID,
							objectIDs: [objectID],
							queryID: queryID,
						});
					const resp = queryClient.getQueryData([
						'fetchAaroStockPrice',
						stockID,
					]);
					const model = resp?.Model ? resp.Model[0] : {};
					const isValid = todayIsBetween(
						new Date(model?.TarihBas),
						new Date(model?.TarihBit)
					);
					const item = isValid
						? {
								Price: model?.Fiyat,
								StokKodu: model?.StokKodu,
								StokAdi: model?.StokAdi,
						  }
						: {
								Fiyat: 0,
								StokAdi: stockName,
								StokKodu: sku,
						  };
					handleBasket(item);
				}}
			>
				Sepet
			</MDButton>
		</MDBox>
	);
}

// const AddToBasket = connectHitInsights(aa)(Add);

export default AddToBasket;
