const getCountryFromLabel = (label) => {
    const labelParts = label.split(', ');
    const countryName = labelParts[labelParts.length - 1];
    return countryName;
};

// City codes mapping
const cityCodes = {
    TR01: 'Adana',
    TR02: 'Adıyaman',
    TR03: 'Afyonkarahisar',
    TR04: 'Ağrı',
    TR05: 'Amasya',
    TR06: 'Ankara',
    TR07: 'Antalya',
    TR08: 'Artvin',
    TR09: 'Aydın',
    TR10: 'Balıkesir',
    TR11: 'Bilecik',
    TR12: 'Bingöl',
    TR13: 'Bitlis',
    TR14: 'Bolu',
    TR15: 'Burdur',
    TR16: 'Bursa',
    TR17: 'Çanakkale',
    TR18: 'Çankırı',
    TR19: 'Çorum',
    TR20: 'Denizli',
    TR21: 'Diyarbakır',
    TR22: 'Edirne',
    TR23: 'Elazığ',
    TR24: 'Erzincan',
    TR25: 'Erzurum',
    TR26: 'Eskişehir',
    TR27: 'Gaziantep',
    TR28: 'Giresun',
    TR29: 'Gümüşhane',
    TR30: 'Hakkâri',
    TR31: 'Hatay',
    TR32: 'Isparta',
    TR33: 'Mersin',
    TR34: 'İstanbul',
    TR35: 'İzmir',
    TR36: 'Kars',
    TR37: 'Kastamonu',
    TR38: 'Kayseri',
    TR39: 'Kırklareli',
    TR40: 'Kırşehir',
    TR41: 'Kocaeli',
    TR42: 'Konya',
    TR43: 'Kütahya',
    TR44: 'Malatya',
    TR45: 'Manisa',
    TR46: 'Kahramanmaraş',
    TR47: 'Mardin',
    TR48: 'Muğla',
    TR49: 'Muş',
    TR50: 'Nevşehir',
    TR51: 'Niğde',
    TR52: 'Ordu',
    TR53: 'Rize',
    TR54: 'Sakarya',
    TR55: 'Samsun',
    TR56: 'Siirt',
    TR57: 'Sinop',
    TR58: 'Sivas',
    TR59: 'Tekirdağ',
    TR60: 'Tokat',
    TR61: 'Trabzon',
    TR62: 'Tunceli',
    TR63: 'Şanlıurfa',
    TR64: 'Uşak',
    TR65: 'Van',
    TR66: 'Yozgat',
    TR67: 'Zonguldak',
    TR68: 'Aksaray',
    TR69: 'Bayburt',
    TR70: 'Karaman',
    TR71: 'Kırıkkale',
    TR72: 'Batman',
    TR73: 'Şırnak',
    TR74: 'Bartın',
    TR75: 'Ardahan',
    TR76: 'Iğdır',
    TR77: 'Yalova',
    TR78: 'Karabük',
    TR79: 'Kilis',
    TR80: 'Osmaniye',
    TR81: 'Düzce',
};

export { getCountryFromLabel, cityCodes };
