import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getSMS = (query) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/sms?${query}`;
	return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postSMS = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/sms`;
	return axios.post(url, data, { headers });
};
