import React from 'react';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';

import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';

import EditCustomerResponsible from './Modals/EditCustomerResponsible';
import DeleteCustomerResponsible from './Modals/DeleteCustomerResponsible';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import masterCardLogo from 'assets/images/logos/mastercard.png';
import userLogo from 'assets/images/logos/user.png';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';

import { getCariIlgili } from 'services/outside/aaro/customer';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		maxWidth: 752,
	},
	demo: {
		backgroundColor: theme.palette.background.paper,
	},
	title: {
		margin: theme.spacing(4, 0, 2),
	},
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	customBadge: {
		backgroundColor: '#485563',
		color: 'white',
	},
}));

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const fetchAaroCustomerResponsibles = async (id) => {
	const response = await getCariIlgili(id).then((rp) => rp);
	return response;
};

export default function ViewCustomerResponsibles(props) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const { customerID } = props;
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(false);
	const [openDeletion, setOpenDeletion] = React.useState(false);

	const [action, setAction] = React.useState('');
	const [current, setCurrent] = React.useState({});
	const classes = useStyles();

	const { data, isLoading, error, isFetching } = useQuery(
		['fetchAaroCustomerResponsibles', customerID],
		() => fetchAaroCustomerResponsibles(customerID),
		{ staleTime: 6000000 }
	);

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{isFetching ? (
				'Güncelleniyor'
			) : (
				<Card id="responsibles">
					<MDBox
						pt={2}
						px={2}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<MDTypography variant="h6" fontWeight="medium">
							Firma Sorumluları
						</MDTypography>
						<MDButton
							variant="gradient"
							color="dark"
							onClick={() => {
								setCurrent({
									CariID: customerID,
								});
								setOpen(true);
								setAction('add');
							}}
						>
							<Icon sx={{ fontWeight: 'bold' }}>add</Icon>
							&nbsp;yenİ İLGİLİ
						</MDButton>
					</MDBox>
					<MDBox p={2}>
						<Grid container spacing={3}>
							{data?.map((el, index) => (
								<Grid item xs={12} md={12} key={index}>
									<MDBox
										borderRadius="lg"
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										p={3}
										sx={{
											border: ({
												borders: {
													borderWidth,
													borderColor,
												},
											}) =>
												`${borderWidth[1]} solid ${borderColor}`,
										}}
									>
										<MDBox
											component="img"
											src={userLogo}
											alt="master card"
											width="10%"
											mr={2}
										/>
										<MDTypography
											variant="h6"
											fontWeight="medium"
										>
											{`${el?.IlgiliAdi} ${
												el?.Unvan
													? '(' + el.Unvan + ')'
													: '(Kim Belli Değil)'
											}`}
										</MDTypography>
										<MDBox
											ml="auto"
											lineHeight={0}
											color={darkMode ? 'white' : 'dark'}
										>
											<Tooltip
												title="Düzenle"
												placement="top"
												onClick={() => {
													setOpen(true);

													setCurrent(el);
													setAction('update');
												}}
											>
												<Icon
													sx={{ cursor: 'pointer' }}
													fontSize="small"
												>
													edit
												</Icon>
											</Tooltip>
											&nbsp; &nbsp;
											<Tooltip
												title="Sil"
												placement="top"
												onClick={() => {
													setOpenDeletion(true);
													setCurrent(el);
												}}
											>
												<Icon
													sx={{ cursor: 'pointer' }}
													fontSize="small"
												>
													delete
												</Icon>
											</Tooltip>
										</MDBox>
									</MDBox>
								</Grid>
							))}
						</Grid>
					</MDBox>
					<div>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<div style={modalStyle} className={classes.paper}>
								<MDTypography variant="h6" fontWeight="medium">
									İlgiliyi Düzenleyin
								</MDTypography>

								<MDTypography
									variant="body2"
									fontWeight="light"
								>
									Lütfen bildiğiniz bütün detayları girin.
								</MDTypography>
								<br />
								<EditCustomerResponsible
									setOpen={setOpen}
									initial={current}
									action={action}
								/>
							</div>
						</Modal>
					</div>
					<div>
						<Modal
							open={openDeletion}
							onClose={() => {
								setOpenDeletion(false);
							}}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<div style={modalStyle} className={classes.paper}>
								<MDTypography variant="h4" fontWeight="medium">
									Emin Misiniz?
								</MDTypography>

								<br />

								<DeleteCustomerResponsible
									setOpenDeletion={setOpenDeletion}
									initial={current}
								/>
							</div>
						</Modal>
					</div>
				</Card>
			)}
		</div>
	);
}
