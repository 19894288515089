import React from 'react';
import Grid from '@mui/material/Grid';
import FormSwitch from 'layouts/projects/Forms/FormComponents/FormSwitch';
import OutlinedFormTextField from 'layouts/projects/Forms/FormComponents/OutlinedFormTextField';
import { validationSchemaUKasaSheetTemplate } from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/YupValidationSchemaSheets.js';
import { uKasaToSheet, sheetToUKasa } from '@samilkahraman/demand-namer';
import { BaseForm } from '../BaseForm';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

export const UKasaForm = (props) => {
    const allowedChanges = [
        'miktar',
        'en',
        'boy',
        'dumanFitili',
        'kesim45',
        'kilitKarsiligi',
        'menteseKarsiligi',
        'hidrolikKarsiligi',
    ];

    const renderFields = (formik, classes) => (
        <>
            <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                    <OutlinedFormTextField
                        itemID="miktar"
                        label="Miktar (Adet)"
                        placeHolder="Örn: 1000"
                        type="number"
                        formik={formik}
                        step="1"
                        adornment="AD"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <OutlinedFormTextField
                        itemID="en"
                        label="En"
                        placeHolder="Örn: 82"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <OutlinedFormTextField
                        itemID="boy"
                        label="Boy"
                        placeHolder="Örn: 209"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.switch}>
                <FormSwitch formik={formik} name="dumanFitili" color="secondary" label="Duman Fitili" />
                <FormSwitch formik={formik} name="kesim45" color="secondary" label="45° Kesim" />
                <FormSwitch formik={formik} name="kilitKarsiligi" color="secondary" label="Kilit Karşılığı" />
                <FormSwitch formik={formik} name="menteseKarsiligi" color="secondary" label="Menteşe Karşılığı" />
                <FormSwitch formik={formik} name="hidrolikKarsiligi" color="secondary" label="Hidrolik Karşılığı" />
            </Grid>
        </>
    );

    return (
        <BaseForm
            {...props}
            validationSchema={validationSchemaUKasaSheetTemplate}
            allowedChanges={allowedChanges}
            toSheet={uKasaToSheet}
            fromSheet={sheetToUKasa}
            renderFields={renderFields}
        />
    );
};

export const UKasaBadges = (props) => {
    const { initial } = props;
    const values = sheetToUKasa(initial);
    const toBadge = (degisken, aciklama, renk = 'dark') => {
        return (
            <MDBox>
                <MDBadge
                    m={1}
                    badgeContent={`${aciklama}: ${values[degisken] !== undefined ? values[degisken] : '-'}`}
                    size="xs"
                    container
                    variant="gradient"
                    color={renk}
                />
            </MDBox>
        );
    };
    return (
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12}>
                <MDBox display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                    {toBadge('kalinlik', 'Kalınlık', 'secondary')}
                    {toBadge('cins', 'Cins', 'secondary')}
                    {toBadge('cinsAciklama', 'Cins Açıklama', 'secondary')}
                    {toBadge('yapi', 'Yapı', 'secondary')}
                    {toBadge('lambaDerinlik', 'Lamba Derinlik', 'secondary')}
                    {toBadge('lambaGenislik', 'Lamba Genişlik', 'secondary')}
                    {toBadge('fitil', 'Fitil', 'secondary')}
                    {toBadge('pervazKanalGenisligi', 'Pervaz Kanal Genişliği', 'secondary')}
                    {toBadge('pervazKanalDerinligi', 'Pervaz Kanal Derinliği', 'secondary')}
                    {toBadge('pervazKanaliAltBosluk', 'Pervaz Kanalı Alt Boşluk', 'secondary')}
                    {toBadge('ozelProfilTipi', 'Özel Profil Tipi', 'secondary')}
                    {toBadge('ozelProfilAciklama', 'Özel Profil Açıklama', 'secondary')}
                    {toBadge('ekNot', 'NOTLAR: ', 'dark')}
                </MDBox>
            </Grid>
        </Grid>
    );
};
