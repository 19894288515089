// Material Dashboard 2 PRO React example components

import DataTable from 'examples/Tables/DataTable';

// Data
import { demandToOffer } from 'layouts/projects/Components/Offer/components/DemandsTable/data/dataTableData';

function DemandsTable({ demand }) {
	const dataTableContent = demandToOffer(demand);
	return (
		<DataTable
			table={dataTableContent}
			entriesPerPage={false}
			showPagination={false}
			itemAmount={250}
		/>
	);
}

export default DemandsTable;
