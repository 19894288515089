import * as React from 'react';
import DraggableList from 'components/General/Task/components/DraggableList';
// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React helper functions
import { Divider } from '@mui/material';

import AddTodo from 'components/General/Task/utils/AddTodo';
import Dialog from '@mui/material/Dialog';

export default function Todo({
    title = 'Yapılacaklar',
    taskListID,
    taskID,
    styleOverride,
    url = null,
}) {
    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <>
            <Card>
                <MDBox
                    pt={2}
                    px={2}
                    pr={3}
                    display="flex"
                    justifyContent="space-between"
                >
                    <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                    >
                        {title}
                    </MDTypography>
                    <MDButton
                        variant="gradient"
                        color="dark"
                        iconOnly
                        size="small"
                        onClick={() => setOpenDialog(true)}
                        // circular
                    >
                        <Icon>add</Icon>
                    </MDButton>

                    <Dialog
                        onClose={() => setOpenDialog(false)}
                        open={openDialog}
                        fullWidth={true}
                        maxWidth="sm"
                    >
                        {/*Burada  */}
                        <AddTodo
                            initial={{
                                taskList: taskListID,
                                parent: taskID,
                                url,
                            }}
                            setOpenDialog={setOpenDialog}
                        />
                    </Dialog>
                </MDBox>
                <Divider />
                <MDBox p={2}>
                    <DraggableList
                        title={title}
                        taskListID={taskListID}
                        taskID={taskID}
                        styleOverride={styleOverride}
                    />
                </MDBox>
            </Card>
        </>
    );
}
