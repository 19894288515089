import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

import MDInput from 'components/MDInput';
import { notify } from 'helpers/notificationHelper';

import { copyDemand } from 'services/api/project';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 500,
	maxHeight: 800,
	width: '100%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	overflowY: 'scroll',
};

function FreezeModal({ open, setOpen, demandID, projectID, setValue }) {
	const queryClient = useQueryClient();
	const [titleValue, setTitleValue] = React.useState('Son Teklif');
	const [freezeNotes, setFreezeNotes] = React.useState('');
	const [validity, setValidity] = React.useState(7);
	const [disabled, setDisabled] = React.useState(false);

	const freezeDemand = async (event) => {
		event.preventDefault();
		setDisabled(true);
		await copyDemand(demandID, projectID, {
			title: titleValue,
			freezeNotes,
			validity,
		})
			.then((rp) => {
				notify(rp.data.success, rp.data.message);
				queryClient.invalidateQueries(['fetchProject', projectID]);

				// setOpen(false);
				setValue(3);
			})
			.catch((err) => {
				notify(false, err.message);
				setOpen(false);
			});
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<MDBox sx={style}>
					<MDBox display="flex" justifyContent="space-around">
						<MDInput
							label="Teklif Adı"
							value={titleValue}
							onChange={(e) => setTitleValue(e.target.value)}
							fullWidth
						></MDInput>
						<MDInput
							label="Geçerlilik Süresi (GÜN)"
							value={validity}
							onChange={(e) =>
								setValidity(parseInt(e.target.value))
							}
							fullWidth
						></MDInput>
					</MDBox>
					<MDBox mt={2} mb={2}>
						<MDInput
							label="Ek Not Alanı"
							value={freezeNotes}
							onChange={(e) => setFreezeNotes(e.target.value)}
							fullWidth
						></MDInput>
					</MDBox>
					<MDBox display="flex" justifyContent="center">
						{' '}
						<MDButton
							variant="gradient"
							color="primary"
							onClick={freezeDemand}
							disabled={disabled}
						>
							Onayla
						</MDButton>
					</MDBox>
				</MDBox>
			</Fade>
		</Modal>
	);
}

export default FreezeModal = React.memo(FreezeModal);
