import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDBadgeDot from 'components/MDBadgeDot';
import MDButton from 'components/MDButton';
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import Card from '@mui/material/Card';

function MonthlyProjectDistribution({ projects }) {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const prevYear = currentYear - 1;
        const twoYearsAgo = currentYear - 2;

        const groupByMonthAndYear = (year) =>
            projects.reduce((acc, cur) => {
                const date = new Date(cur.createdAt);
                if (date.getFullYear() === year) {
                    const month = date.toLocaleString('default', { month: 'short' }); // get month in 'Jan', 'Feb', ... format
                    acc[month] = (acc[month] || 0) + 1;
                }
                return acc;
            }, {});

        const orderAndFillData = (groupedData, baseYear) => {
            const orderedAndFilledData = Array.from({ length: 12 }, (_, i) =>
                new Date(baseYear, i).toLocaleString('default', { month: 'short' })
            ).map((month) => groupedData[month] || 0);
            return orderedAndFilledData;
        };

        const currentYearGrouped = groupByMonthAndYear(currentYear);
        const prevYearGrouped = groupByMonthAndYear(prevYear);
        const twoYearsAgoGrouped = groupByMonthAndYear(twoYearsAgo);

        const currentYearOrderedAndFilled = orderAndFillData(currentYearGrouped, currentYear);
        const prevYearOrderedAndFilled = orderAndFillData(prevYearGrouped, prevYear);
        const twoYearsAgoOrderedAndFilled = orderAndFillData(twoYearsAgoGrouped, twoYearsAgo);

        const datasets = [
            {
                label: `${currentYear} Projeleri`,
                color: 'success',
                data: currentYearOrderedAndFilled,
            },
            {
                label: `${prevYear} Projeleri`,
                color: 'info',
                data: prevYearOrderedAndFilled,
            },
            {
                label: `${twoYearsAgo} Projeleri`,
                color: 'error',
                data: twoYearsAgoOrderedAndFilled,
            },
        ];

        const labels = Array.from({ length: 12 }, (_, i) =>
            new Date(prevYear, i).toLocaleString('default', { month: 'short' })
        );

        setChartData({
            labels,
            datasets,
        });
    }, [projects]);

    return (
        <Card sx={{ height: '100%' }}>
            <DefaultLineChart
                title="Aylara Göre Proje Dağılımları"
                chart={chartData}
                description={
                    <MDBox display="flex" justifyContent="space-between">
                        <MDBox display="flex" ml={-1}>
                            <MDBadgeDot
                                color="success"
                                size="sm"
                                badgeContent={`${new Date().getFullYear()} Projeleri`}
                            />
                            <MDBadgeDot
                                color="info"
                                size="sm"
                                badgeContent={`${new Date().getFullYear() - 1} Projeleri`}
                            />
                            <MDBadgeDot
                                color="error"
                                size="sm"
                                badgeContent={`${new Date().getFullYear() - 2} Projeleri`}
                            />
                        </MDBox>
                        <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                            <Tooltip
                                title="Mevcut sene, geçmiş sene ve 2 yıl öncesi gösterilmektedir"
                                placement="left"
                                arrow
                            >
                                <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                                    <Icon>priority_high</Icon>
                                </MDButton>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                }
            />
        </Card>
    );
}

export default MonthlyProjectDistribution;
