import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from 'examples/Footer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
function BaseLayout({ stickyNavbar, children, setValue, shrink, setShrink }) {
    const [tabsOrientation, setTabsOrientation] = useState('horizontal');
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation('vertical')
                : setTabsOrientation('horizontal');
        }

        /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
        window.addEventListener('resize', handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        setValue(newValue);
    };

    return (
        <DashboardLayout>
            <MDBox mt={2}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <AppBar position="static">
                            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                                <Tab label={`Genel `} icon={<Icon>business_center</Icon>} />

                                <Tab label="Kalemler" icon={<Icon>list</Icon>} />
                                {/* <Tab
									label="Grup"
									icon={<Icon>view_module</Icon>}
								/> */}
                                <Tab label="Ön İzle" icon={<Icon>preview</Icon>} />
                                <Tab label="Teklifler" icon={<Icon>assignment</Icon>} />
                                <Tab label="Ayarlar" icon={<Icon>settings</Icon>} />
                                <MDButton onClick={() => setShrink(!shrink)} variant={'gradient'} color="light">
                                    {shrink ? (
                                        <>
                                            {' '}
                                            <NavigateBeforeIcon />
                                            Genişlet <NavigateNextIcon />{' '}
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            <NavigateNextIcon />
                                            Daralt <NavigateBeforeIcon />{' '}
                                        </>
                                    )}
                                </MDButton>
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
                {children}
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

// Setting default values for the props of BaseLayout
BaseLayout.defaultProps = {
    stickyNavbar: false,
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
    stickyNavbar: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default BaseLayout;
