//https://coolors.co/d9ed92-b5e48c-99d98c-76c893-52b69a-34a0a4-168aad-1a759f-1e6091-184e77
//https://coolors.co/0b090a-161a1d-660708-a4161a-ba181b-e5383b-b1a7a6-d3d3d3-f5f3f4-ffffff
//https://coolors.co/641220-6e1423-85182a-a11d33-a71e34-b21e35-bd1f36-c71f37-da1e37-e01e37
//https://coolors.co/3d5a80-98c1d9-e0fbfc-ee6c4d-293241
//https://coolors.co/ffedd8-f3d5b5-e7bc91-d4a276-bc8a5f-a47148-8b5e34-6f4518-603808-583101
const NAKIT_TAHSILAT_COLOR = '#6f4518';
const NAKIT_ODEME_COLOR = '#e7bc91';
const BANKAYA_PARA_YATIRMA_COLOR = '#8b5e34';
const BANKADAN_PARA_CEKME_COLOR = '#d4a276';
const SATIS_FATURASI_COLOR = '#184e77';
const ALIS_FATURASI_COLOR = '#d9ed92';
const SATIS_IADE_FATURASI_COLOR = '#641220';
const ALIS_IADE_FATURASI_COLOR = '#6e1423';
const SATIS_IRSALIYESI_COLOR = '#1a759f';
const ALIS_IRSALIYESI_COLOR = '#b5e48c';
const SATIS_IADE_IRSALIYESI_COLOR = '#85182a';
const ALIS_IADE_IRSALIYESI_COLOR = '#a11d33';
const ALINAN_SIPARIS_COLOR = '#168aad';
const VERILEN_SIPARIS_COLOR = '#99d98c';
const ALINAN_TEKLIF_COLOR = '#76c893';
const VERILEN_TEKLIF_COLOR = '#52b69a';
const DEPOLAR_ARASI_TRANSFER_COLOR = '#577590';
const URETIM_GENEL_COLOR = '#ee6c4d';

const NAKIT_TAHSILAT = {
    color: NAKIT_TAHSILAT_COLOR,
    name: 'Nakit Tahsilat',
    tipID: 5003,
};
const NAKIT_ODEME = {
    color: NAKIT_ODEME_COLOR,
    name: 'Nakit Ödeme',
    tipID: 5004,
};
const BANKAYA_PARA_YATIRMA = {
    color: BANKAYA_PARA_YATIRMA_COLOR,
    name: 'Bankaya Para Yatırma',
    tipID: 5008,
};
const BANKADAN_PARA_CEKME = {
    color: BANKADAN_PARA_CEKME_COLOR,
    name: 'Bankadan Para Çekme',
    tipID: 5009,
};
const SATIS_FATURASI = {
    color: SATIS_FATURASI_COLOR,
    name: 'Satış Faturası',
    tipID: 10005,
};
const ALIS_FATURASI = {
    color: ALIS_FATURASI_COLOR,
    name: 'Alış Faturası',
    tipID: 10006,
};
const SATIS_IADE_FATURASI = {
    color: SATIS_IADE_FATURASI_COLOR,
    name: 'Satış İade Faturası',
    tipID: 10007,
};
const ALIS_IADE_FATURASI = {
    color: ALIS_IADE_FATURASI_COLOR,
    name: 'Alış İade Faturası',
    tipID: 10008,
};
const SATIS_IRSALIYESI = {
    color: SATIS_IRSALIYESI_COLOR,
    name: 'Satış İrsaliyesi',
    tipID: 10009,
};
const ALIS_IRSALIYESI = {
    color: ALIS_IRSALIYESI_COLOR,
    name: 'Alış İrsaliyesi',
    tipID: 10010,
};
const SATIS_IADE_IRSALIYESI = {
    color: SATIS_IADE_IRSALIYESI_COLOR,
    name: 'Satış İade İrsaliyesi',
    tipID: 10011,
};
const ALIS_IADE_IRSALIYESI = {
    color: ALIS_IADE_IRSALIYESI_COLOR,
    name: 'Alış İade İrsaliyesi',
    tipID: 10012,
};
const ALINAN_SIPARIS = {
    color: ALINAN_SIPARIS_COLOR,
    name: 'Alınan Sipariş',
    tipID: 10013,
};
const VERILEN_SIPARIS = {
    color: VERILEN_SIPARIS_COLOR,
    name: 'Verilen Sipariş',
    tipID: 10014,
};
const ALINAN_TEKLIF = {
    color: ALINAN_TEKLIF_COLOR,
    name: 'Alınan Teklif',
    tipID: 10015,
};
const VERILEN_TEKLIF = {
    color: VERILEN_TEKLIF_COLOR,
    name: 'Verilen Teklif',
    tipID: 10016,
};
const DEPOLAR_ARASI_TRANSFER = {
    color: DEPOLAR_ARASI_TRANSFER_COLOR,
    name: 'Depolar Arası Transfer',
    tipID: 10019,
};
const URETIM_GENEL = {
    color: URETIM_GENEL_COLOR,
    name: 'ÜRETİM HAREKETİ',
    tipID: -1,
};
export const typeMatch = (tipID) => {
    switch (tipID) {
        case 5003:
            return NAKIT_TAHSILAT;

        case 5004:
            return NAKIT_ODEME;

        case 5008:
            return BANKAYA_PARA_YATIRMA;

        case 5009:
            return BANKADAN_PARA_CEKME;

        case 10005:
            return SATIS_FATURASI;

        case 10006:
            return ALIS_FATURASI;

        case 10007:
            return SATIS_IADE_FATURASI;

        case 10008:
            return ALIS_IADE_FATURASI;

        case 10009:
            return SATIS_IRSALIYESI;

        case 10010:
            return ALIS_IRSALIYESI;

        case 10011:
            return SATIS_IADE_IRSALIYESI;

        case 10012:
            return ALIS_IADE_IRSALIYESI;

        case 10013:
            return ALINAN_SIPARIS;

        case 10014:
            return VERILEN_SIPARIS;

        case 10015:
            return ALINAN_TEKLIF;

        case 10016:
            return VERILEN_TEKLIF;

        case 10019:
            return DEPOLAR_ARASI_TRANSFER;

        default:
            return URETIM_GENEL;
    }
};
