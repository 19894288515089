import { memo } from 'react';
// import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React example components
import Layout from './TmpLayout';
import Header from './Header';

// Project page components

function LeadsLayout({ children }) {
    // ProjectCard dropdown menu state

    return (
        <Layout>
            <Header />
            <MDBox pb={3}>
                <MDBox mt={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </Layout>
    );
}

// Setting default props for the LeadsLayout
// LeadsLayout.defaultProps = {
//     children: '',
// };

// // Typechecking props for the LeadsLayout
// LeadsLayout.propTypes = {
//     children: PropTypes.node,
// };

export default memo(LeadsLayout);
