import React from 'react';

import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';

import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { getVisits } from 'services/api/general/visit';

import Transaction from 'components/General/Visit/SingleVisit';
import { formatDate } from 'helpers/dateHelpers';

const fetchVisits = async (query) => {
    const response = await getVisits(query)
        .then((res) => res?.data?.result)
        .catch((err) => err);
    return response;
};

export default function Anouncements(props) {
    const { relatedID, sourcePage = 'customer' } = props;

    const query = `relatedID=${relatedID}&sourcePage=${sourcePage}`;

    const { data, isLoading, error, refetch } = useQuery(['fetchVisits', query], () => fetchVisits(query), {
        staleTime: 91200000,
        enabled: !!relatedID,
    });

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'An error occurred: ' + error.message;

    return (
        <Card sx={{ height: '100%' }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Yapılan Ziyaretler
                </MDTypography>
            </MDBox>
            <MDBox pt={3} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{ listStyle: 'none' }}>
                    {data?.map((item, index) => {
                        const {
                            _id,
                            customer,
                            visitDate,
                            visitDuration,
                            visitNotes,
                            sourcePage,
                            user,
                            // Add other visit properties here
                        } = item;
                        return (
                            <Transaction
                                _id={_id}
                                key={_id}
                                customer={customer}
                                visitDate={formatDate(visitDate)}
                                visitDuration={visitDuration}
                                visitNotes={visitNotes}
                                sourcePage={sourcePage}
                                user={user}
                                refetch={refetch}
                                // Pass other visit properties here
                            />
                        );
                    })}
                </MDBox>
            </MDBox>
        </Card>
    );
}
