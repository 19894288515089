// Libraries

// Layout

// MD2 PRO
import MDBox from 'components/MDBox';
// @mui
import Grid from '@mui/material/Grid';

// Project Pages And Components
import Location from 'layouts/customers/components/Location';
import Potential from 'layouts/customers/components/Potential';
// import FileView from 'components/General/File';
import AddFile from 'components/General/File/AddFile';

// API

// Aaro Components
import CustomerDetails from 'components/Aaro/customer/CustomerDetails';
import CustomerResponsibles from 'components/Aaro/customer/CustomerResponsibles';
import AaroCustomerSales from 'components/Aaro/customer/CustomerSales';
import Actions from 'layouts/customers/components/Actions';
import DisplaySMS from 'components/General/SMS/DisplaySMS.js';
import DisplayCalls from 'components/General/Call/DisplayCalls.js';
import CustomerFeedback from 'layouts/customers/components/CustomerFeedback/index.js';
// Helpers

function MainPage({ setUpdateFiles, customer }) {
	const customerID = customer?._id;
	return (
		<MDBox mt={4}>
			<MDBox mb={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4}>
						<MDBox mb={3}>
							<CustomerDetails
								customerID={customer?.customerID}
								icon="person"
								color="dark"
							/>
						</MDBox>
						<MDBox
							mb={3}
							borderRadius="lg"
							bgColor="white"
							display="flex"
							alignItems="center"
							justify="center"
							flexDirection="column"
						>
							<Potential customer={customer} />
						</MDBox>

						<MDBox
							mb={3}
							bgColor="white"
							display={{
								xs: 'none',
								sm: 'none',
								md: 'flex',
								lg: 'flex',
								xl: 'flex',
							}}
							alignItems="center"
							color="white"
							shadow="md"
							borderRadius="lg"
							variant="gradient"
						>
							<CustomerResponsibles
								customerID={customer?.customerID}
							/>
						</MDBox>

						<MDBox
							mb={3}
							display={{
								xs: 'none',
								sm: 'none',
								md: 'flex',
								lg: 'flex',
								xl: 'flex',
							}}
						>
							<AaroCustomerSales
								customerID={customer?.customerID}
							/>
						</MDBox>

						<MDBox
							mb={3}
							mt={6}
							display={{
								xs: 'none',
								sm: 'none',
								md: 'flex',
								lg: 'flex',
								xl: 'flex',
							}}
						>
							<AddFile
								relatedID={customerID}
								additionalName={customer?.name}
								filePath={`customers/${customer?.name}`}
								setUpdateFiles={setUpdateFiles}
							/>
						</MDBox>
					</Grid>

					<Grid item xs={12} lg={8}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Actions customer={customer} />
								<CustomerFeedback customerID={customerID} />
							</Grid>
							<Grid item xs={12}>
								<Location customer={customer} />
							</Grid>
							<Grid item xs={12} md={6}>
								<DisplaySMS relatedID={customerID} />
							</Grid>
							<Grid item xs={12} md={6}>
								<DisplayCalls relatedID={customerID} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</MDBox>
		</MDBox>
	);
}

export default MainPage;
