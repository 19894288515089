import React, { useEffect, useState } from 'react';
import { config } from 'Constants';

import { useMutation, useQueryClient, useQuery } from 'react-query';
import { notify } from 'helpers/notificationHelper';

import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import MDButton from 'components/MDButton';
import { postUPervaz, updateUPervaz } from 'services/api/form';
import { getTags } from 'services/api/general/tag';
import Grid from '@mui/material/Grid';
import FormSelect from '../FormComponents/FormSelect';
import EmptySpace from '../FormComponents/EmptySpace';
import FormTextFieldTitle from '../FormComponents/FormTextFieldTitle';
import FormSwitch from '../FormComponents/FormSwitch';
import OutlinedFormTextField from '../FormComponents/OutlinedFormTextField';
import { validationSchemaUPervaz } from '../FormComponents/YupControlSchema';
import AaroLoadingScreen from 'components/Loading';

const useStyles = makeStyles((theme) => ({
    switch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) =>
        rp.data?.result?.tags ? rp.data?.result?.tags : []
    );
    return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.DEMAND_STATUS_TAG_GROUP_ID;
export const UPervaz = (props) => {
    const queryClient = useQueryClient();
    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(
        ['fetchTagGroups', TAG_GROUP_ID_DEMAND],
        () => fetchTags(TAG_GROUP_ID_DEMAND),
        { staleTime: 600000 }
    );
    const user = JSON.parse(localStorage.getItem('user'));
    const [disableOnSubmit, setDisableOnSubmit] = React.useState(false);
    const classes = useStyles();
    const { demandID, initial, id, setOpen, projectID } = props;

    const formik = useFormik({
        initialValues: {
            baslik: initial?.baslik || 'U Pervaz',
            miktar: initial?.miktar || null,
            kalinlik: initial?.kalinlik || null,
            en: initial?.en || null,
            boy: initial?.boy || null,
            mdfKalinlik: initial?.mdfKalinlik || 0.6,
            cins: initial?.cins || '',
            cinsAciklama: initial?.cinsAciklama || '',
            yapi: initial?.yapi || 'MDF',
            gecmeKalinlik: initial?.gecmeKalinlik || 0.4,
            gecmeIctenUzunluk: initial?.gecmeIctenUzunluk || 2.5,
            hamZimpara: initial?.hamZimpara || false,
            boyKesmeKertme: initial?.boyKesmeKertme || false,
            ozelProfilTipi: initial?.ozelProfilTipi || 'Standart',
            ozelProfilAciklama: initial?.ozelProfilAciklama || '',
            ekNot: initial?.ekNot || '',
            indirimOrani: initial?.indirimOrani || 0,
            yoneticiNotu: initial?.yoneticiNotu || '',
            verilenFiyat: initial?.verilenFiyat || null,
            durumu: initial?.durumu || '',
        },
        validationSchema: validationSchemaUPervaz,
        onSubmit: (values) => {
            setDisableOnSubmit(true);
            if (id) {
                updateUPervaz(id, values)
                    .then(() => {
                        queryClient.invalidateQueries([
                            'fetchDemand',
                            demandID,
                        ]);
                        notify(true, 'Başarılı güncelleme');
                        setOpen(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        notify(false, err);
                        setDisableOnSubmit(false);
                    });
            } else {
                postUPervaz(demandID, values)
                    .then(() => {
                        queryClient.invalidateQueries([
                            'fetchDemand',
                            demandID,
                        ]);
                        notify(true, 'Başarılı ekleme');
                        setOpen(false);
                    })

                    .catch((err) => {
                        console.log(err);
                        notify(false, err);
                        setDisableOnSubmit(false);
                    });
            }
        },
    });
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    {/* Başlık */}
                    <FormTextFieldTitle
                        variant="filled"
                        fullWidth={true}
                        label="Başlık"
                        placeHolder="Örn: UPervaz Başlık"
                        formik={formik}
                        itemID="baslik"
                        disabled={true}
                        selector="uPervaz"
                    />
                    <EmptySpace height="30px" />
                    {/* Miktar - Kalınlık - En - Boy */}
                    <Grid container justifyContent="space-between" spacing={3}>
                        {/* Miktar */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="miktar"
                                label="Miktar (Adet)"
                                placeHolder="Örn: 1000"
                                type="number"
                                formik={formik}
                                step="1"
                                adornment="AD"
                            />
                        </Grid>
                        {/* Kalınlık */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="kalinlik"
                                label="Kalınlık"
                                placeHolder="Örn: 1.8"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* En */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="en"
                                label="En"
                                placeHolder="Örn: 9"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* Boy */}
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedFormTextField
                                itemID="boy"
                                label="Boy"
                                placeHolder="Örn: 219"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                    </Grid>
                    {/* Geçme Kalınlık - Geçme İçten Uzunluk */}
                    <Grid container justifyContent="space-between" spacing={3}>
                        {/* MDF Kalınlık */}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="mdfKalinlik"
                                label="MDF Kalınlık (cm)"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* Geçme Kalınlık */}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="gecmeKalinlik"
                                label="Geçme Kalınlık (cm)"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                        {/* Geçme İçten Uzunluk*/}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="gecmeIctenUzunluk"
                                label="Geçme İçten Uzunluk (cm)"
                                type="number"
                                formik={formik}
                                step="0.1"
                                adornment="CM"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Cins - Yapı - Özel Profil Tipi - Özel Profil Tipi Açıklaması*/}
                <Grid item xs={6}>
                    {/* Cins */}
                    <FormSelect
                        itemID="cins"
                        formik={formik}
                        inputName="Cins"
                        items={[
                            {
                                name: 'Lakelik',
                                value: 'Lakelik',
                            },
                            {
                                name: 'Desenli',
                                value: 'Desenli',
                            },
                            {
                                name: 'Kaplamalı Alpi',
                                value: 'Kaplamalı Alpi',
                            },
                            {
                                name: 'Kaplamalı Doğal',
                                value: 'Kaplamalı Doğal',
                            },
                            {
                                name: 'Kaplama Müşteriden',
                                value: 'Kaplama Müşteriden',
                            },
                            {
                                name: 'Özel Kaplama Doğal',
                                value: 'Özel Kaplama Doğal',
                            },
                            {
                                name: 'Özel Kaplama Alpi',
                                value: 'Özel Kaplama Alpi',
                            },
                        ]}
                        helperText={'UPervaz cinsi'}
                    />

                    {/*Özel Profil Tipi */}
                    <FormSelect
                        itemID="ozelProfilTipi"
                        formik={formik}
                        inputName="Profil Tipi"
                        items={[
                            {
                                name: 'Standart',
                                value: 'Standart',
                            },
                            {
                                name: 'Farklı Radüsler',
                                value: 'Farklı Radüsler',
                            },
                            {
                                name: 'Özel Profil',
                                value: 'Özel Profil',
                            },
                            {
                                name: 'Özel Profil + Bıçak',
                                value: 'Özel Profil + Bıçak',
                            },
                        ]}
                        helperText={'U Pervaz Profil Tipi'}
                    />
                    {/* Yapı */}
                    <FormSelect
                        itemID="yapi"
                        formik={formik}
                        inputName="Yapı"
                        items={[
                            {
                                name: 'MDF',
                                value: 'MDF',
                            },
                            {
                                name: 'Yumuşak Kontrplak',
                                value: 'Yumuşak Kontrplak',
                            },
                            {
                                name: 'Sert Kontrplak',
                                value: 'Sert Kontrplak',
                            },
                        ]}
                        helperText={'UPervaz Yapısı'}
                    />
                </Grid>
                {/* Cins Açıklaması  - Köşeli Bombeli*/}
                <Grid item xs={6}>
                    {/* Cins Açıklaması */}
                    <OutlinedFormTextField
                        itemID="cinsAciklama"
                        label="Cins Açıklaması"
                        placeHolder="Ek Cins Açıklaması"
                        formik={formik}
                        adornment=""
                    />

                    {/* Özel Profil Tipi Açıklaması*/}
                    <OutlinedFormTextField
                        itemID="ozelProfilAciklama"
                        label="U Pervaz Profil Tipi Açıklaması"
                        placeHolder="Örn: Cnc deseni isteniyor"
                        formik={formik}
                        adornment=""
                    />
                </Grid>
                {/* Ek Not */}
                <Grid item xs={12}>
                    {/* Ek Not */}
                    <OutlinedFormTextField
                        itemID="ekNot"
                        label="Ek Notlarınız"
                        placeHolder="Örn: Kendi bıçaklarını verecekler"
                        formik={formik}
                    />
                </Grid>
                {/* Var Yok Seçenekleri */}
                <Grid container className={classes.switch}>
                    {/* Duman Fitili*/}
                    <FormSwitch
                        formik={formik}
                        name="hamZimpara"
                        color="secondary"
                        label="Ham Zımpara"
                    />
                    {/* 45 Kesim*/}
                    <FormSwitch
                        formik={formik}
                        name="boyKesmeKertme"
                        color="secondary"
                        label="Boy Kesme ve Kertme"
                    />
                </Grid>
                {/* Yönetici Alanı*/}
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                        {/* Yönetici Notu*/}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="yoneticiNotu"
                                label="Yöneticinin Notu"
                                placeHolder="Yönetici Notu"
                                formik={formik}
                                disabled={user.role != 'superadmin'}
                            />
                        </Grid>
                        {/* Verilen Fiyat */}
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedFormTextField
                                itemID="verilenFiyat"
                                label="Birim Fiyat"
                                placeHolder="Birim Fiyat"
                                formik={formik}
                                step="0.01"
                                type="number"
                                // disabled={user.role != 'superadmin'}
                            />
                        </Grid>

                        {/* Teklif Durumu */}
                        <Grid item xs={12} sm={12} md={4}>
                            {/* Girinti */}
                            <FormSelect
                                itemID="durumu"
                                formik={formik}
                                inputName="Talep Durumu"
                                items={tags.map((tag) => ({
                                    value: tag._id,
                                    name: tag.name,
                                }))}
                                helperText={'Talebin durumunu seçin'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <MDButton
                        color="dark"
                        variant="gradient"
                        fullWidth
                        type="submit"
                        disabled={disableOnSubmit}
                    >
                        Kaydet
                    </MDButton>
                </Grid>
            </Grid>
        </form>
    );
};
