import Cookies from 'js-cookie';
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { Token, Aaro } from 'aaro';
import { login } from 'services/api/user';
import { UserContext } from 'App';
import { config } from 'Constants';

function Basic() {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || '/';
    const [rememberMe, setRememberMe] = useState(false);
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const { user, setUser } = useContext(UserContext);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        localStorage.clear();
    }, []);

    const tryLogin = async () => {
        const info = {
            username: userName.replace('@kahramanlar.com.tr', ''),
            password: password,
        };
        setDisabled(true);
        let resp;
        try {
            resp = await login(info);
        } catch {
            setError('Hatalı giriş');
            setPassword('');
            setDisabled(false);
            return;
        }

        const url = config.url.AARO_BASE;
        let aaroBearerToken;
        try {
            const tokenResponse = await Token(url, addKahramanlar(userName), password);
            aaroBearerToken = tokenResponse.data.access_token;
        } catch (err) {
            // AARO'dan token alınamaması durumunda,
            // error'u log'layabilir ve yine de devam edebiliriz.
            console.error('AARO token could not be retrieved:', err.message);
            aaroBearerToken = null; // veya aaroBearerToken'ı undefined olarak bırakabiliriz.
        }

        try {
            // dummy request for starting aaro
            const aaro = new Aaro({
                baseUrl: config.url.AARO_BASE,
                accessToken: aaroBearerToken,
            });

            aaro.get('Kullanicilar', { EsnekAramaKisiti: info.username }).then((rp) =>
                localStorage.setItem('AaroKullanici', rp?.data?.Model ? JSON.stringify(rp?.data?.Model[0]) : {})
            );
        } catch (err) {
            // AARO'dan token alınamaması durumunda,
            // error'u log'layabilir ve yine de devam edebiliriz.
            console.error('AARO dummy couldnt be retrieved:', err.message);
        }

        resp.data.aaroToken = aaroBearerToken;
        localStorage.clear();
        localStorage.setItem('user', JSON.stringify(resp.data));
        Cookies.set('aaroUser', addKahramanlar(userName), {
            expires: 7,
            domain: config.url.DOMAIN,
        });
        Cookies.set('aaroPass', password, {
            expires: 7,
            domain: config.url.DOMAIN,
        });
    };

    async function handleSubmit(event) {
        event.preventDefault();
        await tryLogin();

        // AARO işlemi başarısız olsa bile, şifre doğruysa kullanıcının giriş yapabilmesi
        // Burada user.loggedIn'ı doğrudan true olarak ayarlıyoruz.
        if (true) {
            // Bu kısmı şifrenin doğru olup olmadığını kontrol etmek için güncelleyin
            setUser({ loggedIn: true, username: userName, password });
            if (location.state?.from) {
                navigate(location.state.from);
            } else navigate('/dashboards/analytics');
        }
    }

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Kahramanlar A.Ş.
                    </MDTypography>
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                <FacebookIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                <GitHubIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                <GoogleIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" onSubmit={handleSubmit}>
                        <MDBox mb={2}>
                            <MDInput
                                type="text"
                                label="Kullanıcı Adı"
                                fullWidth
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                required={true}
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                type="password"
                                label="Şifre"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required={true}
                                fullWidth
                            />
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={handleSetRememberMe}
                                sx={{
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    ml: -1,
                                }}
                            >
                                &nbsp;&nbsp;Beni Hatırla
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={disabled}>
                                GİRİŞ
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <MDTypography
                                    component={Link}
                                    to="#"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    AARO
                                </MDTypography>{' '}
                                hesabınız ile giriş yapmalısınız.
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

const addKahramanlar = (username) => {
    if (!username.includes('@')) {
        return username + '@kahramanlar.com.tr';
    }
    return username;
};

export default Basic;
