// Libraries
import { Link } from 'react-router-dom';

import MDButton from 'components/MDButton';
import { getDemandStatusColor } from 'helpers/colorHelpers';

export default function DemandStatus({ id, formattedValue }) {
    const style = {
        minWidth: '17em',
    };
    return (
        <MDButton
            variant="outlined"
            fullWidth
            color={getDemandStatusColor(formattedValue)}
            size="small"
            component={Link}
            to={`../projects/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={style}
        >
            {formattedValue}
        </MDButton>
    );
}
