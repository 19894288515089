import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc  it retrieves all the products
 * @return promise - axios returns a promise
 */
export const getProducts = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/products`;
	return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the products
 * @return promise - axios returns a promise
 */
export const getProduct = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/products/${id}`;
	return axios.get(url, { headers });
};

/**
 * @desc  it updates the project
 * @return promise - axios returns a promise
 */
export const updateProduct = (id, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/products/${id}`;
	return axios.put(url, data, { headers });
};

/**
 * @desc  it deletes the project
 * @return promise - axios returns a promise
 */
export const deleteProduct = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/products/${id}`;
	return axios.delete(url, { headers });
};

/**
 * @desc  it updates given customer
 * @return promise - axios returns a promise
 */
export const postProduct = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/products`;
	return axios.post(url, data, { headers });
};
