import React from 'react';

import Popover from '@mui/material/Popover';
import ListDetailPopover from 'layouts/todo/components/TaskLists/ListDetail/Popover';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { getTasksComplex } from 'services/api/general/task';
import { useQuery } from 'react-query';
import TaskLoadingGIF from 'components/Loading/TaskLoadingGIF.js';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MDProgress from 'components/MDProgress';
import ListAvatar from 'layouts/todo/components/TaskLists/ListDetail/Avatar/index.js';
// import LinearProgress from '@mui/material/LinearProgress';

const fetchTasks = async (taskListID, taskID) => {
    const searchQuery = {
        $and: [
            {
                taskList: {
                    $eq: taskListID,
                },
            },
            {
                parent: {
                    $eq: taskID,
                },
            },
        ],
    };

    const response = await getTasksComplex(searchQuery)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

export default function ListDetail({ listData, openDialog, setActiveList, activeList }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
        order,
        shared,
        favorite,
        tags,
        sharedWith,
        name,
        description,
        relatedID,
        _id: taskListID,
        createdBy,
        createdAt,
        updatedAt,
    } = listData;

    const {
        data: taskData,
        isLoading,
        error,
        isFetching,
    } = useQuery(['fetchTasks', taskListID, null], () => fetchTasks(taskListID, null), {
        refetchOnWindowFocus: false,
        staleTime: 6000000,
    });

    if (isLoading) return <TaskLoadingGIF />;
    if (isFetching) return <TaskLoadingGIF />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const completedTasksNumber = taskData.filter((el) => el.completed).length;
    const allTasksNumber = taskData.length;
    const ratio = allTasksNumber !== 0 ? (completedTasksNumber / allTasksNumber) * 100 : 0;
    const user = JSON.parse(localStorage.getItem('user'));

    const isSameUser = user.userID === createdBy._id;
    const isAdmin = user.role === 'superadmin';
    return (
        <MDBox key={taskListID}>
            <MDBox
                component="li"
                display="flex"
                alignItems="center"
                py={1}
                mb={1}
                onDoubleClick={() =>
                    (isSameUser || isAdmin) &&
                    openDialog('update', {
                        _id: taskListID,
                        relatedID,
                        order,
                        shared: shared,
                        favorite,
                        tags,
                        sharedWith,
                        name,
                        description,
                        createdBy,
                        createdAt,
                        updatedAt,
                    })
                }
            >
                <MDBox mr={2}>
                    <MDBox onMouseEnter={(e) => handlePopoverOpen(e)} onMouseLeave={() => handlePopoverClose()}>
                        <ListAvatar
                            taskData={taskData}
                            createdBy={createdBy}
                            isFavorite={favorite}
                            isSelectedList={activeList === taskListID}
                        />
                        <Popover
                            id={taskListID + 'mouse-popover'}
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <ListDetailPopover listData={listData} taskData={taskData} />
                        </Popover>
                    </MDBox>
                </MDBox>

                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    width="100%"
                >
                    <MDBox
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '80%', // istediğiniz bir genişlik
                        }}
                    >
                        <MDTypography
                            variant="button"
                            fontWeight="medium"
                            // textTransform={activeList === taskListID ? 'uppercase' : 'lowercase'}
                        >
                            {name}
                        </MDTypography>
                    </MDBox>

                    <MDBox width="100%">
                        <MDProgress variant="gradient" value={ratio} color="success" />
                    </MDBox>
                </MDBox>
                <MDBox ml="auto">
                    <IconButton
                        color={'dark'}
                        aria-label="retrieve task lists"
                        onClick={() => setActiveList(taskListID)}
                    >
                        <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                </MDBox>
            </MDBox>
        </MDBox>
    );
}
