import React, { useState } from 'react';
import { AppBar, Toolbar, InputBase, IconButton } from '@mui/material';
import { Search, FilterList, Add, FilterListOff } from '@mui/icons-material';
import MDButton from 'components/MDButton';
import ProjectCreation from 'layouts/projects/Components/ProjectCreation';
import useDialogHook from 'hooks/useDialogHook';
import FilterDialog from 'layouts/projects/Pages/All/SearchBar/filterDialog';
import { useSearch } from 'layouts/projects/Pages/SearchProvider';

function AdvancedSearch() {
    // Use the useDialogHook to handle opening and closing of the FilterDialog
    const { open, handleClickOpen, handleClose } = useDialogHook();

    // Use useState to handle the ProjectCreation component visibility
    const [newProject, setNewProject] = useState(false);

    // Destructure the required properties and methods from useSearch
    const {
        searchProjectQuery,
        updateSearchProjectQuery,
        searchCustomerQuery,
        updateSearchCustomerQuery,
        resetFilters,
    } = useSearch();

    return (
        <AppBar position="static">
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Input field for searching */}
                {/* Clear Filter button */}
                <IconButton color="inherit" onClick={resetFilters}>
                    <FilterListOff />
                </IconButton>
                <InputBase
                    placeholder="Projelerde Ara..."
                    value={searchProjectQuery}
                    onChange={(e) => updateSearchProjectQuery(e.target.value)}
                    inputProps={{ type: 'string' }}
                    sx={{
                        flexGrow: 1,
                        background: 'linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 100%)',
                        borderRadius: '4px',
                        padding: '8px 14px',
                    }}
                />
                &nbsp; &nbsp;
                <InputBase
                    placeholder="Müşterilerde Ara..."
                    value={searchCustomerQuery}
                    onChange={(e) => updateSearchCustomerQuery(e.target.value)}
                    inputProps={{ type: 'string' }}
                    sx={{
                        flexGrow: 1,
                        background: 'linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 100%)',
                        borderRadius: '4px',
                        padding: '8px 14px',
                    }}
                />
                {/* Search button */}
                <IconButton color="inherit">
                    <Search />
                </IconButton>
                {/* Filter button */}
                <IconButton color="inherit" onClick={handleClickOpen}>
                    <FilterList />
                </IconButton>
                {/* New project button */}
                <MDButton
                    startIcon={<Add />}
                    variant="contained"
                    color="light"
                    onClick={() => setNewProject(!newProject)}
                >
                    Yeni Proje
                </MDButton>
            </Toolbar>
            {/* Render FilterDialog if it's open */}
            {open && <FilterDialog open={open} handleClose={handleClose} />}
            {/* Render ProjectCreation if newProject is true */}
            {newProject && <ProjectCreation />}
        </AppBar>
    );
}

export default AdvancedSearch;
