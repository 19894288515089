// Libraries
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Layout
import Layout from 'layouts/lead/pages/singleLead/Layout.js';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// @mui material components
import Grid from '@mui/material/Grid';

import MainPage from 'layouts/lead/pages/singleLead/Sections/Main/index.js';
import AaroLoadingScreen from 'components/Loading';
import Chat from 'components/General/Chat';
import FileView from 'components/General/File';
import TaskListModalView from 'components/General/Task/Views/TaskList/ModalView';

// API
import { config } from 'Constants';
import { Divider } from '@mui/material';
import { getLeads } from 'services/api/lead';
import Position from 'layouts/lead/components/Position';

import Tags from 'layouts/lead/components/Tags';
import MDButton from 'components/MDButton';

import useDialogHook from 'hooks/useDialogHook';
import EditLead from 'layouts/lead/forms/EditLead.js';
import { notify } from 'helpers/notificationHelper';
import { deleteLead } from 'services/api/lead';

const fetchLeads = async (leadID) => {
    const searchQuery = {
        _id: leadID,
    };

    const response = await getLeads(searchQuery)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const { CUSTOMER_INTERESTS_GROUP_ID, CUSTOMER_SOURCE_GROUP_ID, LEAD_CATEGORY_GROUP_ID } = config.fixedID;

function SingleLeadPage({ incomingID = null, isModal = false }) {
    const { open, handleClickOpen, handleClose } = useDialogHook();

    let leadID = '';
    const { id } = useParams();

    if (incomingID) {
        leadID = incomingID;
    } else {
        leadID = id;
    }
    const [value, setValue] = useState(0);
    const [updateFiles, setUpdateFiles] = useState(0);

    const { data, isLoading, error, isFetching } = useQuery(['fetchLeads', leadID], () => fetchLeads(leadID), {
        refetchOnWindowFocus: false,
    });

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const lead = data[0] ? data[0] : null;
    if (!lead) {
        return <h1>Lead Bulunamadı</h1>;
    }

    const handlePageChanges = (pageNumber) => {
        switch (pageNumber) {
            case 0:
                return (
                    <MainPage
                        lead={lead}
                        relatedID={leadID}
                        updateFiles={updateFiles}
                        setUpdateFiles={setUpdateFiles}
                    />
                );
            // case 1:
            //     return <DemandPage project={data} />;
            // // case 2:
            // // 	return <Kanban project={data} />;
            // case 2:
            //     return (
            //         <MDBox mt={4}>
            //             <OfferPage project={data} setValue={setValue} />
            //         </MDBox>
            //     );
            // case 3:
            //     return <OfferHistoryPage project={data} />;
            // case 4:
            //     return <SettingsPage project={data} />;
            default:
                return <p>something went wrong on singleLead/index.js</p>;
        }
    };

    return (
        <Layout setValue={setValue} isModal={isModal}>
            <MDBox mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={value === 2 ? 12 : 9}>
                        <MDBox mb={3}>{handlePageChanges(value)}</MDBox>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <MDBox mb={3} mt={4}>
                            <Chat relatedID={leadID} source="leads" />
                        </MDBox>
                        <MDBox mb={3} bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1}>
                            <Position lead={lead} />
                        </MDBox>
                        <MDBox
                            mb={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                            p={1}
                        >
                            <>
                                <Tags
                                    lead={lead}
                                    tagLabel={'Hangi Aşamada'}
                                    tagGroupID={LEAD_CATEGORY_GROUP_ID}
                                    multiple={false}
                                    dbKey={'category'}
                                />
                                <Divider />
                                <Tags
                                    lead={lead}
                                    tagLabel={'İlgilendiği Ürünler'}
                                    tagGroupID={CUSTOMER_INTERESTS_GROUP_ID}
                                    multiple={true}
                                    dbKey={'interests'}
                                />
                                <Divider />
                                <Tags
                                    lead={lead}
                                    tagLabel={'Kaynak'}
                                    tagGroupID={CUSTOMER_SOURCE_GROUP_ID}
                                    multiple={false}
                                    dbKey={'source'}
                                />
                                <Divider />
                            </>
                        </MDBox>
                        {lead?.taskList && (
                            <MDBox mb={3} bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1}>
                                <TaskListModalView
                                    taskListID={lead?.taskList}
                                    taskID={null}
                                    url={`${config.url.ENV_URL}/leads/${leadID}`}
                                />
                            </MDBox>
                        )}

                        <MDBox mb={3}>
                            <FileView relatedID={leadID} updateFiles={updateFiles} setUpdateFiles={setUpdateFiles} />
                        </MDBox>
                        <MDBox mb={3} display="flex">
                            <MDButton color="info" variant="gradient" sx={{ width: '100%' }} onClick={handleClickOpen}>
                                Düzenle
                            </MDButton>
                            <MDButton
                                color="primary"
                                variant="gradient"
                                sx={{ width: '100%' }}
                                onClick={() => {
                                    const result = window.confirm('Silmek İstediğinize Emin Misiniz?');
                                    if (result)
                                        deleteLead(leadID)
                                            .then((rp) => {
                                                notify(rp.data.success, rp.data.message);
                                                window.location.reload();
                                            })
                                            .catch((err) => {
                                                notify(false, err.message);
                                            });
                                }}
                            >
                                SİL
                            </MDButton>
                        </MDBox>
                        {open && <EditLead open={open} handleClose={handleClose} initial={lead} />}
                    </Grid>
                </Grid>
            </MDBox>
        </Layout>
    );
}

export default SingleLeadPage;
