// separation for dateTime
export const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

export const formatDateHours = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

export const formatDateMinutes = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
};

/**
 * @desc for given city("TR01") it retrieves name of city
 * @return color in material pro
 */
export const cityFinder = (city) => {
    const cities = [
        'Adana',
        'Adıyaman',
        'Afyon',
        'Ağrı',
        'Amasya',
        'Ankara',
        'Antalya',
        'Artvin',
        'Aydın',
        'Balıkesir',
        'Bilecik',
        'Bingöl',
        'Bitlis',
        'Bolu',
        'Burdur',
        'Bursa',
        'Çanakkale',
        'Çankırı',
        'Çorum',
        'Denizli',
        'Diyarbakır',
        'Edirne',
        'Elazığ',
        'Erzincan',
        'Erzurum',
        'Eskişehir',
        'Gaziantep',
        'Giresun',
        'Gümüşhane',
        'Hakkari',
        'Hatay',
        'Isparta',
        'İçel (Mersin)',
        'İstanbul',
        'İzmir',
        'Kars',
        'Kastamonu',
        'Kayseri',
        'Kırklareli',
        'Kırşehir',
        'Kocaeli',
        'Konya',
        'Kütahya',
        'Malatya',
        'Manisa',
        'Kahramanmaraş',
        'Mardin',
        'Muğla',
        'Muş',
        'Nevşehir',
        'Niğde',
        'Ordu',
        'Rize',
        'Sakarya',
        'Samsun',
        'Siirt',
        'Sinop',
        'Sivas',
        'Tekirdağ',
        'Tokat',
        'Trabzon',
        'Tunceli',
        'Şanlıurfa',
        'Uşak',
        'Van',
        'Yozgat',
        'Zonguldak',
        'Aksaray',
        'Bayburt',
        'Karaman',
        'Kırıkkale',
        'Batman',
        'Şırnak',
        'Bartın',
        'Ardahan',
        'Iğdır',
        'Yalova',
        'Karabük',
        'Kilis',
        'Osmaniye',
        'Düzce',
    ];
    const nameReduced = city.substring(2, city.length);
    return cities[parseInt(nameReduced)];
};

/**
 * @desc for given number it translates to correct form
 * @return well formatted number
 */
export const normalizePhoneNumber = (number) => {
    number = number.replace(' ', '').trim();
    const numberLength = number.length;
    const lastTenChar = number.substring(numberLength - 11, numberLength);
    const turkishNumberValue = '90' + lastTenChar;

    return turkishNumberValue;
};

/**
 * Yardımcı fonksiyon: virgül ve nokta içeren string sayıları parse eder.
 *
 * @param {string} str - Sayı olarak parse edilmek istenen string.
 * @returns {number|string} - Başarıyla parse edilmiş bir sayı veya parse edilemezse orijinal string.
 *
 * Bu fonksiyon, virgül ve nokta gibi ayırıcılar içeren string sayıları parse eder.
 * Örneğin, "1.234,56" gibi bir stringi 1234.56 float sayısına dönüştürür.
 * Eğer string sadece nokta içeriyorsa, bu fonksiyon yine doğru şekilde sayıyı parse eder.
 */

export const parseStringNumber = (str) => {
    if (typeof str === 'string') {
        // Eğer str sadece sayılar ve opsiyonel olarak virgül veya nokta içeriyorsa
        if (/^[0-9,.]+$/.test(str)) {
            // Eğer str'de virgül varsa ve bu virgül ondalık ayırıcı ise
            if (str.indexOf(',') > str.indexOf('.')) {
                // Binlik ayırıcıları kaldır ve ondalık ayırıcıyı normalleştir
                const normalizedStr = str.replace(/\./g, '').replace(',', '.');
                // Stringi float sayısı olarak parse et
                const number = parseFloat(normalizedStr);
                return isNaN(number) ? str : number;
            } else {
                // Eğer str'de sadece bir virgül varsa ve bu ondalık ayırıcı değilse
                const normalizedStr = str.replace(/,/g, '');
                const number = parseFloat(normalizedStr);
                return isNaN(number) ? str : number;
            }
        }
    }
    return str;
};

/**
 * @desc Checks if the current device is a mobile device based on screen width
 * @return {boolean} - Returns true if the screen width is less than or equal to 768 pixels, otherwise false
 */
export const isMobile = () => {
    return window.innerWidth <= 768;
};

// Telefon numarasını formatlamak için yardımcı fonksiyon
export const formatPhoneNumber = (phone) => {
    if (!phone) return '';

    // Sadece rakamları al
    const numbers = phone.replace(/\D/g, '');

    // Başındaki 0 veya 90'ı kaldır
    const cleanNumber = numbers.replace(/^0|^90/, '');

    // Başına +90 ekle
    return `+90${cleanNumber}`;
};
