import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import { useMaterialUIController, setLayout } from 'context';
import useWindowDimensions from 'hooks/useWindowDimensions';

const APLayout = ({ children }) => {
    const [controller, dispatch] = useMaterialUIController();
    const { width } = useWindowDimensions();
    const { miniSidenav } = controller;
    const { pathname } = useLocation();

    useEffect(() => {
        setLayout(dispatch, 'dashboard');
    }, [dispatch, pathname]);

    return (
        <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                p: width <= 600 ? 0 : 3,
                position: 'relative',
                [breakpoints.up('xl')]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(['margin-left', 'margin-right'], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
                background: '#fff',
            })}
        >
            {children}
        </MDBox>
    );
};

APLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default APLayout;
