import axios from 'axios';
import { config } from 'Constants';

const url = `${config.url.API_URL}/api/kahramanlar/product-suggestions`;

/**
 * @desc  It retrieves all the product suggestions
 * @return promise - axios returns a promise
 */
export const getProductSuggestions = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    return axios.get(url, { headers });
};

/**
 * @desc  It retrieves a single product suggestion
 * @param {String} id - unique identifier for product suggestion
 * @return promise - axios returns a promise
 */
export const getProductSuggestion = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    return axios.get(`${url}/${id}`, { headers });
};

/**
 * @desc  It posts a new product suggestion
 * @param {Object} data - data for the new product suggestion
 * @return promise - axios returns a promise
 */
export const postProductSuggestion = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    return axios.post(url, data, { headers });
};

/**
 * @desc  It updates an existing product suggestion
 * @param {String} id - unique identifier for product suggestion
 * @param {Object} data - updated data for the product suggestion
 * @return promise - axios returns a promise
 */
export const updateProductSuggestion = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    return axios.put(`${url}/${id}`, data, { headers });
};

/**
 * @desc  It deletes a product suggestion
 * @param {String} id - unique identifier for product suggestion
 * @return promise - axios returns a promise
 */
export const deleteProductSuggestion = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    return axios.delete(`${url}/${id}`, { headers });
};
