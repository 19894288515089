import * as React from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AllLeadsContext } from 'layouts/lead/pages/allLeads/index.js';

export default function CustomizedInputBase() {
    const { search, setSearch } = React.useContext(AllLeadsContext);
    const [value, setValue] = React.useState('');
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                p: '2px 4px',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                maxWidth: 400,
            }}
        >
            <TextField
                label={search}
                id="standard-size-small"
                defaultValue={search}
                size="small"
                variant="standard"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                sx={{ mb: 2.4 }}
            />
            <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => setSearch(value)}
            >
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <MenuIcon />
            </IconButton>
        </Box>
    );
}
