import * as yup from 'yup';

export const validationSchemaKanatSheetTemplate = yup.object({
    miktar: yup
        .number('Talep edilen kanat miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup
        .number('Kasa kalınlığını giriniz')
        .min(0, "kalınlık 0.1cm'den büyük olmalıdır")
        .max(9, "kalınlık 9cm'den küçük olmalıdır")
        .positive()
        .required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('Kanat enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kanat boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),

    cumbaBandi: yup.string('Talep edilen cumba bandını giriniz'),

    kilitYeri: yup.string('Yok(Y), StandartKale(S),  Topuz(T), OtelTipi(O)'),
    menteseYeri: yup.string('Yok(Y), Yönlü 2(Yprk2), Yönlü 3(Yprk3), Gömme 2 (G2), Gömme 3 (G3)'),
    hidrolik: yup.boolean(),
    giyotin: yup.boolean(),
    durbun: yup.boolean(),
    sertAgacDonme: yup.boolean(),
    camYeri: yup.string(
        'Yok(Y), 1GbSerenliCamyeri(CS1),3GbSerenliCamyeri(CS3),5GbSerenliCamyeri(CS5),1GbMdfliCamyeri(CM1),3GbMdfliCamyeri(CM3),5GbMdfliCamyeri(CM5),Giyotin(G)'
    ),
});
export const validationSchemaKasaSheetTemplate = yup.object({
    miktar: yup
        .number('Talep edilen kasa miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    en: yup.number('Kasa enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kasa boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    dumanFitili: yup.boolean().required('Duman fitili seçimi zorunludur'),
    kesim45: yup.boolean().required('45° kesim seçimi zorunludur'),
    kilitKarsiligi: yup.boolean().required('Kilit karşılığı seçimi zorunludur'),
    menteseKarsiligi: yup.boolean().required('Menteşe karşılığı seçimi zorunludur'),
    hidrolikKarsiligi: yup.boolean().required('Hidrolik karşılığı seçimi zorunludur'),
    soveMi: yup.boolean().required('Söve seçimi zorunludur'),
});

export const validationSchemaPervazSheetTemplate = yup.object({
    miktar: yup
        .number('Talep edilen pervaz miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup.number('Pervaz kalınlığını giriniz').positive().required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('Pervaz enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Pervaz boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),

    gecmeKalinlik: yup.number('Pervaz geçme kalınlığını giriniz').positive().required('Standart 0.4 cm'),
    gecmeIctenUzunluk: yup
        .number('Pervaz geçme içten uzunluğunu giriniz')
        .positive()
        .required('Yüzeydeki derz adedini giriniz.S2S04 için 3 , dikeyine tek derz için 1 yatayına tek derz için 0,5'),
    hamZimpara: yup.boolean('Ham zımpara uygulanacak'),
    boyKesmeKertme: yup.boolean('Pervaz derz tipini giriniz'),
});

export const validationSchemaUKasaSheetTemplate = yup.object({
    miktar: yup
        .number('Talep edilen u kasa miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    en: yup.number('Kasa enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kasa boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    dumanFitili: yup.boolean('Duman fitili uygulanacak mı?'),
    kesim45: yup.boolean('45° kesim yapılacak mı?'),
    kilitKarsiligi: yup.boolean('Kilit karşılığı uygulanacak mı?'),
    menteseKarsiligi: yup.boolean('Menteşe karşılığı uygulanacak mı?'),
    hidrolikKarsiligi: yup.boolean('Hidrolik karşılığı uygulanacak mı?'),
});
export const validationSchemaUPervazSheetTemplate = yup.object({
    miktar: yup
        .number('Talep edilen u-pervaz miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup.number('u-pervaz kalınlığını giriniz').positive().required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('u-pervaz enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('u-pervaz boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    mdfKalinlik: yup
        .number('u-pervaz kaç mm mdfden üretilecek')
        .positive()
        .max(5)
        .required('Lütfen geçerli bir mdf(cm) kalınlığı giriniz. Örn: 0.6 '),

    gecmeKalinlik: yup.number('u-pervaz geçme kalınlığını giriniz').positive().required('Standart 0.4 cm'),
    gecmeIctenUzunluk: yup
        .number('u-oervaz geçme içten uzunluğunu giriniz')
        .positive()
        .required('Yüzeydeki derz adedini giriniz.S2S04 için 3 , dikeyine tek derz için 1 yatayına tek derz için 0,5'),
    hamZimpara: yup.boolean('Ham zımpara uygulanacak'),
    boyKesmeKertme: yup.boolean('uPervaz derz tipini giriniz'),
});
