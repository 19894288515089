import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MDBox from 'components/MDBox';
import Card from 'layouts/lead/pages/allLeads/DraggableList/Card/index';
import { updateLead } from 'services/api/lead';
import { notify } from 'helpers/notificationHelper';
import MDTypography from 'components/MDTypography';
import useWindowDimensions from 'hooks/useWindowDimensions';
const updateLeadAndNotify = (changedItemID, updateData) => {
    updateLead(changedItemID, updateData)
        .then((rp) => notify(rp.data.success, rp.data.message))
        .catch((err) => notify(false, JSON.stringify(err)));
};

const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = source.droppableId === destination.droppableId ? sourceItems : [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);

    const changedItem = destItems[destination.index];
    const changedItemID = changedItem?.id;
    const updateData = { order: destination.index };

    if (source.droppableId !== destination.droppableId) {
        updateData.category = destination.droppableId === 'empty' ? null : destination.droppableId;
    }

    updateLeadAndNotify(changedItemID, updateData);

    setColumns({
        ...columns,
        [source.droppableId]: { ...sourceColumn, items: sourceItems },
        [destination.droppableId]: { ...destColumn, items: destItems },
    });
};

export default function Kanban({ data }) {
    const [columns, setColumns] = useState(data);
    const { height, width } = useWindowDimensions();
    const columnNumber = Object.entries(columns).length;

    return (
        <MDBox display="flex" justify="center" p={0} m={0} sx={{ width: '100%', overflowX: 'auto' }}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                {Object.entries(columns).map(([id, column], index) => (
                    <Column
                        key={id}
                        id={id}
                        column={column}
                        height={height}
                        width={width}
                        columnNumber={columnNumber}
                    />
                ))}
            </DragDropContext>
        </MDBox>
    );
}

const Column = ({ id, column, height }) => (
    <MDBox
        color="dark"
        sx={{
            background: '#f5f5f6',
            borderRadius: 1,
            flexGrow: 1, // This will allow the column to grow and fill the space
            flexBasis: 0, // Resetting the default size to 0
            minWidth: 0, // This will allow the column to shrink below its content size if necessary
            height: 'fit-content', // Adjust height to fit content or a fixed height if needed
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Optional: adds shadow for depth
        }}
        variant="gradient"
        shadow="lg"
        opacity={1}
        p={1}
        m={1}
    >
        <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" textAlign="center">
            {column.name}
        </MDTypography>

        <Droppable droppableId={id}>
            {(provided) => (
                <MDBox {...provided.droppableProps} ref={provided.innerRef} sx={{ minHeight: height / 1.1 }}>
                    {column.items.map((item, index) => (
                        <Item key={item.id} item={item} index={index} column={column} provided={provided} />
                    ))}
                    {provided.placeholder}
                </MDBox>
            )}
        </Droppable>
    </MDBox>
);

const Item = ({ item, index, column, provided }) => (
    <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
            <MDBox
                mb={1}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{ padding: 1, borderRadius: 1, ...provided.draggableProps.style }}
                bgColor={snapshot.isDragging ? 'light' : 'white'}
            >
                <Card data={item} column={column} />
            </MDBox>
        )}
    </Draggable>
);
