import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

// Anaytics dashboard components

// Data

import Skeleton from '@mui/material/Skeleton';

function LoadView() {
	const reportsBarChartData = {
		labels: ['Serhat', 'Sefa', 'Bulent', 'Tolga', 'Sinan', 'Arif'],
		datasets: { label: 'Sales', data: [12, 5, 7, 8, 7, 11] },
	};
	const sales = {
		labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		datasets: {
			label: 'Mobile apps',
			data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
		},
	};
	const tasks = {
		labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		datasets: {
			label: 'Desktop apps',
			data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
		},
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				{/* <Grid container>
					<SalesByCountry />
				</Grid> */}
				<MDBox mt={6}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={3}>
								<ReportsBarChart
									color="info"
									title={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									description={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									date="1dk önce güncellendi"
									chart={reportsBarChartData}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={3}>
								<ReportsLineChart
									color="success"
									title={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									description={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									date="1dk önce güncellendi"
									chart={sales}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={3}>
								<ReportsLineChart
									color="dark"
									title={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									description={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									date="1dk önce güncellendi"
									chart={tasks}
								/>
							</MDBox>
						</Grid>
					</Grid>
				</MDBox>
				<MDBox mt={1.5}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="weekend"
									title="Belirsiz Projeler"
									count={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									percentage={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									icon="leaderboard"
									title="Beklenen Projeler"
									count={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									percentage={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="store"
									title="Kazanılan Projeler"
									count={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									percentage={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="primary"
									icon="person_add"
									title="Kaybedilen Projeler"
									count={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
									percentage={
										<Skeleton
											animation="wave"
											height={10}
											width="100%"
										/>
									}
								/>
							</MDBox>
						</Grid>
					</Grid>
				</MDBox>
				<MDBox mt={10}></MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default LoadView;
