import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { useMutation } from 'react-query';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { updateLead } from 'services/api/lead';

import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';

export default function DayRating({ lead, readOnly = false }) {
    const queryClient = useQueryClient();

    const [rating, setRating] = useState(lead?.potential ? lead.potential : 0);
    const updateRating = useMutation(
        (rating) => updateLead(lead._id, { potential: rating }),
        {
            onSuccess: (rp) => {
                notify(rp.data.success, rp.data.message);
                queryClient.invalidateQueries(['fetchLeads', lead._id]);
            },
            onError: (error) => {
                notify(false, error);
            },
        }
    );
    const StyledRating = withStyles({
        iconFilled: {
            color: '#ffb400',
        },
        iconHover: {
            color: '#ffb400',
        },
    })(Rating);
    return (
        <StyledRating
            name="day-rating"
            // defaultValue={2}
            sx={{
                fontSize: '1rem',
            }}
            value={rating}
            readOnly={readOnly}
            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
            precision={1}
            max={5}
            icon={<StarIcon fontSize="inherit" />}
            onChange={(event, newValue) => {
                event.preventDefault();
                updateRating.mutate(newValue);
                setRating(newValue);
            }}
        />
    );
}
