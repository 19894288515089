import Cookies from 'js-cookie';
import axios from 'axios';
import { Token } from 'aaro';
import { config } from 'Constants';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.log(error.toJSON());

        if (error.response && error.response.status === 401) {
            const url = config.url.AARO_BASE;

            try {
                const oldData = JSON.parse(localStorage.getItem('user')) || {};
                if (oldData.token != null) {
                    const rp = await Token(url, Cookies.get('aaroUser'), Cookies.get('aaroPass'));
                    const newToken = rp.data.access_token;
                    const newData = { ...oldData, aaroToken: newToken };
                    localStorage.setItem('user', JSON.stringify(newData));
                }
            } catch (err) {
                console.error('Token alınamadı', err);
            }
        }

        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }

        return Promise.reject(error.message);
    }
);

export default axios;
