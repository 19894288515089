import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import StockMovementsReportForm from 'layouts/reports/stockMovements/FilterForm';
import Card from '@mui/material/Card';
import DonutChartKod1 from 'layouts/reports/stockMovements/Charts/DonutKod1.js';
import DonutChartKod2 from 'layouts/reports/stockMovements/Charts/DonutKod2.js';
import DonutChartKod3 from 'layouts/reports/stockMovements/Charts/DonutKod3.js';
import FlowKod1 from 'layouts/reports/stockMovements/Charts/FlowKod1.js';

function StockMovementsReport() {
    const [fetchedData, setFetchedData] = useState(null);

    const handleDataFetched = (data) => {
        setFetchedData(data); // Formdan gelen veriyi state'e kaydediyoruz
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <Card sx={{ height: '100%' }}>
                    <StockMovementsReportForm onDataFetched={handleDataFetched} />
                </Card>

                <Card sx={{ height: '100%' }}>
                    {fetchedData && (
                        <Grid container spacing={3}>
                            {/* <Grid item xs={4}>
                                <MDBox>
                                    <DonutChartKod1 data={fetchedData} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox>
                                    <DonutChartKod2 data={fetchedData} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox>
                                    <DonutChartKod3 data={fetchedData} />
                                </MDBox>
                            </Grid> */}

                            <Grid item xs={12}>
                                <MDBox sx={{ overflowX: 'auto' }}>
                                    {' '}
                                    {/* Yatay kaydırma için */}
                                    <FlowKod1 data={fetchedData} />
                                </MDBox>
                            </Grid>
                        </Grid>
                    )}
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default StockMovementsReport;
