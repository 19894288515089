/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';
// @mui/material components
// @mui/icons-material

import VisibilityIcon from '@mui/icons-material/Visibility';
// core components

import Button from '@mui/material/Button';

// import ReactTable from 'components/ReactTable/ReactTable.js';

import { formatDate } from 'helpers/smallCodes';
import { getCustomerSales } from 'services/outside/aaro/customer';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Billing page components
import Invoice from 'layouts/pages/account/billing/components/Invoice';
const fetchAaroCustomerSales = async (customerID, buttonStyle) => {
    const response = await getCustomerSales(customerID).then((response) =>
        response
            .map((el) => ({
                key: el.HareketID,
                id: el.HareketID,
                product: el.KartAdi,
                seller: el.OlsAdi,
                total: parseInt(el.Tutar) + '₺',
                date: el.Tarih,
                DekontID: el.DekontID,
                HareketID: el.HareketID,
                // aaro: (
                // 	// we've added some custom button actions
                // 	<div className="actions-right">
                // 		{/* use this button to add a like kind of action */}
                // 		<a
                // 			rel="noopener noreferrer"
                // 			href={`https://erp2.aaro.com.tr/FaturaSatisFaturasi/Ozet/${el.DekontID}?TipID=SatisFaturasi&SayfaKayitTipi=Tanimsiz&HareketID=${el.HareketID}`}
                // 			target="_blank"
                // 		>
                // 			{buttonStyle ? (
                // 				<Button
                // 					round
                // 					size="sm"
                // 					justIcon
                // 					color="info"
                // 					style={buttonStyle}
                // 				>
                // 					<VisibilityIcon />
                // 				</Button>
                // 			) : (
                // 				<Button round size="sm" justIcon color="info">
                // 					<VisibilityIcon />
                // 				</Button>
                // 			)}
                // 		</a>
                // 	</div>
                // ),
            }))
            .sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            })
            .map((el) => ({ ...el, date: formatDate(el.date) }))
    );
    return response;
};

export default function Sales(props) {
    const { customerID, buttonStyle, initialRows } = props;

    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchAaroCustomerSales', customerID, buttonStyle],
        () => fetchAaroCustomerSales(customerID, buttonStyle),
        { staleTime: 6000000 }
    );

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    return (
        <>
            {isFetching ? (
                'Güncelleniyor'
            ) : (
                <Card sx={{ height: '100%', width: '100%' }}>
                    <MDBox
                        pt={2}
                        px={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <MDTypography variant="h6" fontWeight="medium">
                            Son Satışlar
                        </MDTypography>

                        <a
                            rel="noopener noreferrer"
                            href={`https://erp2.aaro.com.tr/CariHareketleri/Liste?CariID=${customerID}&DevirGetir=true&TipID=10005`}
                            target="_blank"
                        >
                            <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                            >
                                tamamı
                            </MDButton>
                        </a>
                    </MDBox>
                    <MDBox p={2}>
                        <MDBox
                            component="ul"
                            display="flex"
                            flexDirection="column"
                            p={0}
                            m={0}
                        >
                            {data.slice(0, 5).map((el) => (
                                <a
                                    rel="noopener noreferrer"
                                    href={`https://erp2.aaro.com.tr/FaturaSatisFaturasi/Ozet/${el.DekontID}?TipID=SatisFaturasi&SayfaKayitTipi=Tanimsiz&HareketID=${el.HareketID}`}
                                    target="_blank"
                                    key={el.HareketID}
                                >
                                    <Invoice
                                        date={el.date}
                                        id={el.seller}
                                        price={el.total}
                                    />
                                </a>
                            ))}
                            {/* 
							<Invoice
								date="March, 01, 2019"
								id="#AR-803481"
								price="$300"
								noGutter
							/> */}
                        </MDBox>
                    </MDBox>
                </Card>
            )}
        </>
    );
}
