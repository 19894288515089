/**
 * @desc for given priority it returns related stuff
 * @return objects
 */
export const getTaskStyle = (priority, completed) => {
	if (completed) return { color: 'success', icon: 'check_circle' };
	switch (priority) {
		case -1:
			return { color: 'secondary', icon: 'arrow_drop_down_circle' };
		case 1:
			return { color: 'primary', icon: 'priority_high' };
		case 2:
			return { color: 'warning', icon: 'crop' };
		case 3:
			return { color: 'info', icon: 'crop' };

		default:
			return { color: 'dark', icon: 'crop' };
	}
};
