import { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
import SingleLead from 'layouts/lead/pages/singleLead/index.js';

function AuthorAvatar({ image, name }) {
    return (
        <Tooltip key={name} title={name} placement="bottom">
            <MDAvatar
                src={image}
                alt={name}
                size="xs"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: 'pointer',
                    position: 'relative',
                    ml: -1.25,
                    '&:hover, &:focus': { zIndex: '10' },
                })}
            />
        </Tooltip>
    );
}

function DefaultProjectCard({ image, label, title, description, authors, leadID }) {
    const [openLead, setOpenLead] = useState(false);

    const renderAuthors = authors.map((author) => <AuthorAvatar key={author.name} {...author} />);

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'visible',
            }}
        >
            <CardMediaComponent image={image} title={title} />
            <CardContent
                label={label}
                title={title}
                description={description}
                renderAuthors={renderAuthors}
                setOpenLead={setOpenLead}
            />
            <ProjectDialog openLead={openLead} setOpenLead={setOpenLead} leadID={leadID} />
        </Card>
    );
}
function CardMediaComponent({ image, title }) {
    return (
        <CardMedia
            src={image}
            component="img"
            title={title}
            sx={{
                height: 150, // Sabit yükseklik
                width: '100%', // Genişliği tam genişlik olarak ayarla
                maxWidth: 300,
                minWidth: 200,
                objectFit: 'cover', // Görseli kaplayacak şekilde ayarla
                objectPosition: 'center', // Görseli ortala
                marginLeft: 'auto', // Sol boşluğu ortadan kaldır
                marginRight: 'auto', // Sağ boşluğu ortadan kaldır
            }}
        />
    );
}
function CardContent({ label, title, description, renderAuthors, setOpenLead }) {
    return (
        <MDBox mt={1} mx={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                {label}
            </MDTypography>
            <MDBox mb={1}>
                <MDTypography variant="h5" textTransform="capitalize">
                    {title}
                </MDTypography>
            </MDBox>
            <MDBox mb={3} lineHeight={0}>
                <MDTypography variant="button" fontWeight="light" color="text">
                    {description}
                </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
                <MDButton
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    size="small"
                    onClick={() => setOpenLead(true)}
                >
                    Görüntüle
                </MDButton>
                <MDBox display="flex">{renderAuthors}</MDBox>
            </MDBox>
        </MDBox>
    );
}

function ProjectDialog({ openLead, setOpenLead, leadID }) {
    return (
        <Dialog onClose={() => setOpenLead(false)} open={openLead} fullWidth={true} maxWidth="xl">
            <MDBox bgColor="light" variant="gradient">
                <SingleLead incomingID={leadID} isModal={true} />
            </MDBox>
        </Dialog>
    );
}

export default DefaultProjectCard;
