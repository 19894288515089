import React from 'react';
import { Rating } from '@mui/material';

export default function RatingComponent({ rating, updateRating }) {
    return (
        <Rating
            name="project-rating"
            defaultValue={0}
            precision={1}
            max={5}
            size="large"
            value={rating}
            onChange={(event, newValue) => {
                event.preventDefault();
                updateRating(newValue);
            }}
        />
    );
}
