import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';

function MonthlyProjects({ projects }) {
	let counterMonths = {};
	const labelsMonth = [];
	const valuesMonth = [];
	projects.forEach((project) => {
		// for user's project

		// for date's
		const month = [
			'Ocak',
			'Şubat',
			'Mart',
			'Nisan',
			'Mayıs',
			'Haziran',
			'Temmuz',
			'Ağustos',
			'Eylül',
			'Ekim',
			'Kasım',
			'Aralık',
		];
		const d = new Date(project.createdAt);
		let name = month[d.getMonth()];
		if (isNaN(counterMonths[name])) {
			counterMonths[name] = 0;
		}
		counterMonths[name] += 1;
	});

	Object.keys(counterMonths).forEach((key) => {
		labelsMonth.push(key);
		valuesMonth.push(counterMonths[key]);
	});

	const viewDataMonths = {
		labels: labelsMonth,
		datasets: { labels: 'Proje Sayısı', data: valuesMonth },
	};

	return (
		<ReportsLineChart
			color="success"
			title="Aylara Göre Projeler"
			description={'Başlangıçtan itibaren projelerin gelişi'}
			date="1dk önce güncellendi"
			chart={viewDataMonths}
		/>
	);
}

export default MonthlyProjects;
