// Libraries

// Layout

// MD2 PRO
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// @mui
import Grid from '@mui/material/Grid';

// Project Pages And Components
import ProjectName from 'layouts/projects/Components/ProjectName';
import ProjectPosition from 'layouts/projects/Components/ProjectPosition';
import ProjectResponsibles from 'layouts/projects/Components/ProjectResponsibles';
import PreviousProjects from 'layouts/projects/Components/PreviousProjects';
// import FileView from 'components/General/File';
// import AddFile from 'components/General/File/AddFile';
import AddMultiFile from 'components/General/File/MultiFile.js';
// API

// Aaro Components
import CustomerDetails from 'components/Aaro/customer/CustomerDetails';
import CustomerResponsibles from 'components/Aaro/customer/CustomerResponsibles';
import AaroCustomerSales from 'components/Aaro/customer/CustomerSales';

// Helpers

function MainPage({ project, relatedID, updateFiles, setUpdateFiles }) {
    return (
        <MDBox mt={4}>
            <MDBox mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <MDBox mb={3}>
                            <AaroCustomerSales customerID={project.aaroCustomerID} />
                        </MDBox>
                        <MDBox
                            mb={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                        >
                            <MDBox pt={2} px={2} pb={2}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    Proje Sorumluları
                                </MDTypography>
                            </MDBox>
                            <MDBox px={2} pb={2}>
                                <ProjectResponsibles projectID={project?._id} />
                            </MDBox>
                        </MDBox>
                        <MDBox
                            mb={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                        >
                            <MDBox pt={2} px={2} pb={2}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    Diğer Projeleri
                                </MDTypography>
                            </MDBox>
                            <PreviousProjects customerID={project.aaroCustomerID} projectID={project?._id} />
                        </MDBox>
                        {/* <MDBox mb={3} mt={6}>
                            <AddFile
                                relatedID={relatedID}
                                additionalName={project?.projectName}
                                filePath={`projects/${project?.aaroCustomerName}`}
                                setUpdateFiles={setUpdateFiles}
                                source="projects"
                            />
                        </MDBox> */}
                        <MDBox
                            mb={3}
                            mt={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                        >
                            <AddMultiFile
                                relatedID={relatedID}
                                additionalName={project?.projectName}
                                filePath={`projects/${project?.aaroCustomerName}`}
                                setUpdateFiles={setUpdateFiles}
                                source="projects"
                            />
                        </MDBox>

                        {/* <Invoices /> */}
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} xl={6}>
                                <CustomerDetails customerID={project.aaroCustomerID} icon="person" color="dark" />
                            </Grid>
                            <Grid item xs={12} md={6} xl={6}>
                                <ProjectName project={project} icon="business" color="dark" />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomerResponsibles customerID={project.aaroCustomerID} />
                            </Grid>
                            <Grid item xs={12}>
                                <ProjectPosition project={project} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default MainPage;
