import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc  it retrieves all the products
 * @return promise - axios returns a promise
 */
export const getSpreadsheets = (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/google/sheets?${query}`;
    return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the products
 * @return promise - axios returns a promise
 */
export const integrateSpreadsheet = (data, demandID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/google/sheets/integrate/${demandID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  it syncs demand with spreadsheet
 * @return promise - axios returns a promise
 */
export const syncSpreadsheet = (demandID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/google/sheets/sync/${demandID}`;
    return axios.put(url, {}, { headers });
};

/**
 * @desc  it syncs demand with spreadsheet
 * @return promise - axios returns a promise
 */
export const createOfferGoogle = (demandID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/google/sheets/offer/${demandID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  it syncs demand with spreadsheet
 * @return promise - axios returns a promise
 */
export const createGroupedOfferGoogle = (demandID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/google/sheets/offer/grouped/${demandID}`;
    return axios.put(url, data, { headers });
};

// DRIVE

// /**
//  * @desc  it gets folder for project ID (ıf not found it crrates)
//  * @return promise - axios returns a promise
//  */
// export const createOrGetGoogleDrive = (demandID, data) => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     const headers = {
//         'Content-Type': 'application/json',
//         Authorization: user.token,
//     };
//     const url = `${config.url.API_URL}/api/google/sheets/offer/${demandID}`;
//     return axios.put(url, data, { headers });
// };
