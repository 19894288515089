import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { config } from 'Constants';
import LineSkeleton from 'components/Loading/LineSkeleton';
import Autocomplete from '@mui/material/Autocomplete';
import FormField from 'layouts/pages/account/components/FormField';
import DekontCreationView from './DekontCreationView';

import axios from 'axios';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 500,
	maxHeight: 800,
	width: '100%',
	bgcolor: 'background.paper',
	// border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	overflowY: 'scroll',
};

function CustomerDetails(props) {
	const { products, dekontType, detail } = props;
	const [value, setValue] = React.useState('');
	const [finalValue, setFinalValue] = React.useState('');
	const [customerID, setCustomerID] = React.useState(-1);
	const [disabled, setDisabled] = React.useState(false);
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: `Bearer ${user.aaroToken}`,
		Accept: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded',
	};

	const { data: aaro, isLoading } = useQuery(
		['aaroCari', value],
		({ signal }) =>
			axios.post(
				`${config.url.AARO_BASE}/api/Rehber/Secenekler?RehberID=RehberCari&Veri=${value}`,
				{},
				{
					headers,
					signal,
				}
			),
		{ enabled: Boolean(value) }
	);
	const [open, setOpen] = React.useState(true);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisabled(true);
		try {
			const customerID = finalValue.split('-')[1];
			setCustomerID(customerID);
		} catch (err) {
			alert(err);
			setOpen(false);
		}
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			{customerID < 0 ? (
				<form onSubmit={handleSubmit}>
					<Fade in={open}>
						<MDBox sx={style}>
							<Autocomplete
								defaultValue=""
								value={finalValue}
								onChange={(event, newValue) => {
									setFinalValue(newValue);
								}}
								filterOptions={(x) => x}
								options={
									aaro?.data?.Model
										? aaro.data.Model.map((el) =>
												(
													el.Text.replaceAll(
														'-',
														' '
													).substring(
														0,
														el.Text.indexOf('(')
													) +
													'-' +
													el.ID
												).trim()
										  )
										: []
								}
								renderInput={(params) => (
									<FormField
										{...params}
										required
										label="Müşteri"
										InputLabelProps={{ shrink: true }}
										value={value}
										onChange={(e) =>
											setValue(e.target.value)
										}
									/>
								)}
							/>

							{isLoading ? (
								<LineSkeleton width={420} line={10} />
							) : (
								<>
									<MDBox mt={3}>
										<MDButton
											variant="gradient"
											color="info"
											type="submit"
											disabled={disabled}
										>
											Oluştur
										</MDButton>
									</MDBox>{' '}
								</>
							)}
						</MDBox>
					</Fade>
				</form>
			) : (
				<MDBox sx={style}>
					<DekontCreationView
						customerID={customerID}
						products={products}
						dekontType={dekontType}
						detail={detail}
					/>
				</MDBox>
			)}
		</Modal>
	);
}

export default CustomerDetails = React.memo(CustomerDetails);
