import { useState } from 'react';
import Link from '@mui/material/Link';
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBadge from 'components/MDBadge';
import { backHistoryOnProject } from 'services/api/project';
import { config } from 'Constants';
import { notify } from 'helpers/notificationHelper';
function ViewDemand({ demandID, projectID }) {
	const [disabled, setDisabled] = useState(false);
	const handleClick = (e) => {
		e.preventDefault();

		setDisabled(true);
		const result = window.prompt(
			'Bu işlemin geri dönüşü yoktur. Lütfen proje kalemlerini bu kalemlerle değiştirmek için onaylıyorum yazınız!'
		);
		if (result === 'onaylıyorum') {
			backHistoryOnProject(projectID, demandID)
				.then((rp) => {
					notify(rp.data.success, rp.data.message);
					window.location.reload();
				})
				.catch((err) => notify(false, err));
		}
	};
	return (
		<MDButton
			variant="gradient"
			color="primary"
			size="small"
			onClick={handleClick}
			disabled={disabled}
		>
			Buraya Dön
		</MDButton>
	);
}

export default ViewDemand;
