import axios from 'axios';
import { config } from 'Constants';

// /**
//  * @desc  it retrieves all the projects
//  * @return promise - axios returns a promise
//  */
// export const getProjects = (active = true) => {
// 	const user = JSON.parse(localStorage.getItem('user'));
// 	const headers = {
// 		'Content-Type': 'application/json',
// 		Authorization: user.token,
// 	};
// 	const url = `${config.url.API_URL}/api/kahramanlar/projects?active=${active}`;
// 	return axios.get(url, { headers });
// };

/**
 * @desc  it retrieves all the projects
 * @return promise - axios returns a promise
 */
export const getProjects = (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects?${query}`;
    return axios.get(url, { headers });
};

/**
 * @desc  it search from all the projects
 * @param data - additional request data (e.g., searchQuery, Rating)
 * @param page - current page number for pagination
 * @param limit - number of items per page for pagination
 * @return promise - axios returns a promise
 */
export const searchProjects = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/search`;

    // `data` objesindeki dizileri stringe dönüştür
    const convertedData = {};
    for (const key in data) {
        if (Array.isArray(data[key])) {
            convertedData[key] = JSON.stringify(data[key]);
        } else {
            convertedData[key] = data[key];
        }
    }

    // Axios'a gönderilecek `params` objesi
    const params = {
        ...convertedData,
    };

    // Axios GET isteğini gerçekleştir ve sonucu döndür
    return axios.get(url, { headers, params });
};

/**
 * @desc  it retrieves all the projects
 * @return promise - axios returns a promise
 */
export const getProject = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc  it updates the project
 * @return promise - axios returns a promise
 */
export const updateProject = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
        'X-Source': 'projects',
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/${id}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  it deletes the project
 * @return promise - axios returns a promise
 */
export const deleteProject = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/${id}`;
    return axios.delete(url, { headers });
};

/**
 * @desc  it updates given customer
 * @return promise - axios returns a promise
 */
export const postProject = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    data.createdBy = user.userID;
    data.responsibles = [user.userID];
    const url = `${config.url.API_URL}/api/kahramanlar/projects`;
    return axios.post(url, data, { headers });
};

/**
 * @desc  it updates given customer
 * @return promise - axios returns a promise
 */
export const backHistoryOnProject = (projectID, demandID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };

    const url = `${config.url.API_URL}/api/kahramanlar/projects?projectID=${projectID}&demandID=${demandID}`;
    return axios.patch(url, {}, { headers });
};

/**
 * @desc  it copies project
 * @return promise - axios returns a promise
 */

export const copyProject = (projectID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: user.token,
        'Access-Control-Allow-Origin': '*',
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/${projectID}`;
    const configuration = {
        method: 'COPY',
        url,
        headers,
    };
    return axios(configuration);
};

/**
 * @desc  it retrieves all the projects
 * @return promise - axios returns a promise
 */
export const getDemand = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the projects
 * @return promise - axios returns a promise
 */
export const getActiveDemand = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/active-demands`;
    return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the projects
 * @return promise - axios returns a promise
 */
export const getDemandHistory = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/history/spesific`;
    return axios.patch(url, data, { headers });
};

/**
 * @desc  it post demand to project
 * @return promise - axios returns a promise
 */
export const postDemand = (projectID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const data = {};
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/${projectID}`;
    return axios.post(url, data, { headers });
};

/**
 * @desc  it post demand to project
 * @return promise - axios returns a promise
 */
export const updateDemand = (demandID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/${demandID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  it copies demand to project
 * @return promise - axios returns a promise
 */

export const copyDemand = (demandID, projectID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: user.token,
        'Access-Control-Allow-Origin': '*',
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand?demandID=${demandID}&projectID=${projectID}`;
    const configuration = {
        method: 'COPY',
        url,
        headers,
        data,
    };
    return axios(configuration);
};

// Demand groups

export const getGroups = (demandID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/groups/${demandID}`;
    return axios.get(url, { headers });
};

/**
 * @desc  Update demand in project
 * @return promise - axios returns a promise
 */
export const updateGroup = (groupID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/groups/${groupID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  Post demand to project
 * @return promise - axios returns a promise
 */
export const postGroup = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/groups`;
    return axios.post(url, data, { headers });
};

/**
 * @desc  Delete demand from project
 * @return promise - axios returns a promise
 */
export const deleteGroup = (groupID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/groups/${groupID}`;
    return axios.delete(url, { headers });
};

// DemandTemplates

export const getDemandTemplates = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/templates`;
    return axios.get(url, { headers });
};

/**
 * @desc  Update demand in project
 * @return promise - axios returns a promise
 */
export const updateDemandTemplate = (groupID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/templates/${groupID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  Post demand to project
 * @return promise - axios returns a promise
 */
export const postDemandTemplate = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/templates`;
    return axios.post(url, data, { headers });
};

/**
 * @desc  Delete demand from project
 * @return promise - axios returns a promise
 */
export const deleteDemandTemplate = (groupID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/templates/${groupID}`;
    return axios.delete(url, { headers });
};

// SheetTemplates

export const getSheetTemplate = (id, requestedNamedRanges) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const ranges = requestedNamedRanges.join(',');
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/sheet-templates/${id}?ranges=${ranges}`;
    return axios.get(url, { headers });
};

// SheetTemplates

export const getSheetTemplates = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/sheet-templates`;
    return axios.get(url, { headers });
};

/**
 * @desc  Update demand in project
 * @return promise - axios returns a promise
 */
export const updateSheetTemplate = (groupID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/sheet-templates/${groupID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  Post demand to project
 * @return promise - axios returns a promise
 */
export const postSheetTemplate = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/sheet-templates`;
    return axios.post(url, data, { headers });
};

/**
 * @desc  Delete demand from project
 * @return promise - axios returns a promise
 */
export const deleteSheetTemplate = (groupID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/sheet-templates/${groupID}`;
    return axios.delete(url, { headers });
};

// sheet template and google

/**
 * @desc  Update named range in a Google Sheet and fetch specified ranges
 * @return promise - axios returns a promise
 */
export const updateGoogleSheet = (sheetURL, updates) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/sheet-templates/update-sheet`;
    const data = {
        sheetURL,
        updates,
    };
    return axios.post(url, data, { headers });
};
