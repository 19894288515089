// @mui material components

// const pageRoutes = [
// 	{
// 		name: 'Dashboards',
// 		key: 'dashboards',
// 		icon: <Icon>dashboard</Icon>,
// 		collapse: [
// 			{
// 				name: 'Analytics',
// 				key: 'analytics',
// 				route: '/dashboards/analytics',
// 			},
// 			{
// 				name: 'Sales',
// 				key: 'sales',
// 				route: '/dashboards/sales',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Users',
// 		key: 'users',
// 		icon: <Icon>people</Icon>,
// 		collapse: [
// 			{
// 				name: 'Reports',
// 				key: 'reports',
// 				route: '/pages/users/reports',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Projeler',
// 		key: 'projects',
// 		icon: <Icon fontSize="medium">apps</Icon>,
// 		collapse: [
// 			{
// 				name: 'Aktif Projeler',
// 				key: 'active-projects',
// 				route: '/projects/active',
// 			},
// 			{
// 				name: 'Sonlanmış Projeler',
// 				key: 'passive-projects',
// 				route: '/projects/passive',
// 			},
// 			{
// 				route: '/projects/:id',
// 				name: 'single-project',
// 				key: 'single-project',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Profile',
// 		key: 'profile',
// 		icon: <Icon>badge</Icon>,
// 		collapse: [
// 			{
// 				name: 'Profile Overview',
// 				key: 'profile-overview',
// 				route: '/pages/profile/profile-overview',
// 			},
// 			// {
// 			// 	name: 'All Projects',
// 			// 	key: 'all-projects',
// 			// 	route: '/pages/profile/all-projects',
// 			// },
// 		],
// 	},
// 	{
// 		name: 'Extra',
// 		key: 'extra',
// 		icon: <Icon>queue_play_next</Icon>,
// 		collapse: [
// 			{
// 				name: 'Pricing Page',
// 				key: 'pricing-page',
// 				route: '/pages/pricing-page',
// 			},
// 			{ name: 'RTL', key: 'rtl', route: '/pages/rtl' },
// 			{ name: 'Widgets', key: 'widgets', route: '/pages/widgets' },
// 			{ name: 'Charts', key: 'charts', route: '/pages/charts' },
// 			{
// 				name: 'Notfications',
// 				key: 'notifications',
// 				route: '/pages/notifications',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Account',
// 		key: 'account',
// 		icon: <Icon>account_balance</Icon>,
// 		collapse: [
// 			{
// 				name: 'Settings',
// 				key: 'setting',
// 				route: '/pages/account/setting',
// 			},
// 			{
// 				name: 'Billing',
// 				key: 'billing',
// 				route: '/pages/account/billing',
// 			},
// 			{
// 				name: 'Invoice',
// 				key: 'invoice',
// 				route: '/pages/account/invoice',
// 			},
// 		],
// 	},
// 	// {
// 	// 	name: 'Projects',
// 	// 	key: 'projects',
// 	// 	icon: <Icon>precision_manufacturing</Icon>,
// 	// 	collapse: [
// 	// 		{
// 	// 			name: 'Timeline',
// 	// 			key: 'timeline',
// 	// 			route: '/pages/projects/timeline',
// 	// 		},
// 	// 	],
// 	// },
// 	{
// 		name: 'Orders',
// 		key: 'orders',
// 		icon: <Icon>shopping_cart</Icon>,
// 		collapse: [
// 			{
// 				name: 'Order List',
// 				key: 'order-list',
// 				route: '/ecommerce/orders/order-list',
// 			},
// 			{
// 				name: 'Order Details',
// 				key: 'order-details',
// 				route: '/ecommerce/orders/order-details',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Products',
// 		key: 'products',
// 		icon: <Icon>memory</Icon>,
// 		collapse: [
// 			{
// 				name: 'New Product',
// 				key: 'new-product',
// 				route: '/ecommerce/products/new-product',
// 			},
// 			{
// 				name: 'Edit Product',
// 				key: 'edit-product',
// 				route: '/ecommerce/products/edit-product',
// 			},
// 			{
// 				name: 'Product Page',
// 				key: 'product-page',
// 				route: '/ecommerce/products/product-page',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Sign In',
// 		key: 'sign-in',
// 		icon: <Icon>login</Icon>,
// 		collapse: [
// 			{
// 				name: 'Basic',
// 				key: 'basic',
// 				route: '/authentication/sign-in',
// 			},
// 			{
// 				name: 'Cover',
// 				key: 'cover',
// 				route: '/authentication/sign-in/cover',
// 			},
// 			{
// 				name: 'Illustration',
// 				key: 'illustration',
// 				route: '/authentication/sign-in/illustration',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Sign Up',
// 		key: 'sign-up',
// 		icon: <Icon>assignment</Icon>,
// 		collapse: [
// 			{
// 				name: 'Cover',
// 				key: 'cover',
// 				route: '/authentication/sign-up/cover',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Reset Password',
// 		key: 'reset-password',
// 		icon: <Icon>restart_alt</Icon>,
// 		collapse: [
// 			{
// 				name: 'Cover',
// 				key: 'cover',
// 				route: '/authentication/reset-password/cover',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Applications',
// 		key: 'applications',
// 		collapse: [
// 			{
// 				name: 'Kanban',
// 				key: 'kanban',
// 				route: '/applications/kanban',
// 				icon: 'widgets',
// 			},
// 			{
// 				name: 'Wizard',
// 				key: 'wizard',
// 				route: '/applications/wizard',
// 				icon: 'import_contacts',
// 			},
// 			{
// 				name: 'Data Tables',
// 				key: 'data-tables',
// 				route: '/applications/data-tables',
// 				icon: 'backup_table',
// 			},
// 			{
// 				name: 'Calendar',
// 				key: 'calendar',
// 				route: '/applications/calendar',
// 				icon: 'event',
// 			},
// 		],
// 	},
// 	{
// 		name: 'Docs',
// 		key: 'docs',
// 		collapse: [
// 			{
// 				name: 'Getting Started',
// 				key: 'getting-started',
// 				href: 'https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/',
// 				description:
// 					'All about overview, quick start, license and contents',
// 				icon: <Icon>article</Icon>,
// 			},
// 			{
// 				name: 'Foundation',
// 				key: 'foundation',
// 				href: 'https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/',
// 				description: 'See our colors, icons and typography',
// 				icon: <Icon>grading</Icon>,
// 			},
// 			{
// 				name: 'Components',
// 				key: 'components',
// 				href: 'https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/',
// 				description:
// 					'Explore our collection of fully designed components',
// 				icon: <Icon>apps</Icon>,
// 			},
// 			{
// 				name: 'Plugins',
// 				key: 'plugins',
// 				href: 'https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/',
// 				description: 'Check how you can integrate our plugins',
// 				icon: <Icon>extension</Icon>,
// 			},
// 		],
// 	},
// ];
const pageRoutes = [];
export default pageRoutes;
