import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import { Dialog, Card, Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import * as yup from 'yup';

import FormField from 'layouts/pages/account/components/FormField';
import { updatePurchase } from 'services/api/purchases';
import { notify } from 'helpers/notificationHelper';

const validationSchema = yup.object({
    wooCommerceID: yup.string().required('WooCommerce ID gereklidir'),
    // Add your validation rules here
});

export default function EditPurchase(props) {
    const { initial, handleClose, open } = props;
    const queryClient = useQueryClient();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    const formik = useFormik({
        initialValues: {
            wooCommerceID: initial?.wooCommerceID || '',
            createdBy: initial?.createdBy || user?._id,
            responsibles: initial?.responsibles || [],
            isActive: initial?.isActive ?? true,
            taskList: initial?.taskList || null,
            priority: initial?.priority || -1,
            shipments: initial?.shipments || [],
            customerRating: initial?.customerRating || '',
            customerFeedback: initial?.customerFeedback || '',
        },
        validateOnMount: true,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            console.log('Formik onSubmit triggered with values:', values);
            setDisableOnSubmit(true);

            updatePurchase(values.wooCommerceID, values)
                .then((response) => {
                    console.log('API Response:', response);
                    notify(response.data.success, response.data.message);
                    handleClose();
                    queryClient.invalidateQueries(['purchase', initial?.wooCommerceID]);
                    queryClient.invalidateQueries(['wooOrders']);
                })
                .catch((err) => {
                    console.error('Submit error:', err);
                    notify(false, err.message || JSON.stringify(err));
                })
                .finally(() => {
                    setDisableOnSubmit(false);
                    setSubmitting(false);
                });
        },
    });

    useEffect(() => {
        console.log('Formik values:', formik.values);
        console.log('Formik errors:', formik.errors);
        console.log('Formik isValid:', formik.isValid);
    }, [formik.values, formik.errors, formik.isValid]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('Handle form submit triggered');
        console.log('Form is valid:', formik.isValid);
        console.log('Form errors:', formik.errors);
        console.log('Form values:', formik.values);
        formik.handleSubmit(e); // Bu satır çalışıyor mu?
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md" onClick={(e) => e.stopPropagation()}>
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={handleFormSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            {/* WooCommerce ID - Salt Okunur */}
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="WooCommerce ID"
                                    id="wooCommerceID"
                                    name="wooCommerceID"
                                    value={formik.values.wooCommerceID}
                                    onChange={formik.handleChange}
                                    error={formik.touched.wooCommerceID && Boolean(formik.errors.wooCommerceID)}
                                    helperText={formik.touched.wooCommerceID && formik.errors.wooCommerceID}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            {/* Öncelik */}
                            <Grid item xs={12} sm={6}>
                                <InputLabel>Öncelik</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        id="priority"
                                        name="priority"
                                        value={formik.values.priority}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={-1}>Öncelik Yok</MenuItem>
                                        <MenuItem value={0}>Düşük</MenuItem>
                                        <MenuItem value={1}>Normal</MenuItem>
                                        <MenuItem value={2}>Yüksek</MenuItem>
                                        <MenuItem value={3}>Acil</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Durum */}
                            <Grid item xs={12} sm={6}>
                                <InputLabel>Durum</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        id="isActive"
                                        name="isActive"
                                        value={formik.values.isActive}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={true}>Aktif</MenuItem>
                                        <MenuItem value={false}>Pasif</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Müşteri Puanı */}
                            <Grid item xs={12} sm={6}>
                                <InputLabel>Müşteri Puanı</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        id="customerRating"
                                        name="customerRating"
                                        value={formik.values.customerRating}
                                        onChange={formik.handleChange}
                                    >
                                        {[1, 2, 3, 4, 5].map((rating) => (
                                            <MenuItem key={rating} value={rating}>
                                                {rating}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Shipping Information */}
                            <Grid item xs={12}>
                                {formik.values.shipments.map((shipment, index) => (
                                    <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                label="Shipping Method"
                                                name={`shipments.${index}.shippingMethod`}
                                                value={shipment.shippingMethod || ''}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                label="Shipping Cost"
                                                name={`shipments.${index}.shippingCost`}
                                                value={shipment.shippingCost || ''}
                                                onChange={formik.handleChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                label="Tracking Number"
                                                name={`shipments.${index}.trackingNumber`}
                                                value={shipment.trackingNumber || ''}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                                <MDButton
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    type="button" // Add this line
                                    onClick={() => {
                                        const shipments = [...formik.values.shipments];
                                        shipments.push({ shippingMethod: '', shippingCost: 0, trackingNumber: '' });
                                        formik.setFieldValue('shipments', shipments);
                                    }}
                                >
                                    Kargo Bilgisi Ekle
                                </MDButton>
                            </Grid>

                            {/* Customer Feedback */}
                            <Grid item xs={12}>
                                <FormField
                                    label="Müşteri Geri Bildirimi"
                                    placeholder="Müşteri geri bildirimini giriniz"
                                    id="customerFeedback"
                                    name="customerFeedback"
                                    value={formik.values.customerFeedback}
                                    onChange={formik.handleChange}
                                    multiline
                                    rows={3}
                                />
                            </Grid>

                            {/* Gönder Butonu */}
                            <Grid item xs={12}>
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="dark"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                    onClick={(e) => {
                                        console.log('Submit button clicked');
                                    }}
                                >
                                    {disableOnSubmit ? 'Güncelleniyor...' : 'SİPARİŞİ GÜNCELLE'}
                                </MDButton>
                            </Grid>

                            {/* Debug bilgisini sadece development ortamında gösterelim */}
                            {process.env.NODE_ENV === 'development' && (
                                <Grid item xs={12} mt={2}>
                                    <details>
                                        <summary>
                                            Debug Bilgisi{' '}
                                            {JSON.stringify(
                                                {
                                                    values: formik.values,
                                                    isSubmitting: formik.isSubmitting,
                                                    disableOnSubmit,
                                                },
                                                null,
                                                2
                                            )}
                                        </summary>
                                    </details>
                                </Grid>
                            )}
                        </Grid>
                    </MDBox>
                </form>
            </Card>
        </Dialog>
    );
}
