import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { getReminders, deleteReminder } from 'services/api/general/reminder';

import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';
import TextField from '@mui/material/TextField';
import { formatDate } from 'helpers/smallCodes';

import { notify } from 'helpers/notificationHelper';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
import logoSMS from 'assets/images/small-logos/sms.png';
import logoGmail from 'assets/images/small-logos/gmail.png';
import logoSlackPNG from 'assets/images/small-logos/slack.png';
import logoWhatsapp from 'assets/images/small-logos/whatsapp.png';
import { todayIsBetween, formatDateMinutes } from 'helpers/dateHelpers';
// Material Dashboard 2 PRO React context
const fetchReminders = async (relatedID) => {
    const response = await getReminders(relatedID);
    return response?.data?.result;
};

export default function Reminders(props) {
    const {
        relatedID,
        restricted = false,
        bgColor,
        count,
        percentage,
        icon,
        direction,
        title,
    } = props;
    const [readable, setReadable] = React.useState(true);
    const [visible, setVisible] = React.useState(false);
    const [reminderTitle, setTitle] = React.useState('');
    const user = JSON.parse(localStorage.getItem('user'));

    const saveDetails = (details, id) => {
        const data = {
            title:
                reminderTitle +
                ' | ' +
                user.name +
                ' - ' +
                formatDate(new Date()),
            note: details,
            relatedID,
        };
        // editNote(data, id).then((rp) => {
        // 	notify(rp.data.success, rp.data.message);
        // 	setReadable(!readable);
        // 	refetch();
        // });
    };
    const { data, refetch, isLoading, error, isFetching } = useQuery(
        ['fetchReminders', relatedID],
        () => fetchReminders(relatedID),
        {
            staleTime: 91200000,
            // enabled: false,
        }
    );
    const reversedData = data?.reverse();
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return reversedData.map((el) => (
        <MDBox my={3} key={el._id}>
            <Card sx={{ overflow: 'hidden' }}>
                <MDBox bgColor={bgColor} variant="gradient">
                    <MDBox p={2}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <MDBox
                                    ml={direction === 'left' ? 2 : 0}
                                    lineHeight={1}
                                    textAlign={
                                        direction === 'left' ? 'right' : 'left'
                                    }
                                >
                                    {/* <MDTypography
										variant="button"
										color={
											bgColor === 'white'
												? 'text'
												: 'white'
										}
										opacity={bgColor === 'white' ? 1 : 0.7}
										textTransform="capitalize"
										fontWeight={title?.fontWeight}
									>
										{el.services.join('-').toString()}
									</MDTypography> */}

                                    <MDBox display="flex">
                                        {el?.services.includes('SLACK') && (
                                            <MDAvatar
                                                src={logoSlackPNG}
                                                alt="Slack logo"
                                                variant="rounded"
                                                size="xs"
                                            />
                                        )}
                                        {el?.services.includes('MAIL') && (
                                            <MDAvatar
                                                src={logoGmail}
                                                alt="Slack logo"
                                                variant="rounded"
                                                size="xs"
                                            />
                                        )}
                                        {el?.services.includes('SMS') && (
                                            <MDAvatar
                                                src={logoSMS}
                                                alt="Slack logo"
                                                variant="rounded"
                                                size="xs"
                                            />
                                        )}
                                        {el?.services.includes('WHATSAPP') && (
                                            <MDAvatar
                                                src={logoWhatsapp}
                                                alt="Slack logo"
                                                variant="rounded"
                                                size="xs"
                                            />
                                        )}
                                    </MDBox>
                                    <MDTypography
                                        variant="h5"
                                        fontWeight="bold"
                                        color={
                                            bgColor === 'white'
                                                ? 'dark'
                                                : 'white'
                                        }
                                    >
                                        {el.users
                                            .map((el) => el.name)
                                            .join('-')
                                            .toString()}
                                    </MDTypography>
                                    <MDTypography
                                        variant="button"
                                        color={
                                            todayIsBetween(
                                                new Date(),
                                                new Date(el?.due?.dateTime)
                                            )
                                                ? 'success'
                                                : 'primary'
                                        }
                                        fontWeight="regular"
                                    >
                                        {formatDateMinutes(el?.due?.dateTime)}
                                    </MDTypography>
                                </MDBox>
                            </Grid>

                            <Grid item xs={4}>
                                <MDBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <MDButton
                                        variant="gradient"
                                        color={
                                            user.username ===
                                            el?.users[0]?.username
                                                ? 'primary'
                                                : 'dark'
                                        }
                                        iconOnly
                                        circular
                                        onClick={() => {
                                            if (
                                                user.username ===
                                                el?.users[0]?.username
                                            )
                                                deleteReminder(el?._id)
                                                    .then((rp) => {
                                                        notify(
                                                            rp.data.success,
                                                            rp.data.message
                                                        );
                                                        refetch();
                                                        // setVisible(false);
                                                        // refetch();
                                                    })
                                                    .catch((err) =>
                                                        notify(
                                                            false,
                                                            JSON.stringify(err)
                                                        )
                                                    );
                                        }}
                                    >
                                        <Icon fontSize="medium" color="inherit">
                                            {user.username ===
                                            el?.users[0]?.username
                                                ? 'delete_forever'
                                                : 'event'}
                                        </Icon>
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </MDBox>
    ));
}

// Setting default values for the props of MiniStatisticsCard
Reminders.defaultProps = {
    bgColor: 'white',
    title: {
        fontWeight: 'light',
        text: '',
    },
    percentage: {
        color: 'success',
        text: '',
    },
    direction: 'right',
};

// Typechecking props for the Reminders
Reminders.propTypes = {
    bgColor: PropTypes.oneOf([
        'white',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
    ]),
    title: PropTypes.PropTypes.shape({
        fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
        text: PropTypes.string,
    }),
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    percentage: PropTypes.shape({
        color: PropTypes.oneOf([
            'primary',
            'secondary',
            'info',
            'success',
            'warning',
            'error',
            'dark',
            'white',
        ]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    icon: PropTypes.shape({
        color: PropTypes.oneOf([
            'primary',
            'secondary',
            'info',
            'success',
            'warning',
            'error',
            'dark',
        ]),
        component: PropTypes.node.isRequired,
    }).isRequired,
    direction: PropTypes.oneOf(['right', 'left']),
};
