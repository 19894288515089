import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { notify } from 'helpers/notificationHelper';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import FormField from 'layouts/pages/account/components/FormField';

import { editFile } from 'services/api/general/file';

const initialValues = {
    name: '',
    alt: '',
};

const validationSchema = yup.object({
    name: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    alt: yup
        .string('Açıklama Giriniz')
        .min(0, '0 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır'),
});

export default function InputArea({ initial = initialValues, open, handleClose, setUpdateFiles }) {
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: initial.name,
            alt: initial.alt,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setDisableOnSubmit(true);

            try {
                const rp = await editFile(initial._id, values);
                setUpdateFiles(Math.random());
                notify(rp.data.success, rp.data.message);
                handleClose();
            } catch (err) {
                notify(false, JSON.stringify(err));
            }

            setDisableOnSubmit(false);
        },
    });

    useEffect(() => {
        formik.resetForm();
    }, [initial, open]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={formik.handleSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            <FormFieldGridItem id="name" label="Dosya Adı" placeholder="Dosya Adı" formik={formik} />
                            <FormFieldGridItem
                                id="alt"
                                label="Dosya Açıklaması"
                                placeholder="Dosya Açıklaması"
                                formik={formik}
                            />
                            <Grid item xs={12}>
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="info"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    Düzenle
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </Card>
        </Dialog>
    );
}

function FormFieldGridItem({ id, label, placeholder, formik }) {
    return (
        <Grid item xs={12} sm={6}>
            <FormField
                label={label}
                placeholder={placeholder}
                id={id}
                name={id}
                value={formik.values[id]}
                onChange={formik.handleChange}
                error={formik.touched[id] && Boolean(formik.errors[id])}
                helperText={formik.touched[id] && formik.errors[id]}
            />
        </Grid>
    );
}
