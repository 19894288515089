import { useMemo } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';

// Soft UI Dashboard PRO React components
import MDBox from 'components/MDBox';

// Soft UI Dashboard PRO React example components

import TimelineList from 'layouts/projects/Pages/SingleProject/OfferHistoryPage/TimelineList';
import TimelineItem from 'layouts/projects/Pages/SingleProject/OfferHistoryPage/TimelineItem';

// Data
import { getDemandHistory } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';

const fetchDemandHistory = async (id) => {
	const response = await getDemandHistory(id)
		.then((rp) => rp.data.result)
		.catch((err) => err);
	return response;
};

function OfferHistoryPage({ project }) {
	const { data, isLoading, error } = useQuery(
		['fetchDemandHistory', project.demandHistory],
		() => fetchDemandHistory({ demandHistory: project.demandHistory }),
		{ staleTime: 60000000 }
	);
	const reverseArr = (input) => {
		const ret = new Array(input.length);
		for (let i = input.length - 1; i >= 0; i--) {
			ret.push(input[i]);
		}
		return ret;
	};

	const renderTimelineItems = useMemo(
		() =>
			data &&
			reverseArr(data).map((demand) => (
				<TimelineItem
					key={demand._id}
					// color={color}
					// icon={icon}
					// title={demand.freezedDate}
					// dateTime={dateTime}
					// description={description}
					// badges={badges}
					// lastItem={lastItem}
					demand={demand}
					project={project}
				/>
			)),
		[data]
	);
	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<MDBox my={4}>
			<Grid container spacing={3}>
				<Grid item xs={12} lg={12}>
					<TimelineList title="Değiştirilemez Teklifler" dark>
						{renderTimelineItems}
					</TimelineList>
				</Grid>
			</Grid>
		</MDBox>
	);
}

export default OfferHistoryPage;
