// Libraries
import { memo } from 'react';
import { Link } from 'react-router-dom';

import MDButton from 'components/MDButton';

const calculateTime = (date) => {
    const currentDate = new Date();
    let timePassed = currentDate - new Date(date);
    const twentyFourHoursToMS = 86400000;
    timePassed = Math.ceil(timePassed / twentyFourHoursToMS);
    return timePassed;
};

export const ProjectCreationDate = memo(({ date, color = 'dark', projectID = '' }) => {
    const dayPassed = calculateTime(date);
    return (
        <MDButton
            variant="outlined"
            color={color}
            size="small"
            fullWidth
            component={Link}
            to={`../projects/${projectID}`}
            target="_blank"
            rel="noopener noreferrer"
            circular
        >
            {dayPassed}
        </MDButton>
    );
});
