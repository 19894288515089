import { useEffect, useRef } from 'react';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import MDBox from 'components/MDBox';
import MDDropzoneRoot from 'components/General/File/DropZoneRootStyled.js';
import { useMaterialUIController } from 'context';
import { postFile } from 'services/api/general/file';

Dropzone.autoDiscover = false; // Move it outside of the component

// Function to normalize filename
function normalizeFilename(filename) {
    // replace non-alphanumeric, non-dot, and non-slash characters with underscore
    return filename.replace(/[^a-zA-Z0-9/]/g, '');
}
function MDDropzone({ options, relatedID, filePath, additionalName, source, setUpdateFiles }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dropzoneRef = useRef();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user')); // Move it here

        let dz = new Dropzone(dropzoneRef.current, {
            ...options,
            addRemoveLinks: true,
            url: '#',
            dictDefaultMessage: 'Dosyalarınızı buraya bırakın veya tıklayın',
            maxFilesize: 75, // Maximum filesize in MB
            dictRemoveFile: 'Dosyayı Kaldır',
        });

        dz.on('sending', function (file, xhr, formData) {
            xhr.abort();

            let data = new FormData();

            data.append('relatedID', relatedID);
            data.append('name', file.name);
            data.append('filePath', normalizeFilename(filePath.trim()));
            data.append('user', user.userID);
            data.append('alt', '');
            data.append('attachment', file);

            file.previewElement.classList.add('dz-uploading');

            postFile(data, normalizeFilename(additionalName.trim()), source)
                .then(() => {
                    setUpdateFiles(Math.random());
                    file.previewElement.classList.add('dz-success');
                    setTimeout(() => this.removeFile(file), 3000);
                })
                .catch((err) => {
                    console.log(err);
                    file.previewElement.classList.add('dz-error-custom');
                });
        });

        dz.on('error', function (file, message) {
            if (message.includes('File is too big')) {
                file.previewElement.classList.add('dz-error-custom');
                console.log('Dosya boyutu sınırlamayı aştı.');
            }
        });

        return () => {
            dz.destroy(); // Remove the check
        };
    }, [options, relatedID, filePath, additionalName, source, setUpdateFiles]);

    return (
        <MDDropzoneRoot
            component="form"
            action="#"
            ref={dropzoneRef}
            className="form-control dropzone"
            ownerState={{ darkMode }}
        >
            <MDBox className="fallback" bgColor="transparent">
                <MDBox component="input" name="file" type="file" multiple />
            </MDBox>
        </MDDropzoneRoot>
    );
}

export default MDDropzone;
