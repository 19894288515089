import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
function valuetext(value) {
	return `${value}°C`;
}

const RangeSlider = ({ min, max, currentRefinement, canRefine, refine }) => {
	const [value, setValue] = React.useState([
		currentRefinement.min,
		currentRefinement.max,
	]);
	const [currentMax, setCurrentMax] = React.useState(currentRefinement.max);

	React.useEffect(() => {
		if (canRefine) {
			setValue([currentRefinement.min, currentRefinement.max]);
		}
	}, [currentRefinement.min, currentRefinement.max]);

	if (min === max) {
		return null;
	}

	const onChangeCommitted = (event, newValue) => {
		const [min, max] = newValue;
		if (currentRefinement.min !== min || currentRefinement.max !== max) {
			refine({ min, max });
		}
	};

	const onChange = (event, newValue) => {
		const [min, max] = newValue;

		setValue([min, max]);
	};

	return (
		// <Rheostat
		// 	min={min}
		// 	max={max}
		// 	values={[currentRefinement.min, currentRefinement.max]}
		// 	onChange={onChange}
		// 	onValuesUpdated={onValuesUpdated}
		// >
		// 	<div
		// 		className="rheostat-marker rheostat-marker--large"
		// 		style={{ left: 3 }}
		// 	>
		// 		<div className="rheostat-value">{stateMin}</div>
		// 	</div>
		// 	<div
		// 		className="rheostat-marker rheostat-marker--large"
		// 		style={{ right: 3 }}
		// 	>
		// 		<div className="rheostat-value">{stateMax}</div>
		// 	</div>
		// </Rheostat>
		<Box mr={3}>
			<Slider
				min={0}
				max={max}
				value={value}
				onChange={onChange}
				valueLabelDisplay="on"
				getAriaValueText={valuetext}
				onChangeCommitted={onChangeCommitted}
				aria-label="Always visible"
				sx={{
					'& .MuiSlider-valueLabel': {
						fontSize: 12,
						fontWeight: 'normal',
						top: -6,
						backgroundColor: 'unset',
						'&:before': {
							display: 'none',
						},
						'& *': {
							background: 'transparent',
						},
					},
				}}
			/>
		</Box>
	);
};

export default RangeSlider;
