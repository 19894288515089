import { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

import { formatPrice, calculateDiscount } from 'helpers/priceHelpers';
import { updateGroup } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';
import { debounce } from 'helpers/debounce';

function BadgeContent({ price, quantity, discount }) {
    const discountedPrice = calculateDiscount(price, quantity, discount);
    return <MDBadge size="xs" color={'light'} badgeContent={formatPrice(discountedPrice)} container />;
}

function BadgeType({ type }) {
    const badgeColor = getBadgeColor(type);
    return <MDBadge size="xs" color={badgeColor} badgeContent={type} container />;
}

function InputField({ quantity, handleAmountUpdate, disabled }) {
    return (
        <Input
            id="standard-adornment-weight"
            // endAdornment={<InputAdornment position="start">ad</InputAdornment>}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
                'aria-label': 'weight',
                type: 'number',
            }}
            sx={{ width: '100%', border: '0 1 0 0 solid black' }}
            value={quantity}
            onChange={handleAmountUpdate}
            disabled={disabled}
        />
    );
}

function Card({ item, groupID, demandID, columnItems }) {
    const { ref = {}, quantity = 0, type } = item;
    const [itemQuantity, setItemQuantity] = useState(quantity);
    const [isHovered, setIsHovered] = useState(false);

    const [inputValue, setInputValue] = useState(quantity); // Ayrı bir state oluşturduk
    const [hideCard, setHideCard] = useState('');
    const queryClient = useQueryClient();

    useEffect(() => {
        setInputValue(quantity);
        setItemQuantity(quantity);
    }, [quantity]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDelete = async () => {
        setHideCard('none');
        const updatedGroupItems = columnItems.filter((el) => el.type === type && el.ref._id !== ref._id);
        await updateGroupItems(groupID, { [type]: updatedGroupItems }, setHideCard);
        queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
    };

    const handleAmountUpdate = async (value) => {
        const intValue = Number(value);
        setItemQuantity(intValue);

        let sameTypes = columnItems.filter((el) => el.type === type);
        sameTypes.forEach((el) => {
            if (el.ref._id === ref._id) el.quantity = intValue;
        });
        await updateGroupItems(groupID, { [type]: sameTypes });
        queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
    };

    // Yeni bir debounce fonksiyonu oluşturduk ve bu fonksiyonu useCallback ile sarmaladık.
    // Bu, her render işlemi sırasında yeni bir debounce fonksiyonu oluşturmayı önler.
    const debouncedHandleAmountUpdate = useCallback(
        debounce((value) => {
            handleAmountUpdate(value);
        }, 1000),
        [groupID, type, columnItems, ref._id, demandID]
    );

    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Input alanını hemen güncelliyoruz
        debouncedHandleAmountUpdate(e.target.value); // Değişiklikler debounce ediliyor
    };

    let displayText = type == 'diger' ? ref.baslik + ' - ' + ref.ekNot : ref.baslik;
    if (!isHovered && displayText.length > 20) {
        displayText = displayText.substring(0, 20) + '...';
    }

    return (
        <MDBox
            sx={{ display: hideCard, margin: '0 auto' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* <MDBox display="flex" alignItems="center" color="text" justifyContent="space-between">
                <BadgeContent price={ref.verilenFiyat} quantity={quantity} discount={ref.indirimOrani} />
                <BadgeType type={type} />
            </MDBox> */}
            <MDBox display="flex" justifyContent="flex-start" alignItems="center">
                <InputField
                    quantity={inputValue}
                    handleAmountUpdate={handleInputChange}
                    disabled={groupID === 'defaultID'}
                />
                {groupID != 'defaultID' && (
                    <IconButton aria-label="delete" size="small" onClick={handleDelete}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                )}
                <BadgeContent price={ref.verilenFiyat} quantity={quantity} discount={ref.indirimOrani} />
                &nbsp;
                <BadgeType type={type} />
            </MDBox>

            <MDBox display="flex">
                <MDTypography
                    variant="overline"
                    fontWeight="light"
                    textTransform="lowercase"
                    sx={{ lineHeight: '1.2' }}
                >
                    {displayText}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

async function updateGroupItems(changedItemID, updateParams, setHideCard) {
    try {
        const response = await updateGroup(changedItemID, updateParams);
        notify(response.data.success, response.data.message);
        if (!response.data.success) setHideCard('');
    } catch (err) {
        notify(false, JSON.stringify(err));
        setHideCard('');
    }
}

function getBadgeColor(type) {
    const badgeColors = {
        uKasa: 'info',
        uPervaz: 'success',
        pervaz: 'error',
        kanat: 'dark',
        kasa: 'warning',
    };
    return badgeColors[type] || 'primary';
}

export default Card;
