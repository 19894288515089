import React from 'react';
import Container from '@mui/material/Container';

export default function EmptySpace({ height }) {
    return (
        <Container fixed>
            <div style={{ height: height }}></div>{' '}
        </Container>
    );
}
