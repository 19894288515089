import * as React from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AllPurchasesContext } from 'layouts/purchases/pages/allLeads/index.js';

export default function DaysToggleButton() {
    const { setDays, days } = React.useContext(AllPurchasesContext);
    const [selectedDays, setSelectedDays] = React.useState(days);

    const handleDaysChange = (event, newDays) => {
        if (newDays !== null) {
            setSelectedDays(newDays);
            setDays(newDays);
        }
    };

    React.useEffect(() => {
        setSelectedDays(days);
    }, [days]);

    return (
        <Stack direction="row" spacing={4}>
            <ToggleButtonGroup
                color="info"
                value={selectedDays}
                onChange={handleDaysChange}
                aria-label="days"
                sx={{ height: 32 }}
                exclusive
            >
                <ToggleButton value={15} aria-label="30 days">
                    15
                </ToggleButton>
                <ToggleButton value={30} aria-label="30 days">
                    30
                </ToggleButton>
                <ToggleButton value={60} aria-label="60 days">
                    60
                </ToggleButton>
                <ToggleButton value={90} aria-label="90 days">
                    90
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}
