import React from 'react';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import { cityCodes } from 'helpers/countryHelpers';

// CardHeader Component
const CardHeader = ({ color, label, status, state, purchase }) => (
    <MDBox display="flex" alignItems="center" color="text" justifyContent="space-between">
        <MDBox display="flex">
            <MDBadge size="xs" color={status === 'completed' ? 'light' : color} badgeContent={label} container />
        </MDBox>

        <MDBox display="flex" justifyContent="flex-end" flexGrow={1}>
            <MDBox display="flex" alignItems="center">
                <MDTypography variant="button" fontWeight="bold" color="text">
                    {cityCodes[state] || state}
                </MDTypography>
            </MDBox>
        </MDBox>
    </MDBox>
);

export default CardHeader;
