/**
=========================================================
* Material Dashboard 2 PRO - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

// ProductPage page components
import ProductImages from 'layouts/projects/Components/OfferToSheet/components/ProductImages';
import ProductInfo from 'layouts/projects/Components/OfferToSheet/components/ProductInfo';

// Data
import { offerSettings, offerTemplates } from 'layouts/projects/Components/OfferToSheet/data/templates';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
// @mui material components

// Material Dashboard 2 PRO components

import { getDemand, getProject } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';

const fetchProject = async (id) => {
    const response = await getProject(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};
const fetchDemand = async (id) => {
    const response = await getDemand(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};
function ProductPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTemplate, setSelectedTemplate] = useState(offerTemplates[0]);

    const projectID = searchParams.get('projectID');
    const demandID = searchParams.get('demandID');

    // const [templates, setTemplates] = useState(offerTemplates);
    // let { projectID, demandID } = useSearchParams();

    const { data, isLoading, error } = useQuery(['fetchDemand', demandID], () => fetchDemand(demandID), {
        enabled: Boolean(demandID),
        staleTime: 600000,
    });
    const { data: project } = useQuery(['fetchProject', projectID], () => fetchProject(projectID), {
        enabled: Boolean(data),
        staleTime: 600000,
    });
    const { data: demandGroups } = useQuery(['fetchDemandGroups', projectID], () => fetchProject(projectID), {
        enabled: Boolean(data),
        staleTime: 600000,
    });

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    return (
        <DashboardLayout>
            <MDBox py={3}>
                <Card sx={{ overflow: 'visible' }}>
                    <MDBox p={3}>
                        <MDBox mb={3}>
                            <MDTypography variant="h5" fontWeight="medium">
                                Şablon Görselleri
                            </MDTypography>
                        </MDBox>

                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} xl={5}>
                                <ProductImages selectedTemplate={selectedTemplate} />
                            </Grid>
                            <Grid item xs={12} lg={5} sx={{ mx: 'auto' }}>
                                <ProductInfo
                                    setSelectedTemplate={setSelectedTemplate}
                                    selectedTemplate={selectedTemplate}
                                    offerTemplates={offerTemplates}
                                    offerSettings={offerSettings}
                                    project={project}
                                    demand={data}
                                    demandGroups={demandGroups}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default ProductPage;
