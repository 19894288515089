import React, { useState, useEffect } from 'react';
import AaroLoadingScreen from 'components/Loading';
import uuid from 'react-uuid';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DataGridPro, GridToolbar, trTR } from '@mui/x-data-grid-pro';
import { formatDataWithHistory } from 'layouts/projects/helpers/historyHelper';
import {
	getUKasa,
	getKasa,
	getPervaz,
	getUPervaz,
	getKanat,
	getDiger,
} from 'services/api/form';

const useStyles = makeStyles({
	root: {
		'& .super-app-theme--cell': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600',
		},
		'& .super-app.negative': {
			backgroundColor: 'rgba(157, 255, 118, 0.49)',
			color: '#1a3e72',
			fontWeight: '600',
		},
		'& .super-app.positive': {
			backgroundColor: '#d47483',
			color: '#1a3e72',
			fontWeight: '600',
		},
	},
});

export default function FormHistory(props) {
	const { id, formName } = props;
	const [data, setData] = useState(null);
	const classes = useStyles();

	useEffect(() => {
		const fetchUKasa = async () => {
			let response = {};
			if (formName == 'uKasa') response = await getUKasa(id);
			if (formName == 'kasa') response = await getKasa(id);
			if (formName == 'pervaz') response = await getPervaz(id);
			if (formName == 'uPervaz') response = await getUPervaz(id);
			if (formName == 'kanat') response = await getKanat(id);
			if (formName == 'diger') response = await getDiger(id);

			setData(response?.data?.result);
		};
		fetchUKasa();
	}, [id]);
	return data ? prepareHistoryView(data, classes) : <AaroLoadingScreen />;
}

const prepareHistoryView = (data, classes) => {
	const { columnNames, sortedHistory } = formatDataWithHistory(data);
	// * first column is for keys
	const columns = [
		{
			field: 'keyColumn',
			headerName: 'Özellikler',
			width: 150,
			sortable: false,
		},
	];
	// * now we can create other columns with unique counter because column name is repeated user names
	let counter = 0;
	sortedHistory.forEach((formElement) => {
		const fieldCode = counter;
		columns.push({
			field: fieldCode,
			headerName: formElement.changedBy,
			width: 100,
			sortable: false,
			cellClassName: (params) => {
				// console.log(params);
				return clsx('super-app', {
					negative:
						params.formattedValue != params.row[params.field + 1],
					// positive: params.value > 0,
				});
			},
		});
		counter++;
	});

	// * time to create rows
	const rows = [];
	let tempRow = {};
	// * columnNames is like "baslik","miktar", this is also specify the number of rows
	// * columns are like -keyColumn,samil-1, sefa-2, samil-3..-
	for (let i = 0; i < columnNames.length; i++) {
		for (let j = 0; j < columns.length + 1; j++) {
			// * first element of sorted history
			let currentFormElement = sortedHistory[j];
			// ! could be undefined because row number(keys) is bigger than history
			if (currentFormElement) {
				const rowKey = columnNames[i];
				//! +1 because first column is for keys
				const columnUniqueFieldCode = columns[j + 1]?.field;
				// * first take row key
				tempRow['keyColumn'] = rowKey;
				// * second for ONE LINE (every column) take the values for all history
				tempRow[columnUniqueFieldCode] = JSON.stringify(
					currentFormElement[rowKey]
				);
			}
		}
		tempRow['id'] = uuid();
		rows.push(tempRow);
		tempRow = {};
	}

	return (
		<div style={{ height: '800px' }} className={classes.root}>
			<DataGridPro
				// localeText={trTR.props.MuiDataGrid.localeText}
				components={{
					Toolbar: GridToolbar,
				}}
				checkboxSelection
				disableSelectionOnClick
				autoHeight
				rows={rows}
				columns={columns}
			/>
		</div>
	);
};
