import axios from 'axios';

/**
 * @desc  it retrieves orders from WooCommerce
 * @param {string} consumerKey - WooCommerce consumer key
 * @param {string} consumerSecret - WooCommerce consumer secret
 * @param {string} websiteUrl - WooCommerce website URL
 * @param {object} params - Additional parameters for filtering orders
 * @return promise - axios returns a promise
 */
export const getWooOrders = (consumerKey, consumerSecret, websiteUrl, params = {}) => {
    const url = `${websiteUrl}/wp-json/wc/v3/orders`;
    const auth = {
        username: consumerKey,
        password: consumerSecret,
    };
    return axios.get(url, { auth, params });
};

/**
 * @desc  WooCommerce'de sipariş durumunu günceller
 * @param {string} consumerKey - WooCommerce consumer key
 * @param {string} consumerSecret - WooCommerce consumer secret
 * @param {string} websiteUrl - WooCommerce website URL
 * @param {number} orderId - Güncellenecek siparişin ID'si
 * @param {string} status - Yeni sipariş durumu
 * @return promise - axios returns a promise
 */
export const updateWooOrderStatus = (consumerKey, consumerSecret, websiteUrl, orderId, status) => {
    const url = `${websiteUrl}/wp-json/wc/v3/orders/${orderId}`;
    const auth = {
        username: consumerKey,
        password: consumerSecret,
    };
    return axios.put(url, { status }, { auth });
};
