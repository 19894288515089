// Libraries
import { memo, useState } from 'react';

// Layout

// MD2 PRO
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';

// @mui

// Project Pages And Components
import ChangeProjectName from './ChangeProjectName';
import ProjectPotential from 'layouts/projects/Components/ProjectPotential';

// API

// Helpers
import { formatDate } from 'helpers/smallCodes';

function ProjectName(props) {
	const { project, setUpdateProject, color, icon } = props;

	const [open, setOpen] = useState(false);
	return (
		<div onDoubleClick={() => setOpen(true)}>
			<DefaultInfoCard
				icon={icon}
				title={project?.projectName}
				description={formatDate(project.createdAt)}
				value={<ProjectPotential project={project} />}
				color={color}
			/>
			<ChangeProjectName
				project={project}
				open={open}
				setOpen={setOpen}
				setUpdateProject={setUpdateProject}
			/>
		</div>
	);
}

export default ProjectName = memo(ProjectName);
