import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useFormikContext } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';

const images = {
    duvar: 'https://kahramanlar.s3.amazonaws.com/projects/ATEAHAPGAMZEATE/test/lkjfhxp1-duvarolcu.jpeg',
    kasa: 'https://kahramanlar.s3.amazonaws.com/projects/ATEAHAPGAMZEATE/test/lkmdcthm-dis.png',
};

function Wall() {
    const { width } = useWindowDimensions();
    const imageDimension = width / 5;
    const { values, handleChange, errors } = useFormikContext(); // Using useFormikContext to get the Formik methods and states

    return (
        <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">Ölçülerinizi nasıl girmek istersiniz?</MDTypography>
                <MDTypography variant="button" color="text">
                    Duvar boşluğu mu, kasa dıştan dış mı?
                    {/* {JSON.stringify(values)} */}
                </MDTypography>
            </MDBox>
            <MDBox mt={1.625} display="flex" justifyContent="center" alignItems="center">
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="olcuBilgisi" // Set the name to match the field's name
                                value={values.olcuBilgisi} // Set the value from Formik's state
                                onChange={handleChange} // Set the onChange to Formik's handleChange
                                alignItems="center"
                                sx={{ display: 'flex', justifyContent: 'center' }} // Here we add the styles to center the content
                            >
                                {Object.entries(images).map(([key, imageSrc]) => (
                                    <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio />}
                                        label={
                                            <img
                                                src={imageSrc}
                                                alt={key}
                                                width={imageDimension}
                                                height={imageDimension}
                                            />
                                        }
                                    />
                                ))}
                            </RadioGroup>
                            {errors.olcuBilgisi && <FormHelperText error>{errors.olcuBilgisi}</FormHelperText>}{' '}
                            {/* Here we added the error message */}
                        </FormControl>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default Wall;
