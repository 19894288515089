import { createContext, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { searchProjects } from 'services/api/project';
import { config } from 'Constants';

const SearchContext = createContext();

export const useSearch = () => {
    return useContext(SearchContext);
};

const fetchProjects = async (searchParams) => {
    try {
        const response = await searchProjects(searchParams);
        return {
            result: response?.data?.result,
            totalCount: response?.data?.totalCount,
            totalPages: response?.data?.totalPages,
        };
    } catch (err) {
        throw err;
    }
};

export const SearchProvider = ({ children }) => {
    const initialDateRange = [null, dayjs()];
    // searchQuery projeler arasında arıyor
    const [searchProjectQuery, setSearchProjectQuery] = useState('');
    const [searchCustomerQuery, setSearchCustomerQuery] = useState('');
    const [rating, setRating] = useState(0);
    const [responsibles, setResponsibles] = useState([]);
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [projectStatus, setProjectStatus] = useState([]);
    const [demandStatus, setDemandStatus] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [isActive, setIsActive] = useState(true);
    const [data, setData] = useState({});

    const resetFilters = () => {
        setSearchProjectQuery('');
        setSearchCustomerQuery('');
        setRating(0);
        setResponsibles([]);
        setDateRange(initialDateRange);
        setProjectStatus([]);
        setDemandStatus([]);
        setPage(1);
        setLimit(25);
        setIsActive(true);
    };

    const searchParams = {
        searchProjectQuery,
        searchCustomerQuery,
        responsibles,
        dateRange,
        projectStatus,
        demandStatus,
        rating,
        isActive,
        page,
        limit,
    };

    const queryInfo = useQuery(
        ['searchProjects', searchParams],
        () => fetchProjects(searchParams),
        {
            keepPreviousData: true, // Sayfa değişikliklerinde önceki verileri tutar
            refetchOnWindowFocus: false, // Pencere odaklandığında yeniden çekmeyecek
        },
        { staleTime: 60000 }
    );

    // Verileri setData ile güncelleyin
    useEffect(() => {
        if (queryInfo.isSuccess) {
            setData(queryInfo.data);
        }
    }, [queryInfo]);

    // İlk Açılışta adminlere yalnızca demand statusu fiyat bekleniyor, düzeltilmeli ve revize bekleniyor olanları getir
    // Buraya {_id:"asdasd123", name:"duzeltilmeli"} şeklinde yaparsam filtrelerde isimler gözükür.
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && config && config.demandStatusID) {
            const {
                demandStatusID: { duzeltilmeli, revizeBekleniyor, fiyatBekleniyor },
            } = config;
            const initialDemandStatus =
                user.role === 'superadmin'
                    ? [
                          { _id: duzeltilmeli, name: 'Düzeltilmeli' },
                          { _id: revizeBekleniyor, name: 'Revize Bekleniyor' },
                          { _id: fiyatBekleniyor, name: 'Fiyat Bekleniyor' },
                      ]
                    : [];
            setDemandStatus(initialDemandStatus.filter((el) => el !== null));
        }
    }, []);

    const updateSearchProjectQuery = (newQuery) => {
        setSearchProjectQuery(newQuery);
    };
    const updateSearchCustomerQuery = (newQuery) => {
        setSearchCustomerQuery(newQuery);
    };
    const updateRating = (rating) => {
        setRating(rating);
    };

    const updateResponsibles = (newResponsible) => {
        setResponsibles(newResponsible);
    };

    const updateDateRange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const updateProjectStatus = (newProjectStatus) => {
        setProjectStatus(newProjectStatus);
    };
    const updateDemandStatus = (newDemandStatus) => {
        setDemandStatus(newDemandStatus);
    };
    const updatePage = (page) => {
        setPage(page);
    };
    const updateLimit = (limit) => {
        setLimit(limit);
    };
    const updateIsActive = (active) => {
        setIsActive(active);
    };

    return (
        <SearchContext.Provider
            value={{
                rating,
                searchProjectQuery,
                searchCustomerQuery,
                responsibles,
                dateRange,
                projectStatus,
                demandStatus,
                page,
                limit,
                isActive,
                data,
                queryInfo,
                updateSearchProjectQuery,
                updateSearchCustomerQuery,
                updateResponsibles,
                updateDateRange,
                updateProjectStatus,
                updateRating,
                updatePage,
                updateLimit,
                updateDemandStatus,
                updateIsActive,
                resetFilters,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};
