// eslint-disable-next-line import/no-anonymous-default-export
export default {
    formId: 'new-user-form',
    formField: {
        olcuBilgisi: {
            name: 'olcuBilgisi',
            label: 'Ölçü Tipi',
            type: 'text',
            errorMsg: 'Ölçü Bilgisi Seçilmelidir.',
        },
        kalinlik: {
            name: 'kalinlik',
            label: 'Kalınlık',
            type: 'number',
            errorMsg: 'Kalınlık bilgisi zorunludur.',
        },
        en: {
            name: 'en',
            label: 'En',
            type: 'number',
            errorMsg: 'En bilgisi zorunludur.',
        },
        boy: {
            name: 'boy',
            label: 'Boy',
            type: 'number',
            errorMsg: 'Boy bilgisi zorunludur.',
        },
        adet: {
            name: 'adet',
            label: 'Adet',
            type: 'number',
            errorMsg: 'Adet bilgisi zorunludur.',
        },
        yanginDayanimSuresi: {
            name: 'yanginDayanimSuresi',
            label: 'Yangın',
            type: 'number',
            errorMsg: 'Yangın Dayanım Süresi sıfır veya daha fazla olmalıdır.',
        },
        montajBoslugu: {
            name: 'montajBoslugu',
            label: 'montajBoslugu',
            type: 'number',
            errorMsg: 'montajBoslugu sıfır veya daha fazla olmalıdır.',
        },
        kanatKasaYanBoslugu: {
            name: 'kanatKasaYanBoslugu',
            label: 'kanatKasaYanBoslugu',
            type: 'number',
            errorMsg: 'kanatKasaYanBoslugu sıfır veya daha fazla olmalıdır.',
        },
        kanatKasaUstBoslugu: {
            name: 'kanatKasaUstBoslugu',
            label: 'kanatKasaUstBoslugu',
            type: 'number',
            errorMsg: 'kanatKasaUstBoslugu sıfır veya daha fazla olmalıdır.',
        },
        kanatAltBoslugu: {
            name: 'kanatAltBoslugu',
            label: 'kanatAltBoslugu',
            type: 'number',
            errorMsg: 'kanatAltBoslugu sıfır veya daha fazla olmalıdır.',
        },
    },
};
