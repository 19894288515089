import React, { memo } from 'react';
import { useQuery } from 'react-query';
import { getTags } from 'services/api/general/tag.js';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from 'components/MDInput';

// Loading component to display a loading message
const Loading = () => <div>Yükleniyor</div>;

// Error component to display an error message
const Error = ({ message }) => <div>Bir Hata Oluştu: {message}</div>;

// Tags component fetches the tags data and handles the state
const Tags = ({ selectedTags, setSelectedTags, tagGroupID }) => {
    const { data, isLoading, error } = useQuery(['fetchTagGroups', tagGroupID], () => fetchTags(tagGroupID), {
        staleTime: 600000,
    });

    // Show loading component if data is being fetched
    if (isLoading) return <Loading />;
    // Show error component if there's an error
    if (error) return <Error message={error.message} />;

    // Render the TagsAutocomplete component with the fetched data
    return <TagsAutocomplete selectedTags={selectedTags} setSelectedTags={setSelectedTags} options={data} />;
};

// TagsAutocomplete component renders the Autocomplete component
const TagsAutocomplete = ({ selectedTags, setSelectedTags, options }) => (
    <Autocomplete
        multiple
        value={selectedTags || []}
        options={options || []}
        getOptionLabel={(option) => option.name || ''}
        getOptionSelected={(option, value) => option._id === value._id}
        onChange={(_, value) => setSelectedTags(value)}
        renderInput={(params) => <MDInput {...params} variant="standard" />}
    />
);

// fetchTags function to asynchronously fetch the tags data
const fetchTags = async (id) => {
    try {
        const response = await getTags(id);
        console.log(response);
        return response.data?.result?.tags || [];
    } catch (error) {
        console.error('Error fetching tags:', error);
        return [];
    }
};

// Export the memoized version of the Tags component to optimize rendering
export default memo(Tags);
