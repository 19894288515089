// prop-types is a library for typechecking of props
import { useState } from 'react';
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import AaroPriceCheck from 'components/Aaro/PriceCheck';
import { calculateDiscount, formatPrice } from 'helpers/priceHelpers';

function CustomerCell({ price, amount = 1, discount = 0, data, demand }) {
	const [open, setOpen] = useState(false);
	if (discount === 0) {
		return (
			<MDBox
				display="flex"
				alignItems="center"
				flexDirection="column"
				onClick={() => setOpen(true)}
			>
				<MDTypography
					variant="caption"
					fontWeight="bold"
					color="dark"
					textGradient
					sx={{ lineHeight: 1 }}
				>
					{formatPrice(
						calculateDiscount(price, amount, discount),
						demand.demandCurrency ? demand.demandCurrency : 'TRY'
					)}
				</MDTypography>
				<AaroPriceCheck open={open} setOpen={setOpen} item={data} />
			</MDBox>
		);
	}
	return (
		<MDBox
			display="flex"
			alignItems="center"
			flexDirection="column"
			onClick={() => setOpen(true)}
		>
			<MDTypography
				variant="caption"
				fontWeight="bold"
				color="dark"
				sx={{ lineHeight: 1, textDecoration: 'line-through' }}
			>
				{formatPrice(
					calculateDiscount(price, amount, 0),
					demand.demandCurrency ? demand.demandCurrency : 'TRY'
				)}
			</MDTypography>
			<MDTypography
				variant="caption"
				fontWeight="bold"
				color="dark"
				textGradient
				sx={{ lineHeight: 1 }}
			>
				{formatPrice(
					calculateDiscount(price, amount, discount),
					demand.demandCurrency ? demand.demandCurrency : 'TRY'
				)}
			</MDTypography>
			<AaroPriceCheck open={open} setOpen={setOpen} item={data} />
		</MDBox>
	);
}

// Setting default value for the props of CustomerCell
CustomerCell.defaultProps = {
	image: '',
	color: 'dark',
};

// Typechecking props for the CustomerCell
CustomerCell.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	color: PropTypes.oneOf([
		'transparent',
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'light',
		'dark',
	]),
};

export default CustomerCell;
