import React from 'react';
import BaseLayout from 'layouts/pages/settings/layout.js';

import Parameters from 'layouts/pages/settings/parameters';
export default function Settings() {
	return (
		<BaseLayout>
			<Parameters />
		</BaseLayout>
	);
}
