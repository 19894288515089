import React, { useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';

// import { getDefaultColor } from 'helpers/colorHelper';
import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getTags } from 'services/api/general/tag';
import AaroLoadingScreen from 'components/Loading';

import { postTag } from 'services/api/general/tag.js';
import Divider from '@mui/material/Divider';
import { TwitterPicker } from 'react-color';
const validationSchema = yup.object({
	name: yup
		.string('Etiket adı zorunludur.')
		.min(3, '3 karakterden fazla olmalıdır')
		.max(200, '200 karakterden az olmalıdır')
		.required('Etiket adı zorunludur.'),
	description: yup
		.string('Açıklama Alanı')
		.max(200, '200 karakter olmalıdır'),
	color: yup
		.string('Renk Seçilmesi Zorunludur.')
		.min(7, '7 karakter olmalıdır')
		.max(7, '7 karakter olmalıdır')
		.required('Renk Seçilmesi Zorunludur.'),
});

const fetchTags = async (id) => {
	const response = await getTags(id).then((rp) =>
		rp.data?.result ? rp.data?.result : {}
	);
	return response;
};

export default function InputArea(props) {
	const { initial, setOpenDialog } = props;
	const [openColorPicker, setOpenColorPicker] = useState(false);

	const queryClient = useQueryClient();

	const {
		data: tagGroup,
		isLoading,
		error,
	} = useQuery(
		['fetchTagGroups', initial?.tagGroupID, 'parent'],
		() => fetchTags(initial?.tagGroupID),
		{ staleTime: 600000 }
	);

	const [disableOnSubmit, setDisableOnSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: initial?.name || '',
			description: initial?.description || '',
			tagGroup: initial?.tagGroupID || '',
			color: initial?.color || '#abb8c3',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setDisableOnSubmit(true);

			postTag(values)
				.then((rp) => {
					queryClient.invalidateQueries(['fetchTagGroups']);
					notify(rp.data.success, rp.data.message);
					setDisableOnSubmit(false);
					setOpenDialog(false);
				})
				.catch((error) => {
					notify(false, JSON.stringify(error));
					setDisableOnSubmit(false);
				});
		},
	});
	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<Card id="basic-info" sx={{ overflow: 'visible' }}>
			<form onSubmit={formik.handleSubmit}>
				<MDBox p={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<MDTypography variant="caption" color="secondary">
								{tagGroup?.name} - {tagGroup?.description}
							</MDTypography>

							<Divider />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormField
								label="Etiket Adı"
								placeholder="name"
								id="name"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								error={
									formik.touched.name &&
									Boolean(formik.errors.name)
								}
								helperText={
									formik.touched.name && formik.errors.name
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormField
								label="Etiket Açıklaması"
								placeholder="description"
								id="description"
								name="description"
								value={formik.values.description}
								onChange={formik.handleChange}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description &&
									formik.errors.description
								}
							/>
						</Grid>

						<Grid item xs={12} sm={12}>
							<ClickAwayListener
								onClickAway={() => setOpenColorPicker(false)}
							>
								<MDBox
									sx={{
										backgroundColor: formik.values.color,
										width: '100%',
									}}
									borderRadius="lg"
									shadow="lg"
									onClick={() => setOpenColorPicker(true)}
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<MDTypography variant="h6" color="white">
										{formik.values.color}
									</MDTypography>
									{openColorPicker && (
										<TwitterPicker
											onChangeComplete={(color) => {
												const { hex } = color;
												formik.setFieldValue(
													'color',
													hex
												);
											}}
										/>
									)}
								</MDBox>
							</ClickAwayListener>
						</Grid>

						<Grid item xs={12}>
							<Grid item xs={12} sm={12}>
								<MDButton
									fullWidth
									size="small"
									variant="gradient"
									color="dark"
									type="submit"
									disabled={disableOnSubmit}
								>
									Ekle
								</MDButton>
							</Grid>
						</Grid>
					</Grid>
				</MDBox>
			</form>
		</Card>
	);
}
