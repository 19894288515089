import toast from 'react-hot-toast';

export const notify = (success, message) =>
	success
		? toast.success(`Başarılı Ekleme/Düzenleme ${message}`, {
				position: 'top-right',
				style: {
					fontSize: '0.75rem',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					lineHeight: 1.5,
				},
		  })
		: toast.error(`Başarısız Ekleme/Düzenleme ${message}`, {
				position: 'top-right',
				style: {
					fontSize: '0.75rem',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					lineHeight: 1.5,
				},
		  });
