import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getParameters = (query) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/parameters?${query}`;
	return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const updateParameter = (id, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/parameters/${id}`;
	return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postParameter = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/parameters`;
	return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it deletes reminder
 * @return promise - axios returns a promise
 */
export const deleteParameter = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/parameters/${id}`;
	return axios.delete(url, { headers });
};
