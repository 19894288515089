import checkout from 'layouts/projects/Forms/Templates/TemplateForm/schemas/form';

const {
    formField: {
        olcuBilgisi,
        kalinlik,
        en,
        boy,
        adet,
        yanginDayanimSuresi,
        montajBoslugu,
        kanatKasaYanBoslugu,
        kanatKasaUstBoslugu,
        kanatAltBoslugu,
    },
} = checkout;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    [olcuBilgisi.name]: '',
    [kalinlik.name]: 0,
    [en.name]: 0,
    [boy.name]: 0,
    [adet.name]: 0,
    [yanginDayanimSuresi.name]: 0,
    [montajBoslugu.name]: 1.5,
    [kanatKasaYanBoslugu.name]: 0.35,
    [kanatKasaUstBoslugu.name]: 0.4,
    [kanatAltBoslugu.name]: 1.5,
};
