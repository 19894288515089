import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { config } from 'Constants';
import Autocomplete from '@mui/material/Autocomplete';
import FormField from 'layouts/pages/account/components/FormField';

import axios from 'axios';

function LiveSearchAaro(props) {
    const { inputName, formik, itemID, required = true, tempName } = props;
    const [value, setValue] = React.useState('');
    const [finalValue, setFinalValue] = React.useState('');
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        Authorization: `Bearer ${user.aaroToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    useEffect(() => {
        if (formik.values.customer) setFinalValue(tempName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data: aaro } = useQuery(
        ['aaroCari', value],
        ({ signal }) =>
            axios.post(
                `${config.url.AARO_BASE}/api/Rehber/Secenekler?RehberID=RehberCari&Veri=${value}`,
                {},
                {
                    headers,
                    signal,
                }
            ),
        { enabled: Boolean(value) }
    );

    return (
        <Autocomplete
            defaultValue=""
            value={finalValue}
            // onChange={formik.handleChange}
            filterOptions={(x) => x}
            labelId={inputName}
            id={itemID}
            // value={finalValue}
            onChange={(event, newValue) => {
                setFinalValue(newValue);
                formik.setFieldValue(itemID, newValue);
            }}
            options={
                aaro?.data?.Model
                    ? aaro.data.Model.map((el) =>
                          (
                              el.Text.replaceAll('-', ' ').substring(
                                  0,
                                  el.Text.indexOf('(')
                              ) +
                              '-' +
                              el.ID
                          ).trim()
                      )
                    : []
            }
            error={formik.touched[itemID] && Boolean(formik.errors[itemID])}
            helperText={formik.touched[itemID] && formik.errors[itemID]}
            renderInput={(params) => (
                <FormField
                    {...params}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required={required}
                    InputLabelProps={{ shrink: true }}
                    label={inputName}
                />
            )}
        />
    );
}

export default LiveSearchAaro = React.memo(LiveSearchAaro);
