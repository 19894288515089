import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Settings page components
import Dialog from '@mui/material/Dialog';
import FormField from 'layouts/pages/account/components/FormField';

import { useFormik } from 'formik';
import * as yup from 'yup';

import LiveSearchAaro from 'layouts/lead/forms/formElements/LiveSearchAaro.js';
import { postCustomerByIDv2, getCustomerByID } from 'services/api/customer';
import { postLead } from 'services/api/lead';

const validationSchema = yup.object({
    // name: yup
    //     .string('Başlık giriniz')
    //     .min(5, '5 karakterden fazla olmalıdır')
    //     .max(200, '200 karakterden az olmalıdır')
    //     .required('Lütfen geçerli bir başlık giriniz'),
    // createdBy: yup
    //     .string('Bağlı olduğu liste IDsi gereklidir.')
    //     .min(5, '5 karakterden fazla olmalıdır')
    //     .max(200, '200 karakterden az olmalıdır')
    //     .required('Bağlı olduğu liste IDsi gereklidir'),
    // callContent: yup
    //     .string('Bağlı olduğu liste IDsi gereklidir.')
    //     .min(5, '5 karakterden fazla olmalıdır')
    //     .max(600, '600 karakterden az olmalıdır')
    //     .required('Bağlı olduğu liste IDsi gereklidir'),
});

export default function InputArea(props) {
    const { initial, handleClose, open } = props;

    const queryClient = useQueryClient();

    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    const formik = useFormik({
        initialValues: {
            name: initial?.name || '',
            createdBy: initial?.createdBy || userID,
            description: initial?.description || '',
            customer: initial?.customer || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setDisableOnSubmit(true);

            try {
                const customer = formik.values.customer;
                const customerName = customer.split('-')[0];
                const customerID = customer.split('-')[1];
                // ! what if server doesnt save the cari?
                await postCustomerByIDv2(customerID, customerName)
                    .then((rp) => console.log(rp))
                    .catch((err) => console.log(err));

                // ! This step written for control purpose.
                const isSuccessfullyAdded = await getCustomerByID(
                    customerID
                ).then((rp) => rp.data.result);

                values.customer = isSuccessfullyAdded?._id;
                // ! If we have this customer we can create project on it
                if (isSuccessfullyAdded?.customerID == customerID) {
                    postLead(values).then((response) => {
                        notify(response.data.success, response.data.message);
                        window.location.reload();
                    });
                }
            } catch (err) {
                notify(false, JSON.stringify(err));
                handleClose();
                queryClient.invalidateQueries(['fetchLeads']);

                // setOpen(false);
            }

            // postCall(values)
            //     .then((rp) => {
            //         notify(rp.data.success, rp.data.message);
            //         handleClose();
            //         queryClient.invalidateQueries(['fetchCalls']);
            //     })
            //     .catch((err) => notify(false, JSON.stringify(err)));
        },
    });

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={formik.handleSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    required
                                    label="Fırsat Başlığı"
                                    placeholder="Ör: Balıkesir 300 Kapı"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LiveSearchAaro
                                    itemID="customer"
                                    formik={formik}
                                    inputName="Müşteri"
                                    helperText={'Fırsatın Potansiyeli'}
                                />
                            </Grid>

                            {/* <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    label="Potansiyel"
                                    id="potential"
                                    name="potential"
                                    type="number"
                                    value={formik.values.potential}
                                    options={[0, 1, 2, 3, 4, 5]}
                                    onChange={(e, value) => {
                                        formik.setFieldValue(
                                            'potential',
                                            value == null ? 0 : value
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormField
                                            {...params}
                                            label="Potansiyel"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <FormField
                                    required
                                    label="Kısa Açıklama"
                                    placeholder="Ör: Otele yangın dayanıklı kapılar talep ediliyor. "
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <MDButton
                                        fullWidth
                                        size="small"
                                        variant="gradient"
                                        color="dark"
                                        type="submit"
                                        disabled={disableOnSubmit}
                                    >
                                        Ekle
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
                {/* {JSON.stringify(formik.values)} */}
            </Card>
        </Dialog>
    );
}
