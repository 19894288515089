import { Grid, Card, Box, Dialog, Autocomplete } from '@mui/material';

import Users from 'layouts/projects/Pages/All/SearchBar/components/Users';
import Tags from 'layouts/projects/Pages/All/SearchBar/components/Tags';
import DateRange from 'layouts/projects/Pages/All/SearchBar/components/DateRange';
import RatingComponent from 'layouts/projects/Pages/All/SearchBar/components/Rating';

import { config } from 'Constants';
import { useSearch } from 'layouts/projects/Pages/SearchProvider';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';

function FilterDialog(props) {
    const {
        rating,
        responsibles,
        dateRange,
        projectStatus,
        demandStatus,
        isActive,
        updateDemandStatus,
        updateResponsibles,
        updateDateRange,
        updateProjectStatus,
        updateRating,
        updateIsActive,
    } = useSearch();

    const { open, handleClose } = props;
    const { PROJECT_STATUS_TAG_GROUP_ID, DEMAND_STATUS_TAG_GROUP_ID } = config.fixedID;

    // Activity options for the Autocomplete component
    const activityOptions = [
        { name: 'Aktif', _id: true },
        { name: 'Pasif', _id: false },
    ];

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <Card>
                <MDBox mx={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <MDBox my={3}>
                                <Card sx={{ overflow: 'visible' }}>
                                    <MDBox p={3}>
                                        {/* First row of filter components */}
                                        <MDBox mt={3}>
                                            <Grid container spacing={3}>
                                                {gridComponent(
                                                    'Potansiyel Alt Limit',
                                                    <RatingComponent rating={rating} updateRating={updateRating} />
                                                )}
                                                {gridComponent(
                                                    'Sorumlular',
                                                    <Users
                                                        selectedUsers={responsibles}
                                                        setSelectedUsers={updateResponsibles}
                                                    />
                                                )}
                                                {gridComponent(
                                                    'Aktif/Pasif Projeler',
                                                    <Autocomplete
                                                        value={activityOptions.find(
                                                            (option) => option._id === isActive
                                                        )}
                                                        options={activityOptions}
                                                        getOptionLabel={(option) => option.name || ''}
                                                        getOptionSelected={(option, value) => option._id === value._id}
                                                        onChange={(_, value) => updateIsActive(value._id)}
                                                        renderInput={(params) => (
                                                            <MDInput {...params} variant="standard" />
                                                        )}
                                                    />
                                                )}
                                            </Grid>
                                        </MDBox>
                                        {/* Second row of filter components */}
                                        <MDBox mt={6}>
                                            <Grid container spacing={3}>
                                                {gridComponent(
                                                    'Tarih Aralığı',
                                                    <DateRange
                                                        dateRange={dateRange}
                                                        updateDateRange={updateDateRange}
                                                    />
                                                )}
                                                {gridComponent(
                                                    'Proje Kalemleri',
                                                    <Tags
                                                        selectedTags={demandStatus}
                                                        setSelectedTags={updateDemandStatus}
                                                        tagGroupID={DEMAND_STATUS_TAG_GROUP_ID}
                                                    />
                                                )}
                                                {gridComponent(
                                                    'Proje Takip Durumu',
                                                    <Tags
                                                        selectedTags={projectStatus}
                                                        setSelectedTags={updateProjectStatus}
                                                        tagGroupID={PROJECT_STATUS_TAG_GROUP_ID}
                                                    />
                                                )}
                                            </Grid>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        </Dialog>
    );
}

// A reusable component to render each grid item with a label and its children components
const gridComponent = (name, children) => {
    return (
        <Grid item xs={12} sm={4}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <MDTypography variant="caption" fontWeight="bold" mb={1}>
                    {name}
                </MDTypography>
                {children}
            </Box>
        </Grid>
    );
};

export default FilterDialog;
