import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Settings page components
import Dialog from '@mui/material/Dialog';
import FormField from 'layouts/pages/account/components/FormField';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { postCall } from 'services/api/general/call';
import FormSelect from 'layouts/lead/forms/formElements/FormSelect.js';
import LiveSearchAaro from 'layouts/lead/forms/formElements/LiveSearchAaro.js';
import Autocomplete from '@mui/material/Autocomplete';
import { postCustomerByIDv2, getCustomerByID } from 'services/api/customer';
import { updateLead } from 'services/api/lead';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
const validationSchema = yup.object({
    // name: yup
    //     .string('Başlık giriniz')
    //     .min(5, '5 karakterden fazla olmalıdır')
    //     .max(200, '200 karakterden az olmalıdır')
    //     .required('Lütfen geçerli bir başlık giriniz'),
    // createdBy: yup
    //     .string('Bağlı olduğu liste IDsi gereklidir.')
    //     .min(5, '5 karakterden fazla olmalıdır')
    //     .max(200, '200 karakterden az olmalıdır')
    //     .required('Bağlı olduğu liste IDsi gereklidir'),
    // callContent: yup
    //     .string('Bağlı olduğu liste IDsi gereklidir.')
    //     .min(5, '5 karakterden fazla olmalıdır')
    //     .max(600, '600 karakterden az olmalıdır')
    //     .required('Bağlı olduğu liste IDsi gereklidir'),
});

export default function InputArea(props) {
    const { initial, handleClose, open } = props;

    const queryClient = useQueryClient();

    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    const leadID = initial?._id || '';

    const [firstCustomer, setFirstCustomer] = useState(initial?.customer?._id || '');

    useEffect(() => {
        setFirstCustomer(initial?.customer?._id);
    }, [initial]);

    const formik = useFormik({
        initialValues: {
            name: initial?.name || '',
            description: initial?.description || '',
            customer: initial?.customer?._id || '',
            potential: initial?.potential || 0,
            estimatedCost: initial?.estimatedCost || 0,
            isActive: initial?.isActive || true,
            order: initial?.order || -1,
            priority: initial?.priority || 0,
            coverImage: initial?.coverImage || '',
            tempName: initial?.customer?.name + ' -' + initial?.customer?.customerID || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setDisableOnSubmit(true);

            try {
                // console.log('firstCustomer');
                // console.log(firstCustomer);
                // console.log('values');
                // console.log(values.customer);
                if (firstCustomer === values.customer) {
                    updateLead(leadID, values).then((response) => {
                        notify(response.data.success, response.data.message);
                        queryClient.invalidateQueries(['fetchLeads']);
                        handleClose();
                    });
                } else {
                    const customer = values.customer;
                    const customerName = customer.split('-')[0];
                    const customerID = customer.split('-')[1];
                    // ! what if server doesnt save the cari?
                    await postCustomerByIDv2(customerID, customerName)
                        .then((rp) => console.log(rp))
                        .catch((err) => console.log(err));

                    // ! This step written for control purpose.
                    const isSuccessfullyAdded = await getCustomerByID(customerID).then((rp) => rp.data.result);

                    values.customer = isSuccessfullyAdded?._id;
                    // ! If we have this customer we can create project on it
                    if (isSuccessfullyAdded?.customerID == customerID) {
                        updateLead(leadID, values).then((response) => {
                            notify(response.data.success, response.data.message);
                            queryClient.invalidateQueries(['fetchLeads']);
                            handleClose();
                        });
                    }
                }
            } catch (err) {
                notify(false, JSON.stringify(err));
                handleClose();
                queryClient.invalidateQueries(['fetchLeads']);

                // setOpen(false);
            }

            // postCall(values)
            //     .then((rp) => {
            //         notify(rp.data.success, rp.data.message);
            //         handleClose();
            //         queryClient.invalidateQueries(['fetchCalls']);
            //     })
            //     .catch((err) => notify(false, JSON.stringify(err)));
        },
    });

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={formik.handleSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    required
                                    label="Fırsat Başlığı"
                                    placeholder="Ör: Balıkesir 300 Kapı"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LiveSearchAaro
                                    itemID="customer"
                                    formik={formik}
                                    inputName="Müşteri"
                                    helperText={'Fırsatın Potansiyeli'}
                                    tempName={formik.values.tempName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    required
                                    label="Kapak Görseli"
                                    placeholder="url"
                                    id="coverImage"
                                    name="coverImage"
                                    value={formik.values.coverImage}
                                    onChange={formik.handleChange}
                                    error={formik.touched.coverImage && Boolean(formik.errors.coverImage)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel id="demo-simple-select-label">Potansiyel</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="potential"
                                        name="potential"
                                        value={formik.values.potential}
                                        label="Potansiyel"
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormField
                                    required
                                    label="Kısa Açıklama"
                                    placeholder="Ör: Otele yangın dayanıklı kapılar talep ediliyor. "
                                    id="description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormField
                                    required
                                    label="Tahmini Değer"
                                    placeholder="Ör:100000 "
                                    id="estimatedCost"
                                    name="estimatedCost"
                                    value={formik.values.estimatedCost}
                                    onChange={formik.handleChange}
                                    error={formik.touched.estimatedCost && Boolean(formik.errors.estimatedCost)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel id="demo-simple-select-label">Aktif Mi</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="isActive"
                                        name="isActive"
                                        value={formik.values.isActive}
                                        label="Aktif Mi"
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={true}>Aktif</MenuItem>
                                        <MenuItem value={false}>Pasif</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormField
                                    required
                                    label="Sıra"
                                    placeholder="Ör:3"
                                    id="order"
                                    name="order"
                                    value={formik.values.order}
                                    onChange={formik.handleChange}
                                    error={formik.touched.order && Boolean(formik.errors.order)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormField
                                    required
                                    label="Öncelik"
                                    placeholder="Ör:-1 "
                                    id="priority"
                                    name="priority"
                                    value={formik.values.priority}
                                    onChange={formik.handleChange}
                                    error={formik.touched.priority && Boolean(formik.errors.priority)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <MDButton
                                        fullWidth
                                        size="small"
                                        variant="gradient"
                                        color="dark"
                                        type="submit"
                                        disabled={disableOnSubmit}
                                    >
                                        Düzenle
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
                {/* {JSON.stringify(formik.values)} */}
            </Card>
        </Dialog>
    );
}
