import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getTaskLists = (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/taskList?${query}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getTaskList = (searchQuery) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/taskList?${searchQuery}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postTaskList = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/taskList`;
    return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it updates task
 * @return promise - axios returns a promise
 */
export const updateTaskList = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/taskList/${id}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it deletes parent task and child tasks
 * @return promise - axios returns a promise
 */
export const deleteTaskList = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/taskList/${id}`;
    return axios.delete(url, { headers });
};
