import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given relation id, it retrieves all the files
 * @return promise - axios returns a promise
 */
export const getTags = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/tag-group/${id}`;
	return axios.get(url, { headers });
};

/**
 * @desc for given relation id, it retrieves all the files
 * @return promise - axios returns a promise
 */
export const updateTag = (id, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/tag-group/tag/${id}`;
	return axios.put(url, data, { headers });
};

/**
 * @desc for given relation id, it retrieves all the files
 * @return promise - axios returns a promise
 */
export const postTag = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/tag-group/tag`;
	return axios.post(url, data, { headers });
};

/**
 * @desc for given relation id, it retrieves all the files
 * @return promise - axios returns a promise
 */
export const deleteTag = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/general/tag-group/tag/${id}`;
	return axios.delete(url, { headers });
};
