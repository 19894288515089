import { useState } from 'react';
// Material Dashboard 2 PRO React components

import MDButton from 'components/MDButton';
import FreezeModal from './FreezeModal';
function FreezeDemand({ demandID, projectID, setValue }) {
	const [open, setOpen] = useState(false);

	const user = JSON.parse(localStorage.getItem('user'));
	return (
		<>
			<MDButton
				variant="gradient"
				color="secondary"
				onClick={() => setOpen(true)}
			>
				Onayla
			</MDButton>
			<FreezeModal
				open={open}
				setOpen={setOpen}
				demandID={demandID}
				projectID={projectID}
				setValue={setValue}
			/>
		</>
	);
}

export default FreezeDemand;
