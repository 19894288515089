import { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

// formik components
import { Formik, Form } from 'formik';
import { postGroup, updateGroup } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// TemplateForm page components
import Wall from 'layouts/projects/Forms/Templates/TemplateForm/components/Wall';
import DoorDetails from 'layouts/projects/Forms/Templates/TemplateForm/components/DoorDetails';
import Profile from 'layouts/projects/Forms/Templates/TemplateForm/components/Profile';

// TemplateForm layout schemas for form and form feilds
import validations from 'layouts/projects/Forms/Templates/TemplateForm/schemas/validations';
import form from 'layouts/projects/Forms/Templates/TemplateForm/schemas/form';
import initialValues from 'layouts/projects/Forms/Templates/TemplateForm/schemas/initialValues';

import { postKasa, postPervaz, postUKasa, postUPervaz, postDiger, postKanat } from 'services/api/form';

import { doorTemplateCalculator } from 'layouts/projects/Forms/Templates/TemplateForm/calculator/index.js';
import { getDemand } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';

function getSteps() {
    return ['DUVAR BİLGİSİ', 'ÖLÇÜLER', 'ÖZET'];
}

function getStepContent(stepIndex, formData, door) {
    switch (stepIndex) {
        case 0:
            return <Wall formData={formData} />;
        case 1:
            return <DoorDetails formData={formData} />;
        case 2:
            return <Profile formData={formData} door={door} />;
        // case 3:
        //     return <Profile formData={formData} />;
        default:
            return null;
    }
}

const fetchDemand = async (id) => {
    const response = await getDemand(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

function TemplateForm({ door, demandID, handleClose }) {
    const [activeStep, setActiveStep] = useState(0);
    const queryClient = useQueryClient();

    const createGroupMutation = useMutation(
        (groupObj) => {
            return postGroup(groupObj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
            },
        }
    );

    const {
        data: demandData,
        isLoading,
        error,
    } = useQuery(['fetchDemand', door.demand], () => fetchDemand(door.demand), {
        staleTime: 600000,
    });

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const handleBack = () => setActiveStep(activeStep - 1);

    const submitForm = async (values, actions) => {
        // await sleep(1000);

        const updatedTemplate = doorTemplateCalculator(demandData, values);
        const doorItems = updatedTemplate;

        // Check if key has values and save them
        const postFunctions = {
            pervaz: postPervaz,
            kasa: postKasa,
            kanat: postKanat,
            diger: postDiger,
            uKasa: postUKasa,
            uPervaz: postUPervaz,
        };

        // Group keys
        const groupKeys = {
            pervaz: [],
            kasa: [],
            kanat: [],
            diger: [],
            uKasa: [],
            uPervaz: [],
        };

        const processDetails = async (key, details) => {
            if (!postFunctions[key] || details.length === 0) {
                return;
            }

            for (const detail of details) {
                const postResult = await postFunctions[key](demandID, detail);
                const id = postResult?.data?.result?._id;
                const amount = postResult?.data?.result?.miktar;
                groupKeys[key].push({ ref: id, quantity: amount });
                await sleep(200);
            }
        };

        for (let key in doorItems) {
            await processDetails(key, doorItems[key], demandID);
        }

        // create group
        const groupResult = await createGroupMutation.mutateAsync({
            demand: demandID,
            groupName: 'yeni grup',
            ...groupKeys,
        });
        console.log(groupResult);

        // eslint-disable-next-line no-alert
        // alert(JSON.stringify(values, null, 2));
        queryClient.invalidateQueries(['fetchDemand', demandID]);
        handleClose();
        actions.setSubmitting(false);
        actions.resetForm();

        setActiveStep(0);
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    return (
        <MDBox py={3}>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%', mt: 1 }}>
                <Grid item xs={12} lg={12}>
                    <Formik initialValues={initialValues} validationSchema={currentValidation} onSubmit={handleSubmit}>
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: '100%' }}>
                                    <MDBox mx={2} mt={-3}>
                                        <Stepper activeStep={activeStep} alternativeLabel>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </MDBox>
                                    <MDBox p={3}>
                                        <MDBox>
                                            {getStepContent(
                                                activeStep,
                                                {
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                },
                                                demandData
                                            )}
                                            <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                                                {activeStep === 0 ? (
                                                    <MDBox />
                                                ) : (
                                                    <MDButton variant="gradient" color="light" onClick={handleBack}>
                                                        GERİ
                                                    </MDButton>
                                                )}
                                                <MDButton
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="dark"
                                                >
                                                    {isLastStep ? 'OLUŞTUR' : 'İLERİ'}
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default TemplateForm;
