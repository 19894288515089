/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getProjectStatusColor = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'KAZANILDI':
            return 'linear-gradient(to right, #52c234, #061700)';
        case 'KAYBEDILDI':
            return 'linear-gradient(to right, #870000, #190a05)';
        case 'TEKLIF GÖNDERILDI':
            return 'linear-gradient(to right, #0575e6, #021b79)';

        default:
            return 'linear-gradient(to right, #485563, #29323c)';
    }
};

export const getDefaultColor = () =>
    'linear-gradient(to right, #485563, #29323c)';
export const getDuzeltilmeliColor = () =>
    'linear-gradient(to right,  #a73737, #7a2828)';
export const getFiyatBekleniyorColor = () =>
    'linear-gradient(to right, #44a08d, #093637)';
export const getTamamlandiColor = () =>
    'linear-gradient(to right, #485563, #29323c)';
export const getYeniColor = () => 'linear-gradient(to left, #4e54c8, #8f94fb)';
export const getTeklifGonderildiColor = () =>
    'linear-gradient(to right, #0575e6, #021b79)';
export const getTeklifKaybedildiColor = () =>
    'linear-gradient(to right, #870000, #190a05)';
export const getTeklifKazanildiColor = () =>
    'linear-gradient(to right, #52c234, #061700)';
export const getTeklifUretildiColor = () =>
    'linear-gradient(to right, #603813, #b29f94)';
export const getTeklifUretiliyorColor = () =>
    'linear-gradient(to right, #f7971e, #ffd200)';
export const getRevizeBekleniyorColor = () =>
    'linear-gradient(90deg, hsla(42, 94%, 57%, 0.7) 0%, hsla(211, 16%, 34%, 1) 83%)';

export const tamamlandiButtonStyle = {
    background: getTamamlandiColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};
export const fiyatBekleniyorButtonStyle = {
    background: getFiyatBekleniyorColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};
export const duzeltilmeliButtonStyle = {
    background: getDuzeltilmeliColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};
export const revizeBekleniyorButtonStyle = {
    background: getRevizeBekleniyorColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};

export const yeniButtonStyle = {
    background: getYeniColor(),
    color: 'white',
    width: '120px',
    height: '20px',
};

export const buttonStyleDecider = (name) => {
    switch (name) {
        case 'Fiyat Verildi':
            return tamamlandiButtonStyle;
        case 'Fiyat Bekleniyor':
            return fiyatBekleniyorButtonStyle;
        case 'Revize Bekleniyor':
            return revizeBekleniyorButtonStyle;
        case 'Düzeltilmeli':
            return duzeltilmeliButtonStyle;

        default:
            return yeniButtonStyle;
    }
};

export const demandStatusNameFinder = (demand) => {
    let duzeltilmeliNumber = 0;
    let fiyatBekleniyorNumber = 0;
    let revizeBekleniyorNumber = 0;
    let tamamlandiNumber = 0;
    const demandNamesWeWant = [
        'uKasa',
        'kasa',
        'kanat',
        'uPervaz',
        'pervaz',
        'diger',
    ];

    Object.keys(demand).forEach((key) => {
        if (demandNamesWeWant.includes(key)) {
            demand[key].forEach((el) => {
                if (el.durumu?.name === 'Düzeltilmeli') {
                    duzeltilmeliNumber++;
                } else if (el.durumu?.name === 'Fiyat Bekleniyor') {
                    fiyatBekleniyorNumber++;
                } else if (el.durumu?.name === 'Revize Bekleniyor') {
                    revizeBekleniyorNumber++;
                } else if (el.durumu?.name === 'Fiyat Verildi') {
                    tamamlandiNumber++;
                }
            });
        }
    });
    if (duzeltilmeliNumber > 0) {
        return 'Düzeltilmeli';
    }
    if (fiyatBekleniyorNumber > 0) {
        return 'Fiyat Bekleniyor';
    }
    if (revizeBekleniyorNumber > 0) {
        return 'Revize Bekleniyor';
    }
    if (tamamlandiNumber > 0) {
        return 'Fiyat Verildi';
    }

    return 'Yeni';
};

// export const demandStatusNameFinder = (obj) => {
//     const name = JSON.stringify(obj);
//     if (name.includes('Düzeltilmeli')) return 'Düzeltilmeli';
//     if (name.includes('Fiyat Bekleniyor')) return 'Fiyat Bekleniyor';
//     if (name.includes('Revize Bekleniyor')) return 'Revize Bekleniyor';
//     if (name.includes('Fiyat Verildi')) return 'Fiyat Verildi';
//     return 'Yeni';
// };
