import React, { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { updateProject } from 'services/api/project';
import Button from '@mui/material/Button';
// import { getDefaultColor } from 'helpers/colorHelper';
import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { getTags } from 'services/api/general/tag';
import { config } from 'Constants';
import AaroLoadingScreen from 'components/Loading';
import { updateTask, postTask } from 'services/api/general/task';

import { getUsers } from 'services/api/user';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { FormatItalic } from '@mui/icons-material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Stack from '@mui/material/Stack';
import trLocale from 'date-fns/locale/tr';
import { postParameter } from 'services/api/general/parameter';

const validationSchema = yup.object({
	key: yup
		.string('Başlık giriniz')
		.min(5, '5 karakterden fazla olmalıdır')
		.max(200, '200 karakterden az olmalıdır')
		.required('Lütfen geçerli bir başlık giriniz'),
	value: yup
		.string('Bağlı olduğu liste IDsi gereklidir.')
		.min(5, '5 karakterden fazla olmalıdır')
		.max(200, '200 karakterden az olmalıdır')
		.required('Bağlı olduğu liste IDsi gereklidir'),
});

const fetchTags = async (id) => {
	const response = await getTags(id).then((rp) =>
		rp.data?.result?.tags ? rp.data?.result?.tags : []
	);
	return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.PARAMS_CATEGORY_GROUP_ID;

export default function InputArea(props) {
	const { initial, setOpenDialog } = props;

	const queryClient = useQueryClient();

	const {
		data: tags,
		isLoading,
		error,
	} = useQuery(
		['fetchTagGroups', TAG_GROUP_ID_DEMAND],
		() => fetchTags(TAG_GROUP_ID_DEMAND),
		{ staleTime: 600000 }
	);

	const user = JSON.parse(localStorage.getItem('user'));
	const [disableOnSubmit, setDisableOnSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			key: initial?.key || '',
			value: initial?.value || '',
			description: initial?.description || '',
			tag: initial?.tag || '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setDisableOnSubmit(true);
			const findTagID = tags?.find((tag) => tag._id === values.tag._id);
			values.tag = findTagID?._id;

			postParameter(values).then((rp) => {
				queryClient.invalidateQueries(['fetchParamaters']);
				notify(rp.data.success, rp.data.message);
				setOpenDialog(false);
			});
		},
	});
	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	// useEffect(() => {
	// 	setProjectName(project.projectName);
	// }, [props]);
	return (
		<Card id="basic-info" sx={{ overflow: 'visible' }}>
			<form onSubmit={formik.handleSubmit}>
				<MDBox p={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormField
								label="Key"
								placeholder="key"
								id="key"
								name="key"
								value={formik.values.key}
								onChange={formik.handleChange}
								error={
									formik.touched.key &&
									Boolean(formik.errors.key)
								}
								helperText={
									formik.touched.key && formik.errors.key
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormField
								label="Value"
								placeholder="value"
								id="value"
								name="value"
								value={formik.values.value}
								onChange={formik.handleChange}
								error={
									formik.touched.value &&
									Boolean(formik.errors.value)
								}
								helperText={
									formik.touched.value && formik.errors.value
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormField
								label="Açıklama"
								placeholder="Açıklama"
								id="description"
								name="description"
								value={formik.values.description}
								onChange={formik.handleChange}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description &&
									formik.errors.description
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								options={tags}
								getOptionLabel={(option) => option?.name}
								value={formik.values.tags}
								onChange={(e, value) => {
									formik.setFieldValue(
										'tag',
										value !== null ? value : initial.tag
									);
								}}
								renderInput={(params) => (
									<FormField
										{...params}
										label="Etiketler"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12} sm={12}>
								<MDButton
									fullWidth
									size="small"
									variant="gradient"
									color="dark"
									type="submit"
									disabled={disableOnSubmit}
								>
									Ekle
								</MDButton>
							</Grid>
						</Grid>
					</Grid>
				</MDBox>
			</form>
		</Card>
	);
}
