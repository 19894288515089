import React, { useState, useEffect } from 'react';
import Map from 'components/General/GoogleMap/Map';
import { updateCustomer } from 'services/api/customer';

import AddProjectPosition from './AddProjectPosition';

import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function ProjectPosition(props) {
	const { customer, showEdit = true } = props;

	const changeLatLng = (val) => {
		// console.log({ lat: val.lat, lng: val.lng, val });

		updateCustomer(customer?._id, {
			location: {
				lat: val.lat,
				lng: val.lng,
				label: val.value.label,
			},
		}).then(() => window.location.reload());
	};
	return (
		<Card id="delete-account">
			<MDBox pt={1} px={2}>
				<MDTypography variant="h6" fontWeight="medium">
					Müşteri Konumu
				</MDTypography>
				<MDTypography variant="caption" fontWeight="text">
					{customer?.location?.label}
				</MDTypography>
			</MDBox>
			<MDBox pb={1}>
				<MDBox display="flex" flexDirection="column">
					<Map
						enableCard={false}
						height={'23vh'}
						zoom={11}
						latLng={{
							lat: customer?.location?.lat
								? customer.location.lat
								: 39.9577965,
							lng: customer?.location?.lng
								? customer.location.lng
								: 32.8952745,
						}}
					/>
					{showEdit && (
						<AddProjectPosition changeLatLng={changeLatLng} />
					)}
				</MDBox>
			</MDBox>
		</Card>
	);
}
