import React from 'react';
import { useQueryClient } from 'react-query';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
// @mui material components
import Icon from '@mui/material/Icon';
import { updateCustomer } from 'services/api/customer';

// Material Dashboard 2 PRO React components
import MDButton from 'components/MDButton';
const useStyles = makeStyles({
	avatar: {},
	profilePicture: {
		color: 'transparent',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		textAlign: 'center',
		textIndent: '10000px',
	},
});

function SimpleDialog(props) {
	const queryClient = useQueryClient();
	const classes = useStyles();
	const {
		onClose,
		selectedValue,
		open,
		allResponsibles,
		customerID,
		users,
		setOpen,
		query,
	} = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const updateResponsibles = async (user) => {
		const sorumlular = allResponsibles.map(
			(responsible) => responsible._id
		);

		if (sorumlular.includes(user._id)) {
			const index = sorumlular.indexOf(user._id);
			sorumlular.splice(index, 1);
			updateCustomer(customerID, {
				responsibles: sorumlular,
			}).then((rp) => {
				queryClient.invalidateQueries(['fetchCustomers']);
				setOpen(false);
			});
		} else {
			updateCustomer(customerID, {
				responsibles: [...allResponsibles, user._id],
			}).then((rp) => {
				queryClient.invalidateQueries(['fetchCustomers']);
				setOpen(false);
			});
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
		>
			<DialogTitle id="simple-dialog-title">
				Projeye sorumlu ekle
				{/* {JSON.stringify(users)} */}
			</DialogTitle>
			<List>
				{allResponsibles &&
					users?.map((user) => (
						<ListItem
							button
							onClick={() => updateResponsibles(user)}
							key={user}
							selected={allResponsibles.some(
								(el) => el._id === user._id
							)}
						>
							<ListItemAvatar>
								<Avatar className={classes.avatar}>
									{user.profilePicture ? (
										<img
											src={user.profilePicture}
											alt="..."
											className={classes.profilePicture}
										/>
									) : (
										<PersonIcon />
									)}
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={user.name} />
						</ListItem>
					))}
			</List>
		</Dialog>
	);
}

export default function AddResponsible(props) {
	const { allResponsibles, customerID, setUpdateResponsibles, users, query } =
		props;
	const [open, setOpen] = React.useState(false);
	const user = JSON.parse(localStorage.getItem('user'));
	return (
		<>
			<MDButton
				variant="outlined"
				color="dark"
				iconOnly
				onClick={() => setOpen(true)}
				disabled={user.role !== 'superadmin'}
			>
				<Icon sx={{ fontWeight: 'bold' }}>add</Icon>
			</MDButton>
			<SimpleDialog
				setOpen={setOpen}
				open={open}
				setUpdateResponsibles={setUpdateResponsibles}
				onClose={() => setOpen(false)}
				allResponsibles={allResponsibles}
				customerID={customerID}
				users={users}
				query={query}
			/>
		</>
	);
}
