import ReactDiffViewer from 'react-diff-viewer';
import Dialog from '@mui/material/Dialog';

function DiffViwer(props) {
    const { oldCode, newCode, open, handleClose, type } = props;

    const oldCodeObj = oldCode.length > 2 ? JSON.parse(oldCode) : {};
    const newCodeObj = newCode.length > 2 ? JSON.parse(newCode) : {};

    let availableKeysOLD = Object.keys(oldCodeObj);
    let availableKeysNEW = Object.keys(newCodeObj);

    let oldValueSTR = '';
    let newValueSTR = '';

    const stringifyValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value, null, 2); // 2 is the number of spaces for indentation
        }
        return value;
    };

    if (type === 'delete')
        availableKeysOLD.forEach((element) => {
            oldValueSTR += element + ': ' + stringifyValue(oldCodeObj[element]) + '\n';
            newValueSTR += element + ': ' + stringifyValue(newCodeObj[element]) + '\n';
        });
    else
        availableKeysNEW.forEach((element) => {
            oldValueSTR += element + ': ' + stringifyValue(oldCodeObj[element]) + '\n';
            newValueSTR += element + ': ' + stringifyValue(newCodeObj[element]) + '\n';
        });
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            {user.role === 'superadmin' ? (
                <ReactDiffViewer oldValue={oldValueSTR} newValue={newValueSTR} splitView={true} />
            ) : (
                "You don't have permission to view this page"
            )}
        </Dialog>
    );
}

export default DiffViwer;
