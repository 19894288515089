import { useState } from 'react';
import MDBox from 'components/MDBox';
import Dialog from '@mui/material/Dialog';
import SinglePurchase from 'layouts/purchases/pages/singleLead/index.js';

import { colorAndLabelDecider } from 'layouts/purchases/helpers/general';
import CardHeader from './components/CardHeader';
import CardBody from './components/CardBody';
import CardFooter from './components/CardFooter';

import { isMobile } from 'helpers/smallCodes';
import MDProgress from 'components/MDProgress';

function Card({ data: wooOrder }) {
    const [openPurchase, setOpenPurchase] = useState(false);

    const {
        id: wooOrderId,
        date_created,
        shipping,
        total,
        payment_method_title,
        customer_note,
        line_items,
        status,
        date_modified,
        purchase,
    } = wooOrder?.content;

    const { color, label } = colorAndLabelDecider(date_created);

    const handleDoubleClick = (e) => {
        e.preventDefault();
        if (!isMobile()) {
            setOpenPurchase(true);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (isMobile()) {
            window.location.href = `/purchase/${wooOrderId}`;
        }
    };

    const completionPercentage = purchase?.taskList?.completionPercentage;

    return (
        <MDBox
            onDoubleClick={handleDoubleClick}
            onClick={handleClick}
            component="a"
            href={`/purchase/${wooOrderId}`}
            target="_blank"
            sx={{
                opacity: status === 'completed' ? 0.6 : 1,
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
                display: 'block',
                WebkitTapHighlightColor: 'transparent',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
            }}
        >
            <CardHeader
                color={color}
                label={label}
                shipping={shipping}
                estimatedCost={total}
                state={shipping?.state}
                status={status}
                purchase={purchase}
                // priority={priority}
                // trackingNumber={trackingNumber}
                // customerRating={customerRating}
            />
            <CardBody
                name={`#${wooOrderId} - ${shipping?.first_name} ${shipping?.last_name}`}
                line_items={line_items}
                purchase={purchase}
                status={status}
            />
            <CardFooter
                orderCost={total}
                source={payment_method_title}
                interests={line_items?.map((item) => item.name)}
                data={wooOrder?.content}
                customer_note={customer_note}
                date_modified={date_modified}
                date_created={date_created}
                status={status}
                purchase={purchase}
            />
            {
                <MDProgress
                    value={completionPercentage}
                    color="success"
                    variant="gradient"
                    sx={{
                        height: '2px',
                        '& .MuiLinearProgress-bar': {
                            height: '2px',
                        },
                    }}
                />
            }
            <Dialog
                onClose={() => setOpenPurchase(false)}
                open={openPurchase}
                fullWidth={true}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                <MDBox bgColor="light" variant="gradient">
                    <SinglePurchase
                        incomingID={wooOrderId}
                        wooCommerceOrderDetails={wooOrder?.content}
                        isModal={true}
                    />
                </MDBox>
            </Dialog>
        </MDBox>
    );
}

export default Card;
