import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { config } from 'Constants';
import { createOfferGoogle, createGroupedOfferGoogle } from 'services/api/google';
import { notify } from 'helpers/notificationHelper';

function ProductInfo(props) {
    const { setSelectedTemplate, selectedTemplate, offerTemplates, offerSettings, project, demand } = props;
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(offerSettings?.availableCategories[0]);
    const [selectedLanguage, setSelectedLanguage] = useState(offerSettings?.availableLanguages[0]);

    const [filteredOfferTemplates, setFilteredOfferTemplates] = useState(offerTemplates);
    const user = JSON.parse(localStorage.getItem('user'));
    const userFolderID = config?.google?.userOfferFolders[user?.username?.toUpperCase()];

    useEffect(() => {
        const filter = offerTemplates.filter(
            (template) => template.offerCategory === selectedCategory && template.language === selectedLanguage
        );
        setFilteredOfferTemplates(filter);
    }, [offerTemplates, selectedCategory, selectedLanguage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            rootFolderID: userFolderID,
            projectName: project?.projectName,
            customerName: project?.aaroCustomerName,
            customerID: project?.aaroCustomerID,
            folderName: config.google.priceListFolderName,
            offerSpreadsheetTemplateID: selectedTemplate?.offerSpreadsheetTemplateID,
            offerSheetID: selectedTemplate?.offerSheetID,
        };
        // console.log(data);
        if (selectedTemplate.isGrouped) {
            createGroupedOfferGoogle(demand._id, data)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    setLoading(false);
                    const createdSpreadsheet = rp?.data?.result?.spreadsheetId;
                    console.log(rp);
                    const googleSheetURL = `https://docs.google.com/spreadsheets/d/${createdSpreadsheet}`;
                    if (rp.data.success) window.open(googleSheetURL, '_blank').focus();
                })
                .catch((err) => {
                    notify(false, JSON.stringify(err));
                    setLoading(false);
                });
        } else {
            createOfferGoogle(demand._id, data)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    setLoading(false);
                    const createdSpreadsheet = rp?.data?.result?.spreadsheetId;
                    console.log(rp);
                    const googleSheetURL = `https://docs.google.com/spreadsheets/d/${createdSpreadsheet}`;
                    if (rp.data.success) window.open(googleSheetURL, '_blank').focus();
                })
                .catch((err) => {
                    notify(false, JSON.stringify(err));
                    setLoading(false);
                });
        }
    };
    return (
        <MDBox>
            <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                    Şablon Seçim Detayları {selectedCategory} {selectedLanguage}
                </MDTypography>
            </MDBox>

            <MDBox mt={3} mb={1} ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Notlar
                </MDTypography>
            </MDBox>
            <MDBox component="ul" m={0} pl={4} mb={2}>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                    <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                        Fiyat teklifi seçmiş olduğunuz şablona aktarılacaktır.
                    </MDTypography>
                </MDBox>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                    <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                        Doğrudan iskonto uygulanmış olarak aktarım yapılmaktadır.
                    </MDTypography>
                </MDBox>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                    <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                        Göndermeden önce tüm ürünlerin fiyatlarının doğruluğunu kontrol ediniz.
                    </MDTypography>
                </MDBox>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                    <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                        Önceki tekliflerinizi Google Drive üzerinden bulabilirsiniz.
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <MDBox mb={1.5} lineHeight={0} display="inline-block">
                            <MDTypography component="label" variant="button" color="text" fontWeight="regular">
                                Teklif Dili
                            </MDTypography>
                        </MDBox>
                        <Autocomplete
                            defaultValue={selectedLanguage}
                            options={offerSettings?.availableLanguages}
                            onChange={(event, newValue) => {
                                setSelectedLanguage(newValue);
                            }}
                            renderInput={(params) => <MDInput {...params} variant="standard" />}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MDBox mb={1.5} lineHeight={0} display="inline-block">
                            <MDTypography component="label" variant="button" color="text" fontWeight="regular">
                                Teklif Tipi
                            </MDTypography>
                        </MDBox>
                        <Autocomplete
                            defaultValue={selectedCategory}
                            options={offerSettings?.availableCategories}
                            onChange={(event, newValue) => {
                                setSelectedCategory(newValue);
                            }}
                            renderInput={(params) => <MDInput {...params} variant="standard" />}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MDBox mb={1.5} lineHeight={0} display="inline-block">
                            <MDTypography component="label" variant="button" color="text" fontWeight="regular">
                                Mevcut Şablonlar
                            </MDTypography>
                        </MDBox>
                        <Autocomplete
                            defaultValue={filteredOfferTemplates[0]}
                            options={filteredOfferTemplates}
                            getOptionLabel={(option) => option?.templateName}
                            onChange={(e, value) => {
                                setSelectedTemplate(value);
                            }}
                            renderInput={(params) => <MDInput {...params} variant="standard" />}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={3}>
                <Grid item xs={12} lg={5} container>
                    <MDButton
                        variant="gradient"
                        color={loading ? 'dark' : 'success'}
                        fullWidth
                        onClick={handleSubmit}
                        disabled={loading || !selectedTemplate}
                    >
                        {loading ? 'Oluşturuluyor...' : 'Teklif Oluştur'}
                    </MDButton>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

export default ProductInfo;
