import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Leaflet CSS'ini ekleyin
import './markerAnimation.css'; // Yanıp sönme animasyonu için CSS
import DefaultProjectCard from 'layouts/lead/pages/allLeads/LeadsMap/DefaultProjectCard';
// Marker ikonlarını import edin
import greenIcon from './markers/green.png';
import yellowIcon from './markers/yellow.png';
import redIcon from './markers/red.png';
import { calculateTimePassed } from 'helpers/dateHelpers';

function RiskMap({ data }) {
    const mapStyle = {
        height: '450px',
        width: '100%',
    };

    const mapColorAndLabelDecider = (date) => {
        const days = calculateTimePassed(date);

        let iconUrl = greenIcon;
        let className = '';
        if (days < 7) {
            iconUrl = greenIcon;
        } else if (days > 7 && days < 30) {
            iconUrl = yellowIcon;
        } else {
            className = 'blinking';
            iconUrl = redIcon;
        }
        return L.icon({
            iconUrl,
            iconSize: [36, 36],
            iconAnchor: [12, 36],
            popupAnchor: [1, -34],
            shadowSize: [36, 36],
            className: className, // Custom CSS sınıfını ikona ekle
        });
    };

    return (
        <MapContainer center={[39.925533, 32.866287]} zoom={6} style={mapStyle}>
            <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" //  Akif reyiz için sadeleştirildi
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {data?.map((lead, index) => {
                const {
                    _id: leadID,
                    name,
                    location,
                    estimatedCost,
                    estimatedCostUnit,
                    // potential,
                    description,
                    responsibles,
                    createdAt,
                    coverImage,
                } = lead;
                const { lat, lng } = location || { lat: 0, lng: 0 };
                const iconUrl = mapColorAndLabelDecider(createdAt);
                return (
                    <Marker key={index} position={[lat, lng]} icon={iconUrl}>
                        <Popup>
                            <DefaultProjectCard
                                leadID={leadID}
                                image={
                                    coverImage || 'https://www.kahramanlar.dev/static/media/kahramanlar.490a0014.png'
                                }
                                label={estimatedCost + ' - ' + estimatedCostUnit} // Name of the project or responsible person
                                title={name} // Project title from JSON data
                                description={description} // Description from JSON data
                                authors={
                                    responsibles?.map((el) => {
                                        return { name: el.name, image: el.profilePicture };
                                    }) || []
                                } // Responsible person from JSON data
                            />
                        </Popup>
                    </Marker>
                );
            })}
        </MapContainer>
    );
}

export default RiskMap;
