// Libraries
import { memo } from 'react';

// MDP2 Components
import MDBox from 'components/MDBox';

import MDAvatar from 'components/MDAvatar';

// MUI V5 Components
import { Tooltip } from '@mui/material';

// Assets
import team4 from 'assets/images/team-4.jpg';

export const ProjectResponsibles = memo(({ responsibles }) => {
    return (
        <MDBox display="flex">
            <MDBox display="flex" flexDirection="row" justifyContent="space-evenly">
                {responsibles?.map((el, index) => (
                    <Tooltip key={el.id || index} title={el.name} placement="top">
                        <MDAvatar
                            key={el.id || index}
                            src={el.profilePicture ? el.profilePicture : team4}
                            alt={el.name}
                            size="xs"
                            sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                border: `${borderWidth[2]} solid ${white.main}`,
                                cursor: 'pointer',
                                position: 'relative',

                                '&:not(:first-of-type)': {
                                    ml: -1.25,
                                },

                                '&:hover, &:focus': {
                                    zIndex: '10',
                                },
                            })}
                        />
                    </Tooltip>
                ))}
            </MDBox>
        </MDBox>
    );
});
