import React from 'react';
import { useField } from 'formik';
import { TextField, FormControl, InputLabel, Select, MenuItem, Box, FormHelperText } from '@mui/material';
import ColoredTagSelection from 'components/Tags/ColoredTagSelectionGeneral';

export const FormField = ({ as, name, label, options, tagGroupID, ...props }) => {
    const [field, meta, helpers] = useField(name);
    if (as === 'coloredTagSelection') {
        return (
            <Box margin="normal">
                <ColoredTagSelection
                    tagGroupID={tagGroupID}
                    value={field.value}
                    onChange={(newValue) => helpers.setValue(newValue)}
                    tagLabel={label}
                    multiple={props.multiple}
                />
                {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
            </Box>
        );
    }

    if (as === 'select') {
        return (
            <FormControl fullWidth margin="normal" variant="outlined" error={meta.touched && Boolean(meta.error)}>
                <InputLabel id={`${name}-label`}>{label}</InputLabel>
                <Select
                    labelId={`${name}-label`}
                    id={name}
                    {...field}
                    {...props}
                    label={label}
                    displayEmpty
                    sx={{
                        height: '56px', // TextField'ın varsayılan yüksekliği
                        '& .MuiOutlinedInput-notchedOutline': {
                            // Outline stilini TextField ile eşleştir
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.87)',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                            borderWidth: '2px',
                        },
                    }}
                >
                    {options?.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
        );
    }

    return (
        <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            id={name}
            label={label}
            {...field}
            {...props}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />
    );
};

export const SelectMultiple = ({ name, label, tagGroupID }) => {
    const [field, meta, helpers] = useField(name);

    return (
        <Box margin="normal">
            <ColoredTagSelection
                tagGroupID={tagGroupID}
                value={field.value}
                onChange={(newValue) => helpers.setValue(newValue)}
                tagLabel={label}
                multiple={true}
            />
            {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </Box>
    );
};
