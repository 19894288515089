// Libraries
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { postCustomer } from 'services/api/customer.js';

// Layout
import ProjectLayout from 'layouts/customers/pages/SingleCustomer/CustomerLayout.js';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import { Divider } from '@mui/material';

// @mui material components
import Grid from '@mui/material/Grid';
import MDTypography from 'components/MDTypography';

// Project Pages And Components
import Responsibles from 'layouts/customers/components/Responsibles/index.js';
import ColoredTagSelection from 'components/Tags/ColoredTagSelection';

import MainPage from 'layouts/customers/pages/SingleCustomer/MainPage/index.js';
import AaroLoadingScreen from 'components/Loading';
import Chat from 'components/General/Chat';
import FileView from 'components/General/File';
import TaskListModalView from 'components/General/Task/Views/TaskList/ModalView';

import { config } from 'Constants';

// API
import { updateCustomer, getCustomers } from 'services/api/customer';
import { getCari } from 'services/outside/aaro/customer';

const fetchAaroCustomer = async (id) => {
	const response = await getCari(id).then((rp) => rp[0]);
	return response;
};

const fetchCustomers = async (query) => {
	const response = await getCustomers(query)
		.then((rp) => rp.data.result)
		.catch((err) => err);
	return response;
};

function GeneralPage() {
	const { id } = useParams();
	const query = `customerID=${id}`;
	const [value, setValue] = useState(0);
	const [updateFiles, setUpdateFiles] = useState(0);
	const user = JSON.parse(localStorage.getItem('user'));
	const { userID } = user;

	const { data, isLoading, error } = useQuery(
		['fetchCustomers', query],
		() => fetchCustomers(query),
		{ staleTime: 1000 * 15 }
	);

	const { data: AaroCustomer } = useQuery(
		['fetchAaroCustomer', id],
		() => fetchAaroCustomer(id),
		{ staleTime: 6000000 }
	);

	const handleChanges = (key, value) => {
		const tag = {};
		const customerID = data[0]?._id;
		tag[key] = Array.isArray(value)
			? value.map((el) => el._id)
			: value?._id;
		updateCustomer(customerID, tag).catch((err) => {
			console.log(err);
		});
	};

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	const handlePageChanges = (pageNumber) => {
		switch (pageNumber) {
			case 0:
				return (
					<MainPage
						updateFiles={updateFiles}
						setUpdateFiles={setUpdateFiles}
						customer={data[0]}
					/>
				);
			default:
				return <p>...page</p>;
		}
	};

	const CUSTOMER_TYPE_GROUP_ID = config.fixedID.CUSTOMER_TYPE_GROUP_ID;
	const CUSTOMER_PROFILE_GROUP_ID = config.fixedID.CUSTOMER_PROFILE_GROUP_ID;
	const CUSTOMER_INTERESTS_GROUP_ID =
		config.fixedID.CUSTOMER_INTERESTS_GROUP_ID;
	const CUSTOMER_SOURCE_GROUP_ID = config.fixedID.CUSTOMER_SOURCE_GROUP_ID;

	if (data) {
		// This part is for the customers that we couldnt find on kh.dev's db
		if (data.length === 0) {
			if (AaroCustomer) {
				const { CariID, CariAdi } = AaroCustomer || {};
				const newCustomer = {
					customerID: CariID,
					name: CariAdi,
					responsibles: [userID],
				};
				if (CariAdi)
					postCustomer(newCustomer)
						.then((rp) => {
							const { data } = rp;
							const { success, message } = data;
							// notify(success, message);
							if (success) window.location.reload();
							else alert('Hata: ' + message);
						})
						.catch((err) => {
							console.log(err);
						});
			}

			return (
				<ProjectLayout>
					{' '}
					{AaroCustomer == null ? (
						<p>Böyle bir cari aaroda bulunamadı</p>
					) : (
						<p>Aaroda aranıyor...</p>
					)}
				</ProjectLayout>
			);
		}
		const customer = data[0]
			? data[0]
			: { type: '', profile: '', interests: '', source: '' };
		return (
			<ProjectLayout setValue={setValue}>
				<MDBox mt={4}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12} lg={value === 2 ? 12 : 9}>
							<MDBox mb={3}>{handlePageChanges(value)}</MDBox>
						</Grid>
						<Grid item xs={12} md={12} lg={3}>
							<MDBox
								mb={3}
								mt={3}
								display={{
									xs: 'none',
									sm: 'none',
									md: 'flex',
								}}
							>
								<MDBox sx={{ flexGrow: 1 }}>
									<Chat relatedID={customer?._id} />
								</MDBox>
							</MDBox>
							<MDBox
								mb={3}
								bgColor="white"
								display="grid"
								alignItems="center"
								color="white"
								shadow="md"
								borderRadius="lg"
								variant="gradient"
								p={1}
							>
								<ColoredTagSelection
									tagGroupID={CUSTOMER_TYPE_GROUP_ID}
									selectedTags={customer.type}
									tagLabel="Müşteri Tipi"
									multiple={false}
									handleChanges={handleChanges}
									dbKey="type"
								/>
								<Divider />
								<ColoredTagSelection
									tagGroupID={CUSTOMER_PROFILE_GROUP_ID}
									selectedTags={customer.profile}
									tagLabel="Müşteri Profili"
									multiple={true}
									handleChanges={handleChanges}
									dbKey="profile"
								/>
								<Divider />
								<ColoredTagSelection
									tagGroupID={CUSTOMER_INTERESTS_GROUP_ID}
									selectedTags={customer.interests}
									tagLabel="İlgilendiği Ürünler"
									multiple={true}
									handleChanges={handleChanges}
									dbKey="interests"
								/>
								<Divider />
								<ColoredTagSelection
									tagGroupID={CUSTOMER_SOURCE_GROUP_ID}
									selectedTags={customer.source}
									tagLabel="Kaynak"
									multiple={false}
									handleChanges={handleChanges}
									dbKey="source"
								/>
							</MDBox>

							<MDBox
								mb={3}
								// mt={4}
								bgColor="white"
								display="grid"
								alignItems="center"
								color="white"
								shadow="md"
								borderRadius="lg"
								variant="gradient"
							>
								<MDBox pt={2} px={2} pb={2}>
									<MDTypography
										variant="h6"
										fontWeight="medium"
									>
										Müşteri Sorumluları
									</MDTypography>
								</MDBox>
								<MDBox px={2} pb={2}>
									<Responsibles
										customer={data[0]}
										query={query}
									/>
								</MDBox>
							</MDBox>

							{customer.taskList && (
								<MDBox
									mb={3}
									bgColor="white"
									variant="gradient"
									borderRadius="lg"
									shadow="lg"
									opacity={1}
								>
									<TaskListModalView
										taskListID={customer.taskList}
										taskID={null}
									/>
								</MDBox>
							)}

							<MDBox mb={3}>
								<FileView
									relatedID={customer?._id}
									updateFiles={updateFiles}
									setUpdateFiles={setUpdateFiles}
								/>
							</MDBox>
						</Grid>
					</Grid>
				</MDBox>
			</ProjectLayout>
		);
	}
	return <>Yükleniyor...</>;
}

export default GeneralPage;
