import { useQuery } from 'react-query';

import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

// Anaytics dashboard components

// Data

// Constants
import { getProjects } from 'services/api/project';
import LoadView from './LoadView';
// Charts
import UsersSummary from './Charts/UsersSummary';
import MonthlyProjects from './Charts/MonthlyProjects';
import PassiveProjects from './Charts/PassiveProjects';
// Components
import Activity from 'components/General/Activity/index.js';
import Birthdays from 'components/Aaro/Birthdays';
import ProductSuggestionPage from 'layouts/productSuggestions';

const fetchProjects = async (projectType) => {
    const isActive = projectType === 'active' ? true : false;
    const response = await getProjects(`isActive=${isActive}`).then((rp) => rp.data.result);
    return response;
};

function Analytics() {
    const {
        data: activeProjects,
        isLoading,
        error,
    } = useQuery(['fetchProjects', 'active'], () => fetchProjects('active'), {
        staleTime: 600000,
    });
    const passiveProjects = useQuery(['fetchProjects', 'passive'], () => fetchProjects('passive'), {
        staleTime: 600000,
    });
    if (isLoading) return <LoadView />;
    if (passiveProjects.isLoading) return <LoadView />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    if (passiveProjects.error) return 'Bir Hata Oluştu: ' + passiveProjects.error.message;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <MDBox mt={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <MonthlyProjects projects={[...activeProjects, ...passiveProjects.data]} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <UsersSummary projects={[...activeProjects, ...passiveProjects.data]} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <PassiveProjects projects={passiveProjects.data} />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox my={3}>
                    <ProductSuggestionPage isComponent={true} />
                </MDBox>
                <MDBox mt={5}>
                    <PrepareDataForNotice activeProjects={activeProjects} passiveProjects={passiveProjects.data} />
                </MDBox>
                <MDBox mt={1.5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Activity />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Birthdays />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

const PrepareDataForNotice = ({ activeProjects, passiveProjects }) => {
    if (activeProjects == null || passiveProjects == null) return null;
    const projects = [...activeProjects, ...passiveProjects];

    // * ----- Passive projects analysis
    const labels = [];
    const values = [];
    let counterProjects = {};

    projects.forEach((project) => {
        const status = project?.status?.tag?.name;
        if (isNaN(counterProjects[status])) {
            counterProjects[status] = 0;
        }
        counterProjects[status] += 1;
    });

    Object.keys(counterProjects).forEach((key) => {
        labels.push(key);
        values.push(counterProjects[key]);
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="dark"
                        icon="question_mark"
                        title="Belirsiz Projeler"
                        count={counterProjects['undefined'] > 0 ? counterProjects['undefined'] : 0}
                        percentage={{
                            color: 'success',
                            amount: '',
                            label: '1dk aralık',
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="success"
                        icon="emoji_events"
                        title="Kazanılan Projeler"
                        count={
                            (counterProjects['Kazanıldı'] = counterProjects['Kazanıldı'] || 0) +
                            (counterProjects['Üretildi'] = counterProjects['Üretildi'] || 0) +
                            (counterProjects['Üretiliyor'] = counterProjects['Üretiliyor'] || 0)
                        }
                        percentage={{
                            color: 'success',
                            amount: '',
                            label: '1dk aralık',
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="info"
                        icon="mail"
                        title="Teklifdeki Projeler"
                        count={counterProjects['Teklif Gönderildi'] > 0 ? counterProjects['Teklif Gönderildi'] : 0}
                        percentage={{
                            color: 'success',
                            amount: '',
                            label: '1dk aralık',
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="primary"
                        icon="cancel"
                        title="Kaybedilen Projeler"
                        count={counterProjects['Kaybedildi'] > 0 ? counterProjects['Kaybedildi'] : 0}
                        percentage={{
                            color: 'success',
                            amount: '',
                            label: '1dk aralık',
                        }}
                    />
                </MDBox>
            </Grid>
        </Grid>
    );
};
export default Analytics;
