import MDBox from 'components/MDBox';
import Tooltip from '@mui/material/Tooltip';
import team4 from 'assets/images/team-4.jpg';
import MDAvatar from 'components/MDAvatar';

export default function Responsibles({ users, size }) {
	return (
		<MDBox display="flex" ml={1}>
			<MDBox
				// component="ul"
				display="flex"
				flexDirection="row"
				justifyContent="space-evenly"
			>
				{users?.map((el) => (
					<Tooltip title={el.name} placement="top">
						<MDAvatar
							src={el.profilePicture ? el.profilePicture : team4}
							alt={el.name}
							size={size}
							sx={({
								borders: { borderWidth },
								palette: { white },
							}) => ({
								border: `${borderWidth[2]} solid ${white.main}`,
								cursor: 'pointer',
								position: 'relative',

								'&:not(:first-of-type)': {
									ml: -1.25,
								},

								'&:hover, &:focus': {
									zIndex: '10',
								},
							})}
						/>
					</Tooltip>
				))}
			</MDBox>
		</MDBox>
	);
}
