import { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';

// Settings page components
import { updateProject } from 'services/api/project';

import { notify } from 'helpers/notificationHelper';
// import ToDoCreation from './ToDoCreation';
import ChangeAaroName from './ChangeAaroName';

const ProjectSettings = (props) => {
	const { project } = props;
	const queryClient = useQueryClient();
	const user = JSON.parse(localStorage.getItem('user'));
	const [todoID, setTodoID] = useState(project.todoID ? project.todoID : -1);
	const [updateProject, setUpdateProject] = useState(false);

	const [disabled, setDisabled] = useState(false);
	useEffect(() => setTodoID(project.todoID ? project.todoID : -1), [project]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisabled(true);

		try {
			const data = {
				todoID: todoID,
			};

			const result = window.confirm(
				'Değiştirmek İstediğinize Emin Misiniz?'
			);
			if (result)
				await updateProject(project._id, data)
					.then((rp) => {
						notify(rp.data.success, rp.data.message);
						queryClient.invalidateQueries([
							'fetchProject',
							project._id,
						]);
						setDisabled(false);
					})
					.catch((err) => {
						notify(false, err.message);
						setDisabled(false);
					});
		} catch (err) {
			alert(err);
		}
	};

	return (
		<MDBox mb={1}>
			<MDBox my={3}>
				<Card id="change-aaro">
					<MDBox pb={3} px={3}>
						<MDButton
							variant="gradient"
							color="info"
							onClick={() => setUpdateProject(!updateProject)}
							fullWidth
							disabled={user.role !== 'superadmin'}
						>
							Cariyi Değiştir
						</MDButton>
						{updateProject && <ChangeAaroName project={project} />}
					</MDBox>
				</Card>
			</MDBox>
		</MDBox>
	);
};

export default ProjectSettings;
