// React Libraries
import React from 'react';

// React Query Library
import { useQuery } from 'react-query';

// MUI Components
import { Card, TableContainer, Table, TableRow, TableBody, TablePagination } from '@mui/material';

// Helper Functions
import { demandStatusNameFinderByID } from 'helpers/projectHelpers';

// API Service Functions
import { getTags } from 'services/api/general/tag';

// Constants
import { config } from 'Constants';

// Project Components
import { ProjectStatus } from 'layouts/projects/Pages/All/ProjectsData/components/status/index.js';
import ProjectRating from 'layouts/projects/Pages/All/ProjectsData/components/rating/index.js';
import { ProjectResponsibles } from 'layouts/projects/Pages/All/ProjectsData/components/responsibles/index.js';
import ProjectName from 'layouts/projects/Pages/All/ProjectsData/components/projectName';
import AaroCustomerName from 'layouts/projects/Pages/All/ProjectsData/components/aaroCustomerName';
import DemandStatus from 'layouts/projects/Pages/All/ProjectsData/components/demandStatus';
import { ProjectCreationDate } from 'layouts/projects/Pages/All/ProjectsData/components/projectCreationDate';
import FastEdit from 'layouts/projects/Pages/All/ProjectsData/components/fastEdit';
import PinProject from 'layouts/projects/Pages/All/ProjectsData/components/pinProject';

import DataTableHeadCell from 'examples/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell';
import MDBox from 'components/MDBox';

// Search Context
import { useSearch } from 'layouts/projects/Pages/SearchProvider';

// Main Component
export default function ProjectsData() {
    const { data: all, page, updatePage, limit, updateLimit, queryInfo } = useSearch();

    // Get tags for project status
    const selectableTags = config.fixedID.PROJECT_STATUS_TAG_GROUP_ID;
    const { data: tags, isLoading } = useQuery(['tags', selectableTags], () =>
        getTags(selectableTags).then((rp) => rp?.data?.result?.tags)
    );
    if (queryInfo.isError) {
        return <div>{JSON.stringify(queryInfo)}</div>;
    }
    if (queryInfo.isLoading) {
        return <div>loading search results...</div>;
    }
    if (isLoading) {
        return <div>loading tags...</div>;
    }

    const data = all?.result || [];
    const totalCount = all?.totalCount;
    const user = JSON.parse(localStorage.getItem('user'));

    // Function to handle page change
    const handleChangePage = (event, newPage) => {
        updatePage(newPage + 1);
    };
    // Function to handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        updateLimit(parseInt(event.target.value, 10));
        // updatePage(0);
    };

    return (
        <Card>
            <TableContainer>
                <Table stickyHeader>
                    <MDBox component="thead">
                        <TableRow>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Proje Genel
                            </DataTableHeadCell>

                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Potansiyeli
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Proje Adı
                            </DataTableHeadCell>

                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Müşteri
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Sorumlu
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Kalemler
                            </DataTableHeadCell>
                            <DataTableHeadCell width={'auto'} align={'left'}>
                                Gün
                            </DataTableHeadCell>
                            <DataTableHeadCell width={10}>Pin</DataTableHeadCell>
                            <DataTableHeadCell width={10}></DataTableHeadCell>
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={row._id} className={row.isActive ? '' : 'inactiveRow'}>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectStatus
                                        name={row.status?.name}
                                        projectId={row._id}
                                        userId={user?.userID}
                                        tags={tags}
                                    />
                                </DataTableBodyCell>

                                <DataTableBodyCell align={'left'}>
                                    <ProjectRating project={{ _id: row._id, projectPotential: row.projectPotential }} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectName id={row._id} formattedValue={row.projectName} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <AaroCustomerName id={row._id} formattedValue={row.aaroCustomerName} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectResponsibles responsibles={row.responsibles} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <DemandStatus
                                        id={row._id}
                                        formattedValue={demandStatusNameFinderByID(row?.pricingStatus)}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'}>
                                    <ProjectCreationDate projectID={row._id} date={row.createdAt} />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'} width={10}>
                                    <PinProject
                                        isPinned={row?.pinnedBy?.includes(user?.userID)}
                                        projectID={row._id}
                                        projects={data}
                                        userID={user?.userID}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell align={'left'} width={10}>
                                    <FastEdit projectID={row._id} />
                                </DataTableBodyCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
    );
}
