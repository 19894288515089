import * as React from 'react';

import MDButton from 'components/MDButton';

const ClearRefinements = ({ items, refine }) => (
	<MDButton
		onClick={() => refine(items)}
		disabled={!items.length}
		variant="gradient"
		color="light"
		size="small"
	>
		FİLTRELERİ TEMİZLE
	</MDButton>
);

export default ClearRefinements;
