import axios from 'axios';

import { Aaro } from 'aaro';
import { getAllData } from 'aaro-scripts';
import { config } from 'Constants';

// axios.interceptors.response.use(
// 	(response) => {
// 		if (response.status === 401) {
// 			localStorage.clear();
// 		}

// 		return response;
// 	},
// 	(error) => {
// 		if (error.response.status === 401) {
// 			const url = config.url.AARO_BASE;
// 			Token(url, Cookies.get('aaroUser'), Cookies.get('aaroPass'))
// 				.then((rp) => {
// 					const newToken = rp.data.access_token;

// 					const oldData = JSON.parse(localStorage.getItem('user'));
// 					const newData = { ...oldData, aaroToken: newToken };

// 					localStorage.setItem('user', JSON.stringify(newData));
// 				})
// 				.catch((err) => err);
// 		}

// 		if (error.response && error.response.data) {
// 			return Promise.reject(error.response.data);
// 		}
// 		return Promise.reject(error.message);
// 	}
// );

/**
 * Returns all the products that were sold.
 */
export const getCustomerSales = async (CariID, TipID = 10005) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const latestSales = await getAllData(aaro, 'StokHareketleri', {
		CariID,
		TipID,
	});
	return latestSales;
};

/**
 * Returns sales by pagination
 */
export const getCustomerSalesByPage = async (CariID, TipID = 10005) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const latestSales = await getAllData(aaro, 'StokHareketleri', {
		CariID,
		TipID,
	});
	return latestSales;
};

/**
 * Posts new cari
 */
export const postCari = async (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});

	return aaro.post('Cari', data);
};

/**
 * For given id, it return customer basic info.(without email)
 * Returning array size should be 1
 */
export const getCari = async (CariID) => {
	const user = JSON.parse(localStorage.getItem('user'));

	const headers = {
		// 'Content-Type': 'application/json',
		Authorization: `Bearer ${user.aaroToken}`,
	};
	const cari = await axios
		.get(`${config.url.AARO_BASE}/api/Cari?CariID=${CariID}`, {
			headers,
		})
		.then((rp) => rp.data.Model);

	return cari;
};

/**
 * For given id, it return customer detailed info.(without email)
 * Returning array size could change
 */
export const getCariIlgili = async (CariID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const cari = await getAllData(aaro, 'CariIlgili', {
		CariID,
	});
	return cari;
};

/**
 * For given id, it returns all the notes
 * Returning array size could change
 */
export const getCariNotlar = async (CariID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const cari = await getAllData(aaro, 'Notlar', {
		TabloID: 'Cari',
		TabloSatirID: CariID,
	});
	return cari;
};

/**
 * sample data
 {
    //     IlgiliAdi: "Akif Harmancı",
    //     Tel: "05549982697",
    //     Email: "akharmanci@gmail.com",
    //     Not1: "Api ilgili ekleme denemesi",
    //     Durum: true,
    //     CariID: 9876,
    //     Dogum: new Date(),
    // };
 */
export const addCariIlgili = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.post('CariIlgili', data);
};

export const updateCariIlgili = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.put('CariIlgili', data);
};

export const deleteCariIlgili = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.delete('CariIlgili', data);
};

/**
 * sample data
 {
        TabloID: "Cari",
        TabloSatirID: 9876,
        Notu: "Nodejs denemesi",
    }
 */
export const postCariKartNot = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.post('Notlar', data);
};

// Retrieves caris by EsnekAramaKisiti
export const searchCari = (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	return aaro.get('Cari', data);
};
