import React, { useState, useEffect, memo } from 'react';
import { useQuery } from 'react-query';
// import PeopleCardFooter from '@mui-treasury/components/cardFooter/people';

import AddResponsible from './AddResponsible';
import { getProject } from 'services/api/project';
import { getUsers } from 'services/api/user';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// @mui material components

import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar';
import AaroLoadingScreen from 'components/Loading';

// Image

import team4 from 'assets/images/team-4.jpg';

const fetchUsers = async (id) => {
	const response = await getUsers(id)
		.then((rp) => rp.data.result)
		.catch((err) => err);
	return response;
};

const Responsibles = (props) => {
	const avatarStyles = {
		border: ({ borders: { borderWidth }, palette: { white } }) =>
			`${borderWidth[2]} solid ${white.main}`,
		cursor: 'pointer',
		position: 'relative',
		ml: -1.5,

		'&:hover, &:focus': {
			zIndex: '10',
		},
	};
	const {
		customer,
		showAddButton = true,
		avatarSize = 'sm',
		additionalStyle = null,
		query,
	} = props;

	const { _id: customerID } = customer || {};

	const {
		data: users,
		isLoading,
		error,
	} = useQuery('fetchUsers', () => fetchUsers(), {
		staleTime: 600000,
	});

	if (isLoading) return <AaroLoadingScreen />;
	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<MDBox
			// component="ul"
			display="flex"
			flexDirection="row"
			justifyContent="space-evenly"
		>
			{customer?.responsibles?.map((el) => (
				<Tooltip title={el.name} placement="top">
					<MDAvatar
						src={el.profilePicture ? el.profilePicture : team4}
						alt={el.name}
						size={avatarSize}
						sx={additionalStyle ? additionalStyle : avatarStyles}
					/>
				</Tooltip>
			))}
			{showAddButton && (
				<MDBox pl={1}>
					<AddResponsible
						allResponsibles={customer?.responsibles}
						customerID={customerID}
						users={users}
						query={query}
					/>
				</MDBox>
			)}
		</MDBox>
	);
};

export default memo(Responsibles);
