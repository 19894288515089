// Libraries
import { useParams } from 'react-router-dom';

import Layout from '../Layout';

import ModalView from './ModalView';

function Task() {
	const { id } = useParams();

	return (
		<Layout>
			<ModalView taskID={id} />
		</Layout>
	);
}

export default Task;
