import { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Images
import Icon from '@mui/material/Icon';
import { getTaskStyle } from 'helpers/taskHelpers';
import {
	completeChildTask,
	completeParentTask,
} from 'services/api/general/task';
import { notify } from 'helpers/notificationHelper';
import Responsibles from '../Responsibles';

function Header({ taskItem }) {
	const isCompleted = taskItem?.completed ? taskItem.completed : false;
	const { _id: taskID } = taskItem;
	const queryClient = useQueryClient();
	const [completed, setCompleted] = useState(isCompleted);

	const handleChange = (e) => {
		e.preventDefault();

		const completeChanged = !completed;
		// Parent true is bütün childlar true, parent false ise sadece kendisi false
		if (completeChanged)
			completeParentTask(taskID, completeChanged)
				.then((rp) => {
					queryClient.invalidateQueries(['fetchSingleTask', taskID]);
					notify(rp.data.success, rp.data.message);
				})
				.catch((err) => notify(false, JSON.stringify(err)));
		else
			completeChildTask(taskID, completeChanged)
				.then((rp) => {
					queryClient.invalidateQueries(['fetchSingleTask', taskID]);
					notify(rp.data.success, rp.data.message);
				})
				.catch((err) => notify(false, JSON.stringify(err)));
		setCompleted(completeChanged);
	};

	const taskStyle = getTaskStyle(taskItem?.priority, taskItem?.checked);
	return (
		<Card id="profile">
			<MDBox p={2}>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<MDAvatar
							size="xl"
							shadow="lg"
							bgColor={taskStyle.color}
							icon="abc"
						>
							<Icon fontSize="large">{taskStyle.icon}</Icon>
						</MDAvatar>
					</Grid>
					<Grid item>
						<MDBox height="100%" mt={0.5} lineHeight={1}>
							<MDBox display="flex">
								<MDTypography variant="h5" fontWeight="medium">
									{taskItem?.name}
								</MDTypography>
								<Responsibles
									users={taskItem.assignee}
									size="xs"
								/>
							</MDBox>
							<MDTypography
								variant="button"
								color="text"
								fontWeight="medium"
							>
								{taskItem?.description}
							</MDTypography>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3} sx={{ ml: 'auto' }}>
						<MDBox
							display="flex"
							justifyContent={{ md: 'flex-end' }}
							alignItems="center"
							lineHeight={1}
						>
							{completedText(completed)}

							<MDBox ml={1}>
								<Switch
									checked={completed}
									onChange={handleChange}
								/>
							</MDBox>
						</MDBox>
					</Grid>
				</Grid>
			</MDBox>
		</Card>
	);
}

const completedText = (completed) => {
	if (completed)
		return (
			<MDTypography variant="caption" fontWeight="bold" color="success">
				Tamamlanmış Görev
			</MDTypography>
		);
	return (
		<MDTypography variant="caption" fontWeight="bold" color="primary">
			Tamamlanmamış Görev
		</MDTypography>
	);
};
export default Header;
