

function configs(labels, datasets) {
	return {
		data: {
			labels,
			datasets: datasets.map((dataset) => ({
				label: dataset.label,
				tension: 0.4,
				borderWidth: 0,
				borderRadius: 4,
				borderSkipped: false,
				backgroundColor: dataset.backgroundColor
					? dataset.backgroundColor
					: 'rgba(255,255,255, 0.8)',
				data: dataset.data ? dataset.data : [],
				maxBarThickness: 6,
			})),
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5],
						color: 'rgba(255, 255, 255, .2)',
					},
					ticks: {
						suggestedMin: 0,
						suggestedMax: 500,
						beginAtZero: true,
						padding: 10,
						font: {
							size: 14,
							weight: 300,
							family: 'Roboto',
							style: 'normal',
							lineHeight: 2,
						},
						color: '#fff',
					},
				},
				x: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5],
						color: 'rgba(255, 255, 255, .2)',
					},
					ticks: {
						display: true,
						color: '#f8f9fa',
						padding: 10,
						font: {
							size: 14,
							weight: 300,
							family: 'Roboto',
							style: 'normal',
							lineHeight: 2,
						},
					},
				},
			},
		},
	};
}

export default configs;
