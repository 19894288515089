

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultNavbarCategory({ icon, title }) {
  return (
    <MDBox width="100%" display="flex" alignItems="center" py={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="md"
        color="text"
        mr={1}
        fontSize="1rem"
      >
        {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
      </MDBox>
      <MDTypography variant="button" fontWeight="bold">
        {title}
      </MDTypography>
    </MDBox>
  );
}

// Typechecking props for the DefaultNavbarCategory
DefaultNavbarCategory.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DefaultNavbarCategory;
