import { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { config } from 'Constants';

import { useFormik } from 'formik';
import * as yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
// import CardBody from 'components/Card/CardBody.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// import Chip from '@mui/material/Divider';
import { postDiger, updateDiger } from 'services/api/form';
import { getTags } from 'services/api/general/tag';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AaroLoadingScreen from 'components/Loading';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/material';
import OutlinedFormTextField from '../FormComponents/OutlinedFormTextField';

const validationSchema = yup.object({
    baslik: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
});

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '1.125rem',
        color: '#999999',
        fontWeight: 500,
        lineHeight: 1.5,
    },
    gridStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    gridStyleBooleans: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    containerMargin: {
        marginLeft: '3%',
        marginRight: '3%',
    },
    dashedSeperator: {
        borderTop: '2px dashed #999999',
        marginLeft: '3%',
        marginRight: '3%',
    },
    dottedSeperator: {
        borderTop: ' 1px dotted #8c8b8b',
        marginLeft: '3%',
        marginRight: '3%',
    },
    dashedSeperator: {
        borderTop: '2px dashed #999999',
        marginLeft: '3%',
        marginRight: '3%',
    },
    bottomInput: {
        display: 'flex',
        flexDirection: 'column-revere',
        alignItems: 'center',
    },
    emptySpace: {
        marginBottom: '16px',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
        minWidth: 120,
    },
}));

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) => (rp.data?.result?.tags ? rp.data?.result?.tags : []));
    return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.DEMAND_STATUS_TAG_GROUP_ID;
export const Diger = (props) => {
    const queryClient = useQueryClient();
    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(['fetchTagGroups', TAG_GROUP_ID_DEMAND], () => fetchTags(TAG_GROUP_ID_DEMAND), { staleTime: 600000 });

    const user = JSON.parse(localStorage.getItem('user'));
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const classes = useStyles();
    const { demandID, initial, id, projectID, setOpen } = props;

    const formik = useFormik({
        initialValues: {
            baslik: initial?.baslik || 'Diger',
            gorsel: initial?.gorsel || '',
            ekNot: initial?.ekNot || '',
            miktar: initial?.miktar || 0,
            yoneticiNotu: initial?.yoneticiNotu || '',
            verilenFiyat: initial?.verilenFiyat || null,
            durumu: initial?.durumu || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setDisableOnSubmit(true);
            if (id) {
                updateDiger(id, values)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demandID]);
                        setOpen(false);
                    })
                    .catch((err) => console.log(err));
            } else {
                postDiger(demandID, values)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demandID]);
                        setOpen(false);
                    })

                    .catch((err) => console.log(err));
            }
        },
    });
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;
    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container justify="flex-start">
                            {/* Başlık */}
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="baslik"
                                    name="baslik"
                                    label="Başlık"
                                    placeholder="Örn: 18lik U Diger"
                                    value={formik.values.baslik}
                                    onChange={formik.handleChange}
                                    error={formik.touched.baslik && Boolean(formik.errors.baslik)}
                                    helperText={formik.touched.baslik && formik.errors.baslik}
                                />
                            </Grid>
                            {/* Ek Not*/}
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="ekNot"
                                    name="ekNot"
                                    label="Eklemek İstedikleriniz"
                                    multiline
                                    variant="outlined"
                                    rows={3}
                                    value={formik.values.ekNot}
                                    onChange={formik.handleChange}
                                    error={formik.touched.ekNot && Boolean(formik.errors.ekNot)}
                                    helperText={formik.touched.ekNot && formik.errors.ekNot}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <OutlinedFormTextField
                                    itemID="miktar"
                                    label="Miktar (Adet)"
                                    placeHolder="Örn: 1000"
                                    type="number"
                                    formik={formik}
                                    step="1"
                                    adornment="AD"
                                />
                            </Grid>
                            {/* Ayraç Boşluklu */}
                            <Grid container>
                                <Grid item x={12} className={classes.emptySpace}>
                                    {' '}
                                </Grid>
                                <Grid item xs={12} className={classes.dashedSeperator}>
                                    {' '}
                                </Grid>
                                <Grid item x={12} className={classes.emptySpace}>
                                    {' '}
                                </Grid>
                            </Grid>
                            {/* Yönetici Alanı*/}
                            <Grid container className={classes.containerMargin}>
                                {/* Yönetici Notu*/}
                                <Grid item xs={12} sm={12} md={4}>
                                    <TextField
                                        disabled={user.role != 'superadmin'}
                                        fullWidth
                                        id="yoneticiNotu"
                                        name="yoneticiNotu"
                                        label="Yönetici Notu"
                                        value={formik.values.yoneticiNotu}
                                        onChange={formik.handleChange}
                                        error={formik.touched.yoneticiNotu && Boolean(formik.errors.yoneticiNotu)}
                                        helperText={formik.touched.yoneticiNotu && formik.errors.yoneticiNotu}
                                    />
                                </Grid>
                                {/* Verilen Fiyat */}
                                <Grid item xs={12} sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        id="verilenFiyat"
                                        name="verilenFiyat"
                                        label="Birim Fiyat"
                                        type="number"
                                        inputProps={{ step: '0.01' }}
                                        value={formik.values.verilenFiyat}
                                        onChange={formik.handleChange}
                                        error={formik.touched.verilenFiyat && Boolean(formik.errors.verilenFiyat)}
                                        helperText={formik.touched.verilenFiyat && formik.errors.verilenFiyat}
                                        // disabled={user.role != 'superadmin'}
                                    />
                                </Grid>

                                {/* Teklif Durumu */}
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box
                                        pl={1}
                                        sx={{
                                            width: '100%',
                                            minWidth: 120,
                                            height: '100%',
                                        }}
                                    >
                                        <FormControl
                                            sx={{
                                                width: '100%',
                                                minWidth: 120,
                                                height: '100%',
                                            }}
                                        >
                                            <InputLabel id="durumu">
                                                {formik?.values?.durumu?.name
                                                    ? formik.values.durumu.name
                                                    : 'Talep Durumu'}
                                            </InputLabel>
                                            <Select
                                                id="durumu"
                                                name="durumu"
                                                value={formik.values.durumu}
                                                onChange={formik.handleChange}
                                                variant="outlined"
                                                defaultValue="Talep Durumu"
                                                sx={{
                                                    width: '100%',
                                                    minWidth: 120,
                                                    height: '100%',
                                                }}
                                                required
                                            >
                                                {tags.map((tag) => (
                                                    <MenuItem value={tag}>{tag.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* Ayraç Boşluklu */}
                            <Grid container>
                                <Grid item x={12} className={classes.emptySpace}>
                                    {' '}
                                </Grid>
                                <Grid item xs={12} className={classes.dashedSeperator}>
                                    {' '}
                                </Grid>
                                <Grid item x={12} className={classes.emptySpace}>
                                    {' '}
                                </Grid>
                            </Grid>
                            {/* Kaydet Butonu */}
                            <Button variant="contained" fullWidth type="submit" disabled={disableOnSubmit}>
                                <Typography variant="h6" sx={{ color: '#fff' }}>
                                    Kaydet
                                </Typography>
                                ;
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
};
