import React from 'react';
import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import MDButton from 'components/MDButton';
import { updateGoogleSheet } from 'services/api/project';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
    switch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

export const BaseForm = ({
    initial,
    setOpen,
    googleSheetURL,
    matches,
    refetch,
    lock,
    setLock,
    validationSchema,
    allowedChanges,
    toSheet,
    fromSheet,
    renderFields,
}) => {
    const classes = useStyles();

    const formik = useFormik({
        initialValues: fromSheet(initial),
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLock(!lock);
            const sheetData = toSheet(values);
            const updates = Object.keys(sheetData).reduce((acc, key) => {
                if (allowedChanges.includes(key) && initial[key] !== sheetData[key] && matches[key]) {
                    acc.push({ range: matches[key], values: [[sheetData[key]]] });
                }
                return acc;
            }, []);
            if (updates.length === 0) {
                setLock(lock);
                setOpen(false);
                return;
            }

            try {
                await updateGoogleSheet(googleSheetURL, updates);
                refetch().then(() => {
                    setLock(lock);
                });
                setOpen(false);
            } catch (err) {
                console.log(err);
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        {renderFields(formik, classes)}
                        <Grid item xs={12} sm={12} md={12}>
                            <MDButton color="warning" variant="gradient" fullWidth type="submit" disabled={lock}>
                                Hesapla
                            </MDButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
