import React, { useState, useEffect } from 'react';
// react-routers components

// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import AddTaskList from 'components/General/Task/utils/AddTaskList/index.js';
import Dialog from '@mui/material/Dialog';
import Icon from '@mui/material/Icon';

import ListDetail from 'layouts/todo/components/TaskLists/ListDetail';
import { notify } from 'helpers/notificationHelper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { updateTaskList } from 'services/api/general/taskList';

function TaskLists({ title, taskLists, shadow = false, setActiveList, activeList }) {
    const [lists, setLists] = useState(taskLists);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAddTaskListDialog, setOpenAddTaskListDialog] = useState(false);
    const [openUpdateTaskListDialog, setOpenUpdateTaskListDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState(null); // 'add' veya 'update'
    const [currentTask, setCurrentTask] = useState(null); // Şu anda işlenen görev

    useEffect(() => {
        setLists(taskLists);
    }, [taskLists]);
    const openDialog = (mode, task = null) => {
        setDialogMode(mode);
        setCurrentTask(task);
        if (mode === 'add') {
            setOpenAddTaskListDialog(true);
        } else if (mode === 'update') {
            setOpenUpdateTaskListDialog(true);
        }
    };

    const closeDialog = () => {
        setDialogMode(null);
        setCurrentTask(null);
        setOpenAddTaskListDialog(false);
        setOpenUpdateTaskListDialog(false);
    };

    // ! bu order mevzusu iyileştrilebilir
    const findPerfectOrder = (arr, position) => {
        // En altta completed task altına bir item yerleştirildiyse diye bir de random değer ekliyorum.

        // Eger üst komşusu varsa (orta ve en alt)
        if (arr[position - 1]) {
            const changedItem = arr[position];
            const changedItemID = changedItem._id;
            const topNeighbor = arr[position - 1];
            const topNeighborOrder = topNeighbor.order;
            let newOrder = topNeighborOrder - 1024;

            // Eger alt komşusu da varsa (aradaysa)
            if (arr[position + 1]) {
                const bottomNeighbor = arr[position + 1];
                const bottomNeighborOrder = bottomNeighbor.order;
                newOrder = parseInt((topNeighborOrder + bottomNeighborOrder) / 2);
            }
            // yani en alt değerse

            arr[position].order = newOrder;
            updateTaskList(changedItemID, { order: newOrder })
                .then((rp) => notify(rp.data.success, rp.data.message))
                .catch((err) => notify(false, JSON.stringify(err)));
        }
        // üst komşusu yok ama alt komşusu varsa indexini bin azalt ve db güncelle (en üst eleman)
        else if (arr[position + 1]) {
            const changedItem = arr[position];
            const changedItemID = changedItem._id;
            const bottomNeighbor = arr[position + 1];
            const bottomNeighborOrder = bottomNeighbor.order;
            const newOrder = bottomNeighborOrder + 1024;

            arr[position].order = newOrder;
            updateTaskList(changedItemID, { order: newOrder })
                .then((rp) => notify(rp.data.success, rp.data.message))
                .catch((err) => notify(false, JSON.stringify(err)));
        }

        return arr;
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        // console.log(startIndex, endIndex, result);

        //üst komşusu varsa üst komşunun idsini bir artır

        const result2 = findPerfectOrder(result, endIndex);
        // resulti tekrar sort ettim, tamamlanan elemanların sona gelmesi için
        return result2.sort((a, b) => a.completed - b.completed);
    };

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const tasks = reorder(lists, result.source.index, result.destination.index);
        // console.log(tasks);

        setLists(tasks);
    }

    const renderTaskLists = (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="taskLists">
                {(provided) => (
                    <MDBox
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        p={0}
                        m={0}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {lists?.map((listData, index) => (
                            <Draggable key={listData?._id} draggableId={listData?._id} index={index}>
                                {(provided) => (
                                    <MDBox
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <ListDetail
                                            listData={listData}
                                            openDialog={openDialog}
                                            setActiveList={setActiveList}
                                            activeList={activeList}
                                        />
                                    </MDBox>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </MDBox>
                )}
            </Droppable>
        </DragDropContext>
    );

    return (
        <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
            <MDBox pt={2} px={2} display="flex" flexDirection="row" justifyContent="space-between">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {title}
                </MDTypography>
                <MDButton
                    variant="gradient"
                    color="dark"
                    iconOnly
                    size="small"
                    onClick={() => openDialog('add')}
                    // circular
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>
            <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {renderTaskLists}
                </MDBox>
            </MDBox>
            <Dialog
                onClose={closeDialog}
                open={openAddTaskListDialog || openUpdateTaskListDialog}
                fullWidth={true}
                maxWidth="md"
            >
                <AddTaskList
                    setOpenDialog={closeDialog}
                    update={dialogMode === 'update'}
                    initial={dialogMode === 'update' ? currentTask : { relatedID: 'genel' }}
                />
            </Dialog>
        </Card>
    );
}

// Setting default props for the TaskLists
TaskLists.defaultProps = {
    shadow: true,
};

// Typechecking props for the TaskLists
TaskLists.propTypes = {
    title: PropTypes.string.isRequired,

    shadow: PropTypes.bool,
};

export default TaskLists;
