// Libraries
import { memo, useEffect, useState } from 'react';

// MDP2 Components
import MDButton from 'components/MDButton';
import { MenuItem, Menu } from '@mui/material';

// Assets
import { updateProject } from 'services/api/project';
import { getProjectStatusColorNew, getDemandStatusIcon } from 'helpers/colorHelpers';
import Icon from '@mui/material/Icon';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export const ProjectStatus = memo(({ tags, name, projectId, userId }) => {
    const [selectedTag, setSelectedTag] = useState(tags.find((tag) => tag.name === name) || '');
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        setSelectedTag(tags.find((tag) => tag.name === name) || '');
    }, [name, tags]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = async (event) => {
        // console.log('event');
        // console.log(event);
        // console.log('event');
        const newSelectedTag = event;

        if (newSelectedTag) {
            try {
                await updateProject(projectId, {
                    status: {
                        user: userId,
                        tag: newSelectedTag._id,
                    },
                });

                // UI'yi güncelle
                setSelectedTag(newSelectedTag);
            } catch (error) {
                console.error('Proje durumu güncellenirken hata oluştu:', error);
            }
        }
        handleClose();
    };

    return (
        <MDBox display="flex" alignItems="center">
            <MDBox display="flex" alignItems="center">
                <MDBox mr={1}>
                    <MDButton
                        variant="outlined"
                        color={getProjectStatusColorNew(selectedTag.name) || 'dark'}
                        size="small"
                        iconOnly
                        circular
                        onClick={handleClick}
                        sx={{ display: 'flex', justifyContent: 'center' }} // Eklediğimiz stil satırı
                    >
                        <Icon sx={{ fontWeight: 'bold' }}>{getDemandStatusIcon(selectedTag.name)}</Icon>
                    </MDButton>
                </MDBox>
                <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                    {selectedTag.name}
                </MDTypography>
            </MDBox>

            <Menu
                id="project-status-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {tags.map((tag) => (
                    <MenuItem key={tag._id} onClick={() => handleChange(tag)}>
                        {tag.name}
                    </MenuItem>
                ))}
            </Menu>
        </MDBox>
    );
});

// function StatusCell({ icon, color, status }) {
//   return (
//     <MDBox display="flex" alignItems="center">
//       <MDBox mr={1}>
//         <MDButton variant="outlined" color={color} size="small" iconOnly circular>
//           <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
//         </MDButton>
//       </MDBox>
//       <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
//         {status}
//       </MDTypography>
//     </MDBox>
//   );
// }

// // Typechecking props for the StatusCell
// StatusCell.propTypes = {
//   icon: PropTypes.string.isRequired,
//   color: PropTypes.string.isRequired,
//   status: PropTypes.string.isRequired,
// };

// export default StatusCell;
