import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { deleteVisit } from 'services/api/general/visit.js';
function SingleVisit({ _id, key, refetch, icon, customer, visitDate, visitDuration, visitNotes, sourcePage, user }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await deleteVisit(_id);
            refetch();
            handleClose();
        } catch (error) {
            console.error('Failed to delete visit: ', error);
        }
    };
    return (
        <MDBox key={key} component="li" py={1} pr={2} mb={1}>
            <MDBox display="flex" alignItems="center">
                <MDBox mr={2}>
                    <MDButton variant="outlined" color={'success'} onClick={handleClickOpen} iconOnly circular>
                        <Icon sx={{ fontWeight: 'bold' }}>done_outline</Icon>
                    </MDButton>
                </MDBox>
                <MDBox display="flex" flexDirection="column">
                    <MDTypography variant="button" fontWeight="medium" gutterBottom>
                        {user?.name}
                    </MDTypography>
                    <MDTypography variant="caption" color="h6" fontWeight="regular">
                        Ziyaret Tarihi: {visitDate}
                    </MDTypography>
                    <MDTypography
                        variant="caption"
                        color="h6"
                        fontWeight="regular"
                        mb={1}
                        sx={{
                            wordBreak: 'break-word',
                        }}
                    >
                        Ziyaret Süresi (DK): {visitDuration}
                    </MDTypography>
                    <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                        sx={{
                            wordBreak: 'break-word',
                        }}
                    >
                        Ziyaret Notları: {visitNotes}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Silmek istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose} color="dark">
                        İptal
                    </MDButton>
                    <MDButton onClick={handleDelete} color="primary" autoFocus>
                        Sil
                    </MDButton>
                </DialogActions>
            </Dialog>
        </MDBox>
    );
}

SingleVisit.propTypes = {
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    visitDate: PropTypes.string.isRequired,
    visitDuration: PropTypes.string.isRequired,
    visitNotes: PropTypes.string.isRequired,
    sourcePage: PropTypes.string.isRequired,
};

export default SingleVisit;
