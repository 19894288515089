import React from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';

export default function OfferGroups({ demandGroups }) {
    return (
        <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
                Oluşturulan Gruplar
            </MDTypography>
            {demandGroups && demandGroups.map((el) => <MDBadge badgeContent={el.groupName} color="dark" />)}
        </MDBox>
    );
}
