import React from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// CardFooter Component
const CardFooter = ({ date_modified, date_created, data, customer_note, status, purchase }) => {
    const { line_items } = data;

    const calculateDaysToComplete = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    return (
        <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                minHeight: '24px',
                gap: 1, // 8px spacing between elements
            }}
        >
            {status === 'completed' ? (
                <>
                    <MDTypography variant="caption" color="text" mr={1}>
                        {purchase?.shipments?.length > 0 ? (
                            <span style={{ fontWeight: 500 }}>
                                {purchase.shipments.map((shipment, index) => (
                                    <React.Fragment key={index}>
                                        {`${shipment.shippingMethod || ''} ${shipment.trackingNumber || ''}`}
                                        {shipment.shippingCost > 0 && ` (${shipment.shippingCost}₺)`}
                                        {index < purchase.shipments.length - 1 && ' • '}
                                    </React.Fragment>
                                ))}
                            </span>
                        ) : (
                            'Kargo bilgisi girilmemiş!'
                        )}
                    </MDTypography>
                    <MDTypography
                        variant="overline"
                        fontWeight="light"
                        textTransform="lowercase"
                        sx={{ lineHeight: '1', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {calculateDaysToComplete(date_created, date_modified)} günde tamamlandı
                    </MDTypography>
                </>
            ) : (
                <>
                    <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        textTransform="lowercase"
                        color={customer_note ? 'warning' : 'text'}
                        sx={{
                            lineHeight: '1',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            opacity: customer_note ? 0.9 : 0.7,
                            color: customer_note ? '#ff7043' : 'inherit',
                            maxWidth: '70%', // not metninin maksimum genişliği
                        }}
                    >
                        {customer_note || 'Not bulunmuyor'}
                    </MDTypography>
                    <MDTypography
                        variant="caption"
                        color="text"
                        sx={{
                            opacity: 0.7,
                            whiteSpace: 'nowrap', // tek satırda kalmasını sağlar
                        }}
                    >
                        {`${line_items.length} Kalem Ürün`}
                    </MDTypography>
                </>
            )}
        </MDBox>
    );
};

export default CardFooter;
