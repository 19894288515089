import axios from 'axios';
import { config } from 'Constants';
import { Aaro } from 'aaro';

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getCustomers = (query) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers?${query}`;
	return axios.get(url, { headers });
};

/**
 * @desc  This is just for eliminating based on responsibles - because customers should be visible everyone and also only for responsibles
 * @return promise - axios returns a promise
 */
export const getAllCustomers = (query) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/all?${query}`;
	return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getCustomerByID = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/${id}`;
	return axios.get(url, { headers });
};

/**
 * @desc  it updates given customer
 * @return promise - axios returns a promise
 */
export const updateCustomer = (id, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/${id}`;
	return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it retrieves customer calls
 * @return promise - axios returns a promise
 */
export const getCustomerCalls = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/${id}?calls=true`;
	return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer call
 * @return promise - axios returns a promise
 */
export const postCustomerCall = (id, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/call/${id}`;
	return axios.post(url, data, { headers });
};

/**
 * @desc Retrieves all the calls
 * @return promise - axios returns a promise
 */
export const getCalls = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/call`;
	return axios.get(url, { headers });
};

/**
 * @desc Retrieves all the calls
 * @return promise - axios returns a promise
 */
export const getCallsByQuery = (query) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/call/query?inquiry=${query}`;
	return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postCustomerSms = (id, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/sms/${id}`;
	return axios.post(url, data, { headers });
};

/**
 * @desc for given group id, it retrieves customer tags
 * @return promise - axios returns a promise
 */
export const getTags = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/tag/${id}`;
	return axios.get(url, { headers });
};

/**
 * @desc for given user id, it retrieves the related customers
 * @return promise - axios returns a promise
 */
export const getCustomersByResponsible = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/responsible/${id}`;
	return axios.get(url, { headers });
};

/**
 * @desc for given user id, it retrieves the related customers
 * @id user id
 * @return promise - axios returns a promise
 */
export const getUserSummaryByDay = (id, day) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers/call/summary/${id}`;
	return axios.post(url, { day }, { headers });
};

/**
 * @desc for given id, it checks aaro and post a new customer
 * @return promise - axios returns a promise
 */
export const postCustomer = async (data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers`;
	return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it checks aaro and post a new customer
 * @return promise - axios returns a promise
 */
export const postCustomerByID = async (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const customer = await aaro.get('Cari', { CariID: id });
	if (customer?.data?.Model[0]?.CariID) {
		const incomingCustomer = customer.data.Model[0];
		const headers = {
			'Content-Type': 'application/json',
			Authorization: user.token,
		};
		const data = {
			responsible: user.userID,
			customerID: incomingCustomer.CariID,
			name: incomingCustomer.CariAdi,
			calls: [],
		};
		const url = `${config.url.API_URL}/api/kahramanlar/customers`;
		return axios.post(url, data, { headers });
	}
};

/**
 * @desc for given id, it checks aaro and post a new customer
 * @return promise - axios returns a promise
 */
export const postCustomerByIDv2 = async (id, name) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const data = {
		responsible: user.userID,
		customerID: id,
		name,
		calls: [],
	};
	const url = `${config.url.API_URL}/api/kahramanlar/customers`;
	return axios.post(url, data, { headers });
};

/**
 * @desc for given kod, it checks aaro and post a new customer
 * @return promise - axios returns a promise
 */
export const postCustomerByKod = async (kod) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const customer = await aaro.get('Cari', { CariKodu: kod });
	if (customer?.data?.Model[0]?.CariKodu) {
		const incomingCustomer = customer.data.Model[0];
		const headers = {
			'Content-Type': 'application/json',
			Authorization: user.token,
		};
		const data = {
			responsible: user.userID,
			customerID: incomingCustomer.CariID,
			name: incomingCustomer.CariAdi,
			calls: [],
		};
		const url = `${config.url.API_URL}/api/kahramanlar/customers`;
		return axios.post(url, data, { headers });
	}
};
