import { useState } from 'react';
import { useQueryClient } from 'react-query';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Dialog from '@mui/material/Dialog';
import SendSMS from 'components/General/SMS/SendSms.js';

const smsMessageContent = (templateName, messageCustomerName, wooCommerceOrderDetails, purchase, orderNumber) => {
    const { first_name = '', last_name = '', phone = '' } = wooCommerceOrderDetails?.billing || {};
    const customerName = messageCustomerName || `${first_name} ${last_name}`.trim();
    const finalCustomerName = customerName || 'Değerli Müşterimiz';

    const messages = {
        ilkKayit: `Sayın ${finalCustomerName?.toUpperCase()}, ahsaplar.com ${orderNumber} numaralı siparişiniz alınmıştır. Bizlere 0850 305 84 04 numaralı telefondan ulaşabilirsiniz. Kapim.com.tr`,
        kargo:
            purchase?.shipments?.length > 0
                ? `Sayın ${finalCustomerName}, ahsaplar.com ${orderNumber} numaralı siparişiniz kargoya verilmiştir. Kargo takip numaranız: ${purchase.shipments[0].trackingNumber}. Kargo firması: ${purchase.shipments[0].shippingMethod}`
                : `Sayın ${finalCustomerName}, ahsaplar.com ${orderNumber} numaralı siparişiniz henüz kargoya verilmemiştir.`,
    };

    return messages[templateName];
};
function SmsTemplatesWoo(props) {
    const {
        wooCommerceOrderDetails,
        purchase,
        open,
        handleClose,
        initial,
        sourcePage = 'purchase',
        orderNumber,
    } = props;
    const queryClient = useQueryClient();

    const [template, setTemplate] = useState('');
    const [message, setMessage] = useState('');
    const [number, setNumber] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState('');

    const handleTemplateChange = (event) => {
        const { value } = event.target;

        setTemplate(value);
        if (!number) {
            const billingPhone = wooCommerceOrderDetails?.billing?.phone.slice(-10) || '';
            setNumber(billingPhone);
        }
        setMessage(smsMessageContent(value, selectedCustomerName, wooCommerceOrderDetails, purchase, orderNumber));
    };

    const handleCustomerChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCustomer(selectedValue);

        const parseSelectedValue = selectedValue.split(' - ');
        const name = parseSelectedValue[0] ? parseSelectedValue[0] : '';
        const selectedPhone = parseSelectedValue[1]
            ? parseSelectedValue[1].slice(-10)
            : (wooCommerceOrderDetails?.billing?.phone || '').slice(-10);
        console.log(selectedValue);
        setNumber(selectedPhone);
        setSelectedCustomerName(name);

        setMessage(smsMessageContent(template, name, wooCommerceOrderDetails, purchase, orderNumber));
    };

    const responsibles = queryClient.getQueryData([
        'fetchAaroCustomerResponsibles',
        wooCommerceOrderDetails?.customerID,
    ]);

    const companyDetail = queryClient.getQueryData(['fetchAaroCustomer', wooCommerceOrderDetails?.customerID]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <Card>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <MDBox p={2} lineHeight={0}>
                            <MDTypography variant="h5">Şablonlar</MDTypography>

                            <FormControl>
                                <RadioGroup value={template} onChange={handleTemplateChange}>
                                    <FormControlLabel value="ilkKayit" control={<Radio />} label="İlk Kayıt" />
                                    <FormControlLabel value="kargo" control={<Radio />} label="Kargo Bilgileri" />
                                </RadioGroup>
                            </FormControl>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MDBox p={2} lineHeight={0}>
                            <MDTypography variant="h5">Numaralar</MDTypography>

                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedCustomer}
                                    onChange={handleCustomerChange}
                                >
                                    {responsibles?.map((item, index) => (
                                        <FormControlLabel
                                            key={item?.EsnekAramaKisiti}
                                            value={item?.IlgiliAdi + ' - ' + item?.Tel}
                                            control={<Radio />}
                                            label={item?.IlgiliAdi + ' - ' + item?.Tel}
                                        />
                                    ))}
                                    {companyDetail?.Tel && (
                                        <FormControlLabel
                                            key={companyDetail?.EsnekAramaKisiti}
                                            value={companyDetail?.CariAdi + ' - ' + companyDetail?.Tel}
                                            control={<Radio />}
                                            label={companyDetail?.CariAdi + ' - ' + companyDetail?.Tel}
                                        />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </MDBox>
                    </Grid>
                </Grid>

                <MDBox p={2}>
                    {/* <MDButton
					variant="gradient"
					color="success"
					onClick={handleClickOpen}
					fullWidth
				>
					SMS GÖNDER
				</MDButton> */}
                </MDBox>

                <SendSMS
                    initial={{
                        relatedID: initial.relatedID || wooCommerceOrderDetails?._id,
                        smsContent: message,
                        sourcePage: initial.sourcePage || sourcePage,
                        ...initial,
                    }}
                    handleClose={handleClose}
                    content={message}
                    number={number}
                />

                {/*Burada  */}
            </Card>
        </Dialog>
    );
}

export default SmsTemplatesWoo;
