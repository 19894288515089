import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import MDEditor from 'components/MDEditor';
import { editNote, deleteNote, getNote } from 'services/api/general/note';
import { useQuery } from 'react-query';
import { formatDate } from 'helpers/smallCodes';
import { notify } from 'helpers/notificationHelper';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import AaroLoadingScreen from 'components/Loading';
import AddNote from './AddNote';
import AddIcon from '@mui/icons-material/Add';

const fetchNotes = async (relatedID) => {
    const response = await getNote(relatedID);
    return response;
};

export default function Anouncements(props) {
    const { relatedID, restricted = false } = props;
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [editingNote, setEditingNote] = useState('');
    const user = JSON.parse(localStorage.getItem('user'));

    const handleEditClick = (note, noteId, currentTitle) => {
        setEditingNoteId(noteId);
        setEditingNote(note);
        const titleParts = currentTitle.split('|');
        setTitle(titleParts[0]?.trim() || '');
    };

    const handleCancelEdit = () => {
        setEditingNoteId(null);
        setEditingNote('');
        setTitle('');
    };

    const saveDetails = (id) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            title: title + ' | ' + user.name + ' - ' + formatDate(new Date()),
            note: editingNote,
            relatedID,
        };
        editNote(data, id).then((rp) => {
            notify(rp.data.success, rp.data.message);
            setEditingNoteId(null);
            setEditingNote('');
            setTitle('');
            refetch();
        });
    };

    const {
        data: incomingResponse,
        refetch,
        isLoading,
        error,
    } = useQuery(['fetchNotes', relatedID], () => fetchNotes(relatedID), { staleTime: 91200000 });

    if (isLoading) return <AaroLoadingScreen />;
    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <MDBox
            p={incomingResponse?.data?.result?.length > 0 ? 2 : 0.1}
            sx={{
                '& .add-button': {
                    opacity: 0,
                    visibility: 'hidden',
                    height: 0,
                    margin: 0,
                    transition: 'all 0.3s ease',
                    overflow: 'hidden',
                },
                '&:hover .add-button': {
                    opacity: 1,
                    visibility: 'visible',
                    height: 'auto',
                },
            }}
        >
            {incomingResponse?.data?.result?.length > 0 ? (
                <MDBox>
                    {incomingResponse.data.result
                        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                        .map((el) => (
                            <MDBox
                                key={el._id}
                                mb={1}
                                p={0.5}
                                sx={{
                                    backgroundColor: 'white',
                                    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.05)',
                                    borderRadius: '0.5rem',
                                    '& .note-controls': {
                                        opacity: 0,
                                        visibility: 'hidden',
                                        height: 0,
                                        margin: 0,
                                        transition: 'all 0.3s ease',
                                        overflow: 'hidden',
                                    },
                                    '&:hover .note-controls': {
                                        opacity: 1,
                                        visibility: 'visible',
                                        height: 'auto',
                                        marginBottom: '0.5rem',
                                    },
                                }}
                            >
                                <MDBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb={0.5}
                                    className="note-controls"
                                >
                                    <MDTypography variant="caption" fontWeight="light" sx={{ fontSize: '0.75rem' }}>
                                        {el?.title}
                                    </MDTypography>
                                    {!restricted && el?.user?._id === user.userID && (
                                        <MDBox>
                                            <IconButton
                                                size="small"
                                                color="info"
                                                onClick={() => handleEditClick(el.note, el._id, el.title)}
                                            >
                                                <EditIcon sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => {
                                                    const result = window.confirm('Silmek İstediğinize Emin Misiniz?');
                                                    if (result) {
                                                        try {
                                                            deleteNote(el._id)
                                                                .then((response) => {
                                                                    if (response.status === 200) {
                                                                        notify(true, 'Not başarıyla silindi');
                                                                        refetch();
                                                                    } else {
                                                                        notify(false, 'Silme işlemi başarısız');
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.error('Silme hatası:', error);
                                                                    notify(
                                                                        false,
                                                                        error.response?.data?.message ||
                                                                            'Silme işlemi sırasında bir hata oluştu'
                                                                    );
                                                                });
                                                        } catch (error) {
                                                            console.error('Try-catch hatası:', error);
                                                            notify(false, 'Silme işlemi sırasında bir hata oluştu');
                                                        }
                                                    }
                                                }}
                                            >
                                                <DeleteIcon sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </MDBox>
                                    )}
                                </MDBox>

                                <MDBox>
                                    {editingNoteId === el._id && (
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Başlık"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                    )}

                                    {editingNoteId === el._id ? (
                                        <MDEditor
                                            value={editingNote}
                                            onChange={setEditingNote}
                                            options={{
                                                placeholder: 'Not ekleyin...',
                                                modules: {
                                                    toolbar: [
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        [{ color: [] }, { background: [] }],
                                                        [{ font: [] }],
                                                        [{ align: [] }],
                                                        ['clean'],
                                                        ['link'],
                                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                                    ],
                                                },
                                                theme: 'snow',
                                            }}
                                            style={{
                                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                fontSize: '0.875rem',
                                                fontWeight: 300,
                                                lineHeight: 1.5,
                                                letterSpacing: '0.02857em',
                                                color: '#7b809a',
                                            }}
                                        />
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={{ __html: el.note }}
                                            style={{
                                                margin: 0,
                                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                fontWeight: 500,
                                                fontSize: '0.875rem',
                                                lineHeight: 1.57,
                                                letterSpacing: '0.00714em',
                                                marginBottom: '0.35em',
                                                color: '#000',
                                                padding: '4px 0',
                                            }}
                                        />
                                    )}
                                    {editingNoteId === el._id && (
                                        <MDBox mt={2} display="flex" justifyContent="flex-end" gap={1}>
                                            <MDButton onClick={handleCancelEdit} variant="outlined" color="info">
                                                İptal
                                            </MDButton>
                                            <MDButton
                                                onClick={() => saveDetails(el._id)}
                                                variant="gradient"
                                                color="info"
                                            >
                                                Kaydet
                                            </MDButton>
                                        </MDBox>
                                    )}
                                </MDBox>
                            </MDBox>
                        ))}
                </MDBox>
            ) : null}

            {!restricted && (
                <MDBox display="flex">
                    {visible ? (
                        <AddNote relatedID={relatedID} setVisible={setVisible} refetch={refetch} />
                    ) : (
                        <div className="add-button">
                            <IconButton size="small" color="dark" onClick={() => setVisible(true)}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </MDBox>
            )}
        </MDBox>
    );
}
