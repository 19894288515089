import React from 'react';
import Map from 'components/General/GoogleMap/Map';
import { updateLead } from 'services/api/lead';

import AddLeadPosition from './AddLeadPosition';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

export default function LeadPosition(props) {
    const { lead, showEdit = true } = props;

    const changeLatLng = (val) => {
        // console.log({ lat: val.lat, lng: val.lng, val });

        updateLead(lead._id, {
            location: {
                lat: val.lat,
                lng: val.lng,
                label: val.value.label,
            },
        }).then(() => window.location.reload());
    };
    return (
        <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
        >
            <Map
                enableCard={false}
                height={'23vh'}
                zoom={11}
                latLng={{
                    lat: lead?.location?.lat ? lead.location.lat : 39.9577965,
                    lng: lead?.location?.lng ? lead.location.lng : 32.8952745,
                }}
            />
            {showEdit && (
                <MDBox pb={1}>
                    {' '}
                    <AddLeadPosition changeLatLng={changeLatLng} />{' '}
                </MDBox>
            )}
        </MDBox>
    );
}
