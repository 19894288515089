// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// NewUser page components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import { doorTemplateCalculator } from 'layouts/projects/Forms/Templates/TemplateForm/calculator/index.js';

function Profile({ formData, door }) {
    const { values } = formData;
    const { olcuBilgisi } = values;

    const formik = useFormikContext(); // Formik durum değerlerine erişim sağlar.

    const calculatedDoorValues = doorTemplateCalculator(door, formik.values);

    const arananAnahtarlar = ['kasa', 'kanat', 'uKasa', 'uPervaz', 'diger', 'pervaz'];

    const kanatBoyHesabiDuvar = `(Duvar Boyu) - (Kapı Montaj Boşluğu) - ((Kasa Kalınlığı)-(Kasa Lamba Derinliği))-(Kanat Altı
        Boşluğu)- (Kanat ile Başlık Kasa Arası Boşluk)`;
    const kanatBoyHesabiKasa = `(Kasa Boyu) - ((Kasa Kalınlığı)-(Kasa Lamba Derinliği))-(Kanat Altı
            Boşluğu)- (Kanat ile Başlık Kasa Arası Boşluk)`;

    const kanatEnHesabiDuvar = ` (Duvar Eni)  - ((Kapı Montaj Boşluğu) * 2 )- ((Kanat ile Yan Kasa Arası Boşluk) * 2) - ((Kasa Kalınlığı)-(Kasa Lamba Derinliği)* 2)`;
    const kanatEnHesabiKasa = ` (Duvar Eni) - ((Kanat ile Yan Kasa Arası Boşluk) * 2) - ((Kasa Kalınlığı)-(Kasa Lamba Derinliği)* 2)`;
    const parts = [];
    Object.keys(calculatedDoorValues).forEach((key) => {
        if (arananAnahtarlar.includes(key)) {
            // parts.push(...door[el]);
            calculatedDoorValues[key].forEach((el) => parts.push({ key, ...el }));
        }
    });
    return (
        <MDBox>
            <MDTypography variant="h5" fontWeight="bold">
                {olcuBilgisi === 'duvar'
                    ? 'DUVAR boşluğuna göre hesap yapılacak'
                    : 'KASA DIŞTAN DIŞ ölçüsüne göre hesap yapılacak'}
            </MDTypography>
            <Stack>&nbsp;</Stack>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="outlined" severity="info">
                    <AlertTitle>Kanat Boy Hesabı</AlertTitle>
                    {olcuBilgisi === 'duvar' ? kanatBoyHesabiDuvar : kanatBoyHesabiKasa}
                </Alert>
                <Alert variant="outlined" severity="info">
                    <AlertTitle>Kanat En Hesabı</AlertTitle>
                    {olcuBilgisi === 'duvar' ? kanatEnHesabiDuvar : kanatEnHesabiKasa}
                </Alert>
                &nbsp;
                <MDTypography variant="h6" fontWeight="bold">
                    Oluşturulacak Parçalarınız
                </MDTypography>
                {/* {JSON.stringify(door)} */}
                {parts.map((el) => (
                    <Alert variant="outlined" severity="success">
                        <AlertTitle>
                            {el.key.toUpperCase()} - ({el.miktar})
                        </AlertTitle>
                        {el.baslik} ({el.ekNot})
                    </Alert>
                ))}
            </Stack>
        </MDBox>
    );
}

// typechecking props for Profile
Profile.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Profile;
