import { useQueryClient } from 'react-query';

import { ResponsivePie } from '@nivo/pie';

import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import useWindowDimensions from 'hooks/useWindowDimensions';

// Material Dashboard 2 PRO React contexts

function ChannelsChart(props) {
    const { branchName, branchID, productID } = props;
    const queryClient = useQueryClient();
    const { height } = useWindowDimensions();

    const TipID = 10005; // Satış Faturası

    const data = queryClient.getQueryData([
        'fetchAllStockMovements',
        productID,
        branchID,
        TipID,
        '2023-01-01T00:00:00',
    ]);

    if (data)
        return (
            <Card sx={{ height: height / 2.25 }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                    <MDTypography variant="h6">1 Ocak 2023 itibariyle bu ürünü alanlar ({branchName})</MDTypography>
                    <Tooltip title="See traffic channels" placement="bottom" arrow>
                        <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                            <Icon>priority_high</Icon>
                        </MDButton>
                    </Tooltip>
                </MDBox>
                <MDBox mt={3}>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <MDBox sx={{ height: height / 2.5 }}>
                                <ResponsivePie
                                    data={prepareChartData(data)}
                                    margin={{
                                        top: 40,
                                        right: 80,
                                        bottom: 40, // Reduced bottom margin
                                        left: 80,
                                    }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [['darker', 0.2]],
                                    }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [['darker', 2]],
                                    }}
                                    // Removed all 'defs' and 'fill' properties as they're not needed without legends
                                    // Removed 'legends' property completely
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        );
    return null;
}

const prepareChartData = (data) => {
    const formated = Object.values(
        data?.reduce((r, o) => {
            r[o.CariAdi] = r[o.CariAdi] || { CariAdi: o.CariAdi, Miktar: 0 };
            r[o.CariAdi].Miktar += +o.Miktar;
            return r;
        }, {})
    ).sort((a, b) => b.Miktar - a.Miktar);

    return formated.map((el) => ({
        id: el.CariAdi,
        label: el.CariAdi,
        value: el.Miktar,
    }));
};

export default ChannelsChart;
