import axios from 'axios';
import { config } from 'Constants';

/**
 * UKasa forms
 */
export const getUKasa = (uKasaID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uKasa/${uKasaID}`;
	return axios.get(url, { headers });
};

export const postUKasa = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uKasa/${demandID}`;
	return axios.post(url, data, { headers });
};

export const updateUKasa = (uKasaID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uKasa/${uKasaID}`;
	return axios.put(url, data, { headers });
};

export const deleteUKasa = (uKasaID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uKasa/${uKasaID}`;
	return axios.delete(url, { headers });
};
export const copyUKasa = (uKasaID, demandID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: user.token,
		'Access-Control-Allow-Origin': '*',
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uKasa?uKasaID=${uKasaID}&demandID=${demandID}`;
	const configuration = {
		method: 'COPY',
		url,
		headers,
		data: {},
	};
	return axios(configuration);
};

/**
 * Kasa forms
 */
export const getKasa = (kasaID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kasa/${kasaID}`;
	return axios.get(url, { headers });
};
export const postKasa = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kasa/${demandID}`;
	return axios.post(url, data, { headers });
};
export const updateKasa = (kasaID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kasa/${kasaID}`;
	return axios.put(url, data, { headers });
};
export const deleteKasa = (kasaID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kasa/${kasaID}`;
	return axios.delete(url, { headers });
};
export const copyKasa = (kasaID, demandID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: user.token,
		'Access-Control-Allow-Origin': '*',
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kasa?kasaID=${kasaID}&demandID=${demandID}`;
	const configuration = {
		method: 'COPY',
		url,
		headers,
		data: {},
	};
	return axios(configuration);
};

/**
 * Kanat forms
 */
export const getKanat = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kanat/${id}`;
	return axios.get(url, { headers });
};

export const postKanat = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kanat/${demandID}`;
	return axios.post(url, data, { headers });
};
export const updateKanat = (kanatID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kanat/${kanatID}`;
	return axios.put(url, data, { headers });
};
export const deleteKanat = (kanatID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kanat/${kanatID}`;
	return axios.delete(url, { headers });
};
export const copyKanat = (kanatID, demandID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: user.token,
		'Access-Control-Allow-Origin': '*',
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/kanat?kanatID=${kanatID}&demandID=${demandID}`;
	const configuration = {
		method: 'COPY',
		url,
		headers,
		data: {},
	};
	return axios(configuration);
};

/**
 * U Pervaz forms
 */
export const getUPervaz = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uPervaz/${id}`;
	return axios.get(url, { headers });
};
export const postUPervaz = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uPervaz/${demandID}`;
	return axios.post(url, data, { headers });
};
export const updateUPervaz = (uPervazID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uPervaz/${uPervazID}`;
	return axios.put(url, data, { headers });
};
export const deleteUPervaz = (uPervazID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uPervaz/${uPervazID}`;
	return axios.delete(url, { headers });
};
export const copyUPervaz = (uPervazID, demandID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: user.token,
		'Access-Control-Allow-Origin': '*',
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/uPervaz?uPervazID=${uPervazID}&demandID=${demandID}`;
	const configuration = {
		method: 'COPY',
		url,
		headers,
		data: {},
	};
	return axios(configuration);
};

/**
 * Pervaz forms
 */
export const getPervaz = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/pervaz/${id}`;
	return axios.get(url, { headers });
};
export const postPervaz = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/pervaz/${demandID}`;
	return axios.post(url, data, { headers });
};
export const updatePervaz = (pervazID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/pervaz/${pervazID}`;
	return axios.put(url, data, { headers });
};
export const deletePervaz = (pervazID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/pervaz/${pervazID}`;
	return axios.delete(url, { headers });
};
export const copyPervaz = (pervazID, demandID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: user.token,
		'Access-Control-Allow-Origin': '*',
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/pervaz?pervazID=${pervazID}&demandID=${demandID}`;
	const configuration = {
		method: 'COPY',
		url,
		headers,
		data: {},
	};
	return axios(configuration);
};

/**
 * ekTalep forms
 */
export const postEkTalep = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/ekTalep/${demandID}`;
	return axios.post(url, data, { headers });
};
export const updateEkTalep = (ekTalepID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/ekTalep/${ekTalepID}`;
	return axios.put(url, data, { headers });
};
export const deleteEkTalep = (ekTalepID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/ekTalep/${ekTalepID}`;
	return axios.delete(url, { headers });
};

/**
 * diger forms
 */
export const getDiger = (id) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/diger/${id}`;
	return axios.get(url, { headers });
};
export const postDiger = (demandID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.recordedBy = user.userID;
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/diger/${demandID}`;
	return axios.post(url, data, { headers });
};
export const updateDiger = (digerID, data) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	data.changedBy = user.userID;
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/diger/${digerID}`;
	return axios.put(url, data, { headers });
};
export const deleteDiger = (digerID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		'Content-Type': 'application/json',
		Authorization: user.token,
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/diger/${digerID}`;
	return axios.delete(url, { headers });
};

export const copyDiger = (digerID, demandID) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const headers = {
		Authorization: user.token,
		'Access-Control-Allow-Origin': '*',
	};
	const url = `${config.url.API_URL}/api/kahramanlar/projects/demand/diger?digerID=${digerID}&demandID=${demandID}`;
	const configuration = {
		method: 'COPY',
		url,
		headers,
		data: {},
	};
	return axios(configuration);
};
