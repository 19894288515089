import React, { useState, useEffect, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQueryClient } from 'react-query';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// @mui material components
import MDAvatar from 'components/MDAvatar';

import { getTags } from 'services/api/general/tag';
import { updateProject } from 'services/api/project';

import { config } from 'Constants';

import {
	getProjectStatusColorNew,
	getDemandStatusImage,
} from 'helpers/colorHelpers';
const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		color: '#fff',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	rootMenuItem: {
		'&$selected': {
			backgroundColor: 'red',
			color: '#fff',
			'&:hover': {
				backgroundColor: 'green',
				color: '#fff',
			},
		},
		'&:hover': {
			color: '#fff',
		},
	},
}));

const ProjectResponsibles = ({ project }) => {
	const [tags, setTags] = useState(['Yükleniyor']);
	const [selectedTag, setSelectedTag] = useState({});
	const user = JSON.parse(localStorage.getItem('user'));
	const [visible, setVisible] = useState(false);
	const classes = useStyles();
	const queryClient = useQueryClient();

	useEffect(() => {
		const fetchFiles = async () => {
			getTags(config.fixedID.PROJECT_STATUS_TAG_GROUP_ID)
				.then((response) => {
					// console.log(response);
					response.data?.result?.tags &&
						setTags(response.data?.result?.tags);
				})
				.catch((err) => {
					console.error(err);
				});
		};
		fetchFiles();
	}, []);

	useEffect(() => {
		setSelectedTag(project?.status?.tag);
	}, [project]);
	const handleChange = (event) => {
		setSelectedTag(event.target.value);
		updateProject(project._id, {
			status: {
				user: user.userID,
				tag: event.target.value?._id,
			},
		}).then(() =>
			queryClient.invalidateQueries(['fetchProject', project?._id])
		);
	};
	return (
		<MDBox
			pt={2}
			pb={2}
			px={2}
			bgColor={getProjectStatusColorNew(selectedTag?.name)}
			borderRadius="xl"
			variant="gradient"
			color="white"
			shadow="lg"
			opacity={1}
			onClick={() => setVisible(!visible)}
		>
			<MDBox
				display="flex"
				alignItems="center"
				flexDirection="row"
				justifyContent="center"
				sx={{ minWidth: 229 }}
			>
				<MDAvatar
					src={getDemandStatusImage(selectedTag?.name)}
					alt={project?.projectName}
					size="md"
					variant="rounded"
					bgColor="transparent"
					sx={{
						borderRadius: ({ borders: { borderRadius } }) =>
							borderRadius.xl,
					}}
				/>
				<MDTypography variant="h4" color="white" align="center">
					{selectedTag?.name ? selectedTag.name : 'Bilinmiyor'}
				</MDTypography>
			</MDBox>

			{visible && (
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel
						id="demo-simple-select-outlined-label"
						style={{ color: 'white' }}
					>
						{selectedTag?.name ? selectedTag.name : 'Bilinmiyor'}
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={selectedTag?.name}
						onChange={handleChange}
						label="asdasd"
						style={{ color: 'white' }}
					>
						{tags.map((tag) => (
							<MenuItem value={tag}>{tag.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</MDBox>
	);
};

export default memo(ProjectResponsibles);
