import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import Grid from '@mui/material/Grid';
import FormSelectionArea from './Sections/FormSelectionArea';
import FormResults from './Sections/FormResults';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

import { getDemand } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';
import GooglePriceSheets from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/index.js';
import DataWarning from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/DataWarning/index.js';
import GroupElements from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GroupElements';

const fetchDemand = async (id) => {
    const response = await getDemand(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

export default function DemandsSection(props) {
    const { project } = props;
    const queryClient = useQueryClient();
    // Durum değişkenini tanımlama
    const [showComponent, setShowComponent] = useState('kalemler');

    const { data, isLoading, error } = useQuery(['fetchDemand', project.demand], () => fetchDemand(project.demand), {
        staleTime: 600000,
    });

    const user = JSON.parse(localStorage.getItem('user'));

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <MDBox mt={4}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    {(user.username === 'mesen' || user.role === 'superadmin') && (
                        <GooglePriceSheets demand={data} project={project} />
                    )}

                    {/* Uyarı */}
                    <DataWarning project={project} />

                    {/* <FormResults demand={data} projectID={project._id} projectName={project.projectName} /> */}

                    {/* Buttonlar */}
                    <MDBox pb={1} display="flex" flexDirection="row-reverse">
                        <MDButton
                            variant="gradient"
                            color={showComponent === 'kalemler' ? 'info' : 'dark'}
                            size="small"
                            onClick={() => {
                                if (showComponent === 'grupla') setShowComponent('kalemler');
                                else setShowComponent('grupla');
                                // data=demand
                                queryClient.invalidateQueries(['fetchDemandGroups', data?._id]);
                            }}
                        >
                            {showComponent === 'grupla' ? 'kalemler' : 'grupla'}
                        </MDButton>
                    </MDBox>

                    {/* Duruma göre componentin gösterilmesi */}
                    {showComponent === 'grupla' && <GroupElements demand={data} projectID={project._id} />}
                    {showComponent === 'kalemler' && (
                        <>
                            <FormResults demand={data} projectID={project._id} projectName={project.projectName} />
                            <FormSelectionArea demand={data} projectID={project._id} />{' '}
                        </>
                    )}
                </Grid>
            </Grid>
        </MDBox>
    );
}
