import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { deleteChat } from 'services/api/general/chat';

import Linkify from 'react-linkify';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';

import MDBox from 'components/MDBox';
import { notify } from 'helpers/notificationHelper';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    message: {
        color: '#263238',
        padding: '7px 7px 3px 7px',
        margin: '0px',
        wordWrap: 'break-word',
    },

    profilePicture: {
        color: 'transparent',
        width: '1rem',
        height: '1rem',
        objectFit: 'cover',
        textAlign: 'center',
        textIndent: '10000px',
    },

    deletedMessageBlockRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '0.3rem',
        marginRight: '0.3rem',
    },

    deletedMessage: {
        fontSize: '0.7rem',
        color: 'rgba(0, 0, 0, 0.35)',
        fontStyle: 'italic',
    },
    deletedMessageIcon: {
        width: '0.8rem',
        height: '0.8rem',
        color: 'rgba(0, 0, 0, 0.35)',
    },
    primaryTextStyle: {
        fontSize: '0.8rem',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    deleteIconButtonStyle: {
        width: '0.4em',
        height: '0.4em',
        color: 'rgba(0, 0, 0, 0.15)',
    },
});

export default function PrimaryMessage(props) {
    const { message, messageID, isSameUser, setRefreshChat, source } = props;
    const classes = useStyles();

    const isEmptyMessage = (message) => message.length === 0;

    const deleteMessage = (id) => (
        <IconButton
            aria-label="close"
            color="inherit"
            className={classes.deleteIconButtonStyle}
            onClick={() => {
                const result = window.confirm('Silmek İstediğinize Emin Misiniz?');
                if (result)
                    deleteChat(id, source)
                        .then((rp) => {
                            notify(rp.data.succxwess, rp.data.message);
                            setRefreshChat((prev) => !prev);
                        })
                        .catch((err) => {
                            notify(false, err.message);
                        });
            }}
            size="large"
        >
            <CloseIcon fontSize="inherit" className={classes.deleteIconButtonStyle} />
        </IconButton>
    );

    if (isEmptyMessage(message))
        return (
            <div className={classes.deletedMessageBlockRight}>
                <BlockIcon className={classes.deletedMessageIcon} />
                <div className={classes.deletedMessage}>{isSameUser ? 'Bu mesajı sildiniz' : 'Bu mesaj silindi'}</div>
            </div>
        );

    return (
        <MDBox component="div" sx={{ display: 'block' }} mr={1} ml={2} mt={1}>
            <Typography component="div" className={classes.primaryTextStyle}>
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {message}
                </Linkify>
                {isSameUser ? deleteMessage(messageID) : ''}
            </Typography>
        </MDBox>
    );
}
