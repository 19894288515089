import React from 'react';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';

// CardHeader Component
const CardHeader = ({ color, label, calls, sms, estimatedCost }) => (
    <MDBox display="flex" alignItems="center" color="text" justifyContent="space-between">
        <MDBox display="flex">
            <MDBadge size="xs" color={color} badgeContent={label} container />
        </MDBox>

        <MDBox display="flex" justifyContent="flex-end" flexGrow={1}>
            <MDBox display="flex">
                <MDTypography variant="button" fontWeight="bold" color="text">
                    {calls?.length}
                </MDTypography>
                <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                    <Icon sx={{ fontWeight: 'bold' }}>call</Icon>
                </MDTypography>
            </MDBox>
            <MDBox display="flex">
                <MDTypography variant="button" fontWeight="bold" color="text">
                    {sms?.length}
                </MDTypography>
                <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                    <Icon sx={{ fontWeight: 'bold' }}>sms</Icon>
                </MDTypography>
            </MDBox>
        </MDBox>
    </MDBox>
);

export default CardHeader;
