import axios from 'axios';
import { config } from 'Constants';

const getHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
};

/**
 * @desc for given id, it gets customer visit
 * @return promise - axios returns a promise
 */
export const getVisits = (query) => {
    const url = `${config.url.API_URL}/api/general/visit?${query}`;
    return axios.get(url, { headers: getHeaders() });
};

/**
 * @desc for given id, it post customer visit
 * @return promise - axios returns a promise
 */
export const postVisit = (data) => {
    const url = `${config.url.API_URL}/api/general/visit`;
    return axios.post(url, data, { headers: getHeaders() });
};

/**
 * @desc for given id, it updates customer visit
 * @return promise - axios returns a promise
 */
export const editVisit = (id, data) => {
    const url = `${config.url.API_URL}/api/general/visit/${id}`;
    return axios.put(url, data, { headers: getHeaders() });
};

/**
 * @desc for given id, it deletes customer visit
 * @return promise - axios returns a promise
 */
export const deleteVisit = (id) => {
    const url = `${config.url.API_URL}/api/general/visit/${id}`;
    return axios.delete(url, { headers: getHeaders() });
};
