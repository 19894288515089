import * as Yup from 'yup';
import checkout from 'layouts/projects/Forms/Templates/TemplateForm/schemas/form';

const {
    formField: { olcuBilgisi, kalinlik, en, boy, adet, yanginDayanimSuresi },
} = checkout;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    Yup.object().shape({
        [olcuBilgisi.name]: Yup.string().required(olcuBilgisi.errorMsg),
        // [lastName.name]: Yup.string().required(lastName.errorMsg),
        // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
        // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
        // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
        // [repeatPassword.name]: Yup.string()
        //     .required(repeatPassword.errorMsg)
        //     .oneOf([Yup.ref('password'), null], repeatPassword.invalidMsg),
    }),
    Yup.object().shape({
        [kalinlik.name]: Yup.number().required(kalinlik.errorMsg).min(10, kalinlik.invalidMsg),
        [en.name]: Yup.number().required(en.errorMsg).min(10, en.invalidMsg),
        [boy.name]: Yup.number().required(boy.errorMsg).min(10, boy.invalidMsg),
        [adet.name]: Yup.number().required(adet.errorMsg).min(1, adet.invalidMsg),
        [yanginDayanimSuresi.name]: Yup.number().required(yanginDayanimSuresi.errorMsg).min(0, boy.invalidMsg),
    }),
    // Yup.object().shape({
    //     [twitter.name]: Yup.string().required(twitter.errorMsg),
    // }),
];
