import { useState } from 'react';
import { useQueryClient } from 'react-query';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Dialog from '@mui/material/Dialog';
import AddCall from 'components/General/Call/AddCall.js';

function SmsTemplates(props) {
    const { customer, open, handleClose, sourcePage = 'customer', initial } = props;
    const queryClient = useQueryClient();

    const [number, setNumber] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState('');

    const handleCustomerChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCustomer(selectedValue);

        const parseSelectedValue = selectedValue.split(' - ');
        const name = parseSelectedValue[0] ? parseSelectedValue[0] : '';
        const phone = parseSelectedValue[1] ? parseSelectedValue[1] : '';
        setNumber(phone);
        setSelectedCustomerName(name);
    };

    const responsibles = queryClient.getQueryData(['fetchAaroCustomerResponsibles', customer?.customerID]);

    const companyDetail = queryClient.getQueryData(['fetchAaroCustomer', customer?.customerID]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <Card>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <MDBox p={2} lineHeight={0}>
                            <MDTypography variant="h5">Numaralar</MDTypography>

                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedCustomer}
                                    onChange={handleCustomerChange}
                                >
                                    {responsibles?.map((item, index) => (
                                        <FormControlLabel
                                            key={item?.EsnekAramaKisiti}
                                            value={item?.IlgiliAdi + ' - ' + item?.Tel}
                                            control={<Radio />}
                                            label={item?.IlgiliAdi + ' - ' + item?.Tel}
                                        />
                                    ))}
                                    {companyDetail?.Tel && (
                                        <FormControlLabel
                                            key={companyDetail?.EsnekAramaKisiti}
                                            value={companyDetail?.CariAdi + ' - ' + companyDetail?.Tel}
                                            control={<Radio />}
                                            label={companyDetail?.CariAdi + ' - ' + companyDetail?.Tel}
                                        />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </MDBox>
                    </Grid>
                </Grid>

                <MDBox p={2}>
                    {/* <MDButton
					variant="gradient"
					color="success"
					onClick={handleClickOpen}
					fullWidth
				>
					SMS GÖNDER
				</MDButton> */}
                </MDBox>

                <AddCall
                    initial={{
                        relatedID: initial.relatedID || customer?._id,
                        sourcePage: initial.sourcePage || sourcePage,
                        ...initial,
                    }}
                    handleClose={handleClose}
                    number={number}
                    customerName={selectedCustomerName}
                />

                {/*Burada  */}
            </Card>
        </Dialog>
    );
}

export default SmsTemplates;
