// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// formik components
import { ErrorMessage, Field } from 'formik';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';

function FormField({ label, name, ...rest }) {
    return (
        <MDBox mb={1.5}>
            <Field name={name} as={MDInput} variant="standard" {...rest} label={label} fullWidth />
            <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                    <ErrorMessage name={name} />
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

// typechecking props for FormField
FormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default FormField;
