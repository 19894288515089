import React, { memo } from 'react';
import { useQuery } from 'react-query';
import { getUsers } from 'services/api/user';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from 'components/MDInput';

const Users = ({ selectedUsers, setSelectedUsers }) => {
    const { isLoading, error, data } = useQuery('fetchUsers', () => fetchUsers(), {
        staleTime: 600000,
    });

    if (isLoading) return <div>Yükleniyor</div>;
    if (error) return <div>Bir Hata Oluştu: {error.message}</div>;

    return (
        <>
            <Autocomplete
                multiple
                value={selectedUsers || []}
                options={data || []}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option._id === value._id}
                onChange={(_, value) => setSelectedUsers(value)}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
        </>
    );
};

const fetchUsers = async () => {
    const response = await getUsers()
        .then((rp) => rp?.data?.result)
        .catch((err) => err);
    return response;
};

export default memo(Users);

//  setSelectedUsers([...selectedUsers, value])
