// @mui material components
import { memo, useEffect } from 'react';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import { connectHighlight } from 'react-instantsearch-dom';
import PricePopover from 'layouts/aaro/fastPrice/views/pricePopover';
import StockPopover from 'layouts/aaro/fastPrice/views/stockPopover';

import AddToBasket from '../../components/AddToBasket';
// Images
import logo from 'assets/images/projects/kahramanlar.png';
// import aa from 'search-insights';
import useWindowDimensions from 'hooks/useWindowDimensions';

function SingleItem(props) {
    const { hit, insights, key } = props;
    const { height, width } = useWindowDimensions();

    // useEffect(() => {
    // 	console.log('single item render');
    // });
    return (
        <MDBox
            m={3}
            px={3}
            color="white"
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            key={key}
        >
            <Grid container alignItems="center">
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" alignItems="center">
                        <MDBox mr={2} display={{ xs: 'none', sm: 'none', md: 'block' }}>
                            <MDAvatar size="lg" src={logo} alt="Gold Glasses" />
                        </MDBox>
                        <MDBox lineHeight={1} pt={2}>
                            <CustomHighlight attribute="name" hit={hit} />
                            <MDBox
                                mb={2}
                                display={{
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex',
                                    lg: 'flex',
                                }}
                            >
                                {hit?.aaroCodes?.map(
                                    (tag) =>
                                        tag.length > 1 && (
                                            <MDBox px={0.25}>
                                                <MDBadge
                                                    variant="gradient"
                                                    color="success"
                                                    size="xs"
                                                    badgeContent={tag}
                                                    container
                                                />
                                            </MDBox>
                                        )
                                )}
                            </MDBox>
                            <MDBox
                                mb={2}
                                display={{
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex',
                                    lg: 'flex',
                                }}
                            >
                                {hit?.aaroTags?.map(
                                    (tag) =>
                                        tag.length > 1 && (
                                            <MDBox px={0.25}>
                                                <MDBadge
                                                    variant="gradient"
                                                    color="warning"
                                                    size="xs"
                                                    badgeContent={tag}
                                                    container
                                                />
                                            </MDBox>
                                        )
                                )}
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                    <MDBox
                        display="flex"
                        flexDirection="row-reverse"
                        alignItems="center"
                        // justifyContent="center"
                        justifyContent={{
                            xs: 'center',
                            sm: 'center',
                            md: 'right',
                        }}
                    >
                        <MDBox m={0.5}>
                            <AddToBasket
                                stockID={hit.productID}
                                stockName={hit.name}
                                stockCode={hit.sku}
                                queryID={hit.__queryID}
                                objectID={hit.objectID}
                            />
                        </MDBox>

                        <MDBox
                            m={0.5}
                            // onMouseEnter={() => {
                            // 	insights('clickedObjectIDsAfterSearch', {
                            // 		eventName: 'Stock viewed',
                            // 	});
                            // }}
                        >
                            <StockPopover stockID={hit.productID} stockName={hit.name} stockCode={hit.sku} />
                        </MDBox>
                        <MDBox
                            m={0.5}
                            // onMouseEnter={() => {
                            // 	insights('clickedObjectIDsAfterSearch', {
                            // 		eventName: 'Price viewed',
                            // 	});
                            // }}
                        >
                            <PricePopover
                                stockID={hit.productID}
                                stockName={hit.name}
                                stockCode={hit.sku}
                                queryID={hit.__queryID}
                                objectID={hit.objectID}
                            />
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
    const parsedHit = highlight({
        highlightProperty: '_highlightResult',
        attribute,
        hit,
    });

    return (
        <MDTypography
            fontWeight="medium"
            sx={{
                typography: {
                    xs: 'h6',
                    sm: 'h6',
                    md: 'h6',
                },
                textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'left',
                },
            }}
        >
            {parsedHit.map((part) => (part.isHighlighted ? <mark>{part.value}</mark> : part.value))}
        </MDTypography>
    );
});

export default memo(SingleItem);
