import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { postVisit } from 'services/api/general/visit';
import Dialog from '@mui/material/Dialog';
const validationSchema = yup.object({
    customer: yup.string('Customer ID is required').required('Customer ID is required'),
    visitDate: yup
        .date('Invalid date')
        .default(() => new Date())
        .required('Visit date is required'),
    visitDuration: yup.number('Visit duration must be a number').required('Visit duration is required'),
    visitNotes: yup.string('Visit notes must be a string').default(''),
    sourcePage: yup.string('Source page must be a string').default('customer'),
});

export default function InputArea(props) {
    const { initial, open, customer, handleClose, number } = props;
    const { _id: customerID } = customer;

    const queryClient = useQueryClient();

    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;
    const formik = useFormik({
        initialValues: {
            customer: initial?.customer || '',
            visitDate: initial?.visitDate || new Date(),
            visitDuration: initial?.visitDuration || '',
            visitNotes: initial?.visitNotes || '',
            sourcePage: initial?.sourcePage || 'customer',
            user: userID,
            relatedID: initial?.relatedID || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setDisableOnSubmit(true);

            postVisit(values)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    handleClose();
                    queryClient.invalidateQueries(['fetchVisits']);
                })
                .catch((err) => notify(false, JSON.stringify(err)));
        },
    });

    useEffect(() => {
        if (customerID) formik.setFieldValue('customer', customerID);
    }, [customerID]);

    useEffect(() => {
        if (number) formik.setFieldValue('visitDuration', number);
    }, [number]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={formik.handleSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="Ziyaret Tarihi"
                                    placeholder="Ziyaret Tarihi"
                                    id="visitDate"
                                    name="visitDate"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={formik.values.visitDate}
                                    onChange={formik.handleChange}
                                    error={formik.touched.visitDate && Boolean(formik.errors.visitDate)}
                                    helperText={formik.touched.visitDate && formik.errors.visitDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="Ziyaret Süresi (dk)"
                                    placeholder="Ziyaret Süresi (dk)"
                                    id="visitDuration"
                                    name="visitDuration"
                                    value={formik.values.visitDuration}
                                    onChange={formik.handleChange}
                                    error={formik.touched.visitDuration && Boolean(formik.errors.visitDuration)}
                                    helperText={formik.touched.visitDuration && formik.errors.visitDuration}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormField
                                    label="Ziyaret Notları"
                                    placeholder="Ziyaret Notları"
                                    id="visitNotes"
                                    name="visitNotes"
                                    value={formik.values.visitNotes}
                                    onChange={formik.handleChange}
                                    error={formik.touched.visitNotes && Boolean(formik.errors.visitNotes)}
                                    helperText={formik.touched.visitNotes && formik.errors.visitNotes}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <MDButton type="submit" variant="contained" color="primary" disabled={disableOnSubmit}>
                                    Submit
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </Card>
        </Dialog>
    );
}
