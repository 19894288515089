import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { useQuery } from 'react-query';
import { getTags } from 'services/api/general/tag';
import AaroLoadingScreen from 'components/Loading';
import MDButton from 'components/MDButton';
import AddTag from 'components/Tags/Forms/AddTag.js';
import EditTags from 'components/Tags/Forms/EditTags.js';
import Dialog from '@mui/material/Dialog';

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) => (rp.data?.result?.tags ? rp.data?.result?.tags : []));
    return response;
};

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

// PopperComponent.propTypes = {
//     anchorEl: PropTypes.any,
//     disablePortal: PropTypes.bool,
//     open: PropTypes.bool.isRequired,
// };

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
    fontWeight: 600,
    '&:hover,&:focus': {
        color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
    },
    '& span': {
        width: '100%',
    },
    '& svg': {
        width: 16,
        height: 16,
    },
}));

export default function GitHubLabel(props) {
    const { selectedTags, setSelectedTags, tagGroupID, tagLabel, multiple = false, handleChanges, dbKey = '' } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);

    const [typingValue, setTypingValue] = React.useState('');

    const [value, setValue] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);

    const theme = useTheme();

    React.useEffect(() => {
        setValue(selectedTags);
    }, [selectedTags]);

    const handleClick = (event) => {
        setPendingValue(value);

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        // const arrUniq = [
        // 	...new Map(pendingValue.map((v) => [v._id, v])).values(),
        // ];
        setValue(pendingValue);
        handleChanges(dbKey, pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    const isArray = () => Array.isArray(value) && value.length > 0;

    const { isLoading, error, data } = useQuery(['fetchTagGroups', tagGroupID], () => fetchTags(tagGroupID), {
        staleTime: 600000,
    });
    if (isLoading) return <AaroLoadingScreen />;
    if (error) return 'Bir Hata Oluştu(ETİKETLER): ' + error.message;

    if (data)
        return (
            <React.Fragment>
                <Box sx={{ fontSize: 12 }} display="flex" flexWrap="wrap">
                    <Button disableRipple aria-describedby={id} onClick={handleClick}>
                        <span>{tagLabel}</span>
                        <SettingsIcon />
                    </Button>
                    {isArray(value) ? (
                        value.map((label) => (
                            <Box
                                key={label._id}
                                sx={{
                                    mt: '3px',
                                    mr: '3px',
                                    height: 20,
                                    padding: '.20em 8px',
                                    fontWeight: 600,
                                    lineHeight: '15px',
                                    borderRadius: '2em',
                                }}
                                style={{
                                    backgroundColor: label.color,
                                    color: theme.palette.getContrastText(label.color),
                                }}
                                display="inline-block"
                            >
                                {label?.name?.toLowerCase()}
                            </Box>
                        ))
                    ) : (
                        <Box
                            key={value?._id}
                            sx={{
                                mt: '3px',
                                mr: '3px',
                                height: 20,
                                padding: '.20em 8px',
                                fontWeight: 600,
                                lineHeight: '15px',
                                borderRadius: '2em',
                            }}
                            style={{
                                backgroundColor: value?.color,
                                color: theme.palette.getContrastText(value?.color ? value.color : '#ffffff'),
                            }}
                            display="inline-block"
                        >
                            {value?.name?.toLowerCase()}
                        </Box>
                    )}
                </Box>
                <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <Box
                                sx={{
                                    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                                    padding: '8px 10px',
                                    fontWeight: 600,
                                }}
                                onClick={() => setOpenEditDialog(true)}
                            >
                                Etiketleri düzenlemek için tıklayınız
                            </Box>
                            <Autocomplete
                                open
                                multiple={multiple}
                                onClose={(event, reason) => {
                                    if (reason === 'escape') {
                                        handleClose();
                                    }
                                }}
                                value={pendingValue}
                                onInputChange={(event, newInputValue) => {
                                    setTypingValue(newInputValue);
                                }}
                                onChange={(event, newValue, reason) => {
                                    if (
                                        event.type === 'keydown' &&
                                        event.key === 'Backspace' &&
                                        reason === 'removeOption'
                                    ) {
                                        return;
                                    }

                                    setPendingValue(newValue);
                                }}
                                disableCloseOnSelect
                                PopperComponent={PopperComponent}
                                renderTags={() => null}
                                noOptionsText={
                                    <MDButton
                                        size="small"
                                        variant="gradient"
                                        color="success"
                                        sx={{ width: '100%' }}
                                        onClick={() => setOpenDialog(true)}
                                    >
                                        YENİ ekle "{typingValue}"
                                    </MDButton>
                                }
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option?._id}>
                                        <Box
                                            component={DoneIcon}
                                            sx={{
                                                width: 17,
                                                height: 17,
                                                mr: '5px',
                                                ml: '-2px',
                                            }}
                                            style={{
                                                visibility: selected ? 'visible' : 'hidden',
                                            }}
                                        />
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 14,
                                                height: 14,
                                                flexShrink: 0,
                                                borderRadius: '3px',
                                                mr: 1,
                                                mt: '2px',
                                            }}
                                            style={{
                                                backgroundColor: option.color,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                '& span': {
                                                    color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                                },
                                            }}
                                        >
                                            {option.name}
                                            <br />
                                            <span>{option.description}</span>
                                        </Box>
                                        <Box
                                            component={CloseIcon}
                                            sx={{
                                                opacity: 0.6,
                                                width: 18,
                                                height: 18,
                                            }}
                                            style={{
                                                visibility: selected ? 'visible' : 'hidden',
                                            }}
                                        />
                                    </li>
                                )}
                                options={
                                    isArray(value)
                                        ? data.sort((a, b) => {
                                              // Display the selected data first.
                                              let ai = value?.findIndex((object) => {
                                                  return object._id === a._id;
                                              });
                                              ai =
                                                  ai === -1
                                                      ? value?.length +
                                                        data.findIndex((object) => {
                                                            return object._id === a._id;
                                                        })
                                                      : ai;
                                              let bi = value?.findIndex((object) => {
                                                  return object._id === b._id;
                                              });
                                              bi =
                                                  bi === -1
                                                      ? value?.length +
                                                        data.findIndex((object) => {
                                                            return object._id === b._id;
                                                        })
                                                      : bi;
                                              return ai - bi;
                                          })
                                        : data
                                }
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <StyledInput
                                        ref={params.InputProps.ref}
                                        inputProps={params.inputProps}
                                        autoFocus
                                        placeholder="Etiketlerde Ara"
                                    />
                                )}
                            />
                        </div>
                    </ClickAwayListener>
                </StyledPopper>
                <Dialog onClose={() => setOpenDialog(false)} open={openDialog} fullWidth={true} maxWidth="sm">
                    {/*Burada  */}
                    <AddTag setOpenDialog={setOpenDialog} initial={{ tagGroupID, name: typingValue }} />
                </Dialog>
                <Dialog onClose={() => setOpenEditDialog(false)} open={openEditDialog} fullWidth={true} maxWidth="md">
                    {/*Burada  */}
                    <EditTags tagGroupID={tagGroupID} />
                </Dialog>
            </React.Fragment>
        );
    return <>Yükleniyor...</>;
}
