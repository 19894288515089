import React, { memo } from 'react';
import { useQuery } from 'react-query';
// import PeopleCardFooter from '@mui-treasury/components/cardFooter/people';

import AddResponsible from './AddResponsible';
import { getUsers } from 'services/api/user';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// @mui material components

import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar';
import AaroLoadingScreen from 'components/Loading';

// Image

import team4 from 'assets/images/team-4.jpg';

const fetchUsers = async (id) => {
    const response = await getUsers(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const Responsibles = (props) => {
    const avatarStyles = {
        border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
        cursor: 'pointer',
        position: 'relative',
        ml: -1.5,

        '&:hover, &:focus': {
            zIndex: '10',
        },
    };
    const {
        lead,
        showAddButton = true,
        avatarSize = 'sm',
        additionalStyle = null,
    } = props;
    const { _id: leadID, responsibles } = lead;

    const allUsers = useQuery('fetchUsers', () => fetchUsers(), {
        staleTime: 600000,
    });

    if (allUsers.isLoading) return <AaroLoadingScreen />;
    if (allUsers.error)
        return (
            'Kullanıcıları Getirirken Bir Hata Oluştu: (Responsibles) ' +
            allUsers.error
        );

    return (
        <MDBox
            // component="ul"
            display="flex"
            flexDirection="row"
            justifyContent="center"
        >
            {responsibles?.map((el) => (
                <Tooltip
                    title={el.name}
                    placement="top"
                    key={el._id + 'toolTip'}
                >
                    <MDAvatar
                        key={el._id}
                        src={el.profilePicture ? el.profilePicture : team4}
                        alt={el.name}
                        size={avatarSize}
                        sx={additionalStyle ? additionalStyle : avatarStyles}
                    />
                </Tooltip>
            ))}
            {showAddButton && (
                <AddResponsible
                    allResponsibles={responsibles}
                    leadID={leadID}
                    users={allUsers.data}
                />
            )}
        </MDBox>
    );
};

export default memo(Responsibles);
