import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

//#region
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import MDBox from 'components/MDBox';

//#endregion

import {
	getDekontBaslik,
	getDekontKalem,
} from 'services/outside/aaro/aaroDekont';
import { formatDateMinutes } from 'helpers/smallCodes';
import { typeMatch } from 'helpers/aaroConverter';

import AaroLoadingScreen from 'components/Loading';

const fetchAaroDekontBaslik = async (params) => {
	const response = await getDekontBaslik(params).then((rp) => rp.data);
	return response;
};
const fetchAaroDekontKalemler = async (params) => {
	const response = await getDekontKalem(params).then((rp) => rp.data);
	return response;
};

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<MDBox sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? (
					<LastPageIcon />
				) : (
					<FirstPageIcon />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? (
					<FirstPageIcon />
				) : (
					<LastPageIcon />
				)}
			</IconButton>
		</MDBox>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function Row(props) {
	const { row, size } = props;
	const [open, setOpen] = React.useState(false);

	const { data, isLoading, error, isFetching, refetch } = useQuery(
		['fetchAaroDekontKalemler', { DekontID: row.DekontID }],
		() =>
			fetchAaroDekontKalemler({
				DekontID: row.DekontID,
				SayfaSatirSayisi: 100,
			}),
		{ staleTime: 600000, enabled: false }
	);

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			justifyContent="flex-end"
			alignItems="center"
		>
			{' '}
			<MDBox
				display="flex"
				flexDirection="row"
				justifyContent="flex-end"
				alignItems="center"
				sx={{ width: '100%' }}
			>
				<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
					{' '}
					<TableCell>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => {
								setOpen(!open);
								refetch();
							}}
						>
							{open ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</IconButton>
					</TableCell>
					<TableCell component="th" scope="row">
						{row.KartAdi}
					</TableCell>
					<TableCell align="right">
						{formatDateMinutes(row.DgsTar)}
					</TableCell>
					<TableCell align="right">{row.Tutar}</TableCell>
					<TableCell align="right">{row.BelgeNo}</TableCell>
					<TableCell align="right">
						<a
							href={`https://erp2.aaro.com.tr/DekontFatura/Kalem?id=${row.DekontID}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Button
								style={{
									backgroundColor: typeMatch(row.TipID).color,
									color: 'white',
								}}
								variant="contained"
							>
								{typeMatch(row.TipID).name}
							</Button>
						</a>
					</TableCell>
				</TableRow>
			</MDBox>
			<MDBox>
				<TableRow>
					<TableCell
						style={{ paddingBottom: 0, paddingTop: 0 }}
						colSpan={6}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<MDBox m={1}>
								<Table size="small" aria-label="purchases">
									<TableHead>
										<TableRow>
											<TableCell
												style={{ color: '#e63946' }}
											>
												Stok Adı
											</TableCell>
											<TableCell
												style={{ color: '#e63946' }}
											>
												Birim Fiyat(KDV HARİÇ){' '}
											</TableCell>
											<TableCell
												align="right"
												style={{ color: '#e63946' }}
											>
												Miktar
											</TableCell>
											<TableCell
												align="right"
												style={{ color: '#e63946' }}
											>
												Toplam (KDV Hariç)
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.Model?.map((item) => (
											<TableRow key={item.HareketID}>
												<TableCell
													component="th"
													scope="row"
												>
													{item?.KartAdi}
												</TableCell>
												<TableCell>
													{item?.Stok?.BrmFiyatN}
												</TableCell>

												<TableCell align="right">
													{item?.Miktar}
												</TableCell>
												<TableCell align="right">
													{item?.Tutar}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</MDBox>
						</Collapse>
					</TableCell>
				</TableRow>
			</MDBox>
		</MDBox>
	);
}

export default function DekontsTable(props) {
	const { params, size } = props;
	const [page, setPage] = React.useState(0);
	const [changedParams, setChangedParams] = React.useState(params);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	useEffect(() => setChangedParams(params), [props]);
	useEffect(() => {
		const copyOfParams = changedParams;
		copyOfParams.Sayfa = page + 1;
		setChangedParams(copyOfParams);
	}, [page]);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		refetch();
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const { data, isLoading, error, isFetching, isPreviousData, refetch } =
		useQuery(
			['fetchAaroDekontBasliklar', changedParams],
			() => fetchAaroDekontBaslik(changedParams),
			{ staleTime: 600000, keepPreviousData: true }
		);

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>Cari Adı</TableCell>
						<TableCell align="right">Oluşturulma Tarihi</TableCell>
						<TableCell align="right">Tutar</TableCell>
						<TableCell align="right">Belge No</TableCell>
						<TableCell align="right">Belge Tipi</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.Model.map((row) => (
						<Row key={row.DekontID} row={row} />
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						{isPreviousData ? (
							<TableCell colSpan={6}>
								{' '}
								<AaroLoadingScreen />
							</TableCell>
						) : (
							<TablePagination
								rowsPerPageOptions={[10]}
								colSpan={6}
								count={
									data.SayfalandirmaBilgisi.ToplamSatirSayisi
								}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										'aria-label': 'Satır Sayısı',
									},
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						)}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
