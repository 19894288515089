import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getActivities = (query) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/activity?${query}`;
    return axios.get(url, { headers });
};
