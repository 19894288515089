import React from 'react';
import MDBox from 'components/MDBox';
import Potential from 'layouts/lead/components/Potential';

// CardFooter Component
const CardFooter = ({ estimatedCost, source, interests, data, renderMembers }) => (
    <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{ minHeight: '24px' }}>
        <Potential lead={data} />

        <MDBox display="flex">{renderMembers}</MDBox>
    </MDBox>
);

export default CardFooter;
