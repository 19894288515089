// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props

// @mui material components
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';

function DefaultProjectCard({ image, label, title, doorDescription, action, authors, setSelected, door, tags }) {
    const renderAuthors = authors?.map(({ image: media, name }) => (
        <Tooltip key={name} title={name} placement="bottom">
            <MDAvatar
                src={media}
                alt={name}
                size="xs"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: 'pointer',
                    position: 'relative',
                    ml: -1.25,

                    '&:hover, &:focus': {
                        zIndex: '10',
                    },
                })}
            />
        </Tooltip>
    ));

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'visible',
            }}
        >
            <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
                <CardMedia
                    src={image}
                    component="img"
                    title={title}
                    sx={{
                        maxWidth: '100%',
                        margin: 0,
                        boxShadow: ({ boxShadows: { md } }) => md,
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
            </MDBox>
            <MDBox mt={1} mx={0.5}>
                {/* <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                    {label}
                </MDTypography> */}
                <MDBox mb={1}>
                    {action.type === 'internal' ? (
                        <MDTypography component={Link} to={action.route} variant="h5" textTransform="capitalize">
                            {title}
                        </MDTypography>
                    ) : (
                        <MDTypography
                            component="a"
                            href={action.route}
                            target="_blank"
                            rel="noreferrer"
                            variant="h5"
                            textTransform="capitalize"
                        >
                            {title}
                        </MDTypography>
                    )}
                </MDBox>
                <MDBox mb={3} lineHeight={0}>
                    <MDTypography variant="button" fontWeight="light" color="text">
                        {doorDescription}{' '}
                    </MDTypography>
                    {tags?.map((el) => (
                        <MDBadge
                            badgeContent={el.name}
                            color={el.muiColorCode ? el.muiColorCode : 'dark'}
                            size="xs"
                            circular
                        />
                    ))}
                </MDBox>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDButton variant="outlined" size="small" color={action.color} onClick={() => setSelected(door)}>
                        Şablonu Seç
                    </MDButton>

                    <MDBox display="flex">{renderAuthors}</MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default DefaultProjectCard;
