import TaskInfoCard from './TaskInfoCard';

import { formatDateMinutes } from 'helpers/dateHelpers';
function ItemDetails({ task }) {
	if (task == null) return <p>!</p>;
	return (
		<TaskInfoCard
			title={task.name}
			description={task.description}
			info={{
				olusturan: task.createdBy?.name,
				sorumlular: task.assignee.map((el) => el?.name).join(', '),
				durumu: task.completed ? 'Tamamlandı' : 'Tamamlanmadı',
				oncelik: task.priority,
				tamamlayan: task.completedBy?.name,
				tamamlanmaTarihi: task.completed
					? formatDateMinutes(task.completedAt)
					: '---',
				olusturulmaTarihi: formatDateMinutes(task.createdAt),
			}}
			social={
				[
					// 	{
					// 		link: 'https://www.facebook.com/CreativeTim/',
					// 		icon: <FacebookIcon />,
					// 		color: 'facebook',
					// 	},
					// 	{
					// 		link: 'https://twitter.com/creativetim',
					// 		icon: <TwitterIcon />,
					// 		color: 'twitter',
					// 	},
					// 	{
					// 		link: 'https://www.instagram.com/creativetimofficial/',
					// 		icon: <InstagramIcon />,
					// 		color: 'instagram',
					// 	},
				]
			}
			action={{ route: '', tooltip: '' }}
			shadow={true}
		/>
	);
}

export default ItemDetails;
