import React from 'react';
import { Link } from 'react-router-dom';

import MDTypography from 'components/MDTypography';

export default function ProjectName({ id, formattedValue }) {
    const displayValue = formattedValue.length > 25 ? formattedValue.substring(0, 25) + '...' : formattedValue;

    return (
        <MDTypography
            variant="caption"
            fontWeight="medium"
            color="text"
            to={`../projects/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            component={Link}
        >
            {displayValue}
        </MDTypography>
    );
}
