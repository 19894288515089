// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import { isSuperAdmin } from 'helpers/roleHelpers'; // isSuperAdmin fonksiyonunu import et

function ProductImages({ coverImage, sheetURL }) {
    const user = JSON.parse(localStorage.getItem('user')); // Kullanıcı bilgisine eriş

    const handleClick = () => {
        if (isSuperAdmin(user)) {
            window.open(sheetURL, '_blank'); // Yeni sekmede URL'i aç
        }
    };

    return (
        <MDBox onClick={handleClick} sx={{ cursor: isSuperAdmin(user) ? 'pointer' : 'default' }}>
            <MDBox
                component="img"
                src={coverImage}
                alt="Product Image"
                shadow="lg"
                borderRadius="lg"
                width="100%"
                height="100%"
            />
        </MDBox>
    );
}

export default ProductImages;
