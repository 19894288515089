const desiredKeysKanat = [
    'verilenFiyat',
    'miktar',
    'kalinlik',
    'en',
    'boy',
    'cins',
    'cinsAciklama',
    'dolguTipi',
    'derzTipi',
    'derzAdedi',
    'derzSekli',
    'derzBicakAdedi',
    'cumbaBandi',
    'cumbaKalinligi',
    'cumbaTipi',
    'kilitYeri',
    'menteseYeri',
    'hidrolik',
    'giyotin',
    'durbun',
    'sertAgacDonme',
    'camYeri',
    'yuzeyKaplamaSekli',
    'altUstSerenAdet',
    'serenDestekMetre',

    'ekNot',
    'changedDate',
    'activeSheetURL',
    'folderName',
    'relatedProjectID',
];

const comparisionKeysKanat = {
    kalinlik: 10,
    en: 7,
    boy: 10,
    cins: 10,
    cinsAciklama: 1,
    derzTipi: 10,
    derzAdedi: 10,
    derzSekli: 10,
    derzBicakAdedi: 10,
    cumbaBandi: 10,
    cumbaKalinligi: 10,
    cumbaTipi: 10,
    kilitYeri: 10,
    menteseYeri: 10,
    hidrolik: 10,
    giyotin: 10,
    durbun: 10,
    sertAgacDonme: 10,
    camYeri: 10,
    yuzeyKaplamaSekli: 10,
    altUstSerenAdet: 10,
    serenDestekMetre: 10,
    ekNot: 1,
};

// Kasa

const desiredKeysKasa = [
    'verilenFiyat',
    'miktar',
    'kalinlik',
    'en',
    'boy',
    'cins',
    'cinsAciklama',
    'yapi',
    'lambaDerinlik',
    'lambaGenislik',
    'koseliBombeli',
    'fitil',
    'pervazKanalGenisligi',
    'pervazKanalDerinligi',
    'ozelProfilTipi',
    'ozelProfilAciklama',
    'dumanFitili',
    'kesim45',
    'kilitKarsiligi',
    'menteseKarsiligi',
    'hidrolikKarsiligi',
    'soveMi',
    'ekNot',
    'changedDate',
    'activeSheetURL',
    'folderName',
    'relatedProjectID',
];

const comparisionKeysKasa = {
    kalinlik: 10,
    en: 10,
    boy: 2,
    cins: 10,
    cinsAciklama: 10,
    yapi: 10,
    lambaDerinlik: 10,
    lambaGenislik: 10,
    koseliBombeli: 10,
    fitil: 5,
    pervazKanalGenisligi: 10,
    pervazKanalDerinligi: 10,
    ozelProfilTipi: 10,
    ozelProfilAciklama: 10,
    dumanFitili: 10,
    kesim45: 8,
    kilitKarsiligi: 9,
    menteseKarsiligi: 7,
    hidrolikKarsiligi: 10,
    soveMi: 10,
    ekNot: 1,
    folderName: 10,
};

// U Kasa

const desiredKeysUKasa = [
    'verilenFiyat',
    'miktar',
    'kalinlik',
    'en',
    'boy',
    'cins',
    'cinsAciklama',
    'yapi',
    'lambaDerinlik',
    'lambaGenislik',
    'fitil',
    'pervazKanalGenisligi',
    'pervazKanalDerinligi',
    'pervazKanaliAltBosluk',
    'ozelProfilTipi',
    'ozelProfilAciklama',
    'dumanFitili',
    'kesim45',
    'kilitKarsiligi',
    'menteseKarsiligi',
    'hidrolikKarsiligi',
    'soveMi',
    'ekNot',
    'changedDate',
    'activeSheetURL',
    'folderName',
    'relatedProjectID',
];

const comparisionKeysUKasa = {
    kalinlik: 10,
    en: 10,
    boy: 2,
    cins: 10,
    cinsAciklama: 10,
    yapi: 10,
    lambaDerinlik: 10,
    lambaGenislik: 10,
    fitil: 5,
    pervazKanalGenisligi: 10,
    pervazKanalDerinligi: 10,
    pervazKanaliAltBosluk: 10,
    ozelProfilTipi: 10,
    ozelProfilAciklama: 10,
    dumanFitili: 10,
    kesim45: 8,
    kilitKarsiligi: 9,
    menteseKarsiligi: 7,
    hidrolikKarsiligi: 10,
    soveMi: 10,
    ekNot: 1,
    folderName: 10,
};

// PERVAZ

const desiredKeysPervaz = [
    'verilenFiyat',
    'miktar',
    'kalinlik',
    'en',
    'boy',
    'cins',
    'cinsAciklama',
    'yapi',
    'gecmeKalinlik',
    'gecmeIctenUzunluk',
    'koseliBombeli',
    'ozelProfilTipi',
    'ozelProfilAciklama',
    'hamZimpara',
    'boyKesmeKertme',
    'ekNot',
    'changedDate',
    'activeSheetURL',
    'folderName',
    'relatedProjectID',
];

const comparisionKeysPervaz = {
    kalinlik: 10,
    en: 10,
    boy: 2,
    cins: 10,
    cinsAciklama: 10,
    yapi: 10,
    gecmeKalinlik: 8,
    gecmeIctenUzunluk: 8,
    koseliBombeli: 10,
    ozelProfilTipi: 10,
    ozelProfilAciklama: 10,
    hamZimpara: 8,
    boyKesmeKertme: 8,
    ekNot: 1,
    folderName: 10,
};

// U PERVAZ

const desiredKeysUPervaz = [
    'verilenFiyat',
    'miktar',
    'kalinlik',
    'en',
    'boy',
    'mdfKalinlik',
    'cins',
    'cinsAciklama',
    'yapi',
    'gecmeKalinlik',
    'gecmeIctenUzunluk',
    'ozelProfilTipi',
    'ozelProfilAciklama',
    'hamZimpara',
    'boyKesmeKertme',
    'ekNot',
    'changedDate',
    'activeSheetURL',
    'folderName',
    'relatedProjectID',
];

const comparisionKeysUPervaz = {
    kalinlik: 10,
    en: 10,
    boy: 2,
    mdfKalinlik: 10,
    cins: 10,
    cinsAciklama: 10,
    yapi: 10,
    gecmeKalinlik: 8,
    gecmeIctenUzunluk: 8,
    ozelProfilTipi: 10,
    ozelProfilAciklama: 10,
    hamZimpara: 8,
    boyKesmeKertme: 8,
    ekNot: 1,
    folderName: 10,
};

const desiredKeysDiger = [
    'verilenFiyat',
    'miktar',
    'baslik',
    'ekNot',
    'yoneticiNotu',
    'changedDate',
    'activeSheetURL',
    'folderName',
    'relatedProjectID',
];

const comparisionKeysDiger = {
    verilenFiyat: 8,
    miktar: 8,
    baslik: 8,
    ekNot: 8,
    yoneticiNotu: 8,
    folderName: 8,
};
export const constants = (activeItemType) => {
    if (activeItemType === 'kanat')
        return {
            desiredKeys: desiredKeysKanat,
            comparisionKeys: comparisionKeysKanat,
        };
    if (activeItemType === 'kasa')
        return {
            desiredKeys: desiredKeysKasa,
            comparisionKeys: comparisionKeysKasa,
        };
    else if (activeItemType === 'uKasa')
        return {
            desiredKeys: desiredKeysUKasa,
            comparisionKeys: comparisionKeysUKasa,
        };
    else if (activeItemType === 'pervaz')
        return {
            desiredKeys: desiredKeysPervaz,
            comparisionKeys: comparisionKeysPervaz,
        };
    else if (activeItemType === 'uPervaz')
        return {
            desiredKeys: desiredKeysUPervaz,
            comparisionKeys: comparisionKeysUPervaz,
        };
    else
        return {
            desiredKeys: desiredKeysDiger,
            comparisionKeys: comparisionKeysDiger,
        };
};

// bu fonksiyon uKasa, kasa, pervaz, kanat, uPervaz, diger arraylerindeki objelere demanddeki active sheet içerisinde ait oldukları google ID eşleşmesini sağlıyor
export function matchProducts(jsonObj) {
    const { uKasa, kasa, pervaz, kanat, uPervaz, diger, activeSheet } = jsonObj;
    if (activeSheet) {
        const { matches } = activeSheet;
        matches?.forEach((match) => {
            const { productID, productType } = match;

            switch (productType) {
                case 'diger':
                    diger?.forEach((item) => {
                        if (item._id === productID) {
                            item.match = match;
                        }
                    });
                    break;
                case 'uKasa':
                    uKasa?.forEach((item) => {
                        if (item._id === productID) {
                            item.match = match;
                        }
                    });
                    break;
                case 'kasa':
                    kasa?.forEach((item) => {
                        if (item._id === productID) {
                            item.match = match;
                        }
                    });
                    break;
                case 'pervaz':
                    pervaz?.forEach((item) => {
                        if (item._id === productID) {
                            item.match = match;
                        }
                    });
                    break;
                case 'kanat':
                    kanat?.forEach((item) => {
                        if (item._id === productID) {
                            item.match = match;
                        }
                    });
                    break;
                case 'uPervaz':
                    uPervaz?.forEach((item) => {
                        if (item._id === productID) {
                            item.match = match;
                        }
                    });
                    break;
                default:
                    break;
            }
        });
    }

    return jsonObj;
}
