import React from 'react';
import TextField from '@mui/material/TextField';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
export default function FormSelect(props) {
    const { variant, fullWidth, label, placeHolder, type, formik, itemID, disabled, selector } = props;

    return (
        <MDBox m={1}>
            <MDBox display="none">
                <TextField
                    disabled={disabled}
                    fullWidth={fullWidth}
                    id={itemID}
                    name={itemID}
                    label={label}
                    placeholder={placeHolder}
                    type={type}
                    multiline={true}
                    rows={5}
                    value={(formik.values[itemID] = selectTitle(selector, formik))}
                    onChange={formik.handleChange}
                    error={formik.touched[itemID] && Boolean(formik.errors[itemID])}
                    helperText={formik.touched[itemID] && formik.errors[itemID]}
                    variant={variant}
                    size="small"
                />
            </MDBox>
            <MDBox color="white" bgColor="dark" variant="gradient" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                <MDTypography variant="h6" align="center" color="white">
                    {selectTitle(selector, formik)}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

const selectTitle = (name, formik) => {
    if (name === 'kasa') return prepareNameForKasa(formik);
    if (name === 'uKasa') return prepareNameForUKasa(formik);
    if (name === 'pervaz') return prepareNameForPervaz(formik);
    if (name === 'uPervaz') return prepareNameForUPervaz(formik);
    if (name === 'kanat') return prepareNameForKanat(formik);
};

// const prepareNameForKasa = (formik) => {
// 	const body = `${formik.values.soveMi ? 'söve' : ''} ${formik.values.yapi} ${
// 		formik.values.cins
// 	} ${formik.values.cinsAciklama} ${formik.values.kalinlik}x${
// 		formik.values.en
// 	}x${formik.values.boy}cm
//     lamba(${formik.values.lambaDerinlik}x${
// 		formik.values.lambaGenislik
// 	}) pervaz kanalı(${formik.values.pervazKanalGenisligi}x${
// 		formik.values.pervazKanalDerinligi
// 	}) Fitil(${formik.values.fitil}) Profil(${formik.values.ozelProfilTipi} ${
// 		formik.values.ozelProfilAciklama
// 	})
//     ${formik.values.dumanFitili ? '+Duman Fitili' : ''} ${
// 		formik.values.kesim45 ? '+45 Kesim' : ''
// 	} ${formik.values.kilitKarsiligi ? '+Kilit Karşılığı' : ''} ${
// 		formik.values.menteseKarsiligi ? '+Menteşe Karşılığı' : ''
// 	} ${formik.values.hidrolikKarsiligi ? '+Hidrolik Karşılığı' : ''} ${
// 		formik.values.ekNot ? '*' + formik.values.ekNot : ''
// 	}
//     `;
// 	return `KASA ${body.toLocaleLowerCase()}
//     `;
// };
// Dikkat bu kısmın bir kopyası da door templates altında var. Bir şey değişirse burası da değişmeli
const prepareNameForKasa = (formik) => {
    const body =
        'Kasa ' +
        formik.values.kalinlik +
        'x' +
        formik.values.en +
        'x' +
        formik.values.boy +
        ' ' +
        formik.values.yapi +
        ' ' +
        formik.values.cins +
        ' ' +
        formik.values.cinsAciklama +
        ' ' +
        formik.values.koseliBombeli +
        ' ' +
        (formik.values.soveMi ? 'Söve ' : '') +
        (formik.values.soveMi
            ? ''
            : 'Lamba(' + formik.values.lambaGenislik + 'x' + formik.values.lambaDerinlik + ') ') +
        (formik.values.fitil == 'L' ? '' : 'Fitil(' + formik.values.fitil + ') ') +
        (formik.values.pervazKanalGenisligi !== 0.5 || formik.values.pervazKanalDerinligi !== 3
            ? 'Pervaz Kanalı(' + formik.values.pervazKanalGenisligi + 'x' + formik.values.pervazKanalDerinligi + ') '
            : '') +
        //+ (formik.values.girinti)
        (formik.values.ozelProfilTipi == 'Standart' ? '' : formik.values.ozelProfilTipi + ' ') +
        (formik.values.ozelProfilAciklama + ' ') +
        (formik.values.dumanFitili ? '+Duman Fitili ' : '') +
        (formik.values.kesim45 ? '+45 Kesim ' : '') +
        (formik.values.kilitKarsiligi ? '+Kilit Karşılığı ' : '') +
        (formik.values.menteseKarsiligi ? '+Menteşe Karşılığı ' : '') +
        (formik.values.hidrolikKarsiligi ? '+Hidrolik Karşılığı ' : '') +
        (formik.values.ekNot ? '*' + formik.values.ekNot : '');

    return body;
};
const prepareNameForUKasa = (formik) => {
    const body = `${formik.values.soveMi ? 'Söve' : ''} ${formik.values.yapi} ${formik.values.cins} ${
        formik.values.cinsAciklama
    } (${formik.values.kalinlik}x${formik.values.en}x${formik.values.boy}cm)
    lamba(${formik.values.lambaDerinlik}x${formik.values.lambaGenislik}) pervaz kanalı(${
        formik.values.pervazKanalGenisligi
    }x${formik.values.pervazKanalDerinligi})
     Fitil:${formik.values.fitil} Profil(${formik.values.ozelProfilTipi} ${formik.values.ozelProfilAciklama}) 
    ${formik.values.dumanFitili ? '+Duman Fitili' : ''} ${formik.values.kesim45 ? '+45 Kesim' : ''} ${
        formik.values.kilitKarsiligi ? '+Kilit Karşılığı' : ''
    } ${formik.values.menteseKarsiligi ? '+Menteşe Karşılığı' : ''} ${
        formik.values.hidrolikKarsiligi ? '+Hidrolik Karşılığı' : ''
    } ${formik.values.ekNot ? '*' + formik.values.ekNot : ''}`;
    return `U KASA ${body.toLocaleLowerCase()}`;
};
const prepareNameForPervaz = (formik) => {
    const gecmeIsimlendirme =
        formik.values.yapi == 'Düz Pervaz'
            ? ''
            : `Geçme(${formik.values.gecmeKalinlik}x${formik.values.gecmeIctenUzunluk})`;

    const body = `${formik.values.cins} ${formik.values.cinsAciklama} ${formik.values.koseliBombeli} ${
        formik.values.yapi
    } (${formik.values.kalinlik}x${formik.values.en}x${formik.values.boy}cm) ${gecmeIsimlendirme} Profil(${
        formik.values.ozelProfilTipi
    } ${formik.values.ozelProfilAciklama}) 
   ${formik.values.hamZimpara ? '+Ham Zımpara' : ''} ${formik.values.boyKesmeKertme ? '+Boy Kesme ve Kertme' : ''} ${
        formik.values.ekNot ? '*' + formik.values.ekNot : ''
    }
    `;
    return `PERVAZ ${body.toLocaleLowerCase()}`;
};
const prepareNameForUPervaz = (formik) => {
    const body = `${formik.values.mdfKalinlik}cm ${formik.values.yapi} ${formik.values.cins} ${
        formik.values.cinsAciklama
    } (${formik.values.kalinlik}x${formik.values.en}x${formik.values.boy}cm)
    Geçme Kalınlığı:${formik.values.gecmeKalinlik}cm Geçme İçten Uzunluğu:${formik.values.gecmeIctenUzunluk}cm 
    Profil:${formik.values.ozelProfilTipi} ${formik.values.ozelProfilAciklama} 
   ${formik.values.hamZimpara ? '+Ham Zımpara' : ''} ${formik.values.boyKesmeKertme ? '+Boy Kesme ve Kertme' : ''} ${
        formik.values.ekNot ? '*' + formik.values.ekNot : ''
    }
    `;
    return `U PERVAZ ${body.toLocaleLowerCase()}`;
};
const prepareNameForKanat = (formik) => {
    const derzIsimlendirme =
        formik.values.derzSekli == 'Yok'
            ? 'Derz Yok'
            : `DERZ(${formik.values.derzAdedi} adet ${formik.values.derzSekli} ${
                  parseInt(formik.values.derzBicakAdedi, 10) > 1 ? formik.values.derzBicakAdedi + ' farklı bıçak' : ''
              })`;
    const cumbaIsimlendirme =
        formik.values.cumbaBandi == 'Yok'
            ? 'Cumba Yok'
            : `Cumba(Bantlama:${formik.values.cumbaBandi}, Tipi:${formik.values.cumbaTipi}, Bant Kalınlığı:${formik.values.cumbaKalinligi}`;

    const dolguIsimlendirme = `${formik.values.dolguTipi == 'Standart' ? '' : formik.values.dolguTipi + ' dolgulu'}`;

    const body = `${dolguIsimlendirme} ${formik.values.cins} ${formik.values.cinsAciklama}(${formik.values.kalinlik}x${
        formik.values.en
    }x${formik.values.boy}cm)
 ${derzIsimlendirme} 
 ${cumbaIsimlendirme}
 Cam Yeri ${formik.values.camYeri} Kilit Yeri:${formik.values.kilitYeri} Menteşe Yeri:${formik.values.menteseYeri}
   ${formik.values.hidrolik ? '+Hidrolik Yeri' : ''} ${formik.values.giyotin ? '+Giyotin Kesim' : ''}${
        formik.values.durbun ? '+Dürbün Yeri' : ''
    } ${formik.values.sertAgacDonme ? '+Sert Ağaç Dönme' : ''} ${formik.values.ekNot ? '*' + formik.values.ekNot : ''}
    `;
    return `KANAT ${body.toLocaleLowerCase()}`;
};
