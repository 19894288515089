import React from 'react';
import Grid from '@mui/material/Grid';
import FormSwitch from 'layouts/projects/Forms/FormComponents/FormSwitch';
import OutlinedFormTextField from 'layouts/projects/Forms/FormComponents/OutlinedFormTextField';
import FormSelect from 'layouts/projects/Forms/FormComponents/FormSelect';
import { validationSchemaKanatSheetTemplate } from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/YupValidationSchemaSheets.js';
import { kanatToSheet, sheetToKanat } from '@samilkahraman/demand-namer';
import { BaseForm } from '../BaseForm';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

export const KanatForm = (props) => {
    const allowedChanges = [
        'miktar',
        'en',
        'boy',
        'cumbaBandi',
        'menteseYeri',
        'camYeri',
        'kilitYeri',
        'hidrolik',
        'giyotin',
        'durbun',
        'sertAgacDonme',
    ];

    const renderFields = (formik, classes) => (
        <>
            <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={12} sm={12} md={3}>
                    <OutlinedFormTextField
                        itemID="miktar"
                        label="Miktar (Adet)"
                        placeHolder="Örn: 1000"
                        type="number"
                        formik={formik}
                        step="1"
                        adornment="AD"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <OutlinedFormTextField
                        itemID="en"
                        label="En"
                        placeHolder="Örn: 82"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <OutlinedFormTextField
                        itemID="boy"
                        label="Boy"
                        placeHolder="Örn: 209"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <FormSelect
                        itemID="cumbaBandi"
                        formik={formik}
                        inputName="Cumba Bandı"
                        items={[
                            { name: 'Yok', value: 'Yok' },
                            { name: '2 Kenar', value: '2 Kenar' },
                            { name: '4 Kenar', value: '4 Kenar' },
                        ]}
                        helperText={'Kanat Cumba Bandı'}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                    <FormSelect
                        itemID="menteseYeri"
                        formik={formik}
                        inputName="Menteşe Yeri"
                        items={[
                            { name: 'Yok', value: 'Yok' },
                            { name: 'Yönlü 2', value: 'Yönlü 2' },
                            { name: 'Yönlü 3', value: 'Yönlü 3' },
                            { name: 'Gömme 2', value: 'Gömme 2' },
                            { name: 'Gömme 3', value: 'Gömme 3' },
                        ]}
                        helperText={'Menteşe Yeri'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormSelect
                        itemID="camYeri"
                        formik={formik}
                        inputName="Cam Yeri"
                        items={[
                            { name: 'Yok', value: 'Yok' },
                            { name: 'Standart', value: 'Standart' },
                            { name: 'Giyotin', value: 'Giyotin' },
                        ]}
                        helperText={'Cam Yeri'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormSelect
                        itemID="kilitYeri"
                        formik={formik}
                        inputName="Kilit Yeri"
                        items={[
                            { name: 'Yok', value: 'Yok' },
                            { name: 'Standart Kale', value: 'Standart Kale' },
                            { name: 'Topuz', value: 'Topuz' },
                            { name: 'Otel Tipi', value: 'Otel Tipi' },
                        ]}
                        helperText={'Kilit Yeri'}
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.switch}>
                <FormSwitch formik={formik} name="hidrolik" color="secondary" label="Hidrolik Yeri" />
                <FormSwitch formik={formik} name="giyotin" color="secondary" label="Giyotin Kesim" />
                <FormSwitch formik={formik} name="durbun" color="secondary" label="Dürbün Yeri" />
                <FormSwitch formik={formik} name="sertAgacDonme" color="secondary" label="Sert Ağaç Dönülmesi" />
            </Grid>
        </>
    );

    return (
        <BaseForm
            {...props}
            validationSchema={validationSchemaKanatSheetTemplate}
            allowedChanges={allowedChanges}
            toSheet={kanatToSheet}
            fromSheet={sheetToKanat}
            renderFields={renderFields}
        />
    );
};

export const KanatBadges = (props) => {
    const { initial } = props;
    const values = sheetToKanat(initial);
    const toBadge = (degisken, aciklama, renk = 'dark') => {
        return (
            <MDBox>
                <MDBadge
                    m={1}
                    badgeContent={`${aciklama}: ${values[degisken] !== undefined ? values[degisken] : '-'}`}
                    size="xs"
                    container
                    variant="gradient"
                    color={renk}
                />
            </MDBox>
        );
    };
    return (
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12}>
                <MDBox display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                    {toBadge('kalinlik', 'KALINLIK: ', 'secondary')}
                    {toBadge('derzSekli', 'DERZ ŞEKLİ: ', 'secondary')}
                    {toBadge('derzTipi', 'DERZ TİPİ: ', 'secondary')}
                    {toBadge('derzAdedi', 'DERZ ADEDİ: ', 'secondary')}
                    {toBadge('derzBicakAdedi', 'DERZ BIÇAK ADEDİ: ', 'secondary')}
                    {toBadge('maxDerzDerinligi', 'Max Derz Derinligi (CM): ', 'secondary')}
                    {toBadge('cumbaTipi', 'Cumba TİPİ: ', 'secondary')}
                    {toBadge('cumbaKalinligi', 'Cumba Kalınlığı (MM): ', 'secondary')}
                    {toBadge('cins', 'CİNS: ', 'secondary')}
                    {toBadge('cinsAciklama', 'CİNS Açıklaması: ', 'secondary')}
                    {toBadge('dolguTipi', 'Dolgu TİPİ: ', 'secondary')}
                    {toBadge('mdfYuzeyKalinlik', 'MDF Yüzey Kalınlığı (CM): ', 'secondary')}
                    {toBadge('ekNot', 'NOTLAR: ', 'dark')}
                </MDBox>
            </Grid>
        </Grid>
    );
};
