import { useState } from 'react';
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import AaroPriceCheck from 'components/Aaro/PriceCheck';

import pxToRem from 'assets/theme/functions/pxToRem';
function CustomerCell({ image, name, color, data }) {
	const [open, setOpen] = useState(false);

	return (
		<MDBox
			display="flex"
			alignItems="center"
			sx={{ width: pxToRem(200) }}
			onClick={() => setOpen(true)}
		>
			<MDTypography variant="caption" fontWeight="medium" color="text">
				{name}
			</MDTypography>
			<AaroPriceCheck open={open} setOpen={setOpen} item={data} />
		</MDBox>
	);
}

// Setting default value for the props of CustomerCell
CustomerCell.defaultProps = {
	image: '',
	color: 'dark',
};

// Typechecking props for the CustomerCell
CustomerCell.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	color: PropTypes.oneOf([
		'transparent',
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'light',
		'dark',
	]),
};

export default CustomerCell;
