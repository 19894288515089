import { useEffect, memo } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';

// import MDButton from 'components/MDButton';

// Images
// import logo from 'assets/images/projects/kahramanlar.png';
import MDBox from 'components/MDBox';
// import Icon from '@mui/material/Icon';
// Components
import ViewToggle from 'layouts/todo/NamedLayout/Header/Components/ViewToggle.js';
// import Search from 'layouts/todo/layout/Header/Components/Search.js';
// import ViewSwitch from 'layouts/todo/layout/Header/Components/ViewSwitch.js';
// import { ColorButton } from 'layouts/todo/layout/Header/Components/ColorButton.js';
import useDialogHook from 'hooks/useDialogHook';
import AddLead from 'layouts/lead/forms/AddLead.js';
import AddAaroPotential from 'components/Aaro/customer/Forms/AddCustomer.js';
const Header = memo(function HeaderPre({ children }) {
    const { open, handleClickOpen, handleClose } = useDialogHook();
    const {
        open: openAaroPotentialAdd,
        handleClickOpen: handleClickOpenAaroPotentialAdd,
        handleClose: handleCloseAaroPotentialAdd,
    } = useDialogHook();
    useEffect(() => console.log('Header Rendered'));

    return (
        <MDBox position="relative" mb={5}>
            {HeaderGradiant()}
            <Card
                sx={{
                    position: 'relative',
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    {/* <Grid item>
                        <MDAvatar src={logo} alt="profile-image" size="xl" shadow="sm" />
                    </Grid> */}
                    <Grid item>
                        <Box height="100%" mt={0.5} lineHeight={1}>
                            <Typography variant="h5" fontWeight="medium" mb={1}>
                                Yapılacaklar Listesi
                            </Typography>
                            <Stack spacing={1} direction="row">
                                <ViewToggle />
                                {/* <ColorButton
                                    size="small"
                                    precolor="#017737"
                                    hovercolor="#119143"
                                    onClick={handleClickOpen}
                                >
                                    +&nbsp;Ekle
                                </ColorButton> */}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ ml: 'auto' }}>
                        <MDBox display="flex">
                            {/* <Search /> */}
                            {/* <MDBox mt={1.5}>
                                <MDButton
                                    variant="gradient"
                                    color="dark"
                                    iconOnly
                                    onClick={handleClickOpenAaroPotentialAdd}
                                >
                                    <Icon>add</Icon>
                                </MDButton>
                            </MDBox> */}
                            {/* <MDBox mt={1.5}>
                                <ViewSwitch />
                            </MDBox> */}
                        </MDBox>
                    </Grid>
                </Grid>
                {children}
            </Card>
            {open && <AddLead open={open} handleClose={handleClose} />}
            {openAaroPotentialAdd && (
                <AddAaroPotential open={openAaroPotentialAdd} handleClose={handleCloseAaroPotentialAdd} />
            )}
        </MDBox>
    );

    function HeaderGradiant() {
        return (
            <MDBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="8rem"
                borderRadius="xl"
                sx={{
                    background: 'linear-gradient(to right, #42424a, #191919)',
                    backgroundSize: 'cover',
                    backgroundPosition: '50%',
                    overflow: 'hidden',
                }}
            />
        );
    }
});

// Setting default props for the Header
Header.defaultProps = {
    children: '',
};

// Typechecking props for the Header
Header.propTypes = {
    children: PropTypes.node,
};

export default Header;
