import * as React from 'react';
import { useQueryClient } from 'react-query';
import makeStyles from '@mui/styles/makeStyles';

import Popover from '@mui/material/Popover';
import MDBox from 'components/MDBox';
import Icon from '@mui/material/Icon';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBadge from 'components/MDBadge';
import { getStockPrice } from 'services/outside/aaro/priceList';
import { todayIsBetween } from 'helpers/dateHelpers';
import { formatPrice, calculateDiscount } from 'helpers/priceHelpers';
import Modal from '@mui/material/Modal';

import DetailedStockView from 'layouts/aaro/fastPrice/views/detailedStockView';
const fetchAaroStockPrice = async (stokID) => {
    const response = await getStockPrice(stokID).then((rp) => rp.data);
    return response;
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '60%',
        maxWidth: 1000,
        maxHeight: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #fff',
        borderRadius: '25px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll',
    },
    '@media (max-device-width: 480px)': {
        paper: {
            position: 'absolute',
            width: '90%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #fff',
            borderRadius: '25px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflowY: 'scroll',
        },
    },
}));
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function MouseOverPopover({ stockID, stockName, stockCode, queryID, objectID }) {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [openModal, setOpenModal] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const rerender = React.useState(0)[1];

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <MDBox
            onMouseEnter={async () => {
                await queryClient.prefetchQuery(['fetchAaroStockPrice', stockID], () => fetchAaroStockPrice(stockID), {
                    staleTime: 60 * 1000, // only prefetch if older than 10 seconds
                });

                setTimeout(() => {
                    rerender({});
                }, 1);
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <MDButton
                size="medium"
                variant="gradient"
                color="info"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                iconOnly
                onClick={() => setOpenModal(!openModal)}
            >
                <Icon>currency_lira</Icon>
            </MDButton>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <PriceView data={queryClient.getQueryData(['fetchAaroStockPrice', stockID])} />
            </Popover>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <DetailedStockView
                        stockID={stockID}
                        activeStockName={stockID}
                        activeStockCode={stockID}
                        setOpen={setOpenModal}
                        stockName={stockName}
                        stockCode={stockCode}
                        queryID={queryID}
                        objectID={objectID}
                        // setUpdate={setOpenModal}
                        // update={update}
                    />
                </div>
            </Modal>
        </MDBox>
    );
}

const PriceView = (props) => {
    const { data } = props;
    const priceDetails = data?.Model ? data.Model[0] : {};
    const isValid = todayIsBetween(new Date(priceDetails?.TarihBas), new Date(priceDetails?.TarihBit));
    return (
        <MDBox
            color="white"
            bgColor={isValid ? 'success' : 'primary'}
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
        >
            {isValid ? (
                <>
                    <MDBox display="flex" justifyContent="center" p={1}>
                        <MDTypography variant="h5" color="white">
                            {priceDetails.Fiyat && formatPrice(priceDetails.Fiyat, priceDetails.DovizKodu)}
                        </MDTypography>
                        <MDTypography variant="h6" fontWeight="light" color="white">
                            &nbsp;&nbsp;&nbsp;
                            {priceDetails.Fiyat && '/' + priceDetails.BirimKodu + ' + K.D.V.'}
                        </MDTypography>
                    </MDBox>
                    <MDBadge
                        variant="gradient"
                        color="info"
                        badgeContent={`%10 - ${formatPrice(
                            calculateDiscount(priceDetails.Fiyat, 1, 10),
                            priceDetails.DovizKodu
                        )}`}
                        container
                    />
                    &nbsp;
                    <MDBadge
                        variant="gradient"
                        color="warning"
                        badgeContent={`%15 - ${formatPrice(
                            calculateDiscount(priceDetails.Fiyat, 1, 15),
                            priceDetails.DovizKodu
                        )}`}
                        container
                    />
                    &nbsp;
                    <MDBadge
                        variant="gradient"
                        color="primary"
                        badgeContent={`%22 - ${formatPrice(
                            calculateDiscount(priceDetails.Fiyat, 1, 22),
                            priceDetails.DovizKodu
                        )}`}
                        container
                    />{' '}
                </>
            ) : (
                <MDTypography variant="h5" color="white">
                    Fiyat Aranıyor...
                </MDTypography>
            )}
        </MDBox>
    );
};
