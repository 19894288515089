

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { demandCalculator } from 'helpers/projectHelpers';
import { formatPrice } from 'helpers/priceHelpers';

function OrderSummary({ demand }) {
	const { pureTotal, pureDiscount, finalTotal, totalDiscountPercentage } =
		demandCalculator(demand);
	return (
		<>
			<MDBox mb={2}>
				<MDTypography variant="h6" fontWeight="medium">
					Teklif Özeti
				</MDTypography>
			</MDBox>
			<MDBox display="flex" justifyContent="space-between" mb={0.5}>
				<MDTypography
					variant="button"
					fontWeight="regular"
					color="text"
				>
					Kalemler:
				</MDTypography>
				<MDBox ml={1}>
					<MDTypography variant="body2" fontWeight="medium">
						{formatPrice(
							pureTotal,
							demand.demandCurrency
								? demand.demandCurrency
								: 'TRY'
						)}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox display="flex" justifyContent="space-between" mb={0.5}>
				<MDTypography
					variant="button"
					fontWeight="regular"
					color="text"
				>
					İskonto:
				</MDTypography>
				<MDBox ml={1}>
					<MDTypography
						variant="body2"
						fontWeight="medium"
						color={pureDiscount > 0 ? 'primary' : 'secondary'}
					>
						{pureDiscount > 0 ? '-' : '+'}
						{formatPrice(
							pureDiscount,
							demand.demandCurrency
								? demand.demandCurrency
								: 'TRY'
						)}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox display="flex" justifyContent="space-between" mb={0.5}>
				<MDTypography
					variant="button"
					fontWeight="regular"
					color="text"
				>
					İskonto Oranı:
				</MDTypography>
				<MDBox ml={1}>
					<MDTypography variant="body2" fontWeight="medium">
						%{totalDiscountPercentage}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox display="flex" justifyContent="space-between" mt={3}>
				<MDTypography variant="body1" fontWeight="light" color="text">
					Toplam:
				</MDTypography>
				<MDBox ml={1}>
					<MDTypography
						variant="body1"
						fontWeight="medium"
						sx={{ textDecoration: 'underline' }}
					>
						{formatPrice(
							finalTotal,
							demand.demandCurrency
								? demand.demandCurrency
								: 'TRY'
						)}
					</MDTypography>
				</MDBox>
			</MDBox>
		</>
	);
}

export default OrderSummary;
