import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MDInput from 'components/MDInput';

export default function RefinementList({
	items,
	refine,
	createURL,
	searchForItems,
	searchable,
}) {
	return (
		<>
			{searchable && (
				<MDInput
					variant="standard"
					size="small"
					type="search"
					onChange={(event) =>
						searchForItems(event.currentTarget.value)
					}
					placeholder="Ara..."
				/>
			)}

			<FormGroup>
				{items.map((item) => (
					<a
						href={createURL(item.value)}
						style={{ fontWeight: item.isRefined ? 'bold' : '' }}
						onClick={(event) => {
							event.preventDefault();
							refine(item.value);
						}}
					>
						<FormControlLabel
							key={item.label}
							control={<Checkbox checked={item.isRefined} />}
							label={`${item.label} (${item.count})`}
							onClick={(event) => {
								event.preventDefault();
								refine(item.value);
							}}
						/>
					</a>
				))}
			</FormGroup>
		</>
	);
}

// const RefinementList = ({ items }) => (
// 	<ul>
// 		{items.map((item) => (
// 			<li key={item.label}>
// 				<a
// 					href="#"
// 					style={{ fontWeight: item.isRefined ? 'bold' : '' }}
// 				>
// 					{item.label} ({item.count})
// 				</a>
// 			</li>
// 		))}
// 	</ul>
// );
