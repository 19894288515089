import React from 'react';
import Grid from '@mui/material/Grid';
import FormSwitch from 'layouts/projects/Forms/FormComponents/FormSwitch';
import OutlinedFormTextField from 'layouts/projects/Forms/FormComponents/OutlinedFormTextField';
import { validationSchemaPervazSheetTemplate } from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/YupValidationSchemaSheets.js';
import { pervazToSheet, sheetToPervaz } from '@samilkahraman/demand-namer';
import { BaseForm } from '../BaseForm';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

export const PervazForm = (props) => {
    const allowedChanges = [
        'miktar',
        'kalinlik',
        'en',
        'boy',
        'gecmeKalinlik',
        'gecmeIctenUzunluk',
        'hamZimpara',
        'boyKesmeKertme',
    ];

    const renderFields = (formik, classes) => (
        <>
            <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <OutlinedFormTextField
                        itemID="miktar"
                        label="Miktar (Adet)"
                        placeHolder="Örn: 1000"
                        type="number"
                        formik={formik}
                        step="1"
                        adornment="AD"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <OutlinedFormTextField
                        itemID="kalinlik"
                        label="Kalınlık"
                        placeHolder="Örn: 1.8"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <OutlinedFormTextField
                        itemID="en"
                        label="En"
                        placeHolder="Örn: 10"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <OutlinedFormTextField
                        itemID="boy"
                        label="Boy"
                        placeHolder="Örn: 220"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <OutlinedFormTextField
                        itemID="gecmeKalinlik"
                        label="Geçme Kalınlık"
                        placeHolder="Örn: 0.4"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <OutlinedFormTextField
                        itemID="gecmeIctenUzunluk"
                        label="Geçme İçten Uzunluk"
                        placeHolder="Örn: 1.5"
                        type="number"
                        formik={formik}
                        step="0.1"
                        adornment="CM"
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.switch}>
                <FormSwitch formik={formik} name="hamZimpara" color="secondary" label="Ham Zımpara" />
                <FormSwitch formik={formik} name="boyKesmeKertme" color="secondary" label="Boy Kesme Kertme" />
            </Grid>
        </>
    );

    return (
        <BaseForm
            {...props}
            validationSchema={validationSchemaPervazSheetTemplate}
            allowedChanges={allowedChanges}
            toSheet={pervazToSheet}
            fromSheet={sheetToPervaz}
            renderFields={renderFields}
        />
    );
};

export const PervazBadges = (props) => {
    const { initial } = props;
    const values = sheetToPervaz(initial);
    const toBadge = (degisken, aciklama, renk = 'dark') => {
        return (
            <MDBox>
                <MDBadge
                    m={1}
                    badgeContent={`${aciklama}: ${values[degisken] !== undefined ? values[degisken] : '-'}`}
                    size="xs"
                    container
                    variant="gradient"
                    color={renk}
                />
            </MDBox>
        );
    };
    return (
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12}>
                <MDBox display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                    {toBadge('kalinlik', 'Kalınlık', 'secondary')}
                    {toBadge('cins', 'Cins', 'secondary')}
                    {toBadge('cinsAciklama', 'Cins Açıklama', 'secondary')}
                    {toBadge('yapi', 'Yapı', 'secondary')}
                    {toBadge('gecmeKalinlik', 'Geçme Kalınlık', 'secondary')}
                    {toBadge('gecmeIctenUzunluk', 'Geçme İçten Uzunluk', 'secondary')}
                    {toBadge('hamZimpara', 'Ham Zımpara', 'secondary')}
                    {toBadge('boyKesmeKertme', 'Boy Kesme Kertme', 'secondary')}
                    {toBadge('ozelProfilTipi', 'Özel Profil Tipi', 'secondary')}
                    {toBadge('ozelProfilAciklama', 'Özel Profil Açıklama', 'secondary')}
                    {toBadge('ekNot', 'NOTLAR: ', 'dark')}
                </MDBox>
            </Grid>
        </Grid>
    );
};
