import React, { useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { config } from 'Constants';
import { constants, matchProducts } from 'layouts/projects/Forms/Comparision/ComparisionDecider.js';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { useQuery } from 'react-query';

import { getActiveDemand } from 'services/api/project';

const fetchActiveDemands = async () => {
    const response = await getActiveDemand()
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

export default function Comparision(props) {
    const { initial: baslangic, activeItemType, open, handleClose, projectName, projectID } = props;
    const [numberOfComparision, setNumberOfComparision] = useState(7);

    const {
        data: activeDemandsData,
        isLoading,
        error,
    } = useQuery(['fetchActiveDemands'], () => fetchActiveDemands(), {
        staleTime: 600000,
    });
    if (isLoading) return 'Yükleniyor...';
    if (error) return 'Bir hata oluştu: ' + error.message;

    const initial = {};
    for (const [key, value] of Object.entries(baslangic)) {
        initial[key] = typeof value === 'boolean' ? (value ? 'evet' : 'hayır') : value;
    }

    const allItems = activeDemandsData
        ?.map((el) => matchProducts(el))
        .filter((obj) => obj !== undefined && obj[activeItemType] !== undefined)
        ?.flatMap((obj) =>
            obj[activeItemType]?.map((item) => ({
                relatedProjectID: obj?.relatedProjects[0]?._id,
                relatedProjectName: obj?.relatedProjects[0]?.projectName,
                activeSheetURL: item?.match
                    ? `https://docs.google.com/spreadsheets/d/${obj?.activeSheet?.spreadsheetId}/edit#gid=${item?.match?.sheetId}`
                    : null,
                folderName: obj?.activeSheet?.folderName,
                ...item,
            }))
        )
        .filter((el) => el?.relatedProjectID !== projectID)
        ?.map((item) => {
            const newObj = {};
            for (const [key, value] of Object.entries(item)) {
                newObj[key] = typeof value === 'boolean' ? (value ? 'evet' : 'hayır') : value;
            }
            return newObj;
        });

    const { desiredKeys, comparisionKeys } = constants(activeItemType);

    const calculateSimilarityPercentage = (initial, item) => {
        let totalWeight = 0;
        let weightedSimilarity = 0;

        for (const key in comparisionKeys) {
            if (comparisionKeys.hasOwnProperty(key)) {
                const weight = comparisionKeys[key];
                totalWeight += weight;

                if (initial[key] === item[key]) {
                    weightedSimilarity += weight;
                }
            }
        }

        return ((weightedSimilarity / totalWeight) * 100).toFixed(2);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('tr-TR');
    };

    const renderCellValue = (params) => {
        if (params.row.properties === 'relatedProjectID') {
            return (
                <MDButton variant="gradient" color="dark" onClick={() => handleClick(params.value)} size="small">
                    Proje
                </MDButton>
            );
        } else if (params.row.properties === 'changedDate') {
            return (
                <MDTypography variant="caption" fontWeight="bold">
                    {formatDate(params.value)}
                </MDTypography>
            );
        } else if (params.row.properties === 'verilenFiyat') {
            return <strong>{`${params.value} ₺`}</strong>;
        } else if (params.row.properties === 'activeSheetURL' && params.value) {
            return (
                <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => handleClickGoogleSheet(params.value)}
                    size="small"
                >
                    Sheet
                </MDButton>
            );
        }
        return params.value;
    };

    const sortedActiveItems = [...allItems]
        .sort((a, b) => {
            return calculateSimilarityPercentage(initial, b) - calculateSimilarityPercentage(initial, a);
        })
        .filter((item) => item?._id !== initial?._id)
        ?.slice(0, numberOfComparision);

    const cellClassName = (params) => {
        if (params.row.properties === 'Benzerlik Oranı') {
            const percentage = parseFloat(params.value);
            if (percentage > 80) {
                return 'similarity-green';
            } else if (percentage > 50) {
                return 'similarity-orange';
            }
        }

        return '';
    };

    const handleClick = (relatedProjectID) => {
        window.open(`${config.url.ENV_URL}/projects/${relatedProjectID}`, '_blank');
    };

    const handleClickGoogleSheet = (url) => {
        window.open(url, '_blank');
    };

    const dataGridColumns = [
        { field: 'properties', headerName: 'Özellikler', flex: 1 },
        {
            field: 'initial',
            headerName: projectName,
            flex: 1,
            cellClassName: cellClassName,
            renderCell: renderCellValue,
        },
        ...sortedActiveItems.map((_, index) => ({
            field: `active_${index}`,
            headerName: `${_.relatedProjectName}`,
            flex: 1,

            cellClassName: cellClassName,
            renderCell: renderCellValue,
        })),
    ];
    const dataGridRows = [
        ...desiredKeys.map((key, index) => ({
            id: index + 1,
            properties: key,
            initial: initial[key],
            ...sortedActiveItems.reduce(
                (acc, item, itemIndex) => ({
                    ...acc,
                    [`active_${itemIndex}`]: item[key],
                }),
                {}
            ),
        })),
        {
            id: desiredKeys.length + 1,
            properties: 'Benzerlik Oranı',
            initial: '',
            ...sortedActiveItems.reduce(
                (acc, item, itemIndex) => ({
                    ...acc,
                    [`active_${itemIndex}`]: `${calculateSimilarityPercentage(initial, item)}%`,
                }),
                {}
            ),
        },
    ];

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <div style={{ height: '800px' }}>
                <style>
                    {`
          .similarity-green {
            background-color: lightgreen;
          }
          .similarity-orange {
            background-color: orange;
          }
       
        `}
                </style>
                <DataGridPro
                    // localeText={trTR.props.MuiDataGrid.localeText}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    rowHeight={35}
                    autoHeight
                    rows={dataGridRows}
                    columns={dataGridColumns}
                    initialState={{
                        pinnedColumns: { left: ['properties', 'initial'] },
                    }}
                />
                <TextField
                    id="outlined-basic"
                    label="Proje Adedi"
                    variant="outlined"
                    value={numberOfComparision}
                    onChange={(e) => setNumberOfComparision(e.target.value)}
                />
            </div>
        </Dialog>
    );
}
