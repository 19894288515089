import React, { useState, useEffect } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { getParameters } from 'services/api/general/parameter';
import { useQuery } from 'react-query';
import MDBox from 'components/MDBox';
import MDBadgeDot from 'components/MDBadgeDot';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Icon from '@mui/material/Icon';
import AaroLoadingScreen from 'components/Loading';
import Avatar from '@mui/material/Avatar';
import pxToRem from 'assets/theme/functions/pxToRem.js';
import { getTags } from 'services/api/general/tag';
import {
	updateParameter,
	deleteParameter,
} from 'services/api/general/parameter';
import { config } from 'Constants';
import { notify } from 'helpers/notificationHelper';
import Dialog from '@mui/material/Dialog';
import AddParameter from 'layouts/pages/settings/parameters/AddParameter.js';

const fetchTags = async (id) => {
	const response = await getTags(id).then((rp) =>
		rp.data?.result?.tags ? rp.data?.result?.tags : []
	);
	return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.PARAMS_CATEGORY_GROUP_ID;

const fetchParameters = async (id) => {
	const response = await getParameters(``).then((rp) =>
		rp.data?.result ? rp.data?.result : []
	);
	return response;
};
const conditionalColoring = (name) => {
	if (name === 'kasa') return 'info';
	if (name === 'uKasa') return 'secondary';
	if (name === 'pervaz') return 'dark';
	if (name === 'uPervaz') return 'light';
	if (name === 'kanat') return 'success';
	return 'error';
};
export default function Parameters(props) {
	const [openDialog, setOpenDialog] = useState(false);

	const { data, isLoading, error, refetch } = useQuery(
		['fetchParamaters'],
		() => fetchParameters(),
		{ staleTime: 600000 }
	);
	const tags = useQuery(
		['fetchTagGroups', TAG_GROUP_ID_DEMAND],
		() => fetchTags(TAG_GROUP_ID_DEMAND),
		{ staleTime: 600000 }
	);

	const handleRowEditCommit = React.useCallback(
		(params) => {
			const id = params.id;
			const key = params.field;
			const value = params.value;
			let obj = {};
			console.log(id, key, value);
			obj[key] = value;
			if (key === 'tag') {
				const findTagID = tags?.data.find((tag) => tag.name === value);
				obj[key] = findTagID._id;
				updateCell(id, obj);
			} else {
				updateCell(id, obj);
			}
		},
		[tags.data]
	);

	// * Edit cells section
	const updateCell = React.useCallback((id, obj) => {
		updateParameter(id, obj)
			.then((rp) => {
				notify(rp.data.success, rp.data.message);
				// queryClient.invalidateQueries(['fetchDemand', demand._id]);
			})
			.catch((err) => {
				notify(false, err.message);
			});
	}, []);

	if (isLoading) return <AaroLoadingScreen />;
	if (tags.isLoading) return <AaroLoadingScreen />;
	if (error) return 'Bir Hata Oluştu: ' + error.message;
	if (tags.error) return 'Bir Hata Oluştu(ETİKETLER): ' + error.message;

	const columns = [
		{
			field: 'key',
			headerName: 'Key',
			flex: 1,
			editable: true,
		},
		{
			field: 'value',
			headerName: 'Value',
			flex: 1,
			editable: true,
		},
		{
			field: 'tag',
			headerName: 'Tag',
			flex: 0.5,
			type: 'singleSelect',
			valueOptions: tags?.data.map((el) => el.name),
			renderCell: (params) => (
				<MDButton
					variant="gradient"
					size="small"
					color={conditionalColoring(params.formattedValue)}
				>
					{params.formattedValue}
				</MDButton>
			),
			editable: true,
		},
		{
			field: 'editColumn',
			headerName: 'Edit',
			flex: 0.25,
			renderCell: (params) => (
				<MDButton
					variant="gradient"
					size="small"
					color="primary"
					onClick={() => {
						const result = window.confirm(
							'Silmek İstediğinize Emin Misiniz?'
						);
						if (result)
							deleteParameter(params.formattedValue)
								.then((rp) => {
									notify(rp.data.success, rp.data.message);
									refetch();
								})
								.catch((err) => {
									notify(false, err.message);
								});
					}}
				>
					SİL
				</MDButton>
			),
		},
	];
	const rows = data.map((el) => ({
		id: el._id,
		key: el.key,
		value: el.value,
		tag: el?.tag?.name,
		editColumn: el._id,
	}));

	return (
		<MDBox
			color="white"
			bgColor="white"
			variant="gradient"
			borderRadius="lg"
			shadow="lg"
			opacity={1}
			p={2}
		>
			<MDBox pb={3} display="flex" flexDirection="row-reverse">
				<MDButton
					variant="gradient"
					color="dark"
					iconOnly
					size="small"
					onClick={() => setOpenDialog(true)}
					// circular
				>
					<Icon>add</Icon>
					<Dialog
						onClose={() => setOpenDialog(false)}
						open={openDialog}
						fullWidth={true}
						maxWidth="sm"
					>
						{/*Burada  */}
						<AddParameter setOpenDialog={setOpenDialog} />
					</Dialog>
				</MDButton>
			</MDBox>
			<DataGridPro
				autoHeight
				autoPageSize
				// localeText={trTR.props.MuiDataGrid.localeText}
				components={{
					Toolbar: GridToolbar,
				}}
				onCellEditCommit={handleRowEditCommit}
				density="compact"
				// checkboxSelection
				disableSelectionOnClick
				rows={rows}
				columns={columns}
				sx={{
					fontSize: pxToRem(12),
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: 400,
					lineHeight: 1.25,
				}}
			/>
		</MDBox>
	);
}
