import React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import { getDefaultAaroColor } from 'helpers/globalColorHelpers';

export default function LoadingScreen() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '150px',
            }}
        >
            <ClockLoader color={getDefaultAaroColor()} size={50} />{' '}
        </div>
    );
}
