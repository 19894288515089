import React from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// CardBody Component
const CardBody = ({ name, description, estimatedCost }) => (
    <MDBox mt={0.5} mb={1} display="flex" flexDirection="column">
        <MDTypography
            variant="caption"
            fontWeight="bold"
            sx={{ lineHeight: '1.3', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
            {name}
        </MDTypography>
        <MDTypography
            variant="overline"
            fontWeight="light"
            textTransform="lowercase"
            sx={{ lineHeight: '1', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
            {description} | ({estimatedCost}₺)
        </MDTypography>
    </MDBox>
);

export default CardBody;
