import React, { useState } from 'react';
import MDEditor from 'components/MDEditor';
import MDBox from 'components/MDBox';
import { postNote } from 'services/api/general/note';
import { formatDateMinutes } from 'helpers/smallCodes';
import { notify } from 'helpers/notificationHelper';
import MDButton from 'components/MDButton';

export default function AddNote({ relatedID, refetch, title, setVisible }) {
    const [editorValue, setEditorValue] = useState('...');
    const user = JSON.parse(localStorage.getItem('user'));

    const saveDetails = () => {
        const data = {
            user: user?.userID,
            title: title ? title : formatDateMinutes(Date.now()) + ' - ' + user?.name,
            note: editorValue,
            relatedID,
        };
        postNote(data).then((rp) => {
            notify(rp.data.success, rp.data.message);
            setVisible(false);
            refetch();
        });
    };

    return (
        <MDBox>
            <MDEditor
                value={editorValue}
                onChange={setEditorValue}
                options={{
                    placeholder: 'Not ekleyin...',
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                            ['clean'],
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                        ],
                    },
                    theme: 'snow',
                }}
                style={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 300,
                    lineHeight: 1.5,
                    letterSpacing: '0.02857em',
                    color: '#7b809a',
                }}
            />
            <MDBox mt={2} display="flex" justifyContent="flex-end">
                <MDButton onClick={saveDetails} variant="gradient" color="info">
                    Kaydet
                </MDButton>
            </MDBox>
        </MDBox>
    );
}
