// prop-types is a library for typechecking of props
import { useState, memo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import TaskListItem from './ListItem';
import { notify } from 'helpers/notificationHelper';
import { updateTask } from 'services/api/general/task';

// ! bu order mevzusu iyileştrilebilir
const findPerfectOrder = (arr, position) => {
    // En altta completed task altına bir item yerleştirildiyse diye bir de random değer ekliyorum.

    // completed ve not completed arraylari ayırmak lazım
    const isCompleted = arr[position].completed;

    let filteredArray = arr.filter((el) => el.completed === isCompleted);
    const filteredArrayLength = filteredArray.length;
    let restArray = arr.filter((el) => el.completed !== isCompleted);

    // bunun sebebi artık eski pozisyon  array uzunluğundan fazla olabilir özellikle completed itemler için
    let newPosition = position % filteredArrayLength;

    // console.log(filteredArray, restArray);

    // Eger üst komşusu varsa (orta ve en alt)
    if (filteredArray[newPosition - 1]) {
        const changedItem = filteredArray[newPosition];
        const changedItemID = changedItem._id;
        const topNeighbor = filteredArray[newPosition - 1];
        const topNeighborOrder = topNeighbor.order;
        let newOrder = topNeighborOrder - 1000;

        // Eger alt komşusu da varsa (aradaysa)
        if (filteredArray[newPosition + 1]) {
            const bottomNeighbor = filteredArray[newPosition + 1];
            const bottomNeighborOrder = bottomNeighbor.order;
            newOrder = parseInt((topNeighborOrder + bottomNeighborOrder) / 2);
        }
        // yani en alt değerse

        filteredArray[newPosition].order = newOrder;
        updateTask(changedItemID, { order: newOrder })
            .then((rp) => notify(rp.data.success, rp.data.message))
            .catch((err) => notify(false, JSON.stringify(err)));
    }
    // üst komşusu yok ama alt komşusu varsa indexini bin azalt ve db güncelle (en üst eleman)
    else if (filteredArray[newPosition + 1]) {
        const changedItem = filteredArray[newPosition];
        const changedItemID = changedItem._id;
        const bottomNeighbor = filteredArray[newPosition + 1];
        const bottomNeighborOrder = bottomNeighbor.order;
        const newOrder = bottomNeighborOrder + 1000;

        filteredArray[newPosition].order = newOrder;
        updateTask(changedItemID, { order: newOrder })
            .then((rp) => notify(rp.data.success, rp.data.message))
            .catch((err) => notify(false, JSON.stringify(err)));
    }

    return [...filteredArray, ...restArray];
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // console.log(startIndex, endIndex, result);

    //üst komşusu varsa üst komşunun idsini bir artır

    const result2 = findPerfectOrder(result, endIndex);
    // resulti tekrar sort ettim, tamamlanan elemanların sona gelmesi için
    return result2.sort((a, b) => a.completed - b.completed);
};

function Todo({ task, index, styleOverride }) {
    return (
        <Draggable draggableId={task._id} index={index}>
            {(provided) => (
                <MDBox
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    index={index}
                    key={task._id}
                >
                    <TaskListItem item={task} styleOverride={styleOverride} key={task._id + 'TLI'} />
                </MDBox>
            )}
        </Draggable>
    );
}

const ListMemo = memo(function ListMemo({ tasks, styleOverride }) {
    return Array.isArray(tasks)
        ? tasks?.map((task, index) => <Todo task={task} index={index} key={task._id} styleOverride={styleOverride} />)
        : [];
});

function TaskList({ tasks, styleOverride }) {
    const [state, setState] = useState({ tasks: tasks });

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const tasks = reorder(state.tasks, result.source.index, result.destination.index);
        // console.log(tasks);

        setState({ tasks });
    }

    return (
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <ListMemo tasks={state.tasks} styleOverride={styleOverride} />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </MDBox>
    );
}

// Typechecking props for the TaskList
TaskList.propTypes = {
    title: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TaskList;
