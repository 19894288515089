import Dialog from '@mui/material/Dialog';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import AddReminder from 'components/General/Reminder/AddReminder.js';
import DisplayReminders from 'components/General/Reminder/DisplayReminders.js';

function ReminderTemplate(props) {
    const { open, handleClose, relatedID, title, message, userIDs } = props;
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth="sm"
        >
            <Card>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <MDBox>
                            <AddReminder
                                relatedID={relatedID}
                                userIDs={userIDs}
                                title={title}
                                message={message}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MDBox p={2} lineHeight={0}>
                            <MDTypography variant="h5">
                                Kurulu Hatırlatıcılar
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <DisplayReminders relatedID={relatedID} />
                        </MDBox>
                    </Grid>
                </Grid>
            </Card>
        </Dialog>
    );
}

export default ReminderTemplate;
