import { useState } from 'react';
import { useQuery } from 'react-query';
import MDBox from 'components/MDBox';
import MDAvatar from 'components/MDAvatar';
import Dialog from '@mui/material/Dialog';
import SingleLead from 'layouts/lead/pages/singleLead/index.js';
import { config } from 'Constants';
import { getCalls } from 'services/api/general/call';
import { getSMS } from 'services/api/general/sms';
import { colorAndLabelDecider } from 'layouts/lead/helpers/general';
import CardHeader from './components/CardHeader';
import CardBody from './components/CardBody';
import CardFooter from './components/CardFooter';

const fetchCalls = async (query) => {
    const response = await getCalls(query)
        .then((res) => res?.data?.result)
        .catch((err) => err);
    return response;
};

const fetchSMS = async (query) => {
    const response = await getSMS(query)
        .then((res) => res?.data?.result)
        .catch((err) => err);
    return response;
};

function Card({ data }) {
    const [openLead, setOpenLead] = useState(false);
    const {
        createdAt,
        name,
        _id: leadID,
        responsibles,
        description,
        source,
        interests,
        customer,
        estimatedCost,
    } = data?.content;

    const { color, label } = colorAndLabelDecider(new Date(createdAt));

    const customerID = customer?._id;
    const query = `relatedID=${customerID}&sourcePage=lead`;

    const { data: calls } = useQuery(['fetchCalls', query], fetchCalls, {
        staleTime: 91200000,
    });

    const { data: sms } = useQuery(['fetchSMS', query], fetchSMS, {
        staleTime: 91200000,
    });

    const renderMembers = responsibles?.map((member, key) => (
        <MDAvatar
            key={`image-${key}`}
            src={member?.profilePicture || config.random.ANONYMOUS_USER_IMAGE}
            alt={member?.name}
            size="xs"
            sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) => `${borderWidth[2]} solid ${white.main}`,
                cursor: 'pointer',
                position: 'relative',
                ml: -1,
                mr: 0,
                '&:hover, &:focus': {
                    zIndex: '10',
                },
            }}
        />
    ));

    return (
        <MDBox onDoubleClick={() => setOpenLead(true)}>
            <CardHeader color={color} label={label} calls={calls} sms={sms} estimatedCost={estimatedCost} />

            <CardBody name={name} description={description} estimatedCost={estimatedCost} />
            <CardFooter
                estimatedCost={estimatedCost}
                source={source}
                interests={interests}
                data={data?.content}
                renderMembers={renderMembers}
            />
            <Dialog onClose={() => setOpenLead(false)} open={openLead} fullWidth={true} maxWidth="xl">
                <MDBox bgColor="light" variant="gradient">
                    <SingleLead incomingID={leadID} isModal={true} />
                </MDBox>
            </Dialog>
        </MDBox>
    );
}

export default Card;
