import { memo } from 'react';

import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import Menu from '@mui/material/Menu';
// import Logo from 'assets/images/logo-ct.png';
import Logo from 'assets/images/projects/logo-card.png';
import ProjectPotential from 'layouts/projects/Components/ProjectPotential';

import Skeleton from '@mui/material/Skeleton';

// Custom styles for ProjectCard
const ProjectCard = memo(function ProjectCard({ project }) {
	return (
		<Card>
			<MDBox p={2}>
				<MDBox display="flex" alignItems="center">
					<MDAvatar
						src={Logo}
						size="lg"
						variant="rounded"
						bgColor="dark"
						sx={{
							p: 1,
							mt: -6,

							borderRadius: ({ borders: { borderRadius } }) =>
								borderRadius.xl,
						}}
					/>
					<MDBox
						ml={2}
						mt={-2}
						lineHeight={0}
						display="flex"
						flexDirection="column"
					>
						<MDTypography
							variant="h6"
							textTransform="capitalize"
							fontWeight="medium"
						>
							<Skeleton
								animation="wave"
								height={20}
								width="200px"
							/>
						</MDTypography>
						<MDBox display="flex">
							<Skeleton
								animation="wave"
								variant="circular"
								width={15}
								height={15}
							/>
							<Skeleton
								animation="wave"
								variant="circular"
								width={15}
								height={15}
							/>
							<Skeleton
								animation="wave"
								variant="circular"
								width={15}
								height={15}
							/>
							<Skeleton
								animation="wave"
								variant="circular"
								width={15}
								height={15}
							/>
						</MDBox>
					</MDBox>
					<MDTypography
						color="secondary"
						sx={{
							ml: 'auto',
							mt: -1,
							alignSelf: 'flex-start',
							py: 1.25,
						}}
						id="basic-button"
					>
						<Icon
							fontSize="default"
							sx={{ cursor: 'pointer', fontWeight: 'bold' }}
						>
							more_vert
						</Icon>
					</MDTypography>
					<Menu
						id="basic-menu"
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						keepMounted
					>
						{/* <MenuItem onClick={close}>Another action</MenuItem>
		  <MenuItem onClick={close}>Something else here</MenuItem> */}
					</Menu>
				</MDBox>
				<MDBox my={2} lineHeight={1}>
					<Skeleton animation="wave" height={10} width="100%" />
				</MDBox>
				<Divider />
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDBox display="flex" flexDirection="column" lineHeight={0}>
						<ProjectPotential readOnly={true} />
						<MDTypography
							variant="button"
							fontWeight="regular"
							color="secondary"
						>
							Potansiyel
						</MDTypography>
					</MDBox>

					<MDBox display="flex" flexDirection="column" lineHeight={0}>
						<MDTypography variant="button" fontWeight="medium">
							<Skeleton animation="wave" height={20} />
						</MDTypography>
						<MDTypography
							variant="button"
							fontWeight="regular"
							color="secondary"
						>
							Başlangıç
						</MDTypography>
					</MDBox>
				</MDBox>
				<Divider />

				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDBox display="flex" alignItems="center">
						<MDBox mr={3} lineHeight={1}>
							<MDTypography
								variant="button"
								color="secondary"
								fontWeight="regular"
								opacity={0.8}
							>
								Müşteri
							</MDTypography>
							<MDTypography
								variant="h6"
								color="secondary"
								fontWeight="medium"
								textTransform="capitalize"
								wordWrap="keep-all"
							>
								<Skeleton
									animation="wave"
									height={20}
									width="250px"
								/>
							</MDTypography>
						</MDBox>
					</MDBox>
					<MDBox display="flex" justifyContent="flex-end" width="30%">
						<MDButton
							variant="gradient"
							color="secondary"
							size="small"
						>
							<Skeleton
								animation="wave"
								height={20}
								width="100%"
							/>
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
});

// Setting default values for the props of ProjectCard
ProjectCard.defaultProps = {
	color: 'dark',
	dateTime: '',
	members: [],
	dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ProjectCard.propTypes = {
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'dark',
		'light',
	]),
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	dateTime: PropTypes.string,
	description: PropTypes.node.isRequired,
	members: PropTypes.arrayOf(PropTypes.string),
	dropdown: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			action: PropTypes.func,
			menu: PropTypes.node,
		}),
	]),
};

export default ProjectCard;
