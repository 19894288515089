import React from 'react';
import { Grid } from '@mui/material';
import PriceCard from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/RetrievePriceAndNames/PriceCard';
import { SHEET_TYPES, SHEET_FIELDS, PRICE_CARD_CONFIGS } from '../../constants';

const getSheetDataValue = (sheetData, sheetName, key) => sheetData[sheetName + '!' + key]?.[0]?.[0];

const RetrievePriceAndNames = ({
    queryClient,
    handleClose,
    demandID,
    templateImage,
    type,
    sheetName,
    sheetData,
    lock,
    setLock,
}) => {
    // type'ı SHEET_TYPES içindeki değerlerle eşleştir
    const sheetType = Object.values(SHEET_TYPES).find((t) => t.toLowerCase() === type.toLowerCase());

    if (!sheetType) {
        console.error(`Unsupported sheet type: ${type}`);
        return null;
    }

    const fields = SHEET_FIELDS[sheetType];
    const miktar = getSheetDataValue(sheetData, sheetName, fields.MIKTAR);

    const priceCardConfigs = PRICE_CARD_CONFIGS[sheetType].map((config) => ({
        ...config,
        name: getSheetDataValue(sheetData, sheetName, fields[config.nameField]),
        price: getSheetDataValue(sheetData, sheetName, fields[config.priceField]),
        amount: miktar * config.amountMultiplier,
    }));

    return (
        <Grid container spacing={3}>
            {priceCardConfigs.map((config, index) => (
                <Grid item xs={12} sm={12} md={3} key={index}>
                    <PriceCard
                        icon="add"
                        baslik={config.label}
                        ekNot={config.name}
                        verilenFiyat={config.price}
                        miktar={config.amount}
                        color={config.color}
                        queryClient={queryClient}
                        handleClose={handleClose}
                        demandID={demandID}
                        templateImage={templateImage}
                        type={type}
                        sheetName={sheetName}
                        sheetData={sheetData}
                        lock={lock}
                        setLock={setLock}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default RetrievePriceAndNames;
