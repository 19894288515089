import * as yup from 'yup';

export const validationSchemaKanat = yup.object({
    baslik: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    miktar: yup
        .number('Talep edilen kanat miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup
        .number('Kasa kalınlığını giriniz')
        .min(0, "kalınlık 0.1cm'den büyük olmalıdır")
        .max(9, "kalınlık 9cm'den küçük olmalıdır")
        .positive()
        .required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('Kanat enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kanat boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    cins: yup.string('Kanat cinsini giriniz'),

    cinsAciklama: yup.string('Kanat cinsinin açıklamasını giriniz'),
    dolguTipi: yup.string('Kanat dolgu tipini giriniz'),
    derzTipi: yup.string('Kanat derz tipini giriniz'),
    derzAdedi: yup.number('Talep edilen kanat derz adedini giriniz'),
    derzSekli: yup.string('Talep edilen kanat derz adedini giriniz'),
    derzBicakAdedi: yup.number('Talep edilen kanat derz adedini giriniz'),
    cumbaBandi: yup.string('Talep edilen cumba bandını giriniz'),
    cumbaKalinligi: yup
        .number('Cumba bant kalınlığını (cm) giriniz')
        .positive()
        .min(0)
        .max(3)
        .required('Lütfen geçerli bir cumba bant kalınlığı(cm) giriniz'),
    cumbaTipi: yup.string('Talep edilen cumba tipini giriniz'),

    kilitYeri: yup.string('Yok(Y), StandartKale(S),  Topuz(T), OtelTipi(O)'),
    menteseYeri: yup.string('Yok(Y), Yönlü 2(Yprk2), Yönlü 3(Yprk3), Gömme 2 (G2), Gömme 3 (G3)'),
    hidrolik: yup.boolean(),
    giyotin: yup.boolean(),
    durbun: yup.boolean(),
    sertAgacDonme: yup.boolean(),
    camYeri: yup.string(
        'Yok(Y), 1GbSerenliCamyeri(CS1),3GbSerenliCamyeri(CS3),5GbSerenliCamyeri(CS5),1GbMdfliCamyeri(CM1),3GbMdfliCamyeri(CM3),5GbMdfliCamyeri(CM5),Giyotin(G)'
    ),
    yuzeyKaplamaSekli: yup.string('Suyuna(SU),Sokrasına(SO),(H),(E),Diğer(D))'),
    indirimOrani: yup.number('İndirim Oranını Giriniz'),
});

export const validationSchemaKanatSheetTemplate = yup.object({
    miktar: yup
        .number('Talep edilen kanat miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup
        .number('Kasa kalınlığını giriniz')
        .min(0, "kalınlık 0.1cm'den büyük olmalıdır")
        .max(9, "kalınlık 9cm'den küçük olmalıdır")
        .positive()
        .required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('Kanat enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kanat boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),

    cumbaBandi: yup.string('Talep edilen cumba bandını giriniz'),

    kilitYeri: yup.string('Yok(Y), StandartKale(S),  Topuz(T), OtelTipi(O)'),
    menteseYeri: yup.string('Yok(Y), Yönlü 2(Yprk2), Yönlü 3(Yprk3), Gömme 2 (G2), Gömme 3 (G3)'),
    hidrolik: yup.boolean(),
    giyotin: yup.boolean(),
    durbun: yup.boolean(),
    sertAgacDonme: yup.boolean(),
    camYeri: yup.string(
        'Yok(Y), 1GbSerenliCamyeri(CS1),3GbSerenliCamyeri(CS3),5GbSerenliCamyeri(CS5),1GbMdfliCamyeri(CM1),3GbMdfliCamyeri(CM3),5GbMdfliCamyeri(CM5),Giyotin(G)'
    ),
});
export const validationSchemaKasa = yup.object({
    baslik: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    miktar: yup
        .number('Talep edilen kasa miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup
        .number('Kasa kalınlığını giriniz')
        .min(0, "kalınlık 0.1cm'den büyük olmalıdır")
        .max(9, "kalınlık 9cm'den küçük olmalıdır")
        .positive()
        .required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('Kasa enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kasa boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    cins: yup
        .string('Kasa cinsini giriniz')
        .required(
            'Kaplamalı Alpi(KA),Kaplamalı Doğal(KD),Kaplama Müşteriden(M),Lakelik(L),Desenli(D),Özel Kaplama Doğal(OD),Özel Kaplama Alpi(OA), Kaplama Müşteriden (KM)'
        ),
    cinsAciklama: yup.string('Kasa cinsinin açıklamasını giriniz'),
    yapi: yup
        .string('Kasa yapısını giriniz')
        .required(
            'Havuz(H), MDF(M), Yumuşak Kontrplak(YK), Sert Kontrplak(SK), KontrTabla(KT),MDF ASTARLANMIŞ CUMBA(MAC)'
        ),
    lambaDerinlik: yup
        .number('Lamba derinliği (cm) giriniz')
        .positive()
        .required('Lütfen geçerli bir lamba derinliği (cm) giriniz'),
    lambaGenislik: yup
        .number('lamba genişliğini cm giriniz')
        .positive()
        .required('Lütfen geçerli bir lamba genişliği (cm) giriniz'),
    koseliBombeli: yup.string('Köşeli mi bombeli mi seçiniz').required('Köşeli, Bombeli'),
    fitil: yup.string('Fitil yapısını seçiniz').required('L, T'),
    pervazKanalGenisligi: yup
        .number('Pervaz kanal genişliği (cm) giriniz')
        .positive()
        .required('Lütfen geçerli bir pervaz kanal genişliği (cm) giriniz'),
    pervazKanalDerinligi: yup
        .number('Pervaz kanal derinliği (cm) giriniz')
        .positive()
        .required('Lütfen geçerli bir pervaz kanal derinliği (cm) giriniz'),
    girinti: yup.string('Girinti yapısını seçiniz').required('E, ?'),
    ozelProfilTipi: yup
        .string('Profil tipini seçiniz')
        .required('Standat Profil(S),Farklı Radüsler(R),Özel Profil(O),Özel Profil + Bıçak (OB)'),
});

export const validationSchemaPervaz = yup.object({
    baslik: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    miktar: yup
        .number('Talep edilen pervaz miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup.number('pervaz kalınlığını giriniz').positive().required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('pervaz enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('pervaz boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    cins: yup.string('pervaz cinsini giriniz'),
    cinsAciklama: yup.string('pervaz cinsinin açıklamasını giriniz'),
    yapi: yup.string('pervaz yapısını seçiniz'),
    gecmeKalinlik: yup.number('pervaz geçme kalınlığını giriniz').positive().required('Standart 0.4 cm'),
    gecmeIctenUzunluk: yup
        .number('u-oervaz geçme içten uzunluğunu giriniz')
        .positive()
        .required('Yüzeydeki derz adedini giriniz.S2S04 için 3 , dikeyine tek derz için 1 yatayına tek derz için 0,5'),
    hamZimpara: yup.boolean('Ham zımpara uygulanacak'),
    boyKesmeKertme: yup.boolean('uPervaz derz tipini giriniz'),
    ozelProfilTipi: yup
        .string('Profil tipini seçiniz')
        .required('Standat Profil(S),Farklı Radüsler(R),Özel Profil(O),Özel Profil + Bıçak (OB)'),
    ozelProfilAciklama: yup.string('Profil tipi aciklamasini girebilirsiniz.'),
});

export const validationSchemaUKasa = yup.object({
    baslik: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    miktar: yup
        .number('Talep edilen u kasa miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup
        .number('Kasa kalınlığını giriniz')
        .min(0, "kalınlık 0.1cm'den büyük olmalıdır")
        .max(9, "kalınlık 9cm'den küçük olmalıdır")
        .positive()
        .required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('Kasa enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('Kasa boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    lambaDerinlik: yup
        .number('Lamba derinliği (cm) giriniz')
        .positive()
        .required('Lütfen geçerli bir lamba derinliği (cm) giriniz'),
    lambaGenislik: yup
        .number('lamba genişliğini cm giriniz')
        .positive()
        .required('Lütfen geçerli bir lamba genişliği (cm) giriniz'),
    pervazKanalGenisligi: yup
        .number('Pervaz kanal genişliği (cm) giriniz')
        .positive()
        .required('Lütfen geçerli bir pervaz kanal genişliği (cm) giriniz'),
    pervazKanalDerinligi: yup
        .number('Pervaz kanal derinliği (cm) giriniz')
        .positive()
        .required('Lütfen geçerli bir pervaz kanal derinliği (cm) giriniz'),
    pervazKanaliAltBosluk: yup
        .number('Pervaz kanalı alt boşluğunu (cm) giriniz')
        .positive()
        .required('Lütfen geçerli pervaz kanalı alt boşluğu (cm) giriniz'),
    indirimOrani: yup.number('İndirim Oranını Giriniz'),
});

export const validationSchemaUPervaz = yup.object({
    baslik: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    miktar: yup
        .number('Talep edilen u-pervaz miktarını giriniz')
        .integer()
        .positive()
        .required('Lütfen geçerli miktar giriniz'),
    kalinlik: yup.number('u-pervaz kalınlığını giriniz').positive().required('Lütfen geçerli bir kalınlık(cm) giriniz'),
    en: yup.number('u-pervaz enini cm cinsinden giriniz').positive().required('Lütfen geçerli bir en(cm) giriniz'),
    boy: yup.number('u-pervaz boyunu cm giriniz').positive().required('Lütfen geçerli bir boy (cm) giriniz'),
    mdfKalinlik: yup
        .number('u-pervaz kaç mm mdfden üretilecek')
        .positive()
        .max(5)
        .required('Lütfen geçerli bir mdf(cm) kalınlığı giriniz. Örn: 0.6 '),
    cins: yup.string('u-pervaz cinsini giriniz'),
    cinsAciklama: yup.string('u-pervaz cinsinin açıklamasını giriniz'),
    yapi: yup.string('u-pervaz yapısını seçiniz'),
    gecmeKalinlik: yup.number('u-pervaz geçme kalınlığını giriniz').positive().required('Standart 0.4 cm'),
    gecmeIctenUzunluk: yup
        .number('u-oervaz geçme içten uzunluğunu giriniz')
        .positive()
        .required('Yüzeydeki derz adedini giriniz.S2S04 için 3 , dikeyine tek derz için 1 yatayına tek derz için 0,5'),
    hamZimpara: yup.boolean('Ham zımpara uygulanacak'),
    boyKesmeKertme: yup.boolean('uPervaz derz tipini giriniz'),
    ozelProfilTipi: yup
        .string('Profil tipini seçiniz')
        .required('Standat Profil(S),Farklı Radüsler(R),Özel Profil(O),Özel Profil + Bıçak (OB)'),
    ozelProfilAciklama: yup.string('Profil tipi aciklamasini girebilirsiniz.'),
    indirimOrani: yup.number('İndirim Oranını Giriniz'),
});
