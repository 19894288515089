import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MapAutoComplete from 'components/General/GoogleMap/MapAutoComplete';
import MDButton from 'components/MDButton';

function SimpleDialog(props) {
    const {
        onClose,
        selectedValue,
        open,

        changeLatLng,
    } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title" style={{ minWidth: '600px' }}>
                Adres
            </DialogTitle>

            <div style={{ minHeight: '300px' }}>
                <MapAutoComplete changeLatLng={changeLatLng} />
            </div>
        </Dialog>
    );
}

export default function AddResponsible(props) {
    const { changeLatLng } = props;
    const [open, setOpen] = React.useState(false);

    const divStyle = {
        display: 'flex',
        flexDirection: 'row-reverse',

        marginRight: '20px',
    };
    return (
        <div style={divStyle}>
            {/* <IconButton aria-label="add" onClick={() => setOpen(true)}>
                <AddIcon fontSize="small" />
            </IconButton> */}

            <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => setOpen(true)}
            >
                DEĞİŞTİR
            </MDButton>
            <SimpleDialog
                setOpen={setOpen}
                open={open}
                onClose={() => setOpen(false)}
                changeLatLng={changeLatLng}
            />
        </div>
    );
}
