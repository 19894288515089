// Libraries
import { memo } from 'react';
import { useQuery } from 'react-query';
import { config } from 'Constants';

// Layout

// MD2 PRO
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';

// @mui

// Project Pages And Components
import AaroLoadingScreen from 'components/Loading';

// API
import { getCari } from 'services/outside/aaro/customer';

// Helpers

import { formatPrice } from 'helpers/priceHelpers';

const fetchAaroCustomer = async (id) => {
	const response = await getCari(id).then((rp) => rp[0]);
	return response;
};
function CustomerDetailsAlternative(props) {
	const { customerID, color, icon } = props;

	const { data, isLoading, error, isFetching } = useQuery(
		['fetchAaroCustomer', customerID],
		() => fetchAaroCustomer(customerID),
		{ staleTime: 6000000 }
	);

	if (isLoading) return <AaroLoadingScreen />;

	if (error) return 'Bir Hata Oluştu: ' + error.message;

	return (
		<div>
			{isFetching ? (
				'Güncelleniyor'
			) : (
				<a
					href={`${config.url.AARO_CARI_KALEM}?id=${data?.CariID}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<DefaultInfoCard
						icon={icon}
						title={data?.CariAdi}
						description={data?.TipAdi}
						value={
							parseFloat(data?.Bakiye) > 0
								? formatPrice(parseFloat(data?.Bakiye)) +
								  ' Borçlu'
								: formatPrice(parseFloat(data?.Bakiye) * -1) +
								  ' Alacaklı'
						}
						color={color}
						valueColor={
							parseFloat(data?.Bakiye) > 0 ? 'primary' : 'success'
						}
					/>
				</a>
			)}
		</div>
	);
}

export default CustomerDetailsAlternative = memo(CustomerDetailsAlternative);
