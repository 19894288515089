import {
    prepareNameForUPervaz,
    prepareNameForPervaz,
    prepareNameForUKasa,
    prepareNameForKasa,
} from 'layouts/projects/Forms/Templates/TemplateForm/calculator/namer.js';

// 45 derece kesim kasaları desteklemektedir
function kasaYanBoyHesapla(olcuBilgisi, duvarBoy, montajBoslugu) {
    if (olcuBilgisi === 'duvar') {
        return duvarBoy - montajBoslugu;
    }
    if (olcuBilgisi === 'kasa') {
        return duvarBoy;
    } else return 0;
}
// 45 derece kesim kasaları desteklemektedir
function kasaBaslikBoyHesapla(olcuBilgisi, duvarEn, montajBoslugu) {
    if (olcuBilgisi === 'duvar') {
        return duvarEn - montajBoslugu * 2;
    }
    if (olcuBilgisi === 'kasa') {
        return duvarEn;
    } else return 0;
}

function getProperty(doorItems, property, keys) {
    for (const key of keys) {
        if (Array.isArray(doorItems[key]) && doorItems[key].length > 0) {
            return doorItems[key][0][property];
        }
    }
    return 0;
}

function getKasaProperty(doorItems, property) {
    return getProperty(doorItems, property, ['kasa', 'uKasa']);
}

function getPervazProperty(doorItems, property) {
    return getProperty(doorItems, property, ['pervaz', 'uPervaz']);
}
function getKanatProperty(doorItems, property) {
    return getProperty(doorItems, property, ['kanat']);
}

function kasaEnHesapla(olcuBilgisi, duvarKalinlik) {
    let duvarNet = Math.floor(duvarKalinlik);
    return (olcuBilgisi === 'duvar' || olcuBilgisi === 'kasa') && (duvarNet % 2 === 0 ? duvarNet : duvarNet - 1);
}

function kasaAdetHesapla(kapiAdedi, kasaBaslikBoy) {
    return Math.ceil(kapiAdedi * (kasaBaslikBoy > 139 ? 3 : 2.5));
}

function kasaDetaylariniHesapla(kasaKalinlik, lambaDerinlik, lambaGenislik, yanginDayanimSuresi, ekNot, kanatKalinlik) {
    let kasaDetay = { kalinlik: kasaKalinlik, lambaDerinlik, lambaGenislik };
    if (yanginDayanimSuresi === 30) {
        kasaDetay = {
            kalinlik: 4.6,
            lambaDerinlik: 1.4,
            lambaGenislik: parseFloat((kanatKalinlik + 0.4).toFixed(1)),
            dumanFitili: true,
            hidrolikKarsiligi: true,
            ekNot: `${ekNot} - 30 DK. YANGIN DAYANIMLI KASA`,
        };
    }
    return kasaDetay;
}

function kanatKalinlikHesapla(
    defaultKanatKalinlik,
    defaultKanatMDFYuzeyKalinlik,
    defaultKanatMaxDerzDerinligi,
    yanginDayanimSuresi
) {
    let result;
    if (yanginDayanimSuresi === 30) {
        result = (4.7 + defaultKanatMaxDerzDerinligi * 2).toFixed(2);
    } else {
        result = defaultKanatKalinlik.toFixed(2);
    }
    return parseFloat(result);
}

function kanatEnHesapla(olcuBilgisi, duvarEn, montajBoslugu, kanatKasaYanBoslugu, kasaKalinlik, kasaLambaDerinlik) {
    // =DUVAR_EN-(DUVAR_MONTAJ_BOSLUGU*2)-KANAT_KASA_YAN_BOSLUGU*2-(KASA_KALINLIK-KASA_LAMBA_DERINLIK)*2
    let sonuc = 0;
    if (olcuBilgisi === 'duvar') {
        sonuc = duvarEn - montajBoslugu * 2 - kanatKasaYanBoslugu * 2 - (kasaKalinlik - kasaLambaDerinlik) * 2;
    }
    if (olcuBilgisi === 'kasa') {
        sonuc = duvarEn - kanatKasaYanBoslugu * 2 - (kasaKalinlik - kasaLambaDerinlik) * 2;
    }
    return parseFloat(sonuc.toFixed(2));
}

function kanatBoyHesapla(
    olcuBilgisi,
    duvarBoy,
    montajBoslugu,
    kanatAltBoslugu,
    kanatKasaUstBoslugu,
    kasaKalinlik,
    kasaLambaDerinlik
) {
    // =DUVAR_BOY-KAPI_MONTAJ_BOSLUGU-(KASA_KALINLIK-KASA_LAMBA_DERINLIK)-KANAT_KASA_ALT_BOSLUGU-KANAT_KASA_UST_BOSLUGU
    let sonuc = 0;
    if (olcuBilgisi === 'duvar') {
        sonuc = duvarBoy - montajBoslugu - (kasaKalinlik - kasaLambaDerinlik) - kanatAltBoslugu - kanatKasaUstBoslugu;
    }
    if (olcuBilgisi === 'kasa') {
        sonuc = duvarBoy - (kasaKalinlik - kasaLambaDerinlik) - kanatAltBoslugu - kanatKasaUstBoslugu;
    }
    return parseFloat(sonuc.toFixed(2));
}
function kanatDetaylariniHesapla(yanginDayanimSuresi, ekNot) {
    if (yanginDayanimSuresi === 30) {
        return {
            hidrolik: true,
            giyotin: true,
            durbun: true,
            ekNot: ekNot + ' - 30 DK OKAL FİYATI EKLENECEK!',
            kilitYeri: 'Otel Tipi',
            menteseYeri: 'Gömme 3',
        };
    }
}

function kasaSoveMi(kasaGenislik) {
    return { soveMi: kasaGenislik > 24 };
}

function pervazBoyHesapla(kasaBoy, pervazGenislik) {
    // Buradaki -1 kanal geçmesi (0,5) ve fatura kalınlık (0,5)
    return kasaBoy + pervazGenislik - 1;
}
function pervazAdetHesapla(baslikKasaBoyu, kapiAdedi) {
    if (baslikKasaBoyu > 219) return 6 * kapiAdedi;
    return 5 * kapiAdedi;
}

function prepareNameForKasas(
    key,
    item,
    kasaAdet,
    kasaEni,
    kasaYanBoyu,
    defaultkasaKalinlik,
    defaultKasaLambaDerinlik,
    defaultKasaLambaGenislik,
    yanginDayanimSuresi,
    defaultKasaEkNot,
    kanatKalinlik
) {
    const kasaDetaylar = kasaDetaylariniHesapla(
        defaultkasaKalinlik,
        defaultKasaLambaDerinlik,
        defaultKasaLambaGenislik,
        yanginDayanimSuresi,
        defaultKasaEkNot,
        kanatKalinlik
    );

    const kasaSove = kasaSoveMi(kasaEni);

    const updatedItem = {
        ...item,
        miktar: kasaAdet,
        en: kasaEni,
        boy: kasaYanBoyu,
        ...kasaDetaylar,
        ...kasaSove,
    };

    if (key === 'kasa') {
        return prepareNameForKasa(updatedItem);
    } else {
        return prepareNameForUKasa(updatedItem);
    }
}

function prepareNameForPervazs(key, item, pervazMiktar, pervazBoy) {
    const updatedItem = {
        ...item,
        miktar: pervazMiktar,
        boy: pervazBoy,
    };

    if (key === 'pervaz') {
        return prepareNameForPervaz(updatedItem);
    } else {
        return prepareNameForUPervaz(updatedItem);
    }
}

export {
    kasaSoveMi,
    kanatDetaylariniHesapla,
    kanatBoyHesapla,
    kanatEnHesapla,
    kanatKalinlikHesapla,
    kasaDetaylariniHesapla,
    kasaAdetHesapla,
    kasaEnHesapla,
    getKanatProperty,
    getPervazProperty,
    getKasaProperty,
    kasaBaslikBoyHesapla,
    kasaYanBoyHesapla,
    prepareNameForKasas,
    pervazBoyHesapla,
    pervazAdetHesapla,
    prepareNameForPervazs,
};
