import { Aaro } from 'aaro';
import { getAllData } from 'aaro-scripts';
import { config } from 'Constants';

export const getOrderStockMovements = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return getAllData(aaro, 'SipStokHareketleri', data);
};

export const getOrders = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('AlinanSiparisListe', data);
};

export const getDekontKalem = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Dekont/Kalemler', data);
};

export const getDekontBaslik = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const aaro = new Aaro({
        baseUrl: config.url.AARO_BASE,
        accessToken: user.aaroToken,
    });
    return aaro.get('Dekont/Basliklar', data);
};
