import { useState, useEffect, useContext } from 'react';
import { BasketContext } from 'layouts/aaro/fastPrice';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Images
import Aaro from 'assets/images/Aaro144.png';

function Header({ children, projectType, setProjectType, setProjectView }) {
	const [tabsOrientation, setTabsOrientation] = useState('horizontal');
	const [tabValue, setTabValue] = useState(0);
	const { setActiveView } = useContext(BasketContext);
	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation('vertical')
				: setTabsOrientation('horizontal');
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
		window.addEventListener('resize', handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () =>
			window.removeEventListener('resize', handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => {
		setTabValue(newValue);
		if (newValue === 0) setActiveView('algolia');
		else if (newValue === 1) setActiveView('fiyat');
		else setActiveView('teklif');
	};
	const preventDefault = (event) => event.preventDefault();
	const aaroKullanici = JSON.parse(localStorage.getItem('AaroKullanici'));

	return (
		<MDBox position="relative" mb={5}>
			<MDBox
				display="flex"
				alignItems="center"
				position="relative"
				minHeight="9rem"
				borderRadius="xl"
				sx={{
					background: 'linear-gradient(310deg,#747b8a, #495361)',
					backgroundSize: 'cover',
					backgroundPosition: '50%',
					overflow: 'hidden',
				}}
			/>
			<Card
				sx={{
					position: 'relative',
					mt: -8,
					mx: 3,
					py: 2,
					px: 2,
				}}
			>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<MDAvatar
							src={Aaro}
							alt="profile-image"
							size="xl"
							shadow="sm"
						/>
					</Grid>
					<Grid item>
						<MDBox height="100%" mt={0.5} lineHeight={1}>
							<MDTypography variant="h5" fontWeight="medium">
								AARO Yazılım
							</MDTypography>
							<Box
								sx={{
									typography: 'body1',
									'& > :not(style) + :not(style)': {
										ml: 1,
									},
								}}
								onClick={preventDefault}
							>
								<Link
									href="#"
									variant="caption"
									color={'secondary'}
									fontWeight="bold"
								>
									{aaroKullanici?.DepoAdi}
								</Link>
								<Link href="#" variant="caption">
									/
								</Link>
								<Link
									href="#"
									color={'secondary'}
									variant="caption"
									fontWeight="bold"
								>
									{aaroKullanici?.KulAdiSoyadi}
								</Link>
							</Box>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={6} sx={{ ml: 'auto' }}>
						<AppBar position="static">
							<Tabs
								orientation={tabsOrientation}
								value={tabValue}
								onChange={handleSetTabValue}
							>
								<Tab
									label="Arama Zekası"
									icon={
										<Icon
											fontSize="small"
											sx={{ mt: -0.25 }}
										>
											psychology
										</Icon>
									}
								/>
								<Tab
									label="HF Eski"
									icon={
										<Icon
											fontSize="small"
											sx={{ mt: -0.25 }}
										>
											currency_lira
										</Icon>
									}
								/>
								<Tab
									label="Teklifler"
									icon={
										<Icon
											fontSize="small"
											sx={{ mt: -0.25 }}
										>
											table_rows
										</Icon>
									}
								/>
							</Tabs>
						</AppBar>
					</Grid>
				</Grid>
				{children}
			</Card>
		</MDBox>
	);
}

// Setting default props for the Header
Header.defaultProps = {
	children: '',
};

// Typechecking props for the Header
Header.propTypes = {
	children: PropTypes.node,
};

export default Header;
