import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import { Dialog, Card, Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import * as yup from 'yup';

import FormField from 'layouts/pages/account/components/FormField';
import { updatePurchase } from 'services/api/purchases';
import { notify } from 'helpers/notificationHelper';

const validationSchema = yup.object({
    wooCommerceID: yup.string().required('WooCommerce ID gereklidir'),
    customerRating: yup.number().nullable(),
    customerFeedback: yup.string(),
});

export default function EditPurchase(props) {
    const { initial, handleClose, open } = props;
    const queryClient = useQueryClient();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);

    const formik = useFormik({
        initialValues: {
            wooCommerceID: initial?.wooCommerceID || '',
            customerRating: initial?.customerRating || '',
            customerFeedback: initial?.customerFeedback || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setDisableOnSubmit(true);

            // Only send the allowed fields in the update
            const updateData = {
                customerRating: values.customerRating,
                customerFeedback: values.customerFeedback,
            };

            updatePurchase(values.wooCommerceID, updateData)
                .then((response) => {
                    notify(response.data.success, response.data.message);
                    handleClose();
                    queryClient.invalidateQueries(['purchase', initial?.wooCommerceID]);
                    queryClient.invalidateQueries(['wooOrders']);
                })
                .catch((err) => {
                    notify(false, err.message || JSON.stringify(err));
                })
                .finally(() => {
                    setDisableOnSubmit(false);
                    setSubmitting(false);
                });
        },
    });

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md" onClick={(e) => e.stopPropagation()}>
            <Card id="basic-info" sx={{ overflow: 'visible' }}>
                <form onSubmit={formik.handleSubmit}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                            {/* WooCommerce ID - Read Only */}
                            <Grid item xs={12}>
                                <FormField
                                    label="WooCommerce ID"
                                    name="wooCommerceID"
                                    value={formik.values.wooCommerceID}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>

                            {/* Customer Rating */}
                            <Grid item xs={12}>
                                <InputLabel>Müşteri Puanı</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        id="customerRating"
                                        name="customerRating"
                                        value={formik.values.customerRating}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="">Seçiniz</MenuItem>
                                        {[1, 2, 3, 4, 5].map((rating) => (
                                            <MenuItem key={rating} value={rating}>
                                                {rating}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Customer Feedback */}
                            <Grid item xs={12}>
                                <FormField
                                    label="Müşteri Geri Bildirimi"
                                    placeholder="Müşteri geri bildirimini giriniz"
                                    name="customerFeedback"
                                    value={formik.values.customerFeedback}
                                    onChange={formik.handleChange}
                                    multiline
                                    rows={3}
                                />
                            </Grid>

                            {/* Submit Button */}
                            <Grid item xs={12}>
                                <MDButton
                                    fullWidth
                                    variant="gradient"
                                    color="dark"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    {disableOnSubmit ? 'Güncelleniyor...' : 'GERİ BİLDİRİMİ KAYDET'}
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </form>
            </Card>
        </Dialog>
    );
}
