// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { cityCodes } from 'helpers/countryHelpers';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';
import { formatPhoneNumber } from 'helpers/smallCodes';

// WhatsApp mesaj linkini oluşturan fonksiyon
const createWhatsAppLink = (phone, firstName, lastName) => {
    const formattedPhone = formatPhoneNumber(phone).replace('+', '');
    const message = encodeURIComponent(`Merhaba ${firstName} ${lastName}, size ahsaplar.com üzerinden ulaşmaktayız.`);
    return `https://wa.me/${formattedPhone}?text=${message}`;
};

function BillingInformation({ billing }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <>
            <MDTypography variant="h6" fontWeight="medium">
                Fatura Bilgileri
            </MDTypography>
            <MDBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor={darkMode ? 'transparent' : 'grey-100'}
                borderRadius="lg"
                p={3}
                mt={2}
            >
                <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
                    <MDBox mb={2}>
                        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                            {billing?.first_name} {billing?.last_name}
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            Firma:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                                {billing?.company || '-'}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            Adres:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                                {billing?.address_1}
                                {billing?.address_2 && `, ${billing.address_2}`}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            İl/İlçe:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                                {`${billing.city}, ${cityCodes[billing.state]} ${billing.postcode}`}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            E-posta:&nbsp;&nbsp;&nbsp;
                            <MDTypography variant="caption" fontWeight="medium">
                                {billing?.email}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                            Telefon:&nbsp;&nbsp;&nbsp;
                            <MDTypography
                                variant="caption"
                                fontWeight="medium"
                                component="a"
                                href={createWhatsAppLink(billing?.phone, billing?.first_name, billing?.last_name)}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ ml: 2, cursor: 'pointer' }}
                            >
                                {formatPhoneNumber(billing?.phone)}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    );
}

export default BillingInformation;
