import * as React from 'react';

import MDPagination from 'components/MDPagination';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
	// console.log(nbPages);
	// console.log(currentRefinement);

	const pagesReduced = nbPages > 10 ? 10 : nbPages;
	return (
		<MDPagination color="secondary">
			{new Array(pagesReduced).fill(null).map((_, index) => {
				const page = index + 1;

				return (
					<MDPagination
						item
						key={index}
						onClick={(event) => {
							event.preventDefault();
							refine(page);
						}}
						href={createURL(page)}
						active={currentRefinement == page ? true : false}
					>
						{page}
					</MDPagination>
				);
			})}
		</MDPagination>
	);
};

export default Pagination;
