import React, { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { updateProject } from 'services/api/project';
import Button from '@mui/material/Button';
// import { getDefaultColor } from 'helpers/colorHelper';
import { notify } from 'helpers/notificationHelper';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { getTags } from 'services/api/general/tag';
import { config } from 'Constants';
import AaroLoadingScreen from 'components/Loading';
import { updateTask, postTask } from 'services/api/general/task';

import { getUsers } from 'services/api/user';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import trLocale from 'date-fns/locale/tr';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { FormatItalic } from '@mui/icons-material';
import { deleteTask } from 'services/api/general/task';

const fetchUsers = async (id) => {
    const response = await getUsers(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const validationSchema = yup.object({
    name: yup
        .string('Başlık giriniz')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Lütfen geçerli bir başlık giriniz'),
    taskList: yup
        .string('Bağlı olduğu liste IDsi gereklidir.')
        .min(5, '5 karakterden fazla olmalıdır')
        .max(200, '200 karakterden az olmalıdır')
        .required('Bağlı olduğu liste IDsi gereklidir'),
});

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) => (rp.data?.result?.tags ? rp.data?.result?.tags : []));
    return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.DEMAND_STATUS_TAG_GROUP_ID;

export default function InputArea(props) {
    const { initial, setOpenDialog, update = false } = props;

    const queryClient = useQueryClient();

    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(['fetchTagGroups', TAG_GROUP_ID_DEMAND], () => fetchTags(TAG_GROUP_ID_DEMAND), { staleTime: 600000 });

    const allUsers = useQuery('fetchUsers', () => fetchUsers(), {
        staleTime: 600000,
    });

    const user = JSON.parse(localStorage.getItem('user'));
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);

    const formik = useFormik({
        initialValues: {
            taskList: initial?.taskList || '',
            parent: initial?.parent || null,
            assignee: initial?.assignee || [],
            assigner: initial?.assigner || [],
            tags: initial?.tags || [],

            name: initial?.name || '',
            description: initial?.description || '',
            completed: initial?.completed || false,
            priority: initial?.priority || 4,
            due: initial?.due || { recurring: false, dateTime: null },
            url: initial?.url || window.location.href,
            // additionalDue: initial?.additionalDue || [null, null],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setDisableOnSubmit(true);
            values.tags = [...new Set(values?.tags?.map((tag) => tag._id))];
            values.assignee = [...new Set(values?.assignee?.map((user) => user._id))];
            // Eğer "due.dateTime" boşsa, bir şey yapmıyoruz veya varsayılan bir değer ayarlıyoruz.
            if (values.due.dateTime === null) {
                values.due.dateTime = null; // veya varsayılan bir değer
            }
            if (update) {
                updateTask(initial._id, values)
                    .then((rp) => {
                        queryClient.invalidateQueries(['fetchTasks', initial.taskList, initial.parent]);
                        notify(rp.data.success, rp.data.message);
                        setOpenDialog(false);
                    })
                    .catch((err) => notify(false, JSON.stringify(err)));
            } else {
                postTask(values).then((rp) => {
                    queryClient.invalidateQueries(['fetchTasks', initial.taskList, initial.parent]);
                    notify(rp.data.success, rp.data.message);
                    setOpenDialog(false);
                });
            }
        },
    });
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    if (allUsers.isLoading) return <AaroLoadingScreen />;
    if (allUsers.error) return 'Bir Hata Oluştu: ' + allUsers.error;

    // useEffect(() => {
    // 	setProjectName(project.projectName);
    // }, [props]);
    return (
        <Card id="basic-info" sx={{ overflow: 'visible' }}>
            <form onSubmit={formik.handleSubmit}>
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Görev Adı"
                                placeholder="Proje Potansiyeli"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label="Görev Açıklaması"
                                placeholder="Proje Potansiyeli eksik bırakılmış"
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                multiple
                                                // defaultValue={[user.username]}
                                                options={allUsers.data}
                                                getOptionLabel={(option) => option?.name}
                                                value={formik.values.assignee}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue(
                                                        'assignee',
                                                        value !== null ? value : initial.assignee
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <FormField
                                                        {...params}
                                                        label="Sorumlular"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                label="Öncelik"
                                                id="priority"
                                                name="priority"
                                                type="number"
                                                value={formik.values.priority}
                                                options={[1, 2, 3, 4]}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('priority', value == null ? 4 : value);
                                                }}
                                                renderInput={(params) => (
                                                    <FormField
                                                        {...params}
                                                        label="Öncelik"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
									<Autocomplete
										// label="Etiketler"
										// size="small"
										multiple
										// defaultValue={['react', 'angular']}
										options={tags}
										getOptionLabel={(option) =>
											option?.name
										}
										value={formik.values.tags}
										onChange={(e, value) => {
											formik.setFieldValue(
												'tags',
												value !== null
													? value
													: initial.tags
											);
										}}
										renderInput={(params) => (
											<FormField
												{...params}
												label="Etiketler"
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)}
									/>
								</Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <MDBox mb={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                                    <Stack>
                                        <DateTimePicker
                                            renderInput={(params) => <TextField {...params} />}
                                            label="Bitiş Tarihi"
                                            value={formik.values.due.dateTime}
                                            onChange={(newValue) => {
                                                formik.setFieldValue('due.dateTime', newValue);
                                            }}
                                            minDate={new Date()}
                                            // minTime={new Date(0, 0, 0, 8)}
                                            // maxTime={new Date(0, 0, 0, 18, 45)}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <MDBox display="flex">
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="dark"
                                    type="submit"
                                    disabled={disableOnSubmit}
                                >
                                    Ekle
                                </MDButton>
                                <MDButton
                                    fullWidth
                                    size="small"
                                    variant="gradient"
                                    color="primary"
                                    onClick={() => {
                                        const result = window.confirm('Silmek İstediğinize Emin Misiniz?');
                                        if (result)
                                            deleteTask(initial._id)
                                                .then((rp) => {
                                                    notify(rp.data.success, rp.data.message);
                                                    queryClient.invalidateQueries(['fetchTaskLists']);
                                                    setOpenDialog(false);
                                                })
                                                .catch((err) => {
                                                    notify(false, err.message);
                                                });
                                    }}
                                    disabled={disableOnSubmit}
                                >
                                    SİL
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </form>
        </Card>
    );
}
