import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given relation id, it retrieves all the files
 * @return promise - axios returns a promise
 */
export const getFiles = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/file/${id}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given data, it post file
 * data needs file path for uploading path
 * example: filePath:projects/hilton
 * finalUrlName: 500kapi
 * then url be like: projects/hilton/500kapi
 * @return promise - axios returns a promise
 */
export const postFile = (data, finalUrlName, source) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
        'X-Source': source || 'web',
    };
    const url = `${config.url.API_URL}/api/general/file/${finalUrlName.trim()}`;

    return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const editFile = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/file/${id}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc for given it deletes file
 * @return promise - axios returns a promise
 */
export const deleteFile = (id, source) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
        'X-Source': source || 'web',
    };
    const url = `${config.url.API_URL}/api/general/file/${id}`;

    return axios.delete(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postTying = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/typing/typingEvent`;
    return axios.post(url, {}, { headers });
};
