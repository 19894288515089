import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc Get a single purchase by WooCommerce ID
 * @return promise
 */
export const getPurchase = (wooCommerceID) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/purchases/${wooCommerceID}`;
    return axios.get(url, { headers });
};

/**
 * @desc Query purchases with filters
 * @return promise
 */
export const getPurchases = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/purchases`;
    return axios.get(url, { headers });
};

/**
 * @desc Update a purchase by WooCommerce ID
 * @return promise
 */
export const updatePurchase = (wooCommerceID, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/purchases/${wooCommerceID}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc Create a new purchase
 * @return promise
 */
export const postPurchase = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/purchases`;
    return axios.post(url, data, { headers });
};

/**
 * @desc Delete a purchase by ID
 * @return promise
 */
export const deletePurchase = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/purchases/${id}`;
    return axios.delete(url, { headers });
};
