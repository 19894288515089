import { useState } from 'react';
import { useQueryClient } from 'react-query';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import Dialog from '@mui/material/Dialog';
import SendSMS from 'components/General/SMS/SendSms.js';

const smsMessageContent = (templateName, messageCustomerName, customer) => {
    const { _id: mongoCustomerID, customerID: aaroCustomerID } = customer;

    const responsible =
        customer?.responsibles?.length > 0
            ? customer.responsibles[0]
            : { name: '', contact: { phone: '' }, username: '' };

    const { name, contact, username } = responsible || {};
    const { phone } = contact || {};

    let jotformURL = ` https://form.jotform.com/221096478573061?temsilci=${username}&aaroCustomerID=${aaroCustomerID}&customerID=${mongoCustomerID}`;

    const messages = {
        ilkKayit: `Sayın ${messageCustomerName?.toUpperCase()}, görüşme talebiniz alınmıştır. Bizlere 0850 305 84 04 numaralı telefondan ulaşabilirsiniz. Size atanan temsilcimiz: ${name}, (tel: ${phone}). Kapim.com.tr`,
        projeGirisi: `Sayın ${messageCustomerName?.toUpperCase()}, projeniz fiyatlandırılmak üzere sistemimize eklenmiştir. Mühendislerimiz en kısa zamanda fiyatlandırmayı tamamlayacaktır.`,
        degerlendir: `Sayın ${messageCustomerName?.toUpperCase()}, temsilcimiz ${name} ile yaptığınız görüşmeyi lütfen değerlendiriniz. ${jotformURL} `,
    };

    return messages[templateName];
};
function SmsTemplates(props) {
    const { customer, open, handleClose, initial, sourcePage = 'customer' } = props;
    const queryClient = useQueryClient();

    const [template, setTemplate] = useState('');
    const [message, setMessage] = useState('');
    const [number, setNumber] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState('');

    const handleTemplateChange = (event) => {
        const { value } = event.target;
        setTemplate(value);
        setMessage(smsMessageContent(value, selectedCustomerName, customer));
    };

    const handleCustomerChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCustomer(selectedValue);

        const parseSelectedValue = selectedValue.split(' - ');
        const name = parseSelectedValue[0] ? parseSelectedValue[0] : '';
        const phone = parseSelectedValue[1] ? parseSelectedValue[1] : '';
        setNumber(phone);
        setSelectedCustomerName(name);

        setMessage(smsMessageContent(template, name, customer));
    };

    const responsibles = queryClient.getQueryData(['fetchAaroCustomerResponsibles', customer?.customerID]);

    const companyDetail = queryClient.getQueryData(['fetchAaroCustomer', customer?.customerID]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <Card>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <MDBox p={2} lineHeight={0}>
                            <MDTypography variant="h5">Şablonlar</MDTypography>

                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={template}
                                    onChange={handleTemplateChange}
                                >
                                    <FormControlLabel value="ilkKayit" control={<Radio />} label="İlk Kayıt" />
                                    <FormControlLabel value="projeGirisi" control={<Radio />} label="Proje Girişi" />
                                    <FormControlLabel value="degerlendir" control={<Radio />} label="Değerlendir" />
                                </RadioGroup>
                            </FormControl>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MDBox p={2} lineHeight={0}>
                            <MDTypography variant="h5">Numaralar</MDTypography>

                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedCustomer}
                                    onChange={handleCustomerChange}
                                >
                                    {responsibles?.map((item, index) => (
                                        <FormControlLabel
                                            key={item?.EsnekAramaKisiti}
                                            value={item?.IlgiliAdi + ' - ' + item?.Tel}
                                            control={<Radio />}
                                            label={item?.IlgiliAdi + ' - ' + item?.Tel}
                                        />
                                    ))}
                                    {companyDetail?.Tel && (
                                        <FormControlLabel
                                            key={companyDetail?.EsnekAramaKisiti}
                                            value={companyDetail?.CariAdi + ' - ' + companyDetail?.Tel}
                                            control={<Radio />}
                                            label={companyDetail?.CariAdi + ' - ' + companyDetail?.Tel}
                                        />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </MDBox>
                    </Grid>
                </Grid>

                <MDBox p={2}>
                    {/* <MDButton
					variant="gradient"
					color="success"
					onClick={handleClickOpen}
					fullWidth
				>
					SMS GÖNDER
				</MDButton> */}
                </MDBox>

                <SendSMS
                    initial={{
                        relatedID: initial.relatedID || customer?._id,
                        smsContent: message,
                        sourcePage: initial.sourcePage || sourcePage,
                        ...initial,
                    }}
                    handleClose={handleClose}
                    content={message}
                    number={number}
                />

                {/*Burada  */}
            </Card>
        </Dialog>
    );
}

export default SmsTemplates;
