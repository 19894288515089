/**
 * @desc for given statusName it return color
 * @return color in material pro
 */
export const getProjectStatusColor = (name) => {
    const upperName = name?.toUpperCase();
    switch (upperName) {
        case 'KAZANILDI':
            return 'linear-gradient(to right, #52c234, #061700)';
        case 'KAYBEDILDI':
            return 'linear-gradient(to right, #870000, #190a05)';
        case 'TEKLIF GÖNDERILDI':
            return 'linear-gradient(to right, #0575e6, #021b79)';
        case 'ÜRETILIYOR':
            return 'linear-gradient(to right, #f7971e, #ffd200)';
        case 'ÜRETILDI':
            return 'linear-gradient(to right, #136a8a, #267871)';

        default:
            return 'linear-gradient(to right, #485563, #29323c)';
    }
};

export const getDefaultAaroColor = () => '#00B8BA';

export const getDefaultColor = () =>
    'linear-gradient(to right, #485563, #29323c)';
export const getDuzeltilmeliColor = () =>
    'linear-gradient(to right,  #a73737, #7a2828)';
export const getFiyatBekleniyorColor = () =>
    'linear-gradient(to right, #44a08d, #093637)';
export const getTamamlandiColor = () =>
    'linear-gradient(to right, #485563, #29323c)';
export const getYeniColor = () => 'linear-gradient(to left, #4e54c8, #8f94fb)';
export const getTeklifGonderildiColor = () =>
    'linear-gradient(to right, #0575e6, #021b79)';
export const getTeklifKaybedildiColor = () =>
    'linear-gradient(to right, #870000, #190a05)';
export const getTeklifKazanildiColor = () =>
    'linear-gradient(to right, #52c234, #061700)';
export const getTeklifUretildiColor = () =>
    'linear-gradient(to right, #603813, #b29f94)';
export const getTeklifUretiliyorColor = () =>
    'linear-gradient(to right, #f7971e, #ffd200)';
