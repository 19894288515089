import * as React from 'react';

import MDInput from 'components/MDInput';

const SearchBox = React.memo(
	({ currentRefinement, isSearchStalled, refine }) => {
		return (
			<MDInput
				autoFocus
				label="Ara..."
				fullWidth
				type="search"
				value={currentRefinement}
				onChange={(event) => refine(event.currentTarget.value)}
				size="large"
				inputProps={{
					style: {
						fontSize: 18,
						height: 70,
						padding: '0 14px',
						fontWeight: 'bold',
					},
				}}
				InputLabelProps={{
					style: {
						top: 10,
						fontSize: 24,
						textAlign: 'center',
					},
				}}
			/>
		);
	}
);

export default SearchBox;
