

// Sales dashboard components
import SheetMainCell from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/components/SheetMainCell';
import FolderCell from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/components/FolderCell';
import IntegrityCell from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/components/IntegrityCell';
import DefaultCell from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/components/DefaultCell';
import SyncCell from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GooglePriceSheets/components/SyncCell';

import { formatDateMinutes } from 'helpers/dateHelpers.js';
import { demandCalculator } from 'helpers/projectHelpers';
export const formatSheetPrices = (sheetPrices, demand) => {
	const { numberOfItem } = demandCalculator(demand);
	const { _id: demandID } = demand;

	const sheetImage =
		'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/1498px-Google_Sheets_logo_%282014-2020%29.svg.png';

	const folderImage =
		'https://icon-library.com/images/google-folder-icon/google-folder-icon-20.jpg';
	const columns = [
		{ Header: 'Fiyat Listesi', accessor: 'file', align: 'left' },
		{ Header: 'sheet', accessor: 'sheet' },
		{ Header: 'kalem', accessor: 'refunds' },
		{ Header: 'Aktif Mi?', accessor: 'active' },
		{ Header: 'Fiyatları Eşitle', accessor: 'priceSync' },
		{ Header: 'tarih', accessor: 'value', align: 'right' },
	];

	const rows = sheetPrices?.map((el) => ({
		active: (
			<DefaultCell>
				{el._id === demand?.activeSheet?._id ? 'Aktif Sheet' : 'Pasif'}
			</DefaultCell>
		),
		priceSync: el._id === demand?.activeSheet?._id && (
			<SyncCell demandID={demandID}> asd</SyncCell>
		),
		sheet: (
			<SheetMainCell
				image={sheetImage}
				name={el?.name}
				createdBy={el?.createdBy.name}
				spreadsheetId={el?.spreadsheetId}
			/>
		),
		value: <DefaultCell>{formatDateMinutes(el?.createdAt)}</DefaultCell>,
		refunds: (
			<IntegrityCell
				currentProducts={numberOfItem}
				sheetProducts={el?.matches.length}
				icon={{ color: 'error', name: 'keyboard_arrow_down' }}
			/>
		),
		file: (
			<FolderCell
				image={folderImage}
				name={el?.folderName}
				folderID={el?.folderID}
			/>
		),
	}));

	return {
		columns,
		rows,
	};
};
// export default {
// 	columns: [
// 		{ Header: 'dosya', accessor: 'product', width: '55%' },
// 		{ Header: 'tarih', accessor: 'value' },
// 		{ Header: 'kalem', accessor: 'refunds', align: 'center' },
// 	],

// 	rows: [
// 		{
// 			product: (
// 				<SheetMainCell
// 					image={
// 						'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/1498px-Google_Sheets_logo_%282014-2020%29.svg.png'
// 					}
// 					name="Lübnan 5500 Kapı 2.Çalışma"
// 					orders="Ali Kemal Kahraman"
// 				/>
// 			),
// 			value: <DefaultCell>15 Mart 2022 12:55</DefaultCell>,
// 			refunds: (
// 				<RefundsCell
// 					value={13}
// 					icon={{ color: 'error', name: 'keyboard_arrow_down' }}
// 				/>
// 			),
// 		},
// 		{
// 			product: (
// 				<SheetMainCell
// 					image={
// 						'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/1498px-Google_Sheets_logo_%282014-2020%29.svg.png'
// 					}
// 					name="Lübnan 5500 Kapı"
// 					orders="Bilal Kahraman"
// 				/>
// 			),
// 			value: <DefaultCell>14 Mart 2022 08:55</DefaultCell>,
// 			refunds: (
// 				<RefundsCell
// 					value={40}
// 					icon={{ color: 'success', name: 'keyboard_arrow_up' }}
// 				/>
// 			),
// 		},
// 	],
// };
