import React, { memo } from 'react';

import { updateLead } from 'services/api/lead';

import ColoredTagSelection from 'components/Tags/ColoredTagSelection';

const LeadTags = ({ lead, tagLabel, tagGroupID, multiple, dbKey }) => {
    const selectedTags = lead[dbKey] == null ? [] : lead[dbKey];
    const handleChanges = (key, value) => {
        const tag = {};
        const leadID = lead?._id;
        tag[key] = Array.isArray(value)
            ? value.map((el) => el._id)
            : value?._id;
        updateLead(leadID, tag).catch((err) => {
            console.log(err);
        });
    };
    return (
        <ColoredTagSelection
            tagGroupID={tagGroupID}
            selectedTags={selectedTags}
            tagLabel={tagLabel}
            multiple={multiple}
            handleChanges={handleChanges}
            dbKey={dbKey}
        />
    );
};

export default memo(LeadTags);
