import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

export default function LoadingScreen() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '30px',
            }}
        >
            <GridLoader color="#017737" size={10} />{' '}
        </div>
    );
}
