import { useEffect, useState } from 'react';

// react-images-viewer components
import ImgsViewer from 'react-images-viewer';

// @mui material components
import Stack from '@mui/material/Stack';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

function ProductImages({ selectedTemplate }) {
	const [currentImage, setCurrentImage] = useState(
		selectedTemplate?.offerImages[0]
	);
	const [imgsViewer, setImgsViewer] = useState(false);
	const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

	const handleSetCurrentImage = ({ currentTarget }) => {
		setCurrentImage(currentTarget.src);
		setImgsViewerCurrent(Number(currentTarget.id));
	};

	useEffect(
		() => setCurrentImage(selectedTemplate?.offerImages[0]),
		[selectedTemplate]
	);

	const openImgsViewer = () => setImgsViewer(true);
	const closeImgsViewer = () => setImgsViewer(false);
	const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
	const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

	return (
		selectedTemplate && (
			<MDBox>
				<ImgsViewer
					imgs={selectedTemplate?.offerImages.map((img) => ({
						src: img,
					}))}
					isOpen={imgsViewer}
					onClose={closeImgsViewer}
					currImg={imgsViewerCurrent}
					onClickPrev={imgsViewerPrev}
					onClickNext={imgsViewerNext}
					backdropCloseable
				/>
				<MDBox
					component="img"
					src={currentImage}
					alt="Product Image"
					shadow="lg"
					borderRadius="lg"
					width="100%"
					onClick={openImgsViewer}
				/>
				<MDBox mt={2} pt={1}>
					<Stack direction="row" spacing={3}>
						{selectedTemplate?.offerImages?.map((img, index) => (
							<MDBox
								component="img"
								id={index}
								src={img}
								alt="small image 1"
								borderRadius="lg"
								shadow="md"
								width="100%"
								height="5rem"
								minHeight="5rem"
								sx={{
									cursor: 'pointer',
									objectFit: 'cover',
								}}
								onClick={handleSetCurrentImage}
							/>
						))}
					</Stack>
				</MDBox>
			</MDBox>
		)
	);
}

export default ProductImages;
