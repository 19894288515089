import React from 'react';
import TaskHistory from './TaskHistory';

const History = ({ task }) => {
    return (
        <div>
            <TaskHistory history={task?.history} />
        </div>
    );
};

export default History;
