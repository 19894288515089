import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { config } from 'Constants';

const AutoCompleteComponent = (props) => {
    const { changeLatLng } = props;
    const { mapApiKey } = config.google;

    const [value, setValue] = useState(null);
    const getLat = (val) => {
        geocodeByAddress(val)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                changeLatLng({ lat, lng, value });
            });
    };
    return (
        <div>
            <GooglePlacesAutocomplete
                apiKey={mapApiKey}
                apiOptions={{ language: 'tr', region: 'tr' }}
                selectProps={{
                    value,
                    onChange: setValue,
                }}
            />
            {value ? getLat(value.label) : ''}
        </div>
    );
};

export default AutoCompleteComponent;
