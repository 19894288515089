import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getUsersByRole = (role) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/all/${role}`;
    return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getUsers = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/all`;
    return axios.get(url, { headers });
};

/**
 * @desc  it updates the user
 * @return promise - axios returns a promise
 */
export const updateUser = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/password`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  it updates the user
 * @return promise - axios returns a promise
 */
export const updateUserProfilePicture = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/aaro`;
    return axios.put(url, data, { headers });
};

/**
 * @desc
 * @return promise - axios returns a promise
 */
export const login = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    const url = `${config.url.API_URL}/api/users/login-official`;
    return axios.post(url, data, { headers });
};
