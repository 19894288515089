import React, { useState } from 'react';
import PushPinIcon from '@mui/icons-material/PushPin'; // MUI Icon
import { updateProject } from 'services/api/project'; // API Service function to pin a project
import { useQueryClient } from 'react-query';
export default function PinProject({ isPinned: initialIsPinned, projectID, userID, projects }) {
    const [isPinned, setIsPinned] = useState(initialIsPinned); // Define a state variable to keep track of pin status
    const queryClient = useQueryClient();
    const user = JSON.parse(localStorage.getItem('user'));

    // // Detect changes in projects
    // React.useEffect(() => {
    //     const project = projects.find((p) => p._id === projectID); // Get the project from your local state
    //     if (project && project.pinnedBy && project.pinnedBy.includes(userID)) {
    //         setIsPinned(true);
    //     } else {
    //         setIsPinned(false);
    //     }
    // }, [projects, projectID, userID]);

    const handlePinProject = async (projectId, userId) => {
        try {
            const project = projects.find((p) => p._id === projectId); // Get the project from your local state
            let newPinnedBy;

            if (project) {
                if (project.pinnedBy && project.pinnedBy.includes(userId)) {
                    // If user has already pinned this project, unpin it
                    newPinnedBy = project.pinnedBy.filter((id) => id !== userId);
                    setIsPinned(false); // Update the pin status to false (unpinned)
                } else {
                    // Else, pin it
                    // If there is no pinnedBy field, default to an empty array
                    newPinnedBy = [...(project.pinnedBy || []), userId];
                    setIsPinned(true); // Update the pin status to true (pinned)
                }

                await updateProject(projectId, { pinnedBy: newPinnedBy }); // Update the project with the new pinnedBy array
                queryClient.invalidateQueries(['fetchPinnedProjects', user?.userID]);
                queryClient.invalidateQueries('searchProjects');
            } else {
                console.log(`Project with ID ${projectId} not found`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <PushPinIcon
                onClick={() => handlePinProject(projectID, userID)}
                style={isPinned ? { color: 'blue', cursor: 'pointer' } : { color: 'grey', cursor: 'pointer' }}
            />
        </div>
    );
}
