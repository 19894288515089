import React from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';

// Custom style for AvatarGroup
const customAvatarGroupStyle = {
    '.MuiAvatar-root': {
        width: 24,
        height: 24,
        fontSize: '.875rem',
        backgroundColor: '#191919',
    },
};

function CustomAvatarGroup({ userSuggestions }) {
    return (
        <AvatarGroup max={3} sx={customAvatarGroupStyle}>
            {userSuggestions?.map((suggestion) => (
                <Avatar alt={suggestion?.user?.username} src={suggestion?.user?.profilePicture} />
            ))}
        </AvatarGroup>
    );
}

export default CustomAvatarGroup;
