import { useState, createContext, useEffect } from 'react';
import Layout from 'layouts/todo/NamedLayout';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { getTaskLists } from 'services/api/general/taskList';
import { useQuery } from 'react-query';

// @mui material components
import TaskLoadingGIF from 'components/Loading/TaskLoadingGIF.js';
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import TaskLists from 'layouts/todo/components/TaskLists/index.js';
import Tasks from 'layouts/todo/components/Tasks/index.js';
import TaskActivity from 'layouts/todo/components/Activity';
export const AllTaskListsContext = createContext();

const fetchTaskLists = async (userID) => {
    try {
        const createdByResponse = await getTaskLists(`createdBy=${userID}`).then((rp) => rp.data.result);
        const sharedWithResponse = await getTaskLists(`sharedWith=${userID}`).then((rp) => rp.data.result);
        // Eğer sonuçları birleştirmek istiyorsanız:
        const combinedResponse = [...createdByResponse, ...sharedWithResponse];

        const key = '_id';
        const unique = [...new Map(combinedResponse.map((item) => [item[key], item])).values()];
        return unique;
    } catch (err) {
        return err;
    }
};

export default function AllTaskLists() {
    const [showActiveLeads, setShowActiveLeads] = useState(true);
    const [search, setSearch] = useState('');
    const [activeList, setActiveList] = useState('');
    const user = JSON.parse(localStorage.getItem('user'));
    const { userID } = user;

    const { data, isLoading, error, isFetching } = useQuery(['fetchTaskLists', userID], () => fetchTaskLists(userID), {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        data && setActiveList(data[0]?._id);
    }, [data]);

    if (isLoading) return <TaskLoadingGIF />;
    if (isFetching) return <TaskLoadingGIF />;
    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <AllTaskListsContext.Provider value={{ setShowActiveLeads, showActiveLeads, search, setSearch }}>
            <Layout>
                <MDBox opacity={1} px={2}>
                    {/* <DraggableList data={kanbanData} /> */}
                    <Grid container spacing={1} justifyContent="center" alignItems="flex-start" height="100%">
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Card sx={{ height: '100%' }}>
                                <TaskLists
                                    title="Listeler"
                                    taskLists={data}
                                    setActiveList={setActiveList}
                                    activeList={activeList}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Card sx={{ height: '100%' }}>
                                <Tasks
                                    title={data ? data.find((el) => el._id === activeList)?.name : 'görevler'}
                                    activeList={activeList}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Card sx={{ height: '100%' }}>
                                <TaskActivity title="Bana Atanan Görevler" />
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </Layout>
        </AllTaskListsContext.Provider>
    );
}
