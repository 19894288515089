import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MDBox from 'components/MDBox';
import Card from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GroupElements/GroupItems/EditGroupItems/Card.js';
import { notify } from 'helpers/notificationHelper';
import MDTypography from 'components/MDTypography';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useGroupItems from 'layouts/projects/Pages/SingleProject/DemandPage/Sections/GroupElements/GroupItems/hooks/useGroupItems.js';
import { updateGroup } from 'services/api/project';
import Grid from '@mui/material/Grid';
import { formatPrice, calculateDiscount } from 'helpers/priceHelpers';
import MDButton from 'components/MDButton';

const updateGroupItems = async (changedItemID, updateParams) => {
    try {
        const rp = await updateGroup(changedItemID, updateParams);
        notify(rp.data.success, rp.data.message);
        return rp;
    } catch (err) {
        notify(false, JSON.stringify(err));
        return false;
    }
};

const handleDragEnd = (result, columns, setColumns, demandID, queryClient) => {
    // const { result, columns, setColumns, demandID, queryClient } = params;
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceColumn = columns.find((column) => column.groupID === source.droppableId);
    const destColumn = columns.find((column) => column.groupID === destination.droppableId);
    const sourceItems = [...sourceColumn.items];
    const destItems = destColumn ? [...destColumn.items] : [];
    const draggedItem = sourceItems[source.index];

    if (source.droppableId !== destination.droppableId) {
        // Remove item from source column
        sourceItems.splice(source.index, 1);
        // Insert item to destination column
        destItems.splice(destination.index, 0, draggedItem);

        if (isItemUnique(destItems, draggedItem)) {
            updateColumns({
                columns,
                setColumns,
                destColumn,
                items: destItems,
                draggedItem,
                demandID,
                queryClient,
                sourceItems,
            });
        } else {
            notify(false, 'Kalem grupta mevcut');
        }
    } else {
        // Reorder items in the same column
        sourceItems.splice(source.index, 1);
        sourceItems.splice(destination.index, 0, draggedItem);
        updateColumns({
            columns,
            setColumns,
            destColumn,
            items: sourceItems,
            draggedItem,
            demandID,
            queryClient,
        });
    }
};

const isItemUnique = (items, targetItem) => {
    const sameItems = items.filter((item) => item.ref._id === targetItem.ref._id);
    return sameItems.length < 2;
};

const convertToSchema = (arr) => {
    let schemaFormat = {
        uKasa: [],
        uPervaz: [],
        pervaz: [],
        kasa: [],
        kanat: [],
        diger: [],
    };
    let items = [];
    arr.forEach((item, index) => {
        if (schemaFormat.hasOwnProperty(item.type)) {
            schemaFormat[item.type].push({
                ref: item.ref,
                quantity: item.quantity,
                itemOrder: index,
                type: item.type,
            });
            items.push({
                ref: item.ref,
                quantity: item.quantity,
                itemOrder: index,
                type: item.type,
            });
        }
    });

    return { schemaFormat, items };
};
const updateColumns = async (params) => {
    const { columns, setColumns, destColumn, items, demandID, queryClient } = params;

    let { schemaFormat: schemeData, items: updatedItems } = convertToSchema(items);

    let updatedColumns = columns.map(
        (obj) =>
            obj.groupID === destColumn.groupID
                ? { ...obj, items: updatedItems } // Spread the object and replace the items
                : obj // If not the target group, leave it as it is
    );

    setColumns(updatedColumns);

    await updateGroupItems(destColumn.groupID, schemeData);

    queryClient.invalidateQueries(['fetchDemandGroups', demandID]);
};

export default function Kanban({ demand, groups }) {
    const { demandItemsReduced, groupColumnsAndItems } = useGroupItems(demand, groups);

    const [columns, setColumns] = useState(
        [
            {
                _id: 'defaultID',
                groupID: 'defaultID',
                groupName: 'Kalemler',
                groupOrder: -1,
                items: demandItemsReduced,
            },
            ...groupColumnsAndItems,
        ].sort((a, b) => a.groupOrder - b.groupOrder)
    );

    const [defaultColumnZeroValuesAreVisible, setDefaultColumnZeroValuesAreVisible] = useState(true);

    const queryClient = useQueryClient();
    const demandID = demand._id;
    const { height, width } = useWindowDimensions();
    const lgColumn = width > 1920 ? 3 : 4;

    useEffect(() => {
        const groupsToRender = [
            {
                _id: 'defaultID',
                groupID: 'defaultID',
                groupName: 'Kalemler',
                groupOrder: -1,
                items: demandItemsReduced,
            },
            ...groupColumnsAndItems,
        ].sort((a, b) => a.groupOrder - b.groupOrder);

        setColumns(groupsToRender);
    }, [demandItemsReduced, groupColumnsAndItems]);

    return (
        <MDBox>
            <MDBox display="flex" justify="center" p={0} m={0}>
                <DragDropContext
                    onDragEnd={(result) => handleDragEnd(result, columns, setColumns, demandID, queryClient)}
                >
                    <Grid container spacing={1}>
                        {columns.map((group, index) => (
                            <Grid item xs={12} md={6} lg={4} xl={lgColumn} key={group._id || index}>
                                <GroupContainer
                                    group={group}
                                    width={width}
                                    height={height}
                                    key={group._id || index} // This line has been updated
                                    demandItemsReduced={demandItemsReduced}
                                    setDefaultColumnZeroValuesAreVisible={setDefaultColumnZeroValuesAreVisible}
                                    defaultColumnZeroValuesAreVisible={defaultColumnZeroValuesAreVisible}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DragDropContext>
            </MDBox>
        </MDBox>
    );
}

function GroupContainer({
    group,
    width,
    height,
    setDefaultColumnZeroValuesAreVisible,
    defaultColumnZeroValuesAreVisible,
}) {
    const calculateTotalPrice = (items) => {
        return items.reduce((total, item) => {
            const discountedPrice = calculateDiscount(item.ref.verilenFiyat, item.quantity, item.ref.indirimOrani);
            return total + discountedPrice;
        }, 0);
    };

    const renderItems = (items) => {
        return items
            .sort((a, b) => a.itemOrder - b.itemOrder)
            .map((item, index) => (
                <Item
                    key={item?.ref._id}
                    item={item}
                    index={index}
                    groupID={group?.groupID}
                    columnItems={group.items}
                    demandID={group.demand}
                />
            ));
    };

    const minWidth = width > 1920 ? width / 5 - 110 : width / 5 - 70;
    const totalPrice = calculateTotalPrice(group?.items || []);

    let visibleItems;
    if (group?.groupName === 'Kalemler' && !defaultColumnZeroValuesAreVisible) {
        visibleItems = group?.items?.filter((el) => el.quantity !== 0);
    } else {
        visibleItems = group?.items;
    }

    return (
        <MDBox
            color="dark"
            bgColor={'light'}
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={1}
            m={1}
            key={group?.groupID}
        >
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" textAlign="center">
                {group?.groupName} - Set Fiyatı: {formatPrice(totalPrice)}
            </MDTypography>
            {group?.groupName === 'Kalemler' && (
                <MDButton
                    size="small"
                    variant="gradient"
                    color="light"
                    onClick={() => setDefaultColumnZeroValuesAreVisible(!defaultColumnZeroValuesAreVisible)}
                >
                    0'ları {defaultColumnZeroValuesAreVisible ? 'gizle' : 'göster'}
                </MDButton>
            )}
            <Droppable droppableId={group?.groupID}>
                {(provided) => (
                    <MDBox
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                            padding: 1,
                            minWidth,
                            minHeight: height / 4,
                            overflow: 'auto',
                        }}
                    >
                        {renderItems(visibleItems)}
                        {provided.placeholder}
                    </MDBox>
                )}
            </Droppable>
        </MDBox>
    );
}

function Item({ item, groupID, columnItems, demandID, index }) {
    return (
        <Draggable draggableId={item.ref._id + groupID} index={index}>
            {(provided, snapshot) => (
                <MDBox
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                        padding: 1,
                        margin: '0 0 8px 0',
                        borderRadius: 2,
                        ...provided.draggableProps.style,
                    }}
                    bgColor={colorDecider(groupID, item)}
                    // bgColor={snapshot.isDragging ? 'light' : 'white'}
                >
                    <Card item={item} groupID={groupID} demandID={demandID} columnItems={columnItems} />
                </MDBox>
            )}
        </Draggable>
    );
}

const colorDecider = (groupID, item) => {
    if (groupID == 'defaultID' && item.quantity < 0) {
        return 'primary';
    }
    if (item.quantity == 0) {
        return 'light';
    }
    return 'white';
};
