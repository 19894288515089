import { Aaro } from 'aaro';
import { getAllData } from 'aaro-scripts';
import { config } from 'Constants';

export const getStocks = async (params) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const stocks = await getAllData(aaro, 'Stok/StokMiktarListe', {
		...params,
		// StokKodu: 'ZAI.TLS.00001',
	});
	return stocks;
};

export const getAaroSipStokHareketleri = async (params) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const stocks = await aaro.get('SipStokHareketleri', {
		...params,
		// StokKodu: 'ZAI.TLS.00001',
	});
	return stocks;
};

export const getStock = async (params) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});

	const stock = await aaro.get('Stok/StokMiktarListe', {
		...params,
		// StokKodu: 'ZAI.TLS.00001',
	});
	return stock;
};

export const getStockSummary = async (params) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});

	const stock = await aaro.get('StokOzet', {
		...params,
		// StokKodu: 'ZAI.TLS.00001',
	});
	return stock;
};

export const getStockMovements = async (params) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const aaro = new Aaro({
		baseUrl: config.url.AARO_BASE,
		accessToken: user.aaroToken,
	});
	const stocks = await getAllData(aaro, 'StokHareketleri', {
		...params,
	});
	return stocks;
};
