import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';

function PassiveProjects({ projects }) {
	const labelsPassiveMonths = [];
	const valuesPassiveMonths = [];
	let counterPassiveProject = {};

	projects.forEach((project) => {
		const status = project?.status?.tag?.name;
		if (isNaN(counterPassiveProject[status])) {
			counterPassiveProject[status] = 0;
		}
		counterPassiveProject[status] += 1;
	});

	Object.keys(counterPassiveProject).forEach((key) => {
		labelsPassiveMonths.push(key);
		valuesPassiveMonths.push(counterPassiveProject[key]);
	});
	const viewDataPassiveProjects = {
		labels: labelsPassiveMonths,
		datasets: {
			labels: 'Pasif Projelerin Durumu',
			data: valuesPassiveMonths,
		},
	};
	return (
		<ReportsLineChart
			color="dark"
			title="Pasif Projelerin Durumu"
			description="Pasifdeki projeler ne durumda"
			date="1dk önce güncellendi"
			chart={viewDataPassiveProjects}
		/>
	);
}

export default PassiveProjects;
