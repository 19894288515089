import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getTask = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/task/${id}`;
    return axios.get(url, { headers });
};

/**
 * 
/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getTasks = (searchQuery) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/task?${searchQuery}`;
    return axios.get(url, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getLeads = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: user.token,
    };

    const url = `${config.url.API_URL}/api/kahramanlar/leads/query`;

    // !
    return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it updates task
 * @return promise - axios returns a promise
 */
export const updateLead = (id, data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/leads/${id}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it updates task
 * @return promise - axios returns a promise
 */
export const postLead = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/leads`;
    return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it updates task
 * @return promise - axios returns a promise
 */
export const completeChildTask = (id, completed) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const data = {
        _id: id,
    };
    const url = `${config.url.API_URL}/api/general/task/completion?completed=${completed}`;
    return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it updates task
 * @return promise - axios returns a promise
 */
export const completeParentTask = (id, completed) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const data = {
        $or: [
            { _id: id },
            {
                parent: id,
            },
        ],
    };
    const url = `${config.url.API_URL}/api/general/task/completion?completed=${completed}`;

    return axios.put(url, data, { headers });
};

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const postTask = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/task`;
    return axios.post(url, data, { headers });
};

/**
 * @desc for given id, it deletes parent task and child tasks
 * @return promise - axios returns a promise
 */
export const deleteLead = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/kahramanlar/leads/${id}`;
    return axios.delete(url, { headers });
};

// ! List

/**
 * @desc for given id, it post customer sms
 * @return promise - axios returns a promise
 */
export const getTaskList = (searchQuery) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/general/taskList?${searchQuery}`;
    return axios.get(url, { headers });
};
